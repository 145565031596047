<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 4.5V13.5C16 13.7761 15.7761 14 15.5 14H10C8.89543 14 8 13.1046 8 12H2V19.5C2 19.7761 1.77614 20 1.5 20H0.5C0.223858 20 0 19.7761 0 19.5V0.5C0 0.223858 0.223858 0 0.5 0H1.5C1.77614 0 2 0.223858 2 0.5V2H8C9.10457 2 10 2.89543 10 4H15.5C15.7761 4 16 4.22386 16 4.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGModelAlert",
});
</script>
