export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier added"])},
        "named": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Named"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier ajouté"])},
        "named": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommé"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activé"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé"])}
      }
    }
  })
}
