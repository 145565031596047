import { User } from "scalingo/lib/models/auth";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updateAccount } from "@/store/user";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  email: string;
}

export class EditAccountEmailHandler extends FormHandler<User> {
  keyPath = "account.emailChanged";

  constructor(
    component: ComponentPublicInstance,
    readonly user: User,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return { email: null };
  }

  dispatchEvents(): void {
    this.on("success", (user) => {
      const email = user.unconfirmed_email as string;
      this.notify("success", { email });
    });
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await updateAccount(this.$store, event));
  }
}
