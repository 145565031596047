export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router Logs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display all connections to your application in the logs."])},
        "routerLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router Logs"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs Router"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche dans les logs toutes les connexions à votre application."])},
        "routerLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs Router"])}
      }
    }
  })
}
