export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force TLS connections"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, you can connect your database both with or without TLS encryption."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-TLS connections are blocked"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-TLS connections are allowed"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before activating this feature, make sure that your application can connect to your database using TLS. If not, communication will not be possible."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer les connexions TLS"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut, vous pouvez vous connecter à votre base de données avec ou sans chiffrement TLS."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les connexions sans TLS sont bloquées"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les connexions sans TLS sont autorisées"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant d'activer cette fonctionnalité, assurez-vous que votre application peut se connecter à votre base de données en utilisant TLS. Dans le cas contraire, la communication ne sera pas possible."])}
      }
    }
  })
}
