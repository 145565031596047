import {
  Event as EventModel,
  AppEvents,
  App,
} from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  FETCH_MORE,
  HANDLE_FETCH,
  FETCH,
  HANDLE_OPERATION,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, SET_META, MERGE, ADD } from "@/lib/store/mutation-types";
import {
  buildMapping,
  CollectionWithFetch,
  EnsureOptions,
  listItems,
  ListItemsOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

type Event = EventModel & {
  app: App;
};

function enhanceEvent(app: App, event: EventModel): Event {
  return { ...event, app };
}

export class EventsStore extends CollectionStore<Event> {
  actions = CollectionStore.buildActions<Event>({
    [REFRESH](context, opts = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Events.for(
          currentApp.id,
          opts,
        ),
        resolveAction: function (resolved: AppEvents) {
          context.commit(
            SET_ALL,
            resolved.events.map((event) => enhanceEvent(currentApp, event)),
          );
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH_MORE](context) {
      const currentApp = useCurrentAppStore().regional as App;

      if (!context.state.meta.pagination?.next_page) {
        return;
      }

      const opts = { page: context.state.meta.pagination.next_page };

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Events.for(
          currentApp.id,
          opts,
        ),
        resolveAction: function (resolved: AppEvents) {
          context.commit(
            MERGE,
            resolved.events.map((event) => enhanceEvent(currentApp, event)),
          );
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH](context, id) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Events.find(
          currentApp.id,
          id,
        ),
        resolveAction: function (event: Event) {
          context.commit(ADD, enhanceEvent(currentApp, event));
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<Event>();
  getters = CollectionStore.buildGetters<Event>();
}

export const Events = buildMapping(new EventsStore(), "events");

export function listEvents(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Event>>,
): CollectionWithFetch<Event> {
  return {
    items: listItems(store.getters[Events.getters.ALL], opts),
    latestFetch: store.getters[Events.getters.LATEST_FETCH],
    meta: store.getters[Events.getters.META],
  };
}

export function findEvent(store: ApplicationStore, id: string): Event {
  return store.getters[Events.getters.FIND](id);
}

export function ensureEvents(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(Events.actions.ENSURE, opts);
}

export function ensureEvent(store: ApplicationStore, id: string): void {
  store.dispatch(Events.actions.ENSURE_ONE, id);
}

export function fetchMoreEvents(store: ApplicationStore): void {
  store.dispatch(Events.actions.FETCH_MORE);
}
