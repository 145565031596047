import {
  Addon,
  AddonProvider as AddonProviderModel,
  Plan as PlanModel,
} from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { HANDLE_FETCH, REFRESH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  listItems,
  ListItemsOptions,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

const parser = new DOMParser();

export type Plan = PlanModel & {
  ramStr?: string;
  diskStr?: string;
  descriptionItems?: string[];
};

export type AddonProvider = AddonProviderModel & {
  plans: Plan[];
};

export function enhancePlan(plan: PlanModel): Plan {
  const result: Plan = { ...plan };

  // RAM values should never be floating point
  // matches: "1 MB de RAM", "10 GiB RAM", "23 TB blablabla RAM"
  const ramMatches = plan.description.match(/(\d*\s*(M|G|T)i?B) \w*\s?RAM/);

  // matches: "1 MB disk space", "10 GiB d'espace disque"
  const diskMatches = plan.description.match(
    /((\d+\.)?\d*\s*(M|G|T)i?B|o) ((disk space)|(d.{1,10}espace))/,
  );

  if (ramMatches && ramMatches[1]) {
    result.ramStr = ramMatches[1];
  }

  if (diskMatches && diskMatches[1]) {
    result.diskStr = diskMatches[1];
  }

  if (plan.description.includes("<li>")) {
    const doc = parser.parseFromString(plan.description, "text/html");
    result.descriptionItems = [];

    doc.querySelectorAll("li").forEach((node) => {
      if (node.textContent) {
        result.descriptionItems?.push(node.textContent);
      }
    });
  } else if (plan.description.includes("<p>")) {
    const doc = parser.parseFromString(plan.description, "text/html");
    const pTags = doc.querySelectorAll("p");

    if (pTags.length == 1) {
      const tag = pTags[0];
      result.descriptionItems = tag.textContent?.split("\n");
    } else {
      pTags.forEach((node) => {
        if (node.textContent) {
          result.descriptionItems?.push(node.textContent);
        }
      });
    }
  }

  return result;
}

export function enhanceProvider(provider: AddonProviderModel): AddonProvider {
  const plans = provider.plans ?? [];

  return { ...provider, plans: plans.map(enhancePlan) };
}

export function enhanceProviders(
  providers: AddonProviderModel[],
): AddonProvider[] {
  return providers.map(enhanceProvider);
}

export class AddonProvidersStore extends CollectionStore<AddonProvider> {
  actions = CollectionStore.buildActions<AddonProvider>({
    async [REFRESH](context, payload = {}) {
      const { regionCode } = payload;
      const promise = scalingoClient(context, regionCode)
        .Addons.listProviders(undefined, true)
        .then(enhanceProviders);

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ALL,
      });
    },
  });

  mutations = CollectionStore.buildMutations<AddonProvider>();
  getters = CollectionStore.buildGetters<AddonProvider>();
}

export const AddonProviders = buildMapping(
  new AddonProvidersStore(),
  "addonProviders",
);

export function listAddonProviders(
  store: ApplicationStore,
  regionCode: string,
  opts?: Partial<ListItemsOptions<AddonProvider>>,
): CollectionWithFetch<AddonProvider> {
  const itemsGetter = `${regionCode}/${AddonProviders.getters.ALL}`;
  const fetchGetter = `${regionCode}/${AddonProviders.getters.LATEST_FETCH}`;

  return {
    items: listItems(store.getters[itemsGetter], opts),
    latestFetch: store.getters[fetchGetter],
  };
}

export function availableAddonProviders(
  store: ApplicationStore,
  regionCode: string,
  existingAddons: Addon[],
  opts?: Partial<ListItemsOptions<AddonProvider>>,
): CollectionWithFetch<AddonProvider> {
  return listAddonProviders(store, regionCode, {
    ...opts,
    transform(coll) {
      // Chain transforms if needed
      if (opts?.transform) coll = opts.transform(coll);

      return coll.filter((addonProvider) => {
        return !existingAddons.find(
          (addon) => addon.addon_provider.id === addonProvider.id,
        );
      });
    },
  });
}

export function ensureAddonProviders(
  store: ApplicationStore,
  regionCode: string,
  opts: EnsureOptions = {},
): void {
  opts.payload = opts.payload || {};
  opts.payload.regionCode = regionCode;

  store.dispatch(`${regionCode}/${AddonProviders.actions.ENSURE}`, opts);
}
