<template>
  <div>
    <span class="font-bold">{{ $t("impacted-addon") }}</span>
    <p class="mb-2">
      {{ $t("addon-intro") }} {{ event.type_data.addon_name }}
      {{ $t("impacted-with-downtime") }}
    </p>
    <span class="font-bold">{{ $t("maintenance-window") }}</span>
    <p class="mb-2">
      {{ $t("window-start-introduction") }}
      {{ formatDate(nextMaintenanceWindow) }}
      {{ $t("between") }}
      {{ formatToHours(nextMaintenanceWindow) }}
      {{ $t("and") }}
      {{
        formatToHours(
          nextMaintenanceWindow.plus({
            hours: event.type_data.maintenance_window_in_hours,
          }),
        )
      }}
      UTC
    </p>
    <span class="font-bold">{{ $t("objective") }}</span>
    <p class="mb-2">
      {{ $ta("maintenanceObjectives", event.type_data.maintenance_type) }}
    </p>
    <p>Maintenance ID: {{ event.type_data.maintenance_id }}</p>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import { formatToHours, formatDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "DatabaseMaintenanceEventDetails",
  mixins: [ModelsTranslation],
  props: {
    event: Object,
  },
  computed: {
    nextMaintenanceWindow() {
      return DateTime.fromISO(
        this.event.type_data.next_maintenance_window,
      ).setZone("utc");
    },
  },
  methods: {
    formatToHours,
    formatDate(date) {
      return formatDateTime(date, "DDDD");
    },
  },
});
</script>

<i18n>
  en:
    title: "Database maintenance planned"
    maintenance: "A maintenance"
    impacted-addon: "Impacted Database"
    addon-intro: "The database"
    impacted-with-downtime: "of the app may be impacted by a brief downtime."
    maintenance-window: "Maintenance window"
    window-start-introduction: "Maintenance will start during the next window configured for this database :"
    between: "between"
    and: "and"
    objective: "Maintenance objective"
  fr:
    title: "Maintenance de base de données planifiée"
    maintenance: "Une maintenance"
    impacted-addon: "Base de données impactée"
    addon-intro: "La base de données"
    impacted-with-downtime: "de l'application peut être impactée par une brève indisponibilité."
    maintenance-window: "Fenêtre de maintenance"
    window-start-introduction: "La maintenance débutera lors de la prochaine fenêtre configurée pour cet base de données:"
    between: "entre"
    and: "et"
    objective: "Objectif de la maintenance"
</i18n>
