<template>
  <StepCard
    :step="1"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <div v-if="notificationPlatforms" class="py-4">
      <template v-if="notificationPlatforms.latestFetch.isLoading">
        <LoadingCartridge
          v-for="i of 4"
          :key="`platform-loader-${i}`"
          class="mb-4"
        />
      </template>

      <template v-else>
        <PlatformCartridge
          v-for="platform of notificationPlatforms.items"
          :key="`platform-${platform.id}`"
          class="mb-4"
          :platform="platform"
          @platformSelected="(e) => $emit('platformSelected', e)"
        />
      </template>
    </div>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import StepCard from "@/components/molecules/card/StepCard.vue";
import LoadingCartridge from "@/components/molecules/cartridge/LoadingCartridge.vue";
import PlatformCartridge from "@/components/parts/app/notifiers/creation/PlatformCartridge.vue";

export default defineComponent({
  name: "NotifierPlatformCard",
  components: { StepCard, LoadingCartridge, PlatformCartridge },
  props: {
    enabled: Boolean,
    notificationPlatforms: Object,
  },
  emits: ["platformSelected"],
});
</script>

<i18n>
  en:
    title: "Notifier platform"
    subtitle: "Select the platform this notifier will use"
    action: "Next step"
  fr:
    title: "Plateforme de notification"
    subtitle: "Choisissez la plateforme de notification à utiliser"
    action: "Étape suivante"
</i18n>
