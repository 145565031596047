<template>
  <div class="flex items-center svg-img" :title="regionCode">
    <SVGRegionFranceSecNum
      v-if="region && region.glyph === 'FranceSecNum'"
      :monochrome="monochrome"
    />
    <SVGRegionFrance
      v-else-if="region && region.glyph === 'France'"
      :monochrome="monochrome"
    />
    <SVGRegionFrance v-else :monochrome="monochrome" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SVGRegionFrance from "@/components/graphics/regions/France.vue";
import SVGRegionFranceSecNum from "@/components/graphics/regions/FranceSecNum.vue";
import { availableRegions } from "@/lib/scalingo/client";

export default defineComponent({
  name: "RegionIcon",
  components: {
    SVGRegionFrance,
    SVGRegionFranceSecNum,
  },
  props: {
    regionCode: String,
    monochrome: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    region() {
      return availableRegions.find((r) => r.code === this.regionCode);
    },
  },
});
</script>
