export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading in progress..."])},
        "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option"])},
        "disabledPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])}
      },
      "fr": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading in progress..."])},
        "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option"])},
        "disabledPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requis"])}
      }
    }
  })
}
