export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result for"])}
      },
      "fr": {
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
        "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de résultat pour"])}
      }
    }
  })
}
