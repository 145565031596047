<template>
  <ViewComponent :billingProfile="billingProfile.value" />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/billing/orders/Root.vue";
import { Routes } from "@/router/names";
import { billingProfileAndFetch } from "@/store/billing-profile";

export default defineComponent({
  name: "OrdersRootContainer",
  components: { ViewComponent },
  computed: {
    billingProfile() {
      return billingProfileAndFetch(this.$store);
    },
  },
  async beforeMount() {
    await this.billingProfile.latestFetch.settled;

    if (!this.billingProfile.value) {
      this.$router.push({ name: Routes.Billing.Root });
      return;
    }
  },
});
</script>
