export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "statuses": {
          "queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queued"])},
          "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
          "pushing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pushing"])},
          "starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful deployment"])},
          "crashed-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crashed when booting"])},
          "timeout-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout at boot"])},
          "build-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build error"])},
          "hook-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hook error"])},
          "aborted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aborted"])}
        }
      },
      "fr": {
        "statuses": {
          "queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queued"])},
          "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
          "pushing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pushing"])},
          "starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement réussi"])},
          "crashed-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crashed when booting"])},
          "timeout-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout at boot"])},
          "build-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build error"])},
          "hook-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hook error"])},
          "aborted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aborted"])}
        }
      }
    }
  })
}
