export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the notifier(s) to use when the alert is triggered."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieur"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionnez le ou les notifiers à utiliser lorsque l'alerte est déclenchée"])}
      }
    }
  })
}
