export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oldest available: ", _interpolate(_named("date"))])},
        "manualBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger manual backup"])},
        "chooseRetentionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a filter"])},
        "backups": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["backup"]), _normalize(["backup"]), _normalize(["backups"])])},
        "noBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No backup yet."])},
        "configureBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first manual backup or configure scheduled backups."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le plus ancien réussi: ", _interpolate(_named("date"))])},
        "backups": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["backup"]), _normalize(["backup"]), _normalize(["backups"])])},
        "manualBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencher un backup manuel"])},
        "chooseRetentionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un filtre"])},
        "noBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun backup n'a encore été effectué."])},
        "configureBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre premier backup manuel ou configurez la planification des backups."])}
      }
    }
  })
}
