export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Git server"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push from your computer to a Git server hosted by Scalingo."])},
        "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
        "remoteUrlLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote url"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur Git de Scalingo"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pousser depuis votre ordinateur vers un serveur Git hébergé par Scalingo."])},
        "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
        "remoteUrlLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url distante"])}
      }
    }
  })
}
