export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "enabledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
        "disabledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])}
      },
      "fr": {
        "enabledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
        "disabledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])}
      }
    }
  })
}
