<template>
  <span class="inline-flex cursor-pointer">
    <button
      :title="$t('smaller')"
      class="inline-flex rounded-l-md border-l items-center px-4 py-2 border-b border-t border-r border-scale-2 border-scale-2 transition ease-in-out duration-150 font-medium bg-primary-2-10 text-primary-2 hover:bg-primary-2-15 text-sm leading-4"
      @click="$emit('down')"
    >
      a
      <font-awesome-icon class="ml-1" icon="caret-down"></font-awesome-icon>
    </button>
    <button
      :title="$t('larger')"
      type="button"
      class="inline-flex rounded-r-md items-center px-4 py-2 border-b border-t border-r border-scale-2 border-scale-2 transition ease-in-out duration-150 font-medium bg-primary-2-10 text-primary-2 hover:bg-primary-2-15 text-sm leading-4"
      @click="$emit('up')"
    >
      A
      <font-awesome-icon class="ml-1" icon="caret-up"></font-awesome-icon>
    </button>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FontSizeButton",
  emits: ["down", "up"],
});
</script>

<i18n>
en:
  larger: "Larger font"
  smaller: "Smaller font"
fr:
  larger: "Plus grande police"
  smaller: "Plus petite police"
</i18n>
