export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create token"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A token will allow authorization as your account"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token name"])},
        "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create token"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un token autorisera l'accès en tant que votre compte"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du token"])},
        "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom"])}
      }
    }
  })
}
