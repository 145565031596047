export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage linked extensions."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an addon"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No addon yet"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des extensions liées."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un addon"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun addon"])}
      }
    }
  })
}
