export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs / Archives"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs db archives, downloadable in gzip format"])},
        "viewArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View archives"])},
        "backToLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to real time logs"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No logs archive yet"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs / Archives"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives des logs de la base de données, téléchargeables au format gzip"])},
        "viewArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir archives"])},
        "backToLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir aux logs temps réel"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore d'archives de logs"])}
      }
    }
  })
}
