import { BillingProfile } from "scalingo/lib/models/billing";

import {
  PayPalInitiateResponse,
  PayPalValidateResponse,
} from "../scalingo/paypal";
import ScalingoPrivate from "../scalingo/private";
import { RemoteOperation } from "../store/remote-operation";

const PAYPAL_ENVIRONMENT = process.env.VUE_APP_PAYPAL_ENVIRONMENT as string;
const PAYPAL_MERCHANT_ID = process.env.VUE_APP_PAYPAL_MERCHANT_ID as string;

let inserted = false;
let init = false;

export function insertPaypalLib(): void {
  if (inserted) {
    return;
  }
  const tag = document.createElement("script");

  tag.setAttribute("src", "https://www.paypalobjects.com/api/checkout.js");

  document.head.appendChild(tag);

  inserted = true;
}

export function initPaypalClient(opts: Record<string, unknown> = {}): void {
  if (init) {
    return;
  }

  window.paypal.checkout.setup(PAYPAL_MERCHANT_ID, {
    environment: PAYPAL_ENVIRONMENT,
    ...opts,
  });

  init = true;
}

/**
 * This function uses the scalingo api to initiate a paypal credit operation.
 * This should be one of the rare items return by the scalingo API that should not go into
 * the vuex store.
 */
export function initiatePaypalCredit(
  client: ScalingoPrivate,
  profile: BillingProfile,
  amount: string | number,
): RemoteOperation<PayPalInitiateResponse> {
  return new RemoteOperation<PayPalInitiateResponse>().follow(
    client.Paypal.initiate(profile.id, amount).then((e) => {
      window.paypal.checkout.initXO();
      return e;
    }),
  );
}

/**
 * This function uses the scalingo api to initiate a paypal credit operation.
 * This should be one of the rare items return by the scalingo API that should not go into
 * the vuex store.
 */
export function validatePaypalCredit(
  client: ScalingoPrivate,
  profile: BillingProfile,
  token: string,
  payerId: string,
): RemoteOperation<PayPalValidateResponse> {
  return new RemoteOperation<PayPalValidateResponse>().follow(
    client.Paypal.validate(profile.id, token, payerId),
  );
}
