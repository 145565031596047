export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
        "newsletter": {
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed to Scalingo's newsletter"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not subscribed to Scalingo's newsletter"])}
        }
      },
      "fr": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
        "newsletter": {
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonné(e) à la newsletter Scalingo"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non abonné(e) à la newsletter Scalingo"])}
        }
      }
    }
  })
}
