export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete an addon"])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want delete this \"", _interpolate(_named("name")), "/", _interpolate(_named("plan")), "\" addon?"])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting this addon could impact the linked app and create disruption."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un addon"])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Souhaitez-vous vraiment supprimer l'addon \"", _interpolate(_named("name")), "/", _interpolate(_named("plan")), "\" ?"])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression de cet addon pourrait avoir un impact sur l'application liée et créer des perturbations."])}
        }
      }
    }
  })
}
