<template>
  <ViewComponent
    :app="app"
    :formHandler="formHandler"
    :notifiers="notifiers"
    @formSubmitted="(e) => formHandler.submit(e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/alerts/edit/Notifiers.vue";
import { EditAlertNotifiersHandler } from "@/lib/handlers";
import { findAlert, ensureAlerts } from "@/store/alerts";
import { ensureNotifiers, listNotifiers } from "@/store/notifiers";

export default defineComponent({
  name: "AlertsCreationEditNotifiers",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formHandler: null,
    };
  },
  computed: {
    alert() {
      if (this.$route.params.alertId) {
        return findAlert(this.$store, this.$route.params.alertId);
      }
      return null;
    },
    notifiers() {
      return listNotifiers(this.$store);
    },
  },
  watch: {
    alert: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.notifiers.latestFetch.settled;

          this.formHandler = new EditAlertNotifiersHandler(
            this,
            this.app,
            this.alert,
            this.notifiers.items,
          );
        } else {
          this.formHandler = null;
        }
      },
    },
  },
  beforeMount() {
    ensureNotifiers(this.$store, { staleAfter: "always" });
    // We have to fetch all alerts : get /alerts/:id don't return the notifier ids!
    ensureAlerts(this.$store, { staleAfter: "always" });
  },
});
</script>
