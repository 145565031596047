export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make your app reachable from other domain names, you'll need to add all of them here and to configure your DNS provider."])},
        "note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For instance, for a subdomain, you will need to add a CNAME entry pointing towards "])},
        "note3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more configuration options, take look at "])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our documentation page"])}
      },
      "fr": {
        "note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rendre votre application accessible depuis d'autres noms de domaines, vous devez les ajouter ci-dessus, ainsi que configurer votre fournisseur DNS."])},
        "note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par exemple, pour un sous-domaine, il faudra ajouter une entrée CNAME pointant vers "])},
        "note3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour plus d'options de configuration, veuillez consulter "])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notre page de documentation"])}
      }
    }
  })
}
