import {
  App,
  Collaborator,
  CollaboratorInvitation,
} from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  CREATE,
  DESTROY,
  HANDLE_FETCH,
  HANDLE_OPERATION,
  REFRESH,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ADD, DELETE, SET_ALL } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export class CollaboratorsStore extends CollectionStore<Collaborator> {
  actions = CollectionStore.buildActions<Collaborator>({
    async [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Collaborators.for(
          currentApp.id,
        ),
        resolveAction: SET_ALL,
      });
    },
    async [CREATE](context, payload) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).Collaborators.invite(currentApp.id, payload.email),
        resolveAction: ADD,
      });
    },
    async [DESTROY](context, collaborator) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).Collaborators.destroy(currentApp.id, collaborator.id),
        resolveAction: () => context.commit(DELETE, collaborator),
      });
    },
  });
  mutations = CollectionStore.buildMutations<Collaborator>();
  getters = CollectionStore.buildGetters<Collaborator>();
}

export const Collaborators = buildMapping(
  new CollaboratorsStore(),
  "collaborators",
);

export function listCollaborators(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Collaborator>>,
): CollectionWithFetch<Collaborator> {
  return {
    items: listItems(store.getters[Collaborators.getters.ALL], opts),
    latestFetch: store.getters[Collaborators.getters.LATEST_FETCH],
  };
}

export function ensureCollaborators(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(Collaborators.actions.ENSURE, opts);
}

export function inviteCollaborator(
  store: ApplicationStore,
  email: string,
): Promise<RemoteOperation<CollaboratorInvitation>> {
  return store.dispatch(Collaborators.actions.CREATE, { email });
}

export function removeCollaborator(
  store: ApplicationStore,
  collaborator: Collaborator,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Collaborators.actions.DESTROY, collaborator);
}
