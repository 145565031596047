import { Notifier } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { destroyNotifier } from "@/store/notifiers";

import type { ComponentPublicInstance } from "vue";

export class DeleteNotifierHandler extends ActionHandler<void> {
  keyPath = "notifiers.delete";

  constructor(
    component: ComponentPublicInstance,
    readonly notifier: Notifier,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.notifier.name });
    });

    this.on("failure", () => {
      this.notify("error", { name: this.notifier.name });
    });
  }

  async submit(): Promise<void> {
    this.follow(await destroyNotifier(this.$store, this.notifier));
  }
}
