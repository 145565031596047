<template>
  <div class="flex bg-scale-0 rounded">
    <div class="flex py-3 font-bold text-scale-9">
      <div
        class="pr-3 w-6 flex justify-center items-center bg-scale-1 mr-2 pl-3 rounded border-r border-primary-2"
        title="Info"
      >
        <font-awesome-icon
          class="text-primary-2 text-opacity-75"
          icon="info"
        ></font-awesome-icon>
      </div>
      <div class="flex-grow flex">
        <div class="self-center text-scale-7">
          <span class="font-normal">
            {{ $t("help") }}
            <code class="bg-scale-2 px-2 text-scale-9 rounded">{{
              app.base_url
            }}</code>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DomainInfo",
  props: {
    app: Object,
  },
});
</script>

<i18n>
en:
  help: "Your app will always be available under this subdomain "
fr:
  help: "Votre app sera toujours disponible sous le domaine "
</i18n>
