import { CollaboratorInvitation } from "scalingo/lib/models/regional";

import { copyToClipboard } from "@/lib/clipboard";
import { ActionHandler } from "@/lib/handlers/action";
import { RemoteOperation } from "@/lib/store/remote-operation";

import type { ComponentPublicInstance } from "vue";

export class CopyCollaborationInvitationHandler extends ActionHandler<CollaboratorInvitation> {
  keyPath = "collaborationLink.copy";

  constructor(
    component: ComponentPublicInstance,
    readonly collaborator: CollaboratorInvitation,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    const promise = copyToClipboard(
      this.collaborator.invitation_link +
        "&utm_source=scalingo&utm_medium=dashboard&utm_campaign=collaboration",
    ).then(() => this.collaborator);

    const operation = new RemoteOperation(promise);

    this.follow(operation);
  }
}
