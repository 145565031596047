<template>
  <VerticalPageMenu :items="menuItems"></VerticalPageMenu>
</template>

<script>
import { defineComponent } from "vue";

import VerticalPageMenu from "@/components/molecules/menus/VerticalPageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DbSettingsMenu",
  components: { VerticalPageMenu },
  props: {
    user: Object,
    selection: String,
    app: Object,
    db: Object,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      const routerParams = {
        region: this.app.region,
        id: this.app.name,
        dbId: this.db.type_name,
      };

      const menuItems = [
        {
          name: this.$t("general"),
          key: Routes.Db.General,
          routerArgs: { name: Routes.Db.General, params: routerParams },
        },
        {
          name: this.$t("maintenance"),
          key: Routes.Db.Maintenance,
          routerArgs: { name: Routes.Db.Maintenance, params: routerParams },
        },
        {
          name: this.$t("access"),
          key: Routes.Db.Networking,
          routerArgs: { name: Routes.Db.Networking, params: routerParams },
        },
        {
          name: this.$t("configuration"),
          key: Routes.Db.Configuration,
          routerArgs: { name: Routes.Db.Configuration, params: routerParams },
        },
      ];

      if (this.db.type_name === "postgresql" || this.db.type_name === "mysql") {
        menuItems.push({
          name: this.$t("users"),
          key: Routes.Db.UserAccess,
          routerArgs: { name: Routes.Db.UserAccess, params: routerParams },
        });
      }

      if (this.db.type_name === "mysql") {
        menuItems.push({
          name: this.$t("databases"),
          key: Routes.Db.DatabasesManagement,
          routerArgs: {
            name: Routes.Db.DatabasesManagement,
            params: routerParams,
          },
        });
      }

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.key)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    general: "General"
    maintenance: "Maintenance"
    access: "Internet Access"
    configuration: "Configuration"
    users: "Users"
    databases: "Databases"
  fr:
    general: "Général"
    maintenance: "Maintenance"
    access: "Accès à Internet"
    configuration: "Configuration"
    users: "Utilisateurs"
    databases: "Bases de données"
</i18n>
