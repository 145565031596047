<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.53846 4C2.68846 4 2 4.716 2 5.6V9.74688C2.89462 10.0773 3.53846 10.9568 3.53846 12C3.53846 13.0432 2.89462 13.9227 2 14.2531V18.4C2 19.284 2.68846 20 3.53846 20H20.4615C21.3115 20 22 19.284 22 18.4V14.2531C21.1054 13.9227 20.4615 13.0432 20.4615 12C20.4615 10.9568 21.1054 10.0773 22 9.74688V5.6C22 4.716 21.3115 4 20.4615 4H3.53846ZM9.38432 7.54844C10.5943 7.54844 11.384 8.41263 11.384 9.74062C11.384 11.063 10.5943 11.9328 9.38432 11.9328C8.18124 11.9328 7.38462 11.063 7.38462 9.74062C7.38462 8.41823 8.17585 7.54844 9.38432 7.54844ZM13.7353 7.76094H15.6148L12.5018 12.2312L10.3218 16.2391H8.4348L11.4982 11.7906L13.7353 7.76094ZM9.38432 8.8125C9.0397 8.8125 8.83143 9.15434 8.83143 9.73594C8.83143 10.3119 9.0397 10.6578 9.38432 10.6578C9.72355 10.6578 9.93269 10.3111 9.93269 9.73594C9.93269 9.14874 9.73508 8.8125 9.38432 8.8125ZM14.6217 12.0672C15.8248 12.0672 16.6154 12.9315 16.6154 14.2531C16.6154 15.5819 15.8248 16.45 14.6217 16.45C13.4186 16.45 12.628 15.5811 12.628 14.2531C12.628 12.9315 13.4186 12.0672 14.6217 12.0672ZM14.6217 13.3313C14.2771 13.3313 14.0673 13.6715 14.0673 14.2531C14.0673 14.8291 14.2771 15.1766 14.6217 15.1766C14.9609 15.1766 15.1686 14.8291 15.1686 14.2531C15.1686 13.6651 14.9655 13.3313 14.6217 13.3313Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "VoucherGlyph",
});
</script>
