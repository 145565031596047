<template>
  <div>
    <template v-if="enabled">
      <div
        class="rounded-full h-6 w-6 flex border-scale-6 border-2 items-center justify-center"
      ></div>
    </template>
    <template v-if="!enabled">
      <CheckGlyph class="text-primary-2 shadow-inner" :size="6"></CheckGlyph>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";

export default defineComponent({
  name: "RadioCheckButton",
  components: { CheckGlyph },
  props: {
    enabled: Boolean,
    modelValue: String,
    inputId: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    classFn: function () {
      return "";
    },
  },
});
</script>
