export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of vouchers that help pay for your monthly consumption."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no vouchers redeemed"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem voucher"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des vouchers qui participent au paiement de votre consommation mensuelle."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun vouchers utilisé"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un voucher"])}
      }
    }
  })
}
