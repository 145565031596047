export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop free trial"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to stop the free trial period?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter l'essai gratuit"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment arrêter la période d'essai gratuite ?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
      }
    }
  })
}
