<template>
  <dl class="divide-y divide-scale-2">
    <slot />
  </dl>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SCKVList",
});
</script>
