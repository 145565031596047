export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displayed in the order they will be used"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment method added"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be charged at the beginning of each month for the resources used."])},
        "sepa": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA direct debit as a payment method"])},
          "body1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA Direct Debit is only available to known customers who have paid at least three consecutive invoices with an amount greater than €100."])},
          "body2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you meet the above requirements, or if your case requires special attention, please "])},
          "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact our support team."])},
          "supportMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello! I would like to request the ability to pay my invoices via SEPA Direct Debit."])}
        },
        "buttons": {
          "addSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add SEPA account"])},
          "addCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add card"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens de paiement"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichés par ordre d'utilisation"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune méthode de paiement"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous serez facturé au début de chaque mois pour les ressources utilisées."])},
        "sepa": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prélèvement SEPA comme mode de paiement"])},
          "body1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prélèvement SEPA (SEPA Direct Debit) n'est disponible que pour les clients connus qui ont payé au moins trois factures consécutives d'un montant supérieur à 100 €."])},
          "body2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous répondez aux critères ci-dessus, ou si votre cas nécessite une attention particulière, veuillez "])},
          "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacter notre équipe support."])},
          "supportMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour! Je souhaiterai pouvoir régler mes factures via prélèvement SEPA."])}
        },
        "buttons": {
          "addSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un compte SEPA"])},
          "addCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une carte bancaire"])}
        }
      }
    }
  })
}
