<template>
  <SCBanner kind="warning">
    <template #icon>
      <ReloadGlyph :animated="true" />
    </template>

    {{ $t("ongoing") }}
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "RestartingBanner",
  components: { ReloadGlyph, SCBanner },
});
</script>

<i18n>
  en:
    ongoing: "Database is being restarted …"
  fr:
    ongoing: "La base de données est en cours de redémarrage …"
</i18n>
