<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"> </CardHeader>
    </template>
    <template #body>
      <AdvancedSelectInput
        v-model="form.locale"
        :options="availableLocales"
        class="mt-6 sm:w-full md:w-1/3 lg:w-1/5"
      >
        <template #label>
          <div class="mb-3">{{ $t("labels.locale") }}</div>
        </template>

        <template #text="{ option }">
          {{ option.text }}
        </template>
      </AdvancedSelectInput>

      <AdvancedSelectInput
        v-model="form.theme"
        :options="availableThemes"
        class="mt-4 sm:w-full md:w-1/3 lg:w-1/5"
      >
        <template #label>
          <div class="mb-3">{{ $t("labels.theme") }}</div>
        </template>

        <template #text="{ option }">
          {{ option.text }}
        </template>
      </AdvancedSelectInput>

      <CheckboxInputAtom
        v-model="form.sticky_search"
        :label="$t('labels.stickySearch')"
        class="mt-6"
      />

      <SCButton
        class="mt-6"
        kind="primary"
        :loading="formHandler?.isSubmitting"
        :disabled="formHandler.isDirty(form)"
        @click="submitForm"
      >
        {{ $t("action") }}
      </SCButton>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import FormHandlerMixin from "@/mixins/form_handler_mixin";

export default defineComponent({
  name: "PreferencesCard",
  components: {
    CardHeader,
    CheckboxInputAtom,
    Card,
    SCButton,
    AdvancedSelectInput,
  },
  mixins: [FormHandlerMixin],
  props: {
    user: Object,
    availableLocales: Array,
  },
  data() {
    return {
      availableThemes: [
        { text: this.$t("theme.default"), value: null },
        { text: this.$t("theme.dark"), value: "dark" },
        { text: this.$t("theme.light"), value: "light" },
      ],
    };
  },
});
</script>

<i18n>
en:
  title: "Preferences"
  subtitle: "You can customize some features of the dashboard"
  labels:
    locale: "Language of the interface"
    theme: "Theme of the interface"
    stickySearch: "Stay on the same section when switching apps via the search"
  theme:
    dark: "Dark"
    light: "Light"
    default: "OS preference"
  action: "Update preferences"
fr:
  title: "Préférences"
  subtitle: "Vous pouvez personnaliser certaines fonctionnalités du dashboard"
  labels:
    locale: "Langue de l'interface"
    theme: "Thème de l'interface"
    stickySearch: "Rester sur la même page lors d'un changement d'app via la recherche"
  theme:
    dark: "Sombre"
    light: "Clair"
    default: "En fonction de l'OS"
  action: "Mettre a jour les préférences"
</i18n>
