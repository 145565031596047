import { APIError } from "scalingo/lib/errors";
import { User } from "scalingo/lib/models/auth";

import { ActionHandler } from "@/lib/handlers/action";
import { setSCMRepoLinker } from "@/store/scm-repo-link";

import type { ComponentPublicInstance } from "vue";

export class EditSCMRepoLinkerHandler extends ActionHandler<void> {
  keyPath = "scmRepoLink.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly user: User,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));

    this.on("failure", () => {
      const messages = this.errors.$base.join(", ");

      this.notify("error", { error: messages });
    });
  }

  async submit(): Promise<void> {
    this.follow(await setSCMRepoLinker(this.$store, this.user));
  }

  formatErrors(): void {
    const error = this.operation?.error;

    if (error && error instanceof APIError) {
      if (error.data.error) {
        this.errors.$base.push(error.data.error);
      }
    }
  }
}
