export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": {
          "canonical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This domain is the canonical one"])}
        },
        "headers": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
          "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
        }
      },
      "fr": {
        "title": {
          "canonical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce domaine est le domaine canonique"])}
        },
        "headers": {
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
          "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
        }
      }
    }
  })
}
