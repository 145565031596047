import { defineStore } from "pinia";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    value: "en", // Default locale
  }),
  actions: {
    setLocale(newLocale: string) {
      this.value = newLocale;
    },
    getLocale() {
      return this.value;
    },
  },
});
