<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 7H18C19.1046 7 20 7.89543 20 9V11.84C19.9985 12.6038 19.8526 13.3604 19.57 14.07L18.5 16.74C18.1981 17.4981 17.466 17.9967 16.65 18H10.41C9.50586 17.9993 8.63899 17.6396 8 17C7.35291 16.3786 6.48702 16.0373 5.59 16.05H5V8.05H5.89C6.581 8.05138 7.22377 7.69597 7.59 7.11L8.81 5.11C9.26062 4.39127 9.92506 3.83174 10.71 3.51L11.14 3.34C11.6835 3.12139 12.1038 2.67547 12.29 2.12L12.89 0.34C12.9582 0.138081 13.1469 0.00158284 13.36 0H13.5C14.3284 0 15 0.671573 15 1.5V1.89C15.0196 2.58381 14.8649 3.27145 14.55 3.89L13 7ZM2.5 17C2.77614 17 3 16.7761 3 16.5V7.5C3 7.22386 2.77614 7 2.5 7H0.5C0.223858 7 0 7.22386 0 7.5V16.5C0 16.7761 0.223858 17 0.5 17H2.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThumbUp",
});
</script>
