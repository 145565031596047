export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
        },
        "appOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the app owner"])},
        "appLinker": {
          "github": {
            "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user's github account is linked to this app"])},
            "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user linking the app to github cannot be removed"])}
          },
          "gitlab": {
            "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user's gitlab account is linked to this app"])},
            "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user linking the app to gitlab cannot be removed"])}
          }
        },
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])},
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the invitation link"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the collaborator"])}
        }
      },
      "fr": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
        },
        "appOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire de l'app"])},
        "appLinker": {
          "github": {
            "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte github de cet utilisateur est lié a cette app"])},
            "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le collaborateur liant l'app a github ne peut pas être supprimé"])}
          },
          "gitlab": {
            "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte gitlab de cet utilisateur est lié a cette app"])},
            "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le collaborateur liant l'app a gitlab ne peut pas être supprimé"])}
          }
        },
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])},
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the invitation link"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le collaborateur"])}
        }
      }
    }
  })
}
