export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the Let’s Encrypt integration"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will remove the certificate actually in use, then ask Let’s Encrypt to generate a new one."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser l'intégration Let’s Encrypt"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela supprimera le certificat actuellement utilisé, puis demander à Let’s Encrypt d'en générer un nouveau."])}
      }
    }
  })
}
