<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton v-if="dbPlan.item?.backup" :to="backupsRoute">
          {{ $t("action") }}
        </SCButton>
      </template>
    </CardHeader>

    <div v-if="dbPlan.item?.backup" class="mt-4">
      <div v-if="lastBackup.item" class="flex flex-col">
        <div
          class="flex-row flex items-center justify-between w-full"
          :title="
            $ta('backup', `full_statuses.${lastBackup.item.status}`) +
            ' via ' +
            $ta('backup', `method.${lastBackup.item.method}`) +
            ' ' +
            $t('method')
          "
        >
          <div
            class="flex flex-row items-center"
            :class="{
              'text-color-1': inSuccess,
              'text-color-2': inFailure,
              'text-scale-5': isScheduled || isOngoing,
            }"
          >
            <CheckGlyph v-if="inSuccess" />
            <CircleExclamationGlyph v-if="inFailure" />
            <ReloadGlyph v-if="isOngoing" :animated="true" />
            <ClockGlyph v-if="isScheduled" />
            <span class="humanize font-bold ml-2">
              {{ $ta("backup", `full_statuses.${lastBackup.item.status}`) }}
            </span>
            <div
              class="flex flex-row items-center space-x-1 text-scale-7 ml-1"
            ></div>
          </div>
          <div class="flex text-sm text-scale-7 items-center space-x-4">
            <div v-if="lastBackup.item.method === 'periodic'" class="flex">
              <span class="font-medium">
                <ClockRotateLeftGlyph class="text-scale-5 h-5 w-5 pr-1" />
              </span>
              <div>{{ $t("periodic") }}</div>
            </div>
            <div v-if="lastBackup.item.method === 'manual'" class="flex">
              <span class="font-medium">
                <UserGlyph class="text-scale-5 h-5 w-5 pr-1" />
              </span>
              <div>{{ $t("manual") }}</div>
            </div>
            <div class="flex">
              <span>
                <font-awesome-icon
                  class="text-scale-5"
                  :icon="['far', 'calendar']"
                  :title="$ta('icons', 'timestamp')"
                ></font-awesome-icon>
              </span>

              <div
                class="ml-1"
                :title="formatDateTime(lastBackup.item.created_at)"
              >
                {{ relativeDateTime(lastBackup.item.created_at) }}
              </div>
            </div>
            <div class="flex" :title="$ta('icons', 'size')">
              <span>
                <font-awesome-icon
                  class="text-scale-5 pr-1"
                  :icon="['far', 'hard-drive']"
                ></font-awesome-icon>
              </span>
              <div
                v-if="lastBackup.item.size && lastBackup.item.size > 0"
                class="shortWidth"
              >
                {{ humanReadableSize(lastBackup.item.size) }}
              </div>
              <div v-else class="shortWidth">
                {{ $t("notAvailable") }}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 border-t pt-4 text-center text-scale-7">
          {{
            $t("backupPolicy", {
              oldestBackupTime: formatDateTime(
                oldestBackup.item.created_at,
                "DD",
              ),
            })
          }}
        </div>
      </div>
      <div v-else>
        <span class="font-bold mr-1">{{ $t("noBackup") }}</span>
        <p>{{ $t("configureBackups") }}</p>
      </div>
    </div>
    <div v-else>
      <span class="font-bold mr-1">{{ $t("alert") }}</span
      >{{ $t("complement") }}
      <router-link :to="changePlanRouterArgs" class="underline"
        >{{ $t("planLink") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AnsiUp from "ansi_up";
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import CircleExclamationGlyph from "@/components/atoms/glyphs/CircleExclamationGlyph.vue";
import ClockGlyph from "@/components/atoms/glyphs/ClockGlyph.vue";
import ClockRotateLeftGlyph from "@/components/atoms/glyphs/ClockRotateLeftGlyph.vue";
import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import UserGlyph from "@/components/atoms/glyphs/UserGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { RemoteOperationSuccess } from "@/lib/store/remote-operation";
import { humanReadableSize } from "@/lib/utils/size";
import {
  formatDateTime,
  formatDuration,
  relativeDateTime,
} from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "BackupsCard",
  components: {
    SCButton,
    CardHeader,
    CheckGlyph,
    CircleExclamationGlyph,
    ClockRotateLeftGlyph,
    UserGlyph,
    ClockGlyph,
    ReloadGlyph,
  },
  mixins: [ModelsTranslation],
  props: {
    dbPlan: Object,
    lastBackup: Object,
    oldestBackup: Object,
    db: Object,
    app: Object,
    addon: Object,
  },
  data() {
    const colorizer = new AnsiUp();
    colorizer.use_classes = true;

    return {
      colorizer,
      loadingOperation: RemoteOperationSuccess,
      backupsRoute: {
        name: Routes.Db.Backups.List,
        args: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      },
    };
  },
  computed: {
    backupTime() {
      return this.db.periodic_backups_scheduled_at[0] + ":00 UTC";
    },
    inSuccess() {
      return this.lastBackup.item.status === "done";
    },
    inFailure() {
      return this.lastBackup.item.status === "error";
    },
    isOngoing() {
      return this.lastBackup.item.status === "running";
    },
    isScheduled() {
      return this.lastBackup.item.status === "scheduled";
    },
    constraintWidth() {
      if (!this.isList) {
        return false;
      }
      return window.innerWidth > 1024;
    },
    changePlanRouterArgs() {
      return {
        name: Routes.App.Addon.Edition.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          addonId: this.addon.id,
        },
      };
    },
  },
  methods: {
    relativeDateTime,
    formatBackupDuration(input) {
      return formatDuration({ seconds: input });
    },
    formatDateTime,
    humanReadableSize,
  },
});
</script>

<i18n>
en:
  title: "Backup"
  subtitle: "Last backup information"
  method: "method"
  action: "Manage"
  alert: "Backups unavailable"
  complement: "in sandbox plans."
  planLink: "Upgrade this plan."
  download: "Download your last backup"
  backupLink: "Make a manual backup."
  periodic: "Scheduled"
  manual: "Manual"
  backupPolicy: "Oldest backup available: {oldestBackupTime}"
  noBackup: "No backup yet."
  notAvailable: "Size not available"
  configureBackups: "Create your first manual backup or configure scheduled backups by clicking on the Manage button."
fr:
  title: "Backup"
  subtitle: "Information sur le dernier backup"
  method: "méthode"
  action: "Gérer"
  alert: "Backups non disponibles"
  complement: "pour les plans sandbox."
  planLink: "Mettre à jour ce plan."
  download: "Télécharger le dernier backup."
  backupLink: "Effectuer un backup manuel."
  periodic: "Planifié"
  manual: "Manuel"
  backupPolicy: "Le plus ancien réussi: {oldestBackupTime}"
  noBackup: "Pas encore de backup."
  notAvailable: "Taille non disponible"
  configureBackups: "Créez votre premier backup manuel ou configurez la planification des backups en cliquant sur le bouton Gérer."
</i18n>
