export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB Persistence"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB persistence configuration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This persistency mode, which is configured by default, provides a compromise between performance and persistence. Data are saved to disk periodically, according to the number of write operations, asynchronously. It means that in the scope of an incident, recent data are lost. Here are the saving rules: "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 900 seconds if at least 1 write operation has occurred"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 300 seconds if at least 10 write operations have occurred"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 60 seconds if at least 10000 write operations have occurred"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB persistence enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB persistence disabled"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistance RDB"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de la persistance RDB"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mode de persistance, configuré par défaut, offre un compromis entre performance et persistance. Les données sont sauvegardées sur le disque périodiquement, en fonction du nombre d'opérations d'écriture, de manière asynchrone. Cela signifie que dans le cadre d'un incident, les données récentes sont perdues. Voici les règles de sauvegarde : "])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 900 secondes si au moins 1 opération d'écriture a eu lieu"])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 300 secondes si au moins 10 opérations d'écriture ont eu lieu"])},
        "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 60 secondes si au moins 10000 opérations d'écriture ont eu lieu"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistance RDB activée"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistance RDB désactivée"])}
      }
    }
  })
}
