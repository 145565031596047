<template>
  <div>
    <ContractUpdateBanner
      v-for="contract in contractsToAccept.items"
      :key="`contract-${contract.id}`"
      :contract="contract"
      @startContractAcceptance="(e) => $emit('startContractAcceptance', e)"
    />
    <ContactNearlyOutdatedBanner
      v-if="contactNearlyOutdated && bannerIsDisplayed"
      :contactLastUpdate="contactLastUpdate"
      @dismiss="$emit('dismissBanner')"
    />
    <ContactOutdatedBanner v-if="contactOutdated" />

    <main class="container px-4 mt-6 pv:mt-0">
      <SideMenuLayout>
        <template v-slot:side>
          <Menu :user="user"></Menu>
        </template>
        <template v-slot:router>
          <router-view />
        </template>
      </SideMenuLayout>
    </main>

    <AcceptContractModal
      v-if="acceptContractHandler"
      :formHandler="acceptContractHandler"
      @modalClosed="$emit('cancelContractAcceptance')"
      @formSubmitted="(e) => $emit('submitContractAcceptance', e)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SideMenuLayout from "@/components/layouts/SideMenuLayout.vue";
import ContactNearlyOutdatedBanner from "@/components/organisms/banners/ContactNearlyOutdatedBanner.vue";
import ContactOutdatedBanner from "@/components/organisms/banners/ContactOutdatedBanner.vue";
import ContractUpdateBanner from "@/components/organisms/banners/ContractUpdateBanner.vue";
import Menu from "@/components/organisms/menus/AccountMenu.vue";
import AcceptContractModal from "@/components/parts/account/contracts/AcceptContractModal.vue";

export default defineComponent({
  name: "Root",
  components: {
    ContactNearlyOutdatedBanner,
    ContactOutdatedBanner,
    SideMenuLayout,
    Menu,
    ContractUpdateBanner,
    AcceptContractModal,
  },
  props: {
    user: Object,
    contractsToAccept: Object,
    acceptContractHandler: Object,
    contactNearlyOutdated: {
      type: Boolean,
      default: false,
    },
    contactOutdated: {
      type: Boolean,
      default: false,
    },
    bannerIsDisplayed: {
      type: Boolean,
      default: true,
    },
    contactLastUpdate: String,
  },
  emits: [
    "cancelContractAcceptance",
    "startContractAcceptance",
    "submitContractAcceptance",
    "dismissBanner",
  ],
});
</script>
