<template>
  <main>
    <BillingCard
      v-if="!isC3Customer(this.billingProfile)"
      :user="user"
      :billingProfile="billingProfile"
      @editBillingProfile="billingModalOpened"
    />

    <C3BillingCard v-if="isC3Customer(this.billingProfile)" />

    <StopFreeTrialCard
      v-if="billingProfile && inFreeTrial && !isC3Customer(this.billingProfile)"
      class="mt-4"
      :user="user"
      :billingProfile="billingProfile"
      :freeTrialHandler="freeTrialHandler"
      @click="$emit('freeTrialModalOpened')"
    />

    <BillingFormModal
      v-if="billingProfileHandler"
      :formHandler="billingProfileHandler"
      @formSubmitted="billingProfileFormSubmitted"
      @modalClosed="billingModalClosed"
      @resetForm="$emit('resetBillingForm')"
    />

    <StopFreeTrialModal
      v-if="freeTrialHandler"
      :formHandler="freeTrialHandler"
      @formSubmitted="$emit('stopFreeTrial')"
      @modalClosed="$emit('freeTrialModalClosed')"
    />
  </main>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import BillingCard from "@/components/parts/billing/showBilling/BillingCard.vue";
import BillingFormModal from "@/components/parts/billing/showBilling/BillingFormModal.vue";
import C3BillingCard from "@/components/parts/billing/showBilling/C3BillingCard.vue";
import StopFreeTrialCard from "@/components/parts/billing/showBilling/StopFreeTrialCard.vue";
import StopFreeTrialModal from "@/components/parts/billing/showBilling/StopFreeTrialModal.vue";
import { isC3Customer } from "@/lib/utils/billing-profile";

export default defineComponent({
  name: "Billing",
  components: {
    C3BillingCard,
    BillingCard,
    StopFreeTrialCard,
    BillingFormModal,
    StopFreeTrialModal,
  },
  props: {
    user: Object,
    billingProfile: Object,
    billingProfileHandler: Object,
    freeTrialHandler: Object,
  },
  emits: [
    "billingModalClosed",
    "billingModalOpened",
    "billingProfileFormSubmitted",
    "freeTrialModalClosed",
    "freeTrialModalOpened",
    "resetBillingForm",
    "stopFreeTrial",
  ],
  computed: {
    inFreeTrial() {
      const freeTrialEndsAt = DateTime.fromISO(this.user.free_trial_end_time);

      return freeTrialEndsAt.diffNow().as("seconds") > 0;
    },
  },
  methods: {
    isC3Customer,
    billingModalOpened(eventArgs) {
      this.$emit("billingModalOpened", eventArgs);
    },
    billingModalClosed(eventArgs) {
      this.$emit("billingModalClosed", eventArgs);
    },
    billingProfileFormSubmitted(eventArgs) {
      this.$emit("billingProfileFormSubmitted", eventArgs);
    },
  },
});
</script>
