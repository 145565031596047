export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic informations"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the name and the target(s) of your notifier"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])},
        "collaborators": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose collaborators to notify"])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a collaborator..."])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All collaborators have been added."])}
        },
        "extraEmail": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other emails to notify"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add email"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations basiques"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez le nom et les cibles de votre notifieur"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])},
        "collaborators": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les collaborateurs à notifier"])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un collaborateur..."])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les collaborators ont été ajoutés."])}
        },
        "extraEmail": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres adresses emails à notifier"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter l'adresse"])}
        }
      }
    }
  })
}
