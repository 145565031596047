export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected as"])},
        "title": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with GitHub"])},
          "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with GitLab"])}
        },
        "actions": {
          "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
          "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
          "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])}
        }
      },
      "fr": {
        "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté en tant que"])},
        "title": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter a github.com"])},
          "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter a gitlab.com"])}
        },
        "actions": {
          "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
          "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler"])},
          "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
        }
      }
    }
  })
}
