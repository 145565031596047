<template>
  <div>
    <div v-if="hasBackupInPlan">
      <EmptyCardState>
        <template #icon>
          <BackupGlyph class="h-8 text-scale-5"></BackupGlyph>
        </template>
        <div class="flex flex-col text-center mt-1">
          <div class="text-lg font-bold text-scale-9">
            {{ $t("noBackupTitle") }}
          </div>
          <div class="mt-3 font-medium">
            <div>{{ $t("text") }}</div>
            <div>{{ $t("text2") }}</div>
          </div>
          <div class="mt-4 flex flex-row space-x-4 justify-center">
            <SCButton @click="$emit('requestMakeManualBackup')">
              {{ $t("manualBackup") }}
            </SCButton>
          </div>
        </div>
      </EmptyCardState>
    </div>
    <div v-else>
      <EmptyCardState>
        <template #icon>
          <BackupGlyph class="h-8 text-scale-5"></BackupGlyph>
        </template>
        <div class="flex flex-col text-center mt-1">
          <div class="text-lg font-bold text-scale-9">
            {{ $t("noPaidPlanTitle") }}
          </div>
          <div class="mt-3 font-medium">
            <div>{{ $t("text") }}</div>
            <div>{{ $t("text2") }}</div>
          </div>
          <div class="mt-4 flex flex-row space-x-4 justify-center">
            <router-link :to="changePlanRouterArgs">
              <SCButton kind="primary">{{ $t("upgradeButton") }}</SCButton>
            </router-link>
            <SCButton
              ><DocLink :href="docLinks[addonProviderId]"
                >{{ $t("learnMore") }}
              </DocLink></SCButton
            >
          </div>
        </div>
      </EmptyCardState>
    </div>
  </div>
</template>
<script>
import BackupGlyph from "@/components/atoms/glyphs/BackupGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import { Routes } from "@/router/names";

export default {
  name: "EmptyState",
  components: { DocLink, SCButton, BackupGlyph, EmptyCardState },
  props: {
    app: Object,
    addon: Object,
    dbPlan: Object,
    addonProviderId: String,
  },
  data() {
    return {
      docLinks: {
        redis: "https://doc.scalingo.com/databases/redis/start#backups",
      },
    };
  },
  computed: {
    hasBackupInPlan() {
      return this.dbPlan.promiseInfo.isSuccess && this.dbPlan.item?.backup;
    },
    changePlanRouterArgs() {
      return {
        name: Routes.App.Addon.Edition.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          addonId: this.addon?.id,
        },
      };
    },
  },
};
</script>

<i18n>
en:
  noPaidPlanTitle: "Upgrade to a Starter or Business plan to get backups"
  text: "Backups allows you to create a manual or scheduled copy of your data."
  text2: "All backups are stored on our platform and can be either restored or downloaded."
  upgradeButton: "Upgrade plan"
  learnMore: "Learn more"
  noBackupTitle: "Trigger your first backup"
  manualBackup: "Trigger manual backup"
fr:
  noPaidPlanTitle: "Passez à un plan Starter ou Business pour disposer de backups"
  text: "Les backups vous permettent de créer une copie manuelle ou planifiée de vos données."
  text2: "Tous les backups sont stockés sur notre plateforme et peuvent être restaurés ou téléchargés."
  upgradeButton: "Changer de plan"
  learnMore: "En savoir plus"
  noBackupTitle: "Déclenchez votre premier backup"
  manualBackup: "Déclencher un backup manuel"
</i18n>
