import { Variable } from "scalingo/lib/models/regional";
import { AddonProvider, Container, Plan } from "scalingo/lib/models/regional";
import { CreateParams as CreateAppParams } from "scalingo/lib/params/regional/apps";
import { CreateParams as CreateDeploymentParams } from "scalingo/lib/params/regional/deployments";
import { reactive } from "vue";

import { ocdStep1 } from "@/lib/one-click-deploy/step1";
import { ocdStep2 } from "@/lib/one-click-deploy/step2";
import { ocdStep3 } from "@/lib/one-click-deploy/step3";
import { ocdStep4 } from "@/lib/one-click-deploy/step4";
import { ocdStep5 } from "@/lib/one-click-deploy/step5";
import { promiseInfo } from "@/lib/promises/info";
import { useSessionStore } from "@/stores/session";

type AppCreateParams = CreateAppParams & { region: string };
type VariableWithoutId = Pick<Variable, "name" | "value">;
type AddonData = {
  addon_provider: AddonProvider;
  plan: Plan;
};

export function performOneClickDeploy(
  manifestData: { deploymentPayload: CreateDeploymentParams },
  appParams: AppCreateParams,
  formation: Container[],
  variables: VariableWithoutId[],
  addons: AddonData[],
) {
  const session = useSessionStore();
  const c = session.client(appParams.region);

  const promise1 = ocdStep1(c, appParams, formation);
  const promise2 = promise1.then((app) => ocdStep2(c, app, variables));
  const promise3 = promise2.then((app) => ocdStep3(c, app, addons));
  const promise4 = promise3.then(({ app, addons }) => ocdStep4(c, app, addons));
  const promise5 = promise4.then((app) => ocdStep5(c, app, manifestData));

  const statuses = reactive({
    app: promiseInfo(promise1),
    variables: promiseInfo(promise2),
    addons: promiseInfo(promise3),
    provisionning: promiseInfo(promise4),
    deployment: promiseInfo(promise5),
  });

  return { statuses, promise: promise5 };
}
