export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TFA enabled"])},
        "named": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider: "])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TFA activé"])},
        "named": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur :"])}
      }
    }
  })
}
