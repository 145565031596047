export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop free trial"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop free trial"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some features (having many apps, using review apps, etc) are only available to accounts that are out of free trial."])},
        "noPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to add "])},
        "add": {
          "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a payment method"])},
          "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or "])},
          "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a voucher"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre fin a la période d'essai"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre fin a la période d'essai"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines fonctionnalités (avoir de nombreuses applications, utiliser des review apps, etc.) sont disponibles uniquement pour les comptes qui ne sont plus en période d'essai."])},
        "noPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez d'abord ajouter "])},
        "add": {
          "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un moyen de paiement"])},
          "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ou "])},
          "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un voucher"])}
        }
      }
    }
  })
}
