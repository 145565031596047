<template>
  <div class="container mx-auto flex pv:flex-grow items-center px-4">
    <div>
      <div class="flex flex-col">
        <div class="text-5xl font-bold leading-none">
          <div class="text-primary-2">404</div>
          <div>{{ $t("title") }}</div>
        </div>
        <div class="font-medium text-scale-6 py-10">{{ $t("text") }}</div>
        <SCButton :to="routeArgs" size="lg" kind="primary">
          {{ $t("button") }}
        </SCButton>
      </div>
    </div>
    <div class="hidden pv:flex justify-center flex-grow">
      <HandKeyGraphic />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import HandKeyGraphic from "@/components/atoms/graphics/HandKeyGraphic.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "NoFoundError",
  components: { HandKeyGraphic, SCButton },
  data() {
    return {
      routeArgs: {
        name: Routes.Welcome,
        args: {},
      },
    };
  },
});
</script>

<i18n>
en:
  title: "Something's gone missing ..."
  text: "We couldn't find the page you're looking for. You may want to visit our home page instead."
  button: "Go home"
fr:
  title: "Quelque chose manque ..."
  text: "Nous n'avons pas trouvé la page que vous cherchez. Nous vous proposons de retourner à la page d'accueil."
  button: "Aller à la page d'accueil"
</i18n>
