import { SCMIntegration } from "scalingo/lib/models/auth";
import { CreateParams } from "scalingo/lib/params/auth/scm_integrations";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  HANDLE_FETCH,
  DESTROY,
  HANDLE_OPERATION,
  CREATE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, DELETE, ADD } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class SCMIntegrationsStore extends CollectionStore<SCMIntegration> {
  actions = CollectionStore.buildActions<SCMIntegration>({
    [REFRESH](context) {
      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context).SCMIntegrations.all(),
        resolveAction: SET_ALL,
      });
    },
    [CREATE](context, payload) {
      const promise = scalingoClient(context).SCMIntegrations.create(payload);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: ADD,
      });
    },
    [DESTROY](context, scmIntegration) {
      const promise = scalingoClient(context).SCMIntegrations.destroy(
        scmIntegration.id,
      );

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: () => context.commit(DELETE, scmIntegration),
      });
    },
  });
  mutations = CollectionStore.buildMutations<SCMIntegration>();
  getters = CollectionStore.buildGetters<SCMIntegration>();
}

export const SCMIntegrations = buildMapping(
  new SCMIntegrationsStore(),
  "scmIntegrations",
);

export function listSCMIntegrations(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<SCMIntegration>>,
): CollectionWithFetch<SCMIntegration> {
  return {
    items: listItems(store.getters[SCMIntegrations.getters.ALL], opts),
    latestFetch: store.getters[SCMIntegrations.getters.LATEST_FETCH],
  };
}

export function ensureSCMIntegrations(store: ApplicationStore): void {
  store.dispatch(SCMIntegrations.actions.ENSURE);
}

export function createSCMIntegration(
  store: ApplicationStore,
  payload: CreateParams,
): Promise<RemoteOperation<SCMIntegration>> {
  return store.dispatch(SCMIntegrations.actions.CREATE, payload);
}

export function destroySCMIntegration(
  store: ApplicationStore,
  scmIntegration: SCMIntegration,
): Promise<RemoteOperation<void>> {
  return store.dispatch(SCMIntegrations.actions.DESTROY, scmIntegration);
}
