export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In order to create HDH apps, you need to define a point of contact who is able to designate a health professional if necessary: ", _interpolate(_named("link"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article 10. CLIENT'S commitments"])}
      },
      "fr": {
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afin de pouvoir créer des applications HDS, vous devez définir un point de contact étant en mesure de désigner un professionnel de santé lorsque cela est nécessaire : ", _interpolate(_named("link"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article 10. Obligations du Client"])}
      }
    }
  })
}
