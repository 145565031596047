import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    $ta(model: string, attrName: string): string {
      const modelAttr = `attributes.${model}.${attrName}`;
      const defaultAttr = `attributes.defaults.${attrName}`;

      let result = this.$t(modelAttr).toString();

      if (result === modelAttr) {
        result = this.$t(defaultAttr).toString();
      }

      if (result === defaultAttr) {
        // The translation was not found :'(
        // Fallback to something a bit readable and send an error
        this.$rollbar.error(
          `model translation "attributes.${model}.${attrName}" not found`,
          {
            model: model,
            attrName: attrName,
          },
        );
      }

      return result;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $tak(model: string, attrName: string, keys: any): string {
      const modelAttr = `attributes.${model}.${attrName}`;
      const defaultAttr = `attributes.defaults.${attrName}`;

      let result = this.$t(modelAttr, keys).toString();

      if (result === modelAttr) {
        result = this.$t(defaultAttr, keys).toString();
      }

      if (result === defaultAttr) {
        // The translation was not found :'(
        // Fallback to something a bit readable and send an error
        this.$rollbar.error(
          `model translation "attributes.${model}.${attrName}" not found`,
          {
            model: model,
            attrName: attrName,
          },
        );
      }

      return result;
    },
  },
});
