<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM12 7.33333L10.8047 6.138L6.138 10.8047L7.33333 12H4V8.66667L5.19533 9.862L9.862 5.19533L8.66667 4H12V7.33333Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AutoscalerGlyph",
});
</script>
