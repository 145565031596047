<template>
  <ViewComponent :app="app"></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/alerts/Root.vue";

export default defineComponent({
  name: "AlertsRootContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
});
</script>
