<template>
  <svg
    width="28"
    height="36"
    viewBox="0 0 28 36"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6667 1.99984L22.0001 0.666504L19.3334 1.99984L16.6667 0.666504L14.0001 1.99984L11.3334 0.666504L8.66675 1.99984L6.00008 0.666504L3.33341 1.99984L0.666748 0.666504V32.6665C0.666748 34.1398 1.86008 35.3332 3.33341 35.3332H24.6667C26.1401 35.3332 27.3334 34.1398 27.3334 32.6665V0.666504L24.6667 1.99984ZM15.3334 28.6665H7.33341C6.59741 28.6665 6.00008 28.0692 6.00008 27.3332C6.00008 26.5972 6.59741 25.9998 7.33341 25.9998H15.3334C16.0694 25.9998 16.6667 26.5972 16.6667 27.3332C16.6667 28.0692 16.0694 28.6665 15.3334 28.6665ZM6.00008 21.9998C6.00008 21.2638 6.59741 20.6665 7.33341 20.6665H12.6667C13.4027 20.6665 14.0001 21.2638 14.0001 21.9998C14.0001 22.7358 13.4027 23.3332 12.6667 23.3332H7.33341C6.59741 23.3332 6.00008 22.7358 6.00008 21.9998ZM15.3334 17.9998H7.33341C6.59741 17.9998 6.00008 17.4025 6.00008 16.6665C6.00008 15.9305 6.59741 15.3332 7.33341 15.3332H15.3334C16.0694 15.3332 16.6667 15.9305 16.6667 16.6665C16.6667 17.4025 16.0694 17.9998 15.3334 17.9998ZM20.6667 28.6665C19.9307 28.6665 19.3334 28.0692 19.3334 27.3332C19.3334 26.5972 19.9307 25.9998 20.6667 25.9998C21.4027 25.9998 22.0001 26.5972 22.0001 27.3332C22.0001 28.0692 21.4027 28.6665 20.6667 28.6665ZM20.6667 23.3332C19.9307 23.3332 19.3334 22.7358 19.3334 21.9998C19.3334 21.2638 19.9307 20.6665 20.6667 20.6665C21.4027 20.6665 22.0001 21.2638 22.0001 21.9998C22.0001 22.7358 21.4027 23.3332 20.6667 23.3332ZM20.6667 17.9998C19.9307 17.9998 19.3334 17.4025 19.3334 16.6665C19.3334 15.9305 19.9307 15.3332 20.6667 15.3332C21.4027 15.3332 22.0001 15.9305 22.0001 16.6665C22.0001 17.4025 21.4027 17.9998 20.6667 17.9998ZM20.6667 9.99984H7.33341C6.59741 9.99984 6.00008 9.4025 6.00008 8.6665C6.00008 7.9305 6.59741 7.33317 7.33341 7.33317H20.6667C21.4027 7.33317 22.0001 7.9305 22.0001 8.6665C22.0001 9.4025 21.4027 9.99984 20.6667 9.99984Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BillGlyph",
});
</script>
