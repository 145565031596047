<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <AppCrashGlyph class="h-6 w-6 text-color-2"></AppCrashGlyph>
      <div class="text-color-2 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      Container:
      <span class="font-medium ml-1">{{
        event.type_data.container_type
      }}</span></template
    >
    <template #body>
      <div
        class="theme-light font-mono flex flex-col bg-scale-10 text-primary-4 px-2 py-1 rounded overflow-scroll border-scale-9 border"
      >
        <SmartLogLine
          v-for="(line, key) in logs"
          :key="key"
          :line="line"
        ></SmartLogLine>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AppCrashGlyph from "@/components/atoms/glyphs/AppCrashGlyph.vue";
import SmartLogLine from "@/components/parts/app/showLogs/SmartLogLine.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "CrashEvent",
  components: { SmartLogLine, AppCrashGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logs() {
      return this.event.type_data.crash_logs.split("\n");
    },
  },
});
</script>

<i18n>
en:
  title: "Container crashed"
fr:
  title: "Container crash"
</i18n>
