import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { ref } from "vue";

import {
  useResourceStore,
  toPublicStore,
} from "@/lib/pinia/use-resource-store";
import { useSessionStore } from "@/stores/session";

export const useUsageStore = defineStore("usage", () => {
  const { privateClient } = useSessionStore();

  const from = ref<DateTime | undefined>(undefined);
  const to = ref<DateTime | undefined>(undefined);

  const store = useResourceStore({
    fetchPromise: () => privateClient().Usage.for(from.value, to.value),
  });

  const setPeriod = function (
    fromPeriod: DateTime,
    toPeriod: DateTime,
    doFetch: boolean,
  ) {
    from.value = fromPeriod;
    to.value = toPeriod;

    if (doFetch) {
      return store.fetch();
    }
  };

  return toPublicStore(store, { setPeriod });
});
