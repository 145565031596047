<template>
  <div
    v-if="warnings.length > 0"
    class="bg-scale-0 rounded px-8 py-6 flex flex-col"
  >
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')" />
    <div class="divide-y divide-scale-2">
      <div v-for="(warning, key) in warnings" :key="key" class="py-2 flex">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row items-center space-x-2">
            <div>
              <CircleExclamationGlyph
                v-if="warning.kind === 'ko'"
                class="text-error"
              />
              <DangerSignGlyph
                v-if="warning.kind === 'warning'"
                class="text-warning"
              />
            </div>
            <span>{{ $t("warnings." + warning.key, warning.data) }}</span>
          </div>
          <div class="flex flex-row space-x-2">
            <router-link
              v-if="
                warning.category === 'swap' ||
                warning.category === 'cpu' ||
                warning.category === 'memory'
              "
              :to="metricsRouterArgs"
            >
              <SCButton kind="neutral">{{ $t("buttons.metrics") }}</SCButton>
            </router-link>
            <router-link
              v-if="warning.key === 'disk.warning'"
              :to="changePlanRouterArgs"
            >
              <SCButton kind="neutral">
                {{ $t("buttons.upgrade") }}
              </SCButton>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CircleExclamationGlyph from "@/components/atoms/glyphs/CircleExclamationGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import {
  displaySwapStatus,
  displayCPUStatus,
  displayDiskStatus,
  displayMemoryStatus,
  displayConnectionsStatus,
} from "@/lib/utils/databaseLimits";
import { humanReadableSize } from "@/lib/utils/size";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "WarningsCard",
  components: {
    SCButton,
    DangerSignGlyph,
    CircleExclamationGlyph,
    CardHeader,
  },
  props: {
    app: Object,
    db: Object,
    dbPlan: Object,
    dbMetrics: Object,
    addon: Object,
  },
  computed: {
    changePlanRouterArgs() {
      return {
        name: Routes.App.Addon.Edition.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          addonId: this.addon?.id,
        },
      };
    },
    metricsRouterArgs() {
      return {
        name: Routes.Db.Metrics,
        params: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
    swapStatus() {
      return this.displaySwapStatus(
        this.db.type_name,
        this.dbMemoryMetrics.swap_limit,
        this.dbMemoryMetrics.swap,
      );
    },
    cpuStatus() {
      return this.displayCPUStatus(
        this.db.type_name,
        this.dbMetrics.item.cpu_usage,
      );
    },
    memoryStatus() {
      return this.displayMemoryStatus(
        this.db.type_name,
        this.dbMemoryMetrics.memory_limit,
        this.dbMemoryMetrics.memory,
      );
    },
    diskStatus() {
      return this.displayDiskStatus(
        this.db.type_name,
        this.dbPlan.item.disk,
        this.dbMetrics.item.real_disk_size,
      );
    },
    connectionsStatus() {
      return this.displayConnectionsStatus(
        this.db.type_name,
        this.dbStats.max_connections,
        this.dbStats.current_connections,
      );
    },
    warnings() {
      let warnings = [];
      this.swapWarnings(warnings);
      this.cpuWarnings(warnings);
      this.memoryWarnings(warnings);
      this.diskWarnings(warnings);
      this.connectionsWarnings(warnings);

      return warnings;
    },
    dbMemoryMetrics() {
      return this.dbMetrics.item.memory;
    },
    dbStats() {
      return this.dbMetrics.item?.database_stats;
    },
  },
  methods: {
    displaySwapStatus,
    displayCPUStatus,
    displayDiskStatus,
    displayMemoryStatus,
    displayConnectionsStatus,

    swapWarnings(warnings) {
      const value = this.dbMemoryMetrics.swap;

      const humanised =
        humanReadableSize(this.dbMemoryMetrics.swap) +
        " / " +
        humanReadableSize(this.dbMemoryMetrics.swap_limit);

      if (this.swapStatus == "ko") {
        warnings.push({
          key: "swap.ko",
          kind: "ko",
          category: "swap",
          data: { value: value, humanised: humanised },
        });
        return;
      }
      if (this.swapStatus == "warning") {
        warnings.push({
          key: "swap.warning",
          kind: "warning",
          category: "swap",
          data: { value: value, humanised: humanised },
        });
      }
    },
    cpuWarnings(warnings) {
      const value = this.dbMetrics.item.cpu_usage;
      if (this.cpuStatus == "ko") {
        warnings.push({
          key: "cpu.ko",
          kind: "ko",
          category: "cpu",
          data: { value: value },
        });
        return;
      }
      if (this.cpuStatus == "warning") {
        warnings.push({
          key: "cpu.warning",
          kind: "warning",
          category: "cpu",
          data: { value: value },
        });
      }
    },
    memoryWarnings(warnings) {
      const value = this.dbMemoryMetrics.memory;

      const humanised =
        humanReadableSize(this.dbMemoryMetrics.memory) +
        " / " +
        humanReadableSize(this.dbMemoryMetrics.memory_limit);

      if (this.memoryStatus == "ko") {
        warnings.push({
          key: "memory.ko",
          kind: "ko",
          category: "memory",
          data: { value: value, humanised: humanised },
        });
        return;
      }
      if (this.memoryStatus == "warning") {
        warnings.push({
          key: "memory.warning",
          kind: "warning",
          category: "memory",
          data: { value: value, humanised: humanised },
        });
      }
    },
    diskWarnings(warnings) {
      const value = this.dbMetrics.item.real_disk_size;
      if (this.diskStatus == "warning") {
        warnings.push({
          key: "disk.warning",
          kind: "warning",
          category: "disk",
          data: { value: value },
        });
      }
    },
    connectionsWarnings(warnings) {
      const value = this.dbMetrics.item.database_stats.current_connections;
      const humanised =
        this.dbStats.current_connections + " / " + this.dbStats.max_connections;

      if (this.connectionsStatus == "ko") {
        warnings.push({
          key: "connections.ko",
          kind: "ko",
          category: "connections",
          data: { value: value, humanised: humanised },
        });
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Scalingo Advisor"
  subtitle: "Insights and recommendations to optimize this database operations."
  warnings:
    swap:
      warning: "Swap level is high ({humanised}). Please check your memory and swap consumption."
      ko: "Swap level is very high ({humanised}). This could affect your database performance."
    cpu:
      warning: "CPU level is high ({value}%). This could affect your database performance."
      ko: "CPU level is very high ({value}%). This could affect your database performance."
    memory:
      warning: "Memory level is high ({humanised}). This could affect your database performance."
      ko: "Memory level is very high ({humanised}). This could affect your database performance."
    connections:
      ko: "The number of available connections is close to the plan limit ({humanised}). If connections are frequently refused, you may want to consider upgrading to a higher plan."
    disk:
      warning: "Prolonged usage exceeding plan storage may incur additional fees."
  buttons:
    upgrade: "Change plan"
    metrics: "View metrics"
fr:
  title: "Scalingo Advisor"
  subtitle: "Conseils et recommandations pour optimiser le fonctionnement de cette base de donnée."
  warnings:
    swap:
      warning: "Le niveau de swap est élevé ({humanised}). Veuillez vérifier votre consommation de mémoire et de swap."
      ko: "Le niveau de swap est très élevé ({humanised}). Cela pourrait altérer les performances de votre base de données."
    cpu:
      warning: "Le niveau de CPU est élevé ({value}%). Cela pourrait altérer les performances de votre base de données."
      ko: "Le niveau de CPU est très élevé ({value}%). Cela pourrait altérer les performances de votre base de données."
    memory:
      warning: "Le niveau de mémoire est élevé ({humanised}%). Cela pourrait altérer les performances de votre base de données."
      ko: "Le niveau de mémoire est très élevé ({humanised}%). Cela pourrait altérer les performances de votre base de données."
    connections:
      ko: "Le nombre de connexions disponibles est proche de la limite du plan ({humanised}). Si des connexions sont régulièrement refusées, vous pouvez envisager de passer à un plan supérieur."
    disk:
      warning: "L’usage prolongé d’un stockage supérieur au plan peut entraîner des frais supplémentaires."
  buttons:
    upgrade: "Changer de plan"
    metrics: "Voir les métriques"
</i18n>
