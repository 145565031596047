import {
  addSignupRouteAndUtmsParams,
  Fallback,
  MiddlewareErrors,
  ROUTE_PATH,
} from "@/lib/auth/utils";

export class LoginOrAuthorize implements Fallback {
  name = "LoginOrAuthorize";
  responseType = "token";
  prompt = "none";
  oauthRedirectUri: string;

  constructor(
    readonly authBaseUrl: string,
    readonly oauthClientId: string,
  ) {
    this.oauthRedirectUri = window.location.origin;
  }

  get oauthLoginUrl(): string {
    return `${this.authBaseUrl}/oauth/authorize?client_id=${this.oauthClientId}&response_type=${this.responseType}&prompt=${this.prompt}&redirect_uri=${this.oauthRedirectUri}`;
  }

  authorize(): void {
    sessionStorage.setItem(ROUTE_PATH, window.location.pathname);
    window.location.replace(this.oauthLoginUrl);
  }

  login(): void {
    sessionStorage.setItem(ROUTE_PATH, window.location.pathname);
    window.location.assign(this.authBaseUrl + addSignupRouteAndUtmsParams());
  }

  // Not sure about this one.
  // I know we need to do a login on certain error messages,
  // but not sure when we need to do an authorization.
  call(errors: MiddlewareErrors): void {
    const errorCallback = errors["LoginFromOAuthCallback"];
    const errorAssisted = errors["LoginFromOAuthAssisted"];

    if (errorCallback?.error == "login_required") {
      this.login();
    } else if (errorAssisted?.error == "interaction_required") {
      this.login();
    } else {
      this.authorize();
    }
  }
}
