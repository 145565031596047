<template>
  <SCCard :title="$t('title')" :subtitle="$t('subtitle')">
    <div v-for="contract in contracts.items" :key="contract.id" class="mb-4">
      <template v-if="answered(contract)">
        <AnsweredContractInfos :contract="contract" />

        <ContractDocuments :documents="contract.documents" />
      </template>
      <template v-else>
        <OptionalContractInfos
          :contract="contract"
          @startContractAcceptance="(e) => $emit('startContractAcceptance', e)"
        />
      </template>
      <div class="mt-2">
        <p>
          {{ $t("hdsContractWarning1")
          }}<font-awesome-icon
            icon="staff-snake"
            class="text-success ml-1 mr-1"
          />
          {{ $t("hdsContractWarning2") }}
        </p>
      </div>
    </div>
  </SCCard>
</template>

<script>
import { defineComponent } from "vue";

import SCCard from "@/components/molecules/card/SCCard.vue";
import AnsweredContractInfos from "@/components/parts/account/contracts/AnsweredContractInfos.vue";
import ContractDocuments from "@/components/parts/account/contracts/ContractDocuments.vue";
import OptionalContractInfos from "@/components/parts/account/contracts/OptionalContractInfos.vue";

export default defineComponent({
  name: "HDSContractsList",
  components: {
    SCCard,
    ContractDocuments,
    AnsweredContractInfos,
    OptionalContractInfos,
  },
  props: {
    contracts: Object,
  },
  emits: ["startContractAcceptance"],
  data() {
    return {};
  },
  methods: {
    answered(c) {
      return c.accepted || c.refused;
    },
  },
});
</script>

<i18n>
en:
  title: "HDH Contracts & documents"
  subtitle: "Contractual documents relative to health data hosting"
  showAllContracts: "List older contracts"
  hdsContractWarning1: "Please note that only applications that are identified as HDH"
  hdsContractWarning2: "and their add-ons are covered by the HDH contract."
fr:
  title: "Contract et documents HDS"
  subtitle: "Documents contractuels relatifs à l'hébergement de données de santé"
  showAllContracts: "Montrer mes anciens contrats"
  hdsContractWarning1: "Seules les applications identifiées comme HDS"
  hdsContractWarning2: "et leurs add-ons sont couvertes par le contrat HDS."
</i18n>
