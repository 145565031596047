<template>
  <section>
    <BackLinkButton class="my-2" :routeArgs="domainsIndex">
      {{ $t("backToDomains") }}
    </BackLinkButton>

    <template v-if="domain">
      <DomainCard :app="app" :domain="domain" />

      <CertificatesCard
        :app="app"
        :domain="domain"
        :domainSSLHandler="domainSSLHandler"
        class="mt-4"
        @startCertificateDeletion="(e) => $emit('startCertificateDeletion', e)"
        @submitCertificate="(e) => $emit('submitCertificate', e)"
      />
    </template>

    <ResetCertificateModal
      v-if="deleteCertificateHandler"
      :formHandler="deleteCertificateHandler"
      @modalClosed="(e) => $emit('cancelCertificateDeletion', e)"
      @warningAccepted="(e) => $emit('submitCertificateDeletion', e)"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import CertificatesCard from "@/components/parts/app/domains/CertificatesCard.vue";
import DomainCard from "@/components/parts/app/domains/DomainCard.vue";
import ResetCertificateModal from "@/components/parts/app/domains/ResetCertificateModal.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DomainsIndex",
  components: {
    BackLinkButton,
    DomainCard,
    CertificatesCard,
    ResetCertificateModal,
  },
  props: {
    app: Object,
    domain: Object,
    domainSSLHandler: Object,
    deleteCertificateHandler: Object,
  },
  emits: [
    "cancelCertificateDeletion",
    "startCertificateDeletion",
    "submitCertificate",
    "submitCertificateDeletion",
  ],
  data() {
    return {
      domainsIndex: {
        name: Routes.App.Domains.Root,
      },
    };
  },
});
</script>

<i18n>
en:
  backToDomains: "Go back to the list of domain names"
fr:
  backToDomains: "Retourner a la liste des noms de domaines"
</i18n>
