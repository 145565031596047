export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing month"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
          "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
        },
        "seeInvoiceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See invoice details"])},
        "downloadInvoicePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download invoice (PDF)"])}
      },
      "fr": {
        "headers": {
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois facturé"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
        },
        "seeInvoiceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la facture en détails"])},
        "downloadInvoicePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger facture (PDF)"])}
      }
    }
  })
}
