import { Notifier } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { updateNotifier } from "@/store/notifiers";

import type { ComponentPublicInstance } from "vue";

export class EditNotifierActivityHandler extends ActionHandler<Notifier> {
  get keyPath(): string {
    return this.notifier.active ? "notifiers.disable" : "notifiers.enable";
  }

  constructor(
    component: ComponentPublicInstance,
    readonly notifier: Notifier,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.notifier.name });
    });
    this.on("failure", () => {
      this.notify("error", { name: this.notifier.name });
    });
  }

  async submit(active: boolean): Promise<void> {
    this.follow(
      await updateNotifier(this.$store, this.notifier, {
        name: this.notifier.name,
        active,
        selected_event_ids: this.notifier.selected_event_ids,
      }),
    );
  }
}
