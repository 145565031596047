import {
  Notifier,
  Alert,
  AlertWithNotifiers,
} from "scalingo/lib/models/regional";
import { UpdateParams } from "scalingo/lib/params/regional/alerts";

import { FormHandler } from "@/lib/handlers/form";
import { App } from "@/lib/scalingo/modifiers/apps";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { updateAlert } from "@/store/alerts";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  notifiers: Notifier[];
}

export class EditAlertNotifiersHandler extends FormHandler<Alert> {
  keyPath = "alerts.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly alert: AlertWithNotifiers,
    readonly notifiers: Notifier[],
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    const notifiers = this.alert.notifiers
      .map((id) => {
        return this.notifiers.find((n) => n.id === id);
      })
      .filter((n) => !!n) as Notifier[];

    return {
      notifiers,
    };
  }

  dispatchEvents(): void {
    this.on("success", (alert) => {
      this.notify("success");

      this.$router.push({
        name: Routes.App.Alerts.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          alertId: alert.id,
        },
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    const payload = {
      notifiers: event.notifiers.map((n) => n.id),
    } as UpdateParams;

    this.follow(await updateAlert(this.$store, this.alert, payload));
  }
}
