import { DateTime } from "luxon";
import { App } from "scalingo/lib/models/regional";
import {
  Deployment,
  DeploymentsResult,
  STATUS_QUEUED,
  STATUS_BUILDING,
  STATUS_PUSHING,
  STATUS_STARTING,
  STATUS_SUCCESS,
  STATUS_CRASHED_ERROR,
  STATUS_TIMEOUT_ERROR,
  STATUS_BUILD_ERROR,
  STATUS_ABORTED,
  STATUS_HOOK_ERROR,
} from "scalingo/lib/models/regional/deployments";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  FETCH_MORE,
  HANDLE_FETCH,
  CREATE,
  HANDLE_OPERATION,
  FETCH,
  RESET,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ONGOING } from "@/lib/store/getter-types";
import { SET_ALL, SET_META, MERGE, ADD } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export const STATUSES_ONGOING = [
  STATUS_QUEUED,
  STATUS_BUILDING,
  STATUS_PUSHING,
  STATUS_STARTING,
];

export const STATUSES_SUCCESSES = [STATUS_SUCCESS];

export const STATUSES_FAILURES = [
  STATUS_CRASHED_ERROR,
  STATUS_TIMEOUT_ERROR,
  STATUS_BUILD_ERROR,
  STATUS_HOOK_ERROR,
  STATUS_ABORTED,
];

export class DeploymentsStore extends CollectionStore<Deployment> {
  actions = CollectionStore.buildActions<Deployment>({
    [REFRESH](context, opts = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Deployments.for(
          currentApp.id,
          opts,
        ),
        resolveAction: function (resolved: DeploymentsResult) {
          context.commit(SET_ALL, resolved.deployments);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH_MORE](context) {
      const currentApp = useCurrentAppStore().regional as App;

      if (!context.state.meta.pagination?.next_page) {
        return;
      }

      const opts = { page: context.state.meta.pagination.next_page };

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Deployments.for(
          currentApp.id,
          opts,
        ),
        resolveAction: function (resolved: DeploymentsResult) {
          context.commit(MERGE, resolved.deployments);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH](context, id) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Deployments.find(
          currentApp.id,
          id,
        ),
        resolveAction: ADD,
      });
    },
    [CREATE](context, opts = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).SCMRepoLinks.manualDeploy(currentApp.id, opts.branch),
        resolveAction: ADD,
      });
    },
    [RESET](context) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).Deployments.purgeCache(currentApp.id),
      });
    },
  });

  mutations = CollectionStore.buildMutations<Deployment>();
  getters = CollectionStore.buildGetters<Deployment>({
    [ONGOING](state) {
      const items = (state.values || []) as Deployment[];

      return items.filter((deployment) => {
        // If the deployment is older than 2 hours, we do not display the banner
        if (
          DateTime.fromISO(deployment.created_at).plus({ hours: 2 }) <
          DateTime.now()
        ) {
          return false;
        }

        return STATUSES_ONGOING.includes(deployment.status);
      });
    },
  });
}

export const Deployments = buildMapping(new DeploymentsStore(), "deployments");

export function listDeployments(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Deployment>>,
): CollectionWithFetch<Deployment> {
  return {
    items: listItems(store.getters[Deployments.getters.ALL], opts),
    latestFetch: store.getters[Deployments.getters.LATEST_FETCH],
    meta: store.getters[Deployments.getters.META],
  };
}

export function deploymentsInProgress(store: ApplicationStore): Deployment[] {
  return store.getters[Deployments.getters.ONGOING];
}

export function findDeployment(
  store: ApplicationStore,
  id: string,
): Deployment {
  return store.getters[Deployments.getters.FIND](id);
}

export function ensureDeployments(store: ApplicationStore): void {
  store.dispatch(Deployments.actions.ENSURE);
}

export function ensureDeployment(store: ApplicationStore, id: string): void {
  store.dispatch(Deployments.actions.ENSURE_ONE, id);
}

export function fetchMoreDeployments(store: ApplicationStore): void {
  store.dispatch(Deployments.actions.FETCH_MORE);
}

export function resetDeploymentCache(
  store: ApplicationStore,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Deployments.actions.RESET);
}

export function triggerDeploymentFromBranch(
  store: ApplicationStore,
  branch: string,
): Promise<RemoteOperation<Deployment>> {
  return store.dispatch(Deployments.actions.CREATE, { branch });
}
