<template>
  <DbNotRunning
    v-if="db.status !== 'running'"
    :app="app"
    :db="db"
    :withCartridge="false"
  />
  <router-view :app="app" />
</template>

<script>
import { defineComponent } from "vue";

import DbNotRunning from "@/components/views/db/DbNotRunning.vue";

export default defineComponent({
  name: "DbLogsRoot",
  components: { DbNotRunning },
  props: {
    app: {
      type: Object,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
  },
});
</script>
