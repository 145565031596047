import { AlertWithNotifiers } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { deleteAlert } from "@/store/alerts";

import type { ComponentPublicInstance } from "vue";

export class DeleteAlertHandler extends ActionHandler<void> {
  keyPath = "alerts.delete";

  constructor(
    component: ComponentPublicInstance,
    readonly alert: AlertWithNotifiers,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(await deleteAlert(this.$store, this.alert));
  }
}
