<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <div class="flex items-center">
        <GithubEditGlyph v-if="isGithub" class="text-scale-7"></GithubEditGlyph>
        <GitlabEditGlyph v-else class="text-scale-7"></GitlabEditGlyph>
        <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
      </div>
    </template>
    <template #more>
      {{ $t("repository") }}:
      <ExtLink :href="event.type_data.source" class="font-medium ml-1">
        {{ event.type_data.repo_name }}
      </ExtLink>
    </template>
    <template #body>
      <ScmBody :event="event" />
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import GithubEditGlyph from "@/components/atoms/glyphs/GithubEditGlyph.vue";
import GitlabEditGlyph from "@/components/atoms/glyphs/GitlabEditGlyph.vue";
import ExtLink from "@/components/molecules/links/ExtLink.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";
import ScmBody from "@/components/parts/events/ScmBody.vue";

export default defineComponent({
  name: "UpdateScmEvent",
  components: {
    ExtLink,
    ScmBody,
    GitlabEditGlyph,
    GithubEditGlyph,
    BaseEvent,
  },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isGithub() {
      const source = this.event.type_data.source;
      return !source || !!source.startsWith("https://github.com/");
    },
  },
});
</script>

<i18n>
en:
  title: "SCM integration updated"
  repository: "Repository"
fr:
  title: "Intégration SCM mise à jour"
  repository: "Dépôt"
</i18n>
