<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9962 6C12.9962 8.20914 11.2059 10 8.99762 10C6.78929 10 4.99908 8.20914 4.99908 6C4.99908 3.79086 6.78929 2 8.99762 2C11.2059 2 12.9962 3.79086 12.9962 6ZM10.9969 6C10.9969 4.89543 10.1018 4 8.99762 4C7.89345 4 6.99835 4.89543 6.99835 6C6.99835 7.10457 7.89345 8 8.99762 8C10.1018 8 10.9969 7.10457 10.9969 6ZM15.7451 14.42L16.9947 13.17V10C16.9947 9.44772 16.5471 9 15.9951 9C15.443 9 14.9954 9.44772 14.9954 10V13.5C15.3161 13.7414 15.5734 14.0571 15.7451 14.42ZM20.0336 11.9942C20.3001 11.9942 20.5556 12.1007 20.7433 12.29H20.7033C20.8926 12.4778 20.999 12.7334 20.999 13C20.999 13.2666 20.8926 13.5222 20.7033 13.71L15.1454 19.27C14.6752 19.7376 14.0391 20.0001 13.376 20H9.29751C9.03527 20.0011 8.78396 20.1053 8.59777 20.29L7.17829 21.71C6.78839 22.0977 6.1587 22.0977 5.7688 21.71L3.28971 19.23C2.90213 18.84 2.90213 18.21 3.28971 17.82L6.11867 14.91C6.81004 14.2472 7.75389 13.9155 8.70773 14H13.396C14.195 13.9769 14.8837 14.5582 14.9954 15.35C15.0903 16.1536 14.5267 16.8861 13.7259 17H13.496H9.49744C9.2214 17 8.99762 17.2239 8.99762 17.5C8.99762 17.7761 9.2214 18 9.49744 18H13.496C14.8418 18.0009 15.9466 16.9354 15.9951 15.59L19.3238 12.29C19.5115 12.1007 19.767 11.9942 20.0336 11.9942Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CreditAddGlyph",
});
</script>
