<template>
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.89453 5.39453L2.90234 3.40234C2.01758 2.51758 0.5 3.14453 0.5 4.39258V10.8438C0.5 11.623 1.12695 12.25 1.90625 12.25H8.35742C9.61133 12.25 10.2383 10.7324 9.35352 9.84766L7.54883 8.04297C9.58203 6.00977 12.3945 4.75 15.5 4.75C21.7109 4.75 26.75 9.78906 26.75 16C26.75 22.2109 21.7109 27.25 15.5 27.25C13.1094 27.25 10.8945 26.5059 9.07227 25.2344C8.22266 24.6426 7.05664 24.8477 6.45898 25.6973C5.86133 26.5469 6.07227 27.7129 6.92188 28.3105C9.35938 30.0039 12.3184 31 15.5 31C23.7852 31 30.5 24.2852 30.5 16C30.5 7.71484 23.7852 1 15.5 1C11.3574 1 7.60742 2.68164 4.89453 5.39453ZM15.5 8.5C14.7207 8.5 14.0938 9.12695 14.0938 9.90625V16C14.0938 16.375 14.2402 16.7324 14.5039 16.9961L18.7227 21.2148C19.2734 21.7656 20.1641 21.7656 20.709 21.2148C21.2539 20.6641 21.2598 19.7734 20.709 19.2285L16.9004 15.4199V9.90625C16.9004 9.12695 16.2734 8.5 15.4941 8.5H15.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackupGlyph",
});
</script>
