export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configure your ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the required environment variables"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configurez votre ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les variables d'environnement requises"])}
      }
    }
  })
}
