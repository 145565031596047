export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sticky Session"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User sessions will be sticky, they will always return to the same container."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sticky Session"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions persistantes"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sessions utilisateurs sont persistantes, elles renvoient toujours au même container."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions persistantes"])}
      }
    }
  })
}
