<template>
  <div class="flex flex-col">
    <div class="flex flex-col pv:flex-row">
      <div>
        <div
          class="flex flex-col pv:flex-row"
          :title="displayMoreTitle"
          :class="{ 'cursor-pointer hover:underline': displayButton }"
          @click="toggleBody"
        >
          <div class="flex">
            <slot name="name" />
          </div>
          <div
            v-if="displayAuthor && withAuthor"
            class="pv:pl-1 pl-8"
            :class="{ 'text-scale-6': isPlatformUser && long }"
          >
            {{ $t("generic.by") }}
            <span class="font-medium">{{ authorName }}</span>
          </div>

          <div v-if="displayAppName && withAppName" class="pv:pl-1 pl-8">
            {{ $t("generic.on") }}
            <router-link :to="appRoute" class="hover:underline">
              {{ event.app_name }}
            </router-link>
          </div>
        </div>
        <div
          v-if="long"
          class="flex text-sm text-scale-7 pt-2 flex-col pv:flex-row"
        >
          <div class="flex">
            <router-link
              v-if="withEventLink"
              :title="$t('navigateToEventPage')"
              :to="eventRoute"
              class="hover:underline"
            >
              {{ formatDateTime(event.created_at) }}
            </router-link>
            <div v-else>
              {{ formatDateTime(event.created_at) }}
            </div>
          </div>
          <div class="flex">
            <span v-if="hasMore" class="mx-1 hidden pv:flex">-</span>
            <slot name="more"></slot>
          </div>
        </div>
      </div>
      <div
        v-if="displayButton"
        class="flex-grow cursor-pointer h-0 ph:h-12"
        :title="displayMoreTitle"
        @click="toggleBody"
      ></div>
      <div
        v-if="displayButton"
        :title="$t('displayMore')"
        class="flex items-center justify-center"
      >
        <SCButton
          kind="neutral"
          class="h-8 w-8 pv:mt-0 mt-2 mr-2"
          @click="toggleBody"
        >
          <span v-if="!bodyOpened">+</span>
          <span v-if="bodyOpened">-</span>
        </SCButton>
      </div>
    </div>
    <div>
      <div v-if="bodyOpened" class="bg-scale-1 p-4 mt-4 break-words">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import { withAuthor, authorName } from "@/lib/events";
import { defaultRegion } from "@/lib/scalingo/client";
import { formatDateTime } from "@/lib/utils/time";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "BaseEvent",
  components: { SCButton },
  props: {
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
    button: { type: Boolean, default: false },
    event: Object,
  },
  emits: ["bodyClosed", "bodyOpened"],
  data() {
    return { bodyOpened: false };
  },
  computed: {
    withAuthor,
    authorName,
    hasMore() {
      return !!this.$slots.more;
    },
    displayButton() {
      return this.button && this.long;
    },
    displayMoreTitle() {
      if (!this.displayButton) {
        return;
      }
      if (this.bodyOpened === true) {
        return this.$t("hideInfo");
      }

      return this.$t("displayMore");
    },
    isPlatformUser() {
      const platformUsers = [
        "scalingo-platform-scm",
        "scalingo-platform",
        "scalingo-platform-cron",
        "scalingo-platform-autoscaler",
      ];

      return platformUsers.indexOf(this.authorName) > -1;
    },
    withAppName() {
      return !!this.event.app_name;
    },
    appRoute() {
      if (this.event.app_name) {
        return {
          name: Routes.App.Root,
          params: { region: defaultRegion.code, id: this.event.app_name },
        };
      } else {
        return {};
      }
    },
    eventRoute() {
      if (this.withEventLink && this.event.app_name) {
        return {
          name: Routes.App.Activity.Show,
          params: {
            region: this.event.app.region,
            id: this.event.app.name,
            eventId: this.event.id,
          },
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    formatDateTime,
    toggleBody() {
      if (!this.displayButton) {
        return;
      }

      if (this.bodyOpened === true) {
        this.bodyOpened = false;
        this.$emit("bodyClosed");
        return false;
      }
      this.bodyOpened = true;
      this.$emit("bodyOpened");
    },
  },
});
</script>

<i18n>
  en:
    displayMore: "Display more information about this event"
    hideInfo: "Hide information about this event"
    navigateToEventPage: "Navigate to the event dedicated page"
    unsupportedMode: "Unsupported mode"
    turnYourMobile: "Turn your phone in landscape mode"
  fr:
    displayMore: "Afficher plus d'information sur l'événement"
    hideInfo: "Masquer les informations de cet événement"
    navigateToEventPage: "Navigaguer à le page dédié de l'événement"
    unsupportedMode: "Mode non supporté"
    turnYourMobile: "Tournez votre mobile en mode paysage"
</i18n>
