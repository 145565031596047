import { BillingProfile as BillingProfileModel } from "scalingo/lib/models/billing";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  UPDATE,
  HANDLE_OPERATION,
  HANDLE_FETCH,
  CREATE,
} from "@/lib/store/action-types";
import { SET_ONE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { ResourceStore } from "@/lib/store/resource-store";
import {
  buildMapping,
  EnsureOptions,
  ResourceWithFetch,
} from "@/lib/store/utils";
import { ApplicationStore } from "@/store";

export class BillingProfileStore extends ResourceStore<BillingProfileModel> {
  actions = ResourceStore.buildActions<BillingProfileModel>({
    [REFRESH](context) {
      const promise = scalingoClient(context).Billing.profile();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [CREATE](context, payload) {
      const promise = scalingoClient(context).Billing.createProfile(payload);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [UPDATE](context, { id, payload }) {
      const promise = scalingoClient(context).Billing.updateProfile(
        id,
        payload,
      );

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
  });

  mutations = ResourceStore.buildMutations<BillingProfileModel>();
  getters = ResourceStore.buildGetters<BillingProfileModel>();
}

export const BillingProfile = buildMapping(
  new BillingProfileStore(),
  "billingProfile",
);

export function billingProfile(store: ApplicationStore): BillingProfileModel {
  return store.getters[BillingProfile.getters.CURRENT];
}

export function billingProfileAndFetch(
  store: ApplicationStore,
): ResourceWithFetch<BillingProfileModel> {
  return {
    value: store.getters[BillingProfile.getters.CURRENT],
    latestFetch: store.getters[BillingProfile.getters.LATEST_FETCH],
  };
}

export function createProfile(
  store: ApplicationStore,
  payload: Partial<BillingProfileModel>,
): Promise<RemoteOperation<BillingProfileModel>> {
  return store.dispatch(BillingProfile.actions.CREATE, payload);
}

export function updateProfile(
  store: ApplicationStore,
  id: string,
  payload: Partial<BillingProfileModel>,
): Promise<RemoteOperation<BillingProfileModel>> {
  return store.dispatch(BillingProfile.actions.UPDATE, { id, payload });
}

export function ensureBillingProfile(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(BillingProfile.actions.ENSURE, opts);
}
