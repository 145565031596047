<template>
  <section>
    <h3 class="mb-2 leading-8">
      <font-awesome-icon
        v-if="iconForContract(contract)"
        v-bind="iconForContract(contract)"
      />

      <span class="font-medium mr-1">
        {{
          $t("contractName", {
            name: localizedAttr(contract, "name"),
            version: contract.version,
          })
        }}
      </span>
      <span v-if="contract.accepted" class="text-sm text-scale-7">
        {{
          $t("acceptedOn", {
            date: formatDateTime(contract.accepted.on, "DDD"),
          })
        }}
      </span>

      <span v-if="contract.refused" class="text-sm text-error">
        {{
          $t("refusedOn", {
            date: formatDateTime(contract.refused.on, "DDD"),
            reason: contract.refused.reason,
          })
        }}
      </span>
    </h3>
  </section>
</template>

<script>
import { requiredForHDS, requiredForPlatform } from "scalingo/lib/Contracts";
import { defineComponent } from "vue";

import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "AnsweredContractInfos",
  components: {},
  props: {
    contract: Object,
  },
  methods: {
    formatDateTime,
    localizedAttr(object, attr) {
      return object[attr]?.[this.$i18n.locale];
    },
    iconForContract(contract) {
      if (requiredForPlatform(contract)) {
        return {
          icon: "file-contract",
          class: "ml-1 mr-1",
        };
      }

      if (requiredForHDS(contract)) {
        return {
          icon: "staff-snake",
          class: "text-success ml-1 mr-1",
        };
      }

      return null;
    },
  },
});
</script>

<i18n>
en:
  contractName: "{name} – version {version}"
  acceptedOn: "accepted on {date}"
  refusedOn: "refused on {date}: {reason}"
fr:
  contractName: "{name} – version {version}"
  acceptedOn: "accepté(e)(s) le {date}"
  refusedOn: "refusé(e)(s) le {date}: {reason}"
</i18n>
