import Client from "scalingo";
import { Addon, AddonProvider, Plan } from "scalingo/lib/models/regional";

import { App } from "@/lib/scalingo/modifiers/apps";

interface AddonData {
  addon_provider: AddonProvider;
  plan: Plan;
}

/**
 * The third step of the one click deploy consists in creating the requested addons.
 * As we will need to watch for them in the next step, we return them as well.
 */
export async function ocdStep3(
  client: Client,
  app: App,
  params: AddonData[],
): Promise<{ app: App; addons: Addon[] }> {
  const addonsPromises = params.map((addon) => {
    return client.Addons.create(app.id, {
      plan_id: addon.plan.id,
      addon_provider_id: addon.addon_provider.id,
    });
  });

  const addons = await Promise.all(addonsPromises);

  return { app, addons };
}
