<template>
  <StepCard
    :step="1"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <div class="py-4">
      <AddonProviders
        :addons="addons"
        :addonProviders="addonProviders"
        :selectedAddonProvider="selectedAddonProvider"
        @addonProviderClicked="addonProviderClicked"
      />

      <div class="flex">
        <SCButton
          block
          kind="primary"
          size="lg"
          class="mt-4 flex-grow"
          :disabled="!selectedAddonProvider"
          @click="$emit('addonProviderClicked', selectedAddonProvider)"
        >
          {{ $t("buttonName") }}
        </SCButton>
      </div>
    </div>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import AddonProviders from "@/components/parts/app/newAddons/AddonProviders.vue";

export default defineComponent({
  name: "ChoiceCard",
  components: { AddonProviders, StepCard, SCButton },
  props: {
    enabled: Boolean,
    addons: Object,
    addonProviders: Object,
    selectedAddon: Object,
  },
  emits: ["addonProviderClicked"],
  data() {
    return {
      selectedAddonProvider: null,
    };
  },
  computed: {},
  methods: {
    addonProviderClicked(e) {
      this.selectedAddonProvider = e;
    },
  },
});
</script>

<i18n>
  en:
    title: "Addons"
    subtitle: "Extensions linked to this app."
    buttonName: "Go to plan selection"
  fr:
    title: "Addons"
    subtitle: "Extensions liées à cette app."
    buttonName: "Aller à la sélection des plans"
</i18n>
