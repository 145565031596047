<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12.0082C21.9967 16.3106 19.2419 20.1287 15.16 21.4882C15.0072 21.5353 14.8413 21.5095 14.71 21.4182C14.5788 21.3227 14.5008 21.1705 14.5 21.0082V18.3382C14.5216 17.6191 14.3489 16.9074 14 16.2782C13.9472 16.1959 13.9472 16.0905 14 16.0082C14.0406 15.9323 14.1148 15.8804 14.2 15.8682C16.64 15.6182 18 14.6482 18 12.0082C18.0588 10.7756 17.6315 9.56907 16.81 8.64821C16.9298 8.25922 16.9938 7.85519 17 7.44821C16.9957 7.08333 16.9453 6.72045 16.85 6.36821C16.7871 6.13333 16.5615 5.98009 16.32 6.00821C15.4038 6.13826 14.5509 6.55073 13.88 7.18821C12.6382 6.94827 11.3619 6.94827 10.12 7.18821C9.44916 6.55073 8.59625 6.13826 7.68001 6.00821C7.43849 5.98009 7.21288 6.13333 7.15001 6.36821C7.0547 6.72045 7.0043 7.08333 7.00001 7.44821C7.00623 7.85519 7.0702 8.25922 7.19001 8.64821C6.36849 9.56907 5.94119 10.7756 6.00001 12.0082C6.00001 14.7882 7.51001 15.7182 10.28 15.9482C9.93615 16.3988 9.70011 16.9222 9.59001 17.4782C9.59001 17.4782 9.59001 17.5482 9.59001 17.5982C9.58519 17.6448 9.58519 17.6917 9.59001 17.7382C9.5484 18.0526 9.26573 18.2778 8.95001 18.2482C8.79946 18.2411 8.65247 18.2001 8.52001 18.1282C7.97522 17.7964 7.49429 17.3697 7.10001 16.8682C6.8488 16.5834 6.58171 16.3129 6.30001 16.0582C6.12101 15.9033 5.9227 15.7722 5.71001 15.6682C5.56096 15.5845 5.37905 15.5845 5.23001 15.6682C5.08693 15.76 5.00029 15.9182 5.00001 16.0882V16.1482C5.00029 16.3182 5.08693 16.4764 5.23001 16.5682C5.58897 16.8687 5.89931 17.2229 6.15001 17.6182C6.57339 18.2816 7.11184 18.8641 7.74001 19.3382C8.15388 19.6157 8.64174 19.762 9.14001 19.7582H9.50001V21.0082C9.49923 21.1705 9.42126 21.3227 9.29001 21.4182C9.15874 21.5095 8.99279 21.5353 8.84001 21.4882C3.99205 19.8735 1.14125 14.8637 2.22938 9.87112C3.31751 4.87854 7.9945 1.5094 13.0747 2.05854C18.1549 2.60768 22.0039 6.89843 22 12.0082Z"
      fill="currentColor"
    />
    <circle cx="18" cy="18" r="5.5" fill="currentColor" stroke="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18ZM18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1951 15.6219L20.3781 15.8049C20.456 15.8828 20.4999 15.9885 20.5 16.0987V16.3094C20.5014 16.4216 20.4574 16.5295 20.3781 16.6088L19.8653 17.1133C19.8393 17.1396 19.8039 17.1543 19.767 17.1543C19.73 17.1543 19.6946 17.1396 19.6686 17.1133L18.8898 16.3233C18.837 16.2694 18.837 16.1831 18.8898 16.1292L19.3914 15.6219C19.4707 15.5425 19.5786 15.4985 19.6907 15.4999H19.9014C20.0116 15.5 20.1173 15.5439 20.1951 15.6219ZM19.3388 17.6346C19.3916 17.5807 19.3916 17.4944 19.3388 17.4405L18.5461 16.6588C18.5201 16.6325 18.4847 16.6178 18.4477 16.6178C18.4108 16.6178 18.3754 16.6325 18.3494 16.6588L16.1128 18.896C16.0587 18.9496 16.0163 19.0137 15.9881 19.0845L15.5114 20.2876C15.4844 20.35 15.5067 20.4229 15.5641 20.4595C15.6033 20.4987 15.6622 20.5107 15.7137 20.49L16.9165 20.0021C16.9872 19.9739 17.0514 19.9314 17.105 19.8773L19.3388 17.6346Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GithubEditGlyph",
});
</script>
