<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71985 6.78017C6.57933 6.92086 6.3887 6.99999 6.18985 7.00017H5.80985C5.61144 6.99786 5.42157 6.91905 5.27985 6.78017L0.149852 1.64016C0.055196 1.54628 0.00195312 1.41848 0.00195312 1.28516C0.00195312 1.15185 0.055196 1.02405 0.149852 0.930165L0.859852 0.220165C0.952016 0.126101 1.07816 0.0730934 1.20985 0.0730934C1.34154 0.0730934 1.46769 0.126101 1.55985 0.220165L5.99985 4.67017L10.4399 0.220165C10.5337 0.125508 10.6615 0.0722656 10.7949 0.0722656C10.9282 0.0722656 11.056 0.125508 11.1499 0.220165L11.8499 0.930165C11.9445 1.02405 11.9977 1.15185 11.9977 1.28516C11.9977 1.41848 11.9445 1.54628 11.8499 1.64016L6.71985 6.78017Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShortArrowBottom",
});
</script>
