<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 3.99998C19.7614 3.99998 22 6.23856 22 8.99998C22.0044 10.0825 21.653 11.1366 21 12V18C21 19.1045 20.1046 20 19 20H4C2.89543 20 2 19.1045 2 18V6.99998C2 5.89541 2.89543 4.99998 4 4.99998H14C14.8634 4.34694 15.9174 3.99559 17 3.99998ZM16.62 5.99998C16.3439 5.99998 16.12 6.22384 16.12 6.49998V8.11998H14.5C14.2239 8.11998 14 8.34384 14 8.61998V9.37998C14 9.65612 14.2239 9.87998 14.5 9.87998H16.12V11.5C16.12 11.7761 16.3439 12 16.62 12H17.38C17.6561 12 17.88 11.7761 17.88 11.5V9.87998H19.5C19.7761 9.87998 20 9.65612 20 9.37998V8.61998C20 8.34384 19.7761 8.11998 19.5 8.11998H17.88V6.49998C17.88 6.22384 17.6561 5.99998 17.38 5.99998H16.62ZM4 6.99998H12.42C12.1421 7.63008 11.9991 8.31133 12 8.99998C12.0026 9.33568 12.0361 9.67042 12.1 9.99998H4V6.99998ZM4 13V18H19V13.58C17.3528 14.3161 15.435 14.0937 14 13H4Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentAddGlyph",
});
</script>
