<template>
  <div>
    <header>
      <NavBar :title="$t('title')"></NavBar>
    </header>
    <main class="container px-4 mt-8">
      <template v-if="manifestInfo">
        <OCDInfosStepCard
          :user="user"
          :manifestInfo="manifestInfo"
          :formHandler="formHandler"
          :enabled="OCDTunnelStep === 1"
          :regions="regions"
          @checkAppValidity="(e) => $emit('checkAppValidity', e)"
          @regionSelected="(e) => $emit('regionSelected', e)"
        />
        <CardSeparator />
        <OCDEnvVarsStepCard
          :dryRunnedApp="dryRunnedApp"
          :manifestInfo="manifestInfo"
          :enabled="OCDTunnelStep === 2"
          @variablesSubmitted="(e) => $emit('variablesSubmitted', e)"
        />
        <CardSeparator />
        <OCDValidationStepCard
          :dryRunnedApp="dryRunnedApp"
          :containerSizes="containerSizes"
          :addonProviders="addonProviders"
          :manifestInfo="manifestInfo"
          :ocdStatus="ocdStatus"
          :enabled="OCDTunnelStep === 3"
          @confirmAppCreation="(e) => $emit('confirmAppCreation', e)"
        />
      </template>

      <template v-else>
        <InfosStepCard
          :user="user"
          :formHandler="formHandler"
          :hdsPointOfContact="hdsPointOfContact"
          :hdsContract="hdsContract"
          :hdsContractPrerequisite="hdsContractPrerequisite"
          :hdsLoadingOperation="hdsLoadingOperation"
          :acceptContractHandler="acceptContractHandler"
          :createPointOfContactHandler="createPointOfContactHandler"
          :enabled="true"
          :regions="regions"
          @formSubmitted="(e) => $emit('formSubmitted', e)"
          @regionSelected="(e) => $emit('regionSelected', e)"
          @toggleHDSMode="(e) => $emit('toggleHDSMode', e)"
          @startPointOfContactCreation="
            (e) => $emit('startPointOfContactCreation', e)
          "
          @cancelPointOfContactCreation="
            (e) => $emit('cancelPointOfContactCreation', e)
          "
          @submitPointOfContactCreation="
            (e) => $emit('submitPointOfContactCreation', e)
          "
          @startContractAcceptance="(e) => $emit('startContractAcceptance', e)"
          @cancelContractAcceptance="
            (e) => $emit('cancelContractAcceptance', e)
          "
          @submitContractAcceptance="
            (e) => $emit('submitContractAcceptance', e)
          "
        />
        <CardSeparator />
        <DeployStepCard :enabled="false" />
      </template>
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import NavBar from "@/components/molecules/navBar/NavBar.vue";
import DeployStepCard from "@/components/parts/configure/app/DeployStepCard.vue";
import InfosStepCard from "@/components/parts/create/app/InfosStepCard.vue";
import OCDEnvVarsStepCard from "@/components/parts/create/app/OCDEnvVarsStepCard.vue";
import OCDInfosStepCard from "@/components/parts/create/app/OCDInfosStepCard.vue";
import OCDValidationStepCard from "@/components/parts/create/app/OCDValidationStepCard.vue";

export default defineComponent({
  name: "CreateApp",
  components: {
    NavBar,
    CardSeparator,
    DeployStepCard,
    InfosStepCard,
    OCDInfosStepCard,
    OCDEnvVarsStepCard,
    OCDValidationStepCard,
  },
  props: {
    user: Object,
    formHandler: Object,
    acceptContractHandler: Object,
    createPointOfContactHandler: Object,
    regions: Array,
    hdsPointOfContact: Object,
    hdsContract: Object,
    hdsContractPrerequisite: Object,
    hdsLoadingOperation: Object,
    containerSizes: Object,
    addonProviders: Object,
    manifestInfo: Object,
    OCDTunnelStep: Number,
    ocdStatus: Object,
    dryRunnedApp: Object,
  },
  emits: [
    "appDryRunSuccess",
    "cancelContractAcceptance",
    "cancelPointOfContactCreation",
    "checkAppValidity",
    "confirmAppCreation",
    "formSubmitted",
    "regionSelected",
    "startContractAcceptance",
    "startPointOfContactCreation",
    "submitContractAcceptance",
    "submitPointOfContactCreation",
    "toggleHDSMode",
    "variablesSubmitted",
  ],
});
</script>

<i18n>
  en:
    title: "Create an application"
  fr:
    title: "Créer une application"
</i18n>
