<template>
  <ViewComponent
    :appInfos="appInfos"
    :events="events"
    :user="currentUser"
    :articles="articles"
    :changelogEntries="changelogEntries"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/Overview.vue";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import {
  listBlogArticles,
  ensureBlogArticles,
  clearBlogArticles,
} from "@/store/blog-articles";
import {
  ensureChangelogEntries,
  listChangelogEntries,
} from "@/store/changelog-entries";
import { listEvents, ensureEvents } from "@/store/user-events";
import { useAppInfosStore } from "@/stores/app-infos";

export default defineComponent({
  name: "OverviewContainer",
  components: { ViewComponent },
  setup() {
    const appInfosStore = useAppInfosStore();

    return { appInfosStore };
  },
  computed: {
    appInfos() {
      const { items, promiseInfo, lastFetchedAt } = this.appInfosStore;

      const sortedItems = filterItems(items, {
        limit: 5,
        sortBy: "deployedOrCreatedAt",
        sortDirection: "desc",
      });

      return { items: sortedItems, promiseInfo, lastFetchedAt };
    },
    events() {
      return listEvents(this.$store, {
        limit: 5,
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
    articles() {
      return listBlogArticles(this.$store, {
        limit: 5,
        sortBy: "publishedAt",
        sortDirection: "desc",
      });
    },
    changelogEntries() {
      return listChangelogEntries(this.$store, {
        limit: 5,
        sortBy: "publishedAt",
        sortDirection: "desc",
      });
    },
  },
  watch: {
    "$i18n.locale": function (val) {
      clearBlogArticles(this.$store);
      ensureBlogArticles(this.$store, val);
    },
  },
  created() {
    this.appInfosStore.ensure({ staleAfter: "always" });
    ensureEvents(this.$store, { staleAfter: "always" });
    ensureChangelogEntries(this.$store);
    ensureBlogArticles(
      this.$store,
      this.$i18n.locale || this.$i18n.fallbackLocale,
    );
  },
});
</script>
