export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew a token"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want renew this token?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler un token"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous vraiment renouveler ce token?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveler"])}
      }
    }
  })
}
