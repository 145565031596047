/* eslint-disable @typescript-eslint/no-explicit-any */
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

import ScalingoPrivate from "./private";
import {
  setupStripeCard,
  setupStripeSepa,
  stripeClient,
  StripeSEPACreateParams,
} from "../stripe";

import type { StripeCardElement, StripeIbanElement } from "@stripe/stripe-js";

export interface PaymentMethod {
  /** Payment method id */
  id: string;
  /** Billing profile id */
  profile_id: string;
  /** Created at */
  created_at: string;
  /** Last updated at */
  updated_at: string;
  /** type of payment method. Can be 'card' or 'sepa' */
  object_type: string;
  /** Wether this is the default payment method for the billing profile */
  default: boolean;
  /** Metadata about the card. Expected to be present if object_type == 'card' */
  card: PaymentCard;
  /** Metadata about the sepa mandate. Expected to be present if object_type == 'sepa' */
  sepa: PaymentSepa;
}

export interface PaymentCard {
  /** Brand of the card */
  brand: string;
  /** Last 4 digits of the card */
  last4: string;
  /** Expiration date "MM/YY" */
  exp: string;
  /** Name of the holder */
  name: string;
}

export interface PaymentSepa {
  /** Bank code */
  bank_code: string;
  /** Country */
  country: string;
  /** last 4 digits of the SEPA */
  last4: string;
  /** URL of the mandate */
  mandate_url: string;
  /** Fingerprint */
  fingerprint: string;
}

export type IndexQuery = {
  profile_id: string;
  page?: number;
  per_page?: number;
};

export type IndexResponse = {
  meta: Record<string, any>;
  payment_methods: PaymentMethod[];
};

export default class PaymentMethods {
  client: Scalingo;

  constructor(readonly privateClient: ScalingoPrivate) {
    this.client = privateClient.client;
  }

  all(params: IndexQuery): Promise<IndexResponse> {
    return unpackData(
      this.client.billingApiClient().get("/payment_methods", { params }),
    );
  }

  find(id: string): Promise<PaymentMethod> {
    return unpackData(
      this.client.billingApiClient().get(`/payment_methods/${id}`),
      "payment_method",
    );
  }

  createSepaPaymentMethod(
    profile_id: string,
    stripe_payment_method_id: string,
  ): Promise<PaymentMethod> {
    const payload = {
      payment_method: {
        profile_id,
        stripe_payment_method_id,
        object_type: "sepa",
      },
    };

    return unpackData(
      this.client.billingApiClient().post("/payment_methods", payload),
      "payment_method",
    );
  }

  async createSepaFullFlow(
    profileId: string,
    el: StripeIbanElement,
    stripePayload: StripeSEPACreateParams,
  ): Promise<PaymentMethod | undefined> {
    const client = await stripeClient();

    if (!client) return;

    const scalingoSetupIntent =
      await this.privateClient.SetupIntents.create("sepa");

    const stripeSetupIntent = await setupStripeSepa(
      client,
      scalingoSetupIntent,
      el,
      stripePayload,
    );

    const pm = stripeSetupIntent.payment_method as string;

    return this.createSepaPaymentMethod(profileId, pm);
  }

  createCardPaymentMethod(
    profile_id: string,
    stripe_payment_method_id: string,
    defaultPaymentMethod: boolean,
  ): Promise<PaymentMethod> {
    const payload = {
      payment_method: {
        profile_id,
        stripe_payment_method_id,
        object_type: "card",
        default: defaultPaymentMethod,
      },
    };

    return unpackData(
      this.client.billingApiClient().post("/payment_methods", payload),
      "payment_method",
    );
  }

  async createCardFullFlow(
    profileId: string,
    el: StripeCardElement,
    holder: string,
    defaultPaymentMethod: boolean,
  ): Promise<PaymentMethod | undefined> {
    const client = await stripeClient();

    if (!client) return;

    const scalingoSetupIntent =
      await this.privateClient.SetupIntents.create("card");
    const stripeSetupIntent = await setupStripeCard(
      client,
      scalingoSetupIntent,
      el,
      holder,
    );

    const pm = stripeSetupIntent.payment_method as string;

    return this.createCardPaymentMethod(profileId, pm, defaultPaymentMethod);
  }

  setDefault(id: string): Promise<PaymentMethod> {
    const payload = {
      payment_method: { default: true },
    };

    return unpackData(
      this.client.billingApiClient().patch(`/payment_methods/${id}`, payload),
      "payment_method",
    );
  }

  destroy(id: string): Promise<void> {
    return unpackData(
      this.client.billingApiClient().delete(`/payment_methods/${id}`),
    );
  }
}
