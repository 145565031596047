export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review app from a fork"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Only deploy review apps from trusted sources. As this pull request comes from a fork (", _interpolate(_named("repository")), "), Scalingo invites you to consult the"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["best practices for using review apps."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy a review app from a fork"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review app depuis un fork"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ne déployez des review apps qu'à partir de sources fiables. Cette pull request provenant d'un fork (", _interpolate(_named("repository")), "), Scalingo vous invite à consulter les"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meilleures pratiques pour l'utilisation des review apps."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déployer une review app depuis un fork"])}
      }
    }
  })
}
