import { defineStore } from "pinia";

import { useResourceStore } from "@/lib/pinia/use-resource-store-next";
import { ignore404 } from "@/lib/pinia/utils/error-handlers";
import { Contact, CreateParams, UpdateParams } from "@/lib/scalingo/contacts";
import { useSessionStore } from "@/stores/session";

export const usePointOfContactStore = defineStore("pointOfContact", () => {
  const { privateClient } = useSessionStore();

  const store = useResourceStore({
    fetchPromise: () => privateClient().Contacts.show().catch(ignore404),
  });

  return {
    ...store,
    create(payload: CreateParams) {
      return privateClient()
        .Contacts.create(payload)
        .then(store.setData) as Promise<Contact>;
    },
    update(payload: UpdateParams) {
      return privateClient()
        .Contacts.update(payload)
        .then(store.setData) as Promise<Contact>;
    },
    destroy() {
      return privateClient()
        .Contacts.destroy()
        .catch(ignore404)
        .then(store.$reset);
    },
  };
});
