export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier platform"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the platform this notifier will use"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plateforme de notification"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la plateforme de notification à utiliser"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])}
      }
    }
  })
}
