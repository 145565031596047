<template>
  <router-view :app="app" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ActivityRoot",
  props: {
    app: Object,
  },
});
</script>
