import { App as OriginalApp } from "scalingo/lib/models/regional";

import { AppInfo as OriginalAppInfo } from "@/lib/scalingo/app-infos";
import { enhanceOne as enhanceRepoLink } from "@/store/scm-repo-link";

interface AdditionnalInfo {
  deployedOrCreatedAt: string;
  _isReviewApp: boolean;
  _prNum: null | number;
}

export type App = OriginalApp & AdditionnalInfo;
export type AppInfo = OriginalAppInfo & AdditionnalInfo;

export function enhanceApp(app: OriginalApp): OriginalApp & AdditionnalInfo;
export function enhanceApp(
  app: OriginalAppInfo,
): OriginalAppInfo & AdditionnalInfo;
export function enhanceApp(
  app: OriginalApp | OriginalAppInfo,
): typeof app & AdditionnalInfo {
  const enhanced: typeof app & AdditionnalInfo = {
    ...app,
    deployedOrCreatedAt: app.last_deployed_at || app.created_at,
    _isReviewApp: false,
    _prNum: null,
  };

  // App info
  if ("parent_app_id" in app) {
    enhanced._isReviewApp = !!app.parent_app_id;
  }

  // Full object
  if ("parent_app_name" in app) {
    enhanced._isReviewApp = !!app.parent_app_name;
  }

  if (enhanced._isReviewApp) {
    const matches = enhanced.name.match(/\d*$/);

    if (matches?.[0]) {
      enhanced._prNum = parseInt(matches[0], 10);
    }
  }

  if ("scm_repo_link" in enhanced && enhanced.scm_repo_link) {
    enhanced.scm_repo_link = enhanceRepoLink(enhanced.scm_repo_link);
  }

  return enhanced;
}
