export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "displayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display more information about this event"])},
        "hideInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide information about this event"])},
        "navigateToEventPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the event dedicated page"])},
        "unsupportedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported mode"])},
        "turnYourMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn your phone in landscape mode"])}
      },
      "fr": {
        "displayMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus d'information sur l'événement"])},
        "hideInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer les informations de cet événement"])},
        "navigateToEventPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigaguer à le page dédié de l'événement"])},
        "unsupportedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode non supporté"])},
        "turnYourMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournez votre mobile en mode paysage"])}
      }
    }
  })
}
