export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database maintenance planned"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A maintenance"])},
        "impacted-addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impacted Database"])},
        "addon-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database"])},
        "impacted-with-downtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the app may be impacted by a brief downtime."])},
        "maintenance-window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance window"])},
        "window-start-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance will start during the next window configured for this database :"])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["between"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
        "objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance objective"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance de base de données planifiée"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une maintenance"])},
        "impacted-addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de données impactée"])},
        "addon-intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données"])},
        "impacted-with-downtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'application peut être impactée par une brève indisponibilité."])},
        "maintenance-window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenêtre de maintenance"])},
        "window-start-introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La maintenance débutera lors de la prochaine fenêtre configurée pour cet base de données:"])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entre"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
        "objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif de la maintenance"])}
      }
    }
  })
}
