export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "some": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("itemsShowed")), " out of ", _interpolate(_named("itemTotal")), " ", _interpolate(_named("computedItemName"))]), _normalize([_interpolate(_named("itemsShowed")), " out of ", _interpolate(_named("itemTotal")), " ", _interpolate(_named("computedItemName")), "s"])])},
        "all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ", _interpolate(_named("computedItemName"))]), _normalize([_interpolate(_named("itemsShowed")), " ", _interpolate(_named("computedItemName"))]), _normalize([_interpolate(_named("itemsShowed")), " ", _interpolate(_named("computedItemName")), "s"])])},
        "defaultItemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item"])}
      },
      "fr": {
        "some": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("itemsShowed")), " sur ", _interpolate(_named("itemTotal")), " ", _interpolate(_named("computedItemName"))]), _normalize([_interpolate(_named("itemsShowed")), " sur ", _interpolate(_named("itemTotal")), " ", _interpolate(_named("computedItemName")), "s"])])},
        "all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ", _interpolate(_named("computedItemName"))]), _normalize([_interpolate(_named("itemsShowed")), " ", _interpolate(_named("computedItemName"))]), _normalize([_interpolate(_named("itemsShowed")), " ", _interpolate(_named("computedItemName")), "s"])])},
        "defaultItemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["élément"])}
      }
    }
  })
}
