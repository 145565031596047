<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4815 0C8.00444 0 5.18518 2.81926 5.18518 6.2963C5.18518 7.06815 5.33042 7.80588 5.58449 8.48958L0 14.0741V17.7778H3.7037V15.5556H5.92593V13.3333H8.14815L8.37818 13.1033C9.13448 10.2529 11.7259 8.14815 14.8148 8.14815C15.7022 8.14815 16.5472 8.32523 17.322 8.64005C17.6132 7.91486 17.7778 7.12519 17.7778 6.2963C17.7778 2.81926 14.9585 0 11.4815 0ZM12.963 2.96296C13.9859 2.96296 14.8148 3.79185 14.8148 4.81481C14.8148 5.83778 13.9859 6.66667 12.963 6.66667C11.94 6.66667 11.1111 5.83778 11.1111 4.81481C11.1111 3.79185 11.94 2.96296 12.963 2.96296ZM14.8148 9.62963C11.9511 9.62963 9.62963 11.9511 9.62963 14.8148C9.62963 17.6785 11.9511 20 14.8148 20C17.6785 20 20 17.6785 20 14.8148C20 11.9511 17.6785 9.62963 14.8148 9.62963ZM12.5926 14.0741H17.037C17.4459 14.0741 17.7778 14.4052 17.7778 14.8148C17.7778 15.2244 17.4459 15.5556 17.037 15.5556H12.5926C12.1837 15.5556 11.8519 15.2244 11.8519 14.8148C11.8519 14.4052 12.1837 14.0741 12.5926 14.0741Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "KeyDelGlyph",
});
</script>
