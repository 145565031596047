import { BillingProfile } from "scalingo/lib/models/billing";

const FLAG_C3_CUSTOMER = "c3-customer";

// Type definition is wrong, this is a temporary fix
type Profile = BillingProfile & { flags: string[] };

export function isC3Customer(profile?: Profile | null) {
  if (!profile) return false;

  return profile.flags.includes(FLAG_C3_CUSTOMER);
}
