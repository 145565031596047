import { User } from "scalingo/lib/models/auth";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updateAccount } from "@/store/user";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  username: string;
  fullname: string;
  location: string;
  company: string;
  email_newsletter: boolean;
}

export class EditAccountHandler extends FormHandler<User> {
  keyPath = "account.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly user: User,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return { ...this.user };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await updateAccount(this.$store, event));
  }
}
