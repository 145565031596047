import { Variable } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { bulkDeleteVariables, bulkUpdateVariables } from "@/store/variables";

import type { ComponentPublicInstance } from "vue";

// Starts with a char (min or maj) OR an underscore
// Otherwise, alphanum + underscore
const VAR_NAME_REGEXP = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

/** A not-yet persisted variable */
type VariableWithoutId = Pick<Variable, "name" | "value">;

/** A diff for a changed variable */
interface UpdatedVariable extends VariableWithoutId {
  oldValue: string;
}
/** An invalid variable and its error */
interface InvalidVariable {
  variable: VariableWithoutId;
  error: "reserved" | "nameFormat" | "nameLength" | "valueLength" | "blank";
}

/** The set of changes. Serves as event data. */
interface VariableChangeset {
  addedVariables: VariableWithoutId[];
  updatedVariables: UpdatedVariable[];
  deletedVariables: Variable[];
}

type ReviewChangeset = VariableChangeset & {
  invalidVariables: InvalidVariable[];
};

/** Concatenates each variable as key=value, one per-line, for use in a text editor */
function variablesAsText(variables: Variable[]): string {
  let string = "";

  variables.forEach((variable) => {
    string += `${variable.name}=${variable.value}\n`;
  });

  return string;
}

export class EditBulkVariablesHandler extends FormHandler<unknown> {
  keyPath = "variable.bulkEdit";

  data(): string {
    return variablesAsText(this.variables);
  }

  constructor(
    component: ComponentPublicInstance,
    readonly variables: Variable[],
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  /**
   * This function compares the content of the editor with the last known variables state,
   * and returns the changes, categorized by type
   */
  reviewChanges(input: string): ReviewChangeset {
    const changeset: ReviewChangeset = {
      addedVariables: [],
      updatedVariables: [],
      deletedVariables: [],
      invalidVariables: [],
    };

    const newVariablesList: VariableWithoutId[] = input
      .split("\n")
      .filter((line) => {
        return line && line !== "";
      })
      .map((line) => {
        const [name, ...vals] = line.split("=");
        return { name: name.trim(), value: vals.join("=").trim() };
      });

    // Looking for new vars
    newVariablesList.forEach((newVariable) => {
      // Refer to the API's source for a list of reserved name
      if (newVariable.name === "PORT") {
        changeset.invalidVariables.push({
          variable: newVariable,
          error: "reserved",
        });
        return;
      }

      // Value is empty? var is invalid
      if (!newVariable.value) {
        changeset.invalidVariables.push({
          variable: newVariable,
          error: "blank",
        });
        return;
      }

      if (newVariable.value.length > 8192) {
        changeset.invalidVariables.push({
          variable: newVariable,
          error: "valueLength",
        });
        return;
      }

      if (newVariable.name.length > 64) {
        changeset.invalidVariables.push({
          variable: newVariable,
          error: "nameLength",
        });
        return;
      }
      // The name must respect a regex
      if (!newVariable.name.match(VAR_NAME_REGEXP)) {
        changeset.invalidVariables.push({
          variable: newVariable,
          error: "nameFormat",
        });
        return;
      }

      const match = this.variables.find((variable) => {
        return newVariable.name === variable.name;
      });

      if (!match) {
        changeset.addedVariables.push(newVariable);
      }
    });

    const validVariables = newVariablesList.filter((v) => {
      return !changeset.invalidVariables.find(
        (iv) => iv.variable.name === v.name,
      );
    });

    // Looking for deleted and updated vars
    this.variables.forEach((variable) => {
      const match = validVariables.find((newVariable) => {
        return newVariable.name === variable.name;
      });

      if (match) {
        if (match.value !== variable.value) {
          changeset.updatedVariables.push({
            ...match,
            oldValue: variable.value,
          });
        }
      } else {
        changeset.deletedVariables.push(variable);
      }
    });

    return changeset;
  }

  /**
   * Submission involves more than one call this time
   * Note: we could add a notification for each promise rather than one global
   * Note: we could use a single call for addition/updating
   */
  async submit(event: VariableChangeset): Promise<void> {
    const promises = [];

    if (event.addedVariables.length > 0) {
      const op = await bulkUpdateVariables(this.$store, event.addedVariables);
      promises.push(op.promise);
    }

    if (event.updatedVariables.length > 0) {
      const op = await bulkUpdateVariables(this.$store, event.updatedVariables);
      promises.push(op.promise);
    }

    if (event.deletedVariables.length > 0) {
      const op = await bulkDeleteVariables(
        this.$store,
        event.deletedVariables.map((v) => v.id),
      );
      promises.push(op.promise);
    }

    const aggregated = new RemoteOperation().follow(Promise.all(promises));

    this.follow(aggregated);
  }
}
