import {
  EventType,
  NotificationPlatform,
  Notifier,
} from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/notifiers";

import { FormHandler } from "@/lib/handlers/form";
import { App } from "@/lib/scalingo/modifiers/apps";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { createNotifier } from "@/store/notifiers";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  name: string;
  selectedUserIds: string[];
  extraEmails: string[];
  webhookUrl: string | null;
  active: boolean;
}

export class CreateNotifierHandler extends FormHandler<Notifier> {
  keyPath = "notifiers.create";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly platform: NotificationPlatform,
    readonly eventTypes: EventType[],
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      name: null,
      selectedUserIds: [],
      extraEmails: [],
      webhookUrl: "https://",
      active: true,
    };
  }

  dispatchEvents(): void {
    this.on("success", (notifier) => {
      this.notify("success", { name: notifier.name });

      this.$router.push({
        name: Routes.App.Notifiers.Edit.Events,
        params: {
          id: this.app.name,
          region: this.app.region,
          notifierId: notifier.id,
        },
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    const appDeployedEvent = this.eventTypes.find(
      (type) => type.name === "app_deployed",
    );

    const payload: CreateParams = {
      name: event.name,
      active: true,
      platform_id: this.platform.id,
    };

    if (appDeployedEvent) {
      payload.selected_event_ids = [appDeployedEvent.id];
    }

    if (this.platform.name === "email") {
      payload.type_data = {
        user_ids: event.selectedUserIds,
        emails: event.extraEmails,
      };
    } else {
      payload.type_data = {
        webhook_url: event.webhookUrl,
      };
    }

    this.follow(await createNotifier(this.$store, payload));
  }
}
