// Taken from https://jasonwatmore.com/post/2018/08/07/javascript-pure-pagination-logic-in-vanilla-js-typescript
// Added startWindow/endWindow logic

interface PagerData {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  mainWindow: number[];
  startWindow: number[] | null;
  endWindow: number[] | null;
}

export function paginate(
  totalItems: number,
  currentPage = 1,
  pageSize = 10,
  mainWindowSize = 3,
  secondaryWindowSize = 2,
): PagerData {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // Short circuit for simplest case
  if (totalPages == 0) {
    return {
      totalItems: totalItems,
      currentPage: 1,
      pageSize: 0,
      totalPages: 1,
      startPage: 1,
      endPage: 1,
      startIndex: 0,
      endIndex: 0,
      mainWindow: [],
      startWindow: null,
      endWindow: null,
    };
  }

  if (totalPages == 1) {
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: 1,
      startPage: 1,
      endPage: 1,
      startIndex: 0,
      endIndex: 0,
      mainWindow: [],
      startWindow: null,
      endWindow: null,
    };
  }

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage: number, endPage: number;
  if (totalPages <= mainWindowSize) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(mainWindowSize / 2);
    const maxPagesAfterCurrentPage = Math.ceil(mainWindowSize / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = mainWindowSize;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - mainWindowSize + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  let startWindow: number[] | null = Array.from(
    Array(secondaryWindowSize).keys(),
  ).map((i) => 1 + i);

  let endWindow: number[] | null = Array.from(Array(secondaryWindowSize).keys())
    .map((i) => totalPages - i)
    .reverse();

  // create an array of pages to ng-repeat in the pager control
  let mainWindow = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i,
  );

  // Handle start and main window overlapping/consecutiveness
  const startOverlapsMainIndex = mainWindow.indexOf(
    startWindow[startWindow.length - 1],
  );

  if (startOverlapsMainIndex > -1) {
    mainWindow = [
      ...startWindow,
      ...mainWindow.slice(startOverlapsMainIndex + 1),
    ];
    startWindow = null;
  } else if (startWindow[startWindow.length - 1] === mainWindow[0] - 1) {
    mainWindow = [...startWindow, ...mainWindow];
    startWindow = null;
  }

  // Handle main and end window overlapping/consecutiveness
  const endOverlapsMainIndex = mainWindow.indexOf(endWindow[0]);

  if (endOverlapsMainIndex > -1) {
    mainWindow = [...mainWindow.slice(0, endOverlapsMainIndex), ...endWindow];
    endWindow = null;
  } else if (mainWindow[mainWindow.length - 1] === endWindow[0] - 1) {
    mainWindow = [...mainWindow, ...endWindow];
    endWindow = null;
  }

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    mainWindow: mainWindow,
    startWindow: startWindow,
    endWindow: endWindow,
  };
}
