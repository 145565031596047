import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSlack,
  faGithub,
  faGitlab,
  faCcVisa,
  faSquareGit,
  faGitAlt,
  faCcMastercard,
  faTwitter,
  faDocker,
} from "@fortawesome/free-brands-svg-icons";
import {
  faStar as faRegularStar,
  faCalendar as farCalendar,
  faClock as farClock,
  faHardDrive as farHardDrive,
  faSquarePlus as farSquarePlus,
  faSquareMinus as farSquareMinus,
  faUser as farUser,
  faClipboard as farClipboard,
} from "@fortawesome/free-regular-svg-icons";
import {
  faHouseUser,
  faInfo,
  faCreditCard,
  faMoneyCheckAlt,
  faTrashAlt,
  faCertificate,
  faCircle,
  faQuestionCircle,
  faDirections,
  faFileContract,
  faRedo,
  faStickyNote,
  faKey,
  faPhoneAlt,
  faEnvelopeOpenText,
  faPen,
  faDownload,
  faBalanceScale,
  faLink,
  faExternalLinkAlt,
  faPalette,
  faCaretUp,
  faCaretDown,
  faCaretRight,
  faStar,
  faLock,
  faPlus,
  faBuilding,
  faMapMarked,
  faNewspaper,
  faGlobeEurope,
  faDatabase,
  faGlobe,
  faStaffSnake,
  faEyeSlash,
  faEye,
  faCodeCommit,
  faLayerGroup,
  faArrowUpFromBracket,
  faClock,
  faGlobeAfrica,
  faDungeon,
  faArrowRightArrowLeft,
  faMemory,
  faBoxArchive,
  faCodeFork,
  faBook,
  faMicrochip,
  faXmark,
  faCheck,
  faFileExport,
  faClockRotateLeft,
  faWrench,
  faChevronUp,
  faChevronDown,
  faArrowsRotate,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronUp);
library.add(faChevronDown);
library.add(faWrench);
library.add(faCircleInfo);
library.add(faArrowsRotate);
library.add(faBalanceScale);
library.add(faHouseUser);
library.add(faInfo);
library.add(faFileContract);
library.add(faCreditCard);
library.add(faMoneyCheckAlt);
library.add(faCcVisa);
library.add(faCcMastercard);
library.add(faTrashAlt);
library.add(faCertificate);
library.add(faCircle);
library.add(faQuestionCircle);
library.add(faDirections);
library.add(faRedo);
library.add(faStickyNote);
library.add(faKey);
library.add(faSlack);
library.add(faDocker);
library.add(faPen);
library.add(faPlus);
library.add(faPhoneAlt);
library.add(faEnvelopeOpenText);
library.add(faDownload);
library.add(faGithub);
library.add(faGitlab);
library.add(faSquareGit);
library.add(faGitAlt);
library.add(faLink);
library.add(faExternalLinkAlt);
library.add(faPalette);
library.add(faCaretUp);
library.add(faCaretDown);
library.add(faCaretRight);
library.add(faStar);
library.add(faRegularStar);
library.add(faLock);
library.add(faBuilding);
library.add(faMapMarked);
library.add(faNewspaper);
library.add(faGlobeEurope);
library.add(faDatabase);
library.add(faTwitter);
library.add(faGlobe);
library.add(faStaffSnake);
library.add(faEyeSlash);
library.add(faEye);
library.add(farCalendar);
library.add(farClock);
library.add(faCodeCommit);
library.add(farHardDrive);
library.add(faLayerGroup);
library.add(farSquarePlus);
library.add(farSquareMinus);
library.add(faArrowUpFromBracket);
library.add(faClock);
library.add(farUser);
library.add(faGlobeAfrica);
library.add(faDungeon);
library.add(faArrowRightArrowLeft);
library.add(faMemory);
library.add(faBoxArchive);
library.add(faBook);
library.add(faCodeFork);
library.add(faMicrochip);
library.add(faXmark);
library.add(faCheck);
library.add(farClipboard);
library.add(faFileExport);
library.add(faClockRotateLeft);

export const addonProviderIconWidth = 80;
