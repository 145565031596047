export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add payment card"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your billing profile with a payment card"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "setAsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default payment method"])},
        "errors": {
          "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Try again in a little bit or contact our support."])},
          "generic_decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card was declined."])},
          "insufficient_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card has insufficient funds."])},
          "lost_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card was declined."])},
          "stolen_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card was declined."])},
          "expired_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card has expired."])},
          "incorrect_cvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card's security code is incorrect."])},
          "processing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing your card. Try again in a little bit."])},
          "incorrect_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your card number is invalid."])},
          "setup_intent_authentication_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication failed."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une carte de paiement"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter votre profil de facturation avec une carte de paiement"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "setAsDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter comme methode de paiement par défaut"])},
        "errors": {
          "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques chose s'est mal passé. Veuillez retenter dans quelques minutes ou contacter notre support."])},
          "generic_decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a été refusée."])},
          "insufficient_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte ne dispose pas de fonds suffisants."])},
          "lost_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a été refusée."])},
          "stolen_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a été refusée."])},
          "expired_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre carte a expirée."])},
          "incorrect_cvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de sécurité de votre carte est incorrect."])},
          "processing_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une error s'est produite durant le traitement de votre carte. Veuillez retenter ultérieurement."])},
          "incorrect_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de votre est invalide."])},
          "setup_intent_authentication_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification a échouée."])}
        }
      }
    }
  })
}
