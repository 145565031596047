export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select another plan"])},
        "per_thirty_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per 30 days"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk space"])}
      },
      "fr": {
        "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un autre plan"])},
        "per_thirty_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour 30 jours"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace disque"])}
      }
    }
  })
}
