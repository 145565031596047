export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage SQL modes"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the SQL modes you wish to activate:"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update configuration"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please reload the page and try again."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les modes SQL"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le ou les modes SQL que vous souhaitez activer :"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la configuration"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez recharger la page et réessayer."])}
      }
    }
  })
}
