// This is a helper to quickly setup a store which manages a collection of data

import { DateTime } from "luxon";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";

import {
  CLEAR,
  ENSURE,
  ENSURE_ONE,
  FETCH,
  PUSH,
  REFRESH,
  HANDLE_FETCH,
  HANDLE_OPERATION,
} from "@/lib/store/action-types";
import {
  ALL,
  FIND,
  FIND_BY,
  FILTER,
  META,
  LATEST_FETCH,
  LAST_FETCH_AT,
} from "@/lib/store/getter-types";
import {
  ADD,
  DELETE,
  MERGE,
  SET_ALL,
  SET_META,
  SET_ONE,
  START_FETCH,
  FETCH_SUCCESS,
  FETCH_ERROR,
  ADD_MANY,
  SPLICE,
} from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  GenericResource,
  RootState,
  CommonState,
  handleFetch,
  startFetch,
  successfulFetch,
  failedFetch,
  handleOperation,
  EnsureOptions,
  shouldRefresh,
} from "@/lib/store/utils";

/** A "state" containing a collection of objects and their optional metadata */
export interface CollectionState<T extends GenericResource>
  extends CommonState<T[]> {
  latestFetch: RemoteOperation<T[]>;
  lastFetchAt: DateTime | null;

  /**
   * `null` means the data should be considered as not fetched.
   * `[]` means there is no remote data.
   */
  values: T[] | null;
  meta: GenericResource;
  idKey: keyof T | null;
}

/** Filter models with a given function */
export function filter<T extends GenericResource>(
  state: CollectionState<T>,
  fn: (val: T) => boolean,
): T[] | null {
  return state.values?.filter(fn) ?? null;
}

/** Look for a given model from a property */
export function findBy<T extends GenericResource>(
  state: CollectionState<T>,
  propName: string,
  propValue: unknown,
): T | null {
  return (
    state.values?.find((v) => (v as GenericResource)[propName] === propValue) ??
    null
  );
}

/** Look for a given model from its ID */
export function find<T extends GenericResource>(
  state: CollectionState<T>,
  id: string,
): T | null {
  return findBy(state, "id", id);
}

/** Insert a value into the store. No check for unicity. */
export function insert<T extends GenericResource>(
  state: CollectionState<T>,
  model: T,
): void {
  if (!state.values) {
    state.values = [];
  }

  state.values.push(model);
}

/**
 * Push many models at once without a check for unicity.
 */
export function insertMany<T extends GenericResource>(
  state: CollectionState<T>,
  models: T[],
): void {
  if (!state.values) {
    state.values = [...models];
  } else {
    state.values.push(...models);
  }
}

/**
 * Update a value into the store.
 * Assumes the presence of an idKey: does nothing otherwise.
 */
export function update<T extends GenericResource>(
  state: CollectionState<T>,
  model: T,
): void {
  const { values, idKey } = state;

  if (!values || !idKey) {
    return;
  }

  state.values = values.map((collectionValue) => {
    return collectionValue[idKey] === model[idKey] ? model : collectionValue;
  });
}

/**
 * If a model is in the store, update it; otherwise insert it.
 * Assumes the presence of an idKey: does nothing otherwise.
 */
export function upsert<T extends GenericResource>(
  state: CollectionState<T>,
  model: T,
): void {
  const { idKey } = state;

  if (idKey && find(state, model[idKey])) {
    update(state, model);
  } else {
    insert(state, model);
  }
}

/**
 * Upserts many models in the store.
 * Assumes the presence of an idKey: does nothing otherwise.
 */
export function merge<T extends GenericResource>(
  state: CollectionState<T>,
  models: T[],
): void {
  models.forEach((model) => upsert(state, model));
}

interface SplicePayload<T> {
  start: number;
  deleteCount: number;
  items?: T[];
}
/**
 * Upserts many models in the store.
 * Assumes the presence of an idKey: does nothing otherwise.
 */
export function splice<T extends GenericResource>(
  state: CollectionState<T>,
  payload: SplicePayload<T>,
): void {
  if (!state.values) {
    state.values = [];
  }

  if (payload.deleteCount && payload.items) {
    state.values.splice(payload.start, payload.deleteCount, ...payload.items);
  } else if (payload.deleteCount) {
    state.values.splice(payload.start, payload.deleteCount);
  } else {
    state.values.splice(payload.start);
  }
}

/**
 * Delete a value from the store. Note: cannot call the function `delete`.
 * Assumes the presence of an idKey: does nothing otherwise.
 */
export function remove<T extends GenericResource>(
  state: CollectionState<T>,
  model: T,
): void {
  const { values, idKey } = state;

  if (!values || !idKey) {
    return;
  }

  state.values = values?.filter((collectionValue) => {
    return !(collectionValue[idKey] === model[idKey]);
  });
}

/** Returns a boilerdplate store for all kind of types types. */
export abstract class BaseCollectionStore<T extends GenericResource>
  implements Module<CollectionState<T>, RootState>
{
  state: CollectionState<T>;
  namespaced = true;

  abstract actions: ActionTree<CollectionState<T>, RootState>;
  abstract mutations: MutationTree<CollectionState<T>>;
  abstract getters: GetterTree<CollectionState<T>, RootState>;

  constructor(...initialValues: T[]) {
    this.state = {
      values: initialValues.length > 0 ? initialValues : null,
      latestFetch: new RemoteOperation<T[]>(),
      lastFetchAt: null,
      meta: {},
      idKey: null,
    };
  }

  static buildActions<T extends GenericResource>(
    actions: ActionTree<CollectionState<T>, RootState> = {},
  ): ActionTree<CollectionState<T>, RootState> {
    return {
      async [ENSURE](context, opts?: EnsureOptions) {
        const staleAfter = opts?.staleAfter;
        const payload = opts?.payload || {};
        const refresh = shouldRefresh(context.state, staleAfter);

        if (refresh) {
          context.dispatch(REFRESH, payload);
        }
      },
      async [ENSURE_ONE](context, ...args) {
        const model = context.getters[FIND](...args);

        if (model) {
          return new RemoteOperation().follow(Promise.resolve(model));
        } else {
          return await context.dispatch(FETCH, ...args);
        }
      },
      [CLEAR](context) {
        context.commit(SET_ALL, null);
        context.commit(SET_META, {});
      },
      [PUSH](context, model) {
        context.commit(ADD, model);

        if (context.state.meta?.pagination?.total_count) {
          context.state.meta.pagination.total_count += 1;
        }
      },
      // No need for a return value: `latestFetch` is in the store
      [HANDLE_FETCH](context, { promise, resolveAction }) {
        handleFetch(context, promise, resolveAction);
      },
      // Other operations are not in the store, so we return the operation
      [HANDLE_OPERATION]: function (
        context,
        { operation, promise, resolveAction },
      ) {
        return handleOperation(operation, context, promise, resolveAction);
      },
      ...actions,
    };
  }

  static buildMutations<T extends GenericResource>(
    mutations: MutationTree<CollectionState<T>> = {},
  ): MutationTree<CollectionState<T>> {
    return {
      [SET_ALL]: (state, values) => (state.values = values),
      [SET_META]: (state, meta) => (state.meta = meta),
      [START_FETCH]: (state, promise) => startFetch(state, promise),
      [FETCH_SUCCESS]: (state, resolved) => successfulFetch(state, resolved),
      [FETCH_ERROR]: (state, error) => failedFetch(state, error),
      [ADD]: upsert,
      [ADD_MANY]: insertMany,
      [MERGE]: merge,
      [SPLICE]: splice,
      [SET_ONE]: update,
      [DELETE]: remove,
      ...mutations,
    };
  }

  static buildGetters<T extends GenericResource>(
    getters: GetterTree<CollectionState<T>, RootState> = {},
  ): GetterTree<CollectionState<T>, RootState> {
    return {
      [LATEST_FETCH](state) {
        return state.latestFetch;
      },
      [LAST_FETCH_AT](state) {
        return state.lastFetchAt;
      },
      [ALL](state) {
        return state.values || [];
      },
      [FIND](state) {
        return (id: string) => find(state, id);
      },
      [FIND_BY](state) {
        return (propName: string, propValue: unknown) =>
          findBy(state, propName, propValue);
      },
      [FILTER](state) {
        return (fn: (val: T) => boolean) => filter(state, fn);
      },
      [META](state) {
        return state.meta;
      },
      ...getters,
    };
  }
}

/** A boilerplate store for a collection of resources with an id key */
export abstract class CollectionStore<
  T extends GenericResource,
> extends BaseCollectionStore<T> {
  constructor(...initialValues: T[]) {
    super(...initialValues);

    this.state.idKey = "id";
  }
}

/**
 * Returns a generic resource store.
 * Meant to be used as a placeholder, or for testing purposes.
 */
export class GenericStore extends CollectionStore<GenericResource> {
  actions = CollectionStore.buildActions<GenericResource>();
  mutations = CollectionStore.buildMutations<GenericResource>();
  getters = CollectionStore.buildGetters<GenericResource>();
}
