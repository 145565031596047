export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "environment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])}
        },
        "variables": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables list"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment variables that will be available in your app containers."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no environment variables yet."])}
        },
        "actions": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New variable"])},
          "bulkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to bulk edit"])},
          "tableMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to table"])}
        },
        "proTip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can alias an existing variable by prepending the $ sign to its name"])},
        "proTip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])}
      },
      "fr": {
        "environment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])}
        },
        "variables": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des variables"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables d'environnement disponible dans vos containers applicatif."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas encore de variable d'environmment"])}
        },
        "actions": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle variable"])},
          "bulkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier en masse"])},
          "tableMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner au tableau"])}
        },
        "proTip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez créer un alias d'une variable existante en faisant précéder son nom par le signe $"])},
        "proTip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple"])}
      }
    }
  })
}
