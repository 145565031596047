export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new user"])},
        "usernameValidations": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The username must:"])},
          "startWithLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start with a letter"])},
          "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be between 6 and 32 characters"])},
          "allowedCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contain only alphanumeric characters and underscores (_)"])}
        },
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
        "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read (default)"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write (optional)"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this user"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A secure password will be generated automatically."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel utilisateur"])},
        "usernameValidations": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur doit :"])},
          "startWithLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer par une lettre"])},
          "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenir entre 6 et 32 caractères"])},
          "allowedCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenir que des caractères alphanumériques et des tiret bas"])}
        },
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
        "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture (par défaut)"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écriture (optionnel)"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter cet utilisateur"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un mot de passe sécurisé sera généré automatiquement."])}
      }
    }
  })
}
