export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review contract"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Published on ", _interpolate(_named("publishedOn"))])},
        "documents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 documents"]), _normalize(["the document"]), _normalize(["all ", _interpolate(_named("count")), " documents"])])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please read carefully ", _interpolate(_named("documents")), " of this contract before accepting it."])},
          "line2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can consult ", _interpolate(_named("pronoun")), " on our website or download the PDFs."])},
          "enrollAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unless otherwise indicated, this contract will automatically be accepted on ", _interpolate(_named("date")), "."])},
          "pronoun": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["it"]), _normalize(["them"])])}
        },
        "article": {
          "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read "])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this article"])},
          "sentenceContractUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for detailed informations on what is changing for you and why."])},
          "sentenceFirstContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for more informations on this contract."])}
        },
        "labels": {
          "documents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I have read ", _interpolate(_named("documents"))])}
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept this contract"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revue d'un contrat"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publié le ", _interpolate(_named("publishedOn"))])},
        "documents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 documents"]), _normalize(["le document"]), _normalize(["les ", _interpolate(_named("count")), " documents"])])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veuillez lire attentivement ", _interpolate(_named("documents")), " de ce contrat avant de l'accepter."])},
          "line2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez ", _interpolate(_named("pronoun")), " lire sur notre site web ou bien télécharger les PDFs."])},
          "enrollAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sauf indication contraire, ce contrat sera automatiquement accepté le ", _interpolate(_named("date")), "."])},
          "pronoun": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["le"]), _normalize(["les"])])}
        },
        "article": {
          "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez "])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cet article"])},
          "sentenceContractUpdate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Consultez ", _interpolate(_named("link")), " pour plus de détails sur ce qui change pour vous et pourquoi."])},
          "sentenceFirstContract": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Consultez ", _interpolate(_named("link")), " pour plus d'informations sur ce contrat."])}
        },
        "labels": {
          "documents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J'ai lu ", _interpolate(_named("documents"))])}
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte ce contrat"])}
      }
    }
  })
}
