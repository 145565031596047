// Generic
export const CLEAR = "clear";
export const ENSURE = "ensure";
export const ENSURE_ONE = "ensureOne";
export const FETCH = "fetch";
export const FETCH_MORE = "fetchMore";
export const PUSH = "push";
export const REFRESH = "refresh";
export const CREATE = "create";
export const UPDATE = "update";
export const DESTROY = "destroy";
export const HANDLE_FETCH = "handleFetch";
export const HANDLE_OPERATION = "handleOperation";
export const HANDLE_PROMISE = "handlePromise";

// Session
export const LOGIN = "login";
export const LOGOUT = "logout";
export const SWITCH_APP = "switchApp";
export const TOGGLE_LOGS_STREAMING = "toggleLogsStreaming";

// App
export const RENAME = "rename";
export const TRANSFER = "transfer";

// Containers
export const RESTART = "restart";
export const SCALE = "scale";

// Addons
export const RESUME = "resume";

// Operations
export const WATCH = "watch";

// User
export const STOP_FREE_TRIAL = "stopFreeTrial";

// Variables
export const BULK_UPDATE = "bulkUpdate";
export const BULK_DELETE = "bulkDelete";

// Deployment cache
export const RESET = "reset";
