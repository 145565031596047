<template>
  <div
    :class="computedBorder"
    class="rounded px-2 py-1 flex text-scale-6"
    :style="widthStyle"
  >
    <div :class="computedClasses">
      {{ query }}
    </div>
    <SCButton
      class="cursor-pointer inline-block my-auto ml-auto"
      kind="neutral"
      @click="$emit('expand')"
    >
      <span class="inline-block align-middle p-1">
        <ShortArrowTop v-if="isOpened" />
        <ShortArrowBottom v-else />
      </span>
    </SCButton>
    <CopyTableButton class="cursor-pointer" @click="copyToClipboard(query)" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ShortArrowBottom from "@/components/atoms/glyphs/ShortArrowBottom.vue";
import ShortArrowTop from "@/components/atoms/glyphs/ShortArrowTop.vue";
import CopyTableButton from "@/components/organisms/buttons/CopyTableButton.vue";
import { copyToClipboard } from "@/lib/clipboard";

export default defineComponent({
  name: "QueryContainer",
  components: {
    CopyTableButton,
    ShortArrowBottom,
    ShortArrowTop,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    widthStyle: {
      type: String,
      required: true,
    },
    isOpened: {
      type: Boolean,
    },
  },
  emits: ["expand"],
  computed: {
    computedClasses() {
      return this.isOpened ? "max-h-36 overflow-y-auto" : "truncate";
    },
    computedBorder() {
      return this.$root.appTheme === "theme-dark"
        ? "border border-scale-6"
        : "border-2";
    },
  },
  methods: {
    copyToClipboard,
  },
});
</script>
