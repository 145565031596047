import { Token } from "scalingo/lib/models/auth";

import { ActionHandler } from "@/lib/handlers/action";
import { destroyToken } from "@/store/tokens";

import type { ComponentPublicInstance } from "vue";

export class DeleteTokenHandler extends ActionHandler<void> {
  keyPath = "tokens.delete";

  constructor(
    component: ComponentPublicInstance,
    readonly token: Token,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.token.name });
    });

    this.on("failure", () => {
      this.notify("error", { name: this.token.name });
    });
  }

  async submit(): Promise<void> {
    this.follow(await destroyToken(this.$store, this.token));
  }
}
