export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled backups"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to enable scheduled backups"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule backups at"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups planifiés"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux activer la planification des backups"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
        "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups planifiés à"])}
      }
    }
  })
}
