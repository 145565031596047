// Generic resource
export const CURRENT = "current";

// Generic collection
export const ALL = "all";
export const FIND = "find";
export const FIND_BY = "findBy";
export const FILTER = "filter";
export const META = "meta";
export const LATEST_FETCH = "latestFetch";
export const LAST_FETCH_AT = "lastFetchAt";

// Session
export const USER_TOKEN = "userToken";
export const LOADING = "loading";

// Deployments
export const ONGOING = "ongoing";
