export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])}
        },
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
      },
      "fr": {
        "headers": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jusqu'au"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])}
        },
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])}
      }
    }
  })
}
