export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deploy your own ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the minimal information required to create an app"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Déployez votre ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les informations requises pour la création d'une app"])}
      }
    }
  })
}
