<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <DomainAddGlyph class="h-6 w-6 text-scale-6"></DomainAddGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      {{ $t("domain") }}:
      <span class="font-medium ml-1">{{
        event.type_data.hostname
      }}</span></template
    >
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import DomainAddGlyph from "@/components/atoms/glyphs/DomainAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "NewDomainEvent",
  components: { DomainAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "New domain added"
  domain: "Domain"
fr:
  title: "Nouveau domaine ajouté"
  domain: "Domaine"
</i18n>
