<template>
  <EmptyCardState v-if="tokens.items.length === 0">
    <template #icon>
      <font-awesome-icon
        icon="circle"
        class="h-4 text-scale-5"
      ></font-awesome-icon>
    </template>
    {{ $t("empty") }}
  </EmptyCardState>
</template>

<script>
import { defineComponent } from "vue";

import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";

export default defineComponent({
  name: "NoToken",
  components: { EmptyCardState },
  props: {
    tokens: Object,
  },
});
</script>

<i18n>
en:
  empty: "No token yet"
fr:
  empty: "Aucun token"
</i18n>
