export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "email": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send notification emails"])}
        },
        "rocket_chat": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocket.Chat"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send notifications through Rocket.Chat"])}
        },
        "slack": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send notifications to slack channels"])}
        },
        "webhook": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a POST request to a url"])}
        },
        "actions": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
        }
      },
      "fr": {
        "email": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des emails de notification"])}
        },
        "rocket_chat": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocket.Chat"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les notifications via Rocket.Chat"])}
        },
        "slack": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les notifications à des canaux slacks"])}
        },
        "webhook": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des requêtes POST vers une url"])}
        },
        "actions": {
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
        }
      }
    }
  })
}
