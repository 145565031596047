<template>
  <SCBanner align="center" :dismissible="true" @dismiss="$emit('dismiss')">
    <i18n keypath="info">
      {{ $t("info") }}
      <router-link :to="hdsRoute" class="underline text-primary-2">
        {{ $t("link") }}
      </router-link>
      {{ $t("info2") }} {{ outdatedContactDate }}
    </i18n>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { addToDateTime } from "@/lib/utils/time";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ContactNearlyOutdatedBanner",
  components: { SCBanner },
  props: {
    contract: Object,
    contactLastUpdate: String,
  },
  emits: ["dismiss"],
  data() {
    return {
      hdsRoute: {
        name: Routes.Account.HDS,
      },
    };
  },
  computed: {
    outdatedContactDate() {
      return addToDateTime(this.contactLastUpdate, { years: 1 }, "D");
    },
  },
  methods: {
    addToDateTime,
  },
});
</script>

<i18n>
  en:
    info: "Hi! You are hosting HDH applications and this implies a periodic verification of the {link}"
    link: "point of contact health professional information."
    info2: "This check must be carried out before {date}"
  fr:
    info: "Bonjour ! Vous hébergez des applications HDS et cela implique une vérification régulière des {link}"
    link: "informations du point de contact professionnel de santé."
    info2: "Cette vérification doit être réalisée avant le {date}"
</i18n>
