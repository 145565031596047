import { DateTime } from "luxon";
import Rollbar from "rollbar";

import { debug } from "@/lib/utils/log";

export type PreProcessor = () => void;
export type PostProcessor = (response: Response) => Response;

export interface Middleware {
  call: (rollbar: Rollbar) => Promise<Response>;
  name: string;
}

export interface MiddlewareError {
  error: string;
  reason?: "timeout";
}

export type MiddlewareErrors = Record<string, MiddlewareError | void>;

export interface Fallback {
  call: (errors: MiddlewareErrors) => void;
  name: string;
}

export interface JWTHeader {
  type: string;
  alg: string;
}

export interface JWTPayload {
  exp: number;
  iat: number;
  iss: string;
  rnd: string;
  scopes: string[];
  uuid: string;
}

export type OAuthAssistedMessage =
  | {
      error: string;
    }
  | {
      assisted_token: string;
      scopes: string[];
      token_type: string;
    };

export interface Response {
  token: string;
  header: JWTHeader;
  payload: JWTPayload;
  attemptedRoute?: string | null;
}

// storage keys
export const ROUTE_PATH = "Auth.AttemptedRoute";
export const TOKEN_PATH = "Auth.TOKEN";
export const AUTHORIZED_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

export function decodeBase64(input: string): unknown {
  const sanitized = input.replace(/-/g, "+").replace(/_/g, "/");

  return JSON.parse(window.atob(sanitized));
}

export function decodeToken(token: string): Response {
  const parts = token.split(".");

  return {
    token: token,
    header: decodeBase64(parts[0]) as JWTHeader,
    payload: decodeBase64(parts[1]) as JWTPayload,
  };
}

export function shouldRefreshToken(
  payload: JWTPayload,
  gracePeriod = 300,
): boolean {
  const value = payload.exp - gracePeriod < DateTime.local().toSeconds();

  debug(`?> Auth: token ${value ? "soon invalid" : "still valid"}`);

  return value;
}

/**
 * Converts a hash response to an object
 * @param hash
 * @return hash object or false
 */
export function readHash(hash: string): Record<string, string> {
  const hsh = hash.slice(1);
  const obj: Record<string, string> = {};

  if (hsh) {
    const list = hsh.split("&");

    for (const kv of list) {
      const [key, val] = kv.split("=");
      obj[key] = decodeURIComponent(val);
    }
  }

  return obj;
}

export function getAndSaveUtms(): void {
  const getParams = new URLSearchParams(document.location.search);

  for (const [key, value] of getParams.entries()) {
    if (AUTHORIZED_PARAMS.includes(key)) {
      localStorage.setItem(key, value);
    }
  }
}

export function addSignupRouteAndUtmsParams(): string {
  const urlParams = new URLSearchParams();

  AUTHORIZED_PARAMS.forEach((param) => {
    const item = localStorage.getItem(param);
    if (item) {
      urlParams.append(param, item);
      localStorage.removeItem(param);
    }
  });

  return urlParams.size > 0 ? "/users/sign_up?" + urlParams.toString() : "";
}
