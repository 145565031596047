<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')"></CardHeader>

    <p class="mt-2 text-scale-7">
      {{ $t("text_1") }}
      <br />
      {{ $t("text_2") }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "C3BillingCard",
  components: {
    CardHeader,
  },
});
</script>

<i18n>
en:
  title: "Billing profile"
  text_1: "Your billing profile is managed by the C3 market administrator."
  text_2: "Please contact him if you have any questions about monitoring of your consumption or billing."
fr:
  title: "Profil de facturation"
  text_1: "Votre profil de facturation est géré par l'administrateur du marché C3."
  text_2: "Veuillez le contacter pour toute question relative au suivi de votre consommation ou de votre facturation."
</i18n>
