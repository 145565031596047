<template>
  <div class="flex justify-end flex-grow">
    <RangeSelectors
      :items="items"
      :selectedValue="selectedValue"
      @selected="(e) => $emit('rangeSelected', e)"
    ></RangeSelectors>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import RangeSelectors from "@/components/atoms/selectors/RangeSelectors.vue";

export default defineComponent({
  name: "DateSelector",
  components: { RangeSelectors },
  props: {
    selectedValue: [String, Number],
  },
  emits: ["rangeSelected"],
  data() {
    return {
      items: [
        { value: "3", text: "3h" },
        { value: "12", text: "12h" },
        { value: "24", text: "24h" },
        { value: "48", text: "48h" },
        { value: "72", text: "72h" },
      ],
    };
  },
});
</script>
