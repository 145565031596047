import { ContainerSize } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { HANDLE_FETCH, REFRESH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class ContainerSizesStore extends CollectionStore<ContainerSize> {
  actions = CollectionStore.buildActions<ContainerSize>({
    async [REFRESH](context, payload = {}) {
      const { regionCode } = payload;
      const promise = scalingoClient(
        context,
        regionCode,
      ).Containers.availableSizes();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ALL,
      });
    },
  });

  mutations = CollectionStore.buildMutations<ContainerSize>();
  getters = CollectionStore.buildGetters<ContainerSize>();
}
export const ContainerSizes = buildMapping(
  new ContainerSizesStore(),
  "containerSizes",
);

export function listContainerSizes(
  store: ApplicationStore,
  regionCode: string,
  opts?: Partial<ListItemsOptions<ContainerSize>>,
): CollectionWithFetch<ContainerSize> {
  const itemsGetter = `${regionCode}/${ContainerSizes.getters.ALL}`;
  const fetchGetter = `${regionCode}/${ContainerSizes.getters.LATEST_FETCH}`;

  return {
    items: listItems(store.getters[itemsGetter], opts),
    latestFetch: store.getters[fetchGetter],
  };
}

export function ensureContainerSizes(
  store: ApplicationStore,
  regionCode: string,
  opts: EnsureOptions = {},
): void {
  opts.payload = opts.payload || {};
  opts.payload.regionCode = regionCode;

  store.dispatch(`${regionCode}/${ContainerSizes.actions.ENSURE}`, opts);
}
