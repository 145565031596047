export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral program"])},
        "earn": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earn 20% of what they use"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a person signs up with your referral link and spends something on the platform, each month you'll get 20% of what they spent in credit, during 12 months after their account creation."])}
        },
        "share": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share you referral link"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy your personal referral link and share it with your friends and followers: "])},
          "tweet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Easily deploy your app on ", "@", "ScalingoHQ in less than 2 minutes. Sign up with my link ", _interpolate(_named("link")), " #PaaS"])}
        },
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
          "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share on Twitter"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de parrainage"])},
        "earn": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagnez 20% de leur consommation"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si une personne créée un compte avec votre lien de parrainage et utilise la plateforme, chaque mois vous gagnerez 20% de leurs dépenses en crédit (pendant les 12 premiers mois)."])}
        },
        "share": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez votre lien de parrainage"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiez votre lien de parrainage et partagez le avec vos amis et followers: "])},
          "tweet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Déployez facilement votre app sur ", "@", "ScalingoHQ en moins de 2 minutes. Inscrivez-vous avec mon lien ", _interpolate(_named("link")), " #PaaS"])}
        },
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
          "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager sur Twitter"])}
        }
      }
    }
  })
}
