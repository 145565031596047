export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New invitation to collaborate"])},
        "sent_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent to"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle invitation à collaborer"])},
        "sent_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé à"])}
      }
    }
  })
}
