// No point in changing this now; will refactor later.
import { App } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { PUSH, REFRESH } from "@/lib/store/action-types";
import { BaseCollectionStore } from "@/lib/store/collection-store";
import { ADD_MANY, SET_ALL, SPLICE } from "@/lib/store/mutation-types";
import { buildMapping, listItems } from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { Session } from "./session";

import { ApplicationStore } from ".";

const LINES_CAP = 500;

//@ts-expect-error string is not supported in theory, but it works
export class LogsStore extends BaseCollectionStore<string> {
  //@ts-expect-error string is not supported in theory, but it works
  actions = BaseCollectionStore.buildActions<string>({
    async [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      const response = await scalingoClient(
        context,
        currentApp.region,
      ).Logs.for(currentApp.id, {
        count: 100,
      });

      const logs = response.split("\n");

      context.commit(SET_ALL, logs);
    },
    [PUSH](context, lines) {
      context.commit(ADD_MANY, lines);

      const len = context.state.values?.length as number;

      // If we go over the limit, we truncate from the start (= remove the oldest lines)
      if (len > LINES_CAP) {
        const deleteCount = len - LINES_CAP;
        context.commit(SPLICE, { start: 0, deleteCount });
      }
    },
  });

  //@ts-expect-error string is not supported in theory, but it works
  mutations = BaseCollectionStore.buildMutations<string>();
  //@ts-expect-error string is not supported in theory, but it works
  getters = BaseCollectionStore.buildGetters<string>();
}

export const Logs = buildMapping(new LogsStore(), "logs");

export function listLogs(store: ApplicationStore): string[] | null {
  return listItems(store.getters[Logs.getters.ALL]);
}

export function ensureLogs(store: ApplicationStore): void {
  store.dispatch(Logs.actions.ENSURE);
}

export function clearLogs(store: ApplicationStore): void {
  store.dispatch(Logs.actions.CLEAR);
}

export function toggleLogStreaming(
  store: ApplicationStore,
  newValue: boolean,
): void {
  const currentApp = useCurrentAppStore().regional as App;

  store.dispatch(Session.actions.TOGGLE_LOGS_STREAMING, {
    app: currentApp,
    stream: newValue,
  });
}
