import { App, Archive, ArchivesResult } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { FETCH_MORE, HANDLE_FETCH, REFRESH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { MERGE, SET_ALL, SET_META } from "@/lib/store/mutation-types";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export class LogArchivesStore extends CollectionStore<Archive> {
  constructor(...initialValues: Archive[]) {
    super(...initialValues);
    this.state.idKey = "from";
  }

  actions = CollectionStore.buildActions<Archive>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      const promise = scalingoClient(context, currentApp.region).Logs.archives(
        currentApp.id,
      );

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction(resolved: ArchivesResult) {
          context.commit(SET_ALL, resolved.archives);
          context.commit(SET_META, {
            hasMore: resolved.has_more,
            nextCursor: resolved.next_cursor,
          });
        },
      });
    },
    [FETCH_MORE](context) {
      const currentApp = useCurrentAppStore().regional as App;

      if (!context.state.meta.nextCursor) {
        return;
      }

      const promise = scalingoClient(context, currentApp.region).Logs.archives(
        currentApp.id,
        context.state.meta.nextCursor,
      );

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: function (resolved: ArchivesResult) {
          context.commit(MERGE, resolved.archives);
          context.commit(SET_META, {
            hasMore: resolved.has_more,
            nextCursor: resolved.next_cursor,
          });
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<Archive>();
  getters = CollectionStore.buildGetters<Archive>();
}

export const LogArchives = buildMapping(new LogArchivesStore(), "logArchives");

export function listArchives(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Archive>>,
): CollectionWithFetch<Archive> {
  return {
    items: listItems(store.getters[LogArchives.getters.ALL], opts),
    latestFetch: store.getters[LogArchives.getters.LATEST_FETCH],
    meta: store.getters[LogArchives.getters.META],
  };
}

export function ensureArchives(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(LogArchives.actions.ENSURE, opts);
}

export function fetchMoreArchives(store: ApplicationStore): void {
  store.dispatch(LogArchives.actions.FETCH_MORE);
}
