import { Deployment } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { triggerDeploymentFromBranch } from "@/store/deployments";

export class CreateDeploymentHandler extends ActionHandler<Deployment> {
  keyPath = "deployments.manual";

  dispatchEvents(): void {
    this.on("failure", () => this.notify("error"));
  }

  async submit(branch: string): Promise<void> {
    this.follow(await triggerDeploymentFromBranch(this.$store, branch));
  }
}
