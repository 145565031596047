export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My SSH Keys"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH keys allow to recognize actions as legitimate"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the fingerprint of your SSH key via "])},
        "tipCommand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh-add -l -E md5"])},
        "tipDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes clés SSH"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés SSH autorisent à reconnaitre des actions comme légitimes"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez l'empreinte digitale de votre clé SSH"])},
        "tipCommand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh-add -l -E md5"])},
        "tipDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])}
      }
    }
  })
}
