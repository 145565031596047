export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "requiredMaintenanceSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An operation will take place during the next Maintenance Window: ", _interpolate(_named("day")), ", between ", _interpolate(_named("h1")), " and ", _interpolate(_named("h2")), " UTC. ", _interpolate(_named("link"))])},
        "requiredMaintenancePlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Operations will take place during the next Maintenance Window: ", _interpolate(_named("day")), ", between ", _interpolate(_named("h1")), " and ", _interpolate(_named("h2")), " UTC. ", _interpolate(_named("link"))])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])}
      },
      "fr": {
        "requiredMaintenanceSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une opération aura lieu au cours de la prochaine fenêtre de maintenance : ", _interpolate(_named("day")), ", entre ", _interpolate(_named("h1")), " et ", _interpolate(_named("h2")), " UTC. ", _interpolate(_named("link"))])},
        "requiredMaintenancePlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Des opérations auront lieu au cours de la prochaine fenêtre de maintenance : ", _interpolate(_named("day")), ", entre ", _interpolate(_named("h1")), " et ", _interpolate(_named("h2")), " UTC. ", _interpolate(_named("link"))])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
      }
    }
  })
}
