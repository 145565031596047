export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption from "])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. VAT"])}
      },
      "fr": {
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
        "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation du "])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. TVA"])}
      }
    }
  })
}
