export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force HTTPS"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically redirect HTTP traffic to HTTPS for all domains associated with this application."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force HTTPS"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer HTTPS"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirige automatiquement le traffic HTTP vers HTTPS pour tous les domaines de l'application."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcer HTTPS"])}
      }
    }
  })
}
