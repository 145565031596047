import { User } from "scalingo/lib/models/auth";
import {
  App,
  SCMRepoLink as SCMRepoLinkModel,
  SCMType,
} from "scalingo/lib/models/regional";
import {
  CreateParams,
  UpdateParams,
} from "scalingo/lib/params/regional/scm_repo_links";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  HANDLE_FETCH,
  CREATE,
  DESTROY,
  HANDLE_PROMISE,
  UPDATE,
  HANDLE_OPERATION,
} from "@/lib/store/action-types";
import {
  FETCH_ERROR,
  FETCH_SUCCESS,
  SET_ONE,
} from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { ResourceStore } from "@/lib/store/resource-store";
import {
  buildMapping,
  EnsureOptions,
  ResourceWithFetch,
} from "@/lib/store/utils";
import { ApplicationStore } from "@/store";
import { useCurrentAppStore } from "@/stores/current/app";

interface SCMRepoLinkCommon {
  scm_type: SCMType;
  url: string;
  owner: string;
  repo: string;
}

export type SCMRepoLinkAdditions = {
  _isGithub: boolean;
  _isGitlab: boolean;
};

export type SCMRepoLink = SCMRepoLinkModel & SCMRepoLinkAdditions;

export function enhanceOne<T extends SCMRepoLinkCommon>(
  item: T,
): T & SCMRepoLinkAdditions {
  return {
    ...item,
    _isGithub: ["github", "github-enterprise"].includes(item.scm_type),
    _isGitlab: ["gitlab", "self-hosted-gitlab"].includes(item.scm_type),
    _ownerUrl: `${item.url}/${item.owner}`,
    _repoUrl: `${item.url}/${item.owner}/${item.repo}`,
  };
}

export class ScmRepoLinkStore extends ResourceStore<SCMRepoLink> {
  actions = ResourceStore.buildActions<SCMRepoLink>({
    [REFRESH](context, opts = {}) {
      const app = opts.app || (useCurrentAppStore().regional as App);
      const promise = scalingoClient(context, app.region)
        .SCMRepoLinks.find(app.id)
        .then(enhanceOne);

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [CREATE](context, opts = {}) {
      const app = opts.app || (useCurrentAppStore().regional as App);
      const promise = scalingoClient(context, app.region)
        .SCMRepoLinks.create(app.id, opts.payload)
        .then(enhanceOne);

      return context.dispatch(HANDLE_PROMISE, {
        promise,
        resolveAction(resolved: SCMRepoLink) {
          context.commit(SET_ONE, resolved);
          context.commit(FETCH_SUCCESS, resolved);
        },
      });
    },
    [UPDATE](context, opts = {}) {
      const app = useCurrentAppStore().regional as App;
      const promise = scalingoClient(context, app.region)
        .SCMRepoLinks.update(app.id, opts)
        .then(enhanceOne);

      const action = opts._promiseInfo ? HANDLE_PROMISE : HANDLE_OPERATION;

      return context.dispatch(action, {
        promise,
        resolveAction(resolved: SCMRepoLink) {
          context.commit(SET_ONE, resolved);
          context.commit(FETCH_SUCCESS, resolved);
        },
      });
    },
    [DESTROY](context, opts = {}) {
      const app = opts.app || (useCurrentAppStore().regional as App);
      const promise = scalingoClient(context, app.region).SCMRepoLinks.destroy(
        app.id,
      );

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction() {
          context.commit(SET_ONE, null);
          context.commit(FETCH_ERROR, null);
        },
      });
    },
  });

  mutations = ResourceStore.buildMutations<SCMRepoLink>();
  getters = ResourceStore.buildGetters<SCMRepoLink>();
}

export const ScmRepoLink = buildMapping(new ScmRepoLinkStore(), "scmRepoLink");

export function scmRepoLink(
  store: ApplicationStore,
): ResourceWithFetch<SCMRepoLink> {
  return {
    value: store.getters[ScmRepoLink.getters.CURRENT],
    latestFetch: store.getters[ScmRepoLink.getters.LATEST_FETCH],
  };
}

export function ensureScmRepoLink(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(ScmRepoLink.actions.ENSURE, opts);
}

export function createScmRepoLink(
  store: ApplicationStore,
  app: App,
  payload: CreateParams,
): Promise<RemoteOperation<SCMRepoLink>> {
  return store.dispatch(ScmRepoLink.actions.CREATE, { app, payload });
}

export function removeSCMRepoLink(
  store: ApplicationStore,
  app: App,
): Promise<RemoteOperation<void>> {
  return store.dispatch(ScmRepoLink.actions.DESTROY, { app });
}

export function disableAutoDeployForSCMRepoLink(
  store: ApplicationStore,
): Promise<RemoteOperation<void>> {
  return store.dispatch(ScmRepoLink.actions.UPDATE, {
    _promiseInfo: true,
    auto_deploy_enabled: false,
  });
}

export function enableAutoDeployForSCMRepoLink(
  store: ApplicationStore,
  branch: string,
): Promise<RemoteOperation<void>> {
  return store.dispatch(ScmRepoLink.actions.UPDATE, {
    _promiseInfo: true,
    auto_deploy_enabled: true,
    branch: branch,
  });
}

export function setSCMRepoLinker(
  store: ApplicationStore,
  user: User,
): Promise<RemoteOperation<void>> {
  return store.dispatch(ScmRepoLink.actions.UPDATE, {
    linker_id: user.id,
  });
}

export function updateSCMRepoLink(
  store: ApplicationStore,
  payload: UpdateParams,
): Promise<RemoteOperation<SCMRepoLink>> {
  return store.dispatch(ScmRepoLink.actions.UPDATE, payload);
}
