export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployments via "])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployed code configuration."])},
        "linkedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to"])},
        "linkedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked by"])},
        "setMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set me as linker"])},
        "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration is being updated..."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements via "])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration du code à déployer."])},
        "linkedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé à"])},
        "linkedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé par"])},
        "setMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser mon compte pour associer le dépot"])},
        "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désassocier"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration en cours de mise à jour..."])}
      }
    }
  })
}
