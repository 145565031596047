import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

/* eslint-disable import/order */
import AppRoot from "@/controllers/app/Root.vue";
import AppOverview from "@/controllers/app/Overview.vue";
import AppResources from "@/controllers/app/Resources.vue";
import AppDeployRoot from "@/controllers/app/deploy/Root.vue";
import AppDeployListRoot from "@/controllers/app/deploy/list/Root.vue";
import AppDeployListAll from "@/controllers/app/deploy/list/All.vue";
import AppDeployListShow from "@/controllers/app/deploy/list/Show.vue";
import AppDeployConfig from "@/controllers/app/deploy/Configuration.vue";
import AppDeployManual from "@/controllers/app/deploy/Manual.vue";
import AppReviewAppsRoot from "@/controllers/app/reviewApps/Root.vue";
import AppReviewAppsConfiguration from "@/controllers/app/reviewApps/Configuration.vue";
import AppReviewAppsList from "@/controllers/app/reviewApps/List.vue";
import AppReviewAppsManual from "@/controllers/app/reviewApps/Manual.vue";
import AppActivityRoot from "@/controllers/app/activity/Root.vue";
import AppActivityAll from "@/controllers/app/activity/All.vue";
import AppActivityShow from "@/controllers/app/activity/Show.vue";
import AppLogsRoot from "@/controllers/app/LogsRoot.vue";
import AppLogsLive from "@/controllers/app/LogsLive.vue";
import AppLogsArchives from "@/controllers/app/LogsArchives.vue";
import AppMetricsRoot from "@/controllers/app/metrics/Root.vue";
import AppMetricsOverview from "@/controllers/app/metrics/Overview.vue";
import AppMetricsPerContainer from "@/controllers/app/metrics/PerContainer.vue";
import AppMetricsApexOverview from "@/controllers/app/metrics/ApexOverview.vue";
import AppMetricsApexPerContainer from "@/controllers/app/metrics/ApexPerContainer.vue";

// App-settings related routes
import AppSettingsRoot from "@/controllers/app/SettingsRoot.vue";
import AppEnvironment from "@/controllers/app/Environment.vue";
import AppCollaborators from "@/controllers/app/Collaborators.vue";
import AppDomainsRoot from "@/controllers/app/domains/Root.vue";
import AppDomainsIndex from "@/controllers/app/domains/Index.vue";
import AppDomainsShow from "@/controllers/app/domains/Show.vue";
import AppNotifiersRoot from "@/controllers/app/notifiers/Root.vue";
import AppNotifiersList from "@/controllers/app/notifiers/List.vue";
import AppNotifiersShow from "@/controllers/app/notifiers/Show.vue";
import AppNotifiersCreation from "@/controllers/app/notifiers/Creation.vue";
import AppNotifiersEditInfos from "@/controllers/app/notifiers/edit/Infos.vue";
import AppNotifiersEditEvents from "@/controllers/app/notifiers/edit/Events.vue";

import AppAlertsRoot from "@/controllers/app/alerts/Root.vue";
import AppAlertsList from "@/controllers/app/alerts/List.vue";
import AppAlertsCreation from "@/controllers/app/alerts/Creation.vue";
import AppAlertsEditTriggers from "@/controllers/app/alerts/edit/Triggers.vue";
import AppAlertsEditNotifiers from "@/controllers/app/alerts/edit/Notifiers.vue";
import AppRouting from "@/controllers/app/Routing.vue";
import AppManagement from "@/controllers/app/Management.vue";

import NotFoundError from "@/controllers/misc/NotFoundError.vue";

import i18n from "@/i18n";
import { AppRoutes as R } from "@/router/app/names";
import { childRoutesForOAuthLinking } from "@/router/utils";
import { createAddonRoutes, editAddonRoutes } from "./addons";
import { appDbRoutes } from "@/router/app/db";

export const appRoutes: RouteRecordRaw = {
  path: "/apps/:region/:id",
  component: AppRoot,
  name: R.Root,
  redirect: (to) => {
    const { params, query } = to;
    return { name: R.Overview, params, query };
  },
  meta: {
    title: ({ params }: RouteLocationNormalized) => {
      return i18n.global.t("titles.app.root", {
        name: params.id,
      });
    },
  },
  children: [
    {
      path: "",
      name: R.Overview,
      component: AppOverview,
      meta: {
        title: i18n.global.t("titles.app.overview"),
      },
    },
    {
      path: "resources",
      name: R.Resources,
      component: AppResources,
      meta: {
        title: i18n.global.t("titles.app.resources"),
      },
    },
    {
      path: "deploy",
      name: R.Deploy.Root,
      component: AppDeployRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Deploy.List.Root, params, query };
      },
      children: [
        {
          path: "config",
          name: R.Deploy.Configuration,
          component: AppDeployConfig,
          meta: {
            title: i18n.global.t("titles.app.deploy.config"),
          },
          children: childRoutesForOAuthLinking(R.Deploy.Configuration),
        },
        {
          path: "manual",
          name: R.Deploy.Manual,
          component: AppDeployManual,
          meta: {
            title: i18n.global.t("titles.app.deploy.manual"),
          },
        },
        {
          path: "",
          name: R.Deploy.List.Root,
          component: AppDeployListRoot,
          redirect: (to) => {
            const { params, query } = to;
            return { name: R.Deploy.List.All, params, query };
          },
          children: [
            {
              path: "list",
              name: R.Deploy.List.All,
              component: AppDeployListAll,
              meta: {
                title: i18n.global.t("titles.app.deploy.list"),
              },
            },
            {
              path: ":deploymentId",
              name: R.Deploy.List.Show,
              component: AppDeployListShow,
              meta: {
                title: i18n.global.t("titles.app.deploy.show"),
              },
            },
          ],
        },
      ],
    },
    {
      path: "review-apps",
      name: R.ReviewApps.Root,
      component: AppReviewAppsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.ReviewApps.List, params, query };
      },
      children: [
        {
          path: "config",
          name: R.ReviewApps.Configuration,
          component: AppReviewAppsConfiguration,
          meta: {
            title: i18n.global.t("titles.app.reviewApps.config"),
          },
        },
        {
          path: "list",
          name: R.ReviewApps.List,
          component: AppReviewAppsList,
          meta: {
            title: i18n.global.t("titles.app.reviewApps.list"),
          },
        },
        {
          path: "manual",
          name: R.ReviewApps.Manual,
          component: AppReviewAppsManual,
          meta: {
            title: i18n.global.t("titles.app.reviewApps.manual"),
          },
        },
      ],
    },
    {
      path: "activity",
      name: R.Activity.Root,
      component: AppActivityRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Activity.All, params, query };
      },
      children: [
        {
          path: "",
          name: R.Activity.All,
          component: AppActivityAll,
          meta: {
            title: i18n.global.t("titles.app.activity.list"),
          },
        },
        {
          path: ":eventId",
          name: R.Activity.Show,
          component: AppActivityShow,
          meta: {
            title: i18n.global.t("titles.app.activity.show"),
          },
        },
      ],
    },
    {
      path: "logs",
      name: R.Logs.Root,
      component: AppLogsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Logs.Live, params, query };
      },
      children: [
        {
          path: "",
          name: R.Logs.Live,
          component: AppLogsLive,
          meta: {
            title: i18n.global.t("titles.app.logs.live"),
          },
        },
        {
          path: "archives",
          name: R.Logs.Archives,
          component: AppLogsArchives,
          meta: {
            title: i18n.global.t("titles.app.logs.archives"),
          },
        },
      ],
    },
    {
      path: "metrics",
      name: R.Metrics.Root,
      component: AppMetricsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Metrics.Overview, params, query };
      },
      children: [
        {
          path: "",
          name: R.Metrics.Overview,
          component: AppMetricsOverview,
          meta: {
            title: i18n.global.t("titles.app.metrics"),
          },
        },
        {
          path: ":containerType",
          name: R.Metrics.PerContainer,
          component: AppMetricsPerContainer,
          meta: {
            title: i18n.global.t("titles.app.metrics"),
          },
        },
      ],
    },
    {
      path: "apex",
      component: AppMetricsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Metrics.ApexOverview, params, query };
      },
      children: [
        {
          path: "",
          name: R.Metrics.ApexOverview,
          component: AppMetricsApexOverview,
          meta: {
            title: i18n.global.t("titles.app.metrics"),
          },
        },
        {
          path: ":containerType",
          name: R.Metrics.ApexPerContainer,
          component: AppMetricsApexPerContainer,
          meta: {
            title: i18n.global.t("titles.app.metrics"),
          },
        },
      ],
    },
    {
      path: "environment",
      name: R.Environment,
      component: AppEnvironment,
      meta: {
        title: i18n.global.t("titles.app.environment"),
      },
    },
    {
      path: "settings",
      name: R.Settings,
      component: AppSettingsRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Collaborators, params, query };
      },
      children: [
        {
          path: "collaborators",
          name: R.Collaborators,
          component: AppCollaborators,
          meta: {
            title: i18n.global.t("titles.app.collaborators"),
          },
        },
        {
          path: "domains",
          name: R.Domains.Root,
          component: AppDomainsRoot,
          redirect: (to) => {
            const { params, query } = to;
            return { name: R.Domains.Index, params, query };
          },
          children: [
            {
              path: "",
              name: R.Domains.Index,
              component: AppDomainsIndex,
              meta: {
                title: i18n.global.t("titles.app.domains"),
              },
            },
            {
              path: ":domainId",
              name: R.Domains.Show,
              component: AppDomainsShow,
              meta: {
                title: i18n.global.t("titles.app.domains"),
              },
            },
          ],
        },
        {
          path: "notifiers",
          name: R.Notifiers.Root,
          component: AppNotifiersRoot,
          redirect: (to) => {
            const { params, query } = to;
            return { name: R.Notifiers.List, params, query };
          },
          children: [
            {
              path: "",
              name: R.Notifiers.List,
              component: AppNotifiersList,
            },
            {
              path: "new/:platformName?",
              name: R.Notifiers.Creation,
              component: AppNotifiersCreation,
            },
            {
              path: ":notifierId",
              name: R.Notifiers.Show,
              component: AppNotifiersShow,
            },
            {
              path: ":notifierId/edit",
              name: R.Notifiers.Edit.Infos,
              component: AppNotifiersEditInfos,
            },
            {
              path: ":notifierId/events",
              name: R.Notifiers.Edit.Events,
              component: AppNotifiersEditEvents,
            },
          ],
        },
        {
          path: "alerts",
          name: R.Alerts.Root,
          component: AppAlertsRoot,
          redirect: (to) => {
            const { params, query } = to;
            return { name: R.Alerts.List, params, query };
          },
          children: [
            {
              path: "",
              name: R.Alerts.List,
              component: AppAlertsList,
            },
            {
              path: "new",
              name: R.Alerts.Creation,
              component: AppAlertsCreation,
            },
            {
              path: ":alertId/edit",
              name: R.Alerts.Edit.Triggers,
              component: AppAlertsEditTriggers,
            },
            {
              path: ":alertId/notifiers",
              name: R.Alerts.Edit.Notifiers,
              component: AppAlertsEditNotifiers,
            },
          ],
        },
        {
          path: "routing",
          name: R.Routing,
          component: AppRouting,
          meta: {
            title: i18n.global.t("titles.app.routing"),
          },
        },
        {
          path: "management",
          name: R.Management,
          component: AppManagement,
          meta: {
            title: i18n.global.t("titles.app.managements"),
          },
        },
      ],
    },
    editAddonRoutes,
    createAddonRoutes,
    appDbRoutes,
    {
      path: ":pathMatch(.*)*",
      component: NotFoundError,
      name: R.NotFoundError,
      meta: {
        title: i18n.global.t("titles.app.notFound"),
      },
    },
  ],
};
