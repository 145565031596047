<template>
  <SCTable
    class="mt-6"
    layout="auto"
    :headers="headers"
    :items="alerts.items"
    :loading="alerts.latestFetch.isLoading"
    :perPage="2"
    @sortChanged="(e) => $emit('sortChanged', e)"
  >
    <template #item.process="{ item }">
      <span class="font-bold">
        {{ item.container_type }}
      </span>
    </template>

    <template #item.name="{ item }">
      <AlertItem :alert="item"></AlertItem>
    </template>

    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <SCTableAction
          :title="$t('title.edit')"
          :rowIndex="index"
          class="ml-2"
          @click="editAlert(item)"
        >
          <EditGlyph class="h-4 w-4" />
        </SCTableAction>

        <SCTableAction
          :title="$t('title.delete')"
          type="delete"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('startAlertDeletion', { alert: item })"
        >
          <BinGlyph class="h-4 w-4" />
        </SCTableAction>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import EditGlyph from "@/components/atoms/glyphs/EditGlyph.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import AlertItem from "@/components/parts/appSettings/alertsSettings/AlertItem.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AlertsList",
  components: {
    SCTableAction,
    BinGlyph,
    AlertItem,
    SCTable,
    EditGlyph,
  },
  props: {
    alerts: Object,
    app: Object,
  },
  emits: ["sortChanged", "startAlertDeletion"],
  data() {
    return {
      headers: [
        { value: "process", text: "", class: "w-12" },
        { value: "name", text: this.$t(`headers.name`) },
        { value: "actions", text: "", align: "right" },
      ],
    };
  },
  methods: {
    editAlert(alert) {
      this.$router.push({
        name: Routes.App.Alerts.Edit.Triggers,
        params: {
          id: this.app.name,
          region: this.app.region,
          alertId: alert.id,
        },
      });
    },
  },
});
</script>

<i18n>
en:
  headers:
    name: "Trigger condition"
  title:
    delete: "Delete alert"
    edit: "Edit alert"
fr:
  headers:
    name: "Condition de déclenchement"
  title:
    delete: "Supprimer l'alerte"
    edit: "Modifier l'alerte"
</i18n>
