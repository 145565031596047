import { setLocale } from "@vee-validate/i18n";
import { Settings } from "luxon";
import { App } from "scalingo/lib/models/regional";
import {
  ActionTree,
  MutationTree,
  GetterTree,
  Module,
  ActionContext,
} from "vuex";

import {
  initLogsStreamer,
  teardownLogsStreamer,
} from "@/lib/scalingo/streamers/logs";
import { LOGIN, LOGOUT, TOGGLE_LOGS_STREAMING } from "@/lib/store/action-types";
import { USER_TOKEN } from "@/lib/store/getter-types";
import { SET_TOKEN, CLEAR_TOKEN } from "@/lib/store/mutation-types";
import { buildMapping, RootState } from "@/lib/store/utils";
import { Nullable } from "@/lib/utils/types";
import { ApplicationStore } from "@/store";
import { updatePreferences } from "@/store/user";
import { useLocaleStore } from "@/stores/locale-store";
import { useSessionStore } from "@/stores/session";

import type { VueI18n } from "vue-i18n";

/** The session state object */
export type SessionState = Nullable<{
  userToken: string;
}>;

export class SessionStore implements Module<SessionState, RootState> {
  namespaced = true;
  state = {
    userToken: null,
  };
  actions: ActionTree<SessionState, RootState> = {
    [LOGIN](
      context: ActionContext<SessionState, RootState>,
      token: string,
    ): void {
      if (token) {
        const store = useSessionStore();
        store.userToken = token;
        context.commit(SET_TOKEN, token);
      }
    },
    [LOGOUT](context: ActionContext<SessionState, RootState>): void {
      const store = useSessionStore();
      store.userToken = null;
      context.commit(CLEAR_TOKEN);
    },
    async [TOGGLE_LOGS_STREAMING](
      context: ActionContext<SessionState, RootState>,
      { app, stream }: { app: App; stream: boolean },
    ): Promise<void> {
      if (stream) {
        initLogsStreamer(context, app);
      } else {
        teardownLogsStreamer(app);
      }
    },
  };
  mutations: MutationTree<SessionState> = {
    [CLEAR_TOKEN](state: SessionState): void {
      state.userToken = null;
    },
    [SET_TOKEN](state: SessionState, token: string): void {
      state.userToken = token;
    },
  };
  getters: GetterTree<SessionState, RootState> = {
    [USER_TOKEN](state: SessionState): string | null {
      return state.userToken;
    },
  };
}

export const Session = buildMapping(new SessionStore(), "session");

export function userToken(store: ApplicationStore): string | null {
  return store.getters[Session.getters.USER_TOKEN];
}

export function changeLocale(i18n: VueI18n, locale: string): void {
  if (locale) {
    i18n.locale = locale; // Vue i18n
    Settings.defaultLocale = locale; // Luxon
    setLocale(locale);
    const localeStore = useLocaleStore();
    localeStore.setLocale(locale);
  }
}

export function updateLocale(
  store: ApplicationStore,
  i18n: VueI18n,
  locale: string,
): void {
  if (locale) {
    const preferences = { locale };

    changeLocale(i18n, locale);
    updatePreferences(store, preferences);
  }
}
