export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators of your applications"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the list of all collaborators of your applications."])},
        "collaborators_table": {
          "headers": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])}
          },
          "columns": {
            "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])}
          }
        },
        "itemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborator"])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although this view presents a consolidated list, collaborators are managed individually in each application."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs de vos applications"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez la liste de tous les collaborateurs de vos applications."])},
        "collaborators_table": {
          "headers": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné à"])}
          },
          "columns": {
            "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])}
          }
        },
        "itemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborateur"])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien que cette vue présente une liste consolidée, la gestion des collaborateurs s’effectue individuellement pour chaque application."])}
      }
    }
  })
}
