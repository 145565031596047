export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Added on ", _interpolate(_named("date"))])},
        "labels": {
          "sslStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSL Status"])},
          "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
          "tlscert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate info"])},
          "tlskey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key info"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "ttl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTL"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
        },
        "instructions": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual action required"])},
          "dns": {
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to configure your domain name to make it point towards Scalingo."])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This can take some time, so do not worry if the changes are not immediately picked up."])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read our documentation page on the topic"])}
          },
          "dns01": {
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to generate a Let’s Encrypt certificate for this wildcard domain, you need to create a TXT record in your zone."])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This allows Let’s Encrypt to know that you are the owner of the wildcard domain."])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below, you will find the configuration that you should apply."])},
            "line4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also read our documentation page on the topic"])}
          }
        },
        "errors": {
          "dns01error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration error"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were not able to update your wildcard domain configuration."])},
            "line2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It seems that the configuration for the DNS provider ", _interpolate(_named("provider")), " is wrong. Please make sure the following environment variables are correctly set:"])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If everything looks alright to you, reach us through the support to resolve the situation."])}
          },
          "challengeError": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation error"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt failed to generate the SSL certificate. The error was:"])}
          },
          "generic": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation error"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt failed to generate the SSL certificate; please contact us through the support to resolve the situation."])}
          }
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajouté le ", _interpolate(_named("date"))])},
        "labels": {
          "sslStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut SSL"])},
          "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide jusqu'au"])},
          "tlscert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos du certificat"])},
          "tlskey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos de la clé"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "ttl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTL"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
        },
        "instructions": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action manuelle requise"])},
          "dns": {
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez configurer votre nom de domaine afin qu'il pointe vers les serveurs Scalingo."])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n'est pas toujours immédiate, il se peut que vous voyez encore ce message alors que votre configuration est correcte."])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se référer a notre page de documentation sur le sujet"])}
          },
          "dns01": {
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de générer un certificat Let’s Encrypt certificate pour ce domaine wildcard, vous devez ajouter un champ TXT à votre zone DNS."])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci permet à Let’s Encrypt de vérifier que vous êtes bien propriétaire du nom de domaine."])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci dessous, vous trouverez la configuration à appliquer."])},
            "line4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez également consulter notre documentation sur le sujet"])}
          }
        },
        "errors": {
          "dns01error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de configuration"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas pu mettre à jour la configuration de votre domaine wildcard."])},
            "line2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il semble que la configuration pour votre fournisseur DNS ", _interpolate(_named("provider")), " soit erronée. Si ce n'est pas le cas, contactez-nous au support."])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier que les variables d'environnement suivantes soit présentes et exactes:"])}
          },
          "challengeError": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de génération"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt n'a pas pu générer le certificat. L'erreur est la suivante:"])}
          },
          "generic": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de génération"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt n'a pas pu générer le certificat. Veuillez nous contacter au support pour régler la situation."])}
          }
        }
      }
    }
  })
}
