export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "localeEnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "localeFrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])}
      },
      "fr": {
        "localeEnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
        "localeFrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
      }
    }
  })
}
