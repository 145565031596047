export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backToDomains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the list of domain names"])}
      },
      "fr": {
        "backToDomains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner a la liste des noms de domaines"])}
      }
    }
  })
}
