import { ActionHandler } from "@/lib/handlers/action";
import { scalingoClient } from "@/lib/scalingo/client";
import { App } from "@/lib/scalingo/modifiers/apps";
import { RemoteOperation } from "@/lib/store/remote-operation";

/** Fetch the recommended value for an autoscaler on appName's containerType for metricType */
import type { ComponentPublicInstance } from "vue";

interface FormData {
  container: string;
  metric: string;
}

export class GetAutoscalerRecommendationHandler extends ActionHandler<number> {
  // unused
  keyPath = "";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  async submit(e: FormData): Promise<void> {
    const scalingo = scalingoClient(this.$store, this.app.region);
    const url = `/apps/${this.app.name}/stats/${e.metric}/recommended_value?container_type=${e.container}`;

    const promise = scalingo
      .apiClient()
      .get(url)
      .then((res) => {
        let value = res.data.value;

        // Those are percentages
        if (["cpu", "memory", "swap"].includes(e.metric)) {
          value = value * 100;
        }

        return value;
      });

    const operation = new RemoteOperation<number>().follow(promise);

    this.follow(operation);
  }
}
