export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deployments"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger the deployment of the application after each push on the linked branch."])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled on"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration is being updated..."])},
        "button_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "button_disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If multiple integrations (CI, etc.) are present, the deployment will be triggered only after their success"])},
        "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last auto-deployment "])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements automatiques"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencher le déploiement automatique de l'application après chaque push sur la branche liée."])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé sur"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
        "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration is being updated..."])},
        "button_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "button_disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branche"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si de multiples intégrations (CI, etc.) sont présentes, le déploiement sera lancé uniquement après leurs succès"])},
        "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier deploiement "])}
      }
    }
  })
}
