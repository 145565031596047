export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "ongoing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deployment in progress (", _interpolate(_named("status")), ")…"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful deployment!"])},
        "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deployment failed (", _interpolate(_named("status")), ")."])}
      },
      "fr": {
        "ongoing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deploiement en cours (", _interpolate(_named("status")), ") …"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement réussi!"])},
        "failure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deploiement échoué (", _interpolate(_named("status")), ")."])}
      }
    }
  })
}
