<template>
  <div class="text-sm text-scale-5 text-right">
    {{ $t("text1") }}
    <a
      class="cursor-pointer font-medium hover:underline"
      :title="$t('optionGithub')"
      @click="$emit('connectHostedSCMIntegration', 'github-enterprise')"
    >
      {{ $t("github") }}
    </a>
    {{ $t("text2") }}
    <a
      class="cursor-pointer font-medium hover:underline"
      :title="$t('optionGitlab')"
      @click="$emit('connectHostedSCMIntegration', 'gitlab-self-hosted')"
    >
      {{ $t("gitlab") }}
    </a>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HostedIntegrationsLinks",
  emits: ["connectHostedSCMIntegration"],
});
</script>

<i18n>
en:
  github: "GitHub Enterprise"
  gitlab: "Self-Hosted GitLab instance"
  text1: "You can also connect a"
  text2: "or a"
  optionGitlab: "Click to see Gitlab self hosted way"
  optionGithub: "Click to see Github entreprise way"
fr:
  github: "GitHub Enterprise"
  gitlab: "Self-Hosted GitLab"
  text1: "Vous pouvez aussi connecter un"
  text2: "ou une instance"
  optionGitlab: "Cliquer pour voir l'option Gitlab self hosted"
  optionGithub: "Cliquer pour voir l'option Github entreprise"
</i18n>
