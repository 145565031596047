export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can customize some features of the dashboard"])},
        "labels": {
          "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language of the interface"])},
          "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme of the interface"])},
          "stickySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay on the same section when switching apps via the search"])}
        },
        "theme": {
          "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
          "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS preference"])}
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update preferences"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préférences"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez personnaliser certaines fonctionnalités du dashboard"])},
        "labels": {
          "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue de l'interface"])},
          "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème de l'interface"])},
          "stickySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rester sur la même page lors d'un changement d'app via la recherche"])}
        },
        "theme": {
          "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombre"])},
          "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair"])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fonction de l'OS"])}
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre a jour les préférences"])}
      }
    }
  })
}
