import { User } from "scalingo/lib/models/auth";
import { BillingProfile } from "scalingo/lib/models/billing";

import { FormHandler } from "@/lib/handlers/form";
import { PaymentMethod } from "@/lib/scalingo/payment-methods";
import { Nullable } from "@/lib/utils/types";
import { createPaymentMethod } from "@/store/payment-methods";

import type { StripeError, StripeIbanElement } from "@stripe/stripe-js";
import type { ComponentPublicInstance } from "vue";

interface FormData {
  holder: string;
}

interface EventData extends FormData {
  el: StripeIbanElement;
}

export class CreateSepaPaymentMethodHandler extends FormHandler<PaymentMethod> {
  keyPath = "paymentMethod.sepa.create";

  constructor(
    component: ComponentPublicInstance,
    readonly user: User,
    readonly profile: BillingProfile,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      holder: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(event: EventData): Promise<void> {
    const email = this.profile.email || this.user.email;

    const payload = {
      profile: this.profile,
      sepa: { ...event, email },
    };

    this.follow(await createPaymentMethod(this.$store, payload));
  }

  formatErrors(): void {
    const error = this.operation?.error as Record<string, unknown>;

    if (error?.stripe) {
      const err = error.stripe as StripeError;
      const code = err.decline_code || err.code;

      if (code) this.errors.$codes.push(code);
    }
  }
}
