import Client from "scalingo";
import { Deployment } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/deployments";

import { App } from "@/lib/scalingo/modifiers/apps";
import { useAppInfosStore } from "@/stores/app-infos";

/**
 * The last step of the one click deploy consists in trigerring the deployment.
 */
export async function ocdStep5(
  client: Client,
  app: App,
  manifestData: { deploymentPayload: CreateParams },
): Promise<Deployment> {
  const store = useAppInfosStore();
  store.ensure({ staleAfter: "always" });

  return client.Deployments.create(app.id, manifestData.deploymentPayload);
}
