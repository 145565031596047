<template>
  <div class="flex space-x-2 items-center">
    <div
      :style="{ visibility: hasPrev ? 'visible' : 'hidden' }"
      class="py-2 hover:bg-primary-2-25 px-2 rounded text-primary-2 text-sm font-medium cursor-pointer"
      @click="onClick(data.currentPage - 1)"
    >
      <ShortArrowLeft></ShortArrowLeft>
    </div>
    <template v-if="data.startWindow">
      <PageItem
        v-for="page in data.startWindow"
        :key="page"
        @click="onClick(page)"
      >
        {{ page }}
      </PageItem>

      <PageItem :disabled="true"> ... </PageItem>
    </template>

    <PageItem
      v-for="page in data.mainWindow"
      :key="page"
      :selected="page === data.currentPage"
      @click="onClick(page)"
    >
      {{ page }}
    </PageItem>

    <template v-if="data.endWindow">
      <PageItem :disabled="true"> ... </PageItem>
      <PageItem
        v-for="page in data.endWindow"
        :key="page"
        @click="onClick(page)"
      >
        {{ page }}
      </PageItem>
    </template>
    <div
      :style="{ visibility: hasNext ? 'visible' : 'hidden' }"
      class="py-2 hover:bg-primary-2-25 px-2 rounded text-primary-2 text-sm font-medium cursor-pointer"
      @click="onClick(data.currentPage + 1)"
    >
      <ShortArrowRight></ShortArrowRight>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ShortArrowLeft from "@/components/atoms/glyphs/ShortArrowLeft.vue";
import ShortArrowRight from "@/components/atoms/glyphs/ShortArrowRight.vue";
import PageItem from "@/components/atoms/pagers/PageItem.vue";

export default defineComponent({
  name: "PagesPager",
  components: { ShortArrowLeft, ShortArrowRight, PageItem },
  props: {
    data: Object,
  },
  emits: ["pageChanged"],
  computed: {
    hasNext() {
      return this.data.totalPages > this.data.currentPage;
    },
    hasPrev() {
      return this.data.currentPage > 1;
    },
  },
  methods: {
    onClick(page) {
      this.$emit("pageChanged", { page });
    },
  },
});
</script>
