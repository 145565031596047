import { App, AuditLog } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { FETCH, HANDLE_OPERATION } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ADD } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { buildMapping } from "@/lib/store/utils";
import { ApplicationStore } from "@/store";
import { useCurrentAppStore } from "@/stores/current/app";

export class AuditLogsStore extends CollectionStore<AuditLog> {
  actions = CollectionStore.buildActions<AuditLog>({
    [FETCH](context, id) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).AuditLogs.show(
          currentApp.id,
          id,
        ),
        resolveAction(logs: AuditLog[]) {
          context.commit(ADD, { id, logs });
        },
      });
    },
  });

  mutations = CollectionStore.buildMutations<AuditLog>();
  getters = CollectionStore.buildGetters<AuditLog>();
}

export const AuditLogs = buildMapping(new AuditLogsStore(), "auditLogs");

export function ensureAuditLog(
  store: ApplicationStore,
  id: string,
): Promise<RemoteOperation<AuditLog>> {
  return store.dispatch(AuditLogs.actions.ENSURE_ONE, id);
}

export function findAuditLogs(store: ApplicationStore, id: string): AuditLog {
  return store.getters[AuditLogs.getters.FIND](id);
}
