import { Archive as OriginalArchive } from "scalingo/lib/models/regional";

type AdditionnalInfo = {
  /** There is no id for log archives, but the `from` key should be enough **/
  id: string;
};

export type Archive = OriginalArchive & AdditionnalInfo;

export function modifyItem(item: OriginalArchive): Archive {
  return {
    ...item,
    id: item.from,
  };
}

export function modifyCollection(items: OriginalArchive[]): Archive[] {
  return items.map(modifyItem);
}
