import { Key } from "scalingo/lib/models/auth/keys";

import { ActionHandler } from "@/lib/handlers/action";
import { destroyKey } from "@/store/keys";

import type { ComponentPublicInstance } from "vue";

export class DeleteSshKeyHandler extends ActionHandler<void> {
  keyPath = "sshKeys.delete";

  constructor(
    component: ComponentPublicInstance,
    readonly key: Key,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.key.name });
    });

    this.on("failure", () => {
      this.notify("error", { name: this.key.name });
    });
  }

  async submit(): Promise<void> {
    this.follow(await destroyKey(this.$store, this.key));
  }
}
