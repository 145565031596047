<template>
  <div
    class="flex flex-col rounded border border-padding cursor-pointer"
    @click="$emit('click')"
  >
    <div class="flex px-2">
      <div class="flex flex-grow py-4">
        <div class="flex items-center pl-4">
          <slot name="icon" />
        </div>
        <div class="flex items-center px-4">
          <div class="flex flex-col">
            <div class="text-scale-9 text-lg font-bold">
              {{ title }}
            </div>

            <div class="text-scale-7">
              {{ subtitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex px-4">
        <div class="flex items-center flex-grow">
          <slot name="action" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SimpleCartridge",
  props: {
    title: String,
    subtitle: String,
  },
  emits: ["click"],
});
</script>

<style scoped>
.border-padding {
  padding: 1px;
}
</style>
