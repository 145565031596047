export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "enterFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter fullscreen"])},
        "exitFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit fullscreen"])}
      },
      "fr": {
        "enterFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer en plein écran"])},
        "exitFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortir du plein écran"])}
      }
    }
  })
}
