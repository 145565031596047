export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your credits are now visible on a ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dedicated page."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vos crédits sont désormais visibles sur une ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page dédiée."])}
      }
    }
  })
}
