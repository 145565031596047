export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet accessibility"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, your database is only accessible from the Scalingo Network."])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a security measure, you must first block non-TLS connections in order to make your database accessible from the Internet."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database is publicly accessible on the internet."])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database is only accessible from the Scalingo Network."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilité à internet"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut, votre base de données n'est accessible que depuis le réseau Scalingo"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par mesure de sécurité, vous devez d'abord bloquer les connexions non TLS afin de rendre votre base de données accessible depuis Internet."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données est publiquement accessible sur Internet."])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données est uniquement accessible depuis le réseau Scalingo."])}
      }
    }
  })
}
