<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.29464 13.9999C4.47891 14.1931 4.47891 14.4968 4.29464 14.6899L3.58407 15.3999C3.38489 15.5983 3.06268 15.5983 2.86349 15.3999C-0.954498 11.2041 -0.954498 4.79574 2.86349 0.599924C3.06268 0.401588 3.38489 0.401588 3.58407 0.599924L4.29464 1.30992C4.47891 1.50307 4.47891 1.80677 4.29464 1.99992C1.23646 5.41633 1.23646 10.5835 4.29464 13.9999ZM19.1365 0.599924C18.9373 0.401588 18.6151 0.401588 18.4159 0.599924L17.7054 1.30992C17.5211 1.50307 17.5211 1.80677 17.7054 1.99992C20.7635 5.41633 20.7635 10.5835 17.7054 13.9999C17.5211 14.1931 17.5211 14.4968 17.7054 14.6899L18.4159 15.3999C18.6151 15.5983 18.9373 15.5983 19.1365 15.3999C22.9545 11.2041 22.9545 4.79574 19.1365 0.599924ZM15.994 3.26992C16.1191 3.28902 16.233 3.35301 16.3142 3.44992C18.5682 6.05963 18.5852 9.92052 16.3543 12.5499C16.2632 12.6558 16.1334 12.7207 15.994 12.7299C15.8525 12.7316 15.7159 12.6778 15.6137 12.5799L14.9031 11.8699C14.7312 11.6796 14.7312 11.3902 14.9031 11.1999C16.4485 9.34602 16.4485 6.65383 14.9031 4.79992C14.7312 4.60961 14.7312 4.32023 14.9031 4.12992L15.6137 3.41992C15.7159 3.32209 15.8525 3.26823 15.994 3.26992ZM7.11689 4.12992L6.40633 3.41992C6.29439 3.31175 6.14119 3.25708 5.98599 3.26992C5.84658 3.27917 5.71675 3.34403 5.6257 3.44992C3.3833 6.06949 3.3833 9.93036 5.6257 12.5499C5.71675 12.6558 5.84658 12.7207 5.98599 12.7299C6.12753 12.7316 6.26408 12.6778 6.36629 12.5799L7.07686 11.8699C7.24876 11.6796 7.24876 11.3902 7.07686 11.1999C5.53146 9.34602 5.53146 6.65383 7.07686 4.79992C7.26053 4.62005 7.27784 4.33035 7.11689 4.12992ZM8.07006 8.65772C7.84284 7.65631 8.14398 6.60834 8.8683 5.87992C9.73759 4.99846 11.0594 4.7408 12.1965 5.23114C13.3336 5.72147 14.0528 6.85917 14.0072 8.09585C13.9617 9.33253 13.1609 10.4144 11.9908 10.8199V19.4999C11.9908 19.7761 11.7668 19.9999 11.4904 19.9999H10.4896C10.2132 19.9999 9.98919 19.7761 9.98919 19.4999V10.8199C9.02131 10.4749 8.29728 9.65913 8.07006 8.65772Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGModelNotifier",
});
</script>
