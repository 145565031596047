import { App } from "scalingo/lib/models/regional";
import { nextTick } from "vue";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { renameApp } from "@/store/apps";
import { useAppInfosStore } from "@/stores/app-infos";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  name: string;
  currentName: string;
}

export class RenameAppHandler extends FormHandler<App> {
  keyPath = "app.rename";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      name: "",
      currentName: "",
    };
  }

  dispatchEvents(): void {
    this.on("success", (app) => {
      this.notify("success", { name: app.name });

      useAppInfosStore().ensure({ staleAfter: "always" });

      nextTick(() => {
        this.$router.push({
          name: Routes.App.Overview,
          params: {
            id: app.name,
          },
        });
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    this.follow(
      await renameApp(this.$store, this.app, event.currentName, event.name),
    );
  }
}
