<template>
  <div v-if="containers.latestFetch" class="bg-scale-0 rounded px-8 py-6">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <Button
          class="place-self-center"
          :to="routeArgs"
          :actions="containerActions"
          @action="(e) => $emit('restartAction', e)"
        >
          {{ $t("actions.main") }}
        </Button>
      </template>
    </CardHeader>

    <template v-if="containers.latestFetch.isLoading">
      <LoadingCardState />
    </template>

    <template v-if="containers.latestFetch.isFinished">
      <div v-if="listItems.length !== 0">
        <div
          v-for="(item, index) in listItems"
          :key="index"
          class="py-3 flex content-end"
          :class="{ 'border-t border-scale-2': index > 0 }"
        >
          <div v-if="item.flag === 'error'" class="pr-2">
            <DangerSign class="text-color-2" />
          </div>
          <div class="font-medium self-end">
            {{ item.name }}
          </div>
          <div class="text-scale-5 pl-1 self-end">
            ({{ item.amount }}:{{ item.size }})
          </div>

          <div
            v-if="item.command"
            class="text-scale-4 pl-4 font-mono text-xs text-right flex-grow self-end truncate leading-5"
            :title="item.command"
          >
            {{ item.command }}
          </div>
        </div>
      </div>
      <EmptyCardState v-if="listItems.length === 0">
        <template #icon>
          <BoxGlyph class="h-8 text-scale-5"></BoxGlyph>
        </template>

        {{ $t("noContainer") }}
      </EmptyCardState>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BoxGlyph from "@/components/atoms/glyphs/BoxGlyph.vue";
import DangerSign from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import Button from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ContainersCard",
  components: {
    EmptyCardState,
    LoadingCardState,
    BoxGlyph,
    DangerSign,
    CardHeader,
    Button,
  },
  props: {
    containers: Object,
    app: Object,
  },
  emits: ["restartAction"],
  data() {
    return {
      routeArgs: {
        name: Routes.App.Resources,
        args: { id: this.app.name },
      },
    };
  },
  computed: {
    listItems() {
      return this.containers.items;
    },
    containerActions() {
      const actions = [
        {
          key: "containers.restartAll",
          text: this.$i18n.t("actions.restartAll"),
        },
        ...this.listItems.map((container) => {
          return {
            key: "containers.restartOne",
            text: this.$i18n.t("actions.restartOne", {
              container: container.id,
            }),
            container: container.id,
          };
        }),
      ];

      return actions;
    },
  },
});
</script>

<i18n>
en:
  title: "Containers"
  subtitle: "Type and amount of containers in use."
  noContainer: "No containers yet"
  actions:
    main: "Manage"
    restartAll: "Restart all"
    restartOne: "Restart {container}"
fr:
  title: "Conteneurs"
  subtitle: "Type et nombre de conteneurs utilisés."
  noContainer: "Pas encore de conteneurs"
  actions:
    main: "Gérer"
    restartAll: "Tout redémarrer"
    restartOne: "Redémarrer {container}"
</i18n>
