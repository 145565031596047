<template>
  <ViewComponent :app="app" :promiseInfo="findAddonPromiseInfo" />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/addonsEdition/Root.vue";
import { pendingPromiseInfo, promiseInfo } from "@/lib/promises/info";
import { ensureAddonProviders } from "@/store/addon-providers";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "AddonsEditionRootContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      findAddonPromiseInfo: pendingPromiseInfo(),
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        const addonsStore = useAppAddonsStore();

        this.findAddonPromiseInfo = promiseInfo(
          addonsStore.fetchOne(newVal.params.addonId),
        );

        ensureAddonProviders(this.$store, this.app.region);
        addonsStore.ensure();
      },
    },
  },
});
</script>
