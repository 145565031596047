<template>
  <span class="inline-block relative">
    <AddonProviderIcon :width="width" :addonProvider="addon.addon_provider" />

    <span
      v-if="addon.status"
      style="top: -4px; right: -4px"
      class="absolute block h-2 w-2 rounded-full ring-2 ring-scale-0"
      :class="{
        'bg-color-1': addon.status === 'running',
        'bg-color-2': addon.status === 'suspended',
        'bg-secondary-2': addon.status === 'provisioning',
      }"
      :title="title"
    ></span>
    <span
      v-if="addon.status === 'provisioning'"
      style="top: -4px; right: -4px"
      class="animate-ping absolute block h-2 w-2 rounded-full ring-2 bg-secondary-2"
      :title="title"
    ></span>
  </span>
</template>

<script>
import { defineComponent } from "vue";

import AddonProviderIcon from "@/components/atoms/graphics/addonProviders/Icon.vue";

const defaultWidth = 32;

export default defineComponent({
  name: "AddonIcon",
  components: {
    AddonProviderIcon,
  },
  props: {
    addon: Object,
    width: {
      type: [Number, String],
      default: defaultWidth,
    },
  },
  computed: {
    title() {
      return this.$i18n.t(`status.${this.addon.status}`);
    },
  },
});
</script>

<i18n>
en:
  status:
    running: "Running"
    suspended: "Suspended"
    provisioning: "Provisioning"
fr:
  status:
    running: "Running"
    suspended: "Suspended"
    provisioning: "Provisioning"
</i18n>
