export default {
  "metrics": {
    "events": {
      "deploy": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy"])}
      },
      "restart": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart"])}
      },
      "scale": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])}
      }
    },
    "rpm": {
      "all": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All requests"])}
      },
      "5xx": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5xx"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only 5xx"])}
      }
    },
    "responseTime": {
      "median": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Median"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Median"])}
      },
      "p95": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p95"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p95"])}
      },
      "p99": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p99"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p99"])}
      }
    },
    "containers": {
      "cpu": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])}
      },
      "memory": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory"])}
      },
      "swap": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])}
      },
      "diskUsed": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disque utilisé"])}
      },
      "diskReads": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reads"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disque IO reads"])}
      },
      "diskWrites": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writes"])},
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disque IO writes"])}
      }
    }
  }
}