export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete app"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete app"])},
        "allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As the owner of this application, you are the only one who has the right to delete it."])},
        "notAllowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Only ", _interpolate(_named("name")), ", owner of the app is allowed to delete it."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’application"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’application"])},
        "allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que propriétaire de l'application, vous seul·e êtes autorisé·e à la supprimer."])},
        "notAllowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seul·e ", _interpolate(_named("name")), ", propriétaire de l'application, peut la supprimer."])}
      }
    }
  })
}
