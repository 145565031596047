<template>
  <VueLine
    :chartData="{ datasets }"
    :chartOptions="options"
    :plugins="plugins"
  />
</template>

<script>
import CrosshairPlugin from "chartjs-plugin-crosshair";
import { defineComponent } from "vue";
import { Line as VueLine } from "vue-chartjs";

import { crosshairConfig } from "@/lib/metrics";

export default defineComponent({
  name: "RequestsPerMinute",
  components: { VueLine },
  props: {
    datasets: Array,
  },
  data() {
    return {
      options: {
        animation: false,
        aspectRatio: 3,
        hover: { intersect: false },
        scales: {
          x: {
            type: "time",
            bounds: "ticks",
            offset: true,
            offsetAfterAutoskip: true,
            ticks: {
              source: "auto",
              autoSkip: true,
              autoSkipPadding: 60,
            },
          },
          y: {
            offset: true,
            grid: {
              drawBorder: false,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              precision: 0, // Necessary to avoid decimal labels on the scale (1.5 RPM is not a relevant tick label)
            },
          },
        },
        plugins: {
          legend: {
            align: "end",
            position: "bottom",
          },
          tooltip: {
            intersect: false,
            mode: "index",
            position: "nearest",
            callbacks: {
              label(context) {
                const dataset = context.dataset;

                let label = dataset.label || "";

                if (!["Scale", "Restart", "Deploy"].includes(label)) {
                  return `${label}: ${context.parsed.y}`;
                }

                const point = context.raw;

                if (!point.event) return "";
                if (!point.event?.user?.username) return label;

                return `${label}: by ${point.event.user.username}`;
              },
            },
          },
          crosshair: crosshairConfig,
        },
      },
    };
  },
  computed: {
    plugins() {
      return [CrosshairPlugin];
    },
  },
});
</script>
