<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"></CardHeader>
    <div v-if="dbPlan.item" class="my-4">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8"
      >
        <div class="flex flex-col">
          <span class="text-scale-5">
            <font-awesome-icon
              class="text-scale-5 h-3.5 w-3.5"
              icon="microchip"
            />
            {{ $t("cpu") }}
          </span>
          <span class="font-medium flex flex-row items-center">
            <AlertingStatesIndicator
              v-if="cpuStatus"
              :status="cpuStatus"
              class="mr-1"
            />
            <span class="font-medium" :title="$t(`hover.cpu.${cpuStatus}`)">
              {{ dbMetrics.item.cpu_usage }}
              <span class="text-scale-5 font-normal">%</span>
            </span>
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-scale-5">
            <font-awesome-icon class="text-scale-5 h-3.5 w-3.5" icon="memory" />
            {{ $t("memory") }}
          </span>
          <span class="font-medium flex flex-row items-center">
            <span
              v-if="displayMemoryCard === 'withoutStatus'"
              class="font-medium"
            >
              {{ humanReadableSize(dbMemoryMetrics.memory) }}
              <span class="text-scale-5 font-normal">
                &nbsp;/
                {{ humanReadableSize(dbMemoryMetrics.memory_limit) }}
              </span>
            </span>
            <span
              v-if="displayMemoryCard === 'withStatus'"
              class="font-medium flex flex-row items-center"
            >
              <AlertingStatesIndicator
                v-if="memoryStatus"
                :status="memoryStatus"
                class="mr-1"
              />
              {{ humanReadableSize(dbMemoryMetrics.memory) }}
              <span class="text-scale-5 font-normal">
                &nbsp;/
                {{ humanReadableSize(dbMemoryMetrics.memory_limit) }}
              </span>
            </span>
            <span v-if="!displayMemoryCard" class="text-scale-7">
              {{ $t("noData") }}
            </span>
          </span>
        </div>
        <div v-if="dbMemoryMetrics" class="flex flex-col">
          <span class="text-scale-5">
            <font-awesome-icon class="text-scale-5 h-3.5 w-3.5" icon="memory" />
            {{ $t("swap") }}
          </span>
          <span class="font-medium flex flex-row items-center">
            <AlertingStatesIndicator
              :title="$t(`hover.swap.${swapStatus}`)"
              :status="swapStatus"
              class="mr-1"
            />
            <span class="font-medium" :title="$t(`hover.swap.${swapStatus}`)">
              {{ humanReadableSize(dbMemoryMetrics.swap) }}
              <span class="text-scale-5 font-normal">
                &nbsp;/
                {{ humanReadableSize(dbMemoryMetrics.swap_limit) }}
              </span>
            </span>
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-scale-5">
            <font-awesome-icon
              class="text-scale-5 h-3.5 w-3.5"
              :icon="['far', 'hard-drive']"
            />
            {{ $t("disk") }}
          </span>
          <span
            class="font-medium flex flex-row items-center"
            :title="$t(`hover.storage.${diskStatus}`)"
          >
            <AlertingStatesIndicator :status="diskStatus" class="mr-1" />
            {{ humanReadableSize(dbMetrics.item.real_disk_size) }}
            <span class="text-scale-5 font-normal">
              &nbsp;/
              {{ humanReadableSize(dbPlan.item.disk * 1024 * 1024) }}
            </span>
          </span>
        </div>

        <div class="flex flex-col">
          <router-link
            :to="planSettingsRoute"
            class="flex flex-row items-center"
          >
            <span class="text-scale-5 hover:underline">
              <font-awesome-icon class="h-3.5 w-3.5" icon="database" />
              <span class="ml-1 hover:underline">{{ $t("ha") }}</span>
            </span>
          </router-link>
          <span class="font-medium flex flex-row items-center">
            <ThreeStatesIndicator
              :isOk="isHighAvailability"
              :isNo="!isHighAvailability"
              class="mr-1"
            />
            <span
              v-if="isHighAvailability"
              :title="$t('hover.nodes.multiNodes')"
            >
              {{ $t("multiNodes") }}
            </span>
            <span v-else :title="$t('hover.nodes.singleNode')">
              {{ $t("singleNode") }}
            </span>
          </span>
        </div>
        <div class="flex flex-col">
          <router-link :to="accessRoute" class="flex flex-row items-center">
            <span class="text-scale-5 flex items-center">
              <SSLGlyph class="h-3.5 w-3.5"></SSLGlyph>
              <span class="ml-1 hover:underline">{{ $t("forceTls") }}</span>
            </span>
          </router-link>

          <span class="font-medium flex flex-row items-center">
            <ThreeStatesIndicator
              :isOk="hasForcedTLS"
              :isNo="!hasForcedTLS"
              class="mr-1"
            />
            <span v-if="hasForcedTLS" :title="$t('hover.tls.enabled')">
              {{ $t("enabled") }}
            </span>
            <span v-else :title="$t('hover.tls.disabled')">
              {{ $t("disabled") }}
            </span>
          </span>
        </div>
        <div class="flex flex-col">
          <router-link :to="accessRoute" class="flex flex-row items-center">
            <span class="text-scale-5">
              <font-awesome-icon
                class="text-scale-5 h-3.5 w-3.5"
                icon="globe-africa"
              />
              <span class="ml-1 hover:underline">
                {{ $t("internetAccess") }}
              </span>
            </span>
          </router-link>

          <span>
            <span
              v-if="hasInternetAccess"
              :title="$t('hover.access.enabled')"
              class="font-medium flex items-center"
            >
              <DangerSignGlyph class="mr-1 text-warning" size="4" />
              <span>{{ $t("enabled") }}</span>
            </span>
            <span v-else class="font-medium flex items-baseline">
              <font-awesome-icon
                icon="lock"
                class="text-scale-5 mr-1 h-3 w-3"
              />
              <span>{{ $t("disabled") }}</span>
            </span>
          </span>
        </div>
        <div class="flex flex-col">
          <router-link
            :to="backupsRoute"
            class="flex flex-row items-center text-scale-5"
          >
            <span>
              <ClockRotateLeftGlyph class="text-scale-5 h-5 w-5 pr-1" />
            </span>
            <span class="hover:underline">{{ $t("periodicBackups") }}</span>
          </router-link>

          <span class="font-medium flex flex-row items-center">
            <ThreeStatesIndicator
              :isOk="db.periodic_backups_enabled"
              :isKo="!db.periodic_backups_enabled"
              class="mr-1"
            />
            <span
              v-if="db.periodic_backups_enabled"
              :title="$t('hover.backups.enabled')"
            >
              {{ $t("backupAt", { hour: scheduledAt }) }}
            </span>
            <span v-else :title="$t('hover.backups.disabled')">
              {{ $t("disabled") }}
            </span>
          </span>
        </div>
        <FeatureKeyPoint
          v-if="db.type_name === 'redis'"
          :route="configurationRoute"
          name="redis-rdb"
          :db="db"
          :title="$t('redis.rdb')"
        />
        <FeatureKeyPoint
          v-if="db.type_name === 'redis'"
          :route="configurationRoute"
          name="redis-aof"
          :db="db"
          :title="$t('redis.aof')"
        />
        <FeatureKeyPoint
          v-if="db.type_name === 'redis'"
          :route="configurationRoute"
          name="redis-cache"
          :db="db"
          :title="$t('redis.cacheMode')"
        />
        <div v-if="displayConnectionCard" class="flex flex-col">
          <span class="text-scale-5">
            <font-awesome-icon
              class="text-scale-5 h-3.5 w-3.5"
              icon="arrow-right-arrow-left"
            />
            {{ $t("connections") }}
          </span>
          <span class="font-medium flex flex-row items-center">
            <AlertingStatesIndicator :status="connectionsStatus" class="mr-1" />
            <span class="font-medium">
              {{ dbStats.current_connections }}
              <span class="text-scale-5 font-normal">
                &nbsp;/
                {{ dbStats.max_connections }}
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isArray } from "lodash";
import { defineComponent } from "vue";

import ClockRotateLeftGlyph from "@/components/atoms/glyphs/ClockRotateLeftGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SSLGlyph from "@/components/atoms/glyphs/SSLGlyph.vue";
import AlertingStatesIndicator from "@/components/atoms/indicators/AlertingStatesIndicator.vue";
import ThreeStatesIndicator from "@/components/atoms/indicators/ThreeStatesIndicator.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import FeatureKeyPoint from "@/components/parts/db/dbOverview/FeatureKeyPoint.vue";
import {
  displaySwapStatus,
  displayCPUStatus,
  displayDiskStatus,
  displayMemoryStatus,
  displayConnectionsStatus,
} from "@/lib/utils/databaseLimits";
import { humanReadableSize } from "@/lib/utils/size";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "InformationCard",
  components: {
    FeatureKeyPoint,
    AlertingStatesIndicator,
    ThreeStatesIndicator,
    CardHeader,
    ClockRotateLeftGlyph,
    DangerSignGlyph,
    SSLGlyph,
  },
  props: {
    app: Object,
    db: Object,
    dbPlan: Object,
    dbMetrics: Object,
  },
  computed: {
    scheduledAt() {
      const value = this.db.periodic_backups_scheduled_at;
      if (isArray(value)) {
        return value[0];
      }
      return value;
    },
    swapStatus() {
      return this.displaySwapStatus(
        this.db.type_name,
        this.dbMemoryMetrics.swap_limit,
        this.dbMemoryMetrics.swap,
      );
    },

    cpuStatus() {
      return displayCPUStatus(this.db.type_name, this.dbMetrics.item.cpu_usage);
    },

    diskStatus() {
      return displayDiskStatus(
        this.db.type_name,
        this.dbPlan.item.disk,
        this.dbMetrics.item.real_disk_size,
      );
    },

    memoryStatus() {
      return this.displayMemoryStatus(
        this.db.type_name,
        this.dbMemoryMetrics.memory_limit,
        this.dbMemoryMetrics.memory,
      );
    },

    connectionsStatus() {
      return this.displayConnectionsStatus(
        this.db.type_name,
        this.dbStats.max_connections,
        this.dbStats.current_connections,
      );
    },

    isHighAvailability() {
      return this.dbPlan.item.gateway_nodes > 1;
    },
    backupsRoute() {
      return {
        name: Routes.Db.Backups.List,
        args: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
    configurationRoute() {
      return {
        name: Routes.Db.Configuration,
        args: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
    planSettingsRoute() {
      return {
        name: Routes.Db.General,
        args: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
    accessRoute() {
      return {
        name: Routes.Db.Networking,
        args: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
    hasForcedTLS() {
      let publicly_available = this.db.features.find(
        (feature) => feature.name === "force-ssl",
      );
      return publicly_available?.status === "ACTIVATED";
    },
    hasInternetAccess() {
      let publicly_available = this.db.features.find(
        (feature) => feature.name === "publicly-available",
      );
      return publicly_available?.status === "ACTIVATED";
    },
    dbMemoryMetrics() {
      return this.dbMetrics.item.memory;
    },
    displayMemoryCard() {
      if (this.dbMemoryMetrics) {
        return this.db.type_name === "redis" ? "withStatus" : "withoutStatus";
      } else {
        return null;
      }
    },
    dbStats() {
      return this.dbMetrics.item?.database_stats;
    },
    displayConnectionCard() {
      return (
        this.db.type_name === "postgresql" || this.db.type_name === "mysql"
      );
    },
  },
  methods: {
    humanReadableSize,
    displaySwapStatus,
    displayCPUStatus,
    displayDiskStatus,
    displayMemoryStatus,
    displayConnectionsStatus,
  },
});
</script>

<i18n>
en:
  title: "Overview"
  subtitle: "Overview of your database configuration and health"
  memory: "Memory"
  disk: "Disk"
  swap: "Swap"
  cpu: "CPU"
  forceTls: "Force TLS"
  ha: "Redundancy"
  periodicBackups: "Scheduled backups"
  internetAccess: "Internet access"
  enabled: "Enabled"
  disabled: "Disabled"
  singleNode: "Single-node"
  multiNodes: "Multi-nodes"
  backupAt: "At { hour }:00 UTC"
  connections: "Database connections"
  noData: "No data"
  redis:
    rdb: "RDB"
    aof: "AOF"
    cacheMode: "Cache mode"
  hover:
    swap:
      ok: ""
      warning: "Swap level is high, please check your memory and swap consumption."
      ko: "Swap level is very high, this could affect your database performance."
    cpu:
      ok: ""
      warning: "CPU level is high, this could affect your database performance"
      ko: "CPU level is very high, this could affect your database performance"
      false: ""
    storage:
      ok: ""
      warning: "All of the included storage is used. Over-usage will be charged additionally."
    nodes:
      multiNodes: "Your database is highly available (HA) and tolerant to primary node failure."
      singleNode: "Change to a Business plan and enjoy increased redundancy and a 99.96% SLA."
    tls:
      disabled: "Non-TLS connections are allowed"
      enabled: "Non-TLS connections are blocked"
    backups:
      enabled: "Last successful backup was less than 24 hours ago"
      disabled: "No new scheduled backup will be made"
    access:
      enabled: "Warning: this database is publicly accessible on the internet."
fr:
  title: "Aperçu"
  subtitle: "Aperçu de la configuration et de l'état de votre base de données"
  memory: "Mémoire"
  disk: "Disque"
  swap: "Swap"
  cpu: "CPU"
  forceTls: "Force TLS"
  ha: "Redondance"
  periodicBackups: "Backups planifiés"
  internetAccess: "Accès à internet"
  enabled: "Activé"
  disabled: "Désactivé"
  singleNode: "Mono-noeud"
  multiNodes: "Multi-noeuds (HA)"
  backupAt: "A { hour }:00 UTC"
  connections: "Connexions à la base de données"
  noData: "Aucune donnée"
  redis:
    rdb: "RDB"
    aof: "AOF"
    cacheMode: "Cache mode"
  hover:
    swap:
      ok: ""
      warning: "Le niveau de swap est élevé, veuillez vérifier votre consommation de mémoire et de swap."
      ko: "Le niveau de swap est très élevé, cela pourrait altérer les performances de votre base de données."
    cpu:
      ok: ""
      warning: "Le niveau du CPU est élevé, cela pourrait altérer les performances de votre base de données."
      ko: "Le niveau du CPU est très élevé, cela pourrait altérer les performances de votre base de données."
      false: ""
    storage:
      ok: ""
      warning: "Tout le stockage inclus est utilisé. La sur-utilisation sera facturée en supplément."
    nodes:
      multiNodes: "Votre base de données est hautement disponible (HA) et tolérante à la panne du nœud primaire."
      singleNode: "Changez pour un plan Business et profitez d’une redondance accrue ainsi que d’un SLA de 99,96 %."
    tls:
      disabled: "Les connexions non TLS sont autorisées"
      enabled: "Les connexions non TLS sont bloquées"
    backups:
      enabled: "Le dernier backup réussi date de moins de 24 heures"
      disabled: "Aucun nouveau backup planifié"
    access:
      enabled: "Avertissement : cette base de données est publiquement accessible sur Internet."
</i18n>
