import { BillingProfile } from "scalingo/lib/models/billing";

import { ActionHandler } from "@/lib/handlers/action";
import { validatePaypalCredit } from "@/lib/paypal";
import { scalingoPrivateClient } from "@/lib/scalingo/client";
import { PayPalValidateResponse } from "@/lib/scalingo/paypal";
import { ensureBillingProfile } from "@/store/billing-profile";

import type { ComponentPublicInstance } from "vue";

interface EventData {
  token: string;
  payerId: string;
}

export class ValidatePaypalHandler extends ActionHandler<PayPalValidateResponse> {
  keyPath = "paypal.validate";

  constructor(
    component: ComponentPublicInstance,
    readonly billingProfile: BillingProfile,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      ensureBillingProfile(this.$store, { staleAfter: "always" });

      this.$router.push({ query: {} });
      this.notify("success");
    });

    this.on("failure", () => {
      this.$router.push({ query: {} });
      this.notify("error");
    });
  }

  async submit(e: EventData): Promise<void> {
    this.follow(
      validatePaypalCredit(
        scalingoPrivateClient(this.$store),
        this.billingProfile,
        e.token,
        e.payerId,
      ),
    );
  }
}
