import { Variable } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { createVariable } from "@/store/variables";

interface FormData {
  name: string;
  value: string;
}

export class CreateVariableHandler extends FormHandler<Variable> {
  keyPath = "variable.create";

  data(): Nullable<FormData> {
    return {
      name: null,
      value: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", (variable) => {
      this.notify("success", { name: variable.name });
    });
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await createVariable(this.$store, event));
  }
}
