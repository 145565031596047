export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deployment of review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit review apps configuration."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "enabledLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to enable automatic review apps"])},
        "autoDestroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto destroy review apps when"])},
        "autoDestroyPrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the pull request is closed"])},
        "autoDestroyNoEventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no deploy or commits event have occurred"])},
        "allowForkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto deploy review apps from forks"])},
        "allowForkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only allow automatic deployment of review apps from forks if you trust the owners of those forks. This could lead to security issues."])},
        "allowForkCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to allow review apps coming from forks"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete after"])},
        "during": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["during"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
        "billingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing profile"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a payment method"])},
        "paymentRequired": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must end the free trial to create a review app. Please fill your "])},
          "andAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and add "])},
          "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(the owner) must end the free trial to create a review app"])}
        },
        "warning": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Review Apps will allow anyone with pull/merge requests capability on repository to automatically deploy any change in a clone of the parent app. Scalingo will not automatically deploy a review app that comes from a fork."])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement automatique des review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la configuration des review apps."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
        "enabledLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite activer les review apps automatiques"])},
        "autoDestroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autodétruit les review apps quand"])},
        "autoDestroyPrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la pull request est fermée"])},
        "autoDestroyNoEventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aucun deploiement ou commit n'a eu lieu"])},
        "allowForkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto déploiement des review apps provenant de forks"])},
        "allowForkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'autorisez le déploiement automatique de review apps à partir de forks que si vous faites confiance aux propriétaires de ces forks. Cela pourrait entraîner des problèmes de sécurité."])},
        "allowForkCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite activer les review apps provenant de forks"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer après"])},
        "during": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendant"])},
        "billingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil de facturation"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un moyen de paiement"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heure"])},
        "paymentRequired": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez mettre fin à l'essai gratuit pour créer une review app. Veuillez remplir votre "])},
          "andAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et ajouter "])},
          "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(le⋅la propriétaire) doit mettre fin à l'essai gratuit pour créer une review app"])}
        },
        "warning": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les review apps automatiques permettent à toute personne ayant les droits suffisants pour créer des pull/merge requests sur le dépôt de code source, de déployer automatiquement des review apps. Scalingo ne déploiera pas de review app si son code provient d'un fork."])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
        }
      }
    }
  })
}
