export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new database"])},
        "dbNameValidation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database name must:"])},
          "uniqueness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be unique"])},
          "hasLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contain at least one letter"])},
          "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be between 1 and 64 characters"])},
          "allowedCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contain only alphanumeric characters, underscores, or dollar signs"])}
        },
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create this database"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle base de données"])},
        "dbNameValidation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de la base de données doit :"])},
          "uniqueness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Être unique"])},
          "hasLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenir entre 1 et 64 caractères"])},
          "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenir au moins une lettre"])},
          "allowedCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenir uniquement des caractères alphanumériques, tiret bas ou symbole dollar"])}
        },
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer cette base de donnée"])}
      }
    }
  })
}
