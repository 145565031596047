import { APIError } from "scalingo/lib/errors";
import { BillingProfile } from "scalingo/lib/models/billing";

import { FormHandler } from "@/lib/handlers/form";
import { initiatePaypalCredit, initPaypalClient } from "@/lib/paypal";
import { scalingoPrivateClient } from "@/lib/scalingo/client";
import { PayPalInitiateResponse } from "@/lib/scalingo/paypal";
import { Nullable } from "@/lib/utils/types";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  amount: string | number;
}

export class InitiatePaypalHandler extends FormHandler<PayPalInitiateResponse> {
  keyPath = "paypal.initiate";

  constructor(
    component: ComponentPublicInstance,
    readonly billingProfile: BillingProfile,
  ) {
    super(component);

    initPaypalClient();
  }

  data(): Nullable<FormData> {
    return {
      amount: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => {
      const response = this.operation?.value;

      if (response) {
        window.paypal.checkout.startFlow(response.token);
      }
    });
  }

  async submit(e: FormData): Promise<void> {
    // This closes a previously started flow if there is one.
    window.paypal.checkout.closeFlow();

    this.follow(
      initiatePaypalCredit(
        scalingoPrivateClient(this.$store),
        this.billingProfile,
        e.amount,
      ),
    );
  }

  formatErrors(): void {
    const error = this.operation?.error;

    if (error && error instanceof APIError) {
      if (error.status == 500) {
        this.errors.amount = [this.getLocaleString("genericError")];
      }
    }
  }
}
