export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your password to another one"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
        "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
        "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "confirmationPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour votre mot de passe pour un autre"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le mot de passe"])},
        "currentPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
        "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
        "confirmationPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])}
      }
    }
  })
}
