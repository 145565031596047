import { ReferralsStats as ReferralStatsModel } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { REFRESH, HANDLE_FETCH } from "@/lib/store/action-types";
import { SET_ONE } from "@/lib/store/mutation-types";
import { ResourceStore } from "@/lib/store/resource-store";
import {
  buildMapping,
  EnsureOptions,
  ResourceWithFetch,
} from "@/lib/store/utils";
import { ApplicationStore } from "@/store";

export class ReferralStatsStore extends ResourceStore<ReferralStatsModel> {
  actions = ResourceStore.buildActions<ReferralStatsModel>({
    [REFRESH](context) {
      const promise = scalingoClient(context).Stats.referrals();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ONE,
      });
    },
  });

  mutations = ResourceStore.buildMutations<ReferralStatsModel>();
  getters = ResourceStore.buildGetters<ReferralStatsModel>();
}

export const ReferralsStats = buildMapping(
  new ReferralStatsStore(),
  "referralStats",
);

export function referralStatsAndFetch(
  store: ApplicationStore,
): ResourceWithFetch<ReferralStatsModel> {
  return {
    value: store.getters[ReferralsStats.getters.CURRENT],
    latestFetch: store.getters[ReferralsStats.getters.LATEST_FETCH],
  };
}

export function ensureReferralStats(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(ReferralsStats.actions.ENSURE, opts);
}
