<template>
  <div :class="wrapperClasses" class="rounded">
    <div :class="contentClasses + paddingClasses" class="px-4 font-bold">
      <div v-if="title" class="flex justify-between mb-2">
        <div class="flex">
          <div v-if="$slots.icon" class="self-center pr-3">
            <slot name="icon" />
          </div>

          <div>
            {{ title }}
          </div>
        </div>

        <div
          v-if="dismissible"
          class="flex align-middle h-3 w-3 cursor-pointer"
          @click="$emit('dismiss')"
        >
          <CrossGlyph />
        </div>
      </div>

      <div class="flex justify-between">
        <div class="self-center font-normal">
          <slot />
        </div>

        <div v-if="$slots.icon && !title" class="self-center">
          <slot name="icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";

const ALERT_KIND_INFO = "info";
const ALERT_KIND_CAUTION = "caution";
const ALERT_KIND_WARNING = "warning";
const ALERT_KIND_ERROR = "error";
const ALERT_KIND_SUCCESS = "success";

const ALERT_BG_CLASSES_FOR_KIND = {
  [ALERT_KIND_INFO]: "bg-primary-2-10",
  [ALERT_KIND_CAUTION]: "bg-secondary-1-10",
  [ALERT_KIND_WARNING]: "bg-secondary-3-10",
  [ALERT_KIND_ERROR]: "bg-color-2-10",
  [ALERT_KIND_SUCCESS]: "bg-color-1-10",
};

const ALERT_TEXT_CLASSES_FOR_KIND = {
  [ALERT_KIND_INFO]: "text-primary-2",
  [ALERT_KIND_CAUTION]: "text-secondary-1",
  [ALERT_KIND_WARNING]: "text-secondary-3",
  [ALERT_KIND_ERROR]: "text-color-2",
  [ALERT_KIND_SUCCESS]: "text-color-1",
};

const ALERT_KINDS = Object.keys(ALERT_BG_CLASSES_FOR_KIND);

export default defineComponent({
  name: "SCAlert",
  components: { CrossGlyph },
  props: {
    title: String,
    kind: {
      type: String,
      default: ALERT_KIND_INFO,
      validator(value) {
        return ALERT_KINDS.includes(value);
      },
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    finerBanner: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dismiss"],
  computed: {
    paddingClasses() {
      if (this.finerBanner) {
        return " py-1";
      }
      return " py-3 ";
    },
    wrapperClasses() {
      return ALERT_BG_CLASSES_FOR_KIND[this.kind];
    },
    contentClasses() {
      return ALERT_TEXT_CLASSES_FOR_KIND[this.kind];
    },
  },
});
</script>
