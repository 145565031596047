<template>
  <div class="text-scale-10">
    <div class="bg-scale-0 rounded px-8 py-6">
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template v-if="containers.items.length > 0" #buttons>
          <SCButton
            class="place-self-center"
            :disabled="!canActOnContainers || !hasContainers"
            :actions="dropdownActions"
            :actionsDisabled="!canActOnContainers || !hasContainers"
            @click="$emit('restartAll')"
            @action="dropdownActionClicked"
          >
            {{ $t("actions.restart.all") }}
          </SCButton>
        </template>
      </CardHeader>
      <div class="py-4">
        <template v-if="remoteOperation.isLoading">
          <LoadingCardState />
        </template>

        <template v-else-if="remoteOperation.isFinished">
          <div class="pv:divide-y-0 divide-y divide-scale-2">
            <div
              v-for="(item, index) in listItems"
              :key="index"
              class="py-1 flex"
            >
              <ContainerItem
                :container="item"
                :minContainers="minContainersFor(item)"
                :maxContainers="maxContainers"
                :isHds="app.hds_resource"
                :sizeOptions="sizeOptions"
                :canActOnContainers="canActOnContainers"
                :autoscaler="autoscalerFor(item)"
                :autoscalerAction="autoscalerActionFor(item)"
                @restartContainer="(e) => $emit('restartContainer', e)"
                @scaleContainer="(e) => $emit('scaleContainer', e)"
                @stopContainer="(e) => $emit('stopContainer', e)"
                @createAutoscaler="(e) => $emit('createAutoscaler', e)"
                @editAutoscaler="(e) => $emit('editAutoscaler', e)"
                @enableAutoscaler="(e) => $emit('enableAutoscaler', e)"
                @disableAutoscaler="(e) => $emit('disableAutoscaler', e)"
                @destroyAutoscaler="(e) => $emit('destroyAutoscaler', e)"
              ></ContainerItem>
            </div>
            <EmptyCardState v-if="listItems.length === 0">
              <template #icon>
                <BoxGlyph class="h-8 text-scale-5"></BoxGlyph>
              </template>
              <div class="flex flex-col text-center">
                <span>{{ $t("none") }}</span>
                <span class="mt-2">
                  <p keypath="help">
                    {{ $t("help") }}
                    <DocLink
                      href="https://doc.scalingo.com/platform/deployment/deployment-process"
                    >
                      {{ $t("doclink") }}
                    </DocLink>
                    {{ $t("contactSupport") }}
                  </p>
                </span>
              </div>
            </EmptyCardState>
          </div>
        </template>
      </div>
    </div>
    <div v-if="withPrice" class="font-medium bg-scale-2 rounded px-8 py-3 flex">
      <div class="flex-grow">{{ $t("estimatedCost") }}</div>
      <div>59€/{{ $t("month") }}</div>
    </div>
    <AutoScaleModal
      v-if="autoscalerHandler"
      :formHandler="autoscalerHandler"
      :maxContainers="maxContainers"
      :autoscalerRecommendedValue="autoscalerRecommendedValue"
      @autoscalerMetricChanged="(e) => $emit('autoscalerMetricChanged', e)"
      @formSubmitted="(e) => $emit('autoScaleFormSubmitted', e)"
      @modalClosed="$emit('cancelAutoscaler')"
    >
    </AutoScaleModal>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BoxGlyph from "@/components/atoms/glyphs/BoxGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import AutoScaleModal from "@/components/parts/app/resources/AutoScaleModal.vue";
import ContainerItem from "@/components/parts/app/resources/ContainerItem.vue";
import { RemoteOperationAggregate } from "@/lib/store/remote-operation";

const DEFAULT_CONTAINERS_LIMIT = 10;

export default defineComponent({
  name: "ContainersCard",
  components: {
    DocLink,
    AutoScaleModal,
    ContainerItem,
    EmptyCardState,
    BoxGlyph,
    LoadingCardState,
    CardHeader,
    SCButton,
  },
  props: {
    withPrice: Boolean,
    app: Object,
    containers: Object,
    autoscalers: Object,
    containerSizes: Object,
    sizeOptions: Array,
    canActOnContainers: Boolean,
    autoscalerRecommendedValue: Number,
    autoscalerHandler: Object,
    autoscalerActions: Object,
  },
  emits: [
    "autoScaleFormSubmitted",
    "autoscalerMetricChanged",
    "cancelAutoscaler",
    "createAutoscaler",
    "destroyAutoscaler",
    "disableAutoscaler",
    "editAutoscaler",
    "enableAutoscaler",
    "restartAll",
    "restartContainer",
    "scaleContainer",
    "stopAll",
    "stopContainer",
  ],
  data() {
    return {
      remoteOperation: new RemoteOperationAggregate(),
    };
  },
  computed: {
    dropdownActions() {
      return [
        {
          key: "containers.stop",
          text: this.$i18n.t("actions.stop.all"),
        },
      ];
    },
    maxContainers() {
      return this.app.limits?.max_containers || DEFAULT_CONTAINERS_LIMIT;
    },
    listItems() {
      return this.containers.items;
    },
    columnClass: function () {
      return "w-1/3";
    },
    columnClass2: function () {
      return "w-1/3";
    },
    hasContainers() {
      return !!this.containers.items.find((item) => item.amount !== 0);
    },
  },
  created() {
    this.remoteOperation.reset();
    this.remoteOperation.follow([
      this.containers.latestFetch,
      this.containerSizes.latestFetch,
      this.autoscalers.latestFetch,
    ]);
  },
  methods: {
    minContainersFor(container) {
      if (container.id !== "web") return 0;

      return this.app.limits?.min_web_containers || 0;
    },
    autoscalerFor(container) {
      return this.autoscalers.items.find(
        (autoscaler) => autoscaler.container_type === container.id?.toString(),
      );
    },
    autoscalerActionFor(container) {
      return Object.values(this.autoscalerActions).find(
        (action) =>
          action.autoscaler?.container_type === container.id?.toString(),
      );
    },
    dropdownActionClicked({ action }) {
      if (action.key == "containers.stop") {
        this.$emit("stopAll");
      }
    },
  },
});
</script>

<i18n>
  en:
    title: "Containers"
    subtitle: "Manage containers in use."
    month: "month"
    estimatedCost: "Estimated cost"
    size: "Size"
    amount: "Qty"
    none: "No containers yet, they will appear after the first successful deployment."
    help: "Need help? Refer to"
    doclink: " the documentation "
    contactSupport: "or contact us through the support."
    actions:
      scale:
        all: "Scale all"
      restart:
        all: "Restart all"
        one: "Restart"
      autoscale:
        enable: "Enable autoscale"
      stop:
        all: "Stop all"
  fr:
    title: "Conteneurs"
    subtitle: "Gestion des conteneurs utilisés."
    month: "mois"
    estimatedCost: "Coût estimé"
    size: "Taille"
    amount: "Qté"
    none: "Pas encore de conteneurs, ils apparaîtront après le premier déploiement réussi."
    help: "Besoin d'aide ? Consultez"
    doclink: " la documentation "
    contactSupport: "ou contactez nous au support."
    actions:
      scale:
        all: "Tout scaler"
      restart:
        all: "Tout redémarrer"
        one: "Redémarrer"
      autoscale:
        enable: "Activer l'autoscaling"
      stop:
        all: "Tout stopper"
</i18n>
