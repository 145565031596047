<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AutoscalerEditGlyph class="h-6 w-6 text-scale-6"></AutoscalerEditGlyph>
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
        {{ $t(event.type_data.disabled ? "disabled" : "edited") }}
      </div>
    </template>
    <template #more>
      {{ $t("autoscaler") }}
      <span class="font-medium px-1">{{ event.type_data.metric }}</span>
      <span v-if="event.type_data.container_type"
        >{{ $t("container") }}
        <span class="font-medium pr-1">{{
          event.type_data.container_type
        }}</span>
      </span>
      <span class="pr-1">-</span>
      <span v-if="event.type_data.min_containers" class="pr-1"
        >{{ $t("min") }}:
        <span class="font-medium pl-1">{{
          event.type_data.min_containers
        }}</span
        >,</span
      >
      <span v-if="event.type_data.max_containers" class="pr-1">
        {{ $t("max") }}:
        <span class="font-medium pl-1">{{
          event.type_data.max_containers
        }}</span
        >,</span
      >
      {{ $t("target") }}:
      <span class="font-medium pl-1">{{ event.type_data.target_text }}</span>

      <span class="font-medium ml-1">{{
        event.type_data.hostname
      }}</span></template
    >
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AutoscalerEditGlyph from "@/components/atoms/glyphs/AutoscalerEditGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "EditAutoscalerEvent",
  components: { AutoscalerEditGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Autoscaler"
  edited: "edited"
  disabled: "disabled"
  autoscaler: "Autoscaler about"
  container: "on container"
  min: "Min containers"
  max: "Max containers"
  target: "Target"
fr:
  title: "Autoscaler"
  edited: "édité"
  disabled: "désactivé"
  autoscaler: "Autoscaler à propos de"
  container: "sur le container"
  min: "Min containers"
  max: "Max containers"
  target: "Objectif"
</i18n>
