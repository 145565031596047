<template>
  <SCBanner :kind="kind">
    <template #icon>
      <ReloadGlyph v-if="opStatus === 'ongoing'" :animated="true"></ReloadGlyph>
      <ThumbUp v-if="opStatus === 'success'"></ThumbUp>
      <DangerSignGlyph v-if="opStatus === 'error'"></DangerSignGlyph>
    </template>

    {{ title }}
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import ThumbUp from "@/components/atoms/glyphs/ThumbUp.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "AppOperationBanner",
  components: { SCBanner, ThumbUp, DangerSignGlyph, ReloadGlyph },
  props: {
    operation: Object,
  },
  computed: {
    ongoing() {
      return !this.success && !this.failure;
    },
    success() {
      return this.operation.status === "done";
    },
    failure() {
      return this.operation.status === "error";
    },
    kind() {
      if (this.success) return "success";
      if (this.failure) return "error";

      return "info";
    },
    opStatus() {
      if (this.ongoing) return "ongoing";
      if (this.success) return "success";

      return "error";
    },
    title() {
      let opType = this.operation.type;
      let opStatus = this.opStatus;

      return this.$i18n.t(`${opType}.${opStatus}`);
    },
  },
});
</script>

<i18n>
  en:
    restart:
      ongoing: "The application is restarting …"
      success: "The application was successfully restarted"
      error: "The application could not be restarted"
    scale:
      ongoing: "The application is scaling …"
      success: "The application was successfully scaled"
      error: "The application could not be scaled"
  fr:
    restart:
      ongoing: "Redémarrage en cours …"
      success: "The application was successfully restarted"
      error: "The application could not be restarted"
    scale:
      ongoing: "The application is scaling …"
      success: "The application was successfully scaled"
      error: "The application could not be scaled"
</i18n>
