<template>
  <main>
    <InvoicesList
      :invoices="invoices"
      @fetchMoreInvoices="$emit('fetchMoreInvoices')"
      @downloadInvoicePdf="(e) => $emit('downloadInvoicePdf', e)"
    ></InvoicesList>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import InvoicesList from "@/components/parts/billing/InvoicesList.vue";

export default defineComponent({
  name: "Invoices",
  components: { InvoicesList },
  props: {
    user: Object,
    billingProfile: Object,
    invoices: Object,
  },
  emits: ["fetchMoreInvoices", "downloadInvoicePdf"],
});
</script>
