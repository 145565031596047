export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amounts"])},
        "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't expire"])},
        "expiredSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired since"])},
        "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])}
      },
      "fr": {
        "amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montants"])},
        "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'expire jamais"])},
        "expiredSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré depuis"])},
        "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide jusqu'au"])}
      }
    }
  })
}
