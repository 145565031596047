<template>
  <main>
    <InformationsCard
      :user="user"
      @startAccountEdition="$emit('startAccountEdition')"
    />

    <PlatformContractsList
      class="mt-6"
      :contracts="contracts"
      @fetchAllContracts="$emit('fetchAllContracts')"
    />

    <PreferencesCard
      class="mt-6"
      :user="user"
      :formHandler="preferencesFormHandler"
      :availableLocales="availableLocales"
      @formSubmitted="(e) => $emit('submitPreferencesEdition', e)"
    />

    <InformationsModal
      v-if="accountFormHandler"
      :formHandler="accountFormHandler"
      @modalClosed="$emit('cancelAccountEdition')"
      @formSubmitted="(e) => $emit('submitAccountEdition', e)"
    />

    <AcceptContractModal
      v-if="acceptContractHandler"
      :formHandler="acceptContractHandler"
      @modalClosed="$emit('cancelContractAcceptance')"
      @formSubmitted="(e) => $emit('submitContractAcceptance', e)"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import InformationsCard from "@/components/parts/account/basic/InformationsCard.vue";
import InformationsModal from "@/components/parts/account/basic/InformationsModal.vue";
import PreferencesCard from "@/components/parts/account/basic/PreferencesCard.vue";
import AcceptContractModal from "@/components/parts/account/contracts/AcceptContractModal.vue";
import PlatformContractsList from "@/components/parts/account/contracts/PlatformContractsList.vue";

export default defineComponent({
  name: "Basic",
  components: {
    InformationsCard,
    InformationsModal,
    PlatformContractsList,
    PreferencesCard,
    AcceptContractModal,
  },
  props: {
    acceptContractHandler: Object,
    accountFormHandler: Object,
    preferencesFormHandler: Object,
    user: Object,
    contracts: Object,
    availableLocales: Array,
  },
  emits: [
    "cancelAccountEdition",
    "cancelContractAcceptance",
    "fetchAllContracts",
    "startAccountEdition",
    "submitAccountEdition",
    "submitContractAcceptance",
    "submitPreferencesEdition",
  ],
  data() {
    return {};
  },
});
</script>
