<template>
  <router-link v-if="isInternalLink" :class="classFn" :to="to">
    <slot />
  </router-link>

  <a
    v-else-if="isExternalLink"
    :class="classFn"
    :disabled="disabled"
    v-bind="a"
    @click="linkClicked"
  >
    <slot />
  </a>

  <button
    v-else
    :type="type"
    :class="classFn"
    :disabled="disabled"
    @click="clicked"
  >
    <template v-if="loading">
      <slot name="loading"><slot /></slot>
    </template>
    <template v-else><slot /></template>
  </button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      validator(value) {
        return ["button", "submit"].includes(value);
      },
      default: "button",
    },
    size: {
      type: [String, Boolean],
      validator(value) {
        return ["sm", "md", "lg", false].includes(value);
      },
      default: "md",
    },
    block: {
      type: Boolean,
      default: false,
    },
    to: Object,
    a: Object,
  },
  emits: ["click"],
  computed: {
    isButton() {
      return !this.to && !this.a;
    },
    isInternalLink() {
      return !!this.to && !this.a;
    },
    isExternalLink() {
      return !this.to && !!this.a;
    },
    classFn() {
      let classValue = "text-center ";

      if (this.size === "sm") {
        classValue += "text-sm leading-4 px-4 py-1 ";
      } else if (this.size === "md") {
        classValue += "text-sm leading-4 px-4 py-2 ";
      } else if (this.size === "lg") {
        classValue += "px-7 py-3 ";
      }

      if (this.block) {
        classValue += "flex-grow min-w-full ";
      }

      if (this.isInternalLink || this.isExternalLink) {
        classValue += "inline-block ";
      }

      if (this.disabled) {
        classValue += " opacity-50 cursor-not-allowed";
      } else if (this.loading) {
        classValue += " cursor-wait";
      }

      return classValue;
    },
  },
  methods: {
    clicked(...e) {
      if (this.disabled || this.loading) {
        return;
      }
      this.$emit("click", ...e);
    },
    linkClicked(e) {
      if (this.disabled || this.loading) {
        e.preventDefault();
        return;
      }
    },
  },
});
</script>
