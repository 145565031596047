import { defineStore } from "pinia";
import {
  CreateParams,
  UpdateParams,
} from "scalingo/lib/params/regional/addons";

import i18n from "@/i18n";
import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { ignore404 } from "@/lib/pinia/utils/error-handlers";
import { clearPollingFor, setupPolling } from "@/lib/pinia/utils/polling";
import { scalingoDBClient } from "@/lib/scalingo/dbapi/client";
import {
  Addon,
  modifyCollection,
  modifyItem,
  setVersionId,
} from "@/lib/scalingo/modifiers/addons";
import { currentApp } from "@/stores/current/app";
import { useSessionStore } from "@/stores/session";
import { useToastsStore } from "@/stores/toasts";

export const useAppAddonsStore = defineStore("app/addons", () => {
  const { client } = useSessionStore();

  const store = useModelsStore({
    async fetchPromise() {
      const app = currentApp();

      const response = await client(app.region).Addons.for(app.id);
      const addons = modifyCollection(response, app);

      addons.map(extendAddonInformations);

      return addons.map(setupAddonPolling);
    },
    async fetchOnePromise(id) {
      const app = currentApp();

      const response = await client(app.region).Addons.getAddon(app.id, id);
      const addon = modifyItem(response, app);

      return setupAddonPolling(addon);
    },
  });

  /**
   * Re-fetch an addon as long as it is provisioning.
   * Once done, display a notification
   */
  function setupAddonPolling(addon: Addon): Addon {
    const stopFn = () => addon.status !== "provisioning";
    const refreshFn = () => store.fetchOne(addon.id);

    const teardownFn = () => {
      const toastsStore = useToastsStore();

      toastsStore.addOne({
        title: i18n.global
          .t("notifications.addons.provisioned.title")
          .toString(),
        message: i18n.global
          .t("notifications.addons.provisioned.message", {
            addon: addon.addon_provider.name,
          })
          .toString(),
      });
    };

    setupPolling({
      id: addon.id,
      kind: "addon",
      stopFn,
      refreshFn,
      teardownFn,
    });

    return addon;
  }

  async function extendAddonInformations(addon: Addon) {
    const app = currentApp();
    const client = await scalingoDBClient(app, addon.id);
    const update = await client.Database.for(addon.id);

    store.insertItem(setVersionId(addon, update.next_version_id));
  }

  return {
    ...store,
    $reset() {
      store.$reset();

      clearPollingFor("addon");
    },
    provision(payload: CreateParams) {
      const app = currentApp();

      return client(app.region)
        .Addons.create(app.id, payload)
        .then((addon) => modifyItem(addon, app))
        .then(setupAddonPolling)
        .then(store.insertItem);
    },
    update(addon: Addon, payload: UpdateParams) {
      const app = currentApp();

      return (
        client(app.region)
          .Addons.update(app.id, addon.id, payload)
          //@ts-expect-error incomplete typings
          .then((addon) => modifyItem(addon, app))
          .then(setupAddonPolling)
          .then(store.insertItem)
      );
    },
    destroy(addon: Addon) {
      const app = currentApp();

      return client(app.region)
        .Addons.destroy(app.id, addon.id)
        .catch(ignore404)
        .then(() => store.removeItem(addon));
    },
    resume(addon: Addon) {
      const app = currentApp();

      return client(app.region)
        .Addons.resume(app.id, addon.id)
        .then((r) => modifyItem(r.addon, app))
        .then(store.insertItem);
    },
  };
});
