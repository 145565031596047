<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons> </template>
      </CardHeader>
    </template>
    <template #body>
      <SmallToggle
        :modelValue="value"
        @update:modelValue="(e) => $emit('settingUpdated', { router_logs: e })"
      >
        {{ $t("routerLogs") }}
      </SmallToggle>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";

export default defineComponent({
  name: "RouterLogsCard",
  components: { Card, SmallToggle, CardHeader },
  props: {
    value: Boolean,
  },
  emits: ["settingUpdated"],
});
</script>

<i18n>
en:
  title: "Router Logs"
  subtitle: "Display all connections to your application in the logs."
  routerLogs: "Router Logs"
fr:
  title: "Logs Router"
  subtitle: "Affiche dans les logs toutes les connexions à votre application."
  routerLogs: "Logs Router"
</i18n>
