export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your billing profile"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save these billing informations"])},
        "invoiceAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text of this field will appear on all your future invoices"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter votre profil de facturation"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer ces informations de facturation"])},
        "invoiceAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte de ce champ apparaîtra sur toutes vos futures factures"])}
      }
    }
  })
}
