export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an extra level of security"])},
        "buttonNameDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "buttonNameEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
        "text_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is disabled"])},
        "text_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is enabled"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification deux facteurs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un niveau de sécurité supplémentaire"])},
        "buttonNameDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "buttonNameEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification deux facteurs"])},
        "text_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est désactivée"])},
        "text_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est activée"])}
      }
    }
  })
}
