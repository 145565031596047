export default {
  "generic": {
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "resetForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset form"])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
    "allFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
  },
  "titles": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators"])},
    "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "account": {
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Keys"])},
      "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
      "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCM Integrations"])},
      "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion"])},
      "hds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Data (HDS)"])},
      "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrals"])}
    },
    "billing": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing profile"])},
      "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
      "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit balance"])},
      "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
      "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing operations"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
    },
    "app": {
      "root": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name"))])},
      "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New app"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
      "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
      "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators"])},
      "domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
      "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
      "routing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing"])},
      "managements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
      "addons": {
        "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New addon"])},
        "edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change addon plan"])}
      },
      "deploy": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployments"])},
        "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual deploy"])},
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy configuration"])}
      },
      "reviewApps": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review app manual deploy"])},
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps configuration"])}
      },
      "activity": {
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])}
      },
      "logs": {
        "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs archives"])}
      }
    },
    "db": {
      "root": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name"))])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "logs": {
        "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "archives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs archives"])}
      },
      "runningQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running Queries"])},
      "queryStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query Statistics"])},
      "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
      "backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups"])},
      "settings": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
        "networking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "databases-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases Management"])}
      }
    }
  }
}