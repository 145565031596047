import { User as OriginalUser } from "@/lib/scalingo/dbapi/users";

type AdditionnalInfo = {
  id: string;
};

export type User = OriginalUser & AdditionnalInfo;

export function modifyItem(item: OriginalUser): User {
  return {
    ...item,
    id: item.name,
  };
}

export function modifyCollection(items: OriginalUser[]): User[] {
  return items.map(modifyItem);
}
