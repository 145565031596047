export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point-In-Time Recovery"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a restore point for this database."])},
        "disclaimer": {
          "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand that this action will permanently delete existing data and cannot be cancelled or undone once started."])}
        },
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the restoration is complete, the continuous backup function will be reset. Please contact our support team for any specific needs."])},
        "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date"])},
        "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
        "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point-In-Time Recovery"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un point de restauration pour cette base de données."])},
        "disclaimer": {
          "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends que cette action effacera définitivement les données existantes et qu'elle ne pourra pas être annulée."])}
        },
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois la restauration terminée, la fonction de sauvegarde continue sera réinitialisée. Contactez notre équipe support en cas de besoin spécifique."])},
        "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une date"])},
        "selectHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
        "selectMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])}
      }
    }
  })
}
