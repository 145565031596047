export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "migrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app is currently migrating. You can still use the dashboard, but your actions will have no effect."])},
        "migrated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This app has been migrated to the region ", _interpolate(_named("region")), ": ", _interpolate(_named("link"))])}
      },
      "fr": {
        "migrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette application est en cours de migration. Vous pouvez toujours utiliser le tableau de bord, mais vos actions n'auront aucun effet."])},
        "migrated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette application a été migrée vers la région ", _interpolate(_named("region")), ": ", _interpolate(_named("link"))])}
      }
    }
  })
}
