<template>
  <div class="flex">
    <img :src="avatar" class="h-8 w-8 rounded-full ring-2 ring-scale-2" />

    <div class="flex pv:flex-row flex-col">
      <BreadcrumbItem :withSeparator="hasAppCrumb">
        <router-link :to="onUserClickRoute">
          {{ avatarSource.username }}
        </router-link>
      </BreadcrumbItem>

      <BreadcrumbItem
        v-if="hasAppCrumb"
        :withSeparator="hasDbCrumb"
        class="flex-grow"
      >
        <template v-if="app">
          <router-link :to="onAppClickRoute">
            {{ app.name }}
          </router-link>

          <font-awesome-icon
            v-if="app.hds_resource"
            icon="staff-snake"
            class="h-5 ml-3 text-success"
            :title="$t('hdsResource')"
          />

          <Region class="h-4 ml-3" :width="26" :regionCode="app.region" />
        </template>
        <span v-else> ... </span>
      </BreadcrumbItem>

      <BreadcrumbItem
        v-if="hasDbCrumb"
        :withSeparator="false"
        class="flex-grow"
      >
        <template v-if="db">
          <router-link :to="onDbClickRoute" class="flex flex-row items-center">
            <RedisLogo
              v-if="db.type_name === 'redis'"
              width="28"
              class="mr-3"
            />
            <PgLogo
              v-if="db.type_name === 'postgresql'"
              width="28"
              class="mr-3"
            />
            <MySQLLogo
              v-if="db.type_name === 'mysql'"
              width="28"
              class="mr-3"
            />
            {{ databaseName(db.type_name) }}</router-link
          >
        </template>
        <span v-else> ... </span>
      </BreadcrumbItem>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import MySQLLogo from "@/components/atoms/graphics/addonProviders/MySQL.vue";
import PgLogo from "@/components/atoms/graphics/addonProviders/PostgreSQL.vue";
import RedisLogo from "@/components/atoms/graphics/addonProviders/Redis.vue";
import BreadcrumbItem from "@/components/molecules/breadcrumb/BreadcrumbItem.vue";
import Region from "@/components/organisms/app/Region.vue";
import { userAvatar } from "@/lib/utils/avatars";
import { databaseName } from "@/lib/utils/databaseName";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "BreadcrumbNav",
  components: { PgLogo, BreadcrumbItem, Region, RedisLogo, MySQLLogo },
  props: {
    user: Object,
    appInfo: Object,
    dbInfo: Object,
  },
  data() {
    return {
      welcomeRoute: { name: Routes.Welcome },
    };
  },

  computed: {
    onUserClickRoute() {
      if (!this.app) return { name: Routes.Welcome };

      return {
        name: Routes.Projects,
        query: { owner: this.app.owner.username },
      };
    },
    onAppClickRoute() {
      return {
        name: Routes.App.Overview,
        params: { id: this.app.name },
      };
    },
    onDbClickRoute() {
      return {
        name: Routes.Db.Overview,
        params: { id: this.app.name, dbId: this.db.type_name },
      };
    },
    hasAppCrumb() {
      return this.appInfo.isSet;
    },
    hasApp() {
      return !!this.app;
    },
    app() {
      return this.appInfo.app;
    },
    hasDbCrumb() {
      return this.dbInfo.isSet;
    },
    db() {
      return this.dbInfo.db;
    },
    avatar() {
      return userAvatar(this.avatarSource);
    },
    // If the app owner is the same as the current user, use the user object (contains more data)
    avatarSource() {
      if (!this.app) return this.user;

      return this.app.owner.id === this.user.id ? this.user : this.app.owner;
    },
  },
  methods: { databaseName },
});
</script>

<i18n>
en:
  hdsResource: "HDS application"
fr:
  hdsResource: "Application norme HDS"
</i18n>
