import { Key } from "scalingo/lib/models/auth/keys";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { createKey } from "@/store/keys";

export interface FormData {
  name: string;
}

export class CreateSshKeyHandler extends FormHandler<Key> {
  keyPath = "sshKeys.create";

  data(): Nullable<FormData> {
    return {
      name: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(form: FormData): Promise<void> {
    this.follow(await createKey(this.$store, form));
  }
}
