export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub Enterprise"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployments triggered from your GitHub Enterprise instance."])}
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitLab self-hosted"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployments triggered from your GitLab instance."])}
        },
        "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect account"])},
        "linkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link repo"])},
        "unlinkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink repo"])},
        "organizationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
        "repositoryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])},
        "branchLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
        "automaticDeployLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deploy"])}
      },
      "fr": {
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub Entreprise"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements déclenchés depuis votre instance GitHub Enterprise."])}
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitLab auto-hébergé"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements déclenchés depuis votre instance GitLab."])}
        },
        "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
        "linkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier"])},
        "unlinkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délier"])},
        "organizationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
        "repositoryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépot"])},
        "branchLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
        "automaticDeployLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement automatique"])}
      }
    }
  })
}
