<template>
  <Card>
    <template v-slot:header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"> </CardHeader>
    </template>
    <template v-slot:body>
      <LoadingCardState v-if="isLoading" class="h-64 justify-center" />
      <EmptyCardState v-else-if="isError" class="h-64 justify-center">
        <template #icon><CrossGlyph /></template>
        <span class="capitalize">{{ promiseInfo.err.data.error }}</span>
      </EmptyCardState>
      <DiskUsage
        v-else-if="isLoaded"
        :datasets="datasets"
        :maxLimit="maxUsage"
      />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import DiskUsage from "@/components/organisms/graphs/DiskUsage.vue";
import { makeDataSet } from "@/lib/metrics";

export default defineComponent({
  name: "DiskUsageCard",
  components: {
    DiskUsage,
    Card,
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
  },
  props: {
    promiseInfo: Object,
    usedData: Array,
    dbPlan: Object,
    graphSize: Object,
    index: Number,
  },
  computed: {
    isLoaded() {
      return this.promiseInfo.isSuccess;
    },
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isError() {
      return this.promiseInfo.isError;
    },
    usedDataset() {
      return makeDataSet(this.$i18n, "containers", "diskUsed", this.usedData);
    },
    maxUsage() {
      let max = this.dbPlan?.disk * 1024 * 1024 || 0;
      const series = this.datasets && this.datasets[0];

      if (series && series.data) {
        max = Math.max(max, ...series.data.map(({ y }) => y));
      }
      return max;
    },
    datasets() {
      return [this.usedDataset];
    },
  },
});
</script>

<i18n>
en:
  title: "Disk usage"
  subtitle: "Disk usage for this instance."
fr:
  title: "Utilisation du disque"
  subtitle: "Utilisation du disque de cette instance."
</i18n>
