<template>
  <main>
    <PlatformCard :enabled="false" />
    <CardSeparator />
    <NotifierInfoCard :owner="app.owner" :enabled="false" />
    <CardSeparator />
    <EventsCard
      :enabled="true"
      :notifier="notifier"
      :eventTypes="eventTypes"
      :formHandler="formHandler"
      @submit="(e) => $emit('submit', e)"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import EventsCard from "@/components/parts/app/notifiers/creation/EventsCard.vue";
import NotifierInfoCard from "@/components/parts/app/notifiers/creation/NotifierInfoCard.vue";
import PlatformCard from "@/components/parts/app/notifiers/creation/PlatformCard.vue";

export default defineComponent({
  name: "NotifiersEditEvents",
  components: {
    PlatformCard,
    CardSeparator,
    NotifierInfoCard,
    EventsCard,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    formHandler: Object,
    notifier: Object,
    eventTypes: Object,
  },
  emits: ["submit"],
});
</script>
