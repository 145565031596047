export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes. Switching to table mode will discard them. Do you want to proceed?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, discard the changes"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler les changements"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des modifications non sauvegardées. Retourner au tableau annulera ces changements. Souhaitez-vous continuer?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, continuer"])}
      }
    }
  })
}
