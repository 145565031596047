export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data access granted"])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["databases"])},
        "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containers"])},
        "end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès aux données autorisé"])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bases de données"])},
        "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containers"])},
        "end_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jusqu'au"])}
      }
    }
  })
}
