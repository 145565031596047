export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit the timeline of events."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit de l'historique des événements."])}
      }
    }
  })
}
