export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add variable"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an environment variable"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une variable"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une variable d'environemment"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])}
      }
    }
  })
}
