import {
  DataStoreOptions,
  FullDataStore,
  DataStore,
  useDataStore,
} from "@/lib/pinia/use-data-store";

export { observePromise } from "@/lib/promises/info";

/**
 * A singular resource store.
 * Almost 100% close to the raw data store, but with a different key name,
 * `item` instead of `data`
 */
export type ResourceStore<DataType> = DataStore<DataType> & {
  item: FullDataStore<DataType>["data"];
};

/**
 * The private interface of the resource store.
 * Extra items are internal helpers, used to define other stores.
 */
export type FullResourceStore<DataType> = ResourceStore<DataType> &
  Omit<FullDataStore<DataType>, "data">;

/** Returns an data store tailored for a singleton resource */
export function useResourceStore<DataType>(
  options: DataStoreOptions<DataType>,
): FullResourceStore<DataType> {
  const store = useDataStore(options);

  return {
    item: store.data,
    promise: store.promise,
    promiseInfo: store.promiseInfo,
    initialPromiseInfo: store.initialPromiseInfo,
    lastFetchedAt: store.lastFetchedAt,
    fetch: store.fetch,
    ensure: store.ensure,
    updateData: store.updateData,
    destroyData: store.destroyData,
    clearData: store.clearData,
  };
}

/**
 * Removes the internal helpers from the store,
 * and returns it with some specific helpers.
 */
export function toPublicStore<DataType, Extra extends object>(
  store: FullResourceStore<DataType>,
  extra: Extra,
): ResourceStore<DataType> & Extra {
  return {
    item: store.item,
    promise: store.promise,
    promiseInfo: store.promiseInfo,
    initialPromiseInfo: store.initialPromiseInfo,
    lastFetchedAt: store.lastFetchedAt,
    fetch: store.fetch,
    ensure: store.ensure,
    clearData: store.clearData,
    ...extra,
  };
}
