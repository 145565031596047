export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "expandPlanInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand information about this plan"])},
        "collapsePlanInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse information about this plan"])},
        "per_thirty_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per 30 days"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk"])},
        "planOnDemand": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This plan is available on demand only. If you're interested, "])},
          "supportRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reach us through the support"])},
          "supportMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi Support Team,\n\n I would like to use the following on-demand plan: ", _interpolate(_named("addon")), " '", _interpolate(_named("plan")), "'.\n\n My need is the following: [complete]\n\n Can you please provision it for me?\n\n Thanks a lot."])}
        }
      },
      "fr": {
        "expandPlanInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer plus information sur ce plan"])},
        "collapsePlanInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher le surplus d'information sur ce plan"])},
        "per_thirty_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour 30 jours"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disque"])},
        "planOnDemand": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce plan est disponible sur demande uniquement. Si vous êtes intéressés, "])},
          "supportRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contactez-nous au support"])},
          "supportMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour l'équipe support,\n\n Je souhaite utiliser le plan sur demande suivant: ", _interpolate(_named("addon")), " '", _interpolate(_named("plan")), "'.\n\n Mes besoins sont les suivants: [complete]\n\n Pouvez-vous me provisionner l'addon?\n\n Merci beaucoup."])}
        }
      }
    }
  })
}
