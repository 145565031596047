import { Contract } from "scalingo/lib/models/auth";

import { FormHandler } from "@/lib/handlers/form";
import { scalingoClient } from "@/lib/scalingo/client";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { ensureContracts } from "@/store/contracts";
import { refreshUser } from "@/store/user";

import type { ComponentPublicInstance } from "vue";

export class AcceptContractHandler extends FormHandler<Contract> {
  keyPath = "contracts.accept";

  constructor(
    component: ComponentPublicInstance,
    readonly contract: Contract,
    readonly previousContractAcceptedPresent: boolean,
  ) {
    super(component);
  }

  // Won't be used.
  data(): unknown {
    return {};
  }

  dispatchEvents(): void {
    this.on("success", () => {
      refreshUser(this.$store);
      ensureContracts(this.$store, { staleAfter: "always" });
      this.notify("success");
    });

    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    const promise = scalingoClient(this.$store).Contracts.accept(
      this.contract.id,
      this.$i18n.locale,
    );

    const op = new RemoteOperation<Contract>().follow(promise);

    this.follow(op);
  }
}
