export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "createSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables successfully created!"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following variables have been created: <strong>", _interpolate(_named("names")), "</strong>"])}
        },
        "updateSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables successfully updated!"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following variables have been updated: <strong>", _interpolate(_named("names")), "</strong>"])}
        },
        "deleteSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables successfully removed!"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following variables have been removed: <strong>", _interpolate(_named("names")), "</strong>"])}
        }
      },
      "fr": {
        "createSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables successfully created!"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following variables have been created: <strong>", _interpolate(_named("names")), "</strong>"])}
        },
        "updateSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables successfully updated!"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following variables have been updated: <strong>", _interpolate(_named("names")), "</strong>"])}
        },
        "deleteSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables successfully removed!"])},
          "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following variables have been removed: <strong>", _interpolate(_named("names")), "</strong>"])}
        }
      }
    }
  })
}
