/* eslint-disable @typescript-eslint/no-explicit-any */
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export interface Invoice {
  id: string;
  profile_id: string;
  owner_id: string;
  paid_at: string;
  total_price: string;
  total_price_with_vat: string;
  billing_month: string;
  pdf_url: string;
  invoice_number: string;
  state: string;
  vat_rate: string;
  customer_name: string;
  customer_tax_number: string | null;
  customer_business_type: string;
  address_line1: string;
  address_line2: string | null;
  address_country: string;
  address_city: string;
  address_zip: string;
}

export type IndexQuery = {
  owner_id: string;
  page?: number;
  per_page?: number;
};

export type IndexResponse = {
  meta: Record<string, any>;
  invoices: Invoice[];
};
export default class Invoices {
  client: Scalingo;

  /**
   * Create new Client
   * @param {Client} client - Scalingo API Client
   */
  constructor(client: Scalingo) {
    this.client = client;
  }

  all(params: IndexQuery): Promise<IndexResponse> {
    return unpackData(
      this.client.billingApiClient().get("/invoices", { params }),
    );
  }

  find(id: string): Promise<Invoice> {
    return unpackData(
      this.client.billingApiClient().get(`/invoices/${id}`),
      "invoice",
    );
  }

  download(id: string): Promise<Invoice> {
    return unpackData(
      this.client
        .billingApiClient()
        .get(`/invoices/${id}/download`, { responseType: "blob" }),
    );
  }
}
