<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <EyeGlyph class="h-6 w-6 text-scale-6"></EyeGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      <div v-if="event.type_data.databases && event.type_data.containers">
        {{ $t("databases") }} and {{ $t("containers") }} {{ $t("end_at") }}
        {{ formatDateTime(event.type_data.end_at) }}
      </div>
      <div v-if="event.type_data.databases && !event.type_data.containers">
        {{ $t("databases") }}
        {{ $t("end_at") }} {{ formatDateTime(event.type_data.end_at) }}
      </div>
      <div v-if="!event.type_data.databases && event.type_data.containers">
        {{ $t("containers") }}
        {{ $t("end_at") }} {{ formatDateTime(event.type_data.end_at) }}
      </div>
      <div
        v-if="
          event.type_data.databases_until && event.type_data.containers_until
        "
      >
        {{ $t("databases") }} and {{ $t("containers") }} {{ $t("end_at") }}
        {{ formatDateTime(event.type_data.containers_until) }}
      </div>
      <div
        v-if="
          event.type_data.databases_until && !event.type_data.containers_until
        "
      >
        {{ $t("databases") }}
        {{ $t("end_at") }} {{ formatDateTime(event.type_data.databases_until) }}
      </div>
      <div
        v-if="
          !event.type_data.databases_until && event.type_data.containers_until
        "
      >
        {{ $t("containers") }}
        {{ $t("end_at") }}
        {{ formatDateTime(event.type_data.containers_until) }}
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import EyeGlyph from "@/components/atoms/glyphs/EyeGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "CreateDataAccessConsentEvent",
  components: { BaseEvent, EyeGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatDateTime,
  },
});
</script>

<i18n>
en:
  title: "Data access granted"
  databases: "databases"
  containers: "containers"
  end_at: "until"
fr:
  title: "Accès aux données autorisé"
  databases: "bases de données"
  containers: "containers"
  end_at: "jusqu'au"
</i18n>
