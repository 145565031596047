<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :scmRepoLink="scmRepoLink.value"
    :collaborators="collaborators"
    :createCollaboratorHandler="createCollaboratorHandler"
    :deleteCollaboratorHandler="deleteCollaboratorHandler"
    @copyInvitationLink="copyInvitationLink"
    @startCollaboratorCreation="startCollaboratorCreation"
    @cancelCollaboratorCreation="cancelCollaboratorCreation"
    @submitCollaboratorCreation="(e) => createCollaboratorHandler.submit(e)"
    @startCollaboratorDeletion="startCollaboratorDeletion"
    @cancelCollaboratorDeletion="cancelCollaboratorDeletion"
    @submitCollaboratorDeletion="(e) => deleteCollaboratorHandler.submit(e)"
  >
  </ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/Collaborators.vue";
import { CopyCollaborationInvitationHandler } from "@/lib/handlers";
import { CreateCollaboratorHandler } from "@/lib/handlers/create/collaborator";
import { DeleteCollaboratorHandler } from "@/lib/handlers/delete/collaborator";
import { ensureCollaborators, listCollaborators } from "@/store/collaborators";
import { ensureScmRepoLink, scmRepoLink } from "@/store/scm-repo-link";

export default defineComponent({
  name: "CollaboratorsContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      createCollaboratorHandler: null,
      deleteCollaboratorHandler: null,
    };
  },
  computed: {
    collaborators() {
      return listCollaborators(this.$store);
    },
    scmRepoLink() {
      return scmRepoLink(this.$store);
    },
  },
  created() {
    ensureCollaborators(this.$store);
    ensureScmRepoLink(this.$store);
  },
  methods: {
    copyInvitationLink({ collaborator }) {
      new CopyCollaborationInvitationHandler(this, collaborator).submit();
    },
    startCollaboratorCreation() {
      this.createCollaboratorHandler = new CreateCollaboratorHandler(this);
    },
    cancelCollaboratorCreation() {
      this.createCollaboratorHandler = null;
    },
    startCollaboratorDeletion({ collaborator }) {
      this.deleteCollaboratorHandler = new DeleteCollaboratorHandler(
        this,
        this.currentUser,
        collaborator,
      );
    },
    cancelCollaboratorDeletion() {
      this.deleteCollaboratorHandler = null;
    },
  },
});
</script>

<i18n>
en:
  addCollaboratorSuccess:
    title: "Collaborator added"
    message: "A new collaborator has been added"
  deleteCollaboratorSuccess:
    title: "Collaborator removed"
    message: "A collaborator has been removed"
fr:
  addCollaboratorSuccess:
    title: "Collaborateur ajouté"
    message: "Un nouveau collaborateur a été ajouté"
  deleteCollaboratorSuccess:
    title: "Collaborateur supprimé"
    message: "Une collaborateur a été supprimé"
</i18n>
