<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6.5V15.5C20 15.7761 19.7761 16 19.5 16H14C12.8954 16 12 15.1046 12 14H6V21.5C6 21.7761 5.77614 22 5.5 22H4.5C4.22386 22 4 21.7761 4 21.5V2.5C4 2.22386 4.22386 2 4.5 2H5.5C5.77614 2 6 2.22386 6 2.5V4H12C13.1046 4 14 4.89543 14 6H19.5C19.7761 6 20 6.22386 20 6.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AlertGlyph",
});
</script>
