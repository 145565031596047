<template>
  <ViewComponent
    :accountCreationDate="currentUser.created_at"
    :accountName="currentUser.username"
    :usage="usage"
    :fromDate="fromDate"
    :toDate="toDate"
    :groupBy="groupBy"
    :hasOrder="hasOrder"
    @groupBySelected="changeGroupBy"
    @periodSelected="changePeriod"
    @resetToday="resetToday"
  />
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/Consumption.vue";
import { useOrdersStore } from "@/stores/orders";
import { useToastsStore } from "@/stores/toasts";
import { useUsageStore } from "@/stores/usage";

export default defineComponent({
  name: "ConsumptionContainer",
  components: { ViewComponent },
  setup() {
    const usageStore = useUsageStore();
    const toastsStore = useToastsStore();
    const ordersStore = useOrdersStore();

    onBeforeMount(() => {
      ordersStore.ensure();
    });

    return { usageStore, toastsStore, ordersStore };
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      groupBy: "app",
    };
  },
  computed: {
    usage() {
      return this.usageStore;
    },

    hasOrder() {
      const { items } = this.ordersStore;

      return items?.length > 0;
    },
  },
  created() {
    this.setTodayPeriod();

    this.periodFromUrl();

    this.usageStore.ensure();
    this.usageStore.promise.catch((err) => {
      if (err.message === "no billing profile") {
        return;
      }
      this.toastsStore.addOne({
        type: "error",
        title: this.$t("error.generic.title"),
        message: this.$t("error.generic.message"),
      });
    });
  },
  methods: {
    periodFromUrl() {
      let urlParams = new URLSearchParams(window.location.search);
      const hasParams = urlParams.has("from") && urlParams.has("to");
      if (!hasParams) {
        return;
      }
      let fromDate = DateTime.fromISO(urlParams.get("from"), { zone: "utc" });
      let toDate = DateTime.fromISO(urlParams.get("to"), { zone: "utc" }).endOf(
        "day",
      );

      if (!this.checkDatesValidity(fromDate, toDate)) {
        return;
      }

      this.fromDate = fromDate;
      this.toDate = toDate;
      this.usageStore.setPeriod(fromDate, toDate, false);
    },
    checkDatesValidity(fromDate, toDate) {
      if (!fromDate.isValid || !toDate.isValid) {
        this.addPersistentErrorToast("invalid_date");
        return false;
      }

      const intervalInMonths = toDate.diff(fromDate, ["months"]).months;

      if (intervalInMonths > 3) {
        this.addPersistentErrorToast("too_long_range");
        return false;
      }

      const intervalInDays = toDate.diff(fromDate, ["days"]).days;
      if (intervalInDays < 0) {
        this.addPersistentErrorToast("negative_range");
        return false;
      }

      let lastDayOfMonth = toDate.endOf("month");
      if (
        fromDate.year < 2023 &&
        (fromDate.day !== 1 || toDate.day !== lastDayOfMonth.day)
      ) {
        this.addPersistentErrorToast("monthly_range");
        return false;
      }

      const today = DateTime.now().toUTC().startOf("day");
      if (toDate >= today) {
        this.addPersistentErrorToast("no_future_range");
        return false;
      }
      return true;
    },
    addPersistentErrorToast(errorName) {
      this.toastsStore.addOne({
        type: "error",
        title: this.$t("error." + errorName + ".title"),
        message: this.$t("error." + errorName + ".message"),
        keep: true,
      });
    },
    changePeriod(e) {
      let period = DateTime.fromISO(e.value);

      this.fromDate = period.toUTC().startOf("month");

      const isPeriodCurrentMonth = DateTime.now()
        .toUTC()
        .startOf("month")
        .equals(this.fromDate);

      if (isPeriodCurrentMonth) {
        this.toDate = this.currentMonthTo();
      } else {
        this.toDate = period.toUTC().endOf("month");
      }
      this.setPeriod();
    },
    setPeriod() {
      this.usageStore.setPeriod(this.fromDate, this.toDate, true);
      this.$router.push({
        path: this.$route.path,
        query: { from: this.fromDate.toISODate(), to: this.toDate.toISODate() },
      });
    },
    currentMonthTo() {
      const today = DateTime.now();
      if (today.day === 1) {
        return today.toUTC().endOf("day");
      }
      return (this.toDate = DateTime.now()
        .toUTC()
        .minus({ day: 1 })
        .endOf("day"));
    },
    changeGroupBy(e) {
      this.groupBy = e;
    },
    setTodayPeriod() {
      this.fromDate = DateTime.now().toUTC().startOf("month");
      this.toDate = this.currentMonthTo();
    },
    resetToday() {
      this.setTodayPeriod();
      this.setPeriod();
    },
  },
});
</script>

<i18n>
en:
  error:
    generic:
      title: "Something went wrong"
      message: "Cannot fetch the consumption"
    invalid_date:
      title: "Date is invalid"
      message: "Check the date format in the url"
    too_long_range:
      title: "Period is invalid"
      message: "Period cannot exceed three months"
    negative_range:
      title: "Period is invalid"
      message: "'from' date is after 'to' date"
    monthly_range:
      title: "Period is invalid"
      message: "Only firsts and last days of months are allowed for periods before 2023"
    no_future_range:
      title: "Period is invalid"
      message: "Period's end cannot exceed yesterday"
fr:
  error:
    generic:
      title: "Quelque chose s'est mal passé"
      message: "Impossible d'obtenir la consommation"
    invalid_date:
      title: "La date est invalide"
      message: "Vérifier le format de la date dans l'url"
    too_long_range:
      title: "La période est invalide"
      message: "La période ne peut être plus grande que trois mois"
    negative_range:
      title: "La période est invalide"
      message: "La date 'from' se situe après la date `to`"
    monthly_range:
      title: "La période est invalide"
      message: "Les seules dates autorisées sont débuts et fins des mois pour les périodes pré 2023"
    no_future_range:
      title: "La période est invalide"
      message: "La fin de période ne peut être plus que hier"
</i18n>
