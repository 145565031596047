import { DateTime } from "luxon";
import { App } from "scalingo/lib/models/regional";
import { unpackData } from "scalingo/lib/utils";

import { useSessionStore } from "@/stores/session";
type TokenInfo = {
  value: string;
  staleAfter: DateTime;
};

type DBApiTokensCache = Record<string, Record<string, TokenInfo>>;

const cache: DBApiTokensCache = {};

export async function getDBToken(app: App, addonId: string) {
  cache[app.uuid] = cache[app.uuid] || {};

  const info = cache[app.uuid][addonId];

  // No token in cache? stale.
  // Token in cache but too old? stale.
  // Otherwise, not stale
  const stale = info
    ? info.staleAfter.toSeconds() < DateTime.local().toSeconds()
    : true;

  if (stale) {
    const sessionStore = useSessionStore();
    const client = sessionStore.client(app.region);

    // TODO: in case of error response, raise a custom error
    // That can be intercepted by the controller and display a clean 404
    const addon = await unpackData(
      client.apiClient().post(`/apps/${app.id}/addons/${addonId}/token`),
      "addon",
    );

    // Cache the token for a little bit less than an hour
    cache[app.uuid][addonId] = {
      value: addon.token,
      staleAfter: DateTime.local().plus({ minutes: 55 }),
    };
  }

  return cache[app.uuid][addonId].value;
}
