export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset cache"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will purge all deployment caches. The build will take longer, but given a clean state, it will remove all sides effects related to previous builds."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider le cache"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action va vider tous les caches de déploiments. Le build prendra plus longtemps, mais étant donné un état propre, il supprimera des effets de bord possibles liés aux builds précédents."])}
      }
    }
  })
}
