<template>
  <ViewComponent :orders="orders" />
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/billing/orders/List.vue";
import { Routes } from "@/router/names";
import { useOrdersStore } from "@/stores/orders";

export default defineComponent({
  name: "OrdersContainer",
  components: { ViewComponent },
  setup() {
    const ordersStore = useOrdersStore();
    const router = useRouter();

    onBeforeMount(() => {
      ordersStore.ensure();
      ordersStore.promise.then((orders) => {
        if (orders.length === 0) {
          router.push({ name: Routes.Billing.Root });
        }
      });
    });

    return { ordersStore };
  },
  computed: {
    orders() {
      const { items, promiseInfo, any, none } = this.ordersStore;
      return { items, latestFetch: promiseInfo, any, none };
    },
  },
});
</script>
