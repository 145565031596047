<template>
  <div class="mt-6 border-b border-scale-2 pb-2 mb-3">
    <div class="flex justify-center">
      <img :src="manifest.logo" class="max-h-8" />
    </div>

    <p class="mt-4 text-scale-9">
      {{ manifest.description }}
    </p>

    <p class="mt-4 text-scale-9">
      Deploying from
      <a :href="manifest.treeUrl" class="underline" target="_blank">
        {{ manifest.treeUrl }}
      </a>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OneClickDeployDescription",
  components: {},
  props: {
    manifest: Object,
  },
});
</script>

<i18n>
en:
  title: "Deploy your own {name}"
  subtitle: "Fill the minimal information required to create an app"
fr:
  title: "Déployez votre {name}"
  subtitle: "Saisissez les informations requises pour la création d'une app"
</i18n>
