export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete database"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation is irreversible. Your database and all its data will be permanently destroyed and unrecoverable."])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this database"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la base de données"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération est irréversible. Votre base de données et toutes ses données seront définitivement détruites et irrécupérables."])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cette base de données"])}
      }
    }
  })
}
