import { defineStore } from "pinia";

import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { ignore404 } from "@/lib/pinia/utils/error-handlers";
import { CreateParams } from "@/lib/scalingo/dbapi/users";
import {
  modifyItem,
  modifyCollection,
  User,
} from "@/lib/scalingo/modifiers/db/users";
import { currentAddon, currentDBClient } from "@/stores/current/db";

export const useDbUsersStore = defineStore("dbUsers", () => {
  const store = useModelsStore({
    fetchPromise: async () => {
      const addon = await currentAddon();
      const client = await currentDBClient();
      const users = await client.Users.for(addon.id);

      return modifyCollection(users);
    },
  });

  return {
    ...store,
    async create(payload: CreateParams) {
      const client = await currentDBClient();

      return client.Users.create(payload)
        .then(modifyItem)
        .then(store.insertItem);
    },
    async destroy(dbId: string, user: User) {
      const client = await currentDBClient();

      return client.Users.destroy(dbId, user.id)
        .catch(ignore404)
        .then(() => store.removeItem(user));
    },
  };
});
