export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove a variable"])},
        "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to remove the variable '", _interpolate(_named("name")), "'?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une variable"])},
        "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Souhaitez-vous vraiment supprimer la variable '", _interpolate(_named("name")), "' ?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      }
    }
  })
}
