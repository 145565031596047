<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <div
        class="flex"
        :class="{
          'text-success': inSuccess,
          'text-error': inFailure,
          'text-ongoing': isOngoing,
        }"
      >
        <CheckGlyph v-if="inSuccess" />
        <DangerSignGlyph v-if="inFailure" />
        <ReloadGlyph v-if="isOngoing" :animated="true" />

        <span class="ml-2 font-bold">
          {{ $t(`statuses.${status}`) }}
        </span>
      </div>
    </template>
    <template #more>
      <div>{{ formatDeploymentDuration(event.type_data.duration) }}</div>
      <div class="px-1">-</div>
      <div>{{ shortGitRef(event.type_data.git_ref) }}</div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";
import { shortGitRef } from "@/lib/utils/git";
import { formatDuration } from "@/lib/utils/time";
import {
  STATUSES_ONGOING,
  STATUSES_SUCCESSES,
  STATUSES_FAILURES,
} from "@/store/deployments";

export default defineComponent({
  name: "DeploymentEvent",
  components: { ReloadGlyph, BaseEvent, CheckGlyph, DangerSignGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.event.type_data?.status;
    },
    inSuccess() {
      return STATUSES_SUCCESSES.includes(this.status);
    },
    inFailure() {
      return STATUSES_FAILURES.includes(this.status);
    },
    isOngoing() {
      return STATUSES_ONGOING.includes(this.status);
    },
  },
  methods: {
    shortGitRef,

    formatDeploymentDuration(input) {
      return formatDuration({ seconds: input });
    },
  },
});
</script>

<i18n>
en:
  statuses:
    queued: "Queued"
    building: "Building"
    pushing: "Pushing"
    starting: "Starting"
    success: "Successful deployment"
    "crashed-error": "Crashed when booting"
    "timeout-error": "Timeout at boot"
    "build-error": "Build error"
    "hook-error": "Hook error"
    aborted: "Aborted"
fr:
  statuses:
    queued: "Queued"
    building: "Building"
    pushing: "Pushing"
    starting: "Starting"
    success: "Déploiement réussi"
    "crashed-error": "Crashed when booting"
    "timeout-error": "Timeout at boot"
    "build-error": "Build error"
    "hook-error": "Hook error"
    aborted: "Aborted"
</i18n>
