export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance operations list"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance operations scheduled or carried out in the last 12 months."])},
        "headers": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
          "applyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply date"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
        },
        "status": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
          "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
          "notified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
          "queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
          "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])}
        },
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No maintenance operations have been carried out on this database."])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to "])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des opérations de maintenance"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations de maintenance planifiées ou effectuées au cours des 12 derniers mois."])},
        "headers": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
          "applyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'application"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
        },
        "status": {
          "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminée"])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echouée"])},
          "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulée"])},
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifiée"])},
          "notified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmée"])},
          "queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmée"])},
          "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])}
        },
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune opération de maintenance n'a été effectuée sur cette base de données."])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à "])}
      }
    }
  })
}
