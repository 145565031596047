import { defineStore } from "pinia";

import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { useSessionStore } from "@/stores/session";

export const useCollaboratorsStore = defineStore("collaborators", () => {
  const { client } = useSessionStore();

  return useModelsStore({
    async fetchPromise() {
      const response = await client().Collaborators.all({});

      return response.collaborators;
    },
  });
});
