<template>
  <main>
    <Card>
      <template #header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template #buttons>
            <SCButton @click="$emit('startTokenCreation')">
              {{ $t("add") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <TokensList
          :tokens="tokens"
          @startTokenDeletion="(e) => $emit('startTokenDeletion', e)"
          @startTokenRenewal="(e) => $emit('startTokenRenewal', e)"
        />
        <NoToken :tokens="tokens"></NoToken>
      </template>
    </Card>

    <TokenShowModal
      v-if="shownToken"
      :context="shownToken.context"
      :token="shownToken.token"
      @modalClosed="$emit('dismissShownToken')"
    />

    <AddTokenModal
      v-if="createTokenHandler"
      :formHandler="createTokenHandler"
      @modalClosed="$emit('cancelTokenCreation')"
      @formSubmitted="(e) => $emit('confirmTokenCreation', e)"
    />

    <RemoveTokenModal
      v-if="deleteTokenHandler"
      :formHandler="deleteTokenHandler"
      @modalClosed="$emit('cancelTokenDeletion')"
      @formSubmitted="$emit('confirmTokenDeletion')"
    />

    <RenewTokenModal
      v-if="renewTokenHandler"
      :formHandler="renewTokenHandler"
      @modalClosed="$emit('cancelTokenRenewal')"
      @formSubmitted="$emit('confirmTokenRenewal')"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import AddTokenModal from "@/components/parts/account/showAccountTokens/AddTokenModal.vue";
import NoToken from "@/components/parts/account/showAccountTokens/NoToken.vue";
import RemoveTokenModal from "@/components/parts/account/showAccountTokens/RemoveTokenModal.vue";
import RenewTokenModal from "@/components/parts/account/showAccountTokens/RenewTokenModal.vue";
import TokenShowModal from "@/components/parts/account/showAccountTokens/TokenShowModal.vue";
import TokensList from "@/components/parts/account/showAccountTokens/TokensList.vue";

export default defineComponent({
  name: "Tokens",
  components: {
    NoToken,
    AddTokenModal,
    Card,
    CardHeader,
    SCButton,
    TokenShowModal,
    TokensList,
    RemoveTokenModal,
    RenewTokenModal,
  },
  props: {
    tokens: Object,
    shownToken: Object,
    createTokenHandler: Object,
    deleteTokenHandler: Object,
    renewTokenHandler: Object,
  },
  emits: [
    "startTokenCreation",
    "cancelTokenCreation",
    "confirmTokenCreation",
    "startTokenDeletion",
    "cancelTokenDeletion",
    "confirmTokenDeletion",
    "startTokenRenewal",
    "cancelTokenRenewal",
    "confirmTokenRenewal",
    "dismissShownToken",
  ],
});
</script>

<i18n>
en:
  title: "API Tokens"
  subtitle: "These tokens allow you to interact with our APIs without using your account credentials"
  add: "Add"
  empty: "No token yet"
fr:
  title: "Tokens API"
  subtitle: "Ces tokens vous permettent d'interagir avec nos APIs sans avoir à fournir les identifiants de votre compte utilisateur"
  add: "Ajouter"
  empty: "Aucun token"
</i18n>
