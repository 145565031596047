import axios from "axios";
import { DateTime } from "luxon";
import XMLJS from "xml-js";

const FEED_URL = "https://doc.scalingo.com/changelog/feed.xml";

export interface ChangelogEntry {
  title: string;
  description: string;
  link: string;
  categories: string[];
  publishedAt: string;
}

export function fetchLastEntries(count = 20): Promise<ChangelogEntry[]> {
  return axios.get<string>(FEED_URL).then((resolved) => {
    const parsedResponse = XMLJS.xml2js(resolved.data);
    const entries = changelogEntriesFromResponse(parsedResponse, count);

    return entries;
  });
}

function changelogEntriesFromResponse(
  content: XMLJS.Element | XMLJS.ElementCompact,
  count: number,
): ChangelogEntry[] {
  const items: XMLJS.Element[] = [];

  const rssElement = content.elements[0];
  const channelElement = rssElement.elements[0];

  for (const item of channelElement.elements) {
    if (item.name === "item") {
      items.push(item);
    }

    if (items.length === count) {
      break;
    }
  }

  return items.map((item) => {
    const parts = titleFromEntry(item)?.split(" - ");
    let mainCategory = parts?.shift()?.trim()?.toLowerCase();
    let title = parts?.join(" - ");

    if (["api", "cli"].includes(mainCategory as string)) {
      mainCategory = mainCategory?.toUpperCase();
    }

    if (mainCategory === "CLI") {
      if (title?.startsWith("CLI - ")) {
        title = title.slice(6);
      }
    }

    return {
      title,
      mainCategory,
      description: descriptionFromEntry(item),
      link: linkFromEntry(item),
      categories: categoriesFromEntry(item),
      publishedAt: publishedAtFromEntry(item),
    };
  }) as ChangelogEntry[];
}

function titleFromEntry(item: XMLJS.Element): string | undefined {
  return textForEntryChildren(item, "title");
}

function categoriesFromEntry(item: XMLJS.Element): string[] {
  const elems = item.elements?.filter((el) => {
    const isCategory = el.name === "category";
    const isNotChangelog = el.elements?.[0].text !== "changelog";

    return isCategory && isNotChangelog;
  });

  return (elems || [])
    .map((elem) => elem.elements?.[0].text)
    .filter((s) => !!s) as string[];
}

function descriptionFromEntry(item: XMLJS.Element): string | undefined {
  return textForEntryChildren(item, "description");
}

function publishedAtFromEntry(item: XMLJS.Element): string | null {
  const date = textForEntryChildren(item, "pubDate");

  if (date) {
    return DateTime.fromRFC2822(date).toISO();
  }
  return null;
}

function linkFromEntry(item: XMLJS.Element): string | undefined {
  return textForEntryChildren(item, "link")?.trim();
}

function textForEntryChildren(
  item: XMLJS.Element,
  name: string,
): string | undefined {
  const titleEl = item.elements?.find((el) => el.name === name);

  return titleEl?.elements?.[0].text as string;
}
