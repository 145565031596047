import {
  CollectionStore,
  CollectionStoreOptions,
  InsertItemFn,
  InsertItemsFn,
  useCollectionStore,
} from "@/lib/pinia/use-collection-store-next";

/**
 * A model collection store. The same as a collection store, but containing models (an object with an ID key).
 */
export type ModelItem = { id: string };
export type ModelsStore<DataType extends ModelItem> =
  CollectionStore<DataType> & {
    /**
     * Fetch a single item and add it to the store.
     * Note: this will throw an error if `fetchOnePromise` is not defined in the options.
     */
    fetchOne: FetchOneFn<DataType>;
    /** Finds an item in the store by its ID */
    findItem: FindItemFn<DataType>;
    removeItem: RemoveItemFn<DataType>;
  };

export type ModelsStoreOptions<DataType extends ModelItem> =
  CollectionStoreOptions<DataType> & {
    fetchOnePromise?: (id: string) => Promise<DataType>;
  };

export type FetchOneFn<DataType extends ModelItem> = (
  id: string,
) => Promise<DataType>;

export type FindItemFn<DataType extends ModelItem> = (
  id: string,
) => DataType | null;

export type RemoveItemFn<DataType extends ModelItem> = (
  model: DataType,
) => void;

/** Returns an data store tailored for a collection of models */
export function useModelsStore<DataType extends ModelItem>(
  options: ModelsStoreOptions<DataType>,
): ModelsStore<DataType> {
  const store = useCollectionStore(options);

  /** Fetch a single item and add it to the store */
  const fetchOne = async function (id: string) {
    if (!options.fetchOnePromise) {
      return Promise.reject(
        new Error("fetchOnePromise is not defined in the options"),
      );
    }

    return options.fetchOnePromise(id).then(insertItem);
  };

  /**
   * Finds a model by its ID and returns its index or null
   */
  const _findModelIndex = function (id: string) {
    if (!store.items.value) return null;

    // findIndex returns -1 if the item is not in the collection, which is annoying
    const index = store.items.value.findIndex((elem) => elem.id === id);

    // We return null instead, it makes more sense.
    return index > -1 ? index : null;
  };

  /** Finds a model by its ID */
  const findItem = function (id: string) {
    if (!store.items.value) return null;

    return store.items.value.find((elem) => elem.id === id) ?? null;
  };

  // Inserts or updates a model in the store
  const insertItem: InsertItemFn<DataType> = function (newItem) {
    if (!store.items.value) store.items.value = [];

    const modelIndex = _findModelIndex(newItem.id);

    if (modelIndex === null) {
      store.items.value.push(newItem);
    } else {
      store.items.value[modelIndex] = newItem;
    }

    return newItem;
  };

  // Inserts or updates many models in the store
  const insertItems: InsertItemsFn<DataType> = function (newItems) {
    newItems.forEach(insertItem);

    return newItems;
  };

  // Removes an item from the store
  const removeItem = function (model: DataType) {
    if (!store.items.value) return;

    store.items.value = store.items.value.filter((modelInColl) => {
      return modelInColl.id !== model.id;
    });
  };

  return {
    ...store,
    fetchOne,
    findItem,
    insertItem,
    insertItems,
    removeItem,
  };
}
