export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All orders associated with your account."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These orders are invoiced through the C3 market and not by Scalingo."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les commandes associées à votre compte."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces commandes sont facturées à travers la place de marché C3 et non par Scalingo."])}
      }
    }
  })
}
