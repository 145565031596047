<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66667 3C2.74583 3 2 3.73227 2 4.63636V7.09091V20.1818C2.00002 20.3988 2.08783 20.6069 2.2441 20.7603C2.40038 20.9138 2.61233 21 2.83333 21H21.1667C21.3877 21 21.5996 20.9138 21.7559 20.7603C21.9122 20.6069 22 20.3988 22 20.1818V6.27273V4.63636C22 3.73227 21.2542 3 20.3333 3H3.66667ZM3.66667 7.09091H20.3333V19.3636H3.66667V7.09091ZM6.99186 10.3556C6.82608 10.3557 6.66409 10.4043 6.52658 10.4952C6.38907 10.5861 6.2823 10.7152 6.21992 10.866C6.15754 11.0168 6.14238 11.1824 6.17638 11.3417C6.21039 11.501 6.29201 11.6468 6.41081 11.7603L8.32162 13.6364L6.41081 15.5124C6.33083 15.5878 6.26698 15.6781 6.22299 15.778C6.179 15.878 6.15576 15.9855 6.15464 16.0944C6.15351 16.2032 6.17451 16.3112 6.21642 16.412C6.25833 16.5127 6.3203 16.6043 6.39869 16.6813C6.47709 16.7583 6.57035 16.8191 6.673 16.8602C6.77564 16.9014 6.88562 16.922 6.99649 16.9209C7.10736 16.9198 7.21688 16.897 7.31866 16.8538C7.42043 16.8106 7.5124 16.7479 7.58919 16.6694L10.0892 14.2148C10.2454 14.0614 10.3332 13.8533 10.3332 13.6364C10.3332 13.4194 10.2454 13.2113 10.0892 13.0579L7.58919 10.6033C7.51153 10.525 7.41866 10.4627 7.31607 10.4201C7.21347 10.3776 7.10323 10.3557 6.99186 10.3556ZM12 15.2727C11.8896 15.2712 11.7799 15.2912 11.6775 15.3317C11.575 15.3721 11.4817 15.4321 11.4031 15.5082C11.3244 15.5844 11.262 15.6751 11.2194 15.7751C11.1767 15.8751 11.1548 15.9825 11.1548 16.0909C11.1548 16.1993 11.1767 16.3067 11.2194 16.4067C11.262 16.5067 11.3244 16.5975 11.4031 16.6736C11.4817 16.7497 11.575 16.8097 11.6775 16.8502C11.7799 16.8906 11.8896 16.9106 12 16.9091H17C17.1104 16.9106 17.2201 16.8906 17.3225 16.8502C17.425 16.8097 17.5183 16.7497 17.5969 16.6736C17.6756 16.5975 17.738 16.5067 17.7806 16.4067C17.8233 16.3067 17.8452 16.1993 17.8452 16.0909C17.8452 15.9825 17.8233 15.8751 17.7806 15.7751C17.738 15.6751 17.6756 15.5844 17.5969 15.5082C17.5183 15.4321 17.425 15.3721 17.3225 15.3317C17.2201 15.2912 17.1104 15.2712 17 15.2727H12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RunGlyph",
});
</script>
