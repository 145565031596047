export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PostgreSQL Extensions"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend the capabilities of your PostgreSQL database."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PostgreSQL extensions management is exclusively done through the remote interactive console. For more information, please <a class='underline' href='https://doc.scalingo.com/databases/postgresql/managing#managing-extensions' target='_blank'>refer to our documentation</a>."])},
        "withExtensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database has the following active extensions:"])},
        "noExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database does not use any PostgreSQL extensions. Popular ones include <a class='underline' href='https://postgis.net/' target='_blank'>PostGIS</a>, <a class='underline' href='https://github.com/pgvector/pgvector' target='_blank'>pgvector</a>, and <a class='underline' href='https://github.com/timescale/timescaledb' target='_blank'>TimescaleDB</a>. <a class='underline' href='https://doc.scalingo.com/databases/postgresql/overview#available-extensions' target='_blank'>Check the list of supported extensions</a> for Scalingo for PostgreSQL®."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions PostgreSQL"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étendez les fonctionnalités de votre base de données PostgreSQL."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gestion des extensions PostgreSQL s'effectue exclusivement via la console interactive à distance. Pour plus d'informations, veuillez <a class='underline' href='https://doc.scalingo.com/databases/postgresql/managing#managing-extensions' target='_blank'>consulter notre documentation</a>."])},
        "withExtensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données dispose des extensions actives suivantes :"])},
        "noExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données n'utilise aucune extension PostgreSQL. <a class='underline' href='https://postgis.net/' target='_blank'>PostGIS</a>, <a class='underline' href='https://github.com/pgvector/pgvector' target='_blank'>pgvector</a>, ou encore <a class='underline' href='https://github.com/timescale/timescaledb' target='_blank'>TimescaleDB</a> font partie des plus populaires. <a class='underline' href='https://doc.scalingo.com/databases/postgresql/overview#available-extensions' target='_blank'>Consultez la liste des extensions supportées</a> par Scalingo pour PostgreSQL®."])}
      }
    }
  })
}
