<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12.0082C21.9967 16.3106 19.2419 20.1287 15.16 21.4882C15.0072 21.5353 14.8413 21.5095 14.71 21.4182C14.5788 21.3227 14.5008 21.1705 14.5 21.0082V18.3382C14.5216 17.6191 14.3489 16.9074 14 16.2782C13.9472 16.1959 13.9472 16.0905 14 16.0082C14.0406 15.9323 14.1148 15.8804 14.2 15.8682C16.64 15.6182 18 14.6482 18 12.0082C18.0588 10.7756 17.6315 9.56907 16.81 8.64821C16.9298 8.25922 16.9938 7.85519 17 7.44821C16.9957 7.08333 16.9453 6.72045 16.85 6.36821C16.7871 6.13333 16.5615 5.98009 16.32 6.00821C15.4038 6.13826 14.5509 6.55073 13.88 7.18821C12.6382 6.94827 11.3619 6.94827 10.12 7.18821C9.44916 6.55073 8.59625 6.13826 7.68001 6.00821C7.43849 5.98009 7.21288 6.13333 7.15001 6.36821C7.0547 6.72045 7.0043 7.08333 7.00001 7.44821C7.00623 7.85519 7.0702 8.25922 7.19001 8.64821C6.36849 9.56907 5.94119 10.7756 6.00001 12.0082C6.00001 14.7882 7.51001 15.7182 10.28 15.9482C9.93615 16.3988 9.70011 16.9222 9.59001 17.4782C9.59001 17.4782 9.59001 17.5482 9.59001 17.5982C9.58519 17.6448 9.58519 17.6917 9.59001 17.7382C9.5484 18.0526 9.26573 18.2778 8.95001 18.2482C8.79946 18.2411 8.65247 18.2001 8.52001 18.1282C7.97522 17.7964 7.49429 17.3697 7.10001 16.8682C6.8488 16.5834 6.58171 16.3129 6.30001 16.0582C6.12101 15.9033 5.9227 15.7722 5.71001 15.6682C5.56096 15.5845 5.37905 15.5845 5.23001 15.6682C5.08693 15.76 5.00029 15.9182 5.00001 16.0882V16.1482C5.00029 16.3182 5.08693 16.4764 5.23001 16.5682C5.58897 16.8687 5.89931 17.2229 6.15001 17.6182C6.57339 18.2816 7.11184 18.8641 7.74001 19.3382C8.15388 19.6157 8.64174 19.762 9.14001 19.7582H9.50001V21.0082C9.49923 21.1705 9.42126 21.3227 9.29001 21.4182C9.15874 21.5095 8.99279 21.5353 8.84001 21.4882C3.99205 19.8735 1.14125 14.8637 2.22938 9.87112C3.31751 4.87854 7.9945 1.5094 13.0747 2.05854C18.1549 2.60768 22.0039 6.89843 22 12.0082Z"
      fill="currentColor"
    />
    <path
      d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18Z"
      fill="white"
    />
    <path
      d="M18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13ZM18 15.1429C18.3943 15.1429 18.7143 15.4621 18.7143 15.8571V17.2857H20.1429C20.5371 17.2857 20.8571 17.605 20.8571 18C20.8571 18.395 20.5371 18.7143 20.1429 18.7143H18.7143V20.1429C18.7143 20.5379 18.3943 20.8571 18 20.8571C17.6057 20.8571 17.2857 20.5379 17.2857 20.1429V18.7143H15.8571C15.4629 18.7143 15.1429 18.395 15.1429 18C15.1429 17.605 15.4629 17.2857 15.8571 17.2857H17.2857V15.8571C17.2857 15.4621 17.6057 15.1429 18 15.1429Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GithubAddGlyph",
});
</script>
