<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C7.26333 4 6.66667 4.61023 6.66667 5.36364V6.04545H10V5.36364C10 4.61023 9.40333 4 8.66667 4H8ZM15.3333 4C14.5967 4 14 4.61023 14 5.36364V6.04545H17.3333V5.36364C17.3333 4.61023 16.7367 4 16 4H15.3333ZM5.33333 7.40909C4.59667 7.40909 4 8.01932 4 8.77273V17.6364C4 18.3898 4.59667 19 5.33333 19H18.6667C19.4033 19 20 18.3898 20 17.6364V8.77273C20 8.01932 19.4033 7.40909 18.6667 7.40909H5.33333Z"
      fill="currentColor"
    />
    <circle cx="18" cy="16" r="5.5" fill="white" stroke="white" />
    <path
      d="M18 11C15.2386 11 13 13.2386 13 16C13 18.7614 15.2386 21 18 21C20.7614 21 23 18.7614 23 16C23 13.2386 20.7614 11 18 11ZM15.8571 15.2857H20.1429C20.5371 15.2857 20.8571 15.605 20.8571 16C20.8571 16.395 20.5371 16.7143 20.1429 16.7143H15.8571C15.4629 16.7143 15.1429 16.395 15.1429 16C15.1429 15.605 15.4629 15.2857 15.8571 15.2857Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonDelGlyph",
});
</script>
