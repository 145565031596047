<template>
  <div>
    <div class="text-xl flex">
      <h2 class="text-scale-10 font-bold">{{ title }}</h2>
      <div v-if="withCount" class="text-scale-4 pl-1">({{ count }})</div>
    </div>
    <div class="text-scale-7">{{ subtitle }}</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SectionTitle",
  props: {
    title: String,
    withCount: Boolean,
    count: Number,
    subtitle: String,
  },
});
</script>
