<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.28711 1.47974L3.05859 10.0901L2.87695 10.5793L1.34766 14.6575L12 22.4065L22.6523 14.6575L21.123 10.5793L17.71 1.47974L14.9121 10.0901H9.08789L6.28711 1.47974ZM6.1875 6.02075L7.51172 10.0901H4.66113L6.1875 6.02075ZM17.8125 6.02075L19.3389 10.0901H16.4883L17.8125 6.02075ZM4.09863 11.5901H7.99805L10.5703 19.512L3.15234 14.1155L4.09863 11.5901ZM9.57422 11.5901H14.4258L12 19.052L9.57422 11.5901ZM16.002 11.5901H19.8984L20.8477 14.1155L13.4268 19.512L16.002 11.5901Z"
      fill="currentColor"
    />
    <circle cx="18" cy="18" r="5.5" fill="currentColor" stroke="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18ZM18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1951 15.6219L20.3781 15.8049C20.456 15.8828 20.4999 15.9885 20.5 16.0987V16.3094C20.5014 16.4216 20.4574 16.5295 20.3781 16.6088L19.8653 17.1133C19.8393 17.1396 19.8039 17.1543 19.767 17.1543C19.73 17.1543 19.6946 17.1396 19.6686 17.1133L18.8898 16.3233C18.837 16.2694 18.837 16.1831 18.8898 16.1292L19.3914 15.6219C19.4707 15.5425 19.5786 15.4985 19.6907 15.4999H19.9014C20.0116 15.5 20.1173 15.5439 20.1951 15.6219ZM19.3388 17.6346C19.3916 17.5807 19.3916 17.4944 19.3388 17.4405L18.5461 16.6588C18.5201 16.6325 18.4847 16.6178 18.4477 16.6178C18.4108 16.6178 18.3754 16.6325 18.3494 16.6588L16.1128 18.896C16.0587 18.9496 16.0163 19.0137 15.9881 19.0845L15.5114 20.2876C15.4844 20.35 15.5067 20.4229 15.5641 20.4595C15.6033 20.4987 15.6622 20.5107 15.7137 20.49L16.9165 20.0021C16.9872 19.9739 17.0514 19.9314 17.105 19.8773L19.3388 17.6346Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GitlabEditGlyph",
});
</script>
