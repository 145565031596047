export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup schedule"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the most appropriate backup time."])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])},
        "dailyBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["every day at "])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled backups are not configured to run"])},
        "nextRun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next backup"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
        "infoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter and Business plans include scheduled, managed backups, that create a daily copy of your database."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["none"])},
        "disabledBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planification des backups"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez l'horaire de backup le plus approprié."])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifier"])},
        "dailyBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chaque jour à"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les backups planifiés ne sont pas configurés pour s'exécuter"])},
        "nextRun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain backup"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifié"])},
        "infoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plans Starter et Business incluent des sauvegardes planifiées et gérées, qui créent une copie quotidienne de votre base de données."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aucun"])},
        "disabledBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé"])}
      }
    }
  })
}
