export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clicks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of clicks on your link"])}
        },
        "signups": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign ups"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of sign ups from your link"])}
        },
        "earnings": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit added to your account"])}
        }
      },
      "fr": {
        "clicks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicks"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de clic sur votre lien"])}
        },
        "signups": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscriptions"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'inscriptions depuis votre lien"])}
        },
        "earnings": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit ajouté à votre compte"])}
        }
      }
    }
  })
}
