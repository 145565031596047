export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
        "backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "queryStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query Statistics"])},
        "runningQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running Queries"])}
      },
      "fr": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue générale"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métriques"])},
        "backups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
        "queryStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques des requêtes"])},
        "runningQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes en cours d'exécution"])}
      }
    }
  })
}
