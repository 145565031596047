<template>
  <SimpleModal size="w-1/4" @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template v-slot:body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6 flex">
              <label class="block text-sm font-medium leading-5 capitalize"
                >{{ $ta("variable", "name") }}:</label
              >
              <span class="ml-2 font-mono text-sm">{{
                formHandler.variable.name
              }}</span>
            </div>
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.value"
                name="value"
                rules="required"
              >
                <TextArea
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('variable', 'value')"
                  :rows="8"
                  labelClass="capitalize"
                  inputClass="font-mono"
                  :errors="errors"
                  @update:modelValue="handleChange"
                ></TextArea>
              </Field>
            </div>
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextArea from "@/components/molecules/inputs/TextArea.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "EditVariableModal",
  components: {
    SimpleModal,
    SCButton,
    TextArea,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["formSubmitted", "modalClosed"],
});
</script>

<i18n>
en:
  title: "Edit variable"
  subtitle: "Change the value of an environment variable"
  buttonName: "Update"
fr:
  title: "Modifier une variable"
  subtitle: "Changer la valeur d'une variable d'environemment"
  buttonName: "Mettre a jour"
</i18n>
