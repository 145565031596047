<template>
  <svg width="40" height="40" viewBox="0 0 4.096 4.0959725">
    <path
      d="M 1.9196018,1.5981545 C 1.7492224,1.8845718 1.585989,2.1619321 1.4193512,2.4372048 1.3765557,2.5078777 1.3553747,2.565447 1.3895629,2.6552775 1.4839541,2.9034437 1.350798,3.1449456 1.1005443,3.2104959 0.8645343,3.2723367 0.63459439,3.1172289 0.58776816,2.8645503 c -0.0414948,-0.223645 0.1320641,-0.44289 0.37865654,-0.4778491 0.0206511,-0.00295 0.0417518,-0.00329 0.07647,-0.00589 L 1.4179863,1.7518332 C 1.1820727,1.5172524 1.0416582,1.2430556 1.0727312,0.90326036 1.094699,0.66307516 1.1891544,0.45550456 1.361782,0.28538216 1.6924239,-0.04041023 2.1968497,-0.093162 2.586169,0.15689906 2.9600723,0.39710026 3.1313188,0.86497716 2.9853482,1.265425 2.875284,1.2355885 2.7644491,1.2054951 2.6426142,1.1724469 2.6884448,0.94979746 2.6545456,0.74987066 2.5043837,0.57859206 2.405175,0.46550896 2.2778642,0.40623746 2.133114,0.38439806 1.842907,0.34055876 1.5579832,0.52699646 1.4734359,0.81183996 1.377471,1.1350952 1.522719,1.3991752 1.9196018,1.5981706 Z"
      fill="#c73a63"
      style="stroke-width: 0.0160584"
    />
    <path
      d="M 2.4061867,1.2594352 C 2.5262231,1.471197 2.6481062,1.6861544 2.7688973,1.8991367 3.3794368,1.710242 3.8397663,2.0482227 4.0049106,2.4100661 4.2043878,2.8471591 4.06802,3.3648491 3.6762759,3.6345174 3.2741741,3.9113317 2.7656535,3.8640398 2.4093984,3.5084431 2.5001925,3.4324388 2.5914362,3.3560972 2.6889266,3.2745367 3.0407977,3.5024372 3.3485565,3.4917102 3.5770351,3.2218331 3.7718714,2.9916042 3.7676481,2.6483243 3.5671592,2.4229289 3.3357901,2.1628313 3.0258795,2.1548985 2.6512697,2.4045741 2.4958728,2.1288839 2.337778,1.8553937 2.187295,1.5777122 2.1365505,1.484124 2.0805389,1.4298146 1.9661711,1.4100146 1.7751888,1.3769022 1.6518926,1.212898 1.6444897,1.029158 c -0.00727,-0.18171664 0.099787,-0.34597774 0.267115,-0.40997034 0.1657546,-0.063415 0.3602697,-0.012236 0.4717631,0.1286918 0.091115,0.1151386 0.1200684,0.2447297 0.072118,0.38673394 -0.013328,0.0396 -0.030591,0.077915 -0.049299,0.1248378 z"
      fill="#4b4b4b"
      style="stroke-width: 0.0160584"
    />
    <path
      d="M 2.6930857,2.9905283 H 1.9580937 C 1.8876456,3.2802856 1.7354765,3.5142241 1.4732913,3.6629889 1.2694623,3.7786092 1.0497837,3.8178077 0.81597376,3.7800545 0.38548078,3.7106341 0.03348114,3.3230973 0.00248847,2.8867269 -0.03258302,2.3923858 0.30719619,1.9529804 0.76009061,1.8542857 0.79135627,1.9678344 0.82295916,2.0824752 0.85422482,2.195751 0.43869824,2.4077537 0.29487941,2.6748687 0.41117418,3.008883 0.51354634,3.3028155 0.8043475,3.4639292 1.1201034,3.4016227 1.4425556,3.3379994 1.6051306,3.0700172 1.5852825,2.6399418 c 0.3056872,0 0.6116314,-0.00316 0.9173508,0.00156 0.119378,0.00188 0.211537,-0.010502 0.301464,-0.1157487 0.1480582,-0.173176 0.4205849,-0.1575512 0.5800446,0.006 0.1629604,0.1671516 0.155156,0.4360973 -0.017295,0.5961994 -0.1663807,0.154485 -0.4292403,0.146231 -0.5849102,-0.02023 -0.031988,-0.034301 -0.0572,-0.075041 -0.088851,-0.1171942 z"
      fill="#4a4a4a"
      style="stroke-width: 0.0160584"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGPlatformWebhook",
});
</script>
