import { Variable } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/environment";

import { scalingoClient } from "@/lib/scalingo/client";
import { App } from "@/lib/scalingo/modifiers/apps";
import {
  BULK_DELETE,
  BULK_UPDATE,
  CREATE,
  DESTROY,
  HANDLE_FETCH,
  HANDLE_OPERATION,
  REFRESH,
  UPDATE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import {
  ADD,
  DELETE,
  MERGE,
  SET_ALL,
  SET_ONE,
} from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  EnsureOptions,
  CollectionWithFetch,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export class VariablesStore extends CollectionStore<Variable> {
  actions = CollectionStore.buildActions<Variable>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Environment.for(
          currentApp.id,
        ),
        resolveAction: SET_ALL,
      });
    },
    [CREATE](context, payload) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Environment.create(
          currentApp.id,
          payload,
        ),
        resolveAction: ADD,
      });
    },
    [UPDATE](context, { variable, value }) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Environment.update(
          currentApp.id,
          variable.id,
          value,
        ),
        resolveAction: SET_ONE,
      });
    },
    [DESTROY](context, { variable }) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Environment.destroy(
          currentApp.id,
          variable.id,
        ),
        resolveAction: () => context.commit(DELETE, variable),
      });
    },
    [BULK_UPDATE](context, variables) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).Environment.bulkUpdate(currentApp.id, variables),
        resolveAction(variables: Variable[]) {
          context.commit(MERGE, variables);
        },
      });
    },
    [BULK_DELETE](context, variableIDs) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).Environment.bulkDestroy(currentApp.id, variableIDs),
        resolveAction() {
          variableIDs.forEach((id: string) => {
            context.commit(DELETE, { id });
          });
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<Variable>();
  getters = CollectionStore.buildGetters<Variable>();
}

export const Variables = buildMapping(new VariablesStore(), "variables");

export function listVariables(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Variable>>,
): CollectionWithFetch<Variable> {
  return {
    items: listItems(store.getters[Variables.getters.ALL], opts),
    latestFetch: store.getters[Variables.getters.LATEST_FETCH],
  };
}

export function ensureVariables(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(Variables.actions.ENSURE, opts);
}

export function createVariable(
  store: ApplicationStore,
  payload: CreateParams,
): Promise<RemoteOperation<Variable>> {
  return store.dispatch(Variables.actions.CREATE, payload);
}

export function updateVariable(
  store: ApplicationStore,
  variable: Variable,
  value: string,
): Promise<RemoteOperation<Variable>> {
  return store.dispatch(Variables.actions.UPDATE, { variable, value });
}

export function deleteVariable(
  store: ApplicationStore,
  variable: Variable,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Variables.actions.DESTROY, { variable });
}

export function bulkUpdateVariables(
  store: ApplicationStore,
  variables: Pick<Variable, "name" | "value">[],
): Promise<RemoteOperation<Variable[]>> {
  return store.dispatch(Variables.actions.BULK_UPDATE, variables);
}

export function bulkDeleteVariables(
  store: ApplicationStore,
  variables: string[],
): Promise<RemoteOperation<Variable[]>> {
  return store.dispatch(Variables.actions.BULK_DELETE, variables);
}

interface VariableGenerator {
  generator: "url" | "template" | "secret";
  template: string;
}

export function generateVariableValue(
  app: App,
  variable: VariableGenerator,
): string {
  const generator = variable.generator;
  const url = app.url;
  const name = app.name;

  if (generator == "url") {
    return variable.template ? variable.template.replace("%URL%", url) : url;
  }

  if (generator == "template") {
    return variable.template
      .replace("%APP%", name)
      .replace("%APP_HOSTNAME%", url.replace("https://", ""))
      .replace("%URL", url);
  }

  if (generator === "secret") {
    const arr = new Uint8Array(32);
    window.crypto.getRandomValues(arr);
    let value = "";

    arr.forEach((n) => (value += n.toString(16)));

    return value;
  }

  return "";
}
