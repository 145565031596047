<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <AddonDatabaseGlyph class="h-6 w-6 text-scale-6"></AddonDatabaseGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      {{ $t("maintenance") }}
      <span class="mx-1 font-medium">
        {{ event.type_data.addon_name }},
        {{ formatDate(nextMaintenanceWindow) }}
      </span>
      {{ $t("between") }}
      <span class="mx-1 font-medium">
        {{ formatToHours(nextMaintenanceWindow) }}
      </span>
      {{ $t("and") }}
      <span class="mx-1 font-medium">
        {{
          formatToHours(
            nextMaintenanceWindow.plus({
              hours: event.type_data.maintenance_window_in_hours,
            }),
          )
        }}
      </span>
      UTC.
    </template>
    <template #body>
      <DatabaseMaintenanceEventDetails :event="event" />
    </template>
  </BaseEvent>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import AddonDatabaseGlyph from "@/components/atoms/glyphs/AddonDatabaseGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";
import DatabaseMaintenanceEventDetails from "@/components/parts/events/DatabaseMaintenanceEventDetails.vue";
import { formatToHours, formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "PlanDatabaseMaintenanceEvent",
  components: {
    AddonDatabaseGlyph,
    BaseEvent,
    DatabaseMaintenanceEventDetails,
  },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nextMaintenanceWindow() {
      return DateTime.fromISO(
        this.event.type_data.next_maintenance_window,
      ).setZone("utc");
    },
  },
  methods: {
    formatToHours,
    formatDate(date) {
      return formatDateTime(date, "DDDD");
    },
  },
});
</script>

<i18n>
  en:
    title: "Scheduled database maintenance"
    maintenance: "An upcoming scheduled maintenance will impact the database"
    between: "between"
    and: "and"
  fr:
    title: "Maintenance de base de données planifiée"
    maintenance: "Une maintenance planifiée aura un impact sur la base de données"
    between: "entre"
    and: "et"
</i18n>
