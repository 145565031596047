<template>
  <section>
    <LoadingCardState v-if="variables.latestFetch.isLoading" />

    <template v-else-if="variables.latestFetch.isSuccess">
      <template v-if="hasMultilineVars">
        <SCAlert
          kind="warning"
          icon="title"
          :title="$t('readonly.title')"
          class="mb-4"
        >
          <template #icon>
            <WarningSignGlyph />
          </template>

          {{ $t("readonly.content") }}
        </SCAlert>

        <Codemirror
          v-model="form"
          autofocus
          disabled
          :extensions="codeMirrorExts"
        />
      </template>

      <template v-else>
        <Codemirror
          v-model="form"
          autofocus
          :extensions="codeMirrorExts"
          @change="onInput"
          @update="onupdate"
        />

        <div class="border-b-2 border-scale-2 pb-2 mb-3 mt-4">
          <h3 class="text-lg font-medium">
            <template v-if="!isValid">
              {{ $t("review.invalid") }}
            </template>
            <template v-else-if="isReallyDirty">
              {{ $t("review.changes") }}
            </template>
            <template v-else>
              {{ $t("review.noChanges") }}
            </template>
          </h3>
        </div>

        <ul>
          <template v-if="invalidVariables.length > 0">
            <li
              v-for="(invalidInfo, index) in invalidVariables"
              :key="`invalid-var-${index}`"
              class="font-mono text-sm variable-value text-error"
            >
              {{
                $t(`invalid.${invalidInfo.error}`, {
                  name: invalidInfo.variable.name,
                })
              }}
            </li>
          </template>

          <template v-if="addedVariables.length > 0">
            <li
              v-for="(variable, index) in addedVariables"
              :key="`added-var-${index}`"
              class="font-mono text-sm variable-value text-success"
            >
              +{{ variable.name }}={{ variable.value }}
            </li>
          </template>

          <template v-if="updatedVariables.length > 0">
            <template
              v-for="(variable, index) in updatedVariables"
              :key="`updated-var-${index}`"
            >
              <li class="font-mono text-sm variable-value text-warning">
                -{{ variable.name }}={{ variable.oldValue }}
              </li>
              <li class="font-mono text-sm variable-value text-success">
                +{{ variable.name }}={{ variable.value }}
              </li>
            </template>
          </template>

          <template v-if="deletedVariables.length > 0">
            <li
              v-for="variable in deletedVariables"
              :key="`deleted-var-${variable.id}`"
              class="font-mono text-sm variable-value text-error"
            >
              -{{ variable.name }}={{ variable.value }}
            </li>
          </template>
        </ul>
      </template>
      <div class="flex mt-4">
        <SCButton
          kind="primary"
          :disabled="!isValid || !isReallyDirty"
          :loading="handler.isSubmitting"
          @click="submit"
        >
          {{ $t("submit") }}
        </SCButton>

        <SCButton
          class="ml-2"
          :disabled="handler.isDirty(form)"
          :loading="handler.isSubmitting"
          @click="$emit('resetBulkEdition')"
        >
          {{ $t("revert") }}
        </SCButton>
      </div>
    </template>
  </section>
</template>

<script>
import { StreamLanguage } from "@codemirror/language";
import { toml } from "@codemirror/legacy-modes/mode/toml";
import { oneDark } from "@codemirror/theme-one-dark";
import { defineComponent } from "vue";
import { Codemirror } from "vue-codemirror";

import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";

export default defineComponent({
  name: "VariablesTextEditor",
  components: {
    Codemirror,
    LoadingCardState,
    SCButton,
    SCAlert,
    WarningSignGlyph,
  },
  props: {
    handler: Object,
    variables: Object,
  },
  emits: ["setBulkDirtiness"],
  data() {
    return {
      form: "",
      addedVariables: [],
      updatedVariables: [],
      deletedVariables: [],
      invalidVariables: [],
    };
  },
  computed: {
    codeMirrorExts() {
      const exts = [StreamLanguage.define(toml)];

      if (this.$root.appTheme === "theme-dark") exts.push(oneDark);

      return exts;
    },
    hasMultilineVars() {
      return this.variables.items.find((v) => v.value.trim().includes("\n"));
    },
    isValid() {
      return this.invalidVariables.length === 0;
    },
    // Adding empty lines or swapping lines should not be considered as dirty
    isReallyDirty() {
      return (
        this.addedVariables.length +
          this.updatedVariables.length +
          this.deletedVariables.length >
        0
      );
    },
  },
  watch: {
    form: {
      immediate: true,
      handler(newVal) {
        if (!this.handler) return;

        const {
          addedVariables,
          updatedVariables,
          deletedVariables,
          invalidVariables,
        } = this.handler.reviewChanges(newVal);

        this.addedVariables = addedVariables;
        this.updatedVariables = updatedVariables;
        this.deletedVariables = deletedVariables;
        this.invalidVariables = invalidVariables;
      },
    },
    handler: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;

        newVal.initComponent(this);
      },
    },
    isReallyDirty(dirty) {
      this.$emit("setBulkDirtiness", { dirty });
    },
  },
  methods: {
    submit() {
      this.$emit("confirmBulkEdition", {
        addedVariables: this.addedVariables,
        updatedVariables: this.updatedVariables,
        deletedVariables: this.deletedVariables,
      });
    },
  },
});
</script>

<style scoped>
.variable-value {
  overflow-wrap: anywhere;
}
</style>

<i18n>
en:
  review:
    invalid: "Invalid content"
    changes: "Review changes"
    noChanges: "No changes"
  readonly:
    title: "Editor is in read-only mode"
    content: "The editor is not available if your environment contains one or mone variables with line breaks, and is therefore in readonly."
  invalid:
    reserved: "{name} is a reserved name"
    nameFormat: "{name}: the name is invalid (must start with _ or a letter and be followed by letters, numbers, or _)"
    nameLength: "{name}: the name of a variable is limited to 64 characters"
    valueLength: "{name}: the value of a variable is limited to 8192 characters"
    blank: "{name}: no value is supplied"
  submit: "Submit"
  revert: "Revert changes"
fr:
  review:
    invalid: "Contenu incorrect"
    changes: "Vérifier les changements"
    noChanges: "Aucun changement"
  readonly:
    title: "L'éditeur est en lecture seule"
    content: "L'éditeur n'est pas disponible si votre environnement contient une ou plusieurs variables avec des sauts de lignes, et est donc présenté en lecture seule."
  invalid:
    reserved: "{name} est un nom réservé"
    nameFormat: "{name}: le nom est invalide (il doit commencer par un _ ou une lettre et être suivi de lettres, chiffres, ou _)"
    nameLength: "{name}: le nom d'une variable ne peut pas dépasser les 64 caractères"
    valueLength: "{name}: la valeur d'une variable ne peut pas dépasser les 8192 caractères"
    blank: "{name}: aucune valeur fournie"
  submit: "Valider"
  revert: "Annuler les modifications"
</i18n>
