import humanizeDurationFn from "humanize-duration";
import { DateTime, Duration, DurationObjectUnits, Settings } from "luxon";

export function formatDateTime(str: string, format = "DDDD, t"): string {
  return DateTime.fromISO(str).toFormat(format);
}

export function formatToHours(datetime: DateTime): string {
  return datetime.toFormat("HH:mm", {
    locale: Settings.defaultLocale,
  });
}

export function customFormatDateTime(
  str: string,
  fromFormat: string,
  toFormat = "ff",
): string {
  return DateTime.fromFormat(str, fromFormat, {
    locale: "en",
  }).toFormat(toFormat, { locale: Settings.defaultLocale });
}

export function humanizedShortDateTime(str: string): string | null {
  return DateTime.fromISO(str).toLocaleString({
    month: "short",
    day: "2-digit",
  });
}

export function humanizedMidDateTime(str: string): string | null {
  return DateTime.fromISO(str).toLocaleString(DateTime.DATE_MED);
}

export function humanizedMonthYearTime(str: string): string | null {
  return DateTime.fromISO(str).toFormat("LLLL yyyy");
}

export function relativeDateTime(str: string): string | null {
  const dateTime = DateTime.fromISO(str);

  if (
    dateTime.diffNow("seconds").seconds > -15 &&
    dateTime.diffNow("seconds").seconds < 15
  ) {
    return Settings.defaultLocale === "fr" ? "maintenant" : "just now";
  } else {
    return dateTime.toRelative();
  }
}

export function todayDate() {
  return DateTime.now()
    .setLocale(Settings.defaultLocale)
    .toLocaleString(DateTime.DATE_FULL);
}

export function currentMonthYear() {
  return DateTime.now().setLocale(Settings.defaultLocale).toFormat("LLL y");
}

export function isInThePast(
  str: string,
  threshold?: DurationObjectUnits,
): boolean {
  let reference = DateTime.now();

  if (threshold) {
    reference = reference.plus(threshold);
  }

  return DateTime.fromISO(str) < reference;
}

export function isBefore(str: string, threshold: DurationObjectUnits): boolean {
  return DateTime.fromISO(str) < DateTime.now().minus(threshold);
}

export function relativeOrAbsolute(
  str: string,
  format = "DDDD, t",
): string | null {
  const threshold = DateTime.local()
    .set({ hour: 0, minute: 0, second: 0 })
    .toISO();

  return str < threshold ? formatDateTime(str, format) : relativeDateTime(str);
}

export function formatDuration(
  input: DurationObjectUnits,
  format = "m'm'ss's'",
): string {
  return Duration.fromObject(input).toFormat(format);
}

export function formatDurationFromMilliseconds(input: number) {
  const duration = Duration.fromMillis(input);
  let formattedDuration;

  if (input < 1000) {
    formattedDuration = `${duration.milliseconds.toFixed(2)} ms`;
  } else if (input < 60000) {
    formattedDuration = `${duration.as("seconds").toFixed(2)} sec`;
  } else {
    formattedDuration = `${duration.as("minutes").toFixed(2)} min`;
  }

  return formattedDuration;
}

export function formatDurationFromNanoseconds(input: number) {
  return formatDurationFromMilliseconds(input / 1000000);
}

export function humanizeDuration(seconds: number): string {
  return humanizeDurationFn(seconds * 1000, {
    language: Settings.defaultLocale,
    units: ["d", "h", "m"],
  });
}

export function addToDateTime(
  str: string,
  time: object,
  format = "DDDD, t",
): string {
  return DateTime.fromISO(str).plus(time).toFormat(format);
}

export function distanceWithUtcNow(hoursToAdd: number): DateTime {
  const now = DateTime.utc();
  const beginOfTheDay = now.startOf("day");
  let time = beginOfTheDay.plus({ hours: hoursToAdd });

  if (time > now) {
    return time;
  }
  time = beginOfTheDay.plus({ days: 1, hours: hoursToAdd });
  return time;
}
