<template>
  <div :class="{ 'border-b-2 border-scale-1': enabled }">
    <div class="px-8">
      <div class="flex">
        <div
          :class="{
            'bg-primary-1': enabled,
            'bg-scale-3': !enabled,
          }"
          class="rounded text-scale-0 px-2 text-xl font-medium"
        >
          {{ step }}
        </div>
        <div
          :class="{
            'text-primary-1': enabled,
            'text-scale-6': !enabled,
          }"
          class="text-xl pl-3 flex-grow"
        >
          {{ title }}
        </div>
      </div>
      <div
        :class="{
          'text-scale-7': enabled,
          'text-scale-4': !enabled,
        }"
        class="py-2"
      >
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StepCardHeader",
  components: {},
  props: {
    step: Number,
    title: String,
    subtitle: String,
    enabled: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
