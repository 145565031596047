export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment method"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a way to deploy your code on Scalingo."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de déploiement"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une manière de déployer votre code sur Scalingo."])}
      }
    }
  })
}
