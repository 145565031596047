<template>
  <Card>
    <template v-slot:header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"></CardHeader>
    </template>
    <template v-slot:body>
      <div class="flex flex-col items-center">
        <div class="text-lg font-bold text-scale-9 mt-4">
          {{ $t("createTitle") }}
        </div>
        <div class="py-2 text-scale-7">{{ $t("createSubtitle") }}</div>
        <div>
          <SCButton class="mt-2" :to="routeArgs">
            {{ $t("createLabel") }}
          </SCButton>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "GettingStartedCard",
  components: { SCButton, Card, CardHeader },
  data() {
    return {
      routeArgs: { name: Routes.Create.App },
    };
  },
});
</script>

<i18n>
en:
  title: "Getting started"
  subtitle: "You have not created any app for the moment, let's get started"
  createTitle: "Create an application"
  createSubtitle: "Click on the button below to start your journey on Scalingo"
  createLabel: "Create an app"
fr:
  title: "Commencer"
  subtitle: "Vous n'avez pas encore créer une app, commencons"
  createTitle: "Créer une application"
  createSubtitle: "Cliquez sur le bouton si dessous pour commencer votre voyage sur Scalingo"
  createLabel: "Créer une app"
</i18n>
