<template>
  <SCBanner :kind="kind">
    <template #icon> <BulbGlyph></BulbGlyph> </template>
    <i18n tag="p" path="title">
      {{ $t("title") }}
      <router-link :to="creditsRoute" class="underline">
        {{ $t("link") }}
      </router-link>
    </i18n>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import BulbGlyph from "@/components/atoms/glyphs/BulbGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "VoucherCreditsBanner",
  components: { SCBanner, BulbGlyph },
  props: { app: Object },
  computed: {
    creditsRoute() {
      return {
        name: Routes.Billing.Credits,
      };
    },
  },
});
</script>

<i18n>
  en:
    title: "Your credits are now visible on a {link}"
    link: "dedicated page."
  fr:
    title: "Vos crédits sont désormais visibles sur une {link}"
    link: "page dédiée."
</i18n>
