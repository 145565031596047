export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Access"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases"])}
      },
      "fr": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à Internet"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bases de données"])}
      }
    }
  })
}
