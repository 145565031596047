import { useI18n } from "vue-i18n";

import { useLocaleStore } from "@/stores/locale-store";

export function i18nT(messages = {}) {
  const { t, locale } = useI18n({ messages: messages });
  const localeStore = useLocaleStore();
  locale.value = localeStore.getLocale();
  return t;
}
