export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add SSH key"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH keys are used to identify you"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Key Name"])},
        "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office computer SSH key"])},
        "contentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Public Key"])},
        "contentPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh-ed25519 .... AAC3Nza"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une clé SSH"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés SSH sont utilisées pour vous identifier"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la clé SSH"])},
        "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé SSH machine du bureau"])},
        "contentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé SSH publique"])},
        "contentPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ssh-ed25519 .... AAC3Nza"])}
      }
    }
  })
}
