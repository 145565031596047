export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "notifiers": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiers"])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a notifier..."])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifier available."])}
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])}
      },
      "fr": {
        "notifiers": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieurs"])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionnez un notifieur"])},
          "noAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun notifieur disponible."])}
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir"])}
      }
    }
  })
}
