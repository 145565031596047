<template>
  <ViewComponent
    v-if="currentUser"
    :hdsContact="contact"
    :upsertHDSContactHandler="upsertHDSContactHandler"
    :hdsContracts="hdsContracts"
    :acceptContractHandler="acceptContractHandler"
    :deleteHDSContactCtx="deleteHDSContactCtx"
    :hdsAppsCount="hdsAppsCount"
    @startContractAcceptance="startContractAcceptance"
    @cancelContractAcceptance="cancelContractAcceptance"
    @submitContractAcceptance="acceptContractHandler.submit()"
    @submitDeleteHDSContact="submitDeleteHDSContact"
    @startHDSContactCreation="startHDSContactCreation"
    @startHDSContactEdition="startHDSContactEdition"
    @startDeleteHDSContact="startDeleteHDSContact"
    @cancelHDSContactEdition="upsertHDSContactHandler = null"
    @cancelDeleteHDSContact="cancelDeleteHDSContact"
    @submitHDSContactEdition="(e) => upsertHDSContactHandler.submit(e)"
    @touchHDSContact="touchHDSContact"
  ></ViewComponent>
</template>

<script>
import { defineComponent, nextTick } from "vue";

import ViewComponent from "@/components/views/account/HDS.vue";
import { AcceptContractHandler } from "@/lib/handlers/accept/contract";
import { CreateHDSContactHandler } from "@/lib/handlers/create/hds-contact";
import { EditHDSContactHandler } from "@/lib/handlers/edit/hds-contact";
import { TouchHdsContactHandler } from "@/lib/handlers/touch/hds-contact";
import { closeCurrentModal } from "@/lib/modals";
import { voidPromiseInfo } from "@/lib/promises/info";
import {
  listHDSContracts,
  listPreviousHDSContractsAccepted,
} from "@/store/contracts";
import { useAppInfosStore } from "@/stores/app-infos";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "HDSController",
  components: { ViewComponent },
  setup() {
    const pocStore = usePointOfContactStore();
    const appInfosStore = useAppInfosStore();
    const toastsStore = useToastsStore();

    return { pocStore, appInfosStore, toastsStore };
  },
  data() {
    return {
      accountFormHandler: null,
      acceptContractHandler: null,
      preferencesFormHandler: null,
      upsertHDSContactHandler: null,
      deleteHDSContactCtx: null,
    };
  },
  computed: {
    hdsContracts() {
      return listHDSContracts(this.$store);
    },
    contact() {
      return this.pocStore.item;
    },
    hdsAppsCount() {
      return (
        this.appInfosStore.items?.filter(
          (app) =>
            app.hds_resource === true && app.owner.id === this.currentUser.id,
        ).length || 0
      );
    },
    previousHDSContractsAcceptedPresent() {
      return listPreviousHDSContractsAccepted(this.$store).items.length > 0;
    },
  },
  created() {
    this.pocStore.ensure();
    this.appInfosStore.ensure();
  },
  methods: {
    startContractAcceptance({ contract }) {
      this.acceptContractHandler = new AcceptContractHandler(
        this,
        contract,
        this.previousHDSContractsAcceptedPresent,
      );
    },
    cancelContractAcceptance() {
      this.acceptContractHandler = null;
    },
    touchHDSContact() {
      new TouchHdsContactHandler(this, this.contact).submit();
    },
    startHDSContactEdition({ contact }) {
      this.upsertHDSContactHandler = new EditHDSContactHandler(this, contact);
    },
    startHDSContactCreation() {
      this.upsertHDSContactHandler = new CreateHDSContactHandler(this);
    },
    startDeleteHDSContact() {
      this.deleteHDSContactCtx = { info: voidPromiseInfo(), errors: null };
    },
    async submitDeleteHDSContact() {
      const ctx = this.deleteHDSContactCtx;

      ctx.info = this.pocStore.destroy();

      await ctx.info.promise;
      await nextTick();

      this.toastsStore.addOne({
        type: "success",
        title: this.$t(`hdsContact.delete.title`),
        message: this.$t(`hdsContact.delete.message`),
      });

      closeCurrentModal();
    },
    cancelDeleteHDSContact() {
      this.deleteHDSContactCtx = null;
    },
  },
});
</script>

<i18n>
en:
  hdsContact:
    delete:
      title: "All good!"
      message: "The point of contact health professional has been deleted."
fr:
  hdsContact:
    delete:
      title: "Succès !"
      message: "Votre contact a été supprimé."
</i18n>
