export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scalingo does not recommend enabling direct internet access to your database. Our recommendation is to use secure connection methods. ", _interpolate(_named("link"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
      },
      "fr": {
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scalingo déconseille l'activation de l'accès à votre base de données par internet. Notre recommandation est d'utiliser des méthodes de connexion sécurisées. ", _interpolate(_named("link"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
      }
    }
  })
}
