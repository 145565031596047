export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "creation": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database created"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been successfully created."])}
          }
        },
        "deletion": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database deleted"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been successfully deleted."])}
          }
        },
        "emptying": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database emptied"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been successfully emptied."])}
          }
        }
      },
      "fr": {
        "creation": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de données créée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été créée avec succès."])}
          }
        },
        "deletion": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de données supprimée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été supprimée avec succès."])}
          }
        },
        "emptying": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de données vidée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été vidée avec succès."])}
          }
        }
      }
    }
  })
}
