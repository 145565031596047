<template>
  <MysqlConfiguration v-if="db.type_name === 'mysql'" class="mb-6" />
  <PostgresExtensions v-if="db.type_name === 'postgresql'" class="mb-6" />
  <RedisPersistence v-if="db.type_name === 'redis'" class="mb-6" />

  <ViewComponent :user="currentUser" :db="db" :app="app" />
</template>

<script>
import { computed, defineComponent } from "vue";

import ViewComponent from "@/components/views/db/settings/Configuration.vue";
import MysqlConfiguration from "@/controllers/db/settings/configuration/MysqlConfiguration.vue";
import PostgresExtensions from "@/controllers/db/settings/configuration/PostgresExtension.vue";
import RedisPersistence from "@/controllers/db/settings/configuration/RedisPersistence.vue";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";

export default defineComponent({
  name: "Configuration",
  components: {
    ViewComponent,
    MysqlConfiguration,
    PostgresExtensions,
    RedisPersistence,
  },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
    };
  },
});
</script>
