export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])},
        "linker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker"])},
        "auto_deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deployments"])},
        "auto_deployment_review_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deployment of review apps"])},
        "allow_creation_from_forks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow creation from forks"])},
        "auto_destroy_on_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto destroy on close"])},
        "auto_destroy_on_stale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto destroy on stale"])},
        "instantly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instantly"])}
      },
      "fr": {
        "repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
        "linker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linker"])},
        "auto_deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements automatiques"])},
        "auto_deployment_review_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement automatique des applications des review apps"])},
        "allow_creation_from_forks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser la création à partir de forks"])},
        "auto_destroy_on_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destruction automatique à la fermeture"])},
        "auto_destroy_on_stale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destruction automatique sur inactivité"])},
        "instantly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["immédiatement"])}
      }
    }
  })
}
