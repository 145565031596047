<template>
  <SCBanner kind="warning" align="center">
    <template #icon> <DangerSignGlyph class="place-self-center" /> </template>

    <i18n tag="p" path="warning">
      {{ $t("warning") }}

      <DocLink href="https://scalingo.com/gtc-appendix-health-data-hosting">
        {{ $t("doclink") }}
      </DocLink>
    </i18n>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";

export default defineComponent({
  name: "NoHDSContactBanner",
  components: { SCBanner, DocLink, DangerSignGlyph },
});
</script>

<i18n>
  en:
    warning: "In order to create HDH apps, you need to define a point of contact who is able to designate a health professional if necessary: {link}"
    doclink: "Article 10. CLIENT'S commitments"

  fr:
    warning: "Afin de pouvoir créer des applications HDS, vous devez définir un point de contact étant en mesure de désigner un professionnel de santé lorsque cela est nécessaire : {link}"
    doclink: "Article 10. Obligations du Client"
</i18n>
