import { App, Container, Alert } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { createAlert } from "@/store/alerts";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  app: App;
  metric: string;
  duration: number;
  containerType: string;
  is: "greater" | "lower";
  limit: number;
}

export class CreateAlertHandler extends FormHandler<Alert> {
  errorFieldsMapping = { duration_before_trigger: "duration" };
  keyPath = "alerts.create";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly containers: Container[],
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      app: null,
      metric: "cpu",
      limit: 0,
      duration: 3,
      is: "greater",
      containerType: this.defaultContainer(),
    };
  }

  private defaultContainer() {
    if (!this.containers || this.containers.length < 1) {
      return "";
    }
    for (const container of this.containers) {
      if (container.name === "web") {
        return "web";
      }
    }
    return this.containers[0].name;
  }

  static isResourceConsumption(metric: string): boolean {
    const resourceConsumptions = ["cpu", "memory", "swap"];

    return resourceConsumptions.includes(metric);
  }

  static transformLimit(limit: number, metric: string): number {
    if (CreateAlertHandler.isResourceConsumption(metric)) {
      return parseFloat(String(limit)) / 100;
    }
    return parseFloat(String(limit));
  }

  dispatchEvents(): void {
    this.on("success", (alert) => {
      this.notify("success");

      this.$router.push({
        name: Routes.App.Alerts.Edit.Notifiers,
        params: {
          id: this.app.name,
          region: this.app.region,
          alertId: alert.id,
        },
      });
    });
  }

  async submit(form: FormData): Promise<void> {
    const payload = {
      container_type: form.containerType,
      limit: CreateAlertHandler.transformLimit(form.limit, form.metric),
      metric: form.metric,
      send_when_below: form.is != "greater",
      duration_before_trigger:
        parseFloat(String(form.duration)) * 60 * 1000000000, // minutes to nanoseconds
      disabled: false,
    };

    this.follow(await createAlert(this.$store, payload));
  }
}
