<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 10.0082C20.9967 14.3106 18.2419 18.1287 14.16 19.4882C14.0072 19.5353 13.8413 19.5095 13.71 19.4182C13.5788 19.3227 13.5008 19.1705 13.5 19.0082V16.3382C13.5216 15.6191 13.3489 14.9074 13 14.2782C12.9472 14.1959 12.9472 14.0905 13 14.0082C13.0406 13.9323 13.1148 13.8804 13.2 13.8682C15.64 13.6182 17 12.6482 17 10.0082C17.0588 8.77557 16.6315 7.56907 15.81 6.64821C15.9298 6.25922 15.9938 5.85519 16 5.44821C15.9957 5.08333 15.9453 4.72045 15.85 4.36821C15.7871 4.13333 15.5615 3.98009 15.32 4.00821C14.4038 4.13826 13.5509 4.55073 12.88 5.18821C11.6382 4.94827 10.3619 4.94827 9.12001 5.18821C8.44916 4.55073 7.59625 4.13826 6.68001 4.00821C6.43849 3.98009 6.21288 4.13333 6.15001 4.36821C6.0547 4.72045 6.0043 5.08333 6.00001 5.44821C6.00623 5.85519 6.0702 6.25922 6.19001 6.64821C5.36849 7.56907 4.94119 8.77557 5.00001 10.0082C5.00001 12.7882 6.51001 13.7182 9.28001 13.9482C8.93615 14.3988 8.70011 14.9222 8.59001 15.4782C8.59001 15.4782 8.59001 15.5482 8.59001 15.5982C8.58519 15.6448 8.58519 15.6917 8.59001 15.7382C8.5484 16.0526 8.26573 16.2778 7.95001 16.2482C7.79946 16.2411 7.65247 16.2001 7.52001 16.1282C6.97522 15.7964 6.49429 15.3697 6.10001 14.8682C5.8488 14.5834 5.58171 14.3129 5.30001 14.0582C5.12101 13.9033 4.9227 13.7722 4.71001 13.6682C4.56096 13.5845 4.37905 13.5845 4.23001 13.6682C4.08693 13.76 4.00029 13.9182 4.00001 14.0882V14.1482C4.00029 14.3182 4.08693 14.4764 4.23001 14.5682C4.58897 14.8687 4.89931 15.2229 5.15001 15.6182C5.57339 16.2816 6.11184 16.8641 6.74001 17.3382C7.15388 17.6157 7.64174 17.762 8.14001 17.7582H8.50001V19.0082C8.49923 19.1705 8.42126 19.3227 8.29001 19.4182C8.15874 19.5095 7.99279 19.5353 7.84001 19.4882C2.99205 17.8735 0.14125 12.8637 1.22938 7.87112C2.31751 2.87854 6.9945 -0.490604 12.0747 0.0585358C17.1549 0.607676 21.0039 4.89843 21 10.0082Z"
      fill="currentColor"
    />
    <circle cx="18" cy="18" r="5.5" fill="white" stroke="white" />
    <path
      d="M18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13ZM15.8571 17.2857H20.1429C20.5371 17.2857 20.8571 17.605 20.8571 18C20.8571 18.395 20.5371 18.7143 20.1429 18.7143H15.8571C15.4629 18.7143 15.1429 18.395 15.1429 18C15.1429 17.605 15.4629 17.2857 15.8571 17.2857Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GithubDelGlyph",
});
</script>
