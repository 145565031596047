/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartDataset } from "chart.js";
import { DateTime } from "luxon";
import { Point, Event } from "scalingo/lib/models/regional";
import { STATUS_SUCCESS } from "scalingo/lib/models/regional/deployments";

import type { VueI18n } from "vue-i18n";

import "chart.js/auto";
import "chartjs-adapter-luxon";
import "chartjs-plugin-crosshair";

type PartialDatasetConfig = Partial<ChartDataset<"line">>;

// Config is the same as chart.js dataset config, except for i18n support
interface DatasetConfiguration extends PartialDatasetConfig {
  labelKey?: string;
}

// A chart point for the luxon adapter
type ChartPoint = {
  x: DateTime;
  y: number;
};

interface EventChartPoint extends ChartPoint {
  event: Event;
}

// Base options for all graphs
const datasetCommonOptions: PartialDatasetConfig = {
  type: "line",
  pointRadius: 0,
  pointHoverRadius: 0,
  fill: false,
  tension: 0,
  borderWidth: 1,
  spanGaps: false,
  stepped: "before",
};

// Base options for events graphs
const eventCommonOptions: PartialDatasetConfig = {
  ...datasetCommonOptions,
  pointRadius: 6,
  pointHitRadius: 8,
  pointHoverRadius: 8,
  showLine: false,
  spanGaps: false,
};

// Per-graph overrides
const config: Record<string, Record<string, DatasetConfiguration>> = {
  events: {
    restart: {
      labelKey: "events.restart",
      borderColor: "#FFB800",
      pointBackgroundColor: "#FFB800",
      backgroundColor: "#FFB800",
    },
    scale: {
      labelKey: "events.scale",
      borderColor: "#3EAFE7",
      pointBackgroundColor: "#3EAFE7",
      backgroundColor: "#3EAFE7",
    },
    deploy: {
      labelKey: "events.deploy",
      borderColor: "#00C5A4",
      pointBackgroundColor: "#00C5A4",
      backgroundColor: "#00C5A4",
    },
  },
  rpm: {
    all: {
      labelKey: "rpm.all",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    "5xx": {
      labelKey: "rpm.5xx",
      borderColor: "rgba(220,97,80,1)",
      backgroundColor: "rgba(220,97,80,1)",
      //@ts-expect-error bad typings
      type: "bar",
    },
  },
  responseTime: {
    median: {
      labelKey: "responseTime.median",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    p95: {
      labelKey: "responseTime.p95",
      borderColor: "rgba(3, 169, 244)",
      backgroundColor: "rgba(3, 169, 244, 0.2)",
      fill: "-1",
    },
    p99: {
      labelKey: "responseTime.p99",
      borderColor: "rgba(126, 206, 244,1)",
      backgroundColor: "rgba(126, 206, 244, 0.2)",
      fill: "-1",
    },
  },
  containers: {
    cpu: {
      labelKey: "containers.cpu",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    diskUsed: {
      labelKey: "containers.diskUsed",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    diskWrites: {
      labelKey: "containers.diskWrites",
      borderColor: "rgba(62, 175, 231, 1)",
      backgroundColor: "rgba(62, 175, 231, 0.2)",
      fill: "origin",
    },
    diskReads: {
      labelKey: "containers.diskReads",
      borderColor: "rgba(134, 94, 255, 1)",
      backgroundColor: "rgba(134, 94, 255, 0.1)",
      fill: "origin",
    },
    memory: {
      labelKey: "containers.memory",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    swap: {
      labelKey: "containers.swap",
      borderColor: "rgba(220,97,80,1)",
      backgroundColor: "rgba(220,97,80, 0.2)",
      fill: "-1",
    },
  },
};

// Crosshair configuration
export const crosshairConfig = {
  line: {
    color: "#F66", // crosshair line color
    width: 1, // crosshair line width
  },
  sync: {
    enabled: true, // enable trace line syncing with other charts
    group: 1, // chart group
    suppressTooltips: false,
  },
  zoom: {
    enabled: true, // enable zooming
    zoomboxBackgroundColor: "rgba(66,133,244,0.2)", // background color of zoom box
    zoomboxBorderColor: "#48F", // border color of zoom box
  },
};

function formatDataPoint(point: Point): ChartPoint {
  const x = DateTime.fromISO(point.time);
  const y = point.value;

  return { x, y };
}

function smoothDatapoints(points: Point[]): Point[] {
  // - A `zero` point at beginning/end of set may be an issue
  // - A `null` point is an issue and will usually be filled later
  // - A negative value can happen for some dataset (swap, for example)
  // Those either temporary or erroneous values, so we need to smooth them out.
  return points.map((point, i) => {
    // First and last points : continue with next/previous data point if possible
    if (i === 0) {
      if (!point.value || point.value < 0) {
        return {
          ...point,
          value: points[i + 1].value || 0,
        };
      }
    }

    // Finish
    if (i === points.length - 1) {
      if (!point.value || point.value < 0) {
        return {
          ...point,
          value: points[points.length - 2].value || 0,
        };
      }
    }

    // In between : take mean of previous + next point if possible, 0 otherwise
    if (point.value === null || point.value < 0) {
      const previous = points[i - 1].value;
      const next = points[i + 1].value;
      const value = previous && next ? (previous + next) / 2.0 : 0;

      return {
        ...point,
        value,
      };
    }

    return point;
  });
}

function formatDataPoints(points: Point[]): ChartPoint[] {
  return smoothDatapoints(points).map(formatDataPoint);
}

function translateLabelKey(
  i18n: VueI18n,
  config: DatasetConfiguration,
  variant: "short" | "full" = "short",
): void {
  if (config.labelKey) {
    config.label = i18n.t(`metrics.${config.labelKey}.${variant}`).toString();
    delete config.labelKey;
  }
}

export function makeDataSet(
  i18n: VueI18n,
  metric: string,
  subset: string,
  points: Point[],
): ChartDataset {
  const specificConfig = config[metric][subset];
  const opts = {
    ...datasetCommonOptions,
    ...specificConfig,
  };

  translateLabelKey(i18n, opts);

  // @ts-expect-error wrong typings
  opts.data = formatDataPoints(points);

  return opts as ChartDataset;
}

export function formatEvent(
  event: Event,
  designatedValue: number,
): EventChartPoint {
  const x = DateTime.fromISO(event.created_at).set({
    second: 0,
    millisecond: 0,
  });
  const y = designatedValue;

  return { x, y, event: event };
}

export function formatEvents(
  events: Event[],
  designatedValue: number,
): ChartPoint[] {
  return events.map((p) => formatEvent(p, designatedValue)).reverse();
}

function datasetMaxValue(datasetRef: ChartDataset): number {
  let max = 0;

  datasetRef.data?.forEach((item: any) => {
    if (item.y) {
      max = item.y > max ? item.y : max;
    }
  });

  return max;
}

export function makeEventsDataSet(
  i18n: VueI18n,
  metric: string,
  subset: string,
  events: Event[],
  datasetRef: ChartDataset,
): ChartDataset {
  const designatedValue = datasetMaxValue(datasetRef) * 1.2;
  const specificConfig = config[metric][subset];
  const opts = {
    ...eventCommonOptions,
    ...specificConfig,
  };

  translateLabelKey(i18n, opts);

  // @ts-expect-error wrong typings
  opts.data = formatEvents(events, designatedValue);

  return opts as ChartDataset;
}

export function onlyEventsOfType(
  events: Event[],
  type: "scale" | "restart" | "deploy",
): Event[] {
  if (type === "scale" || type === "restart") {
    return events.filter((event) => {
      return event.type === type;
    });
  }

  if (type === "deploy") {
    return events.filter((event) => {
      return (
        event.type === "deployment" &&
        event.type_data?.status === STATUS_SUCCESS
      );
    });
  }

  return [];
}

export function upsertDataPoint(rawData: Point[], point: Point): void {
  const match = rawData.find((item) => item.time === point.time);

  if (match) {
    match.value = point.value;
  } else {
    rawData.push(point);
  }
}

export function upsertDataPoints(rawData: Point[], points: Point[]): void {
  points.forEach((point) => upsertDataPoint(rawData, point));
}
