<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.09091 2C6.3375 2 5.72727 2.63929 5.72727 3.42857V7H7.09091V3.42857H9.13636L9.41868 3.72433C9.67436 3.99219 10.0214 4.14286 10.3828 4.14286H13.3445C13.7058 4.14286 14.0529 3.99219 14.3086 3.72433L14.5909 3.42857H16.6364V20.5714H7.09091V17.7143H5.72727V20.5714C5.72727 21.3607 6.3375 22 7.09091 22H16.6364C17.3898 22 18 21.3607 18 20.5714V3.42857C18 2.63929 17.3898 2 16.6364 2H7.09091ZM10.8409 2.71429H12.2045C12.3927 2.71429 12.5455 2.87429 12.5455 3.07143C12.5455 3.26857 12.3927 3.42857 12.2045 3.42857H10.8409C10.6527 3.42857 10.5 3.26857 10.5 3.07143C10.5 2.87429 10.6527 2.71429 10.8409 2.71429ZM13.5682 2.71429C13.7564 2.71429 13.9091 2.87429 13.9091 3.07143C13.9091 3.26857 13.7564 3.42857 13.5682 3.42857C13.38 3.42857 13.2273 3.26857 13.2273 3.07143C13.2273 2.87429 13.38 2.71429 13.5682 2.71429ZM6.40909 8.42857C4.905 8.42857 3.68182 9.71 3.68182 11.2857V12C3.30545 12 3 12.32 3 12.7143V15.5714C3 15.9657 3.30545 16.2857 3.68182 16.2857H9.13636C9.51273 16.2857 9.81818 15.9657 9.81818 15.5714V12.7143C9.81818 12.32 9.51273 12 9.13636 12V11.2857C9.13636 9.71 7.91318 8.42857 6.40909 8.42857ZM6.40909 9.85714C7.16114 9.85714 7.77273 10.4979 7.77273 11.2857V12H5.04545V11.2857C5.04545 10.4979 5.65705 9.85714 6.40909 9.85714Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TwoFaEnabledGlyph",
});
</script>
