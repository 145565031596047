import { Addon as OriginalAddon } from "scalingo/lib/models/regional";

import { App } from "./apps";

type AdditionnalInfo = { app: App; next_version_id: string | null };

export type Addon = OriginalAddon & AdditionnalInfo;

export function modifyItem(
  item: OriginalAddon,
  app: App,
  next_version_id: string | null = null,
): Addon {
  return { ...item, app, next_version_id };
}

export function setVersionId(
  item: Addon,
  next_version_id: string | null,
): Addon {
  return { ...item, next_version_id };
}

export function modifyCollection(
  items: OriginalAddon[],
  app: App,
  next_version_id: string | null = null,
): Addon[] {
  return items.map((addon) => modifyItem(addon, app, next_version_id));
}
