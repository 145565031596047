export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions linked to this app."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to plan selection"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensions liées à cette app."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la sélection des plans"])}
      }
    }
  })
}
