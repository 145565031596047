import { App, Container } from "scalingo/lib/models/regional";
import Operation from "scalingo/lib/Operations/utils";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  HANDLE_FETCH,
  RESTART,
  HANDLE_OPERATION,
  SCALE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, SET_ONE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { Operations } from "@/store/operations";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

// Note: for this store we cheat a little by "enhancing" the models with an id key.
export class ContainersStore extends CollectionStore<Container> {
  actions = CollectionStore.buildActions<Container>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Containers.for(
          currentApp.id,
        ),
        // This adds an 'id' key to containers, equal to their names. The store behaves better with an id key.
        resolveAction(response: Container[]) {
          context.commit(
            SET_ALL,
            response.map((c) => {
              return { id: c.name, ...c };
            }),
          );
        },
      });
    },
    [RESTART](context, scope = null) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Containers.restart(
          currentApp.id,
          scope,
        ),
        resolveAction(operation: Operation) {
          context.dispatch(Operations.actions.PUSH, operation, { root: true });
          context.dispatch(Operations.actions.WATCH, operation.id, {
            root: true,
          });
        },
      });
    },
    [SCALE](context, formation) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Containers.scale(
          currentApp.id,
          formation,
        ),
        resolveAction({
          formation,
          operation,
        }: {
          formation: Container[];
          operation: Operation;
        }) {
          // No operation? This was a no-op, nothing to do.
          // Note: checks should be done client-side to prevent these calls
          if (!operation) return;

          formation.forEach((updatedContainer: Container) => {
            context.commit(SET_ONE, {
              id: updatedContainer.name,
              ...updatedContainer,
            });
          });

          context.dispatch(Operations.actions.PUSH, operation, { root: true });
          context.dispatch(Operations.actions.WATCH, operation.id, {
            root: true,
          });
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<Container>();
  getters = CollectionStore.buildGetters<Container>();
}

export const Containers = buildMapping(new ContainersStore(), "containers");

export function listContainers(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Container>>,
): CollectionWithFetch<Container> {
  return {
    items: listItems(store.getters[Containers.getters.ALL], opts),
    latestFetch: store.getters[Containers.getters.LATEST_FETCH],
  };
}

export function ensureContainers(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(Containers.actions.ENSURE, opts);
}

export function findContainer(store: ApplicationStore, id: string): Container {
  return store.getters[Containers.getters.FIND](id);
}

export function restartAllContainers(
  store: ApplicationStore,
): Promise<RemoteOperation<Container>> {
  return store.dispatch(Containers.actions.RESTART);
}

export function restartContainersOfType(
  store: ApplicationStore,
  type: string,
): Promise<RemoteOperation<Container>> {
  return store.dispatch(Containers.actions.RESTART, [type]);
}

export function scaleContainers(
  store: ApplicationStore,
  formation: Container[],
): Promise<RemoteOperation<Container>> {
  return store.dispatch(Containers.actions.SCALE, formation);
}
