<template>
  <ViewComponent
    :app="app"
    :collaborators="collaborators"
    :selectedPlatform="selectedPlatform"
    :notificationPlatforms="notificationPlatforms"
    :formHandler="formHandler"
    @platformSelected="platformSelected"
    @submit="submit"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/notifiers/Creation.vue";
import { CreateNotifierHandler } from "@/lib/handlers";
import { Routes } from "@/router/names";
import { ensureCollaborators, listCollaborators } from "@/store/collaborators";
import { ensureEventTypes, listEventTypes } from "@/store/event-types";
import {
  ensureNotificationPlatforms,
  listNotificationPlatforms,
} from "@/store/notification-platforms";

export default defineComponent({
  name: "NotifierCreationContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formHandler: null,
    };
  },
  computed: {
    collaborators() {
      return listCollaborators(this.$store);
    },
    notificationPlatforms() {
      return listNotificationPlatforms(this.$store, this.app.region, {
        sortBy: "name",
      });
    },
    eventTypes() {
      return listEventTypes(this.$store, this.app.region, { sortBy: "name" });
    },
    selectedPlatform() {
      return this.notificationPlatforms.items.find((platform) => {
        return platform.name === this.$route.params.platformName;
      });
    },
  },
  watch: {
    selectedPlatform: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (newVal.name === "email") {
            ensureCollaborators(this.$store, { staleAfter: "always" });
          }

          this.formHandler = new CreateNotifierHandler(
            this,
            this.app,
            newVal,
            this.eventTypes.items,
          );
        } else {
          this.formHandler = null;

          if (this.$route.params.platformName) {
            this.$router.push({
              name: Routes.App.Notifiers.Creation,
              params: {
                id: this.app.name,
                region: this.app.region,
              },
            });
          }
        }
      },
    },
  },
  beforeMount() {
    ensureNotificationPlatforms(this.$store, this.app.region);
    ensureEventTypes(this.$store, this.app.region);
  },
  methods: {
    platformSelected({ platform }) {
      this.$router.push({
        name: Routes.App.Notifiers.Creation,
        params: {
          id: this.app.name,
          region: this.app.region,
          platformName: platform.name,
        },
      });
    },
    submit(e) {
      this.formHandler.submit(e);
    },
  },
});
</script>
