import { DateTime, Duration } from "luxon";
import DeploymentsListener from "scalingo/lib/Deployments/listener";
import { App, Deployment } from "scalingo/lib/models/regional";

import { dashboard } from "@/lib/utils/log";
import { ApplicationStore } from "@/store";
import { Containers } from "@/store/containers";
import { CronTasks } from "@/store/cron-tasks";
import { DeploymentLogs } from "@/store/deployment-logs";
import { Deployments } from "@/store/deployments";
import { Events } from "@/store/events";
import { Session } from "@/store/session";

import { scalingoClient } from "../client";

interface ListenerInfo {
  listener: DeploymentsListener;
  lastTouch: Record<string, DateTime>;
  intervalId: NodeJS.Timeout | null;
  connRetryCount: number;
  bypassCallbacks: boolean;
}

const staleInterval = Duration.fromObject({ seconds: 10 });
const streamers: Record<string, ListenerInfo> = {};

/**
 * This opens a websocket listening for new deployments and status changes/log lines of ongoing deployments.
 * As a fallback, it also polls every few seconds to check if the deployment object might be stale.
 * If so, it refreshes it from the API.
 */
export function initDeploymentsStreamer(
  store: ApplicationStore,
  app: App,
  connRetryCount = 0,
): DeploymentsListener | void {
  const url = app.links.deployments_stream;
  const client = scalingoClient(store, app.region);
  let listener;

  try {
    listener = new DeploymentsListener(client, url);
  } catch (e) {
    dashboard.log(`Deployments streamer (${url}) not opened:`, e);
    return;
  }

  const info: ListenerInfo = {
    listener,
    lastTouch: {},
    intervalId: null,
    connRetryCount: connRetryCount,
    bypassCallbacks: false,
  };

  streamers[app.uuid] = info;

  info.intervalId = setInterval(() => {
    const ongoingDeployments: Deployment[] =
      store.getters[Deployments.getters.ONGOING];

    ongoingDeployments.forEach((dep) => {
      // The deployment is fresh. Do nothing. Should not happen, or in very specific race conditions.
      if (!info.lastTouch[dep.id]) return;

      // The deployment has been updated recently, let's not recheck it yet
      if (info.lastTouch[dep.id].plus(staleInterval) > DateTime.local()) {
        return;
      }

      // The deployment is stale: refresh it
      info.lastTouch[dep.id] = DateTime.local();
      store.dispatch(Deployments.actions.FETCH, dep.id);
    });
  }, 5000);

  listener.onOpen(() => {
    dashboard.log("open deployments streamer: ", app.name);
    info.connRetryCount = 0;
    info.bypassCallbacks = false;
  });

  listener.onNew((e) => {
    dashboard.log("new deployments streamer: ", app.name);
    // TODO: wrong typing in scalingo.js
    const deployment = e.deployment as unknown as Deployment;
    info.lastTouch[deployment.id] = DateTime.local();

    store.dispatch(Session.actions.REFRESH);
    store.dispatch(Events.actions.REFRESH);
    store.dispatch(Deployments.actions.PUSH, deployment);
  });

  listener.onStatus((e) => {
    info.lastTouch[e.id] = DateTime.local();

    store.dispatch(Session.actions.REFRESH);
    store.dispatch(Events.actions.REFRESH);
    store.dispatch(Containers.actions.REFRESH);
    store.dispatch(Deployments.actions.FETCH, e.id);

    // Succesful deployment? Clear the previously registered cron tasks.
    if (e.status === "success") {
      store.dispatch(CronTasks.actions.CLEAR);
    }
  });

  listener.onLog((e) => {
    info.lastTouch[e.id] = DateTime.local();

    const model = store.getters[Deployments.getters.FIND](e.id);

    if (!model) {
      store.dispatch(Deployments.actions.FETCH, e.id);
    }

    store.dispatch(DeploymentLogs.actions.UPDATE, e);
  });

  listener.onClose((e) => {
    // We close the streamer when changing apps, but it can get closed due to connection issues.
    // In the first case, there's nothing to do, but otherwise we want to try and reconnect.
    // As callbacks are not optionnal on scalingo.js, we do the condition at this level.
    if (!info.bypassCallbacks) {
      dashboard.log("close deployments streamer with callbacks ", app.name);
      const delay = Math.pow(2, info.connRetryCount) * 1000;

      setTimeout(() => {
        dashboard.log("Trying to reconnect to:", e?.target.url);

        initDeploymentsStreamer(store, app, info.connRetryCount + 1);
      }, delay);
    } else {
      dashboard.log("close deployments streamer without callbacks", app.name);
    }
  });

  return info.listener;
}

export function teardownDeploymentsStreamer(app: App): void {
  const info = streamers[app.uuid];

  if (info.intervalId) {
    clearInterval(info.intervalId);
  }

  const teardown = () => {
    dashboard.log("teardown deployments streamer: ", app.name);
    info.bypassCallbacks = true;
    delete streamers[app.uuid];
  };

  if (info) {
    if (info.listener) {
      info.listener.beforeClose(teardown);
      info.listener.close();
    } else {
      teardown();
    }
  } else {
    dashboard.log("teardown deployments streamer (not present): ", app.name);
  }
}
