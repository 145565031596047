<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <PeriodHeader
      :accountCreationDate="accountCreationDate"
      :usage="usage.item"
      :fromDate="fromDate"
      :toDate="toDate"
      @filterSelected="(e) => $emit('periodSelected', e)"
      @resetToday="(e) => $emit('resetToday', e)"
    ></PeriodHeader>
    <Toolbar
      :usage="usage"
      :fromDate="fromDate"
      :toDate="toDate"
      :groupBy="groupBy"
      :accountName="accountName"
      @groupBySelected="(e) => $emit('groupBySelected', e)"
    ></Toolbar>
    <EmptyCardState
      v-if="
        appUsages.length < 1 || (groupBy === 'resource' && isEmpty(sortedSku))
      "
    >
      <template #icon>
        <BillGlyph class="h-8 text-scale-6"></BillGlyph>
      </template>
      <span class="text-scale-6 text-lg font-medium">{{
        $t("emptyText")
      }}</span>
    </EmptyCardState>
    <div v-else>
      <div v-if="groupBy === 'app'" class="flex flex-col space-y-4">
        <div class="flex flex-row justify-between">
          <div class="text-sm text-scale-7">{{ $t("label.name") }} ↓</div>
          <div class="flex justify-end items-baseline">
            <div class="text-sm text-scale-7">{{ $t("label.price") }}</div>
            <div class="text-scale-6 text-xs ml-1">{{ $t("exclVat") }}</div>
          </div>
        </div>
        <div v-for="(usage, key) in sortedApps" :key="key">
          <AppPeriod
            :usage="usage"
            :multipleRegions="multipleRegions"
          ></AppPeriod>
        </div>
      </div>
      <div v-if="groupBy === 'resource'" class="flex flex-col space-y-4">
        <div class="flex flex-row justify-between">
          <div class="text-sm text-scale-7">{{ $t("label.resource") }} ↓</div>
          <div class="flex justify-end items-baseline">
            <div class="text-sm text-scale-7">{{ $t("label.price") }}</div>
            <div class="text-scale-6 text-xs ml-1">{{ $t("exclVat") }}</div>
          </div>
        </div>
        <div v-for="(sku, key) in sortedSku" :key="key">
          <ResourcePeriod
            :sku="sku"
            :multipleRegions="multipleRegions"
          ></ResourcePeriod>
        </div>
      </div>
    </div>
    <div class="text-scale-6 mt-6 text-sm">
      {{ $t("notInvoice") }}
    </div>
    <ProTipAlert class="mt-2"> {{ $t("proTip") }} </ProTipAlert>
  </div>
</template>

<script>
import { isEmpty, uniqBy } from "lodash";
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import AppPeriod from "@/components/parts/consumption/AppPeriod.vue";
import PeriodHeader from "@/components/parts/consumption/PeriodHeader.vue";
import ResourcePeriod from "@/components/parts/consumption/ResourcePeriod.vue";
import Toolbar from "@/components/parts/consumption/Toolbar.vue";

export default defineComponent({
  name: "Period",
  components: {
    ResourcePeriod,
    PeriodHeader,
    Toolbar,
    AppPeriod,
    BillGlyph,
    EmptyCardState,
    ProTipAlert,
  },
  props: {
    usage: Object,
    accountCreationDate: String,
    accountName: String,
    fromDate: Object,
    toDate: Object,
    groupBy: String,
  },
  computed: {
    multipleRegions() {
      return uniqBy(this.appUsages, "region").length > 1;
    },
    appUsages() {
      return this.usage.item.app_usages;
    },
    sortedApps() {
      return this.appUsages.slice().sort((a, b) => a.app.localeCompare(b.app));
    },
    sortedSku() {
      let skus = {};
      for (const k in this.appUsages) {
        const app = this.appUsages[k];
        for (const k1 in app.runtimes) {
          const runtime = app.runtimes[k1];
          const sku = runtime.sku;
          if (!(sku in skus)) {
            skus[sku] = {
              sku: sku,
              apps: [],
              label: this.runtimeLabel(runtime),
              service_position: runtime.service_position,
              product_position: runtime.product_position,
            };
          }
          skus[sku].apps.push({
            app: app.app,
            price: runtime.price,
            duration: runtime.duration,
            region: app.region,
          });
        }
        for (const k2 in app.addon_usages) {
          const addon = app.addon_usages[k2];
          const sku = addon.sku;
          if (!(sku in skus)) {
            skus[sku] = {
              sku: sku,
              apps: [],
              label: this.addonLabel(addon),
              service_position: addon.service_position,
              product_position: addon.product_position,
            };
          }
          skus[sku].apps.push({
            app: app.app,
            price: addon.price,
            duration: addon.duration,
            region: app.region,
          });
        }
        // Second loop because addon usage contains two products: addon itself and overusage
        for (const k3 in app.addon_usages) {
          const addon = app.addon_usages[k3];
          const overusage = addon.disk_overusage && addon.disk_overusage !== 0;
          if (!overusage) {
            continue;
          }
          const sku = addon.disk_overusage_sku;

          // sandbox plans don't return a sku for overusage.
          // In this case, we'll just not show then, they're free anyway.
          // If this changes, this should be dealt with at the cashmachine level
          if (!sku) continue;

          // The addon position + 1K is a hack to ensure it is sorted after all products
          const sortAfterAll = 1000;
          if (!(sku in skus)) {
            skus[sku] = {
              sku: sku,
              apps: [],
              label: this.$t("overusage"),
              service_position: addon.service_position + sortAfterAll,
              product_position: addon.product_position + sortAfterAll,
            };
          }
          skus[sku].apps.push({
            // Adding the addon name in the app, as the overusage is a unique sku across all addons
            app: app.app + " - " + this.addonLabel(addon),
            price: addon.disk_overusage_price,
            future_price: addon.disk_overusage_future_price,
            duration: addon.disk_overusage,
            region: app.region,
          });
        }
      }

      skus = Object.values(skus);

      return skus
        .slice()
        .sort((a, b) =>
          a.service_position == b.service_position
            ? a.product_position - b.product_position
            : a.service_position - b.service_position,
        );
    },
  },
  methods: {
    isEmpty,
    runtimeLabel(runtime) {
      return (
        this.$t("label.container") +
        " -  " +
        this.$t("label.type") +
        " " +
        runtime.type +
        " - " +
        this.$t("label.size") +
        " " +
        runtime.size_name
      );
    },
    addonLabel(addon) {
      return (
        this.$t("label.addon") +
        " " +
        addon.provider_name +
        " - " +
        addon.plan_name
      );
    },
  },
});
</script>

<i18n>
en:
  emptyText: "No data for the selected period"
  label:
    name: App name
    price: Amount
    resource: Resource
    container: "Container"
    size: "Size"
    type: "Type"
    addon: "Addon"
  proTip: "The amounts shown are frozen at the end of each billing period. An application transferred during the month is billed to its new owner, even if you can see its consumption for the period prior to its transfer."
  notInvoice: "This document is not an invoice, but a breakdown of your consumption excluding VAT. It takes neither the free trial period nor any vouchers or credits into account."
  exclVat: "excl. VAT"
  overusage: "Overusage"
fr:
  emptyText: "Aucune donnée pour la période sélectionnée"
  label:
    name: Nom de l'app
    price: Montant
    resource: Ressource
    container: "Container"
    size: "Taille"
    type: "Type"
    addon: "Addon"
  proTip: "Les montants indiqués sont gelés à la fin de chaque période de facturation. Une application transférée en cours de mois est facturée à son nouveau propriétaire, même si vous pouvez voir sa consommation pour la période précédant son transfert."
  notInvoice: "Ce document n'est pas une facture, mais un décompte de vos consommations hors TVA. Il ne tient pas compte de la période d'essai gratuite ni des éventuels bons ou crédits."
  exclVat: "excl. TVA"
  overusage: "Surconsommation"
</i18n>
