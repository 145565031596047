import { App, ReviewApp } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  CREATE,
  HANDLE_FETCH,
  HANDLE_OPERATION,
  REFRESH,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ADD, SET_ALL } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export class ReviewAppsStore extends CollectionStore<ReviewApp> {
  actions = CollectionStore.buildActions<ReviewApp>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;
      const promise = scalingoClient(
        context,
        currentApp.region,
      ).SCMRepoLinks.reviewApps(currentApp.id);

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ALL,
      });
    },
    [CREATE](context, opts = {}) {
      const currentApp = useCurrentAppStore().regional as App;
      const promise = scalingoClient(
        context,
        currentApp.region,
      ).SCMRepoLinks.manualReviewApp(currentApp.id, opts.pullRequestId);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: ADD,
      });
    },
  });
  mutations = CollectionStore.buildMutations<ReviewApp>();
  getters = CollectionStore.buildGetters<ReviewApp>();
}

export const ReviewApps = buildMapping(new ReviewAppsStore(), "reviewApps");

export function listReviewApps(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<ReviewApp>>,
): CollectionWithFetch<ReviewApp> {
  return {
    items: listItems(store.getters[ReviewApps.getters.ALL], opts),
    latestFetch: store.getters[ReviewApps.getters.LATEST_FETCH],
  };
}

export function ensureReviewApps(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(ReviewApps.actions.ENSURE, opts);
}

export function manualReviewApp(
  store: ApplicationStore,
  pullRequestId: string | number,
): Promise<RemoteOperation<ReviewApp>> {
  return store.dispatch(ReviewApps.actions.CREATE, { pullRequestId });
}
