<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.28711 1.47974L3.05859 10.0901L2.87695 10.5793L1.34766 14.6575L12 22.4065L22.6523 14.6575L21.123 10.5793L17.71 1.47974L14.9121 10.0901H9.08789L6.28711 1.47974ZM6.1875 6.02075L7.51172 10.0901H4.66113L6.1875 6.02075ZM17.8125 6.02075L19.3389 10.0901H16.4883L17.8125 6.02075ZM4.09863 11.5901H7.99805L10.5703 19.512L3.15234 14.1155L4.09863 11.5901ZM9.57422 11.5901H14.4258L12 19.052L9.57422 11.5901ZM16.002 11.5901H19.8984L20.8477 14.1155L13.4268 19.512L16.002 11.5901Z"
      fill="currentColor"
    />
    <path
      d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18Z"
      fill="white"
    />
    <path
      d="M18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13ZM15.8571 17.2857H20.1429C20.5371 17.2857 20.8571 17.605 20.8571 18C20.8571 18.395 20.5371 18.7143 20.1429 18.7143H15.8571C15.4629 18.7143 15.1429 18.395 15.1429 18C15.1429 17.605 15.4629 17.2857 15.8571 17.2857Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GitlabDelGlyph",
});
</script>
