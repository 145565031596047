import { RouteRecordRaw, isNavigationFailure } from "vue-router";

import { dashboard } from "@/lib/utils/log";

export function navErrorHandler(e: unknown) {
  if (isNavigationFailure(e)) {
    dashboard.warn(e);
  } else {
    throw e;
  }
}

/**
 * Many pages/controllers have to handle OAuth callbacks from github/gitlab.
 * We have to use children routes in order to extract the SCM type
 * (query params don't work, because the auth-service expects a url without query params)
 */
export function childRoutesForOAuthLinking(
  parentName: string,
): RouteRecordRaw[] {
  return [
    {
      path: "link-github",
      redirect: (to) => {
        const { params, query } = to;
        query.scmType = "github";

        return {
          name: parentName,
          params,
          query,
        };
      },
    },
    {
      path: "link-gitlab",
      redirect: (to) => {
        const { params, query } = to;
        query.scmType = "gitlab";

        return {
          name: parentName,
          params,
          query,
        };
      },
    },
  ];
}
