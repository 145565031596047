<template>
  <ViewComponent
    :createCtx="createCtx"
    :emptyCtx="emptyCtx"
    :deleteCtx="deleteCtx"
    :mySQLDatabasesList="mySQLDatabasesList"
    @startDatabaseCreation="startDatabaseCreation"
    @cancelDatabaseCreation="cancelDatabaseCreation"
    @confirmDatabaseCreation="confirmDatabaseCreation"
    @startDatabaseEmptying="startDatabaseEmptying"
    @cancelDatabaseEmptying="cancelDatabaseEmptying"
    @confirmDatabaseEmptying="confirmDatabaseEmptying"
    @startDatabaseDeletion="startDatabaseDeletion"
    @cancelDatabaseDeletion="cancelDatabaseDeletion"
    @confirmDatabaseDeletion="confirmDatabaseDeletion"
  ></ViewComponent>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref, nextTick } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/db/settings/DatabasesManagement.vue";
import { closeCurrentModal } from "@/lib/modals";
import { voidPromiseInfo, promiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { Routes } from "@/router/names";
import {
  currentDB,
  useCurrentDBStore,
  currentDBClient,
} from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "DatabasesManagement",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const currentDbStore = useCurrentDBStore();
    const router = useRouter();
    const createdDatabase = ref(null);

    const mySQLDatabasesList = ref([]);
    const createCtx = ref(null);
    const emptyCtx = ref(null);
    const deleteCtx = ref(null);

    onBeforeMount(() => {
      const db = currentDB();
      if (db.type_name !== "mysql") {
        router.push({ name: Routes.Db.Settings });
      }
    });

    async function getMySQLDatabasesList() {
      const client = await currentDBClient();
      const db = currentDB();
      const response = await client.Database.mySQLListDatabases(db.id);

      mySQLDatabasesList.value = response.result;
    }

    async function confirmDatabaseCreation(payload) {
      const client = await currentDBClient();
      const db = currentDB();
      createCtx.value.error = null;

      try {
        const promise = client.Database.mySQLCreateDatabase(
          db.id,
          payload.name,
        );

        createCtx.value.info = promiseInfo(promise);
        createdDatabase.value = await promise;

        toastsStore.addOne({
          type: "success",
          title: t("creation.success.title"),
          message: t("creation.success.message"),
        });

        getMySQLDatabasesList();
        closeCurrentModal();
      } catch (e) {
        createCtx.value.error = e;
      }
    }

    async function confirmDatabaseEmptying() {
      const client = await currentDBClient();
      const db = currentDB();
      emptyCtx.value.error = null;

      try {
        const promise = client.Database.mySQLEmptyDatabase(
          db.id,
          emptyCtx.value.mysql_database_name,
        );

        emptyCtx.value.info = promiseInfo(promise);

        await promise;
        await nextTick();

        toastsStore.addOne({
          type: "success",
          title: t("emptying.success.title"),
          message: t("emptying.success.message"),
        });

        closeCurrentModal();
      } catch (e) {
        emptyCtx.value.error = e;
      }
    }

    async function confirmDatabaseDeletion() {
      const client = await currentDBClient();
      const db = currentDB();
      deleteCtx.value.error = null;

      try {
        const promise = client.Database.mySQLDeleteDatabase(
          db.id,
          deleteCtx.value.mysql_database_name,
        );

        deleteCtx.value.info = promiseInfo(promise);

        await promise;
        await nextTick();

        toastsStore.addOne({
          type: "success",
          title: t("deletion.success.title"),
          message: t("deletion.success.message"),
        });

        getMySQLDatabasesList();
        closeCurrentModal();
      } catch (e) {
        emptyCtx.value.error = e;
      }
    }

    getMySQLDatabasesList();

    return {
      db: computed(() => currentDbStore.database),
      startDatabaseCreation() {
        createCtx.value = { info: voidPromiseInfo(), error: null };
      },
      cancelDatabaseCreation() {
        createCtx.value = null;
      },
      confirmDatabaseCreation,
      startDatabaseEmptying(e) {
        emptyCtx.value = {
          info: voidPromiseInfo(),
          error: null,
          mysql_database_name: e.mysql_database.name,
        };
      },
      cancelDatabaseEmptying() {
        emptyCtx.value = null;
      },
      confirmDatabaseEmptying,
      startDatabaseDeletion(e) {
        deleteCtx.value = {
          info: voidPromiseInfo(),
          error: null,
          mysql_database_name: e.mysql_database.name,
        };
      },
      cancelDatabaseDeletion() {
        deleteCtx.value = null;
      },
      confirmDatabaseDeletion,
      mySQLDatabasesList,
      createCtx,
      emptyCtx,
      deleteCtx,
    };
  },
});
</script>

<i18n>
en:
  creation:
    success:
      title: "Database created"
      message: "The database has been successfully created."
  deletion:
    success:
      title: "Database deleted"
      message: "The database has been successfully deleted."
  emptying:
    success:
      title: "Database emptied"
      message: "The database has been successfully emptied."
fr:
  creation:
    success:
      title: "Base de données créée"
      message: "La base de données a été créée avec succès."
  deletion:
    success:
      title: "Base de données supprimée"
      message: "La base de données a été supprimée avec succès."
  emptying:
    success:
      title: "Base de données vidée"
      message: "La base de données a été vidée avec succès."
</i18n>
