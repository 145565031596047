<template>
  <div class="bg-scale-0 text-scale-5 text-sm border-t border-scale-2 mt-16">
    <div class="container flex py-6 px-4">
      <div class="sm:hidden">
        <div class="flex flex-col">
          <a
            class="flex flex-row space-x-1 items-center"
            target="_blank"
            href="https://scalingostatus.com"
          >
            <AlertingStatesIndicator
              :isOk="platformStatus.OK"
              :isWarning="platformStatus.Warning"
              :isKo="platformStatus.KO"
            ></AlertingStatesIndicator>
            <span>
              {{ $t("platformStatus") }}
            </span>
          </a>
          <a target="_blank" href="https://doc.scalingo.com/">{{
            $t("documentation")
          }}</a>
        </div>
        <div v-if="contract" class="flex flex-col">
          <FooterDocumentLink
            v-for="(document, key) in contract.documents"
            :key="key"
            :document="document"
          />
        </div>
        <div>
          <router-link :to="aboutRoute">{{ $t("about") }}</router-link>
        </div>
        <div>© {{ currentYear }} Scalingo</div>
      </div>
      <div class="hidden sm:flex flex-grow">
        <div class="flex flex-row space-x-3">
          <a
            class="flex flex-row space-x-1 items-center"
            target="_blank"
            href="https://scalingostatus.com"
          >
            <AlertingStatesIndicator
              :isOk="platformStatus.OK"
              :isWarning="platformStatus.Warning"
              :isKo="platformStatus.KO"
            ></AlertingStatesIndicator>
            <span>
              {{ $t("platformStatus") }}
            </span>
          </a>
          <a target="_blank" href="https://doc.scalingo.com/">{{
            $t("documentation")
          }}</a>
        </div>
      </div>
      <div v-if="contract" class="hidden sm:flex space-x-3">
        <FooterDocumentLink
          v-for="(document, key) in contract.documents"
          :key="key"
          :document="document"
        />
        <div>
          <router-link :to="aboutRoute">{{ $t("about") }}</router-link>
        </div>
        <div>© {{ currentYear }} Scalingo</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import AlertingStatesIndicator from "@/components/atoms/indicators/AlertingStatesIndicator.vue";
import FooterDocumentLink from "@/components/organisms/footer/FooterDocumentLink.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Footer",
  components: {
    FooterDocumentLink,
    AlertingStatesIndicator,
  },
  props: {
    contract: Object,
    platformStatus: Object,
  },
  data() {
    return {
      aboutRoute: {
        name: Routes.About,
      },
      currentYear: new Date().getFullYear(),
    };
  },
});
</script>

<i18n>
en:
  documentation: "Documentation"
  about: "About"
  platformStatus: "Platform status"
fr:
  documentation: "Documentation"
  about: "À propos"
  platformStatus: "Statut de la plateforme"
</i18n>
