<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28015 0.219834C5.42067 0.0791415 5.6113 8.50156e-06 5.81015 -0.00016648L6.19015 -0.000166447C6.38856 0.00213765 6.57843 0.0809498 6.72015 0.219834L11.8501 5.35984C11.9448 5.45372 11.998 5.58152 11.998 5.71484C11.998 5.84815 11.9448 5.97595 11.8501 6.06984L11.1401 6.77984C11.048 6.8739 10.9218 6.92691 10.7901 6.92691C10.6585 6.92691 10.5323 6.8739 10.4401 6.77984L6.00015 2.32983L1.56015 6.77983C1.46627 6.87449 1.33847 6.92773 1.20515 6.92773C1.07183 6.92773 0.944033 6.87449 0.850149 6.77983L0.150149 6.06983C0.0554934 5.97595 0.00225077 5.84815 0.00225078 5.71483C0.00225079 5.58152 0.0554935 5.45372 0.150149 5.35983L5.28015 0.219834Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShortArrowTop",
});
</script>
