export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search what you need (", _interpolate(_named("shortcut")), ")"])},
        "placeholder-alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search what you need"])},
        "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
        "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matches"])},
        "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No match"])}
      },
      "fr": {
        "placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cherchez vos applications (", _interpolate(_named("shortcut")), ")"])},
        "placeholder-alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherchez vos applications"])},
        "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité de"])},
        "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
        "noMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de résultat"])}
      }
    }
  })
}
