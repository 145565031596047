export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure connection methods to access your databases"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
        "console": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["console"])},
        "sshTunnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Tunnel"])},
        "connectionString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection String"])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information in our documentation"])},
        "copyClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de connexion sécurisées pour l'accès aux bases de données"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
        "console": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["console"])},
        "sshTunnel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnel SSH"])},
        "connectionString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaîne de connexion"])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'information dans notre documentation"])},
        "copyClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier dans le presse-papiers"])}
      }
    }
  })
}
