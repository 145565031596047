import { Domain } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { makeCanonical, refreshDomains } from "@/store/domains";
import { useCurrentAppStore } from "@/stores/current/app";

import type { ComponentPublicInstance } from "vue";

export class SetDefaultDomainHandler extends ActionHandler<void> {
  keyPath = "domains.setDefault";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly domain: Domain,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      refreshDomains(this.$store);
      useCurrentAppStore().refresh();

      this.notify("success");
    });

    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(await makeCanonical(this.$store, this.domain));
  }
}
