export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk IO"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk consumption for this instance."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disque IO"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation de disque de cette instance."])}
      }
    }
  })
}
