import axios, { AxiosRequestConfig } from "axios";
import XMLJS from "xml-js";

const BLOG_HOST = process.env.VUE_APP_SCALINGO_BLOG_HOST;
const BLOG_AUTH = process.env.VUE_APP_SCALINGO_BLOG_AUTH;

export interface BlogArticle {
  title: string;
  link: string;
  summary: string;
  publishedAt: string;
}

export function fetchLastArticles(
  locale: "en" | "fr",
  count = 20,
): Promise<BlogArticle[]> {
  const url =
    locale === "fr"
      ? `https://${BLOG_HOST}/fr/blog`
      : `https://${BLOG_HOST}/blog`;

  const options: AxiosRequestConfig = {
    headers: {
      Accept: "application/atom+xml",
      "Accept-Language": locale,
    },
  };

  if (BLOG_AUTH) {
    const [username, password] = BLOG_AUTH.split(":");
    options.auth = { username, password };
  }

  return axios.get<string>(url, options).then((resolved) => {
    const parsedResponse = XMLJS.xml2js(resolved.data);
    const articles = blogArticlesFromResponse(parsedResponse, count);

    return articles;
  });
}

function blogArticlesFromResponse(
  content: XMLJS.Element | XMLJS.ElementCompact,
  count: number,
): BlogArticle[] {
  const items: XMLJS.Element[] = [];

  for (const item of content.elements[0].elements) {
    if (item.name === "entry") {
      items.push(item);
    }

    if (items.length === count) {
      break;
    }
  }

  return items.map((item) => {
    return {
      title: titleFromEntry(item),
      link: linkFromEntry(item),
      summary: summaryFromEntry(item),
      publishedAt: publishedAtFromEntry(item),
    };
  }) as BlogArticle[];
}

function titleFromEntry(item: XMLJS.Element): string | undefined {
  return textForEntryChildren(item, "title");
}

function summaryFromEntry(item: XMLJS.Element): string | undefined {
  return textForEntryChildren(item, "summary");
}

function publishedAtFromEntry(item: XMLJS.Element): string | undefined {
  return textForEntryChildren(item, "updated");
}

function linkFromEntry(item: XMLJS.Element): string | undefined {
  const linkEl = item.elements?.find((el) => el.name === "link");

  return linkEl?.attributes?.href as string;
}

function textForEntryChildren(
  item: XMLJS.Element,
  name: string,
): string | undefined {
  const titleEl = item.elements?.find((el) => el.name === name);

  return titleEl?.elements?.[0].text as string;
}
