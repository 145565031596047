import { APIError } from "scalingo/lib/errors";

/**
 * This handler ignores 404. To be used in cases:
 * - where they are expected (a HDS contact)
 * - where they are idempotentent (a 404 when deleting a resource (it is likely already deleted)
 */
export function ignore404(error: unknown): null {
  if (error instanceof APIError) {
    if (error.status === 404) {
      // expected and allowed; do nothing
      return null;
    }
  }

  throw error;
}
