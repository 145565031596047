export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something's gone missing ..."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find the page you're looking for. You may want to visit our home page instead."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go home"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose manque ..."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas trouvé la page que vous cherchez. Nous vous proposons de retourner à la page d'accueil."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])}
      }
    }
  })
}
