export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data for the selected period"])},
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App name"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
          "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
          "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon"])}
        },
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amounts shown are frozen at the end of each billing period. An application transferred during the month is billed to its new owner, even if you can see its consumption for the period prior to its transfer."])},
        "notInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document is not an invoice, but a breakdown of your consumption excluding VAT. It takes neither the free trial period nor any vouchers or credits into account."])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. VAT"])},
        "overusage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overusage"])}
      },
      "fr": {
        "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée pour la période sélectionnée"])},
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'app"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource"])},
          "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon"])}
        },
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les montants indiqués sont gelés à la fin de chaque période de facturation. Une application transférée en cours de mois est facturée à son nouveau propriétaire, même si vous pouvez voir sa consommation pour la période précédant son transfert."])},
        "notInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce document n'est pas une facture, mais un décompte de vos consommations hors TVA. Il ne tient pas compte de la période d'essai gratuite ni des éventuels bons ou crédits."])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. TVA"])},
        "overusage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surconsommation"])}
      }
    }
  })
}
