/* eslint-disable @typescript-eslint/no-explicit-any */
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export const STATE_NEW = "new";
export const STATE_PAID = "paid";
export const STATE_FAILED = "failed";
export const STATE_CANCELLED = "cancelled";
export const STATE_REFUNDED = "refunded";
export const STATE_PARTIALLY_REFUNDED = "partially_refunded";

export const STATES = {
  NEW: STATE_NEW,
  PAID: STATE_PAID,
  FAILED: STATE_FAILED,
  CANCELLED: STATE_CANCELLED,
  REFUNDED: STATE_REFUNDED,
  PARTIALLY_REFUNDED: STATE_PARTIALLY_REFUNDED,
};

export type IndexQuery = {
  owner_id: string;
  page?: number;
  per_page?: number;
};

export interface BalanceOperation {
  /**  unique ID */
  id: number;
  /**  price of this invoice */
  created_at: number;
  /** 	price of this invoice including VAT */
  amount: string;
  /**  this invoice is related to this month */
  source?: string;
  /** 	URL to download the PDF invoice */
  description: string;
  /**  the invoice number */
  invoice_id?: string;
  /** 	state of this invoice (new, paid or failed) */
  invoice_url: string;
}

export type IndexResponse = {
  meta: Record<string, any>;
  balance_operations: BalanceOperation[];
};

export default class BalanceOperations {
  client: Scalingo;

  /**
   * Create new Client for the Balance Operations API
   * @param {Client} client - Scalingo API Client
   */
  constructor(client: Scalingo) {
    this.client = client;
  }

  /**
   * Return balance operations for a given user
   * @param {BalanceOperationsOpts} opts Object that contains the index of the page and the number of elements per page
   * @return {Promise<BalanceOperationResponse | APIError>}
   */
  all(params: IndexQuery): Promise<IndexResponse> {
    return unpackData(
      this.client.billingApiClient().get("/balance_operations", { params }),
    );
  }
}
