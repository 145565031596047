export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are allowed to see this app because you have administrator rights. Be careful of your actions."])}
      },
      "fr": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are allowed to see this app because you have administrator rights. Be careful of your actions."])}
      }
    }
  })
}
