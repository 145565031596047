<template>
  <svg
    class="h-6 w-6"
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99392 7.75104e-05C14.3881 -0.0450807 18.3582 2.61654 19.9878 6.70008C20.0041 6.79942 20.0041 6.90074 19.9878 7.00008C19.9911 7.11306 19.9706 7.22547 19.9279 7.33008C18.2967 11.3813 14.3588 14.0253 9.99392 14.0001C5.62406 14.0221 1.68479 11.3692 0.0599735 7.31008C0.019856 7.21168 -0.000518915 7.10635 1.0041e-05 7.00008C0.0012875 6.88748 0.0215604 6.77591 0.0599735 6.67008C1.69114 2.61885 5.629 -0.02517 9.99392 7.75104e-05ZM6.99575 7.00008C6.99575 8.65693 8.33808 10.0001 9.99392 10.0001C10.7891 10.0001 11.5517 9.68401 12.1139 9.1214C12.6762 8.55879 12.9921 7.79573 12.9921 7.00008C12.9921 5.34322 11.6498 4.00008 9.99392 4.00008C8.33808 4.00008 6.99575 5.34322 6.99575 7.00008Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EyeGlyph",
});
</script>
