export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by:"])},
        "nameAz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name from A to Z"])},
        "nameZa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name from Z to A"])},
        "recentlyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recently updated"])},
        "leastRecentlyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["least recently updated"])}
      },
      "fr": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par:"])},
        "nameAz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de A à Z"])},
        "nameZa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de Z à A"])},
        "recentlyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["récemment mise à jour"])},
        "leastRecentlyUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moins récemment mise à jour"])}
      }
    }
  })
}
