<template>
  <LoadingCardState v-if="isLoading" />
  <router-view v-else-if="isSuccess" :app="app" :addon="promiseInfo.data" />
  <LoadingCardState v-else />
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";

export default defineComponent({
  name: "AddonsEditionRoot",
  components: { LoadingCardState },
  props: {
    app: {
      type: Object,
      required: true,
    },
    promiseInfo: Object,
  },
  computed: {
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isSuccess() {
      return this.promiseInfo.isSuccess;
    },
  },
  methods: {},
});
</script>
