<template>
  <div class="py-6">
    <div
      class="flex justify-between py-4 bg-scale-0 text-scale-10 border-opacity-50 border rounded-lg flex-col pv:flex-row"
    >
      <div class="px-8 justify-center flex">
        <AddonProviderIcon :addonProvider="provider" />
      </div>
      <div v-if="provider" class="px-4 flex-1 text-left my-4">
        <div
          class="font-medium border-b mb-3 pb-3 pv:pb-3 pv:mb-0 pv:border-none"
        >
          {{ provider.name }}
        </div>
        <div v-if="plan && plan.display_name" class="flex flex-col pv:flex-row">
          <div class="pr-4 py-2">
            <span :class="{ 'bg-color-1-25': planHasChanged }">
              {{ plan.display_name }}
            </span>
          </div>
          <div class="pv:px-4 py-2">
            <template v-if="plan.ramStr">
              <span class="font-medium pr-2">{{ plan.ramStr }}</span>
              <span class="text-sm text-scale-8">RAM</span>
            </template>
          </div>
          <div class="pv:px-4 py-2">
            <template v-if="plan.diskStr">
              <span class="font-medium pr-2">{{ plan.diskStr }}</span>
              <span class="whitespace-nowrap">{{ $t("disk") }}</span>
            </template>
          </div>
          <div v-if="displayPrices" class="pv:px-4 py-2">
            <span :class="{ 'bg-color-1-25': planHasChanged }">
              <span class="pr-2 font-medium"> {{ plan.price }}€ </span>
              <span class="text-sm text-scale-8">{{
                $t("per_thirty_days")
              }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="px-4 mt-4 flex justify-center">
        <LinkButton @clicked="$emit('changeAddonPlan')">
          {{ $t("configure") }}
        </LinkButton>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import AddonProviderIcon from "@/components/atoms/graphics/addonProviders/Icon.vue";

export default defineComponent({
  name: "ShowSelectedAddon",
  components: { AddonProviderIcon, LinkButton },
  props: {
    addon: Object,
    provider: Object,
    plan: Object,
    displayPrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["changeAddonPlan"],
  computed: {
    planHasChanged() {
      if (this.addon) {
        return this.addon.plan.id !== this.plan.id;
      } else {
        return false;
      }
    },
  },
  methods: {},
});
</script>

<i18n>
  en:
    configure: "Select another plan"
    per_thirty_days: "per 30 days"
    disk: "Disk space"
  fr:
    configure: "Choisir un autre plan"
    per_thirty_days: "pour 30 jours"
    disk: "Espace disque"
</i18n>
