<template>
  <div class="mt-6 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6">
    <template v-if="appInfos.initialPromiseInfo.isLoading">
      <LoadingListingAppCard
        v-for="key in loadingItems"
        :key="`app-loading-${key}`"
      />
    </template>

    <template v-else>
      <ListingAppCard
        v-for="appInfo in appInfos.items"
        :key="appInfo.id"
        :app="appInfo"
        :scmRepoLink="appInfo.scm_repo_link"
        :multiRegions="multiRegions"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ListingAppCard from "@/components/organisms/appCards/ListingAppCard.vue";
import LoadingListingAppCard from "@/components/organisms/appCards/LoadingListingAppCard.vue";

export default defineComponent({
  name: "ListAppsCards",
  components: { ListingAppCard, LoadingListingAppCard },
  props: {
    appInfos: Object,
    loadingItems: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    multiRegions() {
      if ((this.appInfos.items || []).length <= 1) {
        return false;
      }
      return !!this.appInfos.items.find(
        (app) => app.region !== this.appInfos.items[0].region,
      );
    },
  },
});
</script>

<i18n>
en:
  empty: "No app yet :("
fr:
  empty: "Aucune application :("
</i18n>
