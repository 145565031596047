export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure the deployment process"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer le processus de déploiement"])}
      }
    }
  })
}
