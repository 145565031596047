<template>
  <DeleteModal
    :formHandler="formHandler"
    @formSubmitted="$emit('formSubmitted')"
    @modalClosed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("question") }}
    </template>
    <template #button>
      {{ $t("button") }}
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "StopFreeTrialModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
});
</script>

<i18n>
en:
  title: "Stop free trial"
  question: "Do you really want to stop the free trial period?"
  button: "Confirm"
fr:
  title: "Arrêter l'essai gratuit"
  question: "Voulez-vous vraiment arrêter la période d'essai gratuite ?"
  button: "Confirmer"
</i18n>
