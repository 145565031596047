export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel query"])},
        "kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminate session"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
        "warningMessageCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canceling this query will stop its current execution. This action will not terminate the session, and the connection will remain active. However, any uncommitted changes will be lost."])},
        "warningMessageTerminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terminating the session will immediately disconnect the user and end all running queries. This action will roll back any uncommitted transactions, potentially leading to data loss. Use this option with caution."])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel this query"])},
        "killButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminate this session"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please reload the page and try again."])}
      },
      "fr": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la requête"])},
        "kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre la session"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
        "warningMessageCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'annulation de cette requête interrompra son exécution. Cette action ne met pas fin à la session et la connexion reste active. Toutefois, les modifications non enregistrées seront perdues."])},
        "warningMessageTerminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'interruption de la session entraîne la déconnexion immédiate de l'utilisateur et met fin à toutes les requêtes en cours d'exécution. Cette action annulera toutes les transactions non enregistrées, ce qui peut entraîner une perte de données. Utilisez cette fonction avec précaution."])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler cette requête"])},
        "killButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre cette session"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez recharger la page et réessayer."])}
      }
    }
  })
}
