<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
    <LoadingCardState
      v-if="rpmOperation.isLoading"
      class="h-64 justify-center"
    />
    <EmptyCardState
      v-else-if="rpmOperation.isError"
      class="h-64 justify-center"
    >
      <template #icon><CrossGlyph /></template>
      <span class="capitalize">{{ rpmOperation.error }}</span>
    </EmptyCardState>

    <RPMGraph
      v-else-if="rpmOperation.isSuccess"
      id="requests-per-minute-graph"
      :chartData="rpmOperation.value.datasets"
      :eventsAnnotations="eventsAnnotations"
    />

    <LoadingCardState
      v-if="responseTimeOperation.isLoading"
      class="h-64 justify-center"
    />
    <EmptyCardState
      v-else-if="responseTimeOperation.isError"
      class="h-64 justify-center"
    >
      <template #icon><CrossGlyph /></template>
      <span class="capitalize">{{ responseTimeOperation.error }}</span>
    </EmptyCardState>

    <ResponseTimeGraph
      v-else-if="responseTimeOperation.isSuccess"
      id="response-time-graph"
      :chartData="responseTimeOperation.value.datasets"
      :eventsAnnotations="eventsAnnotations"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ResponseTimeGraph from "@/components/organisms/apex/ResponseTimeGraph.vue";
import RPMGraph from "@/components/organisms/apex/RPMGraph.vue";

export default defineComponent({
  name: "RequestsGraphsCard",
  components: {
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    RPMGraph,
    ResponseTimeGraph,
  },
  props: {
    rpmOperation: Object,
    responseTimeOperation: Object,
    eventsAnnotations: Array,
  },
});
</script>

<i18n>
en:
  title: "Requests"
  subtitle: "Requests counts and response times"
fr:
  title: "Requêtes"
  subtitle: "Nombre de requêtes et temps de réponse"
</i18n>
