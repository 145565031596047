<template>
  <div v-if="collaborators.length !== 0" class="flex-grow">
    <div
      v-for="(item, index) in collaboratorsSorted"
      :key="index"
      class="py-3 flex"
      :class="{ 'border-t border-scale-2': index > 0 }"
    >
      <div class="pr-3 flex">
        <CollaboratorPortrait
          :item="item"
          :isOwner="app.owner.username === item.username"
        ></CollaboratorPortrait>
      </div>
      <div
        class="flex items-center"
        :class="{ 'text-scale-5': item.status === 'pending' }"
      >
        <template v-if="!item.username || item.username === 'n/a'">
          {{ item.email }}
        </template>
        <template v-else>
          {{ item.username }}
          <span
            v-if="app.owner.username === item.username"
            class="text-scale-5 ml-1"
            >(owner)</span
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CollaboratorPortrait from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorPortrait.vue";

export default defineComponent({
  name: "CollaboratorsList",
  components: {
    CollaboratorPortrait,
  },
  props: {
    collaborators: Array,
    app: Object,
  },
  computed: {
    collaboratorsSorted() {
      const accepted = this.collaborators.filter(
        (item) => item.status === "accepted",
      );
      const notAccepted = this.collaborators.filter(
        (item) => item.status !== "accepted",
      );

      return [...accepted, ...notAccepted];
    },
  },
});
</script>
