import { isEqual } from "lodash";
import { EventType, Notifier } from "scalingo/lib/models/regional";
import { UpdateParams } from "scalingo/lib/params/regional/notifiers";

import { FormHandler } from "@/lib/handlers/form";
import { App } from "@/lib/scalingo/modifiers/apps";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { updateNotifier } from "@/store/notifiers";

import type { ComponentPublicInstance } from "vue";

type EventOption = "all" | "deployedEvent" | "selectedEvents";

interface FormData {
  eventOption: EventOption;
  selectedEventIds: string[];
  selectedUserIds: string[];
  extraEmails: string[];
  webhookUrl: string | null;
  name: string;
}

export class EditNotifierHandler extends FormHandler<Notifier> {
  keyPath = "notifiers.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly notifier: Notifier,
    readonly eventTypes: EventType[],
    readonly after: "events" | "show",
  ) {
    super(component);
  }

  // There should always be an `app_deployed` event,
  // but the event types are not always loaded
  get appDeployedEvent(): EventType {
    return this.eventTypes.find(
      (type) => type.name === "app_deployed",
    ) as EventType;
  }

  data(): Nullable<FormData> {
    // By default, we're selecting individual events
    let eventOption: EventOption = "selectedEvents";

    // ... unless the notifier is flagged as sending for all events
    if (this.notifier.send_all_events) {
      eventOption = "all";
    } else if (
      // ... or if there is only one event, app_deployed
      isEqual(this.notifier.selected_event_ids, [this.appDeployedEvent?.id])
    ) {
      eventOption = "deployedEvent";
    }

    const selectedUserIds =
      this.notifier.type === "email"
        ? [...this.notifier.type_data.user_ids]
        : [];

    const extraEmails =
      this.notifier.type === "email" ? [...this.notifier.type_data.emails] : [];

    const webhookUrl =
      this.notifier.type === "email"
        ? null
        : this.notifier.type_data.webhook_url;

    return {
      eventOption,
      name: this.notifier.name,
      selectedEventIds: [...this.notifier.selected_event_ids], // we need to copy the array
      selectedUserIds,
      extraEmails,
      webhookUrl,
    };
  }

  dispatchEvents(): void {
    this.on("success", (notifier) => {
      this.notify("success", { name: notifier.name });

      const route = {
        name:
          this.after === "show"
            ? Routes.App.Notifiers.Show
            : Routes.App.Notifiers.Edit.Events,
        params: {
          id: this.app.name,
          region: this.app.region,
          notifierId: this.notifier.id,
        },
      };

      this.$router.push(route);
    });
  }

  eventOptions(): string[] {
    return ["all", "deployedEvent", "selectedEvents"];
  }

  async submit(event: FormData): Promise<void> {
    const payload: UpdateParams = {
      name: event.name,
      selected_event_ids: this.notifier.selected_event_ids,
      send_all_events: false,
    };

    if (event.eventOption === "all") {
      payload.selected_event_ids = [];
      payload.send_all_events = true;
    } else if (event.eventOption === "deployedEvent") {
      if (this.appDeployedEvent) {
        payload.selected_event_ids = [this.appDeployedEvent?.id];
      }
    } else if (event.selectedEventIds) {
      payload.selected_event_ids = [...event.selectedEventIds];
    }

    if (this.notifier.type === "email") {
      payload.type_data = {
        user_ids: event.selectedUserIds,
        emails: event.extraEmails,
      };
    } else {
      payload.type_data = {
        webhook_url: event.webhookUrl,
      };
    }

    this.follow(await updateNotifier(this.$store, this.notifier, payload));
  }
}
