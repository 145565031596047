<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6.5V15.5C20 15.7761 19.7761 16 19.5 16H14C12.8954 16 12 15.1046 12 14H6V21.5C6 21.7761 5.77614 22 5.5 22H4.5C4.22386 22 4 21.7761 4 21.5V2.5C4 2.22386 4.22386 2 4.5 2H5.5C5.77614 2 6 2.22386 6 2.5V4H12C13.1046 4 14 4.89543 14 6H19.5C19.7761 6 20 6.22386 20 6.5Z"
      fill="currentColor"
    />
    <path
      d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18Z"
      fill="white"
    />
    <path
      d="M18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13ZM18 15.1429C18.3943 15.1429 18.7143 15.4621 18.7143 15.8571V17.2857H20.1429C20.5371 17.2857 20.8571 17.605 20.8571 18C20.8571 18.395 20.5371 18.7143 20.1429 18.7143H18.7143V20.1429C18.7143 20.5379 18.3943 20.8571 18 20.8571C17.6057 20.8571 17.2857 20.5379 17.2857 20.1429V18.7143H15.8571C15.4629 18.7143 15.1429 18.395 15.1429 18C15.1429 17.605 15.4629 17.2857 15.8571 17.2857H17.2857V15.8571C17.2857 15.4621 17.6057 15.1429 18 15.1429Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AlertAddGlyph",
});
</script>
