export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a SSH key"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want delete this SSH key?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une clé SSH"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous vraiment supprimer cette clé SSH?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      }
    }
  })
}
