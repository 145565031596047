export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete your Scalingo account"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all your Scalingo data"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm that I want to start the account deletion process for the account "])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action will queue the removal of all your Scalingo account's data, including: Deployments, Activity, Aliases, Domains, Certificates and your Billing subscription"])},
        "errorPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request could not be fulfilled:"])},
        "successPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account deletion successfully requested. Check your emails to confirm."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer votre compte Scalingo"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppresion de toutes vos données Scalingo"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer que vous voulez commencer le processus de de suppression pour le compte "])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action demandera la suppression de toutes vos données Scalingo, includant: Deploiments, Activités, Alias, Domaines, Certificats et vos Facturations"])},
        "errorPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre requête n'a pas aboutie:"])},
        "successPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de compte enregistrée. Vérifiez vos emails pour confirmer."])}
      }
    }
  })
}
