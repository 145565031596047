export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tokens"])},
        "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "noTokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have tokens yet."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes tokens"])},
        "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouveller"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "noTokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore crée de tokens."])}
      }
    }
  })
}
