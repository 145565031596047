<template>
  <SimpleModal @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template v-slot:body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <div class="flex justify-between">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.name"
                  as="span"
                  name="name"
                  rules="required"
                  class="w-1/2"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('billingProfile', 'name')"
                    labelClass="capitalize"
                    :errors="errors"
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.company"
                  as="span"
                  class="w-1/2 pl-4"
                  name="company"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('billingProfile', 'company')"
                    labelClass="capitalize"
                    :errors="errors"
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
              </div>
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.email"
                as="span"
                name="email"
                rules="email"
                mode="eager"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('billingProfile', 'email')"
                  labelClass="capitalize"
                  :errors="errors"
                  @update:modelValue="handleChange"
                ></TextInput>
              </Field>
              <div class="flex justify-between">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.address_line1"
                  as="span"
                  name="address_line1"
                  class="w-1/2"
                  rules="required"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('billingProfile', 'addressLine1')"
                    labelClass="capitalize"
                    :errors="errors"
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.address_line2"
                  as="span"
                  class="w-1/2 pl-4"
                  name="address_line2"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('billingProfile', 'addressLine2')"
                    labelClass="capitalize"
                    :errors="errors"
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
              </div>
              <div class="flex justify-between flex-col lg:flex-row">
                <div class="flex w-full lg:w-2/3">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.address_city"
                    as="span"
                    class="w-1/2"
                    name="address_city"
                    rules="required"
                  >
                    <TextInput
                      :name="field.name"
                      :modelValue="field.value"
                      :label="$ta('billingProfile', 'addressCity')"
                      labelClass="capitalize"
                      :errors="errors"
                      @update:modelValue="handleChange"
                    ></TextInput>
                  </Field>
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.address_zip"
                    as="span"
                    class="w-1/2 px-4"
                    name="address_zip"
                    rules="required"
                  >
                    <TextInput
                      :name="field.name"
                      :modelValue="field.value"
                      :label="$ta('billingProfile', 'addressZip')"
                      labelClass="capitalize"
                      :errors="errors"
                      @update:modelValue="handleChange"
                    ></TextInput>
                  </Field>
                </div>
                <span class="w-full lg:w-1/3 mb-8 lg:mb-0">
                  <Field
                    v-model="form.address_country"
                    name="address_country"
                    rules="required"
                  >
                    <CountrySelect
                      v-model="form.address_country"
                      class="lg:w-72"
                      :label="$ta('billingProfile', 'addressCountry')"
                    ></CountrySelect>
                  </Field>
                </span>
              </div>
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.vat_number"
                as="span"
                name="vat_number"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('billingProfile', 'vatNumber')"
                  :disabled="!isEuropeanForVat"
                  labelClass="capitalize"
                  :errors="errors"
                  @update:modelValue="handleChange"
                ></TextInput>
              </Field>
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.invoice_additional_info"
                as="span"
                name="invoice_additional_info"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('billingProfile', 'invoiceAdditionalInfo')"
                  labelClass="capitalize"
                  :subText="$t('invoiceAdditionalInfo')"
                  :errors="errors"
                  @update:modelValue="handleChange"
                ></TextInput>
              </Field>
            </div>

            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-8"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import CountrySelect from "@/components/molecules/selects/CountrySelect.vue";
import { europeanForVat } from "@/lib/utils/countries";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "BillingForm",
  components: {
    CountrySelect,
    SimpleModal,
    SCButton,
    TextInput,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["modalClosed"],
  computed: {
    isEuropeanForVat() {
      if (!this.form.address_country) return false;

      return europeanForVat(this.form.address_country);
    },
  },
  watch: {
    "form.address_country": function (newVal) {
      if (!europeanForVat(newVal)) {
        this.form.vat_number = null;
      }
    },
  },
});
</script>

<i18n>
  en:
    title: "Billing"
    subtitle: "Complete your billing profile"
    buttonName: "Save these billing informations"
    invoiceAdditionalInfo: "The text of this field will appear on all your future invoices"
  fr:
    title: "Facturation"
    subtitle: "Compléter votre profil de facturation"
    buttonName: "Enregistrer ces informations de facturation"
    invoiceAdditionalInfo: "Le texte de ce champ apparaîtra sur toutes vos futures factures"
</i18n>
