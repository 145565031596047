export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App created"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Named"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App créée"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nommée"])},
        "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])}
      }
    }
  })
}
