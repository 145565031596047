<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :domains="domains"
    @settingUpdated="settingUpdated"
  >
  </ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/Routing.vue";
import { EditAppSettingsHandler } from "@/lib/handlers";
import { ensureDomains, listDomains } from "@/store/domains";

export default defineComponent({
  name: "RoutingContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },

  computed: {
    domains() {
      return listDomains(this.$store, { sortBy: "name", sortDirection: "asc" });
    },
  },
  beforeMount() {
    ensureDomains(this.$store);
  },
  methods: {
    settingUpdated(e) {
      const handler = new EditAppSettingsHandler(this, this.app);

      handler.submit(e);
    },
  },
});
</script>
