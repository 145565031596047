<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :logsArchives="logsArchives"
    @fetchMoreArchives="fetchMoreArchives"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/LogsArchives.vue";
import {
  ensureArchives,
  fetchMoreArchives,
  listArchives,
} from "@/store/log-archives";

export default defineComponent({
  name: "LogsArchivesContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    logsArchives() {
      return listArchives(this.$store);
    },
  },
  mounted() {
    ensureArchives(this.$store, { staleAfter: "15" });
  },
  methods: {
    fetchMoreArchives() {
      fetchMoreArchives(this.$store);
    },
  },
});
</script>
