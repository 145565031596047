export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
        "deploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy"])},
        "reviewApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
        "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
      },
      "fr": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue générale"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressources"])},
        "deploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement"])},
        "reviewApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs"])},
        "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métriques"])},
        "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
      }
    }
  })
}
