export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice summary"])},
        "itemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
        "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download (PDF)"])}
      },
      "fr": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé de la facture"])},
        "itemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
        "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger (PDF)"])}
      }
    }
  })
}
