import { isArray, isObject } from "lodash";
import { APIError } from "scalingo/lib/errors";
import { Variable } from "scalingo/lib/models/regional";

/** ActiveModel validation errors, eg {errors: {field1: [err1, err2, ...], ...}} */
type AMErrors = Record<string, string[]>;

/** Bulk update variables errors, eg: [{...var1, errors: AMErrors}, ...] */
type BulkVariablesErrors = (Variable & { errors: AMErrors })[];

/**
 * short style: stop at the first err.
 * long style: be exhaustive.
 *
 * Add cases when needed
 */
export function errorAsString(
  err: APIError,
  style: "short" | "long" = "short",
): string | null {
  let str = null;

  if (err.data.errors && isObject(err.data.errors)) {
    str = activeModelErrorAsString(err.data.errors as AMErrors);
  } else if (err.data.failed && isArray(err.data.failed)) {
    str = bulkVariablesErrorAsString(err.data.failed, style);
  }

  return str;
}

/** ActiveModel validation errors, eg {errors: {field1: [err1, err2, ...], ...}} */
export function activeModelErrorAsString(
  errors: AMErrors,
  style: "short" | "long" = "short",
): string | null {
  let str = "";

  if (style === "short") {
    const k = Object.keys(errors)[0];
    const v = errors[k][0];

    str += `${k}: ${v}.`;
  } else {
    for (const [k, v] of Object.entries(errors)) {
      str += `${k}: ${v.join()}.`;
    }
  }

  return str;
}

export function bulkVariablesErrorAsString(
  failed: BulkVariablesErrors,
  style: "short" | "long" = "short",
): string | null {
  let str = null;

  if (style === "short") {
    str = activeModelErrorAsString(failed[0].errors, style);
  } else {
    // TODO: not impl yet
  }

  return str;
}

export type ErrorMeta = {
  generic?: true;
  network?: true;
  scalingo?: true;
  paymentRequired?: true;
  unauthorized?: true;
  forbidden?: true;
  notFound?: true;
};

export function errorsMeta(err?: unknown): ErrorMeta {
  const meta: ErrorMeta = {};

  if (!err) {
    meta.generic = true;
  } else if (err instanceof APIError) {
    // Scalingo API errors handling
    meta.scalingo = true;

    // Server error
    if (err.status >= 500) {
      meta.generic = true;
    }

    // Could be global auth error, could be more like "forbidden".
    if (err.status === 401) meta.unauthorized = true;

    // Payment required
    if (err.status === 402) meta.paymentRequired = true;

    // Forbidden access.
    if (err.status === 403) meta.forbidden = true;

    // Not found. Could be routing error, no-longer existing resources, etc...
    if (err.status === 404) meta.notFound = true;
  } else if (isObject(err) && "name" in err && err.name == "NetworkError") {
    meta.network = true;
  } else {
    // For now, considering all other errors generic
    meta.generic = true;
  }

  return meta;
}
