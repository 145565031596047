export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "tableHeaders": {
          "sqlQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL Query"])},
          "calls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calls"])},
          "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows"])},
          "totalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total time"])},
          "meanTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mean time"])},
          "minTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min time"])},
          "maxTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max time"])},
          "timeStddev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time stddev"])}
        },
        "firstDataMessagePartOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first data will be displayed shortly."])},
        "firstDataMessagePartTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait a few moments and refresh the list to explore the performance of your first queries."])},
        "copyQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the query"])}
      },
      "fr": {
        "tableHeaders": {
          "sqlQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête SQL"])},
          "calls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appels"])},
          "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lignes"])},
          "totalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps total"])},
          "meanTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps moyen"])},
          "minTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps min"])},
          "maxTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps max"])},
          "timeStddev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écart type de la durée"])}
        },
        "firstDataMessagePartOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les premières données seront affichées sous peu."])},
        "firstDataMessagePartTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez quelques instants et rafraîchissez la liste pour explorer les performances de vos premières requêtes."])},
        "copyQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier la requête"])}
      }
    }
  })
}
