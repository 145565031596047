import { defineStore } from "pinia";
import { User } from "scalingo/lib/models/auth";

import {
  observePromise,
  useResourceStore,
  toPublicStore,
} from "@/lib/pinia/use-resource-store";
import { availableRegions } from "@/lib/scalingo/client";
import { userHasFlag } from "@/store/user";
import { useSessionStore } from "@/stores/session";

// Note: will eventually replace `src/store/users`.
// Currently only used for account deletion - doesn't contain any data yet.
export const useUserStore = defineStore("user", () => {
  const { client } = useSessionStore();

  const store = useResourceStore({
    fetchPromise: () => client().Users.self(),
  });

  return toPublicStore(store, {
    requestionDeletion() {
      return observePromise(client().Users.requestAccountDeletion());
    },
  });
});

export function currentUser() {
  const userStore = useUserStore();
  const user = userStore.item;

  if (!user) throw new Error("Expected current user to be present");

  return user;
}

/** Until we replace VueX with pinia,
 * we'll sometimes need to wait for the user object to be filled
 * but we have no promise to rely on.
 * This checks for the presence every 200ms and resolves after.
 * Mostly helpful to avoid race/loading conditions when the dashboard is initialized.
 */
export async function waitForCurrentUser() {
  const userStore = useUserStore();

  return new Promise<User>((resolve) => {
    const intervalId = setInterval(() => {
      const user = userStore.item;
      if (user) {
        clearInterval(intervalId);
        resolve(user);
      }
    }, 200);
  });
}

/** Returns a promise that resolves with the regions codes enabled for the current user */
export async function availableRegionCodes(): Promise<string[]> {
  const user = await waitForCurrentUser();
  const codes = [];

  // Only using regions actually available for the user
  for (const region of availableRegions) {
    if (region.requiresFlag) {
      if (userHasFlag(user, region.requiresFlag)) {
        codes.push(region.code);
      }
    } else {
      codes.push(region.code);
    }
  }

  return codes;
}
