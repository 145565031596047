<template>
  <div>
    <CheckboxInputAtom
      v-model="inputValue"
      :label="label"
      :labelClass="labelClass"
      :inputId="inputId"
      :boxType="boxType"
      :disabled="disabled"
    >
    </CheckboxInputAtom>
    <p v-if="errors.length > 0" class="text-sm text-scale-10 mb-4">
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";

export default defineComponent({
  name: "CheckboxInput",
  components: { CheckboxInputAtom },
  props: {
    modelValue: Boolean,
    label: String,
    labelClass: String,
    inputId: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    boxType: {
      type: String,
      default: "square",
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>
