export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "major": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major version upgrade"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database will be stopped, leading to a service interruption. Major upgrades require cold modification of the data model and critical server components."])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation can take from a few minutes to several hours, depending on the size of the database. We recommend avoiding any actions during its execution."])},
          "pgMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the upgrade, we will automatically run the ANALYZE command to optimize indexing and enhance the performance of your database. This process is essential, although it may temporarily impact performance."])},
          "pgConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand that Point-in-Time Recovery (PITR) will create a new timeline for the database, preventing any restoration to a state prior to this operation."])}
        },
        "minor": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor version upgrade"])},
          "description": {
            "notBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current plan, based on a single-node architecture, involves service interruption during minor upgrades. Switch to a Business plan to avoid any interruptions."])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to its multi-node architecture, the Business plan ensures minor updates without any service interruption!"])}
          },
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation should only take a few minutes, regardless of the size of the database. We recommend avoiding any actions during its execution."])}
        },
        "upgrade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upgrade from ", _interpolate(_named("currentVersion")), " to version ", _interpolate(_named("nextVersion"))])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Now"])},
        "error": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please reload the page and try again."])}
        }
      },
      "fr": {
        "major": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de version majeure"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données va être arrêtée, entraînant une interruption de service. Les mises à jour majeures nécessitent une modification à froid du modèle de données et des composants critiques du serveur."])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’opération peut durer de quelques minutes à plusieurs heures, selon la taille de votre base de données. Nous vous conseillons d’éviter toute action pendant son execution."])},
          "pgMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après la mise à jour, nous exécuterons automatiquement la commande ANALYZE pour optimiser l’indexation et accroître les performances de votre base de données. Ce processus est essentiel, bien qu’il puisse temporairement affecter les performances."])},
          "pgConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends que la restauration Point-in-Time (PITR) créera une nouvelle timeline pour la base de données, empêchant toute restauration à un état antérieur à cette opération."])}
        },
        "minor": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de version mineure"])},
          "description": {
            "notBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plan actuel, reposant sur une architecture mono-nœud, implique une interruption de service lors des mises à jour mineures. Optez pour un plan Business afin d’éviter toute interruption."])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grâce à son architecture multi-nœuds, le plan Business assure des mises à jour mineures sans aucune interruption de service !"])}
          },
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’opération devrait durer seulement quelques minutes, indépendamment de la taille de la base de données. Nous vous conseillons d’éviter toute action pendant son execution."])}
        },
        "upgrade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour de la version ", _interpolate(_named("currentVersion")), " à la version ", _interpolate(_named("nextVersion"))])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour maintenant"])},
        "error": {
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez recharger la page et réessayer."])}
        }
      }
    }
  })
}
