export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addCollaboratorSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator added"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new collaborator has been added"])}
        },
        "deleteCollaboratorSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator removed"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A collaborator has been removed"])}
        }
      },
      "fr": {
        "addCollaboratorSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateur ajouté"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau collaborateur a été ajouté"])}
        },
        "deleteCollaboratorSuccess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateur supprimé"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une collaborateur a été supprimé"])}
        }
      }
    }
  })
}
