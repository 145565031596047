<template>
  <div class="py-3 flex" :class="{ 'border-t border-scale-2': rowIndex > 0 }">
    <div class="flex flex-grow pv:items-center flex-col pv:flex-row">
      <div class="flex items-center">
        <div class="pr-2 flex items-center">
          <AddonIcon :addon="addon" :width="22" />
        </div>

        <div class="font-medium">
          {{ addon.addon_provider.name }}
        </div>
      </div>
      <div class="text-scale-5 pl-1">({{ addon.plan.display_name }})</div>
      <div v-if="upgradeAvailable" class="text-scale-5 pl-1 flex items-center">
        <p>-</p>
        <CircleUpGlyph class="ml-1" :width="16" />
        <div class="pl-1">{{ $t("upgradeAvailable") }}</div>
      </div>
    </div>

    <template v-if="isAddonDashboardAvailable(addon)">
      <SCButton
        v-if="addon.status == 'running' && !linkDbDashboard"
        kind="neutral"
        class="ml-3"
        :actions="newDashActions(addon)"
        @click="openLegacyDbDashboard(addon)"
      >
        {{ this.$t("dashboard.default") }}
      </SCButton>

      <SCButton
        v-else-if="addon.status == 'running' && linkDbDashboard"
        kind="neutral"
        class="ml-3"
        :to="openDbDashboard"
        :actions="legacyDashAction(addon)"
        @click="buttonClicked"
        @action="(event) => openLegacyDbDashboard(event.action.addon)"
      >
        {{ this.$t("dashboard.default") }}
      </SCButton>

      <SCButton
        v-else-if="addon.status === 'provisioning'"
        kind="neutral"
        class="ml-3"
        loading
      >
        {{ this.$t("dashboard.default") }}
      </SCButton>
    </template>

    <a ref="addon-link" class="hidden" href="#" target="_blank"></a>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CircleUpGlyph from "@/components/atoms/glyphs/CircleUpGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AddonIcon from "@/components/organisms/addons/Icon.vue";
import { scalingoClient } from "@/lib/scalingo/client";
import { isAddonDashboardAvailable } from "@/lib/utils/addons";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";

export default defineComponent({
  name: "AddonsCard",
  components: {
    AddonIcon,
    SCButton,
    CircleUpGlyph,
  },
  props: {
    app: Object,
    user: Object,
    addon: Object,
    rowIndex: Number,
  },
  emits: ["buttonClicked"],

  computed: {
    upgradeAvailable() {
      return !!this.addon.next_version_id;
    },
    linkDbDashboard() {
      return ["redis", "postgresql", "mysql"].includes(
        this.addon.addon_provider.id,
      );
    },

    openDbDashboard() {
      return {
        name: Routes.App.Db.Root,
        params: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.addon.addon_provider.id,
        },
      };
    },
  },

  methods: {
    isAddonDashboardAvailable,
    legacyDashboardLinkFor(addon) {
      return {
        noRouter: true,
        tagAttrs: { href: addon.sso_url || "#", target: "_blank" },
      };
    },

    newDashActions(addon) {
      if (
        [].includes(addon.addon_provider.id) && // to be able to work easily on future databases
        userIsAdminOrHasFlag(this.user, "employee")
      ) {
        return [
          {
            text: this.$t("dashboard.preview"),
            to: this.openDbDashboard,
          },
        ];
      }
    },

    legacyDashAction(addon) {
      if (this.linkDbDashboard) {
        return [
          {
            text: this.$t("dashboard.legacy"),
            addon: addon,
            icon: "external-link-alt",
          },
        ];
      }
    },

    async openLegacyDbDashboard(addon) {
      let addonResponse = await scalingoClient(
        this.$store,
        this.app.region,
      ).Addons.sso(addon.app_id, addon.id);

      const target = this.$refs["addon-link"];

      target.setAttribute("href", addonResponse.sso_url);
      target.click();
      target.setAttribute("href", "#");
    },
  },
});
</script>

<i18n>
en:
  upgradeAvailable: "Upgrade available"
  dashboard:
    preview: "New version (preview)"
    legacy: "Old dashboard"
    default: "Dashboard"
fr:
  upgradeAvailable: "Mise à jour disponible"
  dashboard:
    preview: "Nouvelle version (preview)"
    legacy: "Ancien dashboard"
    default: "Dashboard"
</i18n>
