export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Window Schedule"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The upcoming maintenance operations will be scheduled during an 8-hour window."])},
        "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a day"])},
        "startingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a starting time"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update schedule"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planification de la fenêtre de maintenance"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les prochaines opérations de maintenances seront planifiées durant une fenêtre de 8 heures."])},
        "selectDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un jour"])},
        "startingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une heure de début"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la planification"])}
      }
    }
  })
}
