<template>
  <span class="relative z-0 inline-flex rounded-md">
    <BaseButton
      v-for="(item, key) in items"
      :key="key"
      type="button"
      :title="item.title"
      :class="classFn(item)"
      :disabled="disabled"
      @click="$emit('selected', item.value)"
    >
      {{ item.text }}
    </BaseButton>
  </span>
</template>

<script>
import { defineComponent } from "vue";

import BaseButton from "@/components/atoms/Button.vue";

export default defineComponent({
  name: "RangeSelectors",
  components: { BaseButton },
  props: {
    size: {
      type: [String, Boolean],
      validator(value) {
        return ["sm", "md", "lg", false].includes(value);
      },
      default: "lg",
    },
    items: Array,
    selectedValue: [String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["selected"],
  methods: {
    classFn(item) {
      let classValue =
        "relative inline-flex items-center border-b border-t border-r border-scale-2 border-gray-300 active:bg-scale-2 bg-scale-0 font-medium text-scale-8 hover:text-scale-5 transition ease-in-out duration-150";

      if (this.isFirst(item)) classValue += " rounded-l-md border-l";
      if (this.isLast(item)) classValue += " rounded-r-md";
      if (this.isSelected(item)) classValue += " bg-scale-1";

      if (this.size == "sm") {
        classValue += " text-sm leading-4 px-2 py-1";
      } else if (this.size == "md") {
        classValue += " text-sm leading-4 px-3 py-2";
      } else {
        classValue += " text-sm leading-5 px-4 py-2";
      }

      return classValue;
    },
    isSelected(item) {
      return (
        this.selectedValue &&
        item.value.toString() === this.selectedValue.toString()
      );
    },
    isFirst(item) {
      return item.value === this.items[0].value;
    },
    isLast(item) {
      return item.value === this.items[this.items.length - 1].value;
    },
  },
});
</script>
