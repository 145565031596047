export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point of contact Health professional"])},
        "warningContact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["As part of the hosting of health data (HDH), you must define and keep up to date a point of contact who is able to designate a health professional if necessary: ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article 10. CLIENT'S commitments"])},
        "actions": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the point of contact"])}
        },
        "buttonName": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update the point of contact"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the point of contact"])}
        },
        "touchButtonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as up to date"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The point of contact information should be reviewed at least every 12 months and updated if necessary."])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last verification or update:"])},
        "nearlyOutdatedBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are hosting HDH applications and this implies a periodic verification of the point of contact health professional information. This check must be carried out before"])},
        "outdatedBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to continue using your HDS apps, you must review and, if necessary, update the point of contact Health professional information."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de contact professionnel de santé"])},
        "warningContact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dans le cadre de l'hébergement de donnée de santé (HDS), vous devez définir, et maintenir à jour, un point de contact étant en mesure de désigner un professionnel de santé lorsque cela est nécessaire : ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article 10. Obligations du Client"])},
        "actions": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le point de contact"])}
        },
        "buttonName": {
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le point de contact"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le point de contact"])}
        },
        "touchButtonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme à jour"])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations relatives au point de contact doivent être vérifiées au moins tous les 12 mois et mises à jour si nécessaire."])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière vérification ou actualisation :"])},
        "nearlyOutdatedBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous hébergez des applications HDS et cela implique une vérification régulière des informations du point de contact professionnel de santé. Cette vérification doit être réalisée avant le"])},
        "outdatedBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de pouvoir continuer à utiliser vos applications HDS, vous devez vérifier, et le cas échéant mettre à jour, les informations du point de contact professionnel de santé."])}
      }
    }
  })
}
