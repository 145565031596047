<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :domain="domain"
    :domainSSLHandler="domainSSLHandler"
    :deleteCertificateHandler="deleteCertificateHandler"
    @submitCertificate="submitCertificate"
    @startCertificateDeletion="startCertificateDeletion"
    @cancelCertificateDeletion="cancelCertificateDeletion"
    @submitCertificateDeletion="submitCertificateDeletion"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/domains/Show.vue";
import { EditDomainSSLHandler } from "@/lib/handlers";
import { ensureDomain, findDomain } from "@/store/domains";

export default defineComponent({
  name: "DomainsShowCtrl",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      domainSSLHandler: null,
      deleteCertificateHandler: null,
    };
  },
  computed: {
    domain() {
      if (this.$route.params.domainId) {
        return findDomain(this.$store, this.$route.params.domainId);
      }
      return null;
    },
  },
  watch: {
    domain: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.domainSSLHandler = new EditDomainSSLHandler(this, newVal);
        }
      },
    },
  },
  beforeMount() {
    ensureDomain(this.$store, this.$route.params.domainId);
  },
  methods: {
    submitCertificate(event) {
      this.domainSSLHandler.submit(event);
    },
    startCertificateDeletion() {
      this.deleteCertificateHandler = new EditDomainSSLHandler(
        this,
        this.domain,
      );
    },
    cancelCertificateDeletion() {
      this.deleteCertificateHandler = null;
    },
    submitCertificateDeletion() {
      this.deleteCertificateHandler.submit({ tlscert: null, tlskey: null });
    },
  },
});
</script>
