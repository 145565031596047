<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.33333 5C3.04467 5 2 6.02196 2 7.28261C2 8.54326 3.04467 9.56522 4.33333 9.56522H4.66667V19.3478C4.66668 19.5208 4.73693 19.6867 4.86195 19.809C4.98697 19.9313 5.15653 20 5.33333 20H18.6667C18.8435 20 19.013 19.9313 19.1381 19.809C19.2631 19.6867 19.3333 19.5208 19.3333 19.3478V9.56522H19.6667C20.9553 9.56522 22 8.54326 22 7.28261C22 6.02196 20.9553 5 19.6667 5H4.33333ZM6 6.30435H18V17.3913H6V6.30435ZM13.2956 8.2596C10.1129 8.2596 9.3615 10.0833 9.1875 11.1294H8.39974V11.8466H9.13672C9.13605 11.885 9.12032 12.173 9.18099 12.565H8.40104V13.2821L9.36979 13.2834C9.74979 14.2832 10.7382 15.4361 13.2956 15.4361C13.7109 15.4354 14.0376 15.4118 14.2669 15.3877V14.133C14.0536 14.1669 13.6948 14.1801 13.3008 14.1801C12.1321 14.1801 11.4907 13.764 11.138 13.2834H13.5339V12.5662H10.8008C10.7028 12.201 10.7096 11.9058 10.7109 11.8491H13.5326V11.1307H10.7995C10.9855 10.4466 11.5576 9.51681 13.3216 9.51681C13.7109 9.51747 14.0068 9.53249 14.2461 9.57031V8.33602C14.0268 8.29363 13.6542 8.2596 13.2956 8.2596Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PaymentAttemptGlyph",
});
</script>
