import { APIError } from "scalingo/lib/errors";
import { SCMIntegration } from "scalingo/lib/models/auth";

import { HostedSCMType } from "@/lib/scm-integrations";
import { Nullable } from "@/lib/utils/types";
import { createSCMIntegration } from "@/store/scm-integrations";

import { FormHandler } from "../form";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  url: string;
  access_token: string;
}

export class CreateSCMIntegrationHandler extends FormHandler<SCMIntegration> {
  errorFieldsMapping = { endpoint: "access_token" };

  get keyPath(): string {
    const path = this.scmType === "github-enterprise" ? "github" : "gitlab";

    return `scmIntegrations.create.${path}`;
  }

  data(): Nullable<FormData> {
    return {
      url: "https://",
      access_token: null,
    };
  }

  constructor(
    component: ComponentPublicInstance,
    readonly scmType: HostedSCMType,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(event: FormData): Promise<void> {
    this.follow(
      await createSCMIntegration(this.$store, {
        ...event,
        scm_type: this.scmType,
      }),
    );
  }

  formatErrors(): void {
    const error = this.operation?.error;

    if (error && error instanceof APIError) {
      if (error.status === 500) {
        this.errors.url = this.errors.url || [];
        this.errors.url.push("cannot be reached");
      }
    }
  }
}
