export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Management"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases of this MySQL instance."])},
        "addDatabase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a database"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des bases de données"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bases de données de cette instance MySQL."])},
        "addDatabase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une base de données"])}
      }
    }
  })
}
