import { Token } from "scalingo/lib/models/auth";

import { ActionHandler } from "@/lib/handlers/action";
import { renewToken } from "@/store/tokens";

import type { ComponentPublicInstance } from "vue";

export class RenewTokenHandler extends ActionHandler<Token> {
  keyPath = "tokens.renew";

  constructor(
    component: ComponentPublicInstance,
    readonly token: Token,
  ) {
    super(component);
  }

  async submit(): Promise<void> {
    this.follow(await renewToken(this.$store, this.token));
  }
}
