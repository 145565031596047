export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deployment of review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new review app for each new pull request."])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "autoDestroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto destroy review apps when"])},
        "allowForkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto deploy review apps from forks"])},
        "allowForkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow automatic deployment of review apps from forks"])},
        "autoDestroyPrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pull request is closed"])},
        "autoDestroyNoEventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deploy or commits events had happened"])},
        "after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["delete instantly"]), _normalize(["delete after 1 hour"]), _normalize(["delete after ", _interpolate(_named("count")), " hours"])])},
        "during": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["during 0 hour"]), _normalize(["during 1 hour"]), _normalize(["during ", _interpolate(_named("count")), " hours"])])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement automatique des review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle review app pour chaque nouvelle pull request."])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
        "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer"])},
        "autoDestroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto détruit les review apps quand"])},
        "allowForkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto déploiement des review apps provenant de forks"])},
        "allowForkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser le déployment automatique de review apps provenant de forks"])},
        "autoDestroyPrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pull request est fermée"])},
        "autoDestroyNoEventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun deploiement ou commits ne s'est passé"])},
        "after": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["supprimer immédiatement"]), _normalize(["supprimer après une 1 heure"]), _normalize(["supprimer après ", _interpolate(_named("count")), " heures"])])},
        "during": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["pendant 0 heure"]), _normalize(["pendant 1 heure"]), _normalize(["pendant ", _interpolate(_named("count")), " heures"])])}
      }
    }
  })
}
