<template>
  <div :class="wrapperClasses" class="flex">
    <div :class="contentClasses" class="container px-4 flex py-3 font-bold">
      <div v-if="$slots.icon" class="flex mr-2">
        <slot name="icon" />
      </div>

      <div :class="textClasses" class="flex-grow flex">
        <slot />
      </div>

      <div
        v-if="dismissible"
        class="flex align-middle h-3 w-3 ml-2 cursor-pointer"
        @click="$emit('dismiss')"
      >
        <CrossGlyph />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";

const BANNER_KIND_INFO = "info";
const BANNER_KIND_WARNING = "warning";
const BANNER_KIND_ERROR = "error";
const BANNER_KIND_SUCCESS = "success";
const BANNER_KIND_ADMIN = "admin";

const BANNER_BG_CLASSES_FOR_KIND = {
  [BANNER_KIND_INFO]: "bg-primary-2-10",
  [BANNER_KIND_WARNING]: "bg-secondary-3-10",
  [BANNER_KIND_ERROR]: "bg-color-2-10",
  [BANNER_KIND_ADMIN]: "bg-secondary-1-10",
  [BANNER_KIND_SUCCESS]: "bg-color-1-10",
};

const BANNER_TEXT_CLASSES_FOR_KIND = {
  [BANNER_KIND_INFO]: "text-primary-2",
  [BANNER_KIND_WARNING]: "text-secondary-3",
  [BANNER_KIND_ERROR]: "text-color-2",
  [BANNER_KIND_ADMIN]: "text-secondary-1",
  [BANNER_KIND_SUCCESS]: "text-color-1",
};

const BANNER_KINDS = Object.keys(BANNER_BG_CLASSES_FOR_KIND);

export default defineComponent({
  name: "SCBanner",
  components: { CrossGlyph },
  props: {
    kind: {
      type: String,
      default: BANNER_KIND_INFO,
      validator(value) {
        return BANNER_KINDS.includes(value);
      },
    },
    align: {
      type: String,
      default: "left",
      validator(value) {
        return ["left", "center", "right"].includes(value);
      },
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dismiss"],
  computed: {
    wrapperClasses() {
      return BANNER_BG_CLASSES_FOR_KIND[this.kind];
    },
    contentClasses() {
      return BANNER_TEXT_CLASSES_FOR_KIND[this.kind];
    },
    textClasses() {
      if (this.align === "left") return "justify-start";
      if (this.align === "center") return "justify-center";
      if (this.align === "right") return "justify-end";

      return "";
    },
  },
});
</script>
