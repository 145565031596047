import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/lib/store/utils";

import { AddonProvidersStore } from "./addon-providers";
import { ContainerSizesStore } from "./container-sizes";
import { EventTypesStore } from "./event-types";
import { NotificationPlatformsStore } from "./notification-platforms";
import { StacksStore } from "./stacks";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RegionState {}

/** A boilerplate store for a singular resource. */
export class RegionStore implements Module<RegionState, RootState> {
  namespaced = true;
  state: RegionState = {};
  actions: ActionTree<RegionState, RootState> = {};
  mutations: MutationTree<RegionState> = {};
  getters: GetterTree<RegionState, RootState> = {};
  modules: ModuleTree<RootState> = {
    addonProviders: new AddonProvidersStore(),
    containerSizes: new ContainerSizesStore(),
    eventTypes: new EventTypesStore(),
    notificationPlatforms: new NotificationPlatformsStore(),
    stacks: new StacksStore(),
  };
}
