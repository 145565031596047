// Please report change in this file into its sibling file: i18nComposition

import { merge } from "lodash";
import { Settings } from "luxon";
import { createI18n } from "vue-i18n";

import appEn from "@/locales/app.en.yml";
import appFr from "@/locales/app.fr.yml";
import attributesEn from "@/locales/attributes.en.yml";
import attributesFr from "@/locales/attributes.fr.yml";
import bannersEn from "@/locales/banners.en.yml";
import bannersFr from "@/locales/banners.fr.yml";
import genericEn from "@/locales/generic.en.yml";
import genericFr from "@/locales/generic.fr.yml";
import helpersEn from "@/locales/helpers.en.yml";
import helpersFr from "@/locales/helpers.fr.yml";
import metricsEn from "@/locales/metrics.en.yml";
import metricsFr from "@/locales/metrics.fr.yml";
import notificationsEn from "@/locales/notifications.en.yml";
import notificationsFr from "@/locales/notifications.fr.yml";
import regionsEn from "@/locales/regions.en.yml";
import regionsFr from "@/locales/regions.fr.yml";

export function loadLocaleMessages() {
  const messages = { en: {}, fr: {} };

  merge(
    messages.en,
    appEn,
    attributesEn,
    genericEn,
    helpersEn,
    metricsEn,
    notificationsEn,
    regionsEn,
    bannersEn,
  );

  merge(
    messages.fr,
    appFr,
    attributesFr,
    genericFr,
    helpersFr,
    metricsFr,
    notificationsFr,
    regionsFr,
    bannersFr,
  );

  return messages;
}

// Setting locales at boot time
const locale = process.env.VUE_APP_I18N_LOCALE || "en";
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";

export const messages = loadLocaleMessages();

export default createI18n({
  legacy: true,
  locale,
  fallbackLocale,
  messages: messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

Settings.defaultLocale = locale || fallbackLocale;
