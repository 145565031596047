export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to scale"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understood"])},
        "labels": {
          "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app's"])},
          "hds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"HDS\" apps'"])}
        },
        "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["configuration prevents its web container from being scaled below ", _interpolate(_named("count")), "."])},
        "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is still possible to scale the app to 0 (and therefore to shut it down)."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de scaler"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai compris"])},
        "labels": {
          "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette app est configurée"])},
          "hds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les apps \"HDS\" sont configurées"])}
        },
        "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["afin que les containers web ne puissent pas être scalés en dessous de ", _interpolate(_named("count")), "."])},
        "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est toujours possible de scaler l'app a 0 (et en conséquence de la stopper)."])}
      }
    }
  })
}
