<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0029 2C9.24067 2 7.00142 4.23858 7.00142 7C7.00142 9.76142 9.24067 12 12.0029 12C14.7652 12 17.0044 9.76142 17.0044 7C17.0044 4.23858 14.7652 2 12.0029 2ZM20.0054 16.76L21.8959 20.55C22.0519 20.8595 22.0365 21.2277 21.8551 21.5231C21.6738 21.8185 21.3524 21.999 21.0057 22H3.00021C2.65351 21.999 2.33208 21.8185 2.15071 21.5231C1.96935 21.2277 1.95392 20.8595 2.10994 20.55L4.00051 16.76C4.84848 15.0683 6.57907 14.0001 8.47186 14H15.534C17.4268 14.0001 19.1574 15.0683 20.0054 16.76Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserGlyph",
});
</script>
