<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.86012 12.0007C10.8459 12.0026 11.7409 11.425 12.1452 10.526C12.5496 9.62688 12.3879 8.57413 11.7324 7.83782C11.0768 7.10152 10.0498 6.81914 9.11002 7.11677C10.1087 8.54894 10.1087 10.4519 9.11002 11.884C9.35266 11.9609 9.60561 12.0002 9.86012 12.0007ZM8.19324 9.50031C8.19324 10.8812 7.07381 12.0006 5.69293 12.0006C4.31204 12.0006 3.19261 10.8812 3.19261 9.50031C3.19261 8.11943 4.31204 7 5.69293 7C7.07381 7 8.19324 8.11943 8.19324 9.50031ZM10.5679 17.4381C10.719 17.1919 10.7319 16.885 10.6019 16.6271L9.81012 15.0435C9.38509 14.2001 8.52102 13.6681 7.5765 13.6684H3.80936C2.87989 13.6836 2.03561 14.2133 1.61742 15.0435L0.783981 16.6271C0.65399 16.885 0.666845 17.1919 0.817955 17.4381C0.969064 17.6843 1.23688 17.8347 1.52574 17.8356H9.86012C10.149 17.8347 10.4168 17.6843 10.5679 17.4381ZM11.7437 13.6684C12.6882 13.6681 13.5523 14.2001 13.9773 15.0435L14.7691 16.6271C14.8991 16.885 14.8862 17.1919 14.7351 17.4381C14.584 17.6843 14.3162 17.8347 14.0273 17.8356H12.2104C12.4426 17.1952 12.4035 16.4878 12.1021 15.877L11.3103 14.3018C11.1967 14.0788 11.0628 13.8668 10.9103 13.6684H11.7437Z"
      fill="currentColor"
    />
    <path
      d="M20 7C17.2386 7 15 9.23857 15 12C15 14.7614 17.2386 17 20 17C22.7614 17 25 14.7614 25 12C25 9.23857 22.7614 7 20 7ZM20 9.14286C20.3943 9.14286 20.7143 9.46214 20.7143 9.85714V11.2857H22.1429C22.5371 11.2857 22.8571 11.605 22.8571 12C22.8571 12.395 22.5371 12.7143 22.1429 12.7143H20.7143V14.1429C20.7143 14.5379 20.3943 14.8571 20 14.8571C19.6057 14.8571 19.2857 14.5379 19.2857 14.1429V12.7143H17.8571C17.4629 12.7143 17.1429 12.395 17.1429 12C17.1429 11.605 17.4629 11.2857 17.8571 11.2857H19.2857V9.85714C19.2857 9.46214 19.6057 9.14286 20 9.14286Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CollaboratorAddGlyph",
});
</script>
