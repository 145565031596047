<template>
  <div v-if="show" :class="bgColor" class="flex rounded">
    <div :class="textColor" class="px-4 flex py-3 font-medium flex-grow">
      <div class="flex flex-col flex-grow">
        <div class="flex-grow flex items-center">
          <div>
            <slot name="text"></slot>
          </div>
          <div class="flex flex-grow">
            <div class="flex flex-grow"></div>
            <div>
              <DangerSignGlyph
                v-if="styling === 'error'"
                class="w-6 h-6"
              ></DangerSignGlyph>
              <WarningSignGlyph
                v-if="styling === 'warning'"
                class="w-6 h-6"
              ></WarningSignGlyph>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";

export default defineComponent({
  name: "FormAlert",
  components: { WarningSignGlyph, DangerSignGlyph },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    styling: {
      type: String,
      default: "warning",
    },
  },
  computed: {
    bgColor: function () {
      if (this.styling === "error") {
        return "bg-color-2-10";
      }
      if (this.styling === "warning") {
        return "bg-secondary-3-10";
      }
      throw "Invalid style: " + this.styling;
    },
    textColor: function () {
      if (this.styling === "error") {
        return "text-color-2";
      }
      if (this.styling === "warning") {
        return "text-secondary-3";
      }
      throw "Invalid style: " + this.styling;
    },
  },
});
</script>
