<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AppEditGlyph class="h-6 w-6 text-scale-6"></AppEditGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      {{ $t("name") }}:
      <span class="font-medium ml-1">{{ event.app_name }}</span>
      <span v-if="event.type_data.force_https" class="ml-1">
        - {{ $t("forceHttp") }}
        <span class="font-medium">{{
          $t(event.type_data.force_https ? "enabled" : "disabled")
        }}</span>
      </span>
      <span v-if="event.type_data.sticky_session" class="ml-1">
        - {{ $t("stickySession") }}
        <span class="font-medium">{{
          $t(event.type_data.sticky_session ? "enabled" : "disabled")
        }}</span>
      </span>
      <span v-if="event.type_data.router_logs" class="ml-1">
        - {{ $t("routerLogs") }}
        <span class="font-medium">{{
          $t(event.type_data.router_logs ? "enabled" : "disabled")
        }}</span>
      </span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AppEditGlyph from "@/components/atoms/glyphs/AppEditGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "EditAppEvent",
  components: { AppEditGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "App edited"
  name: "Name"
  enabled: "enabled"
  disabled: "disabled"
  forceHttp: "Force HTTPS is now"
  stickySession: "Sticky session is now"
  routerLogs: "Router logs are now"
fr:
  title: "App editée"
  name: "Nom"
  enabled: "activée"
  disabled: "désactivée"
  forceHttp: "La fonction forcer HTTPS est maintenant"
  stickySession: "La fonction sessions persistantes est maintenant"
  routerLogs: "La fonction logs routeur est maintenant"
</i18n>
