<template>
  <ViewComponent
    :db="db"
    :app="app"
    :forceTLSInfo="forceTLSInfo"
    :internetAccessInfo="internetAccessInfo"
    :forceTLSPending="forceTLSPending"
    :internetAccessPending="internetAccessPending"
    :forceTLSEnabled="forceTLSEnabled"
    :internetAccessEnabled="internetAccessEnabled"
    @downloadCaCertificate="downloadCaCertificate"
    @disableForceTLS="disableForceTLS"
    @enableForceTLS="enableForceTLS"
    @disableInternetAccess="disableInternetAccess"
    @enableInternetAccess="enableInternetAccess"
  ></ViewComponent>
</template>

<script>
import { ref, computed, defineComponent, watch } from "vue";

import ViewComponent from "@/components/views/db/settings/InternetAccess.vue";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { dbFeatureIs } from "@/lib/scalingo/dbapi/database";
import { i18nT } from "@/lib/utils/i18n";
import { useCurrentAppStore } from "@/stores/current/app";
import {
  useCurrentDBStore,
  currentDB,
  currentDBClient,
} from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "InternetAccess",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const db = currentDB();
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const forceTLSInfo = ref(voidPromiseInfo());
    const internetAccessInfo = ref(voidPromiseInfo());
    const forceTLSPending = computed(() => {
      return (
        forceTLSInfo.value.isLoading ||
        dbFeatureIs(db, "force-ssl", "PENDING_TO_REMOVE") ||
        dbFeatureIs(db, "force-ssl", "PENDING_TO_ACTIVATE")
      );
    });

    const internetAccessPending = computed(() => {
      return (
        internetAccessInfo.value.isLoading ||
        dbFeatureIs(db, "publicly-available", "PENDING_TO_REMOVE") ||
        dbFeatureIs(db, "publicly-available", "PENDING_TO_ACTIVATE")
      );
    });

    const forceTLSEnabled = computed(() =>
      dbFeatureIs(db, "force-ssl", "ACTIVATED"),
    );
    const internetAccessEnabled = computed(() =>
      dbFeatureIs(db, "publicly-available", "ACTIVATED"),
    );

    watch(forceTLSPending, (newVal) => {
      if (newVal) return;

      if (forceTLSEnabled.value) {
        toastsStore.addOne({
          type: "success",
          title: t("forceTlsEnabled.success.title"),
          message: t("forceTlsEnabled.success.message"),
        });
      } else if (!forceTLSEnabled.value) {
        toastsStore.addOne({
          type: "success",
          title: t("forceTlsDisabled.success.title"),
          message: t("forceTlsDisabled.success.message"),
        });
      }
    });

    watch(internetAccessPending, (newVal) => {
      if (newVal) return;

      if (forceTLSEnabled.value) {
        toastsStore.addOne({
          type: "success",
          title: t("internetAccessEnabled.success.title"),
          message: t("internetAccessEnabled.success.message"),
        });
      } else if (!forceTLSEnabled.value) {
        toastsStore.addOne({
          type: "success",
          title: t("internetAccessDisabled.success.title"),
          message: t("internetAccessDisabled.success.message"),
        });
      }
    });

    async function downloadCaCertificate() {
      const client = await currentDBClient();
      return client.Database.downloadCaCertificate();
    }

    async function enableForceTLS() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(db.id, "force-ssl").then(
        currentDbStore.refreshDBFeatures,
      );

      forceTLSInfo.value = promiseInfo(promise);
      try {
        await forceTLSInfo.value.promise;
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("forceTlsEnabled.error.title"),
          message: t("forceTlsEnabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function disableForceTLS() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(db.id, "force-ssl").then(
        currentDbStore.refreshDBFeatures,
      );

      forceTLSInfo.value = promiseInfo(promise);

      try {
        await forceTLSInfo.value.promise;
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("forceTlsDisabled.error.title"),
          message: t("forceTlsDisabled.error.message", { err: e.data.error }),
        });
      }
    }

    async function enableInternetAccess() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.enableFeature(
        db.id,
        "publicly-available",
      ).then(currentDbStore.refreshDBFeatures);

      internetAccessInfo.value = promiseInfo(promise);

      try {
        await internetAccessInfo.value.promise;
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("internetAccessEnabled.error.title"),
          message: t("internetAccessEnabled.error.message", {
            err: e.data.error,
          }),
        });
      }
    }

    async function disableInternetAccess() {
      const client = await currentDBClient();
      const db = currentDB();

      const promise = client.Database.disableFeature(
        db.id,
        "publicly-available",
      ).then(currentDbStore.refreshDBFeatures);

      internetAccessInfo.value = promiseInfo(promise);

      try {
        await internetAccessInfo.value.promise;
      } catch (e) {
        toastsStore.addOne({
          type: "error",
          title: t("internetAccessDisabled.error.title"),
          message: t("internetAccessDisabled.error.message", {
            err: e.data.error,
          }),
        });
      }
    }

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
      enableForceTLS,
      disableForceTLS,
      enableInternetAccess,
      disableInternetAccess,
      forceTLSInfo,
      internetAccessInfo,
      downloadCaCertificate,
      forceTLSPending,
      internetAccessPending,
      forceTLSEnabled,
      internetAccessEnabled,
    };
  },
});
</script>

<i18n>
en:
  forceTlsEnabled:
    success:
      title: "Force TLS enabled"
      message: "Your database is now only accessible via an encrypted connection."
    error:
      title: "Force TLS activation failure"
      message: "The activation of Force TLS option failed: {err}"
  forceTlsDisabled:
    success:
      title: "Force TLS disabled"
      message: "Your database is now accessible via both encrypted and unencrypted connections."
    error:
      title: "Force TLS deactivate failure"
      message: "The deactivate of Force TLS option failed: {err}"
  internetAccessEnabled:
    success:
      title: "Internet Access being activated"
      message: "Internet Access option is in process of activation."
    error:
      title: "Internet Access activation failure"
      message: "The activation of Internet Access option failed: {err}"
  internetAccessDisabled:
    success:
      title: "Internet Access being deactivated"
      message: "Internet Access option is in process of deactivation."
    error:
      title: "Internet Access deactivate failure"
      message: "The deactivate of Internet Access option failed: {err}"
fr:
  forceTlsEnabled:
    success:
      title: "Force TLS activé"
      message: "Votre base de données est désormais uniquement accessible via une connexion chiffrée."
    error:
      title: "Échec de l'activation de l'option Force TLS"
      message: "L'activation de l'option Force TLS a échoué: {err}"
  forceTlsDisabled:
    success:
      title: "Force TLS désactivé"
      message: "Votre base de données est désormais accessible via des connexions chiffrées et non chiffrées."
    error:
      title: "Échec de a désactivation de Force TLS"
      message: "La désactivation de l'option Force TLS a échoué: {err}"
  internetAccessEnabled:
    success:
      title: "Internet Access en cours d'activation"
      message: "L'option Internet Access est en cours d'activation."
    error:
      title: "Échec de l'activation de l'option Internet Access"
      message: "L'activation de l'option Internet Access a échoué: {err}"
  internetAccessDisabled:
    success:
      title: "Internet Access en cours de désactivation"
      message: "L'option Internet Access est en cours de désactivation."
    error:
      title: "Échec de a désactivation de Internet Access"
      message: "La désactivation de l'option Internet Access a échoué: {err}"
</i18n>
