export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale to 0 container"])},
        "button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scale '", _interpolate(_named("type")), "' to 0 container"])},
        "message_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you decide to scale your web containers to 0, the app will become inactive"])},
        "message_others": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you decide to scale '", _interpolate(_named("type")), "' resource type to 0 containers, the '", _interpolate(_named("type")), "' process will become inactive"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale à 0 container"])},
        "button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scale '", _interpolate(_named("type")), "' à 0 container"])},
        "message_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous décidez de scaler vos containers web à 0, l'app deviendra inactive"])},
        "message_others": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous décidez de scaler vos containers '", _interpolate(_named("type")), "' à 0 containers, le processus '", _interpolate(_named("type")), "' deviendra inactif"])}
      }
    }
  })
}
