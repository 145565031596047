export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add domain"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add domain to your app"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter domaine"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un domaine à votre app"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
      }
    }
  })
}
