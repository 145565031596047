<template>
  <li
    role="option"
    :class="{ 'bg-scale-1': selected }"
    :title="appInfo.name"
    class="text-scale-10 select-none relative py-2 px-3 hover:bg-scale-2 cursor-pointer"
    @click="$emit('selected')"
  >
    <span
      class="font-normal truncate flex flex-col pv:flex-row items-center h-12 pv:h-6"
    >
      <span class="flex flex-row">
        <AppStatusCircle :status="appInfo.status"></AppStatusCircle>
        <span class="text-scale-4">{{ appInfo.owner.username }}</span>
        <span class="text-scale-4 mx-1">/</span>
      </span>
      <span class="flex flex-row items-center">
        <span>{{ firstPart }}</span>
        <span class="font-bold">{{ userQuery }}</span>
        <span>{{ secondPart }}</span>

        <font-awesome-icon
          v-if="displayHDS"
          icon="staff-snake"
          class="ml-2 -mt-1 h-4 text-success"
          :title="$t('hdsResource')"
        />

        <Region
          v-if="displayFlag"
          class="ml-2 -mt-1"
          :regionCode="appInfo.region"
        />
      </span>
    </span>
  </li>
</template>

<script>
import { defineComponent } from "vue";

import Region from "@/components/organisms/app/Region.vue";
import AppStatusCircle from "@/components/parts/home/overview/AppStatusCircle.vue";
import { defaultRegion } from "@/lib/scalingo/client";

export default defineComponent({
  name: "TopInputItem",
  components: { AppStatusCircle, Region },
  props: {
    selected: Boolean,
    userQuery: String,
    appInfo: Object,
  },
  emits: ["selected"],
  computed: {
    firstPart() {
      return this.appInfo.name.split(this.userQuery)[0];
    },
    secondPart() {
      let split = this.appInfo.name.split(this.userQuery);
      split.shift();
      return split.join(this.userQuery);
    },
    displayHDS() {
      return this.appInfo.hds_resource;
    },
    displayFlag() {
      return this.appInfo.region !== defaultRegion.code;
    },
  },
});
</script>

<i18n>
en:
  hdsResource: "HDS application"
fr:
  hdsResource: "Application norme HDS"
</i18n>
