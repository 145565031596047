export const AccountRoutes = {
  Basic: "AccountBasic",
  Auth: "AccountAuth",
  Keys: "AccountKeys",
  Tokens: "AccountTokens",
  Contracts: "AccountContracts",
  Integrations: "AccountIntegrations",
  HDS: "AccountHds",
  Deletion: "AccountDeletion",
  Referrals: "AccountReferrals",
  Features: "AccountFeatures",
};
