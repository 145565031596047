export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual deployment of review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy review apps from pull requests related to your app repository and forks."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy review app"])},
        "refreshPulls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh pull requests"])},
        "titles": {
          "forked_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a fork from the linked repository, authored by a user outside of your organization."])}
        },
        "headers": {
          "repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])},
          "reviewApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review app"])},
          "pullRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull Request"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement manuel de review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déployez des review apps à partir des pull requests liées au dépôt de l'app et à ses forks."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déployer la review app"])},
        "refreshPulls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraichir les pull requests"])},
        "titles": {
          "forked_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un fork du dépôt lié, créé par un utilisateur à l'extérieur de votre organisation."])}
        },
        "headers": {
          "repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
          "reviewApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review app"])},
          "pullRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull Request"])}
        }
      }
    }
  })
}
