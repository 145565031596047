export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": {
          "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
        },
        "duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["none"]), _normalize(["1 minute"]), _normalize([_interpolate(_named("count")), " minutes"])])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. VAT"])},
        "future_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€0 (free until May 1st)"])}
      },
      "fr": {
        "label": {
          "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
        },
        "duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucune"]), _normalize(["1 minute"]), _normalize([_interpolate(_named("count")), " minutes"])])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimée"])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. TVA"])},
        "future_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0€ (offert jusqu’au 1er mai)"])}
      }
    }
  })
}
