export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your addon before provisioning"])},
        "billingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing profile"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a payment method"])},
        "actions": {
          "provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provision addon"])},
          "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm plan change"])},
          "editProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change addon provider"])},
          "editPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose another plan"])}
        },
        "errors": {
          "providerNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Either there is no '", _interpolate(_named("addon")), "' addon, or it is already added to your app."])},
          "planNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is no plan '", _interpolate(_named("plan")), "' for ", _interpolate(_named("addon"))])},
          "paymentRequired": {
            "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must end the free trial to use a paid addon. Please fill your "])},
            "andAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and add "])},
            "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(the owner) must end the free trial to use a paid addon."])}
          }
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier le provisionnement de votre addon"])},
        "billingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil de facturation"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un moyen de paiement"])},
        "actions": {
          "provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionner l'addon"])},
          "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comfirmer le changement de plan"])},
          "editProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un autre type d'addon"])},
          "editPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un autre plan"])}
        },
        "errors": {
          "providerNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Soit il n'y a pas '", _interpolate(_named("addon")), "' addon, soit il a déjà été ajouté à votre application."])},
          "planNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il n'y a pas de '", _interpolate(_named("plan")), "' pour ", _interpolate(_named("addon"))])},
          "paymentRequired": {
            "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez mettre fin à l'essai gratuit pour provisionner un addon payant. Veuillez remplir votre "])},
            "andAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et ajouter "])},
            "collaborator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(le⋅la propriétaire) doit mettre fin à l'essai gratuit pour provisionner un addon payant."])}
          }
        }
      }
    }
  })
}
