<template>
  <ConfirmModal
    :immediate="true"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-lg"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <div class="text-scale-8">
      <div>{{ $t("text", { max: dbPlan.item.manual_backups_count }) }}</div>
      <div v-if="planIsStarter" class="space-x-1 mt-4">
        <span>{{ $t("upsellText") }}</span>
        <router-link :to="changePlanRouterArgs" class="underline"
          >{{ $t("upsellLink") }}
        </router-link>
      </div>
    </div>
    <template #confirmText>{{ $t("buttonName") }}</template>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ConfirmMakeManualBackupModal",
  components: {
    ConfirmModal,
  },
  props: {
    dbPlan: Object,
    app: Object,
    addon: Object,
  },
  emits: ["close", "confirm"],
  computed: {
    planIsStarter() {
      return this.dbPlan.item.manual_backups_count === 10;
    },
    changePlanRouterArgs() {
      return {
        name: Routes.App.Addon.Edition.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          addonId: this.addon.id,
        },
      };
    },
  },
});
</script>

<i18n>
en:
  title: "Manual backup limit"
  text: "You have reached the limit of backups allocated by your plan ({max}). Running a new backup will automatically delete the oldest backup. This does not affect scheduled backups."
  upsellText: "Do you want to go from 10 to 50 manual backup?"
  upsellLink: "Upgrade to a Business plan."
  buttonName: "Make a new manual backup"
fr:
  titre : "Limite des backups manuels"
  text : "Vous avez atteint la limite de backup allouée par votre plan ({max}). L'exécution d'un nouveau backup supprimera automatiquement le plus ancien. Cela n'affecte pas les backups planifiés."
  upsellText : "Voulez-vous passer de 10 à 50 backups manuels ?"
  upsellLink : "Passez à un plan Business."
  buttonName : "Effectuer un nouveau backup manuel"
</i18n>
