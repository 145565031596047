import { uniqBy, uniq } from "lodash";
import {
  STATUS_NEW,
  STATUS_STOPPED,
  STATUS_RUNNING,
  STATUS_CRASHED,
  STATUS_RESTARTING,
  STATUS_SCALING,
  STATUS_BOOTING,
} from "scalingo/lib/models/regional/apps";

import { AppInfoOwner } from "@/lib/scalingo/app-infos";
import { AppInfo } from "@/lib/scalingo/modifiers/apps";
import {
  addTextToOption,
  Filter,
  FilterFn,
  FilterDefinition,
} from "@/lib/utils/filters";

// App Type
const APP_TYPE_FILTER: Filter<AppInfo> = {
  key: "type",
  options: [
    {
      value: "all",
      i18nKey: "generic.all",
      check: null,
      default: true,
      humanize: true,
    },
    {
      value: "onlyParentsApps",
      check: (app: AppInfo): boolean => !app._isReviewApp,
    },
    {
      value: "onlyReviewApps",
      check: (app: AppInfo): boolean => app._isReviewApp,
    },
  ],
};

APP_TYPE_FILTER.options.forEach((option) => {
  addTextToOption(option, "attributes.app.types");
});

export function getAppTypeFilters(): FilterDefinition<AppInfo> {
  return {
    filter: APP_TYPE_FILTER,
    default: APP_TYPE_FILTER.options.find((item) => item.default),
  };
}

// App Status
function checkFor(status: string): FilterFn<AppInfo> {
  return (app: AppInfo) => {
    return app.status === status;
  };
}

export const APP_STATUS_FILTER: Filter<AppInfo> = {
  key: "status",
  options: [
    {
      value: "all",
      i18nKey: "generic.allFemale",
      check: null,
      default: true,
      humanize: true,
    },
    { value: STATUS_NEW, check: checkFor(STATUS_NEW) },
    { value: STATUS_STOPPED, check: checkFor(STATUS_STOPPED) },
    { value: STATUS_RUNNING, check: checkFor(STATUS_RUNNING) },
    { value: STATUS_CRASHED, check: checkFor(STATUS_CRASHED) },
    { value: STATUS_RESTARTING, check: checkFor(STATUS_RESTARTING) },
    { value: STATUS_SCALING, check: checkFor(STATUS_SCALING) },
    { value: STATUS_BOOTING, check: checkFor(STATUS_BOOTING) },
  ],
};

APP_STATUS_FILTER.options.forEach((option) => {
  addTextToOption(option, "attributes.app.statuses");
});

export function getAppStatusFilters(): FilterDefinition<AppInfo> {
  return {
    filter: APP_STATUS_FILTER,
    default: APP_STATUS_FILTER.options.find((item) => item.default),
  };
}

// App Owner
function ownersForAppInfos(appInfos: AppInfo[]): AppInfoOwner[] {
  return uniqBy(
    appInfos.map((appInfo) => appInfo.owner),
    "id",
  );
}

export function getAppOwnerFilters(
  appInfos: AppInfo[] | null,
): FilterDefinition<AppInfo> {
  const allOption = {
    value: "all",
    i18nKey: "generic.all",
    check: null,
    default: true,
    humanize: true,
  };

  addTextToOption(allOption);

  const filter: Filter<AppInfo> = {
    key: "owner",
    options: [],
  };

  const owners = ownersForAppInfos(appInfos ?? []);

  if (owners.length === 1) {
    const owner = owners[0];

    filter.disabled = true;
    filter.options.push({
      value: owner.username,
      text: owner.username,
      check: null,
      default: true,
    });
  } else {
    filter.options.push(allOption);
    owners.forEach((owner) => {
      filter.options.push({
        value: owner.username,
        text: owner.username,
        check: (appInfo) => appInfo.owner.username === owner.username,
      });
    });
  }

  return {
    filter,
    default: filter.options.find((item) => item.default),
  };
}

// App regions
function regionsForAppInfos(appInfos: AppInfo[]): string[] {
  return uniq(appInfos.map((appInfo) => appInfo.region));
}

export function getAppRegionsFilters(
  appInfos: AppInfo[] | null,
): FilterDefinition<AppInfo> {
  const allOption = {
    value: "all",
    i18nKey: "generic.allFemale",
    check: null,
    default: true,
    humanize: true,
  };

  addTextToOption(allOption);

  const filter: Filter<AppInfo> = {
    key: "region",
    options: [],
  };

  const regions = regionsForAppInfos(appInfos ?? []);

  if (regions.length === 1) {
    const region = regions[0];

    filter.disabled = true;
    filter.options.push({
      value: region,
      text: region,
      check: null,
      default: true,
    });
  } else {
    filter.options.push(allOption);
    regions.forEach((region) => {
      filter.options.push({
        value: region,
        text: region,
        check: (appInfo) => appInfo.region === region,
      });
    });
  }

  return {
    filter,
    default: filter.options.find((item) => item.default),
  };
}
