<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 12H19.85L19.77 6.49C19.7595 6.22 19.5402 6.00509 19.27 6H18.72C18.4498 6.00509 18.2305 6.22 18.22 6.49L18.14 12H5.9L5.77 2.49C5.75945 2.22 5.54015 2.00509 5.27 2H4.72C4.44985 2.00509 4.23055 2.22 4.22 2.49L4.09 12H3C2.44772 12 2 12.4477 2 13V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V13C22 12.4477 21.5523 12 21 12ZM10 16.5C10 16.7761 9.77614 17 9.5 17H4.5C4.22386 17 4 16.7761 4 16.5V15.5C4 15.2239 4.22386 15 4.5 15H9.5C9.77614 15 10 15.2239 10 15.5V16.5ZM19.5 17C19.7761 17 20 16.7761 20 16.5V15.5C20 15.2239 19.7761 15 19.5 15H18.5C18.2239 15 18 15.2239 18 15.5V16.5C18 16.7761 18.2239 17 18.5 17H19.5Z"
      fill="currentColor"
    />
    <path
      d="M24 8C24 11.3137 21.3137 14 18 14C14.6863 14 12 11.3137 12 8C12 4.68629 14.6863 2 18 2C21.3137 2 24 4.68629 24 8Z"
      fill="white"
    />
    <path
      d="M18 3C15.2386 3 13 5.23857 13 8C13 10.7614 15.2386 13 18 13C20.7614 13 23 10.7614 23 8C23 5.23857 20.7614 3 18 3ZM18 5.14286C18.3943 5.14286 18.7143 5.46214 18.7143 5.85714V7.28571H20.1429C20.5371 7.28571 20.8571 7.605 20.8571 8C20.8571 8.395 20.5371 8.71429 20.1429 8.71429H18.7143V10.1429C18.7143 10.5379 18.3943 10.8571 18 10.8571C17.6057 10.8571 17.2857 10.5379 17.2857 10.1429V8.71429H15.8571C15.4629 8.71429 15.1429 8.395 15.1429 8C15.1429 7.605 15.4629 7.28571 15.8571 7.28571H17.2857V5.85714C17.2857 5.46214 17.6057 5.14286 18 5.14286Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogDrainAddGlyph",
});
</script>
