<template>
  <div class="w-full text-center">
    <EmptyCardState>
      <template #icon>
        <Chart class="h-8"></Chart>
      </template>
      <span class="text-lg font-bold text-scale-9">
        {{ $t("title") }}
      </span>
    </EmptyCardState>
    <p class="md:w-1/2 mx-auto font-medium text-scale-6 mt-2">
      {{ $t("subtitle") }}
    </p>
    <div class="row text-center mt-4">
      <SCButton
        kind="primary"
        size="md"
        class="mx-1"
        :loading="fakeLoader"
        @click="
          setLoadingButton();
          pgActivateQueryStatistics();
        "
      >
        {{ $t("enable") }}
      </SCButton>

      <a
        href="https://doc.scalingo.com/databases/postgresql/troubleshooting#identifying-performances-issues"
        target="_blank"
      >
        <SCButton size="md" class="mx-1">
          {{ $t("more") }}
          <font-awesome-icon class="ml-1" icon="external-link-alt" />
        </SCButton>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Chart from "@/components/atoms/glyphs/Chart.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";

export default defineComponent({
  name: "ActivateQueryStatistics",
  components: {
    SCButton,
    Chart,
    EmptyCardState,
  },
  props: {
    pgActivateQueryStatistics: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fakeLoader: false,
    };
  },
  methods: {
    setLoadingButton() {
      this.fakeLoader = true;
      setTimeout(() => {
        this.fakeLoader = false;
      }, 500);
    },
  },
});
</script>

<i18n>
  en:
    title: "Enable query statistics"
    subtitle: "Enhance the efficiency of your queries by analyzing their performance! Gain detailed usage statistics to identify and optimize the most resource-intensive processes."
    enable: "Enable"
    more: "Learn more"
  fr:
    title: "Activer les statistiques de requêtes"
    subtitle: "Améliorez l’efficacité de vos requêtes en analysant leurs performances ! Obtenez des statistiques d’usage détaillées, vous permettant d’identifier et d’optimiser les processus les plus gourmands en ressources."
    enable: "Activer"
    more: "En savoir plus"
</i18n>
