export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorize data access"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Scalingo operators to access your application"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent"])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to databases metadata and monitoring data"])},
        "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to application runtime environment"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration:"])},
        "durations": {
          "1Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hour"])},
          "24Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 hours"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser l'accès aux données"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les opérateurs Scalingo à accéder à l'application"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentir"])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès aux métadonnées et aux données de surveillance des bases de données"])},
        "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à l'environnement d'exécution de l'application"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée :"])},
        "durations": {
          "1Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 heure"])},
          "24Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 heures"])}
        }
      }
    }
  })
}
