<template>
  <apexchart
    ref="apex"
    type="line"
    :options="chartOptions"
    :series="chartData"
    height="300px"
  />
</template>

<script>
import { defineComponent } from "vue";

import Common from "@/components/organisms/apex/Common.vue";

export default defineComponent({
  name: "ResponseTimeGraph",
  mixins: [Common],
  computed: {
    title() {
      return "Response Time";
    },
    chartOptions() {
      return {
        ...this.chartDefaults(),
        colors: [
          "rgba(24,59,238,1)",
          "rgba(3, 169, 244)",
          "rgba(126, 206, 244,1)",
        ],
        fill: {
          type: "solid",
          colors: [
            "rgba(79,106,242,0.2)",
            "rgba(3, 169, 244, 0.2)",
            "rgba(126, 206, 244, 0.2)",
          ],
        },
        yaxis: {
          labels: {
            minWidth: 60,
            maxWidth: 60,
            formatter: (val) => `${Math.round(val)} ms`,
          },
        },
      };
    },
  },
});
</script>
