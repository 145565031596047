import { Autoscaler } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { destroyAutoscaler } from "@/store/autoscalers";

import type { ComponentPublicInstance } from "vue";

export class DeleteAutoscalerHandler extends ActionHandler<Autoscaler> {
  keyPath = "autoscaler.delete";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly autoscaler: Autoscaler,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", {
        container: this.autoscaler.container_type,
      });
    });

    this.on("failure", () => {
      this.notify("error", {
        container: this.autoscaler.container_type,
      });
    });
  }

  async submit(): Promise<void> {
    this.follow(await destroyAutoscaler(this.$store, this.autoscaler));
  }
}
