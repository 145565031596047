export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Topology"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Topology details"])},
        "headers": {
          "privateIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private IP"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
        },
        "itemStatus": {
          "booting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booting"])},
          "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
          "restarting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restarting"])},
          "migrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["migrating"])},
          "upgrading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upgrading"])},
          "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stopped"])},
          "removing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["removing"])},
          "unreachable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unreachable"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topologie du cluster"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la topologie du cluster"])},
        "headers": {
          "privateIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP privée"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
        },
        "itemStatus": {
          "booting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["démarrage"])},
          "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en cours d'exécution"])},
          "restarting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redémarrage"])},
          "migrating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["migration"])},
          "upgrading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mise à niveau"])},
          "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrêté"])},
          "removing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suppression"])},
          "unreachable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["injoignable"])}
        }
      }
    }
  })
}
