<template>
  <div class="w-48">
    <router-link
      :to="routeArgs"
      class="text-sm font-medium pt-2 uppercase hover:underline tracking-wide flex text-scale-5 lg:justify-end"
    >
      {{ $t("header") }}
    </router-link>
    <router-link
      v-if="noCollaborators"
      :to="routeArgs"
      class="flex text-scale-6 lg:justify-end hover:underline"
    >
      {{ $t("noCollaborator") }}
    </router-link>
    <div v-else class="w-full flex space-x-2 items-center lg:justify-end">
      <router-link class="mt-1" :to="routeArgs">
        <CollaboratorPortrait
          v-for="(collaborator, key) in collaboratorsSorted"
          :key="key"
          :item="collaborator"
          :isOwner="false"
        ></CollaboratorPortrait>
      </router-link>
      <span
        v-if="hasMoreCollaborators"
        class="text-xs text-scale-5 leading-5 font-medium ml-2"
        >+ {{ remainingCollaborators }}</span
      >
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import CollaboratorPortrait from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorPortrait.vue";
import { Routes } from "@/router/names";

const COLLABORATORS_DISPLAYED = 5;

export default defineComponent({
  name: "InlinedCollaborators",
  components: {
    CollaboratorPortrait,
  },
  props: {
    collaborators: Object,
    app: Object,
  },
  data() {
    return {
      routeArgs: {
        name: Routes.App.Collaborators,
        args: { id: this.app.name },
      },
    };
  },
  computed: {
    collaboratorsSorted() {
      return this.collaborators.items.slice(0, COLLABORATORS_DISPLAYED);
    },
    remainingCollaborators() {
      return this.collaborators.items.length - this.collaboratorsSorted.length;
    },
    hasMoreCollaborators() {
      return this.collaborators.items.length > COLLABORATORS_DISPLAYED;
    },
    noCollaborators() {
      return !this.collaborators.items.length;
    },
  },
});
</script>

<i18n>
en:
  header: "Collaborators"
  noCollaborator: "No collaborator yet"
fr:
  header: "Collaborateurs"
  noCollaborator: "Aucun collaborateur"
</i18n>
