<template>
  <main v-if="container">
    <DividedSectionTitle
      :title="$t('title', { name: container.name })"
      :subtitle="$t('subtitle')"
    >
      <DateSelector
        :selectedValue="since"
        @rangeSelected="(e) => $emit('rangeSelected', e)"
      />
    </DividedSectionTitle>

    <BackLinkButton class="my-2" :routeArgs="metricsOverviewRoute">
      {{ $t("back") }}
    </BackLinkButton>

    <div
      v-for="(rawData, index) in containersRawData"
      :key="index"
      class="flex flex-col pv:flex-row mt-4"
    >
      <CpuCard
        class="pv:w-1/2 pv:mr-2"
        :container="container"
        :containerSizes="containerSizes"
        :promiseInfo="containersInfos[index].allCpu"
        :cpuData="rawData.cpu"
        :index="index + 1"
      ></CpuCard>

      <MemoryCard
        class="pv:ml-2 pv:w-1/2 mt-4 pv:mt-0"
        :container="container"
        :containerSizes="containerSizes"
        :promiseInfo="containersInfos[index].allMemory"
        :memoryData="rawData.memory"
        :swapData="rawData.swap"
        :index="index + 1"
      ></MemoryCard>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import DividedSectionTitle from "@/components/atoms/titles/DividedSectionTitle.vue";
import CpuCard from "@/components/parts/app/showMetrics/CpuCard.vue";
import DateSelector from "@/components/parts/app/showMetrics/DateSelector.vue";
import MemoryCard from "@/components/parts/app/showMetrics/MemoryCard.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "MetricsPerContainer",
  components: {
    DateSelector,
    DividedSectionTitle,
    BackLinkButton,
    MemoryCard,
    CpuCard,
  },
  props: {
    app: Object,
    user: Object,
    container: Object,
    containerSizes: Object,
    since: [String, Number],
    containersInfos: Array,
    containersRawData: Array,
  },
  emits: ["rangeSelected"],
  data: function () {
    return {
      metricsOverviewRoute: {
        name: Routes.App.Metrics.Root,
      },
    };
  },
  computed: {
    containerNames() {
      return Object.keys(this.containersRawData);
    },
  },
});
</script>

<i18n>
  en:
    title: "Metrics per container ({name})"
    subtitle: "Key indicators of performance and consumption for each individual container."
    back: "Application metrics"
  fr:
    title: "Métriques par conteneur ({name})"
    subtitle: "Indicateurs clés de performance et de consommation pour chaque conteneur."
    back: "Métriques de l'application"
</i18n>
