export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual deployment of a branch"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger a manual deployment from a branch of the app repository."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger deployment"])},
        "refreshBranches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh branches"])},
        "reviewAppDisclaimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This review app is linked to the repo <span class='font-bold'>", _interpolate(_named("repo")), "</span> on the branch <span class='font-bold'>", _interpolate(_named("branch")), "</span>. Only this branch can be deployed."])},
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement manuel d'une branche"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclenchez un déploiement manuel à partir d'une branche du dépôt de l'app."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer le déploiement"])},
        "refreshBranches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraichir les branches"])},
        "reviewAppDisclaimer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette review app est liée au dépot <span class='font-bold'>", _interpolate(_named("repo")), "</span> sur la branche <span class='font-bold'>", _interpolate(_named("branch")), "</span>. Seule cette branche peut être déployée."])},
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])}
        }
      }
    }
  })
}
