<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6H2C0.89543 6 0 6.89543 0 8V18C0 19.1046 0.89543 20 2 20H12C13.1046 20 14 19.1046 14 18V14H18C19.1046 14 20 13.1046 20 12V2C20 0.89543 19.1046 0 18 0H8C6.89543 0 6 0.89543 6 2V6ZM12 18H2V8H6V12C6 13.1046 6.89543 14 8 14H12V18Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScaleGlyph",
});
</script>
