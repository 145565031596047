<template>
  <section>
    <DbVersionCard
      :dbVersion="dbVersion"
      :dbName="db.type_name"
      :upgradeCtx="upgradeCtx"
      @cancelDbUpgrade="$emit('cancelDbUpgrade')"
      @confirmDbUpgrade="$emit('confirmDbUpgrade')"
      @startDbUpgrade="$emit('startDbUpgrade')"
    />
    <DbPlanCard :app="app" :addon="addon" :dbPlan="dbPlan" class="mt-6" />
    <DbDeleteCard
      class="mt-6"
      @startAddonDeletion="$emit('startAddonDeletion')"
    />
  </section>
  <ConfirmAddonDeletion
    v-if="deleteAddonCtx"
    :context="deleteAddonCtx"
    @confirm="(e) => $emit('confirmAddonDeletion', e)"
    @close="$emit('cancelAddonDeletion')"
  />
</template>

<script>
import { defineComponent } from "vue";

import ConfirmAddonDeletion from "@/components/organisms/modals/ConfirmAddonDeletion.vue";
import DbDeleteCard from "@/components/parts/dbSettings/general/DbDeleteCard.vue";
import DbPlanCard from "@/components/parts/dbSettings/general/DbPlanCard.vue";
import DbVersionCard from "@/components/parts/dbSettings/general/DbVersionCard.vue";

export default defineComponent({
  name: "General",
  components: { DbVersionCard, DbPlanCard, DbDeleteCard, ConfirmAddonDeletion },
  props: {
    db: Object,
    dbVersion: Object,
    dbPlan: Object,
    deleteAddonCtx: Object,
    upgradeCtx: Object,
    app: Object,
    addon: Object,
  },
  emits: [
    "cancelAddonDeletion",
    "confirmAddonDeletion",
    "startAddonDeletion",
    "cancelDbUpgrade",
    "confirmDbUpgrade",
    "startDbUpgrade",
  ],
});
</script>
