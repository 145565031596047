export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "actions": {
          "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
          "orDragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])}
        }
      },
      "fr": {
        "actions": {
          "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner un fichier"])},
          "orDragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou bien glisser-déposer"])}
        }
      }
    }
  })
}
