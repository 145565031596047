<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6667 4.66689V5.33356C13.6667 5.51766 13.5174 5.66689 13.3333 5.66689H2.55333L5.52 8.62689C5.5831 8.68948 5.61859 8.77468 5.61859 8.86356C5.61859 8.95244 5.5831 9.03764 5.52 9.10023L5.04666 9.56689C4.98407 9.63 4.89887 9.66549 4.81 9.66549C4.72112 9.66549 4.63592 9.63 4.57333 9.56689L0.479995 5.48023C0.3862 5.38655 0.333445 5.25946 0.333328 5.12689V4.87356C0.334864 4.74128 0.387406 4.61471 0.479995 4.52023L4.57333 0.43356C4.63592 0.370456 4.72112 0.334961 4.81 0.334961C4.89887 0.334961 4.98407 0.370456 5.04666 0.43356L5.52 0.906894C5.5827 0.968336 5.61804 1.05243 5.61804 1.14023C5.61804 1.22802 5.5827 1.31212 5.52 1.37356L2.55333 4.33356H13.3333C13.5174 4.33356 13.6667 4.4828 13.6667 4.66689Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArrowLeftGlyph",
});
</script>
