<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      :transform="transform"
      fill-rule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Chevron",
  props: {
    direction: {
      type: String,
      validator(value) {
        return ["up", "down"].includes(value);
      },
      default: "down",
    },
  },
  computed: {
    transform() {
      switch (this.direction) {
        case "up":
          return "rotate(-180 10.0063 9.99373)";
        case "down":
          return null;
      }

      return null;
    },
  },
});
</script>
