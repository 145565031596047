import { APIError } from "scalingo/lib/errors";
import { App } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/apps";

import { formatAppCreationErrors } from "@/lib/handlers/create/app";
import { FormHandler } from "@/lib/handlers/form";
import { defaultRegion, scalingoClient } from "@/lib/scalingo/client";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { Nullable } from "@/lib/utils/types";

type AppCreateParams = CreateParams & { region: string };

export class CreateAppDryRunHandler extends FormHandler<App> {
  keyPath = "app.create";

  data(): Nullable<AppCreateParams> {
    return {
      name: null,
      region: defaultRegion.code,
    };
  }

  async submit(event: AppCreateParams): Promise<void> {
    const promise = scalingoClient(this.$store, event.region).Apps.create({
      name: event.name,
      git_source: event.git_source,
      dry_run: true,
    });

    const operation = new RemoteOperation<App>().follow(promise);

    this.follow(operation);
  }

  formatErrors(): void {
    const error = this.operation?.error;

    if (error && error instanceof APIError) {
      this.errors.$codes.push(...formatAppCreationErrors(error));
    }
  }
}
