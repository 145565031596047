import { Token } from "scalingo/lib/models/auth";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { createToken } from "@/store/tokens";

export interface FormData {
  name: string;
}

export class CreateTokenHandler extends FormHandler<Token> {
  keyPath = "tokens.create";

  data(): Nullable<FormData> {
    return {
      name: null,
    };
  }

  dispatchEvents(): void {
    this.on("failure", () => this.notify("error"));
  }

  async submit(form: FormData): Promise<void> {
    this.follow(await createToken(this.$store, form));
  }
}
