<template>
  <ViewComponent
    :availableModes="availableModes"
    :updateModesCtx="updateModesCtx"
    :configurationList="configurationList"
    :updateMysqlConfigModal="updateMysqlConfigModal"
    @updateConfigurationList="updateConfigurationList"
    @closedConfigurationModal="closedConfigurationModal"
    @openConfigurationModal="openConfigurationModal"
  />
</template>

<script>
import { ref, defineComponent, onBeforeMount, nextTick } from "vue";

import ViewComponent from "@/components/parts/dbSettings/configuration/MysqlConfiguration.vue";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { currentDB, currentDBClient } from "@/stores/current/db";
import { useDbVersionStore } from "@/stores/db/version";
import { useToastsStore } from "@/stores/toasts";

const mysql8ValidVars = {
  ALLOW_INVALID_DATES: false,
  ANSI_QUOTES: false,
  ERROR_FOR_DIVISION_BY_ZERO: false,
  HIGH_NOT_PRECEDENCE: false,
  IGNORE_SPACE: false,
  NO_AUTO_VALUE_ON_ZERO: false,
  NO_BACKSLASH_ESCAPES: false,
  NO_DIR_IN_CREATE: false,
  NO_ENGINE_SUBSTITUTION: false,
  NO_UNSIGNED_SUBTRACTION: false,
  NO_ZERO_DATE: false,
  NO_ZERO_IN_DATE: false,
  ONLY_FULL_GROUP_BY: false,
  PAD_CHAR_TO_FULL_LENGTH: false,
  PIPES_AS_CONCAT: false,
  REAL_AS_FLOAT: false,
  STRICT_ALL_TABLES: false,
  STRICT_TRANS_TABLES: false,
  TIME_TRUNCATE_FRACTIONAL: false,
};

const mysql5_7ValidVars = {
  ALLOW_INVALID_DATES: false,
  ANSI_QUOTES: false,
  ERROR_FOR_DIVISION_BY_ZERO: false,
  HIGH_NOT_PRECEDENCE: false,
  IGNORE_SPACE: false,
  NO_AUTO_CREATE_USER: false,
  NO_AUTO_VALUE_ON_ZERO: false,
  NO_BACKSLASH_ESCAPES: false,
  NO_DIR_IN_CREATE: false,
  NO_ENGINE_SUBSTITUTION: false,
  NO_FIELD_OPTIONS: false,
  NO_KEY_OPTIONS: false,
  NO_TABLE_OPTIONS: false,
  NO_UNSIGNED_SUBTRACTION: false,
  NO_ZERO_DATE: false,
  NO_ZERO_IN_DATE: false,
  ONLY_FULL_GROUP_BY: false,
  PAD_CHAR_TO_FULL_LENGTH: false,
  PIPES_AS_CONCAT: false,
  REAL_AS_FLOAT: false,
  STRICT_ALL_TABLES: false,
  STRICT_TRANS_TABLES: false,
};

export default defineComponent({
  name: "MysqlConfiguration",
  components: { ViewComponent },
  setup() {
    const availableModes = ref([]);
    const configurationList = ref([]);
    const toastsStore = useToastsStore();
    const dbVersionStore = useDbVersionStore();
    const updateModesCtx = ref(null);
    const t = i18nT();

    onBeforeMount(() => {
      dbVersionStore.ensure();
    });

    function updateConfigurationList() {
      setConfigurationList();
    }

    function openConfigurationModal() {
      updateModesCtx.value = { info: voidPromiseInfo() };
    }
    function closedConfigurationModal() {
      updateModesCtx.value = null;
    }

    function convertArrayOfModesToString() {
      let newArr = [];

      for (const [key, value] of Object.entries(availableModes.value)) {
        if (value) {
          newArr.push(key);
        }
      }
      return newArr.toString();
    }

    async function setConfigurationList() {
      const client = await currentDBClient();
      const db = currentDB();

      updateModesCtx.value.error = null;
      try {
        const promise = client.Database.mysqlSetExtensions(
          db.id,
          convertArrayOfModesToString(),
        );
        updateModesCtx.value.info = promiseInfo(promise);

        await promise;
        await getConfigurationList();

        toastsStore.addOne({
          type: "success",
          title: t("setConfiguration.success.title"),
          message: t("setConfiguration.success.message"),
        });
        updateModesCtx.value = null;
      } catch (e) {
        updateModesCtx.value.error = t("setConfiguration.error.message");
      }
    }

    async function getConfigurationList() {
      const client = await currentDBClient();
      const db = currentDB();
      const { result } = await client.Database.mysqlListExtensions(db.id);

      configurationList.value =
        result.length > 0 ? result.split(",").sort() : [];
      configurationList.value.forEach((element) => {
        availableModes.value[element] = true;
      });
    }

    async function getAvailableConfiguration() {
      await dbVersionStore.promiseInfo.promise;
      await nextTick();

      switch (dbVersionStore.item.major) {
        case 8:
          availableModes.value = mysql8ValidVars;
          break;
        case 5:
          availableModes.value = mysql5_7ValidVars;
          break;
      }

      getConfigurationList();
    }

    getAvailableConfiguration();

    return {
      availableModes: availableModes,
      configurationList: configurationList,
      updateConfigurationList: updateConfigurationList,
      updateModesCtx: updateModesCtx,
      openConfigurationModal: openConfigurationModal,
      closedConfigurationModal: closedConfigurationModal,
    };
  },
});
</script>

<i18n>
  en:
    setConfiguration:
      success:
        title: "Configuration Updated"
        message: "Configuration as been updated successfully."
      error:
        message: "An error occurred. Please reload the page and try again."

  fr:
    setConfiguration:
      success:
        title: "Configuration mise à jour"
        message: "La configuration a été mise à jour avec succès."
      error:
        message: "Une erreur est survenue. Veuillez recharger la page et réessayer."
  </i18n>
