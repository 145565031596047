export const AppAddonsRoutes = {
  Creation: {
    Root: "AppAddonCreationRoot",
    Step1: "AppAddonCreationStep1",
    Step2: "AppAddonCreationStep2",
    Step3: "AppAddonCreationStep3",
  },
  Edition: {
    Root: "AppAddonEditionRoot",
    Step2: "AppAddonEditionStep2",
    Step3: "AppAddonEditionStep3",
  },
};
