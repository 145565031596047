export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point of contact Health professional"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HDH Reference System requires Scalingo and the owner of an account hosting Health Data (you) to define a point of contact who is able to designate a health professional when necessary."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update the point of contact"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de contact professionnel de santé"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le référentiel HDS impose, à Scalingo et au propriétaire d'un compte hébergeant des données de Santé (vous), de définir un point de contact étant en mesure de désigner un professionnel de santé lorsque cela est nécessaire."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le point de contact"])}
      }
    }
  })
}
