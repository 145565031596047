export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "global": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Memory – ", _interpolate(_named("formation"))])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum memory consumption across containers of type ", _interpolate(_named("name")), "."])}
        },
        "perContainer": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Memory – ", _interpolate(_named("formation"))])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Memory consumption of container ", _interpolate(_named("formation")), "."])}
        },
        "actions": {
          "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["See metrics for individual ", _interpolate(_named("type")), " containers"])}
        }
      },
      "fr": {
        "global": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mémoire – ", _interpolate(_named("formation"))])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Consommation mémoire maximale des conteneurs de type ", _interpolate(_named("name")), "."])}
        },
        "perContainer": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mémoire – ", _interpolate(_named("formation"))])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Consommation mémoire du conteneur ", _interpolate(_named("formation")), "."])}
        },
        "actions": {
          "details": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voir les métriques pour chaque conteneur ", _interpolate(_named("type")), "."])}
        }
      }
    }
  })
}
