export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region migration starting"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region with name"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration de région lancée"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vers la région"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec le nom"])}
      }
    }
  })
}
