<template>
  <svg
    width="200"
    height="104"
    viewBox="0 0 200 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.15">
      <path
        d="M0.237427 91.5091L31.5423 109.575L62.851 91.5091L32.0313 73L0.237427 91.5091Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.877567"
        d="M0.237671 141.76V91.5308L31.5319 109.574V159.803"
        fill="url(#paint1_linear)"
      />
      <path
        opacity="0.679688"
        d="M31.5312 109.574L62.8255 91.5308V141.76L31.5312 159.803V109.574Z"
        fill="url(#paint2_linear)"
      />
    </g>
    <g opacity="0.15">
      <path
        d="M68.7078 55.5743L100.013 73.7035L131.321 55.5743L100.502 37L68.7078 55.5743Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.877567"
        d="M68.7075 145.153V55.5967L100.002 73.7037V163.259L68.7075 145.153Z"
        fill="url(#paint4_linear)"
      />
      <path
        opacity="0.679688"
        d="M100.001 73.7037L131.295 55.5967V145.153L100.001 163.26V73.7037Z"
        fill="url(#paint5_linear)"
      />
    </g>
    <g opacity="0.15">
      <path
        d="M137.178 18.5672L168.483 36.6895L199.791 18.5672L168.972 0L137.178 18.5672Z"
        fill="url(#paint6_linear)"
      />
      <path
        opacity="0.877567"
        d="M137.178 142.356V18.5898L168.472 36.69V160.455"
        fill="url(#paint7_linear)"
      />
      <path
        opacity="0.679688"
        d="M168.472 36.69L199.766 18.5898V142.356L168.472 160.456V36.69Z"
        fill="url(#paint8_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0.237427"
        y1="73"
        x2="0.237427"
        y2="109.575"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0.237671"
        y1="91.5308"
        x2="0.237671"
        y2="159.803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="31.5312"
        y1="91.5308"
        x2="31.5312"
        y2="159.803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="68.7078"
        y1="37"
        x2="68.7078"
        y2="73.7035"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="68.7075"
        y1="55.5967"
        x2="68.7075"
        y2="163.259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="100.001"
        y1="55.5967"
        x2="100.001"
        y2="163.26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="137.178"
        y1="0"
        x2="137.178"
        y2="36.6895"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="137.178"
        y1="18.5898"
        x2="137.178"
        y2="160.455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="168.472"
        y1="18.5898"
        x2="168.472"
        y2="160.456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.991668" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ColumnsIllustration",
});
</script>
