export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit variable"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the value of an environment variable"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une variable"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la valeur d'une variable d'environemment"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre a jour"])}
      }
    }
  })
}
