<template>
  <section>
    <template v-if="scmRepoLink.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-if="scmRepoLink.latestFetch.isSuccess">
      <DeploymentsViaCard
        :user="user"
        :scmRepoLink="scmRepoLink.value"
        :repoLinkOperation="repoLinkOperation"
        :deleteSCMRepoLinkHandler="deleteSCMRepoLinkHandler"
        :editSCMRepoLinkerHandler="editSCMRepoLinkerHandler"
        @unlinkRepository="$emit('unlinkRepository')"
        @changeLinker="$emit('changeLinker')"
      ></DeploymentsViaCard>
      <AutomaticDeploymentCard
        class="mt-8"
        :repoLinkOperation="repoLinkOperation"
        :scmRepoLink="scmRepoLink.value"
        :scmBranches="scmBranches"
        :listBranchesInfo="listBranchesInfo"
        @disableAutoDeploy="$emit('disableAutoDeploy')"
        @enableAutoDeploy="(e) => $emit('enableAutoDeploy', e)"
        @fetchScmRepoLinkBranches="$emit('fetchScmRepoLinkBranches')"
      ></AutomaticDeploymentCard>
    </template>
    <template v-if="scmRepoLink.latestFetch.isError">
      <Card>
        <template v-slot:header>
          <CardHeader
            :title="$t('title')"
            :subtitle="$t('subtitle')"
          ></CardHeader>
        </template>
        <template v-slot:body>
          <DeployCartridges
            class="mt-4"
            :app="app"
            :scmIntegrations="scmIntegrations"
            :selectedCartridge="currentCartridge"
            :currentSCMIntegration="currentSCMIntegration"
            :scmOrgs="scmOrgs"
            :scmRepos="scmRepos"
            :scmBranches="scmBranches"
            :connectIntegrationHandler="connectIntegrationHandler"
            :repoLinkOperation="repoLinkOperation"
            :listOrgsInfo="listOrgsInfo"
            :listReposInfo="listReposInfo"
            :listBranchesInfo="listBranchesInfo"
            @cartridgeSelected="cartridgeSelected"
            @connectSCMIntegration="connectSCMIntegration"
            @startHostedSCMConnection="
              (e) => $emit('startHostedSCMConnection', e)
            "
            @cancelHostedSCMConnection="$emit('cancelHostedSCMConnection')"
            @confirmHostedSCMConnection="
              (e) => $emit('confirmHostedSCMConnection', e)
            "
            @startSCMLink="startSCMLink"
            @stopSCMLink="$emit('stopSCMLink')"
            @unlinkRepo="$emit('unlinkRepo')"
            @scmOrgSelected="(eventArgs) => $emit('scmOrgSelected', eventArgs)"
            @scmRepoSearched="(e) => $emit('scmRepoSearched', e)"
            @scmRepoSelected="
              (eventArgs) => $emit('scmRepoSelected', eventArgs)
            "
            @scmBranchSelected="
              (eventArgs) => $emit('scmBranchSelected', eventArgs)
            "
            @finishSCMRepoLink="
              (eventArgs) => $emit('finishSCMRepoLink', eventArgs)
            "
          ></DeployCartridges>
        </template>
      </Card>
    </template>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AutomaticDeploymentCard from "@/components/parts/app/showDeployConfiguration/AutomaticDeploymentCard.vue";
import DeploymentsViaCard from "@/components/parts/app/showDeployConfiguration/DeploymentsViaCard.vue";
import DeployCartridges from "@/components/parts/configure/app/DeployCartridges.vue";

export default defineComponent({
  name: "DeployConfiguration",
  components: {
    AutomaticDeploymentCard,
    DeploymentsViaCard,
    Card,
    CardHeader,
    DeployCartridges,
    LoadingCardState,
  },
  props: {
    app: Object,
    user: Object,
    scmRepoLink: Object,
    scmIntegrations: Object,
    currentCartridge: String,
    currentSCMIntegration: Object,
    scmOrgs: Array,
    scmRepos: Array,
    scmBranches: Array,
    connectIntegrationHandler: Object,
    repoLinkOperation: Object,
    deleteSCMRepoLinkHandler: Object,
    editSCMRepoLinkerHandler: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "cancelHostedSCMConnection",
    "cartridgeSelected",
    "changeLinker",
    "confirmHostedSCMConnection",
    "connectSCMIntegration",
    "disableAutoDeploy",
    "enableAutoDeploy",
    "fetchScmRepoLinkBranches",
    "finishSCMRepoLink",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "startHostedSCMConnection",
    "startSCMLink",
    "stopSCMLink",
    "unlinkRepo",
    "unlinkRepository",
  ],
  data() {
    return {};
  },
  computed: {},
  methods: {
    cartridgeSelected(eventArgs) {
      this.$emit("cartridgeSelected", eventArgs);
    },
    connectSCMIntegration(eventArgs) {
      this.$emit("connectSCMIntegration", eventArgs);
    },
    startSCMLink(eventArgs) {
      this.$emit("startSCMLink", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Configuration"
  subtitle: "Configure the deployment process"
fr:
  title: "Configuration"
  subtitle: "Configurer le processus de déploiement"
</i18n>
