<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 6.51C17.9993 5.71462 17.6828 4.95207 17.12 4.39L13.61 0.88C13.0479 0.31723 12.2854 0.000700208 11.49 0H6.49C5.69462 0.000700208 4.93207 0.31723 4.37 0.88L0.88 4.39C0.31723 4.95207 0.000700208 5.71462 0 6.51V11.51C0.000700208 12.3054 0.31723 13.0679 0.88 13.63L4.39 17.14C4.95533 17.6954 5.71748 18.0046 6.51 18H11.51C12.3054 17.9993 13.0679 17.6828 13.63 17.12L17.14 13.61C17.6954 13.0447 18.0046 12.2825 18 11.49V6.51ZM10 12.51C10 12.7861 9.77614 13.01 9.5 13.01H8.5C8.22386 13.01 8 12.7861 8 12.51V11.51C8 11.2339 8.22386 11.01 8.5 11.01H9.5C9.77614 11.01 10 11.2339 10 11.51V12.51ZM9.53 9.01C9.65716 9.01092 9.76474 8.91624 9.78 8.79L10.18 5.57C10.1972 5.42735 10.1523 5.28417 10.0566 5.17698C9.96088 5.06979 9.82369 5.00896 9.68 5.01H8.32C8.17631 5.00896 8.03912 5.06979 7.94342 5.17698C7.84771 5.28417 7.80275 5.42735 7.82 5.57L8.22 8.79C8.23526 8.91624 8.34284 9.01092 8.47 9.01H9.53Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "WarningSignGlyph",
});
</script>
