export function readFile(file: File): Promise<string> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent) => {
      if (!e.target) {
        reject();
        return;
      }

      const target = e.target as FileReader;
      const content = target.result as string; // because `readAsText` is used

      if (!content) {
        reject();
        return;
      }

      resolve(content);
    };

    reader.readAsText(file);
  });
}
