import { Router, RouteLocationNormalized } from "vue-router";

import { scalingoClient } from "@/lib/scalingo/client";
import { ApplicationStore } from "@/store";
import { useToastsStore } from "@/stores/toasts";

import type { VueI18n } from "vue-i18n";

export type SaasSCMType = "github" | "gitlab";
export type HostedSCMType = "github-enterprise" | "gitlab-self-hosted";

export const ERROR_ALREADY_LINKED = "already_linked";
export const ERROR_LINKED_TO_ANOTHER_ACCOUNT = "linked_to_another_account";
export const KNOWN_ERRORS = [
  ERROR_ALREADY_LINKED,
  ERROR_LINKED_TO_ANOTHER_ACCOUNT,
];

export function integrationLinkUrl(
  store: ApplicationStore,
  type: "github" | "gitlab",
  redirectUrl: string,
): string {
  const client = scalingoClient(store);
  const authUrl = client._authApiUrl;

  return `${authUrl}/users/${type}/link?callback_url=${redirectUrl}`;
}

export function performQuickOAuthLink(
  store: ApplicationStore,
  scmType: "github" | "gitlab",
  callbackPath: string,
): void {
  const redirectUri = `${window.location.protocol}//${window.location.host}${callbackPath}/link-${scmType}`;

  const integLinkUri = integrationLinkUrl(store, scmType, redirectUri);

  window.location.assign(integLinkUri);
}

export function handlingLinkingFeedback(
  router: Router,
  route: RouteLocationNormalized,
  store: ApplicationStore,
  i18n: VueI18n,
): void {
  const { query } = route;

  if (query.success === undefined) return;
  if (query.scmType === undefined) return;

  const rootKey = "notifications.handlers.scmIntegrations.link";

  const toastsStore = useToastsStore();

  if (query.success === "true") {
    toastsStore.addOne({
      type: "success",
      title: i18n.t(`${rootKey}.${query.scmType}.success.title`).toString(),
      message: i18n.t(`${rootKey}.${query.scmType}.success.message`).toString(),
    });
  } else {
    const error = KNOWN_ERRORS.includes(query.message as string)
      ? query.message
      : "error";

    toastsStore.addOne({
      type: "error",
      title: i18n.t(`${rootKey}.${query.scmType}.${error}.title`).toString(),
      message: i18n
        .t(`${rootKey}.${query.scmType}.${error}.message`)
        .toString(),
    });
  }

  router.push({
    query: {},
  });
}
