export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi! You are hosting HDH applications and this implies a periodic verification of the ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["point of contact health professional information."])},
        "info2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This check must be carried out before ", _interpolate(_named("date"))])}
      },
      "fr": {
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ! Vous hébergez des applications HDS et cela implique une vérification régulière des ", _interpolate(_named("link"))])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informations du point de contact professionnel de santé."])},
        "info2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette vérification doit être réalisée avant le ", _interpolate(_named("date"))])}
      }
    }
  })
}
