<template>
  <BaseModal
    panelClasses="sm:w-full sm:max-w-lg"
    displayCloseButton
    @close="$emit('close')"
  >
    <template v-slot:title>
      {{ $t("title", { name: user.name }) }}
    </template>

    <div class="mt-6 text-sm font-medium leading-5 h-6 text-scale-10 humanize">
      {{ $t("generatedPassword") }}
    </div>
    <div
      class="flex flex-col sm:flex-row justify-between items-center space-x-2"
    >
      <div
        class="font-mono bg-scale-1 p-2.5 pv:whitespace-nowrap overflow-x-auto mt-2 flex justify-between items-baseline w-full"
      >
        {{ user.password }}
      </div>

      <div class="mt-2.5">
        <CopyButton @click="copyCommand(user.password)" />
      </div>
    </div>
    <div class="mt-4">
      <SCAlert kind="warning" :title="$t('warning')" :withIcon="true">
        <template v-slot:icon>
          <DangerSignGlyph />
        </template>
      </SCAlert>
    </div>
  </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import BaseModal from "@/components/molecules/modals/BaseModal.vue";
import CopyButton from "@/components/organisms/buttons/CopyButton.vue";
import { copyToClipboard } from "@/lib/clipboard";

export default defineComponent({
  name: "PasswordCopyModal",
  components: {
    BaseModal,
    CopyButton,
    SCAlert,
    DangerSignGlyph,
  },
  props: { user: Object },
  emits: ["close"],
  methods: {
    copyCommand(password) {
      copyToClipboard(password);
    },
  },
});
</script>

<i18n>
en:
  title: "User {name} password"
  generatedPassword: "Generated password"
  warning: "The generated password is displayed only once. Please copy it and keep it in a safe place."
fr:
  title: "Mot de passe de l'utilisateur {name}"
  generatedPassword: "Mot de passe généré"
  warning: "Le mot de passe généré n'est affiché qu'une seule fois. Veuillez le copier et le conserver en lieu sûr."
</i18n>
