import {
  Response,
  decodeToken,
  readHash,
  Middleware,
  shouldRefreshToken,
  ROUTE_PATH,
  getAndSaveUtms,
} from "@/lib/auth/utils";

// Possible errors
const OAUTH_CALLBACK_NO_TOKEN = { error: "oauth-callback/no-token" };
const OAUTH_CALLBACK_UNREADABLE = { error: "oauth-callback/unreadable" };
const OAUTH_CALLBACK_EXPIRED = { error: "oauth-callback/expired" };

export class LoginFromOAuthCallback implements Middleware {
  name = "LoginFromOAuthCallback";

  call(): Promise<Response> {
    const readFromHash = readHash(window.location.hash);

    return new Promise((resolve, reject) => {
      getAndSaveUtms();
      // error is expected to be `login_required`, as defined in auth-service, config/initializers/doorkeeper.rb
      if (readFromHash.error == "login_required") {
        reject({ error: readFromHash.error });
        return;
      }

      if (!readFromHash.access_token) {
        reject(OAUTH_CALLBACK_NO_TOKEN);
        return;
      }

      try {
        const decoded = decodeToken(readFromHash.access_token);

        if (shouldRefreshToken(decoded.payload)) {
          reject(OAUTH_CALLBACK_EXPIRED);
          return;
        }

        const attemptedRoute = sessionStorage.getItem(ROUTE_PATH);

        if (attemptedRoute) {
          decoded.attemptedRoute = attemptedRoute;
          sessionStorage.removeItem(ROUTE_PATH);
        }

        resolve(decoded);
      } catch (e) {
        reject({ ...OAUTH_CALLBACK_UNREADABLE, exception: e });
      } finally {
        window.location.hash = "";
      }
    });
  }
}
