<template>
  <SCTable
    :headers="headers"
    :items="notifiers.items"
    :loading="notifiers.latestFetch.isLoading"
    :perPage="4"
  >
    <template #item.enabled="{ item }">
      <SmallToggle
        :modelValue="item.active"
        :title="item.active ? $t('actions.disable') : $t('actions.enable')"
        @update:modelValue="
          $emit('updateNotifier', { notifier: item, active: !item.active })
        "
      />
    </template>

    <template #item.name="{ item }">
      <div class="flex">
        <span
          class="text-scale-7 self-center -ml-2"
          :title="$ta('notifier', `types.${item.type}`)"
        >
          <SVGPlatformEmail v-if="item.type === 'email'" class="h-4" />
          <SVGPlatformSlack v-if="item.type === 'slack'" class="h-4" />
          <SVGPlatformWebhook v-if="item.type === 'webhook'" class="h-4" />
          <SVGPlatformRocketChat
            v-if="item.type === 'rocket_chat'"
            class="h-4"
          />
        </span>
        <router-link :to="routeFor(item)">{{ item.name }}</router-link>
      </div>
    </template>
    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <SCTableAction
          :title="$t('actions.edit')"
          :rowIndex="index"
          @click="editNotifier(item)"
        >
          <EditGlyph class="h-4 w-4" />
        </SCTableAction>

        <SCTableAction
          :title="$t('actions.delete')"
          type="delete"
          class="ml-2"
          :rowIndex="index"
          @click="$emit('startNotifierDeletion', { notifier: item })"
        >
          <BinGlyph class="h-4 w-4" />
        </SCTableAction>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import EditGlyph from "@/components/atoms/glyphs/EditGlyph.vue";
import SVGPlatformEmail from "@/components/graphics/platforms/Email.vue";
import SVGPlatformRocketChat from "@/components/graphics/platforms/RocketChat.vue";
import SVGPlatformSlack from "@/components/graphics/platforms/Slack.vue";
import SVGPlatformWebhook from "@/components/graphics/platforms/Webhook.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "NotifiersList",
  components: {
    SCTable,
    BinGlyph,
    EditGlyph,
    SCTableAction,
    SmallToggle,
    SVGPlatformEmail,
    SVGPlatformSlack,
    SVGPlatformWebhook,
    SVGPlatformRocketChat,
  },
  mixins: [ModelsTranslation],
  props: {
    notifiers: Object,
    app: Object,
  },
  emits: ["startNotifierDeletion", "updateNotifier"],
  data() {
    return {
      headers: [
        {
          value: "enabled",
          text: "",
          class: "w-2",
        },
        { value: "name", text: this.$i18n.t("headers.name") },
        { value: "actions", text: "", align: "right" },
      ],
    };
  },
  methods: {
    routeFor(notifier) {
      return {
        name: Routes.App.Notifiers.Show,
        params: {
          id: this.app.name,
          region: this.app.region,
          notifierId: notifier.id,
        },
      };
    },
    editNotifier(notifier) {
      this.$router.push({
        name: Routes.App.Notifiers.Edit.Infos,
        params: {
          id: this.app.name,
          region: this.app.region,
          notifierId: notifier.id,
        },
      });
    },
  },
});
</script>

<i18n>
en:
  headers:
    name: "Name"
  actions:
    delete: "Delete"
    disable: "Disable"
    edit: "Edit"
    enable: "Enable"
fr:
  headers:
    name: "Nom"
  actions:
    delete: "Supprimer"
    disable: "Désactiver"
    edit: "Modifier"
    enable: "Activer"
</i18n>
