export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
          "encryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password encryption"])},
          "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
        },
        "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protected user"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["write"])},
        "actions": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])}
        }
      },
      "fr": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
          "encryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffrement du mot de passe"])},
          "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
        },
        "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur protégé"])},
        "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["écriture"])},
        "actions": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
          "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])}
        }
      }
    }
  })
}
