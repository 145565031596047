<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="`0 0 32 32`"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="a"
        d="m45.536 38.764c-2.013 1.05-12.44 5.337-14.66 6.494s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276c-1-.478-1.524-.88-1.524-1.26v-3.813s14.447-3.145 16.78-3.982 3.14-.867 5.126-.14 13.853 2.868 15.814 3.587v3.76c0 .377-.452.8-1.477 1.324z"
      />
      <path
        id="b"
        d="m45.536 28.733c-2.013 1.05-12.44 5.337-14.66 6.494s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276-2.04-1.613-.077-2.382l15.332-5.935c2.332-.837 3.14-.867 5.126-.14s12.35 4.853 14.312 5.57 2.037 1.31.024 2.36z"
      />
    </defs>
    <g transform="matrix(.848327 0 0 .848327 -7.883573 -9.449691)">
      <use fill="#a41e11" xlink:href="#a" />
      <path
        d="m45.536 34.95c-2.013 1.05-12.44 5.337-14.66 6.494s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276-2.04-1.613-.077-2.382l15.332-5.936c2.332-.836 3.14-.867 5.126-.14s12.35 4.852 14.31 5.582 2.037 1.31.024 2.36z"
        fill="#d82c20"
      />
      <use fill="#a41e11" xlink:href="#a" y="-6.218" />
      <use fill="#d82c20" xlink:href="#b" />
      <path
        d="m45.536 26.098c-2.013 1.05-12.44 5.337-14.66 6.495s-3.453 1.146-5.207.308-12.85-5.32-14.85-6.276c-1-.478-1.524-.88-1.524-1.26v-3.815s14.447-3.145 16.78-3.982 3.14-.867 5.126-.14 13.853 2.868 15.814 3.587v3.76c0 .377-.452.8-1.477 1.324z"
        fill="#a41e11"
      />
      <use fill="#d82c20" xlink:href="#b" y="-6.449" />
      <g fill="#fff">
        <path
          d="m29.096 20.712-1.182-1.965-3.774-.34 2.816-1.016-.845-1.56 2.636 1.03 2.486-.814-.672 1.612 2.534.95-3.268.34zm-6.296 3.912 8.74-1.342-2.64 3.872z"
        />
        <ellipse cx="20.444" cy="21.402" rx="4.672" ry="1.811" />
      </g>
      <path d="m42.132 21.138-5.17 2.042-.004-4.087z" fill="#7a0c00" />
      <path d="m36.963 23.18-.56.22-5.166-2.042 5.723-2.264z" fill="#ad2115" />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

import { addonProviderIconWidth } from "@/config/icons";

export default defineComponent({
  name: "Redis",
  props: {
    width: {
      type: [Number, String],
      default: addonProviderIconWidth,
    },
  },
  computed: {
    height() {
      return this.width;
    },
  },
});
</script>
