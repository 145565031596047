import { noop } from "lodash";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import { useResourceStore } from "@/lib/pinia/use-resource-store";
import { clearPollingFor, setupPolling } from "@/lib/pinia/utils/polling";
import {
  promiseInfo as promiseInfoFn,
  voidPromiseInfo,
} from "@/lib/promises/info";
import { scalingoDBClient } from "@/lib/scalingo/dbapi/client";
import { Db } from "@/lib/scalingo/dbapi/database";
import { teardownDbLogsStreamer } from "@/lib/scalingo/streamers/logs";
import { currentApp } from "@/stores/current/app";
import { useDbBackupsStore } from "@/stores/db/backups";
import { useDbBannersStore } from "@/stores/db/banners";
import { useDbInstancesStore } from "@/stores/db/instances";
import { useDbLogArchivesStore } from "@/stores/db/log-archives";
import { useDbLogsStore } from "@/stores/db/logs";
import { useDbMaintenanceStore } from "@/stores/db/maintenance";
import { useDbMetricsStore } from "@/stores/db/metrics";
import { useDbPlanStore } from "@/stores/db/plan";
import { useDbUsersStore } from "@/stores/db/users";
import { useDbVersionStore } from "@/stores/db/version";
import { useSessionStore } from "@/stores/session";

/**
 * The current db store behaves mostly like a regular resource store,
 * except that it'll store the db id (which is needed for the fetch)
 * and won't expose fetch/ensure
 */
export const useCurrentDBStore = defineStore("currentDB", () => {
  const { client } = useSessionStore();

  // For now, we fetch via addon type rather than ID
  const dbType = ref<string | null>(null);

  const { item, promiseInfo, fetch, clearData } = useResourceStore({
    fetchPromise: () => {
      if (!dbType.value) throw new Error("no db Type in store");

      databasePromiseInfo.value = voidPromiseInfo();
      database.value = null;

      const app = currentApp();

      // We fetch all addons then filter by type using the provider
      return client(app.region)
        .Addons.for(app.id)
        .then((addons) => {
          const addon = addons.find((addon) => {
            return addon.addon_provider.id == dbType.value;
          });

          if (!addon) {
            throw new Error("no addon for this type");
          }

          return addon;
        });
    },
  });

  const addon = item;
  const addonPromiseInfo = promiseInfo;

  // The DB Api database object is fetched dynamically when addon changes
  const databasePromiseInfo = ref(voidPromiseInfo());
  const database = ref<Db | null>(null);

  watch(addon, (newValue) => {
    if (newValue) {
      fetchDatabase();
    } else {
      databasePromiseInfo.value = voidPromiseInfo();
      database.value = null;
    }
  });

  // When the database object changes, re-fetch the database type version and the plan.
  // Those objects don't change much
  watch(database, (newValue) => {
    const dbVersionStore = useDbVersionStore();
    const dbPlanStore = useDbPlanStore();

    if (newValue) {
      dbVersionStore.fetch();
      dbPlanStore.fetch();
    } else {
      dbVersionStore.$reset();
      dbPlanStore.$reset();
    }
  });

  const fetchDatabase = async () => {
    const app = currentApp();

    if (!addon.value) throw new Error("no current addon");

    const client = await scalingoDBClient(app, addon.value.id);
    const promise = client.Database.for(addon.value.id).then(
      setupDatabasePolling,
    );

    promise.then((db) => (database.value = db), noop);
    databasePromiseInfo.value = promiseInfoFn(promise);

    return promise;
  };

  // Refreshing the full database object causes the UI to blink.
  // By fetching the full object but setting only the desired field, we avoid this.
  async function partialDBRefresh(field: keyof Db): Promise<Db> {
    const client = await currentDBClient();
    const db = database.value as Db;
    const refreshed = await client.Database.for(db.id);

    //@ts-expect-error typings will be compatible
    db[field] = refreshed[field];

    return db;
  }

  async function refreshDBFeatures() {
    const db = await partialDBRefresh("features");

    setupDatabasePolling(db);
  }

  async function refreshDbStatus(status: string) {
    const db = await partialDBRefresh("status");

    setupStatusDatabasePolling(db, status);
  }

  function setupStatusDatabasePolling(db: Db, status: string): Db {
    const stopFn = () => db.status !== status;
    const refreshFn = () => refreshDbStatus(status);

    setupPolling({
      id: db.id,
      kind: "database",
      stopFn,
      refreshFn,
    });

    return db;
  }

  function setupDatabasePolling(db: Db): Db {
    // Refreshing the DB as long as features are pending
    const stopFn = () =>
      !db.features.find(
        (f) =>
          f.status === "PENDING_TO_REMOVE" ||
          f.status === "PENDING_TO_ACTIVATE",
      );
    const refreshFn = () => refreshDBFeatures();

    setupPolling({
      id: db.id,
      kind: "database",
      stopFn,
      refreshFn,
    });

    return db;
  }

  // To call when entering the context of a database
  const setCurrentDB = function (type: string) {
    unsetCurrentDB();

    dbType.value = type;

    return fetch();
  };

  const isSet = computed(() => !!dbType.value);

  // To call when exiting the context of a database
  function unsetCurrentDB() {
    useDbBackupsStore().clearData();
    useDbLogArchivesStore().$reset();
    useDbInstancesStore().$reset();
    useDbLogsStore().$reset();
    useDbMetricsStore().$reset();
    useDbPlanStore().$reset();
    useDbVersionStore().$reset();
    useDbUsersStore().$reset();
    useDbMaintenanceStore().$reset();
    clearPollingFor("dbOperation");
    useDbBannersStore().$reset();

    if (database.value) {
      teardownDbLogsStreamer(database.value);
    }

    clearData();

    dbType.value = null;
  }

  return {
    addon,
    addonPromiseInfo,
    database,
    databasePromiseInfo,
    isSet,
    partialDBRefresh,
    refreshDBFeatures,
    setCurrentDB,
    unsetCurrentDB,
    fetchDatabase,
    setupStatusDatabasePolling,
  };
});

export function currentAddon() {
  const dbStore = useCurrentDBStore();
  const addon = dbStore.addon;

  if (!addon) throw new Error("Expected current addon to be present");

  return addon;
}

export function currentDB() {
  const dbStore = useCurrentDBStore();
  const database = dbStore.database;

  if (!database) throw new Error("Expected current database to be present");

  return database;
}

export function currentDBClient() {
  const app = currentApp();
  const addon = currentAddon();

  return scalingoDBClient(app, addon.id);
}
