import { BlogArticle, fetchLastArticles } from "@/lib/scalingo/feeds/blog";
import { HANDLE_FETCH, REFRESH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  listItems,
  ListItemsOptions,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class BlogArticlesStore extends CollectionStore<BlogArticle> {
  actions = CollectionStore.buildActions<BlogArticle>({
    [REFRESH](context, { locale }) {
      context.dispatch(HANDLE_FETCH, {
        promise: fetchLastArticles(locale),
        resolveAction: SET_ALL,
      });
    },
  });
  mutations = CollectionStore.buildMutations<BlogArticle>();
  getters = CollectionStore.buildGetters<BlogArticle>();
}

export const BlogArticles = buildMapping(
  new BlogArticlesStore(),
  "blogArticles",
);

export function listBlogArticles(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<BlogArticle>>,
): CollectionWithFetch<BlogArticle> {
  return {
    items: listItems(store.getters[BlogArticles.getters.ALL], opts),
    latestFetch: store.getters[BlogArticles.getters.LATEST_FETCH],
  };
}

export function clearBlogArticles(store: ApplicationStore): void {
  store.dispatch(BlogArticles.actions.CLEAR);
}

export function ensureBlogArticles(
  store: ApplicationStore,
  locale: "en" | "fr",
): void {
  store.dispatch(BlogArticles.actions.ENSURE, { payload: { locale } });
}
