export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage resources in use."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressources"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des ressources utilisées."])}
      }
    }
  })
}
