<template>
  <div
    class="bg-scale-0 text-scale-10 rounded-lg py-6"
    :class="{
      'border-primary-2 border-2': isSelected,
      'border-scale-2 border-2 ': !isSelected,
    }"
    :data-addon-provider-id="addonProvider.id"
  >
    <AddonProviderIcon
      class="flex justify-center"
      :addonProvider="addonProvider"
    />

    <div class="text-center font-medium mt-4 whitespace-nowrap overflow-hidden">
      {{ addonProvider.name }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import AddonProviderIcon from "@/components/atoms/graphics/addonProviders/Icon.vue";

export default defineComponent({
  name: "AddonProvider",
  components: {
    AddonProviderIcon,
  },
  props: {
    addonProvider: Object,
    selectedAddonProvider: Object,
  },
  computed: {
    isSelected() {
      return this.addonProvider.id === this.selectedAddonProvider?.id;
    },
  },
});
</script>

<style scoped>
.padding-1px {
  padding: 1px;
}
</style>
