export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last backup information"])},
        "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["method"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups unavailable"])},
        "complement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in sandbox plans."])},
        "planLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade this plan."])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download your last backup"])},
        "backupLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a manual backup."])},
        "periodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
        "backupPolicy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oldest backup available: ", _interpolate(_named("oldestBackupTime"))])},
        "noBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No backup yet."])},
        "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size not available"])},
        "configureBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first manual backup or configure scheduled backups by clicking on the Manage button."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le dernier backup"])},
        "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["méthode"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups non disponibles"])},
        "complement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour les plans sandbox."])},
        "planLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour ce plan."])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le dernier backup."])},
        "backupLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer un backup manuel."])},
        "periodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifié"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])},
        "backupPolicy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le plus ancien réussi: ", _interpolate(_named("oldestBackupTime"))])},
        "noBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de backup."])},
        "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille non disponible"])},
        "configureBackups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre premier backup manuel ou configurez la planification des backups en cliquant sur le bouton Gérer."])}
      }
    }
  })
}
