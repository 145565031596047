<template>
  <div class="text-scale-10">
    <div class="bg-scale-0 rounded px-8 py-6">
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"> </CardHeader>
      <template v-if="cronTasks.latestFetch.isLoading">
        <LoadingCardState />
      </template>
      <template v-else-if="cronTasks.latestFetch.isSuccess && !noItems">
        <div
          v-for="(item, index) in displayedTasks"
          :key="index"
          class="py-1 flex"
        >
          <div class="flex flex-grow py-1 flex-col pv:flex-row">
            <div class="flex items-center justify-start flex-shrink pv:w-1/3">
              <div class="flex flex-col flex-wrap">
                <div class="font-medium">
                  {{ humanCronSchedule(item._schedule, $i18n.locale) }}
                </div>
                <div class="text-scale-4 font-mono text-xs flex-grow leading-5">
                  {{ item.command }}
                </div>
              </div>
            </div>

            <div class="flex items-center pv:w-1/3 flex-col pv:flex-row">
              <span v-if="item.size">
                <span class="text-scale-7">{{ $t("size") }}:</span>
                {{ sizeForJob(item) }}
              </span>
            </div>

            <div
              class="flex items-center pv:w-1/3 flex-col pv:flex-row pv:justify-end justify-center"
            >
              <div class="flex flex-col text-right">
                <span
                  :title="
                    item.last_execution_date
                      ? formatDateTime(item.last_execution_date)
                      : null
                  "
                  class="humanize"
                >
                  {{ $t("lastRun", { date: lastRun(item) }) }}
                </span>

                <span
                  :title="
                    item.next_execution_date
                      ? formatDateTime(item.next_execution_date)
                      : null
                  "
                  class="humanize"
                >
                  {{ $t("nextRun", { date: nextRun(item) }) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasMore" class="flex justify-center">
          <LinkButton :noUnderLine="true" @clicked="viewMore">
            {{ $t("viewMore") }}
          </LinkButton>
        </div>
      </template>
      <template v-else-if="noItems">
        <EmptyCardState>
          <template #icon>
            <BillGlyph class="h-8 text-scale-5"></BillGlyph>
          </template>
          <div class="flex flex-col text-center">
            <div class="text-lg font-bold text-scale-10">
              {{ $t("none") }}
            </div>
            <div class="mt-2">
              <p>
                {{ $t("help") }}
                <DocLink
                  href="https://doc.scalingo.com/platform/app/task-scheduling/scalingo-scheduler"
                >
                  {{ $t("doclink") }}
                </DocLink>
              </p>
            </div>
          </div>
        </EmptyCardState>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import { humanCronSchedule } from "@/lib/utils/cron";
import { formatDateTime, relativeOrAbsolute } from "@/lib/utils/time";

export default defineComponent({
  name: "ContainersCard",
  components: {
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    BillGlyph,
    DocLink,
    LinkButton,
  },
  props: {
    cronTasks: Object,
    sizeOptions: Array,
  },
  data() {
    return {
      maxList: 6,
    };
  },
  computed: {
    hasMore() {
      return this.cronTasks.items.length > 6 && this.maxList < 7;
    },
    noItems() {
      return this.cronTasks.items.length === 0;
    },
    displayedTasks() {
      return this.cronTasks.items.slice(0, this.maxList);
    },
  },
  methods: {
    formatDateTime,
    humanCronSchedule,
    sizeForJob(job) {
      const opt = this.sizeOptions.find((opt) => opt.value === job.size);

      return opt ? opt.text : job.size;
    },
    lastRun(job) {
      return job.last_execution_date
        ? relativeOrAbsolute(job.last_execution_date)
        : "–";
    },
    nextRun(job) {
      return job.next_execution_date
        ? relativeOrAbsolute(job.next_execution_date)
        : "–";
    },
    viewMore() {
      this.maxList = this.cronTasks.length;
    },
  },
});
</script>

<i18n>
  en:
    title: "Scheduled tasks"
    subtitle: "Times are expressed in UTC. Tasks are displayed in the order of the cron.json file"
    help: "Need help? Refer to {doclink}"
    doclink: "the documentation"
    none: "No scheduled tasks."
    size: "Size"
    lastRun: "Last run: {date}"
    nextRun: "Next run: {date}"
    viewMore: "Show all tasks"
  fr:
    title: "Tâches planifiées"
    subtitle: "Les heures sont exprimées en UTC. Les tâches sont affichées dans l'ordre du fichier cron.json"
    help: "Besoin d'aide? Consultez {doclink}"
    doclink: "la documentation"
    none: "Aucune tâche planifiée."
    size: "Taille"
    lastRun: "Dernière exécution : {date}"
    nextRun: "Prochaine exécution : {date}"
    viewMore: "Voir toutes les tâches"
</i18n>
