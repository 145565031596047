<template>
  <SimpleModal
    :hasBorder="true"
    size="w-3/4 lg:w-1/2 2xl:w-1/4"
    @modalClosed="$emit('close')"
  >
    <template #title>
      <div class="mb-4">
        {{ modalTitle }}
      </div>
    </template>

    <template #body>
      <p class="mt-3 font-medium">{{ $t("warning") }}: {{ warningMessage }}</p>

      <div class="rounded border-2 px-2 py-2 flex max-h-28 overflow-auto mt-3">
        {{ item.query }}
      </div>

      <div class="mt-6 flex">
        <SCButton
          block
          kind="danger"
          size="lg"
          class="flex-grow"
          :loading="isLoading"
          @click="(e) => confirm(e)"
        >
          <slot name="confirmText">
            {{ modalButton }}
          </slot>
        </SCButton>
      </div>

      <div class="flex">
        <SCButton
          block
          kind="neutral"
          size="lg"
          class="flex flex-grow mt-4"
          @click="(e) => $emit('close', e)"
        >
          <slot name="cancelText">
            {{ $t("close") }}
          </slot>
        </SCButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";

export default defineComponent({
  name: "RunningQueriesModal",
  components: {
    SimpleModal,
    SCButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    method: {
      type: Function,
      required: true,
    },
  },
  emits: ["close"],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    modalTitle() {
      return (
        (this.type === "cancel"
          ? this.$i18n.t("cancel")
          : this.$i18n.t("kill")) +
        "  " +
        this.item.pid
      );
    },
    modalButton() {
      return this.type === "cancel"
        ? this.$i18n.t("cancel")
        : this.$i18n.t("kill");
    },

    warningMessage() {
      return this.type === "cancel"
        ? this.$i18n.t("warningMessageCancel")
        : this.$i18n.t("warningMessageTerminate");
    },
  },
  methods: {
    async confirm(e) {
      this.isLoading = true;
      await this.method(this.item.pid);
      this.$emit("close", e);
      this.isLoading = false;
    },
  },
});
</script>

<i18n>
    en:
      cancel: Cancel query
      kill: Terminate session
      warning: Warning
      warningMessageCancel: canceling this query will stop its current execution. This action will not terminate the session, and the connection will remain active. However, any uncommitted changes will be lost.
      warningMessageTerminate: terminating the session will immediately disconnect the user and end all running queries. This action will roll back any uncommitted transactions, potentially leading to data loss. Use this option with caution.
      cancelButton: Cancel this query
      killButton: Terminate this session
      close: Close
      error: An error occurred. Please reload the page and try again.
    fr:
      cancel: Annuler la requête
      kill: Interrompre la session
      warning: Attention
      warningMessageCancel: l'annulation de cette requête interrompra son exécution. Cette action ne met pas fin à la session et la connexion reste active. Toutefois, les modifications non enregistrées seront perdues.
      warningMessageTerminate: l'interruption de la session entraîne la déconnexion immédiate de l'utilisateur et met fin à toutes les requêtes en cours d'exécution. Cette action annulera toutes les transactions non enregistrées, ce qui peut entraîner une perte de données. Utilisez cette fonction avec précaution.
      cancelButton: Annuler cette requête
      killButton: Interrompre cette session
      close: Fermer
      error: Une erreur est survenue. Veuillez recharger la page et réessayer.
  </i18n>
