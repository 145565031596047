export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "strongConfirm": {
          "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to proceed, please enter"])},
          "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the text field below:"])}
        },
        "weakConfirmDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to proceed, please tick the checkbox"])}
      },
      "fr": {
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "strongConfirm": {
          "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de procéder, veuillez entrer"])},
          "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans le champ de texte ci-dessous:"])}
        },
        "weakConfirmDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de procéder, veuillez cocher la case"])}
      }
    }
  })
}
