export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate outdated"])},
        "willExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate soon outdated"])},
        "manualActionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual action required"])},
        "dnsProviderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS config. error"])},
        "challengeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt failure"])},
        "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt failure"])},
        "certificateTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Custom certificate - valid until ", _interpolate(_named("date"))])}
      },
      "fr": {
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat expiré"])},
        "willExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat bientot expiré"])},
        "manualActionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action manuelle requise"])},
        "dnsProviderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de config. DNS"])},
        "challengeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt failure"])},
        "genericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Encrypt failure"])},
        "certificateTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certificat personnalisé - valide jusqu'au ", _interpolate(_named("date"))])}
      }
    }
  })
}
