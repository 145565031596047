import { Collaborator } from "scalingo/lib/models/regional";

export function sortCollaborators(collaborators: Collaborator[]) {
  return [...collaborators].sort((a, b) => {
    const usernameA = a.username || "";
    const usernameB = b.username || "";

    if (usernameA === "" && usernameB === "") return 0;
    if (usernameA === "") return 1;
    if (usernameB === "") return -1;

    return usernameA.localeCompare(usernameB);
  });
}
