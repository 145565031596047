export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "openTerminalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open terminal"])},
        "openDockerInstructionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Docker instructions"])},
        "unsupportedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported mode"])},
        "turnYourMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn your phone in landscape mode"])},
        "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
        "docker": {
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First, read the instructions related to the docker addon"])},
          "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on our documentation center"])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then, you will need an API token: you can generate one from"])},
          "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the dedicated page in your account"])},
          "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finally, execute the commands below from a terminal:"])},
          "line4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning: old images are probably already deleted."])}
        },
        "title": {
          "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
          "commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commit"])},
          "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image size"])}
        }
      },
      "fr": {
        "openTerminalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le terminal"])},
        "openDockerInstructionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les instructions Docker"])},
        "unsupportedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode non supporté"])},
        "turnYourMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournez votre mobile en mode paysage"])},
        "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par"])},
        "docker": {
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'abord, consultez les instructions relatives a l'addon docker"])},
          "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depuis notre documentation"])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensuite, vous allez avoir besoin d'un API token: vous pouvez en générer un depuis"])},
          "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la page dédiée de votre compte"])},
          "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfin, exécutez les commandes ci-dessous depuis un terminal:"])},
          "line4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention: les anciennes images sont probablement déjà supprimées."])}
        },
        "title": {
          "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horodatage"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
          "commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commit"])},
          "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de l'image"])}
        }
      }
    }
  })
}
