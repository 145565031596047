import { App } from "scalingo/lib/models/regional";
import Operation from "scalingo/lib/Operations/utils";

import { scalingoClient } from "@/lib/scalingo/client";
import { CLEAR, HANDLE_OPERATION, WATCH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ONGOING } from "@/lib/store/getter-types";
import { ADD, DELETE, SET_ALL } from "@/lib/store/mutation-types";
import { buildMapping } from "@/lib/store/utils";
import { dashboard } from "@/lib/utils/log";
import { ApplicationStore } from "@/store";
import { Session } from "@/store/session";
import { useCurrentAppStore } from "@/stores/current/app";

import { Events } from "./events";

const POLLING_INTERVAL = 1000;
const SUCCES_REMOVE_AFTER = 5000;

let currentPollingId: NodeJS.Timeout | null = null;

export class OperationsStore extends CollectionStore<Operation> {
  actions = CollectionStore.buildActions<Operation>({
    [CLEAR](context) {
      context.commit(SET_ALL, null);

      if (currentPollingId) {
        clearTimeout(currentPollingId);
      }
    },
    [WATCH](context, operationId) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(
          context,
          currentApp.region,
        ).Operations.operation(currentApp.id, operationId),
        resolveAction(operation: Operation) {
          context.commit(ADD, operation);

          const status = operation.status as string;

          // Refresh the operation if not finished
          if (["pending", "running"].includes(status)) {
            currentPollingId = setTimeout(() => {
              context.dispatch(WATCH, operationId);
            }, POLLING_INTERVAL);
          }

          // Leave it in the store a little bit when done, then remove
          if (status === "done") {
            context.dispatch(Session.actions.REFRESH, null, { root: true });
            context.dispatch(Events.actions.REFRESH, null, { root: true });

            currentPollingId = setTimeout(() => {
              context.commit(DELETE, operation);
            }, SUCCES_REMOVE_AFTER);
          }

          // In error, do nothing but display the error
          if (status === "error") {
            dashboard.log("error");
          }
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<Operation>();
  getters = CollectionStore.buildGetters<Operation>({
    [ONGOING](state) {
      const items = (state.values || []) as Operation[];

      return items.filter((operation) => {
        const status = operation.status as string;
        return ["pending", "running"].includes(status);
      });
    },
  });
}

export const Operations = buildMapping(new OperationsStore(), "operations");

export function allOperations(store: ApplicationStore): Operation[] {
  return store.getters[Operations.getters.ALL];
}

export function ongoingOperations(store: ApplicationStore): Operation[] {
  return store.getters[Operations.getters.ONGOING];
}
