<template>
  <SimpleModal
    :hasBorder="true"
    size="w-1/4"
    @modalClosed="$emit('modalClosed')"
  >
    <template #title>
      <div class="mb-4">
        {{ $t("title") }}
      </div>
    </template>
    <template #body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form class="mt-4" @submit.prevent="handleSubmit(submitForm)">
          <div class="mb-4">
            <p>{{ $t("text") }}</p>
            <p>
              {{ $t("text2") }}:
              <DocLink
                class="font-normal text-primary-2"
                href="https://doc.scalingo.com/platform/app/lifecycle-management#transfer-ownership-of-an-application"
              >
                {{ $t("link") }} </DocLink
              >.
            </p>
          </div>

          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.owner"
            name="owner"
            rules="required"
          >
            <AdvancedSelectInput
              :name="field.name"
              :modelValue="field.value"
              :options="options"
              :optionsAreLoading="collaborators.latestFetch.isLoading"
              @update:modelValue="handleChange"
            >
              <template #label>
                {{ $t("labels.owner") }}
              </template>

              <template #prompt>
                {{ $t("prompt") }}
              </template>

              <template #picture="{ option }">
                <Portrait :user="option" size="5" />
              </template>

              <template #text="{ option }">
                {{ option.username }}
              </template>
            </AdvancedSelectInput>

            <p v-if="errors.length > 0" class="text-xs text-color-2 mb-4 mt-2">
              {{ errors[0] }}
            </p>
          </Field>

          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.currentName"
            name="currentname"
            :rules="currentNameRules"
          >
            <TextInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('labels.currentAppName')"
              :errors="errors"
              class="mt-4"
              :subText="$t('helpers.name', { name: app.name })"
              @update:modelValue="handleChange"
            ></TextInput>
          </Field>

          <div v-if="errors">{{ errors.base }}</div>

          <SCAlert
            v-if="app.hds_resource"
            class="mt-4 border border-secondary-1"
            kind="caution"
            icon="title"
            :title="$t('hds_warning.title')"
          >
            {{ $t("hds_warning.message") }}
          </SCAlert>

          <div class="flex">
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-8"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("button") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import Portrait from "@/components/atoms/graphics/Portrait.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";

export default defineComponent({
  name: "TransferAppModal",
  components: {
    SCAlert,
    DocLink,
    TextInput,
    SCButton,
    SimpleModal,
    Field,
    Form,
    AdvancedSelectInput,
    Portrait,
  },
  mixins: [FormModalHandlerMixin],
  props: {
    app: Object,
    collaborators: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    currentNameRules() {
      return {
        required: true,
        is: this.app.name,
      };
    },
    options() {
      return this.collaborators.items.map((collaborator) => {
        return { ...collaborator, value: collaborator.user_id };
      });
    },
  },
});
</script>

<i18n>
en:
  title: "Transfer app ownership"
  prompt: "Select the new owner"
  button: "Transfer ownership"
  text: Are you sure you want to transfer this app? Once transferred, the new owner will be responsible for the app, its data and the payment of its consumption.
  text2: Before taking any action, please read the following section of our documentation carefully
  link: Transfer Ownership of an Application
  hds_warning:
    title: You are about to transfer an HDS app
    message: The new owner must have accepted the HDH appendix and have an up-to-date point of contact.
  helpers:
    name: "Confirm by typing “{name}”"
  labels:
    owner: "New owner"
    currentAppName: "Name of the app"
fr:
  title: "Transférer la propriété de l'application"
  prompt: "Choisissez à qui transférer l'application"
  button: "Transférer la propriété"
  text: Êtes-vous sûr de vouloir transférer cette application ? Une fois transférée, le ou la nouvelle propriétaire sera responsable de l'application, de ses données et du paiement de sa consommation.
  text2: Avant toute action, veuillez lire attentivement la section suivante de notre documentation
  link: Transfert de propriété d'une application
  hds_warning:
    title: Vous êtes sur le point de transférer une application HDS
    message: Le nouveau propriétaire doit avoir accepté l'annexe HDS et disposer d'un point de contact à jour.
  helpers:
    name: "Confirmez en saisissant “{name}”"
  labels:
    owner: "Nouveau propriétaire"
    currentAppName: "Nom de l’application"
</i18n>
