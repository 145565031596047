export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your own SSL certificate"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload another SSL certificate"])}
        },
        "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate (usually .crt)"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key (usually .key)"])},
        "actions": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
          "letsencrypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the Let’s Encrypt integration"])}
        },
        "instructions": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish to use your own SSL certificate, you need to upload both your certificate and key files below."])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload another set of certificate and key below,\nor use our Let’s Encrypt integration to benefit from\nthe automatic certicate generation and renewal,\n"])},
          "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files must follow the PEM format. For additional details please refer to"])},
          "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure SSL/HTTPS access on our documentation center"])}
        },
        "placeholders": {
          "tlscert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-----BEGIN CERTIFICATE-----\nMIIDXTCCAkWgAwIBAgIJAJC1HiIAZAiIMA0GCSqGSIb3Df\naWRnaXRzIFB0eSBMdGQwHhcNMTExMjMxMDg1OTQ0WhcNMT\n.... MANY LINES LIKE THAT ....\nJjyzfN746vaInA1KxYEeI1Rx5KXY8zIdj6a7hhphpj2E04\nB7xxt8BVc69rLeHV15A0qyx77CLSj3tCx2IUXVqRs5mlSb\n-----END CERTIFICATE-----\n"])},
          "tlskey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-----BEGIN PRIVATE KEY-----\nMIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQwwDg\n9g73NQbtqZwI+9X5OhpSg/2ALxlCCjbqvzgSu8gfFZ4yo+\n.... MANY LINES LIKE THAT ....\nX0R+meOaudPTBxoSgCCM51poFgaqt4l6VlTN4FRpj+c/WZ\nH0ga/iLNvWYexG7FHLRiq5hTj0g9mUPEbeTXuPtOkTEb/0\n-----END PRIVATE KEY-----\n"])}
        }
      },
      "fr": {
        "title": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un certificat SSL personnel"])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un autre certificat SSL"])}
        },
        "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat (généralement .crt)"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé (généralement .key)"])},
        "actions": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploader"])},
          "letsencrypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser l'intégration Let’s Encrypt"])}
        },
        "instructions": {
          "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez utiliser votre propre certificat SSL, vous devez uploader votre certificat et votre clé ci-dessous."])},
          "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez uploader un autre jeu de certificat et clé ci-dessous,\nou bien utiliser notre intégration avec Let’s Encrypt\net profiter de la génération et du renouvellement automatique.\n"])},
          "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fichiers doivent être au format PEM. Pour plus d'informations, se référer à la page"])},
          "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer l'accès SSL/HTTPS sur notre documentation"])}
        },
        "placeholders": {
          "tlscert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-----BEGIN CERTIFICATE-----\nMIIDXTCCAkWgAwIBAgIJAJC1HiIAZAiIMA0GCSqGSIb3Df\naWRnaXRzIFB0eSBMdGQwHhcNMTExMjMxMDg1OTQ0WhcNMT\n.... PLUSIEURS LIGNES DU GENRE ....\nJjyzfN746vaInA1KxYEeI1Rx5KXY8zIdj6a7hhphpj2E04\nB7xxt8BVc69rLeHV15A0qyx77CLSj3tCx2IUXVqRs5mlSb\n-----END CERTIFICATE-----\n"])},
          "tlskey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-----BEGIN PRIVATE KEY-----\nMIIFDjBABgkqhkiG9w0BBQ0wMzAbBgkqhkiG9w0BBQwwDg\n9g73NQbtqZwI+9X5OhpSg/2ALxlCCjbqvzgSu8gfFZ4yo+\n.... PLUSIEURS LIGNES DU GENRE ....\nX0R+meOaudPTBxoSgCCM51poFgaqt4l6VlTN4FRpj+c/WZ\nH0ga/iLNvWYexG7FHLRiq5hTj0g9mUPEbeTXuPtOkTEb/0\n-----END PRIVATE KEY-----\n"])}
        }
      }
    }
  })
}
