/** We'll store all the intervalIDS in this object, nested by object type */
const intervalIDs: Record<string, Record<string, NodeJS.Timeout>> = {};

type PollingParams = {
  /** Kind of target object to watch */
  kind: "addon" | "backup" | "database" | "dbOperation";
  /** ID of the target object to watch */
  id: string;
  /** A function that returns true when the object should no longer be watched */
  stopFn: () => boolean;
  /** A function that refreshes the object */
  refreshFn: () => void;
  /** A function called once the polling is stopped */
  teardownFn?: () => void;
};

/** Sets up a periodic refresh (every 5s) of an object according to the given params */
export function setupPolling(opts: PollingParams) {
  intervalIDs[opts.kind] = intervalIDs[opts.kind] || {};
  const relevantIDs = intervalIDs[opts.kind];

  // If the object is in a finalized state, remove the watcher (if needed).
  // Otherwise, add the watcher (unless already watching)
  if (opts.stopFn()) {
    const intervalID = relevantIDs[opts.id];

    if (intervalID) {
      clearInterval(intervalID);
      delete relevantIDs[opts.id];

      // Call the teardown if supplied
      opts.teardownFn?.();
    }
  } else if (!relevantIDs[opts.id]) {
    relevantIDs[opts.id] = setInterval(opts.refreshFn, 5000);
  }
}

/** Clears all interval watchers. Useful for data cleanup. */
export function clearPollingFor(kind: PollingParams["kind"]) {
  intervalIDs[kind] = intervalIDs[kind] || {};
  const relevantIDs = intervalIDs[kind];

  for (const [id, intervalID] of Object.entries(relevantIDs)) {
    clearInterval(intervalID);
    delete relevantIDs[id];
  }
}
