<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"></CardHeader>
    </template>
    <template #body>
      <div>
        <div v-if="events.items.length > 0" class="divide-y divide-scale-2">
          <div
            v-for="(event, key) in events.items"
            :key="key"
            class="py-2 flex"
          >
            <EventSwitch
              :event="event"
              :long="false"
              :displayAuthor="false"
              :displayAppName="true"
            />
          </div>
        </div>
        <div v-else>No event</div>
      </div>
    </template>
    <template #footer>
      <CardFooter></CardFooter>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EventSwitch from "@/components/organisms/events/EventSwitch.vue";

export default defineComponent({
  name: "ActivityCard",
  components: { EventSwitch, Card, CardFooter, CardHeader },
  props: {
    events: Object,
  },
  methods: {
    formatType(event) {
      let s = event.type;
      s = s.replace("_", " ");
      return s[0].toUpperCase() + s.slice(1);
    },
  },
});
</script>

<i18n>
en:
  title: "Activity"
  subtitle: "Latest events."
fr:
  title: "Activité"
  subtitle: "Derniers événements."
</i18n>
