<template>
  <RangeSelectors
    class="place-items-center"
    size="md"
    :items="items"
    :selectedValue="selectedValue"
    :disabled="disabled"
    @selected="(e) => $emit('rangeSelected', e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import RangeSelectors from "@/components/atoms/selectors/RangeSelectors.vue";

export default defineComponent({
  name: "RetentionPolicySelector",
  components: { RangeSelectors },
  props: {
    displayInFullScreen: Boolean,
    selectedValue: [String, Number],
    dbPlan: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["rangeSelected"],
  computed: {
    items() {
      let items = [
        {
          value: "all",
          text: this.$t("all"),
          title: this.$t("all"),
        },
        {
          value: "periodic",
          text: this.$t("scheduled"),
          title: this.$t("scheduled"),
        },
      ];

      items.push({
        value: "manual",
        text: this.$t("user"),
        title: this.$t("user"),
      });
      return items;
    },
  },
});
</script>

<i18n>
  en:
    all: "All"
    scheduled: "Scheduled"
    user: "Manual"
  fr:
    all: "Tous"
    scheduled: "Planifiés"
    user: "Manuels"
</i18n>
