export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
        },
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit variable"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete variable"])}
        }
      },
      "fr": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
        },
        "actions": {
          "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit variable"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete variable"])}
        }
      }
    }
  })
}
