export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL Modes Configuration"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modes determine the SQL syntax accepted by MySQL and the validation rules it applies."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information, plese <a class='underline' href='https://doc.scalingo.com/databases/mysql/managing#working-with-sql-modes' target='_blank'>refer to our documentation</a>."])},
        "activeConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database has the following active SQL modes:"])},
        "noConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database has no active SQL modes. Popular ones include <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_ansi' target='_blank'>ANSI</a>, <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_strict_trans_tables' target='_blank'>STRICT_TRANS_TABLES</a> and  <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_traditional' target='_blank'>TRADITIONAL</a>."])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage SQL modes"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modes SQL Configuration"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modes déterminent la syntaxe SQL acceptée par MySQL et les règles de validation qu’il applique."])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour plus d'informations, veuillez <a class='underline' href='https://doc.scalingo.com/databases/mysql/managing#working-with-sql-modes' target='_blank'>consulter notre documentation</a>."])},
        "activeConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données dispose des modes SQL actifs suivants :"])},
        "noConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données ne dispose d'aucun mode SQL actif. Les modes populaires incluent <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_ansi' target='_blank'>ANSI</a>, <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_strict_trans_tables' target='_blank'>STRICT_TRANS_TABLES</a> et  <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_traditional' target='_blank'>TRADITIONAL</a>."])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les modes SQL"])}
      }
    }
  })
}
