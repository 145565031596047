import { defineStore } from "pinia";
import { useRoute } from "vue-router";

import {
  useCollectionStore,
  toPublicStore,
} from "@/lib/pinia/use-collection-store";
import { useSessionStore } from "@/stores/session";

export const useOrderInvoicesStore = defineStore("orderInvoices", () => {
  const { privateClient } = useSessionStore();

  const store = useCollectionStore({
    fetchPromise: async () => {
      const route = useRoute();
      const order_id = route.params.id as string;
      const response =
        await privateClient().Orders.invoices_for_order(order_id);
      return response.invoices;
    },
  });

  return toPublicStore(store, {});
});
