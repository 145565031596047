export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "inFreeTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yay! You are now trialing Scalingo. You have "])},
        "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 days"]), _normalize(["1 day"]), _normalize([_interpolate(_named("count")), " days"])])},
        "inFreeTrialEndSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to try out our features!"])},
        "freeTrialOver": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free trial ended. To continue to use Scalingo,"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you need to add a payment method or a voucher."])}
        }
      },
      "fr": {
        "inFreeTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yay ! Vous êtes maintenant en cours d'essai. Vous avez "])},
        "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 jours"]), _normalize(["1 jour"]), _normalize([_interpolate(_named("count")), " jours"])])},
        "inFreeTrialEndSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" restant pour tester notre plateforme !"])},
        "freeTrialOver": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'essai terminée. Pour continuer à utiliser Scalingo,"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous devez ajouter un moyen de paiement ou un voucher."])}
        }
      }
    }
  })
}
