export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore a backup"])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to restore backup from"])},
          "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand that this action will permanently delete existing data and cannot be cancelled or undone once started."])}
        },
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restoration time can vary from a few minutes to several hours, depending on the size of your database. We recommend that you do not perform any operations on your database until it has been fully restored."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurer un backup"])},
        "disclaimer": {
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir restaurer la sauvegarde du"])},
          "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends que cette action effacera définitivement les données existantes et qu'elle ne pourra pas être annulée."])}
        },
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de restauration peut varier de quelques minutes à plusieurs heures, en fonction de la taille de votre base de données. Nous vous recommandons de ne pas effectuer d'opérations sur votre base de données tant qu'elle n'a pas été entièrement restaurée."])}
      }
    }
  })
}
