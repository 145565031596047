<template>
  <div
    class="bg-scale-0 border-t-4 rounded px-6 py-4 border-pending"
    style="height: 124px"
  ></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DbLoading",
});
</script>
