<template>
  <SCBanner kind="info">
    <p class="mt-0.5">
      <i18n :keypath="sentencePlurality">
        {{
          $t(sentencePlurality, {
            day: weekDay,
            h1: startTime,
            h2: endTime,
          })
        }}
        <router-link :to="maintenanceWindowRoute" class="underline">
          {{ $t("more") }}
        </router-link>
      </i18n>
    </p>
  </SCBanner>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "MaintenanceScheduledBanner",
  components: { SCBanner },
  props: {
    items: Array,
    db: Object,
    dbMaintenance: Object,
  },
  setup(props) {
    const startingHourUTC = props.db.maintenance_window.starting_hour_utc;
    const durationInHours = props.db.maintenance_window.duration_in_hour;
    const weekdayUTC = props.db.maintenance_window.weekday_utc + 1;
    const sentencePlurality =
      "requiredMaintenance" + (props.items.length > 1 ? "Plural" : "Single");

    const startTime = DateTime.utc().set({
      hour: startingHourUTC,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const endTime = startTime.plus({ hours: durationInHours });

    const weekDay = DateTime.utc()
      .set({ weekday: weekdayUTC })
      .toLocaleString({ weekday: "long" });

    return {
      startingHourUTC,
      maintenanceWindowRoute: {
        name: Routes.Db.Maintenance,
      },
      startTime: startTime.toLocaleString(DateTime.TIME_SIMPLE),
      endTime: endTime.toLocaleString(DateTime.TIME_SIMPLE),
      weekDay,
      sentencePlurality,
    };
  },
});
</script>

<i18n>
  en:
    requiredMaintenanceSingle: "An operation will take place during the next Maintenance Window: {day}, between {h1} and {h2} UTC. {link}"
    requiredMaintenancePlural: "Operations will take place during the next Maintenance Window: {day}, between {h1} and {h2} UTC. {link}"
    more: "More information"
  fr:
    requiredMaintenanceSingle: "Une opération aura lieu au cours de la prochaine fenêtre de maintenance : {day}, entre {h1} et {h2} UTC. {link}"
    requiredMaintenancePlural: "Des opérations auront lieu au cours de la prochaine fenêtre de maintenance : {day}, entre {h1} et {h2} UTC. {link}"
    more: "En savoir plus"
</i18n>
