<template>
  <ClusterTopology :db="db" />
</template>

<script>
import { defineComponent } from "vue";

import ClusterTopology from "@/components/parts/dbSettings/configuration/ClusterTopology.vue";

export default defineComponent({
  name: "Configuration",
  components: {
    ClusterTopology,
  },
  events: ["clearErrorMessage"],
  props: {
    db: Object,
  },
});
</script>
