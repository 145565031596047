import {
  BalanceOperation,
  IndexQuery as BalanceOperationsQuery,
  IndexResponse as BalanceOperationsList,
} from "@/lib/scalingo/balance-operations";
import { scalingoPrivateClient } from "@/lib/scalingo/client";
import { REFRESH, HANDLE_FETCH, FETCH_MORE } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_META, SET_ALL, MERGE } from "@/lib/store/mutation-types";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class BalanceOperationsStore extends CollectionStore<BalanceOperation> {
  actions = CollectionStore.buildActions<BalanceOperation>({
    [REFRESH](context, params) {
      const opts: BalanceOperationsQuery = {
        owner_id: params.ownerId,
      };

      if (params.perPage) {
        opts.per_page = params.perPage;
      }

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).BalanceOperations.all(opts),
        resolveAction: function (resolved: BalanceOperationsList) {
          context.commit(SET_ALL, resolved.balance_operations);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH_MORE](context, params) {
      if (!context.state.meta.pagination?.next_page) {
        return;
      }

      const opts: BalanceOperationsQuery = {
        owner_id: params.ownerId,
        page: context.state.meta.pagination.next_page,
      };

      if (params.perPage) {
        opts.per_page = params.perPage;
      }

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).BalanceOperations.all(opts),
        resolveAction: function (resolved: BalanceOperationsList) {
          context.commit(MERGE, resolved.balance_operations);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<BalanceOperation>();
  getters = CollectionStore.buildGetters<BalanceOperation>();
}

export const Operations = buildMapping(
  new BalanceOperationsStore(),
  "balanceOperations",
);

export function listOperations(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<BalanceOperation>>,
): CollectionWithFetch<BalanceOperation> {
  return {
    items: listItems(store.getters[Operations.getters.ALL], opts),
    latestFetch: store.getters[Operations.getters.LATEST_FETCH],
    meta: store.getters[Operations.getters.META],
  };
}

export function ensureOperations(
  store: ApplicationStore,
  ownerId: string,
  perPage: number,
): void {
  store.dispatch(Operations.actions.ENSURE, { payload: { ownerId, perPage } });
}

export function fetchMoreOperations(
  store: ApplicationStore,
  ownerId: string,
  perPage: number,
): void {
  store.dispatch(Operations.actions.FETCH_MORE, { ownerId, perPage });
}
