import { Variable } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updateVariable } from "@/store/variables";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  value: string;
}

export class EditVariableHandler extends FormHandler<Variable> {
  keyPath = "variable.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly variable: Variable,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      value: this.variable.value,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.variable.name });
    });
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await updateVariable(this.$store, this.variable, event.value));
  }
}
