<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <font-awesome-icon
        size="2x"
        icon="database"
        class="h-6 w-6 text-scale-6"
      ></font-awesome-icon>
      <span class="mx-1 text-scale-5"> > </span>
      <font-awesome-icon
        size="2x"
        icon="lock"
        class="h-6 w-6 text-scale-6"
      ></font-awesome-icon>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #body>
      <AddonEventBody :event="event"></AddonEventBody>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AddonEventBody from "@/components/parts/events/AddonEventBody.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "DatabaseRemoveForceTlsEvent",
  components: { AddonEventBody, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Database does not require TLS encryption anymore"
fr:
  title: "La base de donnée ne nécessite plus le chiffrement TLS"
</i18n>
