export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect from SCM provider"])},
        "question": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to disconnect your GitHub account?"])},
          "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to disconnect your GitLab account?"])},
          "github-enterprise": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to disconnect your GitHub account on ", _interpolate(_named("server")), "?"])},
          "gitlab-self-hosted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to disconnect your GitLab account on ", _interpolate(_named("server")), "?"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This SCM integration is still used by the following applications"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink them or use another Scalingo account as linker before disconnecting your account."])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter d'un SCM"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
        "question": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes vous sur de vouloir déconnecter votre compte GitHub?"])},
          "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes vous sur de vouloir déconnecter votre compte GitLab?"])},
          "github-enterprise": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes vous sur de vouloir déconnecter votre compte GitHub de ", _interpolate(_named("server")), "?"])},
          "gitlab-self-hosted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes vous sur de vouloir déconnecter votre compte GitLab de ", _interpolate(_named("server")), "?"])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette intégration SCM est utilisée par les applications suivantes"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déliez les, ou bien utilisez un autre compte Scalingo en tant que linker avant de déconnecter votre compte."])}
        }
      }
    }
  })
}
