export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts & documents"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractual documents relative to the use of the platform"])},
        "showAllContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display older contracts"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrats & documents"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents contractuels relatifs à l'utilisation de la plateforme"])},
        "showAllContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer mes anciens contrats"])}
      }
    }
  })
}
