<template>
  <div class="flex bg-scale-0 rounded">
    <div class="flex py-3 font-bold text-scale-9">
      <div
        class="pr-3 w-6 flex pl-1 items-center bg-scale-1 rounded mr-2 border-r border-secondary-4"
        title="Note"
      >
        <font-awesome-icon
          class="text-secondary-4 text-opacity-50"
          icon="sticky-note"
        ></font-awesome-icon>
      </div>
      <div class="flex-grow flex">
        <div class="self-center text-scale-7">
          <span class="font-normal">
            <p>{{ $t("note1") }}</p>

            <p>
              {{ $t("note2") }}

              <code class="bg-scale-2 px-2 text-scale-9 rounded">{{
                url
              }}</code>
            </p>

            <p>
              {{ $t("note3") }}

              <DocLink
                href="https://doc.scalingo.com/platform/app/domain#configure-your-domain-name"
              >
                {{ $t("doclink") }}
              </DocLink>
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DocLink from "@/components/molecules/links/DocLink.vue";

export default defineComponent({
  name: "DomainNote",
  components: { DocLink },
  props: {
    url: String,
  },
});
</script>

<i18n>
en:
  note1: "To make your app reachable from other domain names, you'll need to add all of them here and to configure your DNS provider."
  note2: "For instance, for a subdomain, you will need to add a CNAME entry pointing towards "
  note3: "For more configuration options, take look at "
  doclink: "our documentation page"
fr:
  note1: "Pour rendre votre application accessible depuis d'autres noms de domaines, vous devez les ajouter ci-dessus, ainsi que configurer votre fournisseur DNS."
  note2: "Par exemple, pour un sous-domaine, il faudra ajouter une entrée CNAME pointant vers "
  note3: "Pour plus d'options de configuration, veuillez consulter "
  doclink: "notre page de documentation"
</i18n>
