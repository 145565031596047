export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub deployment"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your GitHub settings"])}
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitlab deployment"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your Gitlab settings"])}
        },
        "orgs": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading organizations..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an organization"])}
        },
        "repos": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading repositories..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a repository"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an organization first"])}
        },
        "branches": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading branches..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a branch"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a repository first"])}
        },
        "autoDeployLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable automatic deploys"])}
      },
      "fr": {
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements GitHub"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner vos paramètres GitHub"])}
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements Gitlab"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner vos paramètres Gitlab"])}
        },
        "orgs": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading organizations..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an organization"])}
        },
        "repos": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading repositories..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a repository"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an organization first"])}
        },
        "branches": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading branches..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a branch"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a repository first"])}
        },
        "autoDeployLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer le déploiement automatique"])}
      }
    }
  })
}
