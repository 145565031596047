<template>
  <main>
    <ButtonedSectionTitle
      v-if="!displayInFullScreen"
      :title="$t('title')"
      :subtitle="$t('subtitle')"
      :buttonName="$t('viewArchives')"
      :buttonRouteArgs="viewArchiveLink"
    >
    </ButtonedSectionTitle>
    <LogsWindow
      :logs="logs"
      :fullscreen="displayInFullScreen"
      :streamingEnabled="streamLogs"
      :fontSize="logsFontSize"
      class="mt-6"
      @streamingToggled="streamingToggled"
      @fullScreenClicked="toggleFullScreen"
      @fontSizeChanged="(e) => $emit('fontSizeChanged', e)"
      @clearClicked="$emit('clearClicked')"
    ></LogsWindow>
    <ProTipLogsViaCLI
      v-if="!displayInFullScreen"
      class="mt-4"
      :app="app"
    ></ProTipLogsViaCLI>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ButtonedSectionTitle from "@/components/molecules/titles/ButtonedSectionTitle.vue";
import ProTipLogsViaCLI from "@/components/organisms/alerts/ProTipLogsViaCLI.vue";
import LogsWindow from "@/components/parts/app/showLogs/ShowLogsLogs.vue";
import FullscreenableComponent from "@/mixins/fullscreenable_component";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "LogsLive",
  components: {
    ProTipLogsViaCLI,
    LogsWindow,
    ButtonedSectionTitle,
  },
  mixins: [FullscreenableComponent],
  props: {
    app: Object,
    user: Object,
    logs: Array,
    streamLogs: Boolean,
    logsFontSize: String,
  },
  emits: [
    "clearClicked",
    "fontSizeChanged",
    "routerLogsToggled",
    "streamingToggled",
  ],
  computed: {
    viewArchiveLink() {
      return { name: Routes.App.Logs.Archives, params: { id: this.app.name } };
    },
  },
  methods: {
    streamingToggled(eventArgs) {
      this.$emit("streamingToggled", eventArgs);
    },
  },
});
</script>

<i18n>
  en:
    title: "Logs"
    subtitle: "Real time logs."
    viewArchives: "View archives"
  fr:
    title: "Logs"
    subtitle: "Logs temps réel."
    viewArchives: "Voir archives"
</i18n>
