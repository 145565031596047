export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "actions": {
          "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open application"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an application"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in admin"])}
        }
      },
      "fr": {
        "actions": {
          "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir l'application"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une application"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir dans le admin"])}
        }
      }
    }
  })
}
