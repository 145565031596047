<template>
  <SimpleModal
    size="sm:w-2/3 md:1/2 lg:w-1/2 2xl:w-1/3"
    @modalClosed="$emit('modalClosed')"
  >
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template v-slot:body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.containers"
                name="containers"
              >
                <CheckboxInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$t('containers')"
                  :errors="errors"
                  @update:modelValue="handleChange"
                />
              </Field>
            </div>
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.databases"
                name="databases"
              >
                <CheckboxInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$t('databases')"
                  :errors="errors"
                  @update:modelValue="handleChange"
                />
              </Field>
            </div>
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.duration"
                name="duration"
              >
                <p>{{ $t("duration") }}</p>
                <SelectInput
                  :name="field.name"
                  :modelValue="field.value"
                  :errors="errors"
                  label=""
                  :options="durationOptions"
                  @update:modelValue="handleChange"
                />
              </Field>
            </div>
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid || uselessSubmission"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Duration } from "luxon";
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import CheckboxInput from "@/components/atoms/inputs/CheckboxInput";
import SelectInput from "@/components/atoms/inputs/SelectInput";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "AddDataAccessConsentFormModal",
  components: {
    SelectInput,
    CheckboxInput,
    SimpleModal,
    SCButton,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["formSubmitted", "modalClosed"],
  computed: {
    uselessSubmission() {
      return !this.form.databases && !this.form.containers;
    },
    durationOptions() {
      const result = [
        { hour: 12 },
        { day: 1 },
        { day: 3 },
        { day: 7 },
        { month: 1 },
      ].map((d) => {
        const duration = Duration.fromObject(d).normalize();

        return {
          text: duration.toHuman(),
          value: duration.as("hours"),
        };
      });

      return result;
    },
  },
  methods: {
    submitForm() {
      if (this.uselessSubmission) {
        return;
      }

      this.$emit("formSubmitted", this.form);
    },
  },
});
</script>

<i18n>
en:
  title: "Authorize data access"
  subtitle: "Allow Scalingo operators to access your application"
  buttonName: "Consent"
  databases: "Access to databases metadata and monitoring data"
  containers: "Access to application runtime environment"
  duration: "Duration:"
  durations:
    1Hour: "1 hour"
    24Hours: "24 hours"
fr:
  title: "Autoriser l'accès aux données"
  subtitle: "Autoriser les opérateurs Scalingo à accéder à l'application"
  buttonName: "Consentir"
  databases: "Accès aux métadonnées et aux données de surveillance des bases de données"
  containers: "Accès à l'environnement d'exécution de l'application"
  duration: "Durée :"
  durations:
    1Hour: "1 heure"
    24Hours: "24 heures"
</i18n>
