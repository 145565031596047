/* eslint-disable @typescript-eslint/no-explicit-any */
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export interface PayPalInitiateResponse {
  /** URL that can be used to continue the transaction */
  redirect_url: string;
  /** Token needed to continue the transaction */
  token: string;
}

export interface PayPalValidateResponse {
  /** A success message */
  message: string;
  /** The updated billing profile */
  profile: string;
}

export default class PayPal {
  client: Scalingo;

  constructor(client: Scalingo) {
    this.client = client;
  }

  initiate(
    profileId: string,
    amount: string | number,
  ): Promise<PayPalInitiateResponse> {
    return unpackData(
      this.client
        .billingApiClient()
        .post(`profiles/${profileId}/credit_paypal`, {
          amount,
          mode: "in_context",
        }),
    );
  }

  validate(
    profileId: string,
    token: string,
    payer_id: string,
  ): Promise<PayPalValidateResponse> {
    return unpackData(
      this.client
        .billingApiClient()
        .post(`profiles/${profileId}/check_paypal`, {
          token,
          payer_id,
        }),
    );
  }
}
