export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
        "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])}
      },
      "fr": {
        "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
        "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
        "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource"])}
      }
    }
  })
}
