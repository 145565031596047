import { defineStore } from "pinia";

import {
  useCollectionStore,
  toPublicStore,
} from "@/lib/pinia/use-collection-store";
import { AppInfo, enhanceApp } from "@/lib/scalingo/modifiers/apps";
import { useSessionStore } from "@/stores/session";
import { availableRegionCodes } from "@/stores/user";

export const useAppInfosStore = defineStore("appInfos", () => {
  const { privateClient } = useSessionStore();

  const store = useCollectionStore({
    fetchPromise: async () => {
      const codes = await availableRegionCodes();
      const apps = codes.map((c) => privateClient(c).AppInfos.all());

      // Fetching all apps, discard if region is unavailable
      return Promise.allSettled(apps).then((results) => {
        const apps = [];

        for (const result of results) {
          if (result.status == "fulfilled") {
            apps.push(...result.value);
          }
        }

        return apps.map(enhanceApp);
      });
    },
  });

  return toPublicStore(store, {
    parentAppInfoFor(appInfo: AppInfo) {
      if (!appInfo || !appInfo.parent_id) return null;

      return (store.items.value ?? []).find(({ id }) => {
        return id === appInfo.parent_id;
      });
    },
  });
});
