import Rollbar from "rollbar";

import { logoutPipeline } from "@/lib/auth";
import { FormHandler } from "@/lib/handlers/form";
import { scalingoClient } from "@/lib/scalingo/client";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { Session } from "@/store/session";

import type { ComponentPublicInstance } from "vue";

interface EventData {
  reason: string;
  details: string;
}

export class ValidateAccountDeletionHandler extends FormHandler<void> {
  keyPath = "account.deletion";

  rollbar: Rollbar;

  constructor(
    component: ComponentPublicInstance,
    readonly deletionId: string,
    readonly token: string,
  ) {
    super(component);

    this.rollbar = component.$rollbar;
  }

  data(): Record<string, unknown> {
    return {};
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success");

      // Give a couple of second to let the user see the success toast, then logout
      setTimeout(() => {
        this.$store.dispatch(Session.actions.LOGOUT);
        logoutPipeline(this.rollbar).call();
      }, 2000);
    });
  }

  async submit(e: EventData): Promise<void> {
    const operation = new RemoteOperation<void>().follow(
      scalingoClient(this.$store).Users.confirmAccountDeletion(
        this.deletionId,
        {
          token: this.token,
          ...e,
        },
      ),
    );

    this.follow(operation);
  }
}
