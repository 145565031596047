<template>
  <ViewComponent
    :app="app"
    :containers="containers"
    :formHandler="formHandler"
    @formSubmitted="(e) => formHandler.submit(e)"
  >
  </ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/alerts/Creation.vue";
import { CreateAlertHandler } from "@/lib/handlers/create/alert";
import { listContainers, ensureContainers } from "@/store/containers";

export default defineComponent({
  name: "AlertsCreationContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formHandler: null,
    };
  },
  computed: {
    containers() {
      return listContainers(this.$store);
    },
  },
  async beforeMount() {
    ensureContainers(this.$store, { staleAfter: "always" });
    await this.containers.latestFetch.settled;

    if (this.containers.items.length > 0) {
      this.formHandler = new CreateAlertHandler(
        this,
        this.app,
        this.containers.items,
      );
    }
  },
});
</script>
