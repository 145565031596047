export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last deployment"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A summary about the last app deployment."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deployment yet"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier déploiement"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un résumé à propos du dernier déploiement de l'app."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun déploiement"])}
      }
    }
  })
}
