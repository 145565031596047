import { SCMRepoLink } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updateSCMRepoLink } from "@/store/scm-repo-link";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  deploy_review_apps_enabled: boolean;
  automatic_creation_from_forks_allowed: boolean;
  delete_on_close_enabled: boolean;
  hours_before_delete_on_close: number;
  delete_stale_enabled: boolean;
  hours_before_delete_stale: number;
}

export class EditReviewAppsConfigHandler extends FormHandler<SCMRepoLink> {
  keyPath = "reviewAppsConfig.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly scmRepoLink: SCMRepoLink,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    const { scmRepoLink } = this;

    return {
      deploy_review_apps_enabled: scmRepoLink
        ? scmRepoLink.deploy_review_apps_enabled
        : false,
      automatic_creation_from_forks_allowed: scmRepoLink
        ? scmRepoLink.automatic_creation_from_forks_allowed
        : false,
      delete_on_close_enabled: scmRepoLink
        ? scmRepoLink.delete_on_close_enabled
        : false,
      hours_before_delete_on_close: scmRepoLink
        ? scmRepoLink.hours_before_delete_on_close
        : 0,
      delete_stale_enabled: scmRepoLink
        ? scmRepoLink.delete_stale_enabled
        : false,
      hours_before_delete_stale: scmRepoLink
        ? scmRepoLink.hours_before_delete_stale
        : 0,
    };
  }

  async submit(form: FormData): Promise<void> {
    this.follow(await updateSCMRepoLink(this.$store, form));
  }
}
