<template>
  <VerticalPageMenu :items="menuItems"></VerticalPageMenu>
</template>

<script>
import { defineComponent } from "vue";

import VerticalPageMenu from "@/components/molecules/menus/VerticalPageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AccountMenu",
  components: { VerticalPageMenu },
  props: {
    user: Object,
    selection: String,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      const routerParams = {};
      const A = Routes.Account;

      const menuItems = [
        {
          name: this.$t("basic"),
          routerArgs: { name: A.Basic, params: routerParams },
        },
        {
          name: this.$t("authentication"),
          routerArgs: { name: A.Auth, params: routerParams },
        },
        {
          name: this.$t("keys"),
          routerArgs: { name: A.Keys, params: routerParams },
        },
        {
          name: this.$t("tokens"),
          routerArgs: { name: A.Tokens, params: routerParams },
        },
        {
          name: this.$t("integrations"),
          routerArgs: { name: A.Integrations, params: routerParams },
        },
        {
          name: this.$t("hds"),
          routerArgs: { name: A.HDS, params: routerParams },
        },
        {
          name: this.$t("referrals"),
          routerArgs: { name: A.Referrals, params: routerParams },
        },
      ];

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.routerArgs.name)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    basic: "Basic information"
    authentication: "Authentication"
    keys: "SSH Keys"
    tokens: "API Tokens"
    integrations: "SCM Integrations"
    referrals: "Referrals"
    hds: "Health Data (HDH)"
  fr:
    basic: "Information basique"
    authentication: "Authentification"
    keys: "Clés SSH"
    tokens: "Tokens API"
    integrations: "Intégrations SCM"
    referrals: "Parrainage"
    hds: "Données de santé (HDS)"
</i18n>
