export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable query statistics"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhance the efficiency of your queries by analyzing their performance! Gain detailed usage statistics to identify and optimize the most resource-intensive processes."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les statistiques de requêtes"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Améliorez l’efficacité de vos requêtes en analysant leurs performances ! Obtenez des statistiques d’usage détaillées, vous permettant d’identifier et d’optimiser les processus les plus gourmands en ressources."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
      }
    }
  })
}
