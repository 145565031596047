export function isMacOs(): boolean {
  const platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];

  return macosPlatforms.includes(platform);
}

export function noKeyboardShortCut(): boolean {
  const platform = window.navigator.platform;
  const macosPlatforms = ["Android", "iPhone", "iPad"];

  return macosPlatforms.includes(platform);
}

export function kbShortcut(key: string): string | string[] {
  if (isMacOs()) {
    return ["meta", key];
  } else {
    return ["ctrl", key];
  }
}

export function kbHumanShortcut(key: string): string {
  if (isMacOs()) {
    return `Cmd+${key.toUpperCase()}`;
  } else {
    return `Ctrl+${key.toUpperCase()}`;
  }
}
