import { App, Plugin } from "vue";
import { mapGetters } from "vuex";

import { User } from "@/store/user";

export const StoreAccessors: Plugin = {
  install(app: App): void {
    app.mixin({
      computed: {
        ...mapGetters({
          currentUser: User.getters.CURRENT,
        }),
      },
    });
  },
};

export default StoreAccessors;
