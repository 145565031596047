import { defineStore } from "pinia";

import { useCollectionStore } from "@/lib/pinia/use-collection-store-next";
import { currentDB, currentDBClient } from "@/stores/current/db";

const LINES_CAP = 500;

export const useDbLogsStore = defineStore("dbLogs", () => {
  const store = useCollectionStore({
    fetchPromise: async () => {
      const { id } = currentDB();
      const client = await currentDBClient();

      return client.Logs.retrieveFor(id).then((logs) => logs.split("\n"));
    },
  });

  return {
    ...store,
    /** This store is capped to a maximum number of items */
    insertItems(lines: string[]) {
      store.insertItems(lines);

      // This should not happen as we just pushed items,
      // but the type system doesn't know that.
      if (!store.items.value) return;

      const len = store.items.value.length;
      if (len > LINES_CAP) {
        const deleteCount = len - LINES_CAP;

        store.spliceItems({ start: 0, deleteCount });
      }
    },
  };
});
