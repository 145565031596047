<template>
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="(e) => $emit('click', e)"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2683 0.29282L11.7073 0.731934C11.8944 0.918913 11.9997 1.17258 11.9999 1.43718V1.94283C12.0033 2.21202 11.8977 2.47115 11.7073 2.66138L10.4768 3.87227C10.4143 3.93524 10.3293 3.97067 10.2406 3.97067C10.152 3.97067 10.0669 3.93524 10.0045 3.87227L8.13545 1.97609C8.0087 1.84674 8.0087 1.63972 8.13545 1.51036L9.33936 0.29282C9.52954 0.102314 9.78859 -0.00325554 10.0577 7.65504e-05H10.5632C10.8277 0.000309483 11.0813 0.105607 11.2683 0.29282ZM9.21298 5.1233C9.33973 4.99394 9.33973 4.78692 9.21298 4.65757L7.31067 2.78136C7.24822 2.71838 7.16322 2.68295 7.07454 2.68295C6.98587 2.68295 6.90087 2.71838 6.83842 2.78136L1.47072 8.15053C1.34095 8.27917 1.23906 8.43318 1.17141 8.60295L0.0273636 11.4905C-0.0374437 11.6403 0.0161029 11.8151 0.153741 11.903C0.248008 11.9971 0.389344 12.0259 0.512917 11.9761L3.39963 10.8052C3.56935 10.7375 3.72332 10.6356 3.85193 10.5058L9.21298 5.1233Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EditGlyph",
  emits: ["click"],
});
</script>
