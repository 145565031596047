export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the point of contact"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you no longer wish to deploy HDH apps, you can remove the point of contact. You will need to add another one to deploy HDH apps on Scalingo."])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You cannot delete the point of contact while you still own HDH apps (currently: ", _interpolate(_named("count")), ")."])},
        "error": {
          "generic": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not delete the contact."])}
          }
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppprimer le point de contact"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne souhaitez plus déployer d'applications HDS, vous pouvez supprimer le point de contact. Vous devrez en ajouter un autre pour déployer les applications HDS sur Scalingo."])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous ne pouvez pas supprimer le point de contact si vous possédez toujours des apps HDS (actuellement: ", _interpolate(_named("count")), ")."])},
        "error": {
          "generic": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le contact."])}
          }
        }
      }
    }
  })
}
