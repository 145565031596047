<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C7.26333 4 6.66667 4.61023 6.66667 5.36364V6.04545H10V5.36364C10 4.61023 9.40333 4 8.66667 4H8ZM15.3333 4C14.5967 4 14 4.61023 14 5.36364V6.04545H17.3333V5.36364C17.3333 4.61023 16.7367 4 16 4H15.3333ZM5.33333 7.40909C4.59667 7.40909 4 8.01932 4 8.77273V17.6364C4 18.3898 4.59667 19 5.33333 19H18.6667C19.4033 19 20 18.3898 20 17.6364V8.77273C20 8.01932 19.4033 7.40909 18.6667 7.40909H5.33333Z"
      fill="currentColor"
    />
    <circle cx="18" cy="16" r="5.5" fill="white" stroke="white" />
    <circle cx="18" cy="16" r="5" fill="currentColor" />
    <path
      d="M19.9593 16.354C19.9979 17.5006 19.0868 18.4609 17.9245 18.4988C16.7622 18.5368 15.7887 17.6381 15.7502 16.4916C15.7482 16.4563 15.7605 16.4218 15.7844 16.3955C15.8111 16.368 15.8484 16.353 15.887 16.354H16.1501C16.2188 16.3538 16.2761 16.4058 16.2816 16.4734C16.3473 17.3154 17.0814 17.9523 17.9363 17.909C18.7913 17.8657 19.4554 17.158 19.4333 16.3138C19.4112 15.4696 18.7109 14.7967 17.8548 14.797V15.4094C17.8553 15.4446 17.841 15.4784 17.8153 15.5028L17.7627 15.5547C17.738 15.5793 17.7044 15.5931 17.6693 15.5931C17.6342 15.5931 17.6006 15.5793 17.5759 15.5547L16.6447 14.6387C16.5942 14.5874 16.5942 14.5058 16.6447 14.4544L17.5759 13.5384C17.6006 13.5138 17.6342 13.5 17.6693 13.5C17.7044 13.5 17.738 13.5138 17.7627 13.5384L17.8153 13.5903C17.841 13.6147 17.8553 13.6485 17.8548 13.6837V14.278C19.0171 14.278 19.9593 15.2075 19.9593 16.354Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonResumedGlyph",
});
</script>
