<template>
  <section>
    <Card>
      <template v-slot:header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template #buttons>
            <SCButton @click="$emit('startCollaboratorCreation')">
              {{ $t("add") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template v-slot:body>
        <template v-if="collaborators.latestFetch.isLoading">
          <LoadingCardState />
        </template>

        <template v-else-if="collaborators.latestFetch.isFinished">
          <CollaboratorsList
            v-if="collaborators.items.length > 0"
            class="mt-2"
            :collaborators="collaborators"
            :app="app"
            :scmRepoLink="scmRepoLink"
            @copyInvitationLink="(e) => $emit('copyInvitationLink', e)"
            @removeCollaborator="(e) => $emit('startCollaboratorDeletion', e)"
          />
          <NoCollaborators v-else class="mb-4" />
        </template>
      </template>
    </Card>

    <AddCollaboratorFormModal
      v-if="createCollaboratorHandler"
      :formHandler="createCollaboratorHandler"
      @modalClosed="$emit('cancelCollaboratorCreation')"
      @formSubmitted="(e) => $emit('submitCollaboratorCreation', e)"
    ></AddCollaboratorFormModal>
    <RemoveCollaboratorModal
      v-if="deleteCollaboratorHandler"
      :formHandler="deleteCollaboratorHandler"
      @modalClosed="$emit('cancelCollaboratorDeletion')"
      @formSubmitted="(e) => $emit('submitCollaboratorDeletion', e)"
    ></RemoveCollaboratorModal>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import NoCollaborators from "@/components/organisms/collaborators/NoCollaborators.vue";
import AddCollaboratorFormModal from "@/components/parts/appSettings/collaboratorsSettings/AddCollaboratorFormModal.vue";
import CollaboratorsList from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorsList.vue";
import RemoveCollaboratorModal from "@/components/parts/appSettings/collaboratorsSettings/RemoveCollaboratorModal.vue";

export default defineComponent({
  name: "CollaboratorsSettings",
  components: {
    RemoveCollaboratorModal,
    AddCollaboratorFormModal,
    LoadingCardState,
    CardHeader,
    NoCollaborators,
    CollaboratorsList,
    Card,
    SCButton,
  },
  props: {
    app: Object,
    scmRepoLink: Object,
    user: Object,
    collaborators: Object,
    createCollaboratorHandler: Object,
    deleteCollaboratorHandler: Object,
  },
  emits: [
    "cancelCollaboratorCreation",
    "cancelCollaboratorDeletion",
    "copyInvitationLink",
    "startCollaboratorCreation",
    "startCollaboratorDeletion",
    "submitCollaboratorCreation",
    "submitCollaboratorDeletion",
  ],
  data: function () {
    return {
      collaboratorToSuppress: null,
    };
  },
});
</script>

<i18n>
en:
  title: "Collaborators"
  subtitle: "Collaborators of your app."
  add: "Add"
fr:
  title: "Collaborateurs"
  subtitle: "Collaborateurs de votre app."
  add: "Ajouter"
</i18n>
