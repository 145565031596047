<template>
  <StepCard
    :step="3"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <template v-if="enabled && loadingOperation">
      <template v-if="loadingOperation.isLoading">
        <LoadingCardState />
      </template>

      <template v-else-if="loadingOperation.isSuccess">
        <template v-if="!selectedAddonProvider">
          <FormAlert class="mt-4" styling="error">
            <template #text>
              {{
                $t("errors.providerNotFound", {
                  addon: $route.params.providerId,
                })
              }}
            </template>
          </FormAlert>
          <div class="text-sm text-scale-5 text-right mt-1">
            <a
              class="cursor-pointer"
              :title="$t('changeProviderTitle')"
              @click="$emit('changeAddonProvider')"
            >
              {{ $t("actions.editProvider") }}
            </a>
          </div>
        </template>
        <template v-else-if="!selectedAddonPlan">
          <FormAlert class="mt-4" styling="error">
            <template #text>
              {{
                $t("errors.planNotFound", {
                  addon: selectedAddonProvider.name,
                  plan: $route.params.planId,
                })
              }}
            </template>
          </FormAlert>
          <div class="text-sm text-scale-5 text-right mt-1">
            <a class="cursor-pointer" @click="$emit('changeAddonPlan')">
              {{ $t("actions.editPlan") }}
            </a>
          </div>
        </template>
        <template v-else>
          <ShowSelectedAddon
            :addon="addon"
            :provider="selectedAddonProvider"
            :plan="selectedAddonPlan"
            :displayPrices="displayPrices"
            @changeAddonPlan="$emit('changeAddonPlan')"
          />

          <SandboxWarning v-if="isSandbox" class="mb-6"></SandboxWarning>

          <FormAlert
            v-if="paymentRequired || hasErrorMessages"
            class="mt-2 mb-6"
            styling="error"
          >
            <template #text>
              <template v-if="paymentRequired">
                <template v-if="currentUserIsOwner">
                  <p>
                    {{ $t("errors.paymentRequired.owner") }}
                    <router-link :to="billingProfileRoute" class="underline">
                      {{ $t("billingProfile") }}
                    </router-link>
                    {{ $t("errors.paymentRequired.andAdd") }}

                    <router-link :to="paymentMethodsRoute" class="underline">
                      {{ $t("paymentMethod") }} </router-link
                    >.
                  </p>
                </template>
                <template v-else>
                  {{ owner.username }}

                  {{ $t("errors.paymentRequired.collaborator") }}
                </template>
              </template>
              <template v-else-if="hasErrorMessages">
                {{ context.errors.message }}
              </template>
            </template>
          </FormAlert>

          <div class="flex">
            <SCButton
              block
              kind="primary"
              size="lg"
              class="flex-grow"
              :loading="context.info.isLoading"
              @click="performAction"
            >
              {{ actionTitle }}
            </SCButton>
          </div>

          <div v-if="!addon" class="text-sm text-scale-5 text-right mt-1">
            <a class="cursor-pointer" @click="$emit('changeAddonProvider')">
              {{ $t("actions.editProvider") }}
            </a>
          </div>
        </template>
      </template>
    </template>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import SandboxWarning from "@/components/parts/app/newAddons/SandboxWarning.vue";
import ShowSelectedAddon from "@/components/parts/app/newAddons/ShowSelectedAddon.vue";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ValidationCard",
  components: {
    SandboxWarning,
    StepCard,
    ShowSelectedAddon,
    SCButton,
    LoadingCardState,
    FormAlert,
  },
  props: {
    owner: Object,
    enabled: Boolean,
    loadingOperation: [RemoteOperation, Object],
    addon: Object,
    selectedAddonProvider: Object,
    selectedAddonPlan: Object,
    context: Object,
    displayPrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "changeAddonPlan",
    "changeAddonProvider",
    "planChangeRequested",
    "provisionningRequested",
  ],
  computed: {
    actionTitle() {
      if (this.addon) {
        return this.$t("actions.changePlan");
      } else {
        return this.$t("actions.provision");
      }
    },
    billingProfileRoute() {
      return { name: Routes.Billing.Profile };
    },
    paymentMethodsRoute() {
      return { name: Routes.Billing.PaymentMethods };
    },
    paymentRequired() {
      return this.context.info.isError
        ? this.context.errors.meta.paymentRequired
        : false;
    },
    isSandbox() {
      return this.selectedAddonPlan.name.includes("sandbox");
    },
    hasErrorMessages() {
      if (!this.context.info.isError) return false;

      return !!this.context.errors.message;
    },
    currentUserIsOwner() {
      return this.owner.id === this.currentUser.id;
    },
  },
  methods: {
    performAction() {
      if (this.addon) {
        this.$emit("planChangeRequested");
      } else {
        this.$emit("provisionningRequested");
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Validation"
  subtitle: "Review your addon before provisioning"
  billingProfile: "billing profile"
  paymentMethod: "a payment method"
  actions:
    provision: "Provision addon"
    changePlan: "Confirm plan change"
    editProvider: "Change addon provider"
    editPlan: "Choose another plan"
  errors:
    providerNotFound: "Either there is no '{addon}' addon, or it is already added to your app."
    planNotFound: "There is no plan '{plan}' for {addon}"
    paymentRequired:
      owner: "You must end the free trial to use a paid addon. Please fill your "
      andAdd: " and add "
      collaborator: "(the owner) must end the free trial to use a paid addon."
fr:
  title: "Validation"
  subtitle: "Vérifier le provisionnement de votre addon"
  billingProfile: "profil de facturation"
  paymentMethod: "un moyen de paiement"
  actions:
    provision: "Provisionner l'addon"
    changePlan: "Comfirmer le changement de plan"
    editProvider: "Choisir un autre type d'addon"
    editPlan: "Choisir un autre plan"
  errors:
    providerNotFound: "Soit il n'y a pas '{addon}' addon, soit il a déjà été ajouté à votre application."
    planNotFound: "Il n'y a pas de '{plan}' pour {addon}"
    paymentRequired:
      owner: "Vous devez mettre fin à l'essai gratuit pour provisionner un addon payant. Veuillez remplir votre "
      andAdd: " et ajouter "
      collaborator: "(le⋅la propriétaire) doit mettre fin à l'essai gratuit pour provisionner un addon payant."
</i18n>
