export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger condition"])}
        },
        "title": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete alert"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit alert"])}
        }
      },
      "fr": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition de déclenchement"])}
        },
        "title": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'alerte"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'alerte"])}
        }
      }
    }
  })
}
