export function databaseName(dbName: string): string {
  switch (dbName) {
    case "postgresql":
      return "PostgreSQL";
    case "redis":
      return "Redis";
    case "mysql":
      return "MySQL";
    case "mongo":
      return "MongoDB";
    case "elasticsearch":
      return "Elasticsearch";
    case "influxdb":
      return "InfluxDB";
  }

  return "";
}
