export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "deployments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual deployment"])}
      },
      "fr": {
        "deployments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement manuel"])}
      }
    }
  })
}
