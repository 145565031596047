<template>
  <SCTable :headers="headers" :items="backups" :paginated="true" :perPage="5">
    <template #item.started_at="{ item }">
      {{ formatDateTime(item._startedOrCreatedAt) }}
    </template>
    <template #item.method="{ item }">
      <span v-if="item.method === 'periodic'">{{ $t("periodic") }}</span>
      <span v-if="item.method === 'manual'">{{ $t("manual") }}</span>
    </template>
    <template #item.size="{ item }">
      <div v-if="item.size && item.size > 0">
        {{ humanReadableSize(item.size) }}
      </div>
      <div v-else>
        {{ $t("notAvailable") }}
      </div>
    </template>
    <template #item.status="{ item }">
      <BackupStatus :item="item" />
    </template>
    <template #item.actions="{ item, index }">
      <div v-if="inSuccess(item)" class="flex flex-row space-x-2 justify-end">
        <SCButton
          v-if="db.type_name === 'redis'"
          kind="table"
          :title="$t('restore')"
          class="hover:underline"
          :rowIndex="index"
          :disabled="backupRunningOrScheduled"
          @click="$emit('startBackupRestoration', { backup: item })"
        >
          <span class="flex flex-row items-baseline space-x-1.5"
            ><font-awesome-icon icon="arrows-rotate" class="h-3 w-3" /><span>{{
              $t("restore")
            }}</span></span
          >
        </SCButton>
        <SCButton
          kind="table"
          :title="$t('download')"
          :rowIndex="index"
          class="hover:underline"
          :disabled="downloadInfos[item.id]?.isLoading"
          @click="$emit('downloadBackup', { backup: item })"
        >
          <span class="flex flex-row items-baseline space-x-2"
            ><font-awesome-icon icon="download" class="h-3 w-3" /><span>{{
              $t("download")
            }}</span></span
          >
        </SCButton>
      </div>
      <div v-else class="h-8"></div>
    </template>
    <template #bottomFiller="{ remaining }">
      <div v-for="n in remaining" :key="n" class="h-12"></div>
    </template>
  </SCTable>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import BackupStatus from "@/components/parts/db/backups/BackupStatus.vue";
import { humanReadableSize } from "@/lib/utils/size";
import { formatDateTime } from "@/lib/utils/time";

export default {
  name: "BackupsTable",
  components: { FontAwesomeIcon, SCButton, BackupStatus, SCTable },
  props: {
    db: Object,
    backups: Array,
    downloadInfos: Object,
    backupRunningOrScheduled: Boolean,
  },
  data() {
    return {
      headers: [
        {
          value: "started_at",
          text: this.$t("headers.startedAt"),
          class: "xl:w-2/6",
        },
        { value: "status", text: this.$t("headers.status"), class: "xl:w-1/6" },
        { value: "method", text: this.$t("headers.type"), class: "xl:w-1/6" },
        { value: "size", text: this.$t("headers.size"), class: "xl:w-1/6" },
        { value: "actions", text: "", class: "xl:w-1/6" },
      ],
    };
  },
  methods: {
    formatDateTime,
    humanReadableSize,
    inSuccess(item) {
      return item.status === "done";
    },
  },
};
</script>

<i18n>
en:
  headers:
    startedAt: "Started at"
    type: "Type"
    duration: "Duration"
    size: "Size"
    status: "Status"
  periodic: "Scheduled"
  manual: "Manual"
  notAvailable: "Size not available"
  restore: Restore
  download: Download
fr:
  headers:
    startedAt: "Commencée à"
    type: "Type"
    duration: "Durée"
    size: "Taille"
    status: "Statut"
  periodic: "Planifié"
  manual: "Manuel"
  notAvailable: "Taille non disponible"
  restore: Restaurer
  download: Télécharger
</i18n>
