import { App } from "scalingo/lib/models/regional";
import { CreateParams, UpdateParams } from "scalingo/lib/params/regional/apps";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  UPDATE,
  CREATE,
  HANDLE_OPERATION,
  FETCH,
  DESTROY,
  RENAME,
  TRANSFER,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ONE, ADD, DELETE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { broadcastGlobalError, buildMapping } from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class AppsStore extends CollectionStore<App> {
  actions = CollectionStore.buildActions<App>({
    [CREATE](context, { region, ...payload }) {
      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, region).Apps.create(payload),
        resolveAction: ADD,
      });
    },
    [FETCH](context, { name, region }) {
      const promise = scalingoClient(context, region).Apps.find(name);

      // Not using a RemoteOperation here, we have to integrate with the store by hand.
      return promise.then(
        (resolved) => {
          context.commit(SET_ONE, resolved);
          return resolved;
        },
        (error: unknown) => {
          broadcastGlobalError(error);
          throw error;
        },
      );
    },
    [UPDATE](context, { app, payload }) {
      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, app.region).Apps.update(
          app.id,
          payload,
        ),
        resolveAction: SET_ONE,
      });
    },
    [RENAME](context, { app, currentName, name }) {
      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, app.region).Apps.rename(
          app.id,
          currentName,
          name,
        ),
        resolveAction: SET_ONE,
      });
    },
    [TRANSFER](context, { app, currentName, ownerID }) {
      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, app.region).Apps.transfer(
          app.id,
          currentName,
          ownerID,
        ),
        resolveAction: SET_ONE,
      });
    },
    [DESTROY](context, { app }) {
      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, app.region).Apps.destroy(
          app.id,
          app.name,
        ),
        resolveAction: () => context.commit(DELETE, app),
      });
    },
  });
  mutations = CollectionStore.buildMutations<App>();
  getters = CollectionStore.buildGetters<App>();
}

export const Apps = buildMapping(new AppsStore(), "apps");

export function createApp(
  store: ApplicationStore,
  region: string,
  payload: CreateParams,
): Promise<RemoteOperation<App>> {
  return store.dispatch(Apps.actions.CREATE, { region, ...payload });
}

export function updateApp(
  store: ApplicationStore,
  app: App,
  payload: UpdateParams,
): Promise<RemoteOperation<App>> {
  return store.dispatch(Apps.actions.UPDATE, { app, payload });
}

export function renameApp(
  store: ApplicationStore,
  app: App,
  currentName: string,
  name: string,
): Promise<RemoteOperation<App>> {
  return store.dispatch(Apps.actions.RENAME, { app, currentName, name });
}

export function transferApp(
  store: ApplicationStore,
  app: App,
  currentName: string,
  ownerID: string,
): Promise<RemoteOperation<App>> {
  return store.dispatch(Apps.actions.TRANSFER, {
    app,
    currentName,
    ownerID,
  });
}

export function findApp(
  store: ApplicationStore,
  region: string,
  name: string,
): Promise<RemoteOperation<App>> {
  return store.dispatch(Apps.actions.FETCH, { region, name });
}

export function destroyApp(
  store: ApplicationStore,
  app: App,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Apps.actions.DESTROY, { app });
}
