import { App } from "scalingo/lib/models/regional";
import {
  CronDefinition,
  CronJob as CronJobModel,
} from "scalingo/lib/models/regional/cron_tasks";

import { scalingoClient } from "@/lib/scalingo/client";
import { REFRESH, HANDLE_FETCH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export interface CronJob extends CronJobModel {
  _schedule: string;
}

/** Extracts the schedule part of the command into its own field */
export function enhanceJob(job: CronJobModel): CronJob {
  const schedule = job.command.split(" ").slice(0, 5).join(" ");

  return { ...job, _schedule: schedule };
}

export class CronTasksStore extends CollectionStore<CronJob> {
  actions = CollectionStore.buildActions<CronJob>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).CronTasks.for(
          currentApp.id,
        ),
        resolveAction: (response: CronDefinition) => {
          context.commit(SET_ALL, response.jobs?.map(enhanceJob));
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<CronJob>();
  getters = CollectionStore.buildGetters<CronJob>();
}

export const CronTasks = buildMapping(new CronTasksStore(), "cronTasks");

export function listCronTasks(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<CronJob>>,
): CollectionWithFetch<CronJob> {
  return {
    items: listItems(store.getters[CronTasks.getters.ALL], opts),
    latestFetch: store.getters[CronTasks.getters.LATEST_FETCH],
  };
}

export function ensureCronTasks(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(CronTasks.actions.ENSURE, opts);
}
