<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AppRenameGlyph class="h-6 w-6 text-scale-6"></AppRenameGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      <span
        >{{ event.type_data.old_name }} → {{ event.type_data.new_name }}</span
      >
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AppRenameGlyph from "@/components/atoms/glyphs/AppRenameGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "RenameEventEvent",
  components: { BaseEvent, AppRenameGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "App renamed"
fr:
  title: "App renommée"
</i18n>
