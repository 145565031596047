export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account deletion"])},
        "hint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are continuously working on our platform to make it the best hosting service for developers ever. We are sorry to hear that it didn't fit your needs."])},
        "hint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a few minutes to spare to let us know why you are leaving, this will help us improve our platform."])},
        "options": {
          "useless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I no longer use the app hosted on Scalingo"])},
          "otherService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I migrated to another hosting service"])},
          "dislike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I disliked the experience on Scalingo"])},
          "testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was just a test"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
        },
        "errors": {
          "wrongId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deletion ID is invalid - please restart the process"])},
          "wrongToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The deletion token is invalid - please restart the process"])}
        },
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would make you come back?"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete my account"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de compte"])},
        "hint1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous travaillons continuellement sur notre plateform pour en faire le meilleur service d'hébergement pour les développeurs. Nous sommes désolé de ne pas répondre a vos besoins."])},
        "hint2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez quelques minutes pour nous communiquer les raisons de votre départ, ceci nous permettra de nous améliorer."])},
        "options": {
          "useless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'utilise plus l'application hébergée sur Scalingo"])},
          "otherService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai migré vers un autre service d'hébergement"])},
          "dislike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas apprécié l'expérience sur Scalingo"])},
          "testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'était juste un test"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
        },
        "errors": {
          "wrongId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ID de suppression est incorrect - veuillez recommencer le processus"])},
          "wrongToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le token de suppression est incorrect - veuillez recommencer le processus"])}
        },
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce qui pourrait vous faire revenir?"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer mon compte"])}
      }
    }
  })
}
