import { Backup as OriginalBackup } from "@/lib/scalingo/dbapi/backups";

type AdditionnalInfo = {
  /** "started_at" if filled, otherwise "created_at" */
  _startedOrCreatedAt: string;
};

export type Backup = OriginalBackup & AdditionnalInfo;

export function modifyItem(item: OriginalBackup): Backup {
  return {
    ...item,
    _startedOrCreatedAt: item.started_at || item.created_at,
  };
}

export function modifyCollection(items: OriginalBackup[]): Backup[] {
  return items.map(modifyItem);
}
