export const POSSIBLE_SORTS = [
  {
    key: "name-a-z",
    i18nKey: "nameAz",
    default: false,
    value: {
      sortBy: "name",
      sortDirection: "asc",
    },
  },
  {
    key: "name-z-a",
    i18nKey: "nameZa",
    default: false,
    value: {
      sortBy: "name",
      sortDirection: "desc",
    },
  },
  {
    key: "recently-updated",
    i18nKey: "recentlyUpdated",
    default: true,
    value: {
      sortBy: "deployedOrCreatedAt",
      sortDirection: "desc",
    },
  },
  {
    key: "least-recently-updated",
    i18nKey: "leastRecentlyUpdated",
    default: false,
    value: {
      sortBy: "deployedOrCreatedAt",
      sortDirection: "asc",
    },
  },
];

export const DEFAULT_POSSIBLE_SORT = POSSIBLE_SORTS.find(
  (item) => item.default,
);
