<template>
  <div class="flex flex-col rounded border border-padding animate-pulse">
    <div class="flex px-2">
      <div class="flex flex-grow py-4">
        <div class="flex items-center pl-4">
          <div class="rounded bg-scale-2 h-14 w-14"></div>
        </div>
        <div class="flex items-center px-4">
          <div class="flex flex-col">
            <div class="h-6 w-28 bg-scale-2 rounded mb-4"></div>

            <div class="h-4 w-56 bg-scale-2 rounded"></div>
          </div>
        </div>
      </div>
      <div class="flex px-4">
        <div class="flex items-center flex-grow">
          <div class="h-4 w-12 mr-2 bg-scale-2 rounded"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingCartridge",
});
</script>

<style scoped>
.border-padding {
  padding: 1px;
}
</style>
