import { APIError } from "scalingo/lib/errors";

import { ActionHandler } from "@/lib/handlers/action";
import { PaymentMethod } from "@/lib/scalingo/payment-methods";
import { deletePaymentMethod } from "@/store/payment-methods";

import type { ComponentPublicInstance } from "vue";

export class DeletePaymentMethodHandler extends ActionHandler<void> {
  get keyPath(): string {
    if (this.paymentMethod.object_type === "card")
      return "paymentMethod.card.delete";
    else return "paymentMethod.sepa.delete";
  }

  constructor(
    readonly component: ComponentPublicInstance,
    readonly paymentMethod: PaymentMethod,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => {
      const error = this.operation?.error as APIError;
      this.notify("error", { message: error.message });
    });
  }

  async submit(): Promise<void> {
    this.follow(await deletePaymentMethod(this.$store, this.paymentMethod));
  }
}
