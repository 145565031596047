export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": {
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display the domain, upload a custom certificate"])},
          "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to perform some actions to finish the SSL certificate's generation"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SSL certificate generation went wrong"])},
          "star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make the domain canonical"])},
          "unstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove the domain canonicity"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this domain"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open help"])}
        }
      },
      "fr": {
        "title": {
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le nom de domaine, uploader un certificat"])},
          "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a des actions a faire pour finir la génération du certificat SSL"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La génération du certificat SSL est en erreur"])},
          "star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre ce domaine canonique"])},
          "unstar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la canonicité du domaine"])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le domaine"])},
          "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir l'aide"])}
        }
      }
    }
  })
}
