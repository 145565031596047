<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <RestartGlyph class="text-color-1" />
      <div class="text-color-1 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      <span class="font-medium ml-1">
        {{ fromTo }}
      </span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import RestartGlyph from "@/components/atoms/glyphs/RestartGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "ScaleEvent",
  components: { BaseEvent, RestartGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fromTo() {
      const prev = this.event.type_data.previous_containers;
      const current = this.event.type_data.containers;
      let fromTo = "";
      for (const key in prev) {
        const prevValue = prev[key];
        const currentValue = current[key];
        if (prevValue === currentValue) {
          continue;
        }
        if (fromTo !== "") {
          fromTo += ", ";
        }
        fromTo += key + " " + prevValue + "→" + currentValue;
      }

      return fromTo;
    },
    type() {
      return this.event.type_data.scope.join(", ");
    },
  },
});
</script>

<i18n>
en:
  title: "Containers scaled"
  from: "From"
  to: "to"
fr:
  title: "Containers scaled"
  from: "De"
  to: "à"
</i18n>
