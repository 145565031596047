<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <PaymentAttemptGlyph class="h-6 w-6 text-scale-6"></PaymentAttemptGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more
      >{{ $t("method") }}
      <span class="font-medium ml-1">{{ event.type_data.payment_method }}</span>
      <span class="ml-1"
        >- {{ $t("amount") }}
        <span class="font-medium ml-1">{{ event.type_data.amount }}</span></span
      >
      <span class="ml-1"
        >- {{ $t("state") }}
        <span class="font-medium ml-1">{{ event.type_data.state }}</span></span
      >
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import PaymentAttemptGlyph from "@/components/atoms/glyphs/PaymentAttemptGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent, PaymentAttemptGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Payment attempted"
  method: "Method:"
  amount: "Amount:"
  state: "State:"
fr:
  title: "Tentative de paiement"
  method: "Méthode :"
  montant: "Montant :"
  state: "Status "
</i18n>
