<template>
  <div class="flex flex-col mid-xl:grid mid-xl:grid-cols-2 gap-4 mt-6">
    <ContainersCard
      :app="app"
      :containers="containers"
      @restartAction="(e) => $emit('restartAction', e)"
    />
    <AddonsCard :app="app" :addons="addons" :user="user" />

    <div class="flex flex-col order-2 mid-xl:order-none">
      <LastDeploymentCard :app="app" :lastDeployment="lastDeployment" />

      <DataAccessConsentCard
        class="mt-4"
        :app="app"
        :dataAccessConsent="dataAccessConsent"
        :createDataAccessConsentHandler="createDataAccessConsentHandler"
        @startDataAccessConsentCreation="
          (e) => $emit('startDataAccessConsentCreation', e)
        "
      />
    </div>

    <ActivityCard
      class="order-1 mid-xl:order-none"
      :app="app"
      :events="events"
    />

    <ReviewAppsCard
      v-if="displayReviewAppsCard"
      class="lg:col-span-2 order-3 mid-xl:order-none"
      :app="app"
      :reviewApps="reviewApps"
      :scmRepoLink="scmRepoLink"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ActivityCard from "@/components/parts/app/showApp/ActivityCard.vue";
import AddonsCard from "@/components/parts/app/showApp/AddonsCard.vue";
import ContainersCard from "@/components/parts/app/showApp/ContainersCard.vue";
import DataAccessConsentCard from "@/components/parts/app/showApp/DataAccessConsentCard.vue";
import LastDeploymentCard from "@/components/parts/app/showApp/LastDeploymentCard.vue";
import ReviewAppsCard from "@/components/parts/app/showApp/ReviewAppsCard.vue";

export default defineComponent({
  name: "ShowAppCards",
  components: {
    ActivityCard,
    AddonsCard,
    ContainersCard,
    ReviewAppsCard,
    DataAccessConsentCard,
    LastDeploymentCard,
  },
  props: {
    addons: Object,
    collaborators: Object,
    containers: Object,
    events: Object,
    reviewApps: Object,
    scmRepoLink: Object,
    lastDeployment: Object,
    app: Object,
    user: Object,
    createDataAccessConsentHandler: Object,
    dataAccessConsent: Object,
  },
  emits: ["restartAction", "startDataAccessConsentCreation"],
  computed: {
    displayReviewAppsCard() {
      return !this.app.parent_app_name && !!this.scmRepoLink;
    },
  },
});
</script>
