<template>
  <svg
    class="h-6 w-6"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8499 10.4399C11.9445 10.5337 11.9978 10.6615 11.9978 10.7949C11.9978 10.9282 11.9445 11.056 11.8499 11.1499L11.1499 11.8499C11.056 11.9445 10.9282 11.9978 10.7949 11.9978C10.6615 11.9978 10.5337 11.9445 10.4399 11.8499L5.99985 7.40985L1.55985 11.8499C1.46597 11.9445 1.33817 11.9978 1.20485 11.9978C1.07153 11.9978 0.943736 11.9445 0.849852 11.8499L0.149852 11.1499C0.055196 11.056 0.00195312 10.9282 0.00195312 10.7949C0.00195312 10.6615 0.055196 10.5337 0.149852 10.4399L4.58985 5.99985L0.149852 1.55985C0.055196 1.46597 0.00195313 1.33817 0.00195313 1.20485C0.00195313 1.07153 0.055196 0.943736 0.149852 0.849852L0.849852 0.149852C0.943736 0.055196 1.07153 0.00195313 1.20485 0.00195313C1.33817 0.00195313 1.46597 0.055196 1.55985 0.149852L5.99985 4.58985L10.4399 0.149852C10.5337 0.055196 10.6615 0.00195312 10.7949 0.00195312C10.9282 0.00195312 11.056 0.055196 11.1499 0.149852L11.8499 0.849852C11.9445 0.943736 11.9978 1.07153 11.9978 1.20485C11.9978 1.33817 11.9445 1.46597 11.8499 1.55985L7.40985 5.99985L11.8499 10.4399Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CrossGlyph",
});
</script>
