<template>
  <ConfirmModal
    :immediate="false"
    kind="danger"
    :promiseInfo="context.info"
    :strongConfirmation="context.app.name"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p>
      {{
        $t("disclaimer.line1", {
          name: context.addon.addon_provider.name,
          plan: context.addon.plan.display_name,
        })
      }}
    </p>

    <p class="mt-2">
      {{ $t("disclaimer.line2") }}
    </p>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  components: { ConfirmModal },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
  setup() {
    return { formatDateTime };
  },
});
</script>

<i18n>
en:
  title: "Delete an addon"
  disclaimer:
    line1: "Do you really want delete this \"{name}\/{plan}\" addon?"
    line2: "Deleting this addon could impact the linked app and create disruption."
fr:
  title: "Supprimer un addon"
  disclaimer:
    line1: "Souhaitez-vous vraiment supprimer l'addon \"{name}\/{plan}\" ?"
    line2: "La suppression de cet addon pourrait avoir un impact sur l'application liée et créer des perturbations."
</i18n>
