<template>
  <ConfirmModal
    :immediate="false"
    :promiseInfo="context.info"
    :title="$t(`${upgradeType}.title`)"
    :weakConfirmation="isPostgresDb && upgradeType === 'major'"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p>
      {{
        $t("upgrade", {
          currentVersion: readableFullVersion(currentDbVersion),
          nextVersion: readableFullVersion(nextDbVersion),
        })
      }}
    </p>

    <p v-if="isPostgresDb && upgradeType === 'major'" class="mt-3">
      {{ $t("major.pgMessage") }}
    </p>

    <p class="mt-3">
      {{ $t(`${upgradeType}.description${minorPlanType}`) }}
    </p>

    <InformationAlert class="mt-3">
      {{ $t(`${upgradeType}.disclaimer`) }}
    </InformationAlert>

    <SCAlert v-if="context.info?.isError" class="mt-4" kind="error">
      <div class="flex flex-row">
        <ExclamationTriangleIcon class="h-6 mr-1" />
        {{ $t("error.message") }}
      </div>
    </SCAlert>

    <template #weakConfirmationPrompt>
      <span class="font-medium">{{ $t("major.pgConfirmation") }}</span>
    </template>

    <template #confirmText>{{ $t("confirm") }}</template>
  </ConfirmModal>
</template>

<script>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";

export default defineComponent({
  components: {
    ConfirmModal,
    InformationAlert,
    ExclamationTriangleIcon,
    SCAlert,
  },
  props: {
    context: { type: Object, required: true },
    dbName: { type: String, required: true },
  },
  emits: ["close", "confirm"],
  computed: {
    dbVersion() {
      return this.context.dbVersion;
    },
    currentDbVersion() {
      return this.dbVersion?.item;
    },
    nextDbVersion() {
      return this.currentDbVersion?.next_upgrade;
    },
    warningMessage() {
      return this.currentDbVersion?.warning_message;
    },
    upgradeType() {
      return this.nextDbVersion?.major > this.currentDbVersion?.major
        ? "major"
        : "minor";
    },
    minorPlanType() {
      if (this.upgradeType === "major") {
        return "";
      }

      return this.context.db["plan"].includes("business")
        ? ".business"
        : ".notBusiness";
    },
    isPostgresDb() {
      return this.dbName === "postgresql";
    },
  },
  methods: {
    readableFullVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch}-${dbVersion.build}`;
    },
  },
});
</script>

<i18n>
en:
  major:
    title: "Major version upgrade"
    description: "The database will be stopped, leading to a service interruption. Major upgrades require cold modification of the data model and critical server components."
    disclaimer: "This operation can take from a few minutes to several hours, depending on the size of the database. We recommend avoiding any actions during its execution."
    pgMessage: "After the upgrade, we will automatically run the ANALYZE command to optimize indexing and enhance the performance of your database. This process is essential, although it may temporarily impact performance."
    pgConfirmation: "I understand that Point-in-Time Recovery (PITR) will create a new timeline for the database, preventing any restoration to a state prior to this operation."
  minor:
    title: "Minor version upgrade"
    description:
      notBusiness: "The current plan, based on a single-node architecture, involves service interruption during minor upgrades. Switch to a Business plan to avoid any interruptions."
      business: "Thanks to its multi-node architecture, the Business plan ensures minor updates without any service interruption!"
    disclaimer: "This operation should only take a few minutes, regardless of the size of the database. We recommend avoiding any actions during its execution."
  upgrade: "Upgrade from {currentVersion} to version {nextVersion}"
  confirm: "Upgrade Now"
  error:
    message: "An error occurred. Please reload the page and try again."
fr:
  major:
    title: "Mise à jour de version majeure"
    description: "La base de données va être arrêtée, entraînant une interruption de service. Les mises à jour majeures nécessitent une modification à froid du modèle de données et des composants critiques du serveur."
    disclaimer: "L’opération peut durer de quelques minutes à plusieurs heures, selon la taille de votre base de données. Nous vous conseillons d’éviter toute action pendant son execution."
    pgMessage: "Après la mise à jour, nous exécuterons automatiquement la commande ANALYZE pour optimiser l’indexation et accroître les performances de votre base de données. Ce processus est essentiel, bien qu’il puisse temporairement affecter les performances."
    pgConfirmation: "Je comprends que la restauration Point-in-Time (PITR) créera une nouvelle timeline pour la base de données, empêchant toute restauration à un état antérieur à cette opération."
  minor:
    title: "Mise à jour de version mineure"
    description:
      notBusiness: "Le plan actuel, reposant sur une architecture mono-nœud, implique une interruption de service lors des mises à jour mineures. Optez pour un plan Business afin d’éviter toute interruption."
      business: "Grâce à son architecture multi-nœuds, le plan Business assure des mises à jour mineures sans aucune interruption de service !"
    disclaimer: "L’opération devrait durer seulement quelques minutes, indépendamment de la taille de la base de données. Nous vous conseillons d’éviter toute action pendant son execution."
  upgrade: "Mise à jour de la version {currentVersion} à la version {nextVersion}"
  confirm: "Mettre à jour maintenant"
  error:
    message: "Une erreur est survenue. Veuillez recharger la page et réessayer."
</i18n>
