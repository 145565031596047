import { unpackData } from "scalingo/lib/utils";

import ScalingoDBApi from "@/lib/scalingo/dbapi/client";

export type BackupRestorationStatus =
  | "scheduled"
  | "running"
  | "done"
  | "error";

export interface BackupRestoration {
  id: string;
  created_at: string;
  started_at: string | null;
  ended_at: string | null;
  method:
    | "backup" // Restoration comes from a backup made on the same region (on the same db or a different one)
    | "remote"; // Restoration comes from a remote URL (used for region migration);
  status: BackupRestorationStatus;
  database_id: string;
  source_backup_id: string;
}

export default class BackupRestorations {
  /** Scalingo DB API Client */
  _client: ScalingoDBApi;

  /**
   * Create a new "thematic" client
   * @param client Scalingo DB API Client
   */
  constructor(client: ScalingoDBApi) {
    this._client = client;
  }

  /**
   * Get a backup status
   * @param dbId ID of the addon/database
   * @param id ID of the backup
   */
  find(dbId: string, id: string): Promise<BackupRestoration> {
    return unpackData(
      this._client
        .apiClient()
        .get(`/databases/${dbId}/backup_restorations/${id}`),
      "backup_restoration",
    );
  }
}
