export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A new version of the ", _interpolate(_named("contract")), " is available."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review and accept"])}
      },
      "fr": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une nouvelle version des ", _interpolate(_named("contract")), " est disponible."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer en revue et accepter."])}
      }
    }
  })
}
