export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests per minute"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of requests per minute, with a discount count for requests with 5XX status."])},
        "noMetric1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No request to the app during the selected period"])},
        "noMetric2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hence no metric to display"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête par minute"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête par minute"])},
        "noMetric1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête à l'app sur la période sélectionnée"])},
        "noMetric2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par conséquence aucune métrique à aficher"])}
      }
    }
  })
}
