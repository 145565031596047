export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop All Containers"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will stop all containers running for your application."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop All Containers"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter tous les conteneurs"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action arrêtera tous les conteneurs de votre application."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter tous les conteneurs"])}
      }
    }
  })
}
