<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons> </template>
      </CardHeader>
    </template>
    <template #body>
      <SmallToggle
        :modelValue="value"
        @update:modelValue="(e) => $emit('settingUpdated', { force_https: e })"
        >{{ $t("text") }}</SmallToggle
      >
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";

export default defineComponent({
  name: "ForceHTTPSCard",
  components: { Card, SmallToggle, CardHeader },
  props: {
    value: Boolean,
  },
  emits: ["settingUpdated"],
});
</script>

<i18n>
en:
  title: "Force HTTPS"
  subtitle: "Automatically redirect HTTP traffic to HTTPS for all domains associated with this application."
  text: "Force HTTPS"
fr:
  title: "Forcer HTTPS"
  subtitle: "Redirige automatiquement le traffic HTTP vers HTTPS pour tous les domaines de l'application."
  text: "Forcer HTTPS"
</i18n>
