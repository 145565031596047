<template>
  <main>
    <div class="flex flex-col mid-xl:grid mid-xl:grid-cols-2 gap-4 mt-8">
      <template v-if="appsFirstLoadIsFinished">
        <GettingStartedCard v-if="!hasApps" class="lg:col-span-2" />
        <AppsCard v-if="hasApps" :appInfos="appInfos" />
        <ActivityCard v-if="hasApps" :events="events" />
      </template>
      <NewsCard :articles="articles" class="lg:col-span-2"></NewsCard>
      <ChangelogCard :entries="changelogEntries" class="lg:col-span-2" />
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ActivityCard from "@/components/parts/home/overview/ActivityCard.vue";
import AppsCard from "@/components/parts/home/overview/AppsCard.vue";
import ChangelogCard from "@/components/parts/home/overview/ChangelogCard.vue";
import GettingStartedCard from "@/components/parts/home/overview/GettingStartedCard.vue";
import NewsCard from "@/components/parts/home/overview/NewsCard.vue";

export default defineComponent({
  name: "Overview",
  components: {
    GettingStartedCard,
    ActivityCard,
    AppsCard,
    NewsCard,
    ChangelogCard,
  },
  props: {
    appInfos: Object,
    events: Object,
    user: Object,
    articles: Object,
    changelogEntries: Object,
  },
  computed: {
    appsFirstLoadIsFinished() {
      return this.appInfos.lastFetchedAt;
    },
    hasApps() {
      return this.appInfos.items?.length > 0;
    },
  },
});
</script>
