import { App, Autoscaler } from "scalingo/lib/models/regional";
import {
  CreateParams,
  UpdateParams,
} from "scalingo/lib/params/regional/autoscalers";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  HANDLE_FETCH,
  CREATE,
  HANDLE_OPERATION,
  DESTROY,
  UPDATE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ADD, DELETE, SET_ALL } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export const METRICS_IN_PERCENTS = ["cpu", "memory", "swap"];

export class AutoscalersStore extends CollectionStore<Autoscaler> {
  actions = CollectionStore.buildActions<Autoscaler>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Autoscalers.for(
          currentApp.id,
        ),
        resolveAction: SET_ALL,
      });
    },
    [CREATE](context, payload = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Autoscalers.create(
          currentApp.id,
          payload,
        ),
        resolveAction: ADD,
      });
    },
    [UPDATE](context, opts = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Autoscalers.update(
          currentApp.id,
          opts.autoscaler.id,
          opts.payload,
        ),
        resolveAction: ADD,
      });
    },
    [DESTROY](context, autoscaler) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Autoscalers.destroy(
          currentApp.id,
          autoscaler.id,
        ),
        resolveAction: () => context.commit(DELETE, autoscaler),
      });
    },
  });
  mutations = CollectionStore.buildMutations<Autoscaler>();
  getters = CollectionStore.buildGetters<Autoscaler>();
}

export const Autoscalers = buildMapping(new AutoscalersStore(), "autoscalers");

export function listAutoscalers(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Autoscaler>>,
): CollectionWithFetch<Autoscaler> {
  return {
    items: listItems(store.getters[Autoscalers.getters.ALL], opts),
    latestFetch: store.getters[Autoscalers.getters.LATEST_FETCH],
  };
}

export function ensureAutoscalers(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(Autoscalers.actions.ENSURE, opts);
}

export function createAutoscaler(
  store: ApplicationStore,
  payload: CreateParams,
): Promise<RemoteOperation<Autoscaler>> {
  return store.dispatch(Autoscalers.actions.CREATE, payload);
}

export function updateAutoscaler(
  store: ApplicationStore,
  autoscaler: Autoscaler,
  payload: UpdateParams,
): Promise<RemoteOperation<Autoscaler>> {
  return store.dispatch(Autoscalers.actions.UPDATE, { autoscaler, payload });
}

export function destroyAutoscaler(
  store: ApplicationStore,
  autoscaler: Autoscaler,
): Promise<RemoteOperation<Autoscaler>> {
  return store.dispatch(Autoscalers.actions.DESTROY, autoscaler);
}
