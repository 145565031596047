export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCM integration linked"])},
        "repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration SCM liée"])},
        "repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])}
      }
    }
  })
}
