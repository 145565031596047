export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Window"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the 8 hour window that best suits the workload of this database."])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["between"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled:"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update schedule"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenêtre de Maintenance"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez la fenêtre de 8 heures la plus adaptée à la charge de travail de cette base de données."])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entre"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifié :"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la planification"])}
      }
    }
  })
}
