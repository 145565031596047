export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activatedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query statistics enabled"])},
        "activatedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance tracking will be available shortly."])},
        "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query statistics reset"])},
        "resetMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance tracking will be available shortly."])}
      },
      "fr": {
        "activatedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query statistics activées"])},
        "activatedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le suivi de la performance de vos requêtes sera disponible sous peu."])},
        "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query statistics réinitialisées"])},
        "resetMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le suivi de la performance de vos requêtes sera disponible sous peu."])}
      }
    }
  })
}
