export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User ", _interpolate(_named("name")), " password"])},
        "generatedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated password"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The generated password is displayed only once. Please copy it and keep it in a safe place."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mot de passe de l'utilisateur ", _interpolate(_named("name"))])},
        "generatedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe généré"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe généré n'est affiché qu'une seule fois. Veuillez le copier et le conserver en lieu sûr."])}
      }
    }
  })
}
