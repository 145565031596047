<template>
  <ViewComponent />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/collaborations/Accept.vue";
import { scalingoClient } from "@/lib/scalingo/client";
import { availableRegions } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";
import { navErrorHandler } from "@/router/utils";
import { userHasFlag } from "@/store/user";
import { useAppInfosStore } from "@/stores/app-infos";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "CollaborationAcceptContainer",
  components: { ViewComponent },
  computed: {
    toastsStore() {
      return useToastsStore();
    },
    requestedRegion() {
      return availableRegions.find((r) => r.code === this.$route.params.region);
    },
    hasAccessToRegion() {
      if (!this.requestedRegion.requiresFlag) return true;

      return userHasFlag(this.currentUser, this.requestedRegion.requiresFlag);
    },
  },
  beforeMount() {
    // No token? no need to go on
    if (!this.$route.query.token) {
      this.$router.push({ name: Routes.Welcome }).catch(navErrorHandler);
    }
  },
  async mounted() {
    if (!this.requestedRegion) {
      this.toastsStore.addOne({
        type: "error",
        title: this.$t("region.invalid.title"),
        message: this.$t("region.invalid.message", {
          region: this.$route.params.region,
        }),
        keep: true,
      });

      this.$router.push({ name: Routes.Welcome }).catch(navErrorHandler);
      return;
    }

    if (!this.hasAccessToRegion) {
      this.toastsStore.addOne({
        type: "error",
        title: this.$t("region.unauthorized.title"),
        message: this.$t("region.unauthorized.message", {
          region: this.$route.params.region,
        }),
        keep: true,
      });

      this.$router.push({ name: Routes.Welcome }).catch(navErrorHandler);
      return;
    }

    const client = scalingoClient(this.$store, this.$route.params.region);

    try {
      const { app } = await client.Collaborators.inviteAccept(
        this.$route.query.token,
      );

      // Refreshing the app listing
      const appInfosStore = useAppInfosStore();
      appInfosStore.ensure({ staleAfter: "always" });
      await appInfosStore.promise;

      this.toastsStore.addOne({
        type: "success",
        title: this.$t("accept.success.title"),
        message: this.$t("accept.success.message", { name: app.name }),
      });

      this.$router
        .push({
          name: Routes.App.Overview,
          params: { region: app.region, id: app.name },
        })
        .catch(navErrorHandler);
    } catch (e) {
      this.toastsStore.addOne({
        type: "error",
        title: this.$t("accept.error.title"),
        message: this.$t("accept.error.message"),
      });

      this.$router.push({ name: Routes.Welcome }).catch(navErrorHandler);
    }
  },
});
</script>

<i18n>
en:
  accept:
    success:
      title: "Collaboration accepted!"
      message: "You now have access to {name}"
    error:
      title: "Failure"
      message: "The collaboration is invalid."
  region:
    invalid:
      title: "Invalid region"
      message: "The region '{region}' does not exist."
    unauthorized:
      title: "Unauthorized"
      message: "You do not have access to the region '{region}'. Get in touch with the support to request the access."
fr:
  accept:
    success:
      title: "Collaboration acceptée!"
      message: "Vous avez a présent accès à {name}"
    error:
      title: "Échec"
      message: "La collaboration est invalide."
  region:
    invalid:
      title: "Région invalide"
      message: "La région '{region}' n'existe pas."
    unauthorized:
      title: "Non autorisé"
      message: "Vous n'avez pas accès a la région '{region}'. Contactez le support afin d'y avoir accès."
</i18n>
