<template>
  <SCTable
    class="mt-6"
    layout="fixed"
    :headers="headers"
    :items="collaboratorsSorted"
    :paginated="true"
    :searchable="true"
    :searchFn="searchFn"
    @sortChanged="(e) => $emit('sortChanged', e)"
  >
    <template #item.avatar="{ item }">
      <div class="flex place-items-center items-center flex-grow">
        <CollaboratorPortrait
          :item="item"
          :isOwner="item.username === app.owner.username"
        />
      </div>
    </template>
    <template #item.name="{ item }">
      <div
        class="flex flex-grow place-items-center"
        :class="{ 'text-scale-5': item.status === 'pending' }"
      >
        <div
          v-if="item.username === app.owner.username"
          :title="$t('appOwner')"
        >
          <StarGlyph class="h-8 mr-2" />
        </div>

        <div v-if="item._linkerGithub" :title="$t('appLinker.github.hint')">
          <SVGModelSCMGithub class="h-4 mr-1" />
        </div>

        <div
          v-else-if="item._linkerGitlab"
          :title="$t('appLinker.gitlab.hint')"
        >
          <SVGModelSCMGitlab class="text-secondary-3 h-4 mr-1" />
        </div>

        <template v-if="!item.username || item.username === 'n/a'">
          n/a
        </template>
        <span v-else>{{ item.username }}</span>
      </div>
    </template>

    <template #item.email="{ item }">
      <span :class="{ 'text-scale-5': item.status === 'pending' }">
        {{ item.email }}
      </span>
    </template>

    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <SCTableAction
          v-if="item.status === 'pending'"
          :title="$t('actions.copy')"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('copyInvitationLink', { collaborator: item })"
        >
          <CopyTableButton :title="$t('actions.copy')" />
        </SCTableAction>

        <SCTableAction
          v-if="item.username !== app.owner.username"
          :title="titleFor(item)"
          type="delete"
          :disabled="item._linker"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('removeCollaborator', { collaborator: item })"
        >
          <BinGlyph class="h-4 w-4" />
        </SCTableAction>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import StarGlyph from "@/components/atoms/glyphs/StarGlyph.vue";
import SVGModelSCMGithub from "@/components/graphics/models/SCMGithub.vue";
import SVGModelSCMGitlab from "@/components/graphics/models/SCMGitlab.vue";
import CopyTableButton from "@/components/organisms/buttons/CopyTableButton.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import CollaboratorPortrait from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorPortrait.vue";

const searchFn = (row, search) => {
  return (
    row.email
      .toString()
      .toLocaleLowerCase()
      .indexOf(search.toLocaleLowerCase()) !== -1 ||
    row.username
      .toString()
      .toLocaleLowerCase()
      .indexOf(search.toLocaleLowerCase()) !== -1
  );
};

export default defineComponent({
  name: "CollaboratorsList",
  components: {
    CopyTableButton,
    BinGlyph,
    SCTableAction,
    CollaboratorPortrait,
    SCTable,
    StarGlyph,
    SVGModelSCMGithub,
    SVGModelSCMGitlab,
  },
  props: {
    app: Object,
    scmRepoLink: Object,
    collaborators: Object,
  },
  emits: ["copyInvitationLink", "removeCollaborator", "sortChanged"],
  data() {
    return {
      headers: [
        { value: "avatar", text: "", class: "w-14" },
        { value: "name", text: this.$t("headers.name"), class: "w-64" },
        { value: "email", text: this.$t("headers.email") },
        { value: "actions", text: "", align: "right" },
      ],
      searchFn: searchFn,
    };
  },
  computed: {
    /**
     * Display in order : owner, accepted collaborators, the rest.
     * Also flag the user that is responsible for the ScmRepoLinking.
     */
    collaboratorsSorted() {
      const colls = this.collaborators.items;

      const owner = { ...this.app.owner, user_id: this.app.owner.id };
      const accepted = colls.filter((c) => c.status === "accepted");
      const notAccepted = colls.filter((c) => c.status !== "accepted");

      const list = [owner, ...accepted, ...notAccepted];

      return list.map((u) => {
        if (
          u.user_id &&
          this.scmRepoLink &&
          this.scmRepoLink.linker?.id === u.user_id
        ) {
          return {
            ...u,
            _linker: true,
            _linkerGithub: this.scmRepoLink._isGithub,
            _linkerGitlab: this.scmRepoLink._isGitlab,
          };
        } else {
          return u;
        }
      });
    },
  },
  methods: {
    titleFor(item) {
      if (!item._linker) return this.$t("actions.delete");

      if (item._linkerGithub) return this.$t("appLinker.github.warn");
      if (item._linkerGitlab) return this.$t("appLinker.gitlab.warn");

      // Should not happen
      return "";
    },
  },
});
</script>

<i18n>
en:
  headers:
    name: "Name"
    email: "Email"
  appOwner: "This is the app owner"
  appLinker:
    github:
      hint: "This user's github account is linked to this app"
      warn: "The user linking the app to github cannot be removed"
    gitlab:
      hint: "This user's gitlab account is linked to this app"
      warn: "The user linking the app to gitlab cannot be removed"
  pending: "pending"
  actions:
    copy: "Copy the invitation link"
    delete: "Delete the collaborator"
fr:
  headers:
    name: "Nom"
    email: "Email"
  appOwner: "Propriétaire de l'app"
  appLinker:
    github:
      hint: "Le compte github de cet utilisateur est lié a cette app"
      warn: "Le collaborateur liant l'app a github ne peut pas être supprimé"
    gitlab:
      hint: "Le compte gitlab de cet utilisateur est lié a cette app"
      warn: "Le collaborateur liant l'app a gitlab ne peut pas être supprimé"
  pending: "en attente"
  actions:
    copy: "Copy the invitation link"
    delete: "Supprimer le collaborateur"
</i18n>
