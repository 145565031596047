export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
        "authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
        "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH Keys"])},
        "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Tokens"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCM Integrations"])},
        "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrals"])},
        "hds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Data (HDH)"])}
      },
      "fr": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information basique"])},
        "authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification"])},
        "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés SSH"])},
        "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens API"])},
        "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations SCM"])},
        "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrainage"])},
        "hds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de santé (HDS)"])}
      }
    }
  })
}
