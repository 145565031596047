export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
        "userSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User settings"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
        "titles": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open user menu"])}
        }
      },
      "fr": {
        "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
        "userSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres utilisateur"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
        "titles": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvre le menu utilisateur"])}
        }
      }
    }
  })
}
