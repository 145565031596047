<template>
  <main v-if="container">
    <DividedSectionTitle
      :title="
        $t('title', {
          name: container.name,
          amount: container.amount,
          size: container.size,
        })
      "
      :subtitle="$t('subtitle')"
    >
      <DateSelector
        :selectedValue="since"
        :displayInFullScreen="displayInFullScreen"
        @rangeSelected="(e) => $emit('rangeSelected', e)"
        @fullScreenClicked="toggleFullScreen"
      />
    </DividedSectionTitle>

    <BackLinkButton class="my-2" :routeArgs="metricsOverviewRoute">
      {{ $t("back") }}
    </BackLinkButton>

    <section class="bg-scale-0 pt-2 mt-6 theme-light">
      <div v-for="(ops, index) in containersOperations" :key="index + 1">
        <ContainerGraphsCard
          :container="container"
          :index="index + 1"
          :containerSizes="containerSizes"
          :cpuOperation="ops.cpu"
          :memoryOperation="ops.memory"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import DividedSectionTitle from "@/components/atoms/titles/DividedSectionTitle.vue";
import ContainerGraphsCard from "@/components/parts/app/metrics/ContainerGraphsCard.vue";
import DateSelector from "@/components/parts/app/metrics/DateSelector.vue";
import FullscreenableComponent from "@/mixins/fullscreenable_component";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ApexMetricsPerContainer",
  components: {
    DateSelector,
    DividedSectionTitle,
    BackLinkButton,
    ContainerGraphsCard,
  },
  mixins: [FullscreenableComponent],
  props: {
    app: Object,
    user: Object,
    container: Object,
    containerSizes: Object,
    since: [String, Number],
    containersOperations: Array,
  },
  emits: ["rangeSelected"],
  computed: {
    metricsOverviewRoute() {
      const query = { ...this.$route.query };

      if (this.displayInFullScreen) {
        query.fullscreen = "true";
      } else {
        delete query.fullscreen;
      }

      return {
        name: Routes.App.Metrics.ApexOverview,
        query,
      };
    },
  },
});
</script>

<i18n>
  en:
    title: "Metrics per container ({name} {amount}:{size})"
    subtitle: "Key indicators of performance and consumption for each individual container."
    back: "Application metrics"
  fr:
    title: "Métriques par conteneur ({name} {amount}:{size})"
    subtitle: "Indicateurs clés de performance et de consommation pour chaque conteneur."
    back: "Métriques de l'application"
</i18n>
