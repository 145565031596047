<template>
  <ProTipAlert>
    <div class="flex flex-col pv:flex-row">
      <div>
        {{ $t("proTip") }}
      </div>
      <div>
        <div
          v-if="db"
          class="ml-1 font-mono bg-scale-1 px-1 pv:whitespace-nowrap"
        >
          scalingo --region {{ app.region }} --app {{ app.name }} --addon
          {{ db.type_name }} logs --lines 100000
        </div>
        <div v-else class="ml-1 font-mono bg-scale-1 px-1 pv:whitespace-nowrap">
          scalingo --region {{ app.region }} --app {{ app.name }} logs --lines
          100000
        </div>
      </div>
    </div>
  </ProTipAlert>
</template>

<script>
import { defineComponent } from "vue";

import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";

export default defineComponent({
  name: "ProTipLogsViaCLI",
  components: { ProTipAlert },
  props: {
    app: Object,
    db: Object,
  },
});
</script>

<i18n>
en:
  proTip: "Logs from the last archive up to now are viewable using our CLI tool with"
fr:
  proTip: "Les logs entre la dernière archive et maintenant sont visibles sur la CLI via"
</i18n>
