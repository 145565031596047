<template>
  <ViewComponent
    :app="app"
    :notifier="notifier"
    :collaborators="collaborators"
    :formHandler="formHandler"
    @submit="submit"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/notifiers/edit/Infos.vue";
import { EditNotifierHandler } from "@/lib/handlers";
import { ensureCollaborators, listCollaborators } from "@/store/collaborators";
import { ensureNotifier, findNotifier } from "@/store/notifiers";

export default defineComponent({
  name: "NotifierEditInfosContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formHandler: null,
    };
  },
  computed: {
    notifier() {
      if (this.$route.params.notifierId) {
        return findNotifier(this.$store, this.$route.params.notifierId);
      }
      return null;
    },
    collaborators() {
      return listCollaborators(this.$store);
    },
  },
  watch: {
    notifier: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formHandler = new EditNotifierHandler(
            this,
            this.app,
            newVal,
            [], // event types, not needed here
            "events",
          );
        } else {
          this.formHandler = null;
        }
      },
    },
  },
  async beforeMount() {
    const operation = await ensureNotifier(
      this.$store,
      this.$route.params.notifierId,
    );
    await operation.settled;

    if (this.notifier.type === "email") {
      ensureCollaborators(this.$store, { staleAfter: "always" });
    }
  },
  methods: {
    submit(e) {
      this.formHandler.submit(e);
    },
  },
});
</script>
