<template>
  <main>
    <MetricsChoiceStep :enabled="false" />
    <CardSeparator />
    <NotifierStep
      :enabled="true"
      :formHandler="formHandler"
      :notifiers="notifiers"
      @formSubmitted="(e) => $emit('formSubmitted', e)"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import MetricsChoiceStep from "@/components/parts/appSettings/alertsCreation/MetricsChoiceStep.vue";
import NotifierStep from "@/components/parts/appSettings/alertsCreation/NotifierStep.vue";

export default defineComponent({
  name: "AlertsCreation",
  components: {
    CardSeparator,
    NotifierStep,
    MetricsChoiceStep,
  },
  props: {
    app: Object,
    alert: Object,
    formHandler: Object,
    notifiers: Object,
  },
  emits: ["formSubmitted"],
});
</script>
