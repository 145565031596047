export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred."])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please reload the page and try again."])},
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query"])},
          "terminated": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The session has been successfully terminated."])}
          },
          "cancel": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request has been successfully canceled."])}
          }
        }
      },
      "fr": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue."])},
        "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez recharger la page et réessayer."])},
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête"])},
          "terminated": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La session a été interrompue avec succès."])}
          },
          "cancel": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La requête a été annulée avec succès."])}
          }
        }
      }
    }
  })
}
