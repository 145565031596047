<template>
  <section>
    <Card>
      <template #header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template #buttons>
            <SCButton :to="newNotifierRoute">
              {{ $t("add") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <EmptyCardState v-if="noItems">
          <template #icon>
            <SVGModelNotifier class="h-8 w-8 text-scale-5" />
          </template>
          <div class="flex flex-col text-center">
            <div class="text-lg font-bold text-scale-10">
              {{ $t("none.title") }}
            </div>
            <div class="mt-2">
              {{ $t("none.subtitle") }}
            </div>
            <div class="mt-2">
              <SCButton :to="newNotifierRoute">
                {{ $t("none.action") }}
              </SCButton>
            </div>
          </div>
        </EmptyCardState>

        <NotifiersList
          v-else
          class="mt-2"
          :notifiers="notifiers"
          :app="app"
          @startNotifierDeletion="(e) => $emit('startNotifierDeletion', e)"
          @updateNotifier="(e) => $emit('updateNotifier', e)"
        />
      </template>
    </Card>

    <RemoveNotifierModal
      v-if="deletionHandler"
      :formHandler="deletionHandler"
      @formSubmitted="$emit('comfirmNotifierDeletion')"
      @modalClosed="$emit('cancelNotifierDeletion')"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SVGModelNotifier from "@/components/graphics/models/Notifier.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import RemoveNotifierModal from "@/components/parts/app/notifiers/RemoveNotifierModal.vue";
import NotifiersList from "@/components/parts/appSettings/notifiersSettings/NotifiersList.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "List",
  components: {
    NotifiersList,
    CardHeader,
    Card,
    SCButton,
    RemoveNotifierModal,
    EmptyCardState,
    SVGModelNotifier,
  },
  props: {
    app: Object,
    user: Object,
    notifiers: Object,
    deletionHandler: Object,
  },
  emits: [
    "cancelNotifierDeletion",
    "comfirmNotifierDeletion",
    "startNotifierDeletion",
    "updateNotifier",
  ],
  data: function () {
    return {};
  },
  computed: {
    noItems() {
      return (
        this.notifiers.latestFetch.isFinished &&
        this.notifiers.items.length === 0
      );
    },
    newNotifierRoute() {
      return {
        name: Routes.App.Notifiers.Creation,
        params: {
          id: this.app.name,
          region: this.app.region,
        },
      };
    },
    oldDashboardLink() {
      const region = this.app.region;
      const appName = this.app.name;

      return (
        "https://dashboard-prev." +
        region +
        ".scalingo.com/apps/" +
        appName +
        "/notifications"
      );
    },
  },
});
</script>

<i18n>
en:
  title: "Notifiers"
  subtitle: "Configure notifiers to be informed about events in your app."
  add: "Add"
  none:
    title: "There are no items here"
    subtitle: "Notifiers will appear here once created."
    action: "Add a notifier"
fr:
  title: "Notifieurs"
  subtitle: "Configurer des notifieurs pour être informé des évènements de votre app."
  add: "Ajouter"
  none:
    title: "Il n'y a aucun élément"
    subtitle: "Les notifieurs apparaitront ici une fois ajoutés."
    action: "Ajouter un notifieur"
</i18n>
