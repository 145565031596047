import { onMounted, onUnmounted } from "vue";

import { dashboard } from "@/lib/utils/log";

// Closing functions don't expect arguments and don't return anything
type CloseFn = () => void;

// A global references in which we'll store the modal closing functions
let modalQueue: CloseFn[] = [];

/**
 * Should be called by modals during setup.
 * This will let the current controller close the current modal from the bottom up
 * in order to respect animations delays.
 */
export function registerModalCloser(fn: CloseFn) {
  // Register/unregister closing function
  onMounted(() => setCurrentModalCloser(fn));
  onUnmounted(() => unsetCurrentModalCloser(fn));
}

/**
 * To be used by controller to close the top-most modal.
 * In most cases, there should only be one in the queue.
 */
export function closeCurrentModal() {
  const closeFn = modalQueue[modalQueue.length - 1];

  closeFn?.();
  dashboard.debug("modalQueue:", modalQueue);
}

/** Adds a closing function to the queue */
function setCurrentModalCloser(fn: CloseFn) {
  const lastItem = modalQueue[modalQueue.length - 1];

  // Just in case, we work around adding the same closer twice
  if (lastItem !== fn) modalQueue.push(fn);

  dashboard.debug("modalQueue:", modalQueue);
}

/** Removes a closing function from the queue */
function unsetCurrentModalCloser(fn: CloseFn) {
  /** In order to be thorough, we scan the queue and remove all the references fo the supplied function */
  modalQueue = modalQueue.filter((queuedFn) => queuedFn !== fn);

  dashboard.debug("modalQueue:", modalQueue);
}
