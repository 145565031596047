import { RouteRecordRaw } from "vue-router";

import AccountAuthentication from "@/controllers/account/Authentication.vue";
import AccountBasic from "@/controllers/account/Basic.vue";
import AccountDeletion from "@/controllers/account/Deletion.vue";
import AccountHDS from "@/controllers/account/HDS.vue";
import AccountIntegrations from "@/controllers/account/Integrations.vue";
import AccountKeys from "@/controllers/account/Keys.vue";
import AccountReferrals from "@/controllers/account/Referrals.vue";
import AccountRoot from "@/controllers/account/Root.vue";
import AccountTokens from "@/controllers/account/Tokens.vue";
import i18n from "@/i18n";
import { AccountRoutes as R } from "@/router/account/names";
import { childRoutesForOAuthLinking } from "@/router/utils";

export const accountRoutes: RouteRecordRaw = {
  path: "/account",
  component: AccountRoot,
  meta: {
    title: i18n.global.t("titles.account.root"),
  },
  children: [
    {
      path: "",
      name: R.Basic,
      component: AccountBasic,
    },
    {
      path: "authentication",
      name: R.Auth,
      component: AccountAuthentication,
      meta: {
        title: i18n.global.t("titles.account.authentication"),
      },
    },
    {
      path: "keys",
      name: R.Keys,
      component: AccountKeys,
      meta: {
        title: i18n.global.t("titles.account.keys"),
      },
    },
    {
      path: "tokens",
      name: R.Tokens,
      component: AccountTokens,
      meta: {
        title: i18n.global.t("titles.account.tokens"),
      },
    },
    {
      path: "integrations",
      name: R.Integrations,
      component: AccountIntegrations,
      meta: {
        title: i18n.global.t("titles.account.integrations"),
      },
      children: childRoutesForOAuthLinking(R.Integrations),
    },
    {
      path: "hds",
      name: R.HDS,
      component: AccountHDS,
      meta: {
        title: i18n.global.t("titles.account.hds"),
      },
    },
    {
      path: "delete",
      name: R.Deletion,
      component: AccountDeletion,
      meta: {
        title: i18n.global.t("titles.account.deletion"),
      },
    },
    {
      path: "referrals",
      name: R.Referrals,
      component: AccountReferrals,
      meta: {
        title: i18n.global.t("titles.account.referrals"),
      },
    },
  ],
};
