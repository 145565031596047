import { DateTime } from "luxon";
import { computed, ref } from "vue";

import {
  promiseInfo as promiseInfoFn,
  voidPromiseInfo,
} from "@/lib/promises/info";
import type { PromiseInfo } from "@/lib/promises/info";

import { EnsureOptions, shouldFetch } from "./utils/ensure";

import type { ComputedRef, Ref } from "vue";

// Note: this file aims to replace a few other files:
// - @/lib/pinia/use-data-store.ts
// - @/lib/pinia/use-resource-store.ts
// As those serve as the foundations for all stores, rather than having a big bang refactor
// this file is here to allow progressive refactoring of the stores.
//
// Main changes from data-store / resource-store:
// - No notion of public/private interface -- most of the complexity came from this notion and it serves little to no purpose
// - Removal of DataStore - it's the same as ResourceStore, except for the name of the item getter. Distinction is unnecessary
// - Removal of initialPromiseInfo - not used, except in one specific context that doesn't apply to resources.
// - Better support for what to do when a fetch fails through options.errorHandler, see @/lib/pinia/utils/errors

//======== Types ========//
/** The interface of the store */
export type ResourceStore<DataType> = {
  /** The data contained in this store */
  item: Ref<DataType | undefined>;
  /** The promise representing the current fetch operation */
  promise: Ref<Promise<DataType> | null>;
  /** A promise info representing the state of the current fetch operation */
  promiseInfo: ComputedRef<PromiseInfo<DataType>>;
  /** When this store was last fetched */
  lastFetchedAt: Ref<DateTime | null>;
  /** Fetch the source of data for this store */
  fetch: FetchFn<DataType>;
  /** Fetch (if relevant) the source of data for this store */
  ensure: EnsureFn<DataType>;
  /** Set the store's data */
  setData: SetFn<DataType>;
  /** Reset's the store's data */
  $reset: ResetFn;
};

type FetchFn<DataType> = () => Promise<DataType | void>;
type EnsureFn<DataType> = (opts?: EnsureOptions) => Promise<DataType | void>;

type SetFn<DataType> = (data: DataType) => DataType;

type ResetFn = () => void;

/** The required options for a data store */
export type ResourceStoreOptions<DataType> = {
  /**
   * The function that retrieves the resource from its source API.
   */
  fetchPromise: () => Promise<DataType>;
};

/** Returns a resource store */
export function useResourceStore<DataType>(
  options: ResourceStoreOptions<DataType>,
): ResourceStore<DataType> {
  const item: Ref<DataType | undefined> = ref(undefined);
  const promise = ref<Promise<DataType> | null>(null);
  const lastFetchedAt = ref<DateTime | null>(null);

  // A promise info representing the current fetch status
  const promiseInfo = computed<PromiseInfo<DataType>>(() => {
    return promise.value
      ? promiseInfoFn(promise.value)
      : voidPromiseInfo<DataType>();
  });

  const setData = function (newData: DataType) {
    lastFetchedAt.value = DateTime.local();
    item.value = newData;

    return item.value;
  };

  const $reset = function () {
    item.value = undefined;
    promise.value = null;
    lastFetchedAt.value = null;
  };

  const fetch = function () {
    promise.value = options.fetchPromise();
    return promise.value.then(setData);
  };

  const ensure = function (opts?: EnsureOptions) {
    const staleAfter = opts?.staleAfter;
    const requiresFetch = shouldFetch(
      { data: item.value, lastFetchedAt: lastFetchedAt.value },
      staleAfter,
    );

    if (requiresFetch) {
      return fetch();
    } else {
      // item.value is expected to be present, we can cast
      return Promise.resolve(item.value as DataType);
    }
  };

  return {
    item,
    promise,
    promiseInfo,
    lastFetchedAt,
    fetch,
    ensure,
    setData,
    $reset,
  };
}
