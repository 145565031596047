<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <GithubAddGlyph
        v-if="event.type_data.integration_type === 'github'"
        class="h-6 w-6 text-scale-6"
      ></GithubAddGlyph>
      <GitlabAddGlyph v-else class="h-6 w-6 text-scale-6"></GitlabAddGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more
      >{{ $t("named") }}
      <span class="font-medium ml-1">{{
        event.type_data.integration_type
      }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import GithubAddGlyph from "@/components/atoms/glyphs/GithubAddGlyph.vue";
import GitlabAddGlyph from "@/components/atoms/glyphs/GitlabAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { GitlabAddGlyph, GithubAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Integration added"
  named: "Named:"
fr:
  title: "Integration ajoutée"
  named: "Nommée :"
</i18n>
