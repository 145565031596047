export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm the name of the user you want to delete."])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting a user is a permanent action that cannot be undone."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this user"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer le nom de l'utilisateur que vous souhaitez supprimer."])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression d'un utilisateur est une action permanente qui ne peut être annulée."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer cet utilisateur"])}
      }
    }
  })
}
