<template>
  <div
    class="border-b-2 border-scale-2 pb-3 mb-3 flex flex-row justify-between"
  >
    <div class="flex flex-col space-y-1">
      <div class="text-sm">{{ $t("period") }}</div>
      <div class="flex flex-row space-x-1">
        <AdvancedSelectInput
          :options="dateRangeOptions"
          :modelValue="currentPeriod"
          class="w-64"
          @update:modelValue="onInput"
        >
          <template #text="{ option }">
            <slot name="optionText" v-bind="{ option }">
              {{ capitalize(option.text) }}
            </slot>
          </template>
        </AdvancedSelectInput>
        <SCButton
          kind="neutral"
          :disabled="periodEndDateIsToday"
          @click="resetToday"
        >
          <font-awesome-icon
            size="2x"
            icon="clock-rotate-left"
            class="h-4 w-4 mr-1"
          ></font-awesome-icon>
          {{ $t("today") }}
        </SCButton>
      </div>
    </div>
    <div class="flex flex-col space-y-1">
      <div class="text-sm" :title="fromDate.toISO() + ' - ' + toDate.toISO()">
        {{ $t("consumption") }}
        {{ humanizedShortDateTime(fromDate) }}
        {{ $t("to") }}
        {{ humanizedMidDateTime(toDate) }}
      </div>
      <div class="flex justify-end items-baseline">
        <div class="text-2xl">{{ price }}</div>
        <div class="text-scale-6 text-sm ml-2">{{ $t("exclVat") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime, Interval, Settings } from "luxon";
import { capitalize, defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import { formatAmount } from "@/lib/utils/currency";
import { humanizedMidDateTime, humanizedShortDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "PeriodHeader",
  components: { AdvancedSelectInput, SCButton },
  props: {
    accountCreationDate: String,
    usage: Object,
    toDate: Object,
    fromDate: Object,
  },

  computed: {
    currentPeriod() {
      return this.fromDate.startOf("month").toISO();
    },
    currentTime() {
      return DateTime.now().toUTC();
    },
    periodEndDateIsToday() {
      return this.toDate.hasSame(DateTime.local().minus({ days: 1 }), "day");
    },
    appUsages() {
      return this.usage.app_usages;
    },
    price() {
      let price = 0;
      for (const k in this.appUsages) {
        const app = this.appUsages[k];
        for (const k1 in app.runtimes) {
          price += app.runtimes[k1].price;
        }
        for (const k2 in app.addon_usages) {
          price += app.addon_usages[k2].price;
        }
        for (const k3 in app.addon_usages) {
          const addon = app.addon_usages[k3];
          if (!this.overusage(addon)) {
            continue;
          }
          price += addon.disk_overusage_price;
        }
      }
      return formatAmount(price);
    },
    dateRangeOptions() {
      return Interval.fromDateTimes(
        DateTime.fromISO(this.accountCreationDate).toUTC().startOf("month"),
        DateTime.local().toUTC().endOf("month"),
      )
        .splitBy({ month: 1 })
        .map((d) => ({
          value: d.start.toUTC().toISO(),
          text: d.start.setLocale(Settings.defaultLocale).toFormat("LLLL y"),
        }))
        .reverse();
    },
  },
  methods: {
    overusage(addon) {
      return addon.disk_overusage && addon.disk_overusage !== 0;
    },
    humanizedMidDateTime,
    humanizedShortDateTime,
    capitalize,
    onInput(value) {
      const option = this.dateRangeOptions.find((item) => item.value === value);
      this.$emit("filterSelected", option);
    },
    resetToday() {
      this.$emit("resetToday");
    },
  },
});
</script>

<i18n>
en:
  period: "Period"
  today: "Today"
  consumption: "Consumption from "
  to: "to"
  exclVat: "excl. VAT"
fr:
  period: "Période"
  today: "Aujourd'hui"
  consumption: "Consommation du "
  to: "au"
  exclVat: "excl. TVA"
</i18n>
