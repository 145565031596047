<template>
  <BaseModal
    class="text-scale-10"
    :panelClasses="panelClasses"
    :closable="!loading"
    @close="onClose"
  >
    <template #title>
      {{ title }}
    </template>

    <slot></slot>

    <hr v-if="strongConfirmation || weakConfirmation" class="my-4" />

    <div v-if="strongConfirmation" class="text-scale-6 text-sm">
      <slot name="strongConfirmationPrompt">
        {{ $t("strongConfirm.before") }}
        <strong class="font-bold whitespace-nowrap">
          {{ strongConfirmation }}
        </strong>
        {{ $t("strongConfirm.after") }}
      </slot>

      <TextInput
        v-model="confirmationInput"
        class="mt-4"
        :disabled="!canClose"
      />
    </div>

    <div v-if="weakConfirmation" class="flex flex-row">
      <CheckboxInputAtom v-model="weakConfirm"> </CheckboxInputAtom>
      <span class="cursor-pointer" @click="weakConfirm = !weakConfirm">
        <slot name="weakConfirmationPrompt">
          <strong>{{ $t("weakConfirmDefault") }}</strong>
        </slot>
      </span>
    </div>

    <div class="mt-6 flex">
      <SCButton
        block
        :kind="kind"
        size="lg"
        class="flex-grow"
        :loading="loading"
        :disabled="
          !strongConfirmationOK || !weakConfirmationOK || success || !canConfirm
        "
        @click="confirm"
      >
        <CheckIcon v-if="success" class="h-6 inline-block" />
        <slot v-else name="confirmText">
          {{ $t("confirm") }}
        </slot>
      </SCButton>
    </div>

    <div class="flex">
      <SCButton
        block
        kind="neutral"
        size="lg"
        class="flex flex-grow mt-4"
        :disabled="!canClose"
        @click="close"
      >
        <slot name="cancelText">
          {{ $t("cancel") }}
        </slot>
      </SCButton>
    </div>
  </BaseModal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/24/outline";
import { computed, defineComponent, ref } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import TextInput from "@/components/atoms/inputs/TextInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import BaseModal from "@/components/molecules/modals/BaseModal.vue";
import { closeCurrentModal } from "@/lib/modals";

export default defineComponent({
  components: {
    BaseModal,
    CheckIcon,
    SCButton,
    TextInput,
    CheckboxInputAtom,
  },
  props: {
    panelClasses: { type: String },
    title: { type: String },
    kind: { type: String, default: "warning" },
    // Set to true if there will not be a matching operation.
    // For example: Scale To Zero/Below Limit, discard bulk variables changes...
    immediate: { type: Boolean, default: false },
    canConfirm: { type: Boolean, default: true },
    // If present, the user has to fill a text field with this value
    // before being able to confirm
    strongConfirmation: { type: String },
    // If present, the user as to check a checkbox to confirm
    weakConfirmation: { type: Boolean, default: false },
    promiseInfo: { type: Object },
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const loading = computed(() => {
      if (props.immediate) return false;

      return props.promiseInfo?.isLoading || false;
    });

    const success = computed(() => {
      if (props.immediate) return false;

      return props.promiseInfo?.isSuccess || false;
    });

    const canClose = computed(() => {
      if (props.immediate) return true;

      return !(loading.value || success.value);
    });

    async function onClose(e) {
      if (!loading.value) emit("close", e);
    }

    function confirm() {
      emit("confirm");
    }

    const confirmationInput = ref(null);

    const strongConfirmationOK = computed(() => {
      return (
        !props.strongConfirmation ||
        props.strongConfirmation === confirmationInput.value
      );
    });

    return {
      confirmationInput,
      strongConfirmationOK,
      loading,
      success,
      canClose,
      onClose,
      confirm,
      close: closeCurrentModal,
    };
  },
  data() {
    return {
      weakConfirm: false,
    };
  },
  computed: {
    weakConfirmationOK() {
      if (!this.weakConfirmation) {
        return true;
      }
      return this.weakConfirm;
    },
  },
});
</script>

<i18n>
en:
  close: "Close"
  confirm: "Confirm"
  cancel: "Cancel"
  strongConfirm:
    before: "In order to proceed, please enter"
    after: "in the text field below:"
  weakConfirmDefault: "In order to proceed, please tick the checkbox"
fr:
  close: "Fermer"
  confirm: "Confirmer"
  cancel: "Annuler"
  strongConfirm:
    before: "Afin de procéder, veuillez entrer"
    after: "dans le champ de texte ci-dessous:"
  weakConfirmDefault: "Afin de procéder, veuillez cocher la case"
</i18n>
