export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting started"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not created any app for the moment, let's get started"])},
        "createTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an application"])},
        "createSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the button below to start your journey on Scalingo"])},
        "createLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an app"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore créer une app, commencons"])},
        "createTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une application"])},
        "createSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton si dessous pour commencer votre voyage sur Scalingo"])},
        "createLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une app"])}
      }
    }
  })
}
