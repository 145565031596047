<template>
  <router-view :app="app"></router-view>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotifiersRoot",
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
});
</script>
