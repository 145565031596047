<template>
  <router-view :billingProfile="billingProfile" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "InvoicesRoot",
  props: {
    billingProfile: Object,
  },
});
</script>
