import { Variable } from "scalingo/lib/models/regional";

import { copyToClipboard } from "@/lib/clipboard";
import { ActionHandler } from "@/lib/handlers/action";
import { RemoteOperation } from "@/lib/store/remote-operation";

import type { ComponentPublicInstance } from "vue";

export class CopyVariableHandler extends ActionHandler<Variable> {
  keyPath = "variable.copy";

  constructor(
    component: ComponentPublicInstance,
    readonly variable: Variable,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    const promise = copyToClipboard(
      `${this.variable.name}=${this.variable.value}`,
    ).then(() => this.variable);

    const operation = new RemoteOperation(promise);

    this.follow(operation);
  }
}
