export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
        "autoscaler": {
          "enabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler is enabled (min: ", _interpolate(_named("min")), ", max: ", _interpolate(_named("max")), ")"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler is disabled"])}
        },
        "title": {
          "restart": {
            "cant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to trigger a restart at this moment"])},
            "noContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to trigger a restart since there is 0 containers"])}
          }
        },
        "actions": {
          "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
          "autoscale": {
            "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup autoscaler"])},
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure autoscaler"])},
            "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enable autoscaler"])},
            "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable autoscaler"])},
            "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove autoscaler"])}
          },
          "containers": {
            "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])}
          }
        }
      },
      "fr": {
        "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qté"])},
        "autoscaler": {
          "enabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler est activé (min: ", _interpolate(_named("min")), ", max: ", _interpolate(_named("max")), ")"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler est désactivé"])}
        },
        "title": {
          "restart": {
            "cant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le redémarrage n'est pas possible pour le moment"])},
            "noContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le redémarrage n'est pas possible étant donné qu'il n'y a aucun container"])}
          }
        },
        "actions": {
          "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrer"])},
          "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaler"])},
          "autoscale": {
            "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer l'autoscaling"])},
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer l'autoscaling"])},
            "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver l'autoscaling"])},
            "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver l'autoscaling"])},
            "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'autoscaling"])}
          },
          "containers": {
            "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopper"])}
          }
        }
      }
    }
  })
}
