<template>
  <div class="bg-primary-2 rounded flex">
    <div class="text-primary-4 container px-6 pt-3 flex font-bold">
      <div class="flex-grow flex">
        <div class="w-1/2 flex flex-col">
          <div class="text-2xl">
            {{ $t("title") }}
          </div>
          <div class="text-sm font-normal my-2 pb-2">
            <p>
              {{ $t("subtitle1") }}

              <a
                :href="url"
                class="font-bold underline cursor-pointer"
                target="_blank"
                >{{ $t("link1") }}
                <font-awesome-icon class="mx-1" icon="external-link-alt"
              /></a>
            </p>

            <p>{{ $t("subtitle2") }}</p>
            <p>
              {{ $t("subtitle3") }}
              <a class="font-bold underline" :href="resendUrl"
                >{{ $t("link1") }}
              </a>
              {{ $t("subtitle4") }}
            </p>

            <a class="font-bold underline" :href="resendUrl">{{
              $t("link1")
            }}</a>
            {{ $t("subtitle4") }}
          </div>
        </div>

        <div
          class="w-1/2 overflow-hidden h-auto flex items-end justify-end px-8"
        >
          <ColumnsIllustration />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ColumnsIllustration from "@/components/atoms/illustrations/ColumnsIllustration.vue";

const resendUrl = `${process.env.VUE_APP_AUTH_BASE_URI}/users/confirmation/new`;

export default defineComponent({
  name: "UnconfirmedUserAlert",
  components: { ColumnsIllustration },
  data() {
    return {
      resendUrl,
    };
  },
});
</script>

<i18n>
  en:
    title: "Welcome to Scalingo!"
    subtitle1: "We're happy to have you among our users!"
    subtitle2: "Don't forget to confirm your account in the 24 hours following its creation, otherwise you will no longer be able to use the platform."
    subtitle3: "You did not receive the confirmation instructions? Follow "
    link1: "this link "
    subtitle4: "to send it again."
  fr:
    title: "Bienvenue sur Scalingo!"
    subtitle1: "Nous sommes heureux de vous compter parmi nos utilisateurs!"
    subtitle2: "N'oubliez pas de confirmer votre compte dans les 24 heures suivant sa création, sans quoi vous ne pourrez plus utiliser la plateforme."
    subtitle3: "Vous n'avez pas reçu les instructions de confirmation? Suivez "
    link1: "ce lien "
    subtitle4: "pour l'envoyer à nouveau."
</i18n>
