<template>
  <ViewComponent :deployment="deployment" />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/deploy/list/Show.vue";
import { ensureDeployment, findDeployment } from "@/store/deployments";

export default defineComponent({
  name: "DeployShowController",
  components: { ViewComponent },
  computed: {
    deployment() {
      if (this.$route.params.deploymentId) {
        return findDeployment(this.$store, this.$route.params.deploymentId);
      }

      return null;
    },
  },
  beforeMount() {
    ensureDeployment(this.$store, this.$route.params.deploymentId);
  },
});
</script>
