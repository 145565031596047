export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure alerts to be informed about events in your app."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items here"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts will appear here once created."])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an alert"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer des alertes pour être informé des évènements de votre app."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun élément"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les alertes apparaitront ici une fois ajoutées."])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une alerte"])}
        }
      }
    }
  })
}
