import { noop } from "lodash";
import { computed, ComputedRef } from "vue";

import {
  DataStoreOptions,
  FullDataStore,
  DataStore,
  useDataStore,
} from "@/lib/pinia/use-data-store";
import { promiseInfo as promiseInfoFn, PromiseInfo } from "@/lib/promises/info";

export { observePromise } from "@/lib/promises/info";

/**
 * A collection store.
 */
export type CollectionStore<DataType> = DataStore<DataType[]> & {
  items: FullDataStore<DataType[]>["data"];
  /** Wether there is currently anything in the collection */
  any: ComputedRef<boolean>;
  /** Wether there is currently nothing in the collection */
  none: ComputedRef<boolean>;
};

/**
 * The private interface of the collection store.
 * Extra items are internal helpers, used to define other stores.
 */
export type FullCollectionStore<DataType> = CollectionStore<DataType> &
  Omit<FullDataStore<DataType[]>, "data" | "destroyData"> & {
    /** Appends data at the end of the collection */
    appendData: AppendDataFn<DataType>;
    /** Splice the collection data */
    spliceData: SpliceDataFn<DataType>;
    /** Inserts the result of the operation in the collection. */
    insertItem: InsertItemFn<DataType>;
    /** Removes the result of the operation from the collection. */
    removeItem: RemoveItemFn<DataType>;
  };

export type AppendDataFn<DataType> = (data: DataType[]) => void;

export type SplicePayload<DataType> = {
  start: number;
  deleteCount: number;
  items?: DataType[];
};

export type SpliceDataFn<DataType> = (opts: SplicePayload<DataType>) => void;

export type InsertItemFn<DataType> = (
  promise: Promise<DataType>,
) => PromiseInfo<DataType>;

export type RemoveItemFn<DataType> = (
  item: DataType,
  promise: Promise<void>,
) => PromiseInfo<void>;

/** Returns an data store tailored for a collection of resources */
export function useCollectionStore<DataType>(
  options: DataStoreOptions<DataType[]>,
): FullCollectionStore<DataType> {
  const store = useDataStore(options);

  function appendData(data: DataType[]) {
    if (!store.data.value) {
      store.data.value = [...data];
    } else {
      store.data.value.push(...data);
    }
  }

  function spliceData(opts: SplicePayload<DataType>) {
    if (!store.data.value) store.data.value = [];

    if (opts.deleteCount && opts.items) {
      store.data.value.splice(opts.start, opts.deleteCount, ...opts.items);
    } else if (opts.deleteCount) {
      store.data.value.splice(opts.start, opts.deleteCount);
    } else {
      store.data.value.splice(opts.start);
    }
  }

  const insertItem: InsertItemFn<DataType> = function (promise) {
    const info = promiseInfoFn(promise);

    promise.then((newData) => {
      if (!store.data.value) {
        store.data.value = [];
      }

      store.data.value.push(newData);
    }, noop);

    return info;
  };

  const removeItem: RemoveItemFn<DataType> = function (item, promise) {
    const info = promiseInfoFn(promise);

    promise.then(() => {
      if (!store.data.value) return;

      store.data.value = store.data.value.filter((itemInColl) => {
        return itemInColl !== item;
      });
    }, noop);

    return info;
  };

  const any = computed(() => {
    if (!store.data.value) return false;

    return store.data.value.length > 0;
  });

  const none = computed(() => !any.value);

  return {
    items: store.data,
    promise: store.promise,
    promiseInfo: store.promiseInfo,
    initialPromiseInfo: store.initialPromiseInfo,
    lastFetchedAt: store.lastFetchedAt,
    fetch: store.fetch,
    ensure: store.ensure,
    updateData: store.updateData,
    clearData: store.clearData,
    appendData,
    spliceData,
    insertItem,
    removeItem,
    any,
    none,
  };
}

/**
 * Removes the internal helpers from the store,
 * and returns it with some specific helpers.
 */
export function toPublicStore<DataType, Extra extends object>(
  store: FullCollectionStore<DataType>,
  extra: Extra,
): CollectionStore<DataType> & Extra {
  return {
    items: store.items,
    promise: store.promise,
    promiseInfo: store.promiseInfo,
    initialPromiseInfo: store.initialPromiseInfo,
    lastFetchedAt: store.lastFetchedAt,
    fetch: store.fetch,
    ensure: store.ensure,
    clearData: store.clearData,
    any: store.any,
    none: store.none,
    ...extra,
  };
}
