import { isFunction } from "lodash";
import { nextTick } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

/**
 * Note: when adding a route, don't forget to add a matching test
 * in tests/unit/router/index.spec.js
 * in order to prevent possible regressions in the routing.
 */

/* eslint-disable import/order */
// Home routes
import Welcome from "@/controllers/Welcome.vue";
import Overview from "@/controllers/Overview.vue";
import Projects from "@/controllers/Projects.vue";
import Collaborators from "@/controllers/Collaborators.vue";
import CollaborationsAccept from "@/controllers/collaborations/Accept.vue";

// Error routes
import NotFoundError from "@/controllers/misc/NotFoundError.vue";

// Misc routes
import About from "@/controllers/misc/About.vue";

// App-creation routes
import CreateApp from "@/controllers/create/App.vue";
import ConfigureApp from "@/controllers/configure/App.vue";

// App-related routes
import AppDetection from "@/controllers/AppDetection.vue";
import { appRoutes } from "@/router/app";

// Account routes
import { accountRoutes } from "@/router/account";

// Billing routes
import { billingRoutes } from "@/router/billing";
import Consumption from "@/controllers/Consumption.vue";

import i18n from "@/i18n";
import { Routes } from "@/router/names";
import { childRoutesForOAuthLinking } from "@/router/utils";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: Welcome,
    name: Routes.Welcome,
    redirect: (to) => {
      const { params, query } = to;
      return { name: Routes.Overview, params, query };
    },
    children: [
      {
        path: "",
        name: Routes.Overview,
        component: Overview,
        meta: {
          title: i18n.global.t("titles.overview"),
        },
      },
      {
        path: "apps",
        name: Routes.Projects,
        component: Projects,
        meta: {
          title: i18n.global.t("titles.projects"),
        },
      },
      {
        path: "collaborators",
        name: Routes.Collaborators,
        component: Collaborators,
        meta: {
          title: i18n.global.t("titles.collaborators"),
        },
      },
      {
        path: "consumption",
        name: Routes.Consumption,
        component: Consumption,
        meta: {
          title: i18n.global.t("titles.consumption"),
        },
      },

      billingRoutes,
      {
        path: "apps/:id",
        component: AppDetection,
      },
      {
        path: "collaborations/:region/accept",
        name: Routes.CollaborationsAccept,
        component: CollaborationsAccept,
      },
    ],
  },
  {
    path: "/create/app",
    name: Routes.Create.App,
    component: CreateApp,
    meta: {
      title: i18n.global.t("titles.app.creation"),
    },
  },
  {
    path: "/configure/:region/:id",
    name: Routes.Configure.App,
    component: ConfigureApp,
    meta: {
      title: i18n.global.t("titles.app.creation"),
    },
    children: childRoutesForOAuthLinking(Routes.Configure.App),
  },
  appRoutes,
  // Compatibility routes. Can be dropped once in prod for a little while.
  {
    path: "/new-app/",
    redirect: (to) => {
      const { params, query } = to;
      return { name: Routes.Create.App, params, query };
    },
    children: [
      {
        path: ":region/:id/deploy",
        children: [
          {
            path: "",
            redirect: (to) => {
              const { params, query } = to;
              return { name: Routes.Configure.App, params, query };
            },
          },
          ...childRoutesForOAuthLinking(Routes.Configure.App),
        ],
      },
    ],
  },
  accountRoutes,
  {
    path: "/about",
    name: Routes.About,
    component: About,
    meta: {
      title: i18n.global.t("titles.about"),
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundError,
    name: Routes.NotFoundError,
    meta: {
      title: i18n.global.t("titles.notFound"),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(/** to, from, savedPosition */) {
    return { left: 0, top: 0 };
  },
});

router.afterEach(async (to) => {
  const parts = [];

  if (process.env.VUE_APP_PLATFORM_ENV === "staging") {
    if (process.env.NODE_ENV === "development") {
      parts.push("Scalingo dev/staging");
    } else {
      parts.push("Scalingo staging");
    }
  } else {
    parts.push("Scalingo");
  }

  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  await nextTick();

  to.matched.forEach(async (route) => {
    if (route.meta.title) {
      if (isFunction(route.meta.title)) {
        parts.push(route.meta.title(to));
      } else {
        parts.push(route.meta.title);
      }
    }
  });

  const separator = " – ";
  const title = parts.join(separator);

  document.title = title;
});

export default router;
export { routes };
