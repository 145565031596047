export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the one that fit you best"])},
        "changeProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change addon"])},
        "changeProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come back to the previous step to allow you to choose another addon"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
        "providerNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Either there is no '", _interpolate(_named("addon")), "' addon, or it is already added to your app."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez celui qui vous convient le mieux"])},
        "changeProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un autre addon"])},
        "changeProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviens à l'étape précédente et vous permets de choisir un autre addon"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "providerNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Either there is no '", _interpolate(_named("addon")), "' addon, or it is already added to your app."])}
      }
    }
  })
}
