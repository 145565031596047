import { RouteRecordRaw } from "vue-router";

import AppAddonCreationRoot from "@/controllers/app/addonsCreation/Root.vue";
import AppAddonCreationStep1 from "@/controllers/app/addonsCreation/Step1.vue";
import AppAddonCreationStep2 from "@/controllers/app/addonsCreation/Step2.vue";
import AppAddonCreationStep3 from "@/controllers/app/addonsCreation/Step3.vue";
import AppAddonEditionRoot from "@/controllers/app/addonsEdition/Root.vue";
import AppAddonEditionStep2 from "@/controllers/app/addonsEdition/Step2.vue";
import AppAddonEditionStep3 from "@/controllers/app/addonsEdition/Step3.vue";
import i18n from "@/i18n";
import { AppAddonsRoutes as R } from "@/router/app/addons/names";

export const createAddonRoutes: RouteRecordRaw = {
  path: "new-addon/",
  component: AppAddonCreationRoot,
  name: R.Creation.Root,
  meta: {
    title: i18n.global.t("titles.app.addons.creation"),
  },
  redirect: (to) => {
    const { params, query } = to;
    return { name: R.Creation.Step1, params, query };
  },
  children: [
    {
      path: "",
      name: R.Creation.Step1,
      component: AppAddonCreationStep1,
      meta: {
        hasNavBar: true,
      },
    },
    {
      path: ":providerId",
      name: R.Creation.Step2,
      component: AppAddonCreationStep2,
      meta: {
        hasNavBar: true,
      },
    },
    {
      path: ":providerId/:planId",
      name: R.Creation.Step3,
      component: AppAddonCreationStep3,
      meta: {
        hasNavBar: true,
      },
    },
  ],
};

export const editAddonRoutes: RouteRecordRaw = {
  path: "addon/:addonId/edit",
  component: AppAddonEditionRoot,
  name: R.Edition.Root,
  meta: {
    title: i18n.global.t("titles.app.addons.edition"),
  },
  redirect: (to) => {
    const { params, query } = to;
    return { name: R.Edition.Step2, params, query };
  },
  children: [
    {
      path: "",
      name: R.Edition.Step2,
      component: AppAddonEditionStep2,
      meta: {
        hasNavBar: true,
      },
    },
    {
      path: ":planId",
      name: R.Edition.Step3,
      component: AppAddonEditionStep3,
      meta: {
        hasNavBar: true,
      },
    },
  ],
};
