<template>
  <ViewComponent :credits="credits" />
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/billing/Credits.vue";
import { Routes } from "@/router/names";
import { billingProfileAndFetch } from "@/store/billing-profile";
import { useCreditsStore } from "@/stores/credits";

export default defineComponent({
  name: "CreditsContainer",
  components: { ViewComponent },
  setup() {
    const creditsStore = useCreditsStore();

    onBeforeMount(() => creditsStore.ensure());

    return { creditsStore };
  },
  computed: {
    billingProfile() {
      return billingProfileAndFetch(this.$store);
    },

    credits() {
      const { items, promiseInfo, any, none } = this.creditsStore;

      return { items, latestFetch: promiseInfo, any, none };
    },
  },
  async beforeMount() {
    await this.billingProfile.latestFetch.settled;

    if (!this.billingProfile.value) {
      this.$router.push({ name: Routes.Billing.Root });
      return;
    }
  },
});
</script>
