<template>
  <main>
    <div class="mt-8">
      <Card>
        <template v-slot:header>
          <CardHeader
            :title="$t('title')"
            :subtitle="$t('subtitle')"
          ></CardHeader>
        </template>
        <template v-slot:body>
          <div v-if="collaborators.length">
            <SCTable
              class="mt-6"
              layout="auto"
              :headers="tableHeaders"
              :items="collaborators"
              :itemName="$t('itemName')"
            >
              <template #item.avatar="{ item }">
                <div class="flex place-items-center items-center flex-grow">
                  <CollaboratorPortrait
                    :item="collaboratorPortraitItem(item)"
                  ></CollaboratorPortrait>
                </div>
              </template>

              <template #item.apps="{ item }">
                {{ $tc("collaborators_table.columns.app", item.apps.length) }}
              </template>
            </SCTable>
          </div>
          <div v-else>
            <NoCollaborators :isAppOwner="isAppOwner" />
          </div>
        </template>
        <template v-slot:footer>
          <CardFooter :withViewAllButton="false"></CardFooter>
        </template>
      </Card>

      <ProTipAlert class="mt-4 flex">
        <div>{{ $t("proTip") }}</div>
      </ProTipAlert>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import CollaboratorPortrait from "@/components/parts/appSettings/collaboratorsSettings/CollaboratorPortrait.vue";
import NoCollaborators from "@/components/views/collaborators/NoCollaborators.vue";

export default defineComponent({
  name: "Collaborators",
  components: {
    CollaboratorPortrait,
    SCTable,
    CardFooter,
    CardHeader,
    Card,
    NoCollaborators,
    ProTipAlert,
  },
  props: {
    collaborators: {
      type: Array,
    },
    isAppOwner: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      tableHeaders: [
        { text: "", value: "avatar", class: "w-14" },
        {
          text: this.$t("collaborators_table.headers.username"),
          value: "username",
        },
        {
          text: this.$t("collaborators_table.headers.email"),
          value: "email",
        },
        {
          text: this.$t("collaborators_table.headers.apps"),
          value: "apps",
        },
      ],
    };
  },
  methods: {
    collaboratorPortraitItem(collaborator) {
      return {
        username: collaborator.username,
        status: collaborator.status,
        email: collaborator.email,
      };
    },
  },
});
</script>

<i18n>
en:
  title: "Collaborators of your applications"
  subtitle: "View the list of all collaborators of your applications."
  collaborators_table:
    headers:
      username: "Username"
      email: "Email"
      apps: "Assigned to"
    columns:
      app: "1 app | {count} apps"
  itemName: "collaborator"
  proTip: "Although this view presents a consolidated list, collaborators are managed individually in each application."
fr:
  title: "Collaborateurs de vos applications"
  subtitle: "Consultez la liste de tous les collaborateurs de vos applications."
  collaborators_table:
    headers:
      username: "Nom d’utilisateur"
      email: "Email"
      apps: "Assigné à"
    columns:
      app: "1 app | {count} apps"
  itemName: "collaborateur"
  proTip: "Bien que cette vue présente une liste consolidée, la gestion des collaborateurs s’effectue individuellement pour chaque application."
</i18n>
