<template>
  <ViewComponent :appInfos="matchingApps" />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/AppDetection.vue";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import { availableRegions } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";
import { useAppInfosStore } from "@/stores/app-infos";

export default defineComponent({
  name: "AppDetectionContainer",
  components: { ViewComponent },
  setup() {
    const appInfosStore = useAppInfosStore();

    return { appInfosStore };
  },
  computed: {
    matchingApps() {
      const { items, promiseInfo } = this.appInfosStore;

      const filteredItems = filterItems(items, {
        transform: (apps) => {
          return apps.filter((app) => app.name === this.$route.params.id);
        },
      });

      return { items: filteredItems, promiseInfo };
    },
  },
  watch: {
    "matchingApps.items": {
      handler(matchingApps) {
        if (matchingApps?.length === 1) {
          const app = matchingApps[0];
          this.$router.push({
            name: Routes.App.Root,
            params: { region: app.region, id: app.name },
          });
        }
      },
    },
  },
  created() {
    this.redirectToAppsListIfAppIsRegion();
    this.appInfosStore.ensure();
  },
  methods: {
    redirectToAppsListIfAppIsRegion() {
      let matchRegion = availableRegions.find(
        (region) => region.code === this.$route.params.id,
      );
      if (matchRegion) {
        this.$router.push({
          name: Routes.Projects,
          query: { region: this.$route.params.id },
        });
      }
    },
  },
});
</script>
