<template>
  <svg
    width="56"
    height="55"
    viewBox="0 0 56 55"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9982 0C12.537 0 0 12.5849 0 28.1127C0 40.5308 8.02199 51.0672 19.1502 54.7848C20.5502 55.0431 21.0612 54.1751 21.0612 53.4302C21.0612 52.7625 21.0367 50.9951 21.0227 48.6496C13.2335 50.3485 11.5903 44.881 11.5903 44.881C10.318 41.6342 8.48226 40.7697 8.48226 40.7697C5.93951 39.0251 8.67473 39.0602 8.67473 39.0602C11.4835 39.2587 12.9622 41.9574 12.9622 41.9574C15.4612 46.2531 19.516 45.0127 21.112 44.2924C21.3658 42.4757 22.0902 41.2371 22.89 40.5343C16.6722 39.8245 10.136 37.414 10.136 26.6422C10.136 23.5728 11.2263 21.0639 13.0183 19.0979C12.7295 18.3863 11.7688 15.5296 13.2913 11.659C13.2913 11.659 15.6432 10.9035 20.9912 14.5404C23.226 13.9167 25.62 13.6057 28.0018 13.5934C30.38 13.6057 32.7757 13.9167 35.0122 14.5404C40.3567 10.9035 42.7035 11.659 42.7035 11.659C44.2313 15.5296 43.2705 18.3863 42.9817 19.0979C44.7772 21.0639 45.8605 23.5728 45.8605 26.6422C45.8605 37.4403 39.3138 39.8174 33.0768 40.5132C34.0812 41.3811 34.9773 43.0959 34.9773 45.719C34.9773 49.4771 34.9422 52.5078 34.9422 53.4302C34.9422 54.1822 35.4463 55.0571 36.8673 54.7813C47.985 51.0566 56 40.5272 56 28.1127C56 12.5849 43.463 0 27.9982 0Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GitHubGraphic",
});
</script>
