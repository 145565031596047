<template>
  <main>
    <SectionTitle
      :title="$t('title')"
      :subtitle="$t('subtitle')"
      class="mb-6 mt-8"
    ></SectionTitle>
    <div
      v-if="usage.promiseInfo.isLoading"
      class="rounded bg-scale-0 px-8 py-6 flex flex-col"
    >
      <LoadingCardState></LoadingCardState>
    </div>
    <div
      v-else-if="
        usage.promiseInfo.isError && errorMessage === 'no billing profile'
      "
      class="rounded bg-scale-0 px-8 py-6 flex flex-col"
    >
      <EmptyCardState>
        <template #icon>
          <BillGlyph class="h-8"></BillGlyph>
        </template>
        <span class="text-scale-6 text-lg font-medium">{{
          $t("noBillingProfile")
        }}</span>
      </EmptyCardState>
    </div>

    <div
      v-else-if="usage.promiseInfo.isError"
      class="rounded bg-scale-0 px-8 py-6 flex flex-col"
    >
      <EmptyCardState>
        <template #icon>
          <BillGlyph class="h-8 text-error"></BillGlyph>
        </template>
        <span class="text-scale-6 text-lg font-medium">{{ $t("error") }}</span>
      </EmptyCardState>
    </div>
    <div v-else>
      <SCBanner v-if="hasOrder">
        {{ $t("orderWarning") }}
      </SCBanner>
      <Period
        class="mt-4"
        :usage="usage"
        :accountCreationDate="accountCreationDate"
        :accountName="accountName"
        :fromDate="fromDate"
        :toDate="toDate"
        :groupBy="groupBy"
        @resetToday="(e) => $emit('resetToday', e)"
        @groupBySelected="(e) => $emit('groupBySelected', e)"
        @periodSelected="(e) => $emit('periodSelected', e)"
      ></Period>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import Period from "@/components/parts/consumption/Period.vue";

export default defineComponent({
  name: "Consumption",
  components: {
    BillGlyph,
    EmptyCardState,
    LoadingCardState,
    Period,
    SectionTitle,
    SCBanner,
  },
  props: {
    usage: Object,
    accountCreationDate: String,
    accountName: String,
    fromDate: Object,
    toDate: Object,
    groupBy: String,
    hasOrder: Boolean,
  },
  computed: {
    errorMessage() {
      return this.usage.promiseInfo.err?.message;
    },
  },
});
</script>

<i18n>
en:
  title: "Consumption reports"
  subtitle: "This report is updated daily at midnight UTC."
  error: "An error occured."
  noBillingProfile: "Create a billing profile to follow your consumption."
  orderWarning: "The amounts displayed may differ from those shown on your orders. These are calculated using the price grid associated with your account and not one of the specific grids for one of your orders."
fr:
  title: "Rapports de consommation"
  subtitle: "Ce rapport est mis à jour quotidiennement à minuit UTC."
  error: "Une erreur est survenue."
  noBillingProfile: "Créez un profil de facturation pour suivre vos consommation."
  orderWarning: "Les montants affichés peuvent différer de ceux reportés sur vos commandes. Ceux-ci sont calculés en utilisant la grille de prix associée à votre compte et non une des grilles spécifiques à l'une de vos commandes."
</i18n>
