import { NotificationPlatform } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { HANDLE_FETCH, REFRESH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  listItems,
  ListItemsOptions,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { ApplicationStore } from "@/store";

export class NotificationPlatformsStore extends CollectionStore<NotificationPlatform> {
  actions = CollectionStore.buildActions<NotificationPlatform>({
    async [REFRESH](context, payload = {}) {
      const { regionCode } = payload;
      const promise = scalingoClient(
        context,
        regionCode,
      ).NotificationPlatforms.list();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ALL,
      });
    },
  });

  mutations = CollectionStore.buildMutations<NotificationPlatform>();
  getters = CollectionStore.buildGetters<NotificationPlatform>();
}

export const NotificationPlatforms = buildMapping(
  new NotificationPlatformsStore(),
  "notificationPlatforms",
);

export function listNotificationPlatforms(
  store: ApplicationStore,
  regionCode: string,
  opts?: Partial<ListItemsOptions<NotificationPlatform>>,
): CollectionWithFetch<NotificationPlatform> {
  const itemsGetter = `${regionCode}/${NotificationPlatforms.getters.ALL}`;
  const fetchGetter = `${regionCode}/${NotificationPlatforms.getters.LATEST_FETCH}`;

  return {
    items: listItems(store.getters[itemsGetter], opts),
    latestFetch: store.getters[fetchGetter],
  };
}

export function ensureNotificationPlatforms(
  store: ApplicationStore,
  regionCode: string,
  opts: EnsureOptions = {},
): void {
  opts.payload = opts.payload || {};
  opts.payload.regionCode = regionCode;

  store.dispatch(`${regionCode}/${NotificationPlatforms.actions.ENSURE}`, opts);
}
