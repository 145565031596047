export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "alert": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No configuration linked"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure a repository deployment method to be able to use review apps"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure"])}
        },
        "review_app_risk": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo does not recommend using review apps for production applications. Our recommendation is to create a second application linked to the same source code repository, but without production data."])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
        }
      },
      "fr": {
        "alert": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune configuration liée"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez une méthode de déploiement à partir d'un repo pour pouvoir utiliser les review apps"])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer"])}
        },
        "review_app_risk": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo déconseille l'utilisation de review apps pour les applications de production. Notre recommandation est de créer une deuxième application liée au même dépôt de code source, mais sans donnée de production."])},
          "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])}
        }
      }
    }
  })
}
