export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like something unplanned happened, and we can't recover from it."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload current page"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semblerait que quelque chose se soit mal passé et que cela nécessite de recharger la fenêtre."])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger la page"])}
      }
    }
  })
}
