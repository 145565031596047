export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canonical domain"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When enabled, all traffic coming to other domains will be redirected to the selected domain."])},
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage domains"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading domains..."])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No canonical domain configured"])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To configure a canonical domain, go to the Domains/SSL tab"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine canonique"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand configuré, tout le trafic des autres domaines sera redirigé vers le domaine choisi."])},
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les domaines"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des domaines..."])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun domaine canonique configuré"])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour configuer le nom de domaine canonique, aller dans l'onglet Domaines/SSL"])}
      }
    }
  })
}
