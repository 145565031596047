<template>
  <nav class="bg-scale-0 border-b border-scale-1">
    <div class="container px-4">
      <div class="flex justify-between h-14 items-center">
        <div class="flex mr-4 pv:mr-0">
          <router-link :to="rootRoute" class="flex-shrink-0 flex items-center">
            <SVGScalingoLogoFull class="h-8 w-28 text-logo" />
          </router-link>
        </div>
        <div class="sm:-my-px sm:ml-6 sm:flex w-auto">
          <TopInput
            :appInfos="appInfos"
            :openWhenMounted="openSearchWhenMounted"
            :animateDropdown="animateSearchDropdown"
            class="py-4"
            @updated="(e) => $emit('topInputUpdated', e)"
            @selected="(e) => $emit('topInputSelected', e)"
          />
        </div>
        <div>
          <button
            class="hidden p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
            aria-label="Notifications"
          >
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7332 11.3998L13.4249 12.0915C13.5789 12.2467 13.6657 12.4562 13.6666 12.6748V13.1665C13.6666 13.6267 13.2935 13.9998 12.8332 13.9998H1.16659C0.706348 13.9998 0.333252 13.6267 0.333252 13.1665V12.6748C0.334172 12.4562 0.420965 12.2467 0.574919 12.0915L1.26659 11.3998C1.73556 10.9314 1.99933 10.296 1.99992 9.63317V7.33317C1.99992 6.25132 2.35081 5.19865 2.99992 4.33317L3.74992 3.33317C4.22205 2.70366 4.96302 2.33317 5.74992 2.33317H6.16658V1.08317C6.16658 0.853052 6.35313 0.666504 6.58325 0.666504H7.41658C7.6467 0.666504 7.83325 0.853052 7.83325 1.08317V2.33317H8.24992C9.03681 2.33317 9.77778 2.70366 10.2499 3.33317L10.9999 4.33317C11.649 5.19865 11.9999 6.25132 11.9999 7.33317V9.63317C12.0005 10.296 12.2643 10.9314 12.7332 11.3998ZM6.99992 17.3332C7.92039 17.3332 8.66658 16.587 8.66658 15.6665H5.33325C5.33325 16.587 6.07944 17.3332 6.99992 17.3332Z"
                fill="#CBD2D9"
              />
            </svg>
          </button>

          <!-- Profile dropdowns -->
          <OnClickOutside class="ml-3 relative" @trigger="hideProfileMenu">
            <div
              id="profile-dropdown-icon"
              :title="$t('titles.button')"
              @click="showMenu = !showMenu"
            >
              <img
                v-if="gravatar"
                :src="gravatar"
                :class="avatarClasses"
                alt=""
              />
              <div v-else class="bg-gray-400" :class="avatarClasses"></div>
            </div>

            <DropdownMenu
              class="w-64"
              align="right"
              :show="user && showMenu"
              :items="menuActions"
              @click="actionClicked"
            >
              <template #prepend>
                <div class="px-4 py-2 text-sm leading-5 flex flex-col">
                  <span class="text-scale-10 text-xl font-bold py-2">
                    {{ user.username }}
                  </span>
                  <span class="text-scale-5 font-medium">{{ user.email }}</span>
                </div>
              </template>
            </DropdownMenu>
          </OnClickOutside>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";
import { defineComponent } from "vue";

import TopInput from "@/components/atoms/specializedInputs/TopInput.vue";
import SVGScalingoLogoFull from "@/components/graphics/scalingo/LogoFull.vue";
import DropdownMenu from "@/components/molecules/dropdowns/DropdownMenu.vue";
import { userAvatar } from "@/lib/utils/avatars";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Header",
  components: { OnClickOutside, SVGScalingoLogoFull, DropdownMenu, TopInput },
  props: {
    user: Object,
    appInfos: Object,
    openSearchWhenMounted: {
      type: Boolean,
      default: false,
    },
    animateSearchDropdown: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["signOutClicked", "topInputUpdated", "topInputSelected"],
  data() {
    return {
      showMenu: false,
      rootRoute: { name: Routes.Welcome },
      avatarClasses:
        "h-8 w-8 rounded-full flex-shrink cursor-pointer hover:opacity-75",
    };
  },
  computed: {
    menuActions() {
      const actionClass = "block px-4 py-2 font-medium leading-5";

      return [
        {
          text: this.$t("userSettings"),
          wrapperClass: actionClass,
          to: {
            name: Routes.Account.Basic,
          },
        },
        {
          text: this.$t("billing"),
          wrapperClass: actionClass,
          to: {
            name: Routes.Billing.Root,
          },
        },
        {
          text: this.$t("signOut"),
          wrapperClass: actionClass,
          key: "logout",
          kind: "warning",
        },
      ];
    },
    gravatar() {
      return this.user ? userAvatar(this.user) : null;
    },
  },
  methods: {
    hideProfileMenu() {
      this.showMenu = false;
    },
    actionClicked({ action }) {
      this.hideProfileMenu();

      if (action.key === "logout") {
        this.$emit("signOutClicked");
        return;
      }
    },
  },
});
</script>

<i18n>
en:
  signOut: "Sign out"
  userSettings: "User settings"
  billing: "Billing"
  titles:
    button: "Open user menu"
fr:
  signOut: "Déconnecter"
  userSettings: "Paramètres utilisateur"
  billing: "Facturation"
  titles:
    button: "Ouvre le menu utilisateur"
</i18n>
