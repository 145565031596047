export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deployments"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable automatic deployments from a branch"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "branches": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading branches..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a branch"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a repository first"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements automatiques"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les déploiements automatiques à partir d'une branche"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "branches": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
          "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des branches..."])},
          "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une branche"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un dépôt"])}
        }
      }
    }
  })
}
