<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"> </CardHeader>
    </template>
    <template #body>
      <div v-if="extensionList.length > 0" class="my-4">
        {{ $t("withExtensions") }}
        <div class="my-4 flex flex-wrap gap-2">
          <div
            v-for="extension in extensionList"
            :key="extension.name"
            class="col-auto rounded border border-padding"
          >
            <div class="flex items-center p-2">
              <TwoStatesIndicator class="mr-2" :isOn="true" />
              <div>{{ extension.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="my-4 flex flex-wrap gap-2">
        <p v-html="$t('noExtension')" />
      </div>
      <InformationAlert class="mt-3">
        <p v-html="$t('info')" />
      </InformationAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";

export default defineComponent({
  name: "PostgresExtensions",
  components: { Card, CardHeader, TwoStatesIndicator, InformationAlert },
  props: {
    extensionList: Array,
  },
});
</script>

<i18n>
  en:
    title: "PostgreSQL Extensions"
    subtitle: "Extend the capabilities of your PostgreSQL database."
    info: "PostgreSQL extensions management is exclusively done through the remote interactive console. For more information, please \
      <a class='underline' href='https://doc.scalingo.com/databases/postgresql/managing#managing-extensions' target='_blank'>refer to our documentation</a>."
    withExtensions: "This database has the following active extensions:"
    noExtension: "This database does not use any PostgreSQL extensions. Popular ones include \
      <a class='underline' href='https://postgis.net/' target='_blank'>PostGIS</a>, \
      <a class='underline' href='https://github.com/pgvector/pgvector' target='_blank'>pgvector</a>, and \
      <a class='underline' href='https://github.com/timescale/timescaledb' target='_blank'>TimescaleDB</a>. \
      <a class='underline' href='https://doc.scalingo.com/databases/postgresql/overview#available-extensions' target='_blank'>Check the list of supported extensions</a> \
      for Scalingo for PostgreSQL®."
  fr:
    title: "Extensions PostgreSQL"
    subtitle: "Étendez les fonctionnalités de votre base de données PostgreSQL."
    info: "La gestion des extensions PostgreSQL s'effectue exclusivement via la console interactive à distance. Pour plus d'informations, veuillez \
      <a class='underline' href='https://doc.scalingo.com/databases/postgresql/managing#managing-extensions' target='_blank'>consulter notre documentation</a>."
    withExtensions: "Cette base de données dispose des extensions actives suivantes :"
    noExtension: "Cette base de données n'utilise aucune extension PostgreSQL. \
      <a class='underline' href='https://postgis.net/' target='_blank'>PostGIS</a>, \
      <a class='underline' href='https://github.com/pgvector/pgvector' target='_blank'>pgvector</a>, ou encore \
      <a class='underline' href='https://github.com/timescale/timescaledb' target='_blank'>TimescaleDB</a> font partie des plus populaires. \
      <a class='underline' href='https://doc.scalingo.com/databases/postgresql/overview#available-extensions' target='_blank'>Consultez la liste des extensions supportées</a> \
      par Scalingo pour PostgreSQL®."
</i18n>
