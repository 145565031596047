export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing profile"])},
        "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your billing profile is managed by the C3 market administrator."])},
        "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact him if you have any questions about monitoring of your consumption or billing."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil de facturation"])},
        "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre profil de facturation est géré par l'administrateur du marché C3."])},
        "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez le contacter pour toute question relative au suivi de votre consommation ou de votre facturation."])}
      }
    }
  })
}
