<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28711 0.479736L2.05859 9.09009L1.87695 9.57935L0.347656 13.6575L11 21.4065L21.6523 13.6575L20.123 9.57935L16.71 0.479736L13.9121 9.09009H8.08789L5.28711 0.479736ZM5.1875 5.02075L6.51172 9.09009H3.66113L5.1875 5.02075ZM16.8125 5.02075L18.3389 9.09009H15.4883L16.8125 5.02075ZM3.09863 10.5901H6.99805L9.57031 18.512L2.15234 13.1155L3.09863 10.5901ZM8.57422 10.5901H13.4258L11 18.052L8.57422 10.5901ZM15.002 10.5901H18.8984L19.8477 13.1155L12.4268 18.512L15.002 10.5901Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGModelSCMGitlab",
});
</script>
