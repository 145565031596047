<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM11 15.5C11 15.7761 10.7761 16 10.5 16H9.5C9.22386 16 9 15.7761 9 15.5V14.5C9 14.2239 9.22386 14 9.5 14H10.5C10.7761 14 11 14.2239 11 14.5V15.5ZM11.88 10.29C13.0655 9.9063 13.8716 8.806 13.88 7.56V7C13.88 6.23618 13.5766 5.50364 13.0365 4.96353C12.4964 4.42343 11.7638 4.12 11 4.12H9C7.40942 4.12 6.12 5.40942 6.12 7V7.5C6.12 7.77614 6.34386 8 6.62 8H7.38C7.65614 8 7.88 7.77614 7.88 7.5V7C7.88 6.38144 8.38144 5.88 9 5.88H11C11.3039 5.86914 11.5992 5.98233 11.8179 6.19356C12.0367 6.40479 12.1602 6.6959 12.16 7V7.56C12.161 8.04231 11.8557 8.47208 11.4 8.63L10.45 8.94C9.68176 9.19392 9.16216 9.91089 9.16 10.72V11.5C9.16 11.7761 9.38386 12 9.66 12H10.42C10.6961 12 10.92 11.7761 10.92 11.5V10.72C10.92 10.6675 10.9516 10.6202 11 10.6L11.88 10.29Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "QuestionGlyph",
});
</script>
