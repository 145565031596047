import { DateTime } from "luxon";

import { dashboard } from "@/lib/utils/log";

/** The arguments to pass to .ensure */
export type EnsureOptions = {
  /** Trigger a fretch even if data is already present. "always", or a number of seconds */
  staleAfter?: "always" | number | null | undefined;
};

/** The options needed for shouldFetch */
export type ShouldFetchOpts<DataType = unknown> = {
  data: DataType;
  lastFetchedAt: DateTime | null;
};

/** Wether the data should be (re)fetched */
export function shouldFetch<DataType = unknown>(
  { data, lastFetchedAt }: ShouldFetchOpts<DataType>,
  staleAfter: "always" | number | null | undefined,
): boolean {
  let performFetch = false;

  if (staleAfter === "always") {
    dashboard.debug("pinia: always (re)fetching");

    performFetch = true;
  } else if (!data) {
    dashboard.debug("pinia: no values, fetching");

    performFetch = true;
  } else if (!lastFetchedAt) {
    dashboard.debug("pinia: no last fetch at, fetching");

    performFetch = true;
  } else if (staleAfter) {
    const threshold = lastFetchedAt.plus({
      seconds: staleAfter,
    });

    dashboard.debug("pinia: stale data, refetching");

    performFetch = threshold < DateTime.local();
  } else {
    dashboard.debug("pinia: not fetching");
  }

  return performFetch;
}
