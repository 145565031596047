import {
  Response,
  decodeToken,
  Middleware,
  shouldRefreshToken,
  TOKEN_PATH,
} from "@/lib/auth/utils";

// Possible errors
const CACHE_EMPTY = { error: "cache/empty" };
const CACHE_UNREADABLE = { error: "cache/unreadable" };
const CACHE_EXPIRED = { error: "cache/expired" };

/**
 * Looks for a token into the cache (by default, local storage).
 * Resolves if present and valid, rejects otherwise.
 */
export class LoginFromCache implements Middleware {
  name = "LoginFromCache";

  constructor(readonly storage: Storage = localStorage) {}

  call(): Promise<Response> {
    const token = this.storage.getItem(TOKEN_PATH);

    return new Promise((resolve, reject) => {
      if (!token) {
        reject(CACHE_EMPTY);
        return;
      }

      try {
        const decoded = decodeToken(token);

        if (shouldRefreshToken(decoded.payload)) {
          reject(CACHE_EXPIRED);
          return;
        }

        resolve(decoded);
      } catch (e) {
        reject({ ...CACHE_UNREADABLE, exception: e });
      }
    });
  }
}
