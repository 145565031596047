import { computed, ComputedRef } from "vue";

import {
  ResourceStoreOptions,
  ResourceStore,
  useResourceStore,
} from "@/lib/pinia/use-resource-store-next";

// Note: this file aims to replace @/lib/pinia/use-collection-store.ts
// As it serves as the foundations for many stores, rather than having a big bang refactor
// this file is here to allow progressive refactoring of the stores.
//
// Main changes from collection-store:
// - No notion of public/private interface -- most of the complexity came from this notion and it serves little to no purpose
// - Better support for what to do when a fetch fails through options.errorHandler, see @/lib/pinia/utils/errors

//======== Types ========//
/** The interface of the store */
export type CollectionStore<DataType> = Omit<
  ResourceStore<DataType[]>,
  "item"
> & {
  items: ResourceStore<DataType[]>["item"];
  /** Wether there is currently anything in the collection */
  any: ComputedRef<boolean>;
  /** Wether there is currently nothing in the collection */
  none: ComputedRef<boolean>;
  /** Inserts an item at the end of the collection. */
  insertItem: InsertItemFn<DataType>;
  /** Inserts many items at the end of the collection. */
  insertItems: InsertItemsFn<DataType>;
  /** Splice the collection data */
  spliceItems: SpliceDataFn<DataType>;
};

export type CollectionStoreOptions<DataType> = ResourceStoreOptions<DataType[]>;

export type InsertItemFn<DataType> = (data: DataType) => DataType;
export type InsertItemsFn<DataType> = (data: DataType[]) => DataType[];

export type SplicePayload<DataType> = {
  start: number;
  deleteCount: number;
  items?: DataType[];
};

export type SpliceDataFn<DataType> = (opts: SplicePayload<DataType>) => void;

/** Returns an data store tailored for a collection of resources */
export function useCollectionStore<DataType>(
  options: CollectionStoreOptions<DataType>,
): CollectionStore<DataType> {
  const store = useResourceStore(options);
  const items = store.item;

  function insertItem(data: DataType) {
    insertItems([data]);

    return data;
  }

  function insertItems(data: DataType[]) {
    if (!items.value) {
      items.value = [...data];
    } else {
      items.value.push(...data);
    }

    return data;
  }

  function spliceItems(opts: SplicePayload<DataType>) {
    if (!items.value) items.value = [];

    if (opts.deleteCount && opts.items) {
      items.value.splice(opts.start, opts.deleteCount, ...opts.items);
    } else if (opts.deleteCount) {
      items.value.splice(opts.start, opts.deleteCount);
    } else {
      items.value.splice(opts.start);
    }
  }

  const any = computed(() => {
    if (!items.value) return false;

    return items.value.length > 0;
  });

  const none = computed(() => !any.value);

  return {
    items,
    any,
    none,
    promise: store.promise,
    promiseInfo: store.promiseInfo,
    lastFetchedAt: store.lastFetchedAt,
    fetch: store.fetch,
    ensure: store.ensure,
    setData: store.setData,
    $reset: store.$reset,
    insertItem,
    insertItems,
    spliceItems,
  };
}
