<template>
  <div :class="classFn" class="h-3 w-3 rounded-full" :title="titleFn"></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TwoStatesIndicator",
  props: {
    isOn: Boolean,
    alternativeColors: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    classFn() {
      let classString;
      if (this.isOn) {
        classString += " bg-color-1";
      }
      if (!this.isOn) {
        classString += !this.alternativeColors ? " bg-color-2" : " bg-scale-5";
      }
      classString += " h-" + this.size + " " + "w-" + this.size;
      return classString;
    },
    titleFn() {
      if (this.isOn) {
        return this.$t("enabledText");
      }

      return this.$t("disabledText");
    },
  },
});
</script>
<i18n>
en:
  enabledText: "Enabled"
  disabledText: "Disabled"
fr:
  enabledText: "Activé"
  disabledText: "Désactivé"
</i18n>
