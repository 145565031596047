<template>
  <SimpleModal size="w-1/2 xl:w-1/4" @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template v-slot:body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="mt-6">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.name"
              name="name"
              rules="required"
            >
              <TextInput
                :name="field.name"
                :modelValue="field.value"
                :label="$t('nameLabel')"
                :placeholder="$t('namePlaceholder')"
                :errors="errors"
                @update:modelValue="handleChange"
              ></TextInput>
            </Field>
          </div>
          <div class="flex">
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid"
              :loading="isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";

export default defineComponent({
  name: "AddTokenModal",
  components: {
    TextInput,
    SCButton,
    SimpleModal,
    Field,
    Form,
  },
  mixins: [FormModalHandlerMixin],
  emits: ["modalClosed"],
});
</script>

<i18n>
en:
  title: "Create token"
  subtitle: "A token will allow authorization as your account"
  buttonName: "Create"
  nameLabel: "Token name"
  namePlaceholder: "name"
fr:
  title: "Create token"
  subtitle: "Un token autorisera l'accès en tant que votre compte"
  buttonName: "Create"
  nameLabel: "Nom du token"
  namePlaceholder: "nom"
</i18n>
