<template>
  <svg
    width="400"
    height="400"
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M152.197 247.501C163.339 273.218 173.039 300.867 198.352 315.716C208.787 321.846 219.706 322.401 231.526 320.577C246.476 318.269 258.101 314.533 271.039 307.596C285.138 300.031 296.467 288.254 308.641 277.884C318.917 269.121 328.646 259.075 338.548 249.758C348.146 240.729 357.558 231.974 364.091 220.68L365.649 218.885C366.01 219.079 366.37 219.26 366.753 219.447V218.885C366.753 180.766 332.402 149.763 297.634 165.39C297.348 165.518 297.063 165.648 296.777 165.778C280.984 172.989 266.986 182.74 253.702 193.824C241.723 203.812 229.499 215.315 214.449 220.428C197.256 226.262 180.633 220.046 163.714 216.375C154.389 214.356 144.899 213.49 135.776 214.702C143.312 224.322 146.976 235.472 152.197 247.501Z"
      fill="#E4E7EB"
    />
    <path
      d="M67.7108 126.986C69.9969 130.347 72.3695 134.234 74.9225 137.71C69.6796 131.285 62.7563 136.902 62.8284 143.754C62.915 152.134 71.0931 153.468 77.4538 154.023C85.3867 154.744 92.5479 153.691 97.9062 146.876C102.349 141.229 104.909 136.246 100.163 130.289C95.4181 124.332 89.1223 117.416 83.4539 112.873"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.1649 162.675C42.3908 157.079 38.1719 151.137 35.9219 143.47C33.4771 135.184 32.6838 130.345 33.1093 121.554C33.8738 105.688 50.8357 98.4405 61.6605 88.4451C67.3144 83.2238 73.7329 79.0699 79.8556 74.5409C84.1826 71.3534 92.2958 65.8869 97.4233 65.8869C104.873 65.8436 109.755 74.2957 114.01 79.2285C119.39 85.4811 123.962 90.7673 128.614 97.5463C131.917 102.378 136.951 108.674 134.29 114.898C132.126 119.946 124.265 123.126 119.08 122.694"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.0749 94.0059C104.541 96.3785 114.717 110.953 116.931 116.701C121.611 128.817 109.056 129.783 102.349 134.305"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.9511 183.763C60.4706 184.167 70.3506 181.354 79.5528 178.881C86.4912 177.042 93.0846 174.088 99.0749 170.133C99.0749 170.133 112.258 195.035 110.678 198.922C108.392 204.562 91.7982 208.932 87.1827 210.078C77.757 212.415 66.6366 215.055 55.3575 215.055C53.0786 204.922 50.4607 193.939 48.3044 183.871C49.2708 183.46 49.617 183.907 50.9511 183.763Z"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M127.193 121.584C134.333 131.226 126.933 135.243 118.539 141.582C114.169 144.885 103.149 151.037 100.221 143.306"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M192.03 212.077L195.915 220.467"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M237.991 126.938L246.717 127.392"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M228.462 171.77L236.263 175.703"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M237.921 85.084L244.029 81.8655"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.406 118.884L3.00012 122.066"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8804 65.0816L9.45742 61.9644"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.9551 49.0051L15.0247 43.2836"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.1978 27.8302L57.1001 20.9994"
      stroke="#183BEE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.9514 183.762C50.9514 183.762 42.9825 163.519 42.3983 156.574"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M112.597 146.457C109.713 156.553 96.5802 167.45 86.1016 165.381"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M94.2064 164.84L96.9108 170.789"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M166.771 89.334C166.714 94.7355 167.925 110.407 171.206 123.712"
      stroke="#22D1EE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.8193 214.045C49.812 228.331 46.372 243.375 60.031 256.183C51.0452 255.325 50.9514 279.26 54.7808 285.996"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M110.678 198.921C116.037 198.236 123.912 203.191 128.275 206.926C141.307 218.069 145.489 232.009 152.196 247.5C163.338 273.216 173.038 300.866 198.351 315.715C208.786 321.845 219.705 322.4 231.525 320.576C246.475 318.268 258.1 314.532 271.038 307.595C285.137 300.03 296.466 288.253 308.64 277.882C318.916 269.12 328.645 259.074 338.547 249.757C348.146 240.728 357.557 231.973 364.091 220.679"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.188 70.9435C122.506 69.1477 141.884 71.1382 151.078 71.5637C158.968 71.9315 166.987 72.7247 174.92 72.3786C178.483 72.2199 185.19 70.2151 187.981 75.3426C196.015 90.0977 157.865 89.7155 153.675 90.0977C144.256 90.9631 121.943 90.4295 123.227 90.2131"
      stroke="#1F2933"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M177.034 116.355H165.755C163.056 116.357 160.468 117.43 158.559 119.338C156.65 121.245 155.576 123.832 155.572 126.531V127.498C155.574 130.196 156.646 132.784 158.554 134.693C160.462 136.602 163.049 137.677 165.747 137.68H167.731C167.731 138.914 167.731 140.428 167.731 142.166C167.623 160.433 167.529 180.208 167.774 183.633C167.834 184.452 168.201 185.217 168.802 185.776C169.402 186.335 170.192 186.647 171.012 186.648H171.25C171.675 186.617 172.09 186.502 172.471 186.311C172.852 186.119 173.192 185.855 173.47 185.532C173.749 185.209 173.961 184.834 174.095 184.429C174.229 184.025 174.282 183.597 174.25 183.172C174.25 182.876 174.207 182.4 174.192 181.787H180.207C181.068 181.787 181.893 181.445 182.502 180.837C183.11 180.228 183.452 179.403 183.452 178.542C183.452 177.681 183.11 176.856 182.502 176.247C181.893 175.639 181.068 175.297 180.207 175.297H178.043C178.904 175.297 179.73 174.955 180.338 174.346C180.947 173.738 181.289 172.912 181.289 172.051C181.289 171.191 180.947 170.365 180.338 169.757C179.73 169.148 178.904 168.806 178.043 168.806H174.113C174.113 159.907 174.178 149.01 174.221 142.209C174.221 140.464 174.221 138.935 174.221 137.688H177.027C179.726 137.684 182.314 136.61 184.223 134.701C186.132 132.792 187.206 130.204 187.21 127.505V126.538C187.208 123.84 186.135 121.252 184.227 119.343C182.32 117.434 179.733 116.359 177.034 116.355ZM180.726 127.498C180.724 128.476 180.335 129.414 179.643 130.106C178.951 130.798 178.013 131.188 177.034 131.19H165.755C164.777 131.188 163.839 130.798 163.148 130.106C162.458 129.414 162.07 128.476 162.07 127.498V126.531C162.07 125.554 162.458 124.616 163.149 123.925C163.84 123.234 164.777 122.846 165.755 122.846H177.034C178.012 122.846 178.95 123.234 179.642 123.925C180.334 124.616 180.724 125.553 180.726 126.531V127.498Z"
      fill="#FCE965"
    />
    <path
      d="M171.206 123.689C174.019 112.871 176.118 102.869 176.377 93.0968C176.478 89.0077 178.223 73.243 175.187 70.2429C172.461 67.5529 170.579 69.3703 169.331 72.2261"
      stroke="#22D1EE"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HandKeyGraphic",
});
</script>
