export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "setConfiguration": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration Updated"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration as been updated successfully."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please reload the page and try again."])}
          }
        }
      },
      "fr": {
        "setConfiguration": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration mise à jour"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été mise à jour avec succès."])}
          },
          "error": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez recharger la page et réessayer."])}
          }
        }
      }
    }
  })
}
