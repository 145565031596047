export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest events."])},
        "events": {
          "deploymentInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deployment in progress"])},
          "buildError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["build error"])},
          "containersRestarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["containers restarted"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derniers événements."])},
        "events": {
          "deploymentInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déploiement en cours"])},
          "buildError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur durant la création"])},
          "containersRestarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conteneurs redémarrés"])}
        }
      }
    }
  })
}
