<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :alerts="alerts"
    :deletionHandler="deletionHandler"
    @startAlertDeletion="startAlertDeletion"
    @cancelAlertDeletion="cancelAlertDeletion"
    @comfirmAlertDeletion="deletionHandler.submit()"
  >
  </ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/alerts/List.vue";
import { DeleteAlertHandler } from "@/lib/handlers";
import { ensureAlerts, listAlerts } from "@/store/alerts";

export default defineComponent({
  name: "AlertsListContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deletionHandler: null,
    };
  },
  computed: {
    alerts() {
      return listAlerts(this.$store);
    },
  },
  beforeMount() {
    ensureAlerts(this.$store);
  },
  methods: {
    startAlertDeletion({ alert }) {
      this.deletionHandler = new DeleteAlertHandler(this, alert);
    },
    cancelAlertDeletion() {
      this.deletionHandler = null;
    },
  },
});
</script>
