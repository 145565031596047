export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A break down of each billed elements"])},
        "labels": {
          "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
        },
        "totalFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total for ", _interpolate(_named("name"))])},
        "duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["none"]), _normalize(["1 minute"]), _normalize([_interpolate(_named("count")), " minutes"])])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un détail de tous les éléments facturés"])},
        "labels": {
          "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])}
        },
        "totalFor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total for ", _interpolate(_named("name"))])},
        "duration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucune"]), _normalize(["1 minute"]), _normalize([_interpolate(_named("count")), " minutes"])])}
      }
    }
  })
}
