<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <UserGlyph class="h-6 w-6 text-warning"></UserGlyph>
      <div class="text-warning pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #body>
      <div>
        IP:
        <span class="font-medium ml-1">{{ event.type_data.remote_ip }}</span>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import UserGlyph from "@/components/atoms/glyphs/UserGlyph";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "LoginFailureEvent",
  components: { UserGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Login failure"
fr:
  title: "Échec de la connexion"
</i18n>
