export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected as"])},
        "connectedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
        "actions": {
          "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])}
        }
      },
      "fr": {
        "connectedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté en tant que"])},
        "connectedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
        "actions": {
          "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
        }
      }
    }
  })
}
