export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amounts"])},
        "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't expire"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
      },
      "fr": {
        "amounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montants"])},
        "never": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'expire jamais"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
      }
    }
  })
}
