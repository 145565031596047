<template>
  <AdvancedSelectInput
    :searchable="true"
    :options="countriesList"
    :modelValue="modelValue"
    :required="required"
    @update:modelValue="(e) => $emit('update:modelValue', e)"
  >
    <template #label>
      {{ $t("country") }}
    </template>

    <template #picture="{ option }">
      <flag :iso="option.value" />
    </template>

    <template #text="{ option }">
      {{ option.text }}
    </template>
  </AdvancedSelectInput>
</template>

<script>
import { isArray } from "lodash";
import { defineComponent } from "vue";

import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import countries from "@/lib/utils/countries";

export default defineComponent({
  name: "CountrySelect",
  components: { AdvancedSelectInput },
  props: {
    modelValue: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {};
  },
  computed: {
    countries() {
      return countries.getNames(this.$i18n.locale);
    },
    countriesList() {
      return Object.keys(this.countries).reduce((acc, key) => {
        // values can be a string or a string[] with variants
        const text = isArray(this.countries[key])
          ? this.countries[key][0]
          : this.countries[key];

        acc.push({ text: text, value: key });

        return acc;
      }, []);
    },
  },
});
</script>

<i18n>
en:
  country: "Country"
  noResult: "No result for"
fr:
  country: "Pays"
  noResult: "Pas de résultat pour"
</i18n>
