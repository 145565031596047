export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "restart": {
          "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is restarting …"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application was successfully restarted"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application could not be restarted"])}
        },
        "scale": {
          "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is scaling …"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application was successfully scaled"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application could not be scaled"])}
        }
      },
      "fr": {
        "restart": {
          "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrage en cours …"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application was successfully restarted"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application could not be restarted"])}
        },
        "scale": {
          "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application is scaling …"])},
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application was successfully scaled"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application could not be scaled"])}
        }
      }
    }
  })
}
