export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created at"])},
        "type": {
          "sepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA"])},
          "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])}
        },
        "actions": {
          "mandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See mandate"])},
          "makeDefault": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make default ", _interpolate(_named("type"))])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
        }
      },
      "fr": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détails"])},
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date d'ajout"])},
        "type": {
          "sepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA"])},
          "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte"])}
        },
        "actions": {
          "mandate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le mandat"])},
          "makeDefault": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mettre par défaut ", _interpolate(_named("type"))])},
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
        }
      }
    }
  })
}
