export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCM Integrations"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your account to enable advanced features such as automatic deployments and review apps"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrations SCM"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez vos comptes pour bénéficier des déploiements et reviews apps automatiques"])}
      }
    }
  })
}
