export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler for container ", _interpolate(_named("type"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure how this resource will automatically scale"])},
        "errors": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not save autoscaler: make sure the values selected are correct."])}
        },
        "form": {
          "containersRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers range"])},
          "containersRangeMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum containers"])},
          "containersRangeMaximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum containers"])},
          "scaleWhen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale when"])},
          "variesFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varies from"])},
          "noRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recommended value"])},
          "reenable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enable autoscaler"])},
          "variesFromValues": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5xx errors"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests per minute"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPM per container"])}
          },
          "variesFromSubText": {
            "cpu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " % recommended"])},
            "memory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " % recommended"])},
            "swap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " % recommended"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " ms recommended"])},
            "5XX": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " recommended"])},
            "rpm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " rpm recommended"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " rpm recommended"])}
          },
          "variesFromUnit": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per min"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])}
          }
        },
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler pour le container ", _interpolate(_named("type"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer commment cette ressource va automatiquement changer d'échelle"])},
        "errors": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sauvegarder l'autoscaler : vérifiez que les valeurs sélectionnées sont correctes."])}
        },
        "form": {
          "containersRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalle des containers"])},
          "containersRangeMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum de containers"])},
          "containersRangeMaximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum de containers"])},
          "scaleWhen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer quand"])},
          "variesFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur varie de"])},
          "noRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune valeur recommandée"])},
          "reenable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver l'autoscaler"])},
          "variesFromValues": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs 5xx"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes par minute"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPM par container"])}
          },
          "variesFromSubText": {
            "cpu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " % recommandé"])},
            "memory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " % recommandé"])},
            "swap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " % recommandé"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " ms recommandé"])},
            "5XX": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " recommandé"])},
            "rpm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " rpm recommandé"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " rpm recommandé"])}
          },
          "variesFromUnit": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par min"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])}
          }
        },
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      }
    }
  })
}
