<template>
  <div
    ref="logConsole"
    :class="logConsoleClasses"
    class="theme-light text-scale-2 font-mono flex flex-col bg-scale-10 text-primary-4 px-2 py-1 rounded overflow-scroll border-scale-9 border"
  >
    <div
      v-if="isLoading"
      class="rounded-full flex mt-20 place-self-stretch items-center justify-center"
    >
      <Spinner class="h-10 w-10 text-scale-5"></Spinner>
    </div>

    <div
      v-else-if="htmlLogs"
      class="whitespace-pre-wrap"
      v-html="htmlLogs"
    ></div>
    <div v-for="(line, index) in lines" v-else :key="index">
      <SmartLogLine v-if="smartColoration" :line="line"></SmartLogLine>
      <div v-else>{{ line }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SmartLogLine from "@/components/parts/app/showLogs/SmartLogLine.vue";

export default defineComponent({
  name: "ShowLogsConsole",
  components: { SmartLogLine, Spinner },
  props: {
    lines: Array,
    htmlLogs: String,
    autoScroll: Boolean,
    fontSize: {
      type: String,
      default: "text-sm",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    smartColoration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logConsoleClasses() {
      let classes = this.fontSize;

      if (this.fullscreen) {
        classes += " console-height-fullscreen";
      } else if (this.small) {
        classes += " console-height-small";
      } else {
        classes += " console-height";
      }

      return classes;
    },
  },
  watch: {
    lines: {
      deep: true,
      handler() {
        this.scrollToBottomIfRelevant();
      },
    },
    htmlLogs() {
      this.scrollToBottomIfRelevant();
    },
  },
  methods: {
    scrollToBottomIfRelevant() {
      if (!this.autoScroll) {
        return;
      }

      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    scrollToBottom() {
      const el = this.$refs.logConsole;
      el.scrollTop = el.scrollHeight;
    },
  },
});
</script>

<style scoped>
.console-height-small {
  min-height: 52px;
}

.console-height {
  max-height: 80vh;
  min-height: 300px;
}
.console-height-fullscreen {
  max-height: 93vh;
  min-height: 93vh;
}
</style>
