<template>
  <AppCard
    :app="app"
    :scmRepoLink="scmRepoLink"
    class="transition-shadow hover:shadow-lg"
  >
    <template #header>
      <div class="flex-grow text-2xl font-bold truncate text-scale-10">
        <router-link
          class="hover:underline cursor-pointer flex flex-grow"
          :to="routerArgs"
          :title="app.name"
        >
          {{ app.name }}
        </router-link>
      </div>
      <div v-if="displayIcons" class="self-center flex">
        <font-awesome-icon
          v-if="displayHDS"
          icon="staff-snake"
          class="text-success h-5"
          :title="$t('hdsResource')"
        />

        <Region
          v-if="displayFlag"
          class="ml-2 h-4"
          :width="26"
          :regionCode="app.region"
        />
      </div>
    </template>
    <template #body>
      <SCMIntegrationLinks :app="app" :scmRepoLink="scmRepoLink" />
    </template>
  </AppCard>
</template>

<script>
import { defineComponent } from "vue";

import Region from "@/components/organisms/app/Region.vue";
import AppCard from "@/components/organisms/appCards/AppCard.vue";
import SCMIntegrationLinks from "@/components/organisms/appCards/SCMIntegrationLinks.vue";
import { defaultRegion } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ListingAppCard",
  components: { SCMIntegrationLinks, AppCard, Region },
  props: {
    app: Object,
    scmRepoLink: Object,
    multiRegions: Boolean,
  },
  computed: {
    displayIcons() {
      return this.displayHDS || this.displayFlag;
    },
    displayHDS() {
      return this.app.hds_resource;
    },
    displayFlag() {
      if (!this.multiRegions) {
        return false;
      }
      return this.app.region !== defaultRegion.code;
    },
    routerArgs() {
      return {
        name: Routes.App.Overview,
        params: { region: this.app.region, id: this.app.name },
      };
    },
  },
});
</script>

<i18n>
en:
  hdsResource: "HDS application"
fr:
  hdsResource: "Application norme HDS"
</i18n>
