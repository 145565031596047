<template>
  <TextButton
    :styling="styling"
    :noShadow="true"
    :routeArgs="routeArgs"
    :isDisabled="isDisabled"
    :isLoading="isLoading"
    :title="title"
    @clicked="$emit('clicked')"
  >
    <font-awesome-icon :icon="icon" />
  </TextButton>
</template>

<script>
import { defineComponent } from "vue";

import TextButton from "./TextButton.vue";

const TYPE_DELETE = "delete";
const TYPE_HIGHLIGHT = "highlight";

export default defineComponent({
  name: "IconActionButton",
  components: { TextButton },
  props: {
    active: Boolean,
    noUnderLine: Boolean,
    routeArgs: Object,
    alternativeColor: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,
    icon: [String, Array],
    title: String,
    type: {
      type: String,
      default: "",
    },
  },
  emits: ["clicked"],
  computed: {
    styling: function () {
      let styling =
        " border border-opacity-50 border-scale-3 bg-scale-0 hover:bg-scale-3-25 group-hover:bg-scale-0";
      if (this.type === TYPE_DELETE) {
        styling += " hover:text-color-2 text-scale-7";
      }
      if (this.type === TYPE_HIGHLIGHT) {
        styling += " text-color-2";
      }
      if (this.type === "") {
        styling += " hover:text-scale-9 text-scale-7";
      }
      if (this.active) {
        styling += " text-primary-2";
      }

      return styling;
    },
  },
});
</script>
