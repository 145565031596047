<template>
  <svg
    class="h-6 w-6"
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9946 10.9999C16.1409 15.4181 12.6789 19.1185 8.26211 19.2649C3.84529 19.4112 0.146153 15.9481 -0.000154448 11.5299C-0.00786565 11.3941 0.0389163 11.2609 0.129803 11.1599C0.231353 11.0539 0.372964 10.9957 0.519674 10.9999H1.51934C1.7805 10.999 1.99829 11.1994 2.01918 11.4599C2.26859 14.7045 5.05818 17.1588 8.30705 16.992C11.5559 16.8251 14.0796 14.098 13.9956 10.8448C13.9115 7.59168 11.2504 4.99858 7.9972 4.99985V7.35985C7.99916 7.49544 7.94487 7.62578 7.84725 7.71985L7.64731 7.91985C7.55346 8.01451 7.42571 8.06775 7.29243 8.06775C7.15916 8.06775 7.0314 8.01451 6.93755 7.91985L3.39872 4.38985C3.207 4.19204 3.207 3.87767 3.39872 3.67985L6.93755 0.149852C7.0314 0.055196 7.15916 0.00195312 7.29243 0.00195312C7.42571 0.00195312 7.55346 0.055196 7.64731 0.149852L7.84725 0.349852C7.94487 0.443928 7.99916 0.574266 7.9972 0.709852V2.99985C12.414 2.99985 15.9946 6.58157 15.9946 10.9999Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RestartGlyph",
});
</script>
