import { User } from "scalingo/lib/models/auth";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updateAccount } from "@/store/user";

interface FormData {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export class ResetPasswordHandler extends FormHandler<User> {
  keyPath = "account.passwordChanged";

  data(): Nullable<FormData> {
    return {
      current_password: null,
      password: null,
      password_confirmation: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(event: FormData): Promise<void> {
    //@ts-expect-error scalingo.js types does not include password-related fields
    this.follow(await updateAccount(this.$store, event));
  }
}
