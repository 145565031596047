import { Point } from "scalingo/lib/models/regional";
import { QueryParams } from "scalingo/lib/params/regional/metrics";
import { unpackData } from "scalingo/lib/utils";

import ScalingoDBApi from "@/lib/scalingo/dbapi/client";
import { DbFeature } from "@/lib/scalingo/dbapi/database";

export type InstanceType =
  | "db-node"
  | "gateway"
  | "utility"; /** redis only (for sentinels) */

export type InstanceStatus =
  | "running"
  | "booting"
  | "restarting"
  | "migrating"
  | "upgrading"
  | "stopped"
  | "removing";

export type InstanceRole = "follower" | "leader";

export interface DbInstanceStatus {
  id: string;
  type: InstanceType;
  status: InstanceStatus;
  role: string;
  dbms_status: string;
}

export interface DbInstance {
  id: string;
  hostname: string;
  port: number;
  status: InstanceStatus;
  type: InstanceType;
  features: DbFeature[];
  private_ip: string;
}

export type InstanceMetrics =
  | "cpu"
  | "memory"
  | "swap"
  | "disk" /** Different response format! */
  | "diskio"; /** Also different response format! */

export type InstanceMetricsPoint = Point;

export type InstanceMetricsPointDisk = Pick<Point, "time"> & {
  disk_used: number;
  disk_total: number;
};

export type InstanceMetricsPointDiskIO = Pick<Point, "time"> & {
  diskio_reads: number;
  diskio_writes: number;
};

/**
 * DB Instances API Client
 */
export default class Instances {
  /** Scalingo DB API Client */
  _client: ScalingoDBApi;

  /**
   * Create a new "thematic" client
   * @param client Scalingo DB API Client
   */
  constructor(client: ScalingoDBApi) {
    this._client = client;
  }

  /**
   * Retrieve the statuse of each instance of the db
   * @see https://developers.scalingo.com/databases/status
   * @param dbId ID of the addon matching the database
   */
  status(dbId: string): Promise<DbInstanceStatus[]> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}/instances_status`),
    );
  }

  metrics(
    dbId: string,
    instanceId: string,
    metric: "disk",
    opts?: Pick<QueryParams, "since" | "last">,
  ): Promise<{ used: InstanceMetricsPoint[]; total: InstanceMetricsPoint[] }>;

  metrics(
    dbId: string,
    instanceId: string,
    metric: "diskio",
    opts?: Pick<QueryParams, "since" | "last">,
  ): Promise<{ reads: InstanceMetricsPoint[]; writes: InstanceMetricsPoint[] }>;

  metrics(
    dbId: string,
    instanceId: string,
    metric: Exclude<InstanceMetrics, "disk" | "diskio">,
    opts?: Pick<QueryParams, "since" | "last">,
  ): Promise<InstanceMetricsPoint[]>;

  /**
   * Fetch metrics for a database instance
   * @param dbId DB ID
   * @param instanceId DB instance ID
   * @param metric Which metric to get. One of the following: [cpu, swap, disk]
   * @param opts Optional params
   */
  metrics(
    dbId: string,
    instanceId: string,
    metric: InstanceMetrics,
    opts: Pick<QueryParams, "since" | "last"> = {},
  ) {
    const url = `/databases/${dbId}/instances/${instanceId}/metrics/${metric}`;

    const { since, last } = opts;
    const params: Record<string, string | number | boolean> = {};

    if (since !== undefined) params["since"] = since;
    if (last !== undefined) params["last"] = last;

    const promise = this._client.apiClient().get(url, { params: params });

    // The response structure changes depending on the metrics asked...
    // We're processing it to make it the same no matter what.
    if (metric === "disk") {
      return unpackData(promise, "disk_metrics").then(diskDatapoints);
    } else if (metric === "diskio") {
      return unpackData(promise, "diskio_metrics").then(diskIODatapoints);
    } else {
      return unpackData(promise);
    }
  }
}

function diskDatapoints(response: InstanceMetricsPointDisk[]) {
  const used: Point[] = [];
  const total: Point[] = [];

  response.forEach((p) => {
    used.push({ time: p.time, value: p.disk_used });
    total.push({ time: p.time, value: p.disk_total });
  });

  return { used, total };
}

function diskIODatapoints(response: InstanceMetricsPointDiskIO[]) {
  const reads: Point[] = [];
  const writes: Point[] = [];

  response.forEach((p) => {
    reads.push({ time: p.time, value: p.diskio_reads });
    writes.push({ time: p.time, value: p.diskio_writes });
  });

  return { reads, writes };
}
