export function formatAmount(cents: number, withCurrency = true): string {
  const amount = cents / 100;

  return formatEuroAmount(amount, withCurrency);
}

export function formatEuroAmount(euros: number, withCurrency = true): string {
  let result = euros.toFixed(2);

  if (withCurrency) result = `${result}€`;

  return result;
}
