import { DateTime } from "luxon";
import Qs from "qs";
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export default class Usage {
  client: Scalingo;

  /**
   * Create new Client
   * @param {Client} client - Scalingo API Client
   */
  constructor(client: Scalingo) {
    this.client = client;
  }

  /**
   * Return contacts for a given user
   */
  for(
    from?: DateTime,
    to?: DateTime,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    /**
     * We need to switch to UTC for this endpoint
     * and we need to target from the beginning of the month to the end of the last full day.
     */
    const today = DateTime.now();

    if (!from) {
      from = DateTime.now().toUTC().startOf("month");
    }

    if (!to) {
      if (today.day === 1) {
        to = today.endOf("day");
      } else {
        to = DateTime.now().toUTC().minus({ day: 1 }).endOf("day");
      }
    }

    const params = Qs.stringify(
      {
        from: from.toISO({ suppressMilliseconds: true }),
        to: to.toISO({ suppressMilliseconds: true }),
      },
      { addQueryPrefix: true },
    );

    return unpackData(
      this.client.billingApiClient().get(`profile/usage${params}`),
    );
  }
}
