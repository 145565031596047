export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a token"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want delete this token?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un token"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous vraiment supprimer ce token?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      }
    }
  })
}
