<template>
  <div class="mt-8">
    <DbCartridge
      v-if="withCartridge"
      :app="app"
      :db="db"
      :addon="null"
      :dbVersion="null"
    ></DbCartridge>
    <CreatingBanner v-if="db.status === 'creating'"></CreatingBanner>
    <UpdateBanner v-if="db.status === 'updating'"></UpdateBanner>
    <RestartingBanner v-if="db.status === 'restarting'"></RestartingBanner>
    <MigratingBanner v-if="db.status === 'migrating'"></MigratingBanner>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CreatingBanner from "@/components/organisms/banners/CreatingBanner.vue";
import MigratingBanner from "@/components/organisms/banners/MigratingBanner.vue";
import RestartingBanner from "@/components/organisms/banners/RestartingBanner.vue";
import UpdateBanner from "@/components/organisms/banners/UpdateBanner.vue";
import DbCartridge from "@/components/parts/db/dbOverview/DbCartridge.vue";

export default defineComponent({
  name: "DbNotRunning",
  components: {
    CreatingBanner,
    RestartingBanner,
    MigratingBanner,
    UpdateBanner,
    DbCartridge,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
    withCartridge: {
      type: Object,
      required: true,
    },
  },
  computed: {},
});
</script>
