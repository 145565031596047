export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
        },
        "actions": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])}
        }
      },
      "fr": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
        },
        "actions": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
          "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])}
        }
      }
    }
  })
}
