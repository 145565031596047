<template>
  <OnClickOutside class="z-40" @trigger="hide">
    <div class="relative">
      <input
        id="top-input"
        ref="input"
        v-shortkey="shortkey"
        autocomplete="off"
        class="form-input bg-scale-0 rounded block w-full pr-10 sm:text-sm sm:leading-5 outline-none border-scale-2 border placeholder-scale-6 px-3 py-1 focus:border-color-3 shadow-none special-height-width"
        :placeholder="
          $t(hasShortKey ? 'placeholder' : 'placeholder-alt', {
            shortcut: humanShortcut,
          })
        "
        :value="userQuery"
        @input="(e) => updateUserQuery(e.target.value)"
        @shortkey="toggle"
        @keydown.enter.prevent="onEnter"
        @keydown.down.prevent="onDown"
        @keydown.up.prevent="onUp"
        @keydown.esc.prevent="onEsc"
        @focus="show"
      />
      <div
        class="invisible pv:visible absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          width="13"
          height="12"
          viewBox="0 0 13 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.75 8.30362L1.08334 10.9735C0.964246 11.106 0.970115 11.3087 1.09667 11.4341L1.56334 11.9013C1.62593 11.9645 1.71112 12 1.8 12C1.88888 12 1.97408 11.9645 2.03667 11.9013L4.70334 9.2314C4.77709 9.1575 4.84403 9.07707 4.90334 8.99111L5.40334 8.32364C6.23063 8.98506 7.25797 9.34518 8.31667 9.34487C10.4983 9.35245 12.394 7.84573 12.8815 5.71674C13.369 3.58774 12.3183 1.40466 10.3513 0.459942C8.38427 -0.484772 6.02602 0.0610084 4.67267 1.77417C3.31932 3.48732 3.33205 5.91064 4.70334 7.60945L4.03667 8.07C3.93256 8.13672 3.83638 8.21509 3.75 8.30362ZM11.65 4.67255C11.65 6.51572 10.1576 8.0099 8.31668 8.0099C7.43262 8.0099 6.58478 7.65829 5.95966 7.03241C5.33453 6.40654 4.98335 5.55767 4.98335 4.67255C4.98335 2.82938 6.47573 1.3352 8.31668 1.3352C10.1576 1.3352 11.65 2.82938 11.65 4.67255Z"
            fill="#7B8794"
          />
        </svg>
      </div>
      <transition
        :css="animateDropdown"
        enterActiveClass="ease-out duration-300 transition"
        enterClass="opacity-0"
        enterToClass="opacity-100"
        leaveActiveClass="transition ease-in duration-100"
        leaveClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <div
          v-if="active"
          class="z-45 border border-scale-2 absolute mt-2 pv:left-1/2 pv:transform pv:-translate-x-1/2 rounded-md bg-scale-0 shadow-lg mt-2 search-items-width"
        >
          <ul
            tabindex="-1"
            role="listbox"
            class="max-h-full rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
          >
            <TopInputItem
              v-for="(proposition, key) in propositions"
              :key="key"
              :selected="key === selectionIndex"
              :appInfo="proposition"
              :userQuery="userQuery"
              @selected="select(proposition)"
            ></TopInputItem>
            <li
              v-if="propositionsCount"
              class="text-scale-5 cursor-default select-none relative py-1 pl-3 pr-9"
            >
              <router-link
                :to="resultsRoute"
                class="text-xs text-scale-5 block truncate underline"
              >
                {{ $t("showing") }} 5 / {{ propositionsCount }}
                {{ $t("matches") }}
              </router-link>
            </li>
            <li
              v-if="
                (userQuery && propositions.length === 0) ||
                allPropositions.length === 0
              "
              class="text-scale-5 cursor-default select-none relative py-1 pl-3 pr-9"
            >
              <span class="text-scale-5">
                {{ $t("noMatch") }}
              </span>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";
import { defineComponent } from "vue";

import TopInputItem from "@/components/atoms/specializedInputs/TopInputItem.vue";
import {
  kbHumanShortcut,
  kbShortcut,
  noKeyboardShortCut,
} from "@/lib/utils/platform";
import { Routes } from "@/router/names";

const MAX = 5;

export default defineComponent({
  name: "TopInput",
  components: { OnClickOutside, TopInputItem },
  props: {
    appInfos: Object,
    openWhenMounted: {
      type: Boolean,
      default: false,
    },
    animateDropdown: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["selected", "updated"],
  data() {
    return {
      userQuery: "",
      selectionIndex: 0,
      active: false,
      shortkey: kbShortcut("k"),
      humanShortcut: kbHumanShortcut("k"),
    };
  },
  computed: {
    propositions() {
      return this.allPropositions.slice(0, MAX);
    },
    propositionsCount() {
      if (this.allPropositions.length > MAX) {
        return this.allPropositions.length;
      }
      return 0;
    },
    allPropositions() {
      if (this.appInfos.none) return [];
      if (this.userQuery === "") return this.appInfos.items;

      return this.appInfos.items.filter((app) =>
        app.name.includes(this.userQuery),
      );
    },
    hasShortKey() {
      if (noKeyboardShortCut()) {
        return false;
      }
      // Accepting that screens with width < 420px are mobile emulator
      return screen.width >= 420;
    },
    resultsRoute() {
      const route = { name: Routes.Projects };

      if (this.propositionsCount > 0 && this.userQuery) {
        route.query = {
          appName: this.userQuery,
        };
      }

      return route;
    },
  },
  watch: {
    propositions: function () {
      this.selectionIndex = 0;
    },
  },
  beforeMount() {
    if (this.$route.query?.appName) {
      this.updateUserQuery(this.$route.query.appName);
    }
  },
  mounted() {
    if (this.openWhenMounted) {
      this.show();
    }
  },
  methods: {
    updateUserQuery(value, emit = true) {
      this.userQuery = value;
      if (emit) this.$emit("updated", value);
    },
    select(proposition) {
      this.updateUserQuery("", false);
      this.$emit("selected", proposition);
      this.active = false;
      this.$refs.input.blur();
    },
    toggle() {
      this.$refs.input.focus();
    },
    hide() {
      this.active = false;
    },
    show() {
      this.active = true;
    },
    onEnter() {
      if (this.propositions.length < 1) {
        return;
      }
      this.select(this.propositions[this.selectionIndex]);
    },
    onDown() {
      this.selectionIndex++;
      if (this.selectionIndex >= this.propositions.length) {
        this.selectionIndex = 0;
      }
    },
    onUp() {
      this.selectionIndex--;
      if (this.selectionIndex < 0) {
        this.selectionIndex = this.propositions.length - 1;
      }
    },
    onEsc() {
      this.userQuery = "";
      this.active = false;
      this.$refs.input.blur();
    },
  },
});
</script>

<style scoped>
/* Only way to get exactly 32px height instead of 28px */
.special-height-width {
  height: 32px;
  width: 600px;
}

@media screen and (max-width: 1024px) {
  .special-height-width {
    height: 32px;
    width: 455px;
  }
}

@media screen and (max-width: 800px) {
  .special-height-width {
    height: 32px;
    width: 355px;
  }
}

@media screen and (max-width: 640px) {
  .special-height-width {
    height: 32px;
    width: 255px;
  }
}

.search-items-width {
  width: 600px;
}
@media screen and (max-width: 800px) {
  .search-items-width {
    width: 500px;
  }
}
@media screen and (max-width: 640px) {
  .search-items-width {
    width: 255px;
  }
}
</style>

<i18n>
en:
  placeholder: "Search what you need ({shortcut})"
  placeholder-alt: "Search what you need"
  showing: Showing
  matches: matches
  noMatch: "No match"
fr:
  placeholder: "Cherchez vos applications ({shortcut})"
  placeholder-alt: "Cherchez vos applications"
  showing: Visibilité de
  matches: résultats
  noMatch: "Pas de résultat"
</i18n>
