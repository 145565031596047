import { App, Notifier } from "scalingo/lib/models/regional";
import {
  CreateParams,
  UpdateParams,
} from "scalingo/lib/params/regional/notifiers";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  CREATE,
  DESTROY,
  FETCH,
  HANDLE_FETCH,
  HANDLE_OPERATION,
  REFRESH,
  UPDATE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ADD, DELETE, SET_ALL, SET_ONE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export class NotifiersStore extends CollectionStore<Notifier> {
  actions = CollectionStore.buildActions<Notifier>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Notifiers.for(
          currentApp.id,
        ),
        resolveAction: SET_ALL,
      });
    },
    [FETCH](context, id) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Notifiers.get(
          currentApp.id,
          id,
        ),
        resolveAction: ADD,
      });
    },
    [CREATE](context, payload) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Notifiers.create(
          currentApp.id,
          payload,
        ),
        resolveAction: ADD,
      });
    },
    [UPDATE](context, { id, payload }) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Notifiers.update(
          currentApp.id,
          id,
          payload,
        ),
        resolveAction: SET_ONE,
      });
    },
    [DESTROY](context, notifier) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Notifiers.destroy(
          currentApp.id,
          notifier.id,
        ),
        resolveAction: () => context.commit(DELETE, notifier),
      });
    },
  });
  mutations = CollectionStore.buildMutations<Notifier>();
  getters = CollectionStore.buildGetters<Notifier>();
}

export const Notifiers = buildMapping(new NotifiersStore(), "notifiers");

export function listNotifiers(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Notifier>>,
): CollectionWithFetch<Notifier> {
  return {
    items: listItems(store.getters[Notifiers.getters.ALL], opts),
    latestFetch: store.getters[Notifiers.getters.LATEST_FETCH],
  };
}

export function ensureNotifiers(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(Notifiers.actions.ENSURE, opts);
}

export function ensureNotifier(
  store: ApplicationStore,
  id: string,
): Promise<Notifier> {
  return store.dispatch(Notifiers.actions.ENSURE_ONE, id);
}

export function findNotifier(store: ApplicationStore, id: string): Notifier {
  return store.getters[Notifiers.getters.FIND](id);
}

export function createNotifier(
  store: ApplicationStore,
  payload: CreateParams,
): Promise<RemoteOperation<Notifier>> {
  return store.dispatch(Notifiers.actions.CREATE, payload);
}

export function updateNotifier(
  store: ApplicationStore,
  notifier: Notifier,
  payload: UpdateParams,
): Promise<RemoteOperation<Notifier>> {
  return store.dispatch(Notifiers.actions.UPDATE, { id: notifier.id, payload });
}

export function destroyNotifier(
  store: ApplicationStore,
  notifier: Notifier,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Notifiers.actions.DESTROY, notifier);
}
