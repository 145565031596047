<template>
  <div class="pr-2 self-center">
    <div
      :class="styling"
      class="h-3 w-3 rounded-full"
      :title="$t('app.statuses.' + status)"
    ></div>
  </div>
</template>

<script>
import {
  STATUS_RUNNING,
  STATUS_CRASHED,
  STATUS_RESTARTING,
  STATUS_SCALING,
  STATUS_BOOTING,
} from "scalingo/lib/models/regional/apps";
import { defineComponent } from "vue";

const ACTIVE = [STATUS_RUNNING];
const WARNING = [STATUS_CRASHED];
const ONGOING = [STATUS_RESTARTING, STATUS_SCALING, STATUS_BOOTING];

export default defineComponent({
  name: "AppStatusCircle",
  props: {
    status: String,
  },
  computed: {
    styling() {
      if (ACTIVE.includes(this.status)) {
        return "bg-color-1";
      } else if (WARNING.includes(this.status)) {
        return "bg-color-2";
      } else if (ONGOING.includes(this.status)) {
        return "bg-primary-2";
      } else {
        return "bg-pending";
      }
    },
  },
});
</script>
