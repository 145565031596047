<template>
  <StepCard
    :step="1"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-3xl"
  >
    <InfosForm
      class="mt-6"
      :formHandler="formHandler"
      :acceptContractHandler="acceptContractHandler"
      :createPointOfContactHandler="createPointOfContactHandler"
      :regions="regions"
      :canEnableHDS="true"
      :hdsPointOfContact="hdsPointOfContact"
      :hdsContract="hdsContract"
      :hdsContractPrerequisite="hdsContractPrerequisite"
      :hdsLoadingOperation="hdsLoadingOperation"
      :user="user"
      @formSubmitted="(e) => $emit('formSubmitted', e)"
      @toggleHDSMode="(e) => $emit('toggleHDSMode', e)"
      @startPointOfContactCreation="
        (e) => $emit('startPointOfContactCreation', e)
      "
      @cancelPointOfContactCreation="
        (e) => $emit('cancelPointOfContactCreation', e)
      "
      @submitPointOfContactCreation="
        (e) => $emit('submitPointOfContactCreation', e)
      "
      @startContractAcceptance="(e) => $emit('startContractAcceptance', e)"
      @cancelContractAcceptance="(e) => $emit('cancelContractAcceptance', e)"
      @submitContractAcceptance="(e) => $emit('submitContractAcceptance', e)"
    />
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import StepCard from "@/components/molecules/card/StepCard.vue";
import InfosForm from "@/components/parts/create/app/InfosForm.vue";

export default defineComponent({
  name: "AppInfosStepCard",
  components: { StepCard, InfosForm },
  props: {
    user: Object,
    enabled: Boolean,
    hdsPointOfContact: Object,
    hdsContract: Object,
    hdsContractPrerequisite: Object,
    hdsLoadingOperation: Object,
    formHandler: Object,
    acceptContractHandler: Object,
    createPointOfContactHandler: Object,
    regions: Array,
  },
  emits: [
    "cancelContractAcceptance",
    "cancelPointOfContactCreation",
    "formSubmitted",
    "startContractAcceptance",
    "startPointOfContactCreation",
    "submitContractAcceptance",
    "submitPointOfContactCreation",
    "toggleHDSMode",
  ],
});
</script>

<i18n>
en:
  title: "Basic information"
  subtitle: "Fill the minimal information required to create an app"
fr:
  title: "Information de base"
  subtitle: "Saisissez les information miminal pour la création d'une app"
</i18n>
