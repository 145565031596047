import { AppAddonsRoutes } from "@/router/app/addons/names";
import { AppDbRoutes } from "@/router/app/db/names";

export const AppRoutes = {
  Root: "App",
  Overview: "AppRoot",
  Resources: "AppResources",
  Activity: {
    Root: "AppActivityRoot",
    All: "AppActivityAll",
    Show: "AppActivityShow",
  },
  Logs: {
    Root: "AppLogs",
    Live: "AppLogsLive",
    Archives: "AppLogsArchives",
  },
  Metrics: {
    Root: "AppMetricsRoot",
    Overview: "AppMetricsOverview",
    PerContainer: "AppMetricsPerContainer",
    ApexOverview: "AppApexMetricsOverview",
    ApexPerContainer: "AppApexMetricsPerContainer",
  },
  Deploy: {
    Root: "AppDeployRoot",
    List: {
      Root: "AppDeployListRoot",
      All: "AppDeployListAll",
      Show: "AppDeployListShow",
    },
    Configuration: "AppDeployConfiguration",
    ConfigurationGithub: "AppDeployConfigurationGithub",
    ConfigurationGitlab: "AppDeployConfigurationGitlab",
    Manual: "AppDeployManual",
  },
  ReviewApps: {
    Root: "AppDeployReviewAppsRoot",
    Configuration: "AppDeployReviewAppsConfiguration",
    List: "AppDeployReviewAppsList",
    Manual: "AppDeployReviewAppsManual",
  },
  Settings: "AppSettings",
  Environment: "AppEnvironment",
  Collaborators: "AppCollaborators",
  Domains: {
    Root: "AppDomainsRoot",
    Index: "AppDomainsIndex",
    Show: "AppDomainsShow",
  },
  Notifiers: {
    Root: "AppNotifiersRoot",
    List: "AppNotifiersList",
    Show: "AppNotifiersShow",
    Creation: "AppNotifierCreation",
    Edit: {
      Infos: "AppNotifierEditInfos",
      Events: "AppNotifierEditEvents",
    },
  },
  Alerts: {
    Root: "AppAlertsRoot",
    List: "AppAlertsList",
    Creation: "AppAlertsCreation",
    Edit: {
      Triggers: "AppAlertsEditTriggers",
      Notifiers: "AppAlertsEditNotifiers",
    },
  },
  Routing: "AppRouting",
  Management: "AppManagement",
  Addon: AppAddonsRoutes,
  Db: AppDbRoutes,
  NotFoundError: "AppNotFoundError",
};
