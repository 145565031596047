export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate authority"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download database TLS certificate."])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate authority is already included in the certificate pool of your application's containers. You only need this certificate if you need to access your database from a remote server or your development environment."])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorité de certification"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le certificat TLS de la base de données."])},
        "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'autorité de certification est déjà incluse dans le pool de certificats des conteneurs de votre application. Vous n'avez besoin de ce certificat que si vous souhaitez accéder à votre base de données depuis un serveur distant ou votre environnement de développement."])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])}
      }
    }
  })
}
