<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader
      :title="$t('title')"
      :subtitle="subtitle"
      :withDelimiter="!!billingProfile"
    >
      <template v-if="user.confirmed" #buttons>
        <SCButton @click="editBillingProfile">
          {{ buttonName }}
        </SCButton>
      </template>
    </CardHeader>
    <template v-if="billingProfile">
      <p class="mt-2 text-scale-7">
        {{ $t("address.disclaimer") }}
      </p>

      <p class="mt-2 font-bold">
        {{ name }}
        <br />
        {{ address }}
      </p>

      <h4 class="mt-4 text-lg font-medium">
        {{ $t("email.title") }}
      </h4>

      <p class="mt-2 text-scale-7">
        {{ $t("email.disclaimer") }}
      </p>

      <div class="mt-2">
        <template v-if="billingProfile.email">
          <a :href="mailto" class="text-primary-2">
            {{ email }}
          </a>
        </template>
        <template v-else>
          {{ $t("email.none") }} :
          <a :href="mailto" class="text-primary-2">
            {{ email }}
          </a>
        </template>
      </div>

      <div v-if="billingProfile.invoice_additional_info" class="mt-4">
        <h4 class="text-lg font-medium">
          {{ $ta("billingProfile", "invoiceAdditionalInfo") }}
        </h4>

        <div class="mt-2 text-scale-7">
          {{ $t("invoiceAdditionalInfo") }} :
          <span
            class="ml-1 font-mono bg-scale-1 px-2 py-1 pv:whitespace-nowrap"
            >{{ billingProfile.invoice_additional_info }}</span
          >
        </div>
      </div>

      <h4 class="mt-4 text-lg font-medium">
        {{ $t("tax.title") }}
      </h4>

      <p class="mt-2 text-scale-7">
        <span>{{ $t("tax.disclaimer") }}</span>
      </p>

      <ul class="list-disc ml-4 text-scale-7">
        <li>{{ $t("tax.point1") }}</li>
        <li>{{ $t("tax.point2") }}</li>
        <li>{{ $t("tax.point3") }}</li>
        <li>{{ $t("tax.point4") }}</li>
      </ul>

      <p class="mt-2">
        <template v-if="billingProfile.vat_number">
          {{ $t("tax.label") }}
          <code class="text-sm">{{ billingProfile.vat_number }}</code>
        </template>
        <template v-else>
          {{ $t("tax.none") }}
        </template>
      </p>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import countries from "@/lib/utils/countries.ts";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "BillingCard",
  components: {
    CardHeader,
    SCButton,
  },
  mixins: [ModelsTranslation],
  props: {
    user: Object,
    billingProfile: Object,
  },
  emits: ["editBillingProfile"],
  data: function () {
    return {};
  },
  computed: {
    subtitle() {
      if (this.billingProfile) {
        return this.$t("created.subtitle");
      } else if (this.user.confirmed) {
        return this.$t("newRecord.subtitle");
      } else {
        return this.$t("needConfirmation.subtitle");
      }
    },
    buttonName() {
      return this.billingProfile
        ? this.$t("created.buttonName")
        : this.$t("newRecord.buttonName");
    },
    name() {
      const name = this.billingProfile.name || this.user.fullname;
      const { company } = this.billingProfile;

      return company ? `${name} - ${company}` : name;
    },

    address() {
      const {
        address_line1,
        address_line2,
        address_zip,
        address_city,
        address_state,
        address_country,
      } = this.billingProfile;

      const city = `${address_zip} ${address_city}`;
      let toDisplay = [
        address_line1,
        address_line2,
        city,
        address_state,
        this.countryNameForCode(address_country),
      ].filter((v) => !!v);

      return toDisplay.join(", ");
    },
    email() {
      return this.billingProfile.email || this.user.email;
    },
    mailto() {
      return `mailto:${this.email}`;
    },
  },
  methods: {
    countryNameForCode(code) {
      return countries.getName(code, this.$i18n.locale);
    },
    editBillingProfile(eventArgs) {
      this.$emit("editBillingProfile", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Billing profile"
  created:
    subtitle: "The info below will be used for invoicing"
    buttonName: "Edit these billing informations"
  newRecord:
    subtitle: "You have not yet added any billing informations. Click the button to add one."
    buttonName: "Add billing informations"
  needConfirmation:
    subtitle: "You need to confirm your account before creating a billing profile."
  address:
    disclaimer: "This address will appear on your monthly invoices and should be the legal address of your home or company."
  email:
    title: "Billing email"
    disclaimer: "You have the possibility to defined a billing email. Invoices will be sent to this email. If none is defined, your account email will be used."
    none: "No address defined, using the account's one"
  tax:
    title: "Tax information"
    disclaimer: "Scalingo is a French company, thus:"
    point1: "Non European companies are exempted of tax"
    point2: "European companies won't be charged VAT if they enter their European Tax ID"
    point3: "European individuals will be charged the VAT of their home country"
    point4: "French companies and individuals will be charged the French VAT"
    label: "Tax ID :"
    none: "No tax ID has been supplied."
  invoiceAdditionalInfo: "This text will be added in a dedicated field on each future invoice"
fr:
  title: "Profil de facturation"
  created:
    subtitle: "Les informations ci-dessous seront utilisées pour la facturation"
    buttonName: "Modifier les informations de facturation"
  newRecord:
    subtitle: "Vous n'avez pas encore ajouté d'informations de facturation. Cliquez sur le bouton pour les remplir."
    buttonName: "Enregistrer les informations de facturation"
  needConfirmation:
    subtitle: "Pour créer un profil de facturation, vous devez d'abord confirmer votre compte."
  address:
    disclaimer: "Cette adresse apparaîtra sur vos factures mensuelles et doit être l'adresse légale de votre domicile ou de votre société."
  email:
    title: "Email de facturation"
    disclaimer: "Vous avez la possibilité de définir un email de facturation. Les factures seront envoyées à cette adresse. Si aucune adresse n'est définie, l'email de votre compte sera utilisé."
    none: "Si aucune adresse n'est définie, celle du compte sera utilisée."
  tax:
    title: "Informations sur les taxes"
    disclaimer: "Scalingo est une société française, donc :"
    point1: "Les entreprises non européennes sont exemptées de taxe"
    point2: "Les entreprises européennes ne seront pas facturées de la TVA si elles indiquent leur numéro d'identification fiscale européen"
    point3: "Les particuliers européens se verront appliquer la TVA de leur pays d'origine."
    label: "Tax ID :"
    point4: "Les entreprises et les particuliers français se verront appliquer la TVA française."
    none: "Aucun identifiant fiscal n'a été fourni."
  invoiceAdditionalInfo: "Ce texte sera rajouté dans un champ dédié sur chaque facture future"
</i18n>
