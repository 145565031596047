<template>
  <div :class="classFn" class="h-3 w-3 rounded-full"></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThreeStatesIndicator",
  props: {
    isOk: {
      type: Boolean,
      default: false,
    },
    isKo: {
      type: Boolean,
      default: false,
    },
    isNo: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    classFn() {
      let classString;
      if (this.isOk) {
        classString += " bg-color-1";
      }
      if (this.isKo) {
        classString += " bg-color-2";
      }
      if (this.isNo) {
        classString += " bg-scale-5";
      }
      classString += " h-" + this.size + " " + "w-" + this.size;
      return classString;
    },
  },
});
</script>
