import { every, sortBy } from "lodash";
import { DateTime } from "luxon";
import Client from "scalingo";
import { Point as APIPoint } from "scalingo/lib/models/regional";
import { Event } from "scalingo/lib/models/regional";
import { STATUS_SUCCESS } from "scalingo/lib/models/regional/deployments";

import {
  ContainerMetricsOpts,
  cpuGraphConfig,
  Dataset,
  DatasetOptions,
  eventsConfig,
  getSeriesOptions,
  GraphConfig,
  memoryGraphConfig,
  MetricsOpts,
  requestsGraphConfig,
  routerGraphConfig,
} from "./config";

import type { VueI18n } from "vue-i18n";

type ChartPoint = [number, number];

/**
 * The dataset needs to be smoothed to handled null/0/incomplete data points
 */
function smoothDatapoints(
  points: APIPoint[],
  removeUnder?: number,
): APIPoint[] {
  // Sometimes we have very low values that are just noise and can considered 0
  // (for instance, a little bit of swap). We smooth those over.
  const smoothed = removeUnder
    ? points.map((p) => (p.value < removeUnder ? { ...p, value: 0 } : p))
    : points;

  return smoothed.map((point, i) => {
    // First and last points : continue with next/previous data point if possible
    if (i === 0) {
      if (!point.value) {
        return {
          ...point,
          value: points[i + 1].value || 0,
        };
      }
    }

    // Finish
    if (i === points.length - 1) {
      if (!point.value) {
        return {
          ...point,
          value: points[points.length - 2].value || 0,
        };
      }
    }

    // In between : take mean (rounded up) of previous + next point if possible, 0 otherwise
    if (point.value === null) {
      const previous = points[i - 1].value;
      const next = points[i + 1].value;
      const value = previous && next ? (previous + next) / 2.0 : 0;

      return {
        ...point,
        value: Math.ceil(value),
      };
    }

    return point;
  });
}

/**
 * Takes a data point from the Scalingo API
 * and converts it to a point suitable for ApexCharts
 */
function formatDataPoint(point: APIPoint): ChartPoint {
  const x = DateTime.fromISO(point.time).toMillis();
  const y = point.value;

  return [x, y];
}

/**
 * Takes an API response, smoothes the dataset, and make it ApexCharts-suitable
 */
function formatDataPoints(
  points: APIPoint[],
  removeUnder?: number,
): ChartPoint[] {
  return smoothDatapoints(points, removeUnder).map(formatDataPoint);
}

/**
 * Translate any property looking like an i18n key
 * Note: for now, only the series name
 */
function translateKeys(
  i18n: VueI18n,
  config: DatasetOptions,
  variant: "short" | "full" = "short",
): void {
  if (config.nameKey) {
    config.name = i18n.t(`metrics.${config.nameKey}.${variant}`).toString();
    delete config.nameKey;
  }
}

/**
 * Takes points coming from the Scalingo API,
 * returns an ApexChart ready series.
 */
export function generateDataSet(
  i18n: VueI18n,
  points: APIPoint[],
  config: GraphConfig,
): Dataset {
  const serie = getSeriesOptions(config.metric, config.subset);
  translateKeys(i18n, serie);

  serie.data = formatDataPoints(points, config.removeUnder);

  return serie;
}

/**
 * This function returns the time between the points of a dataset
 * */
export function intervalBetweenPoints(datapoints: APIPoint[]): number | null {
  const first = datapoints[0];
  const second = datapoints[1];

  if (first && second) {
    const diff = DateTime.fromISO(second.time).diff(
      DateTime.fromISO(first.time),
    );

    return diff.as("milliseconds");
  }

  return null;
}

type GraphFetcherFn = (c: GraphConfig) => Promise<APIPoint[]>;
type GraphDatasetConfig = {
  datasets: Dataset[];
  interval: number | null;
};

/**
 * This function fetch all the requested metrics,
 * then merges the results:
 * - if no dataset is available, rejects the promise
 * - if at least one dataset is available :
 *   - format all available datasets
 *   - use an empty one for the other data
 * It also extracts the interval between points.
 */
function processMetricsResponses(
  configs: GraphConfig[],
  fn: GraphFetcherFn,
  i18n: VueI18n,
): Promise<GraphDatasetConfig> {
  const promises = configs.map(fn);

  return Promise.allSettled(promises).then((promises) => {
    // All promises rejected? Then return the first error,
    // they're probably all the same
    if (every(promises, { status: "rejected" })) {
      const reason = (promises[0] as PromiseRejectedResult).reason;
      return Promise.reject(reason);
    }

    // All datasets should have the same interval
    const interval = intervalBetweenPoints(
      (promises[0] as PromiseFulfilledResult<APIPoint[]>).value,
    );

    const datasets = promises.map((pr, i) => {
      // Empty dataset if there's no response
      if (pr.status === "rejected") return [];

      return generateDataSet(i18n, pr.value, configs[i]);
    });

    return Promise.resolve({ datasets, interval });
  });
}

export function routerDatasets(
  client: Client,
  i18n: VueI18n,
  opts: MetricsOpts,
): Promise<unknown> {
  return processMetricsResponses(
    routerGraphConfig(opts),
    (c) => client.Metrics.get(opts.app.id, "router", c.opts),
    i18n,
  );
}

export function responseTimeDatasets(
  client: Client,
  i18n: VueI18n,
  opts: MetricsOpts,
): Promise<unknown> {
  return processMetricsResponses(
    requestsGraphConfig(opts),
    (c) => client.Metrics.get(opts.app.id, "requests", c.opts),
    i18n,
  );
}

export function cpuDatasets(
  client: Client,
  i18n: VueI18n,
  opts: ContainerMetricsOpts,
): Promise<unknown> {
  return processMetricsResponses(
    cpuGraphConfig(opts),
    (c) => client.Metrics.get(opts.app.id, c.subset, c.opts),
    i18n,
  );
}

export function memoryDatasets(
  client: Client,
  i18n: VueI18n,
  opts: ContainerMetricsOpts,
): Promise<unknown> {
  return processMetricsResponses(
    memoryGraphConfig(opts),
    (c) => client.Metrics.get(opts.app.id, c.subset, c.opts),
    i18n,
  );
}

export function eventsAnnotations(
  client: Client,
  i18n: VueI18n,
  opts: MetricsOpts,
): Promise<unknown> {
  const promise = client.Events.for(opts.app.id, { from: opts.since });

  return promise
    .then(
      (response) => onlyEventsOfType(response.events, []),
      () => Promise.resolve([]),
    )
    .then((events) => processEvents(events))
    .then((events) => sortBy(events, "x"));
}

const graphableEventTypesSimple = [
  "crash",
  "repeated_crash",
  "scale",
  "restart",
];

function onlyEventsOfType(events: Event[], types: string[]): Event[] {
  if (types.length === 0) {
    types = [...graphableEventTypesSimple, "deployment"];
  }

  return events.filter((e) => {
    if (!types.includes(e.type)) {
      return false;
    } else if (graphableEventTypesSimple.includes(e.type)) {
      return true;
    } else if (e.type === "deployment") {
      return e.type_data?.status === STATUS_SUCCESS;
    } else {
      return false;
    }
  });
}

function processEvents(events: Event[]) {
  return events.map((event) => {
    const date = event.type_data?.finished_at || event.created_at;
    const x = DateTime.fromISO(date).toMillis();
    const config = eventsConfig[event.type];

    return {
      x,
      borderColor: config.color,
      label: {
        borderColor: config.color,
        text: event.type,
      },
    };
  });
}
