<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7805 0.48779L19.5122 1.21965C19.8241 1.53128 19.9995 1.95407 19.9999 2.39506V3.23781C20.0055 3.68648 19.8296 4.11835 19.5122 4.4354L17.4613 6.45356C17.3572 6.55852 17.2155 6.61756 17.0678 6.61756C16.92 6.61756 16.7783 6.55852 16.6742 6.45356L13.5591 3.29326C13.3478 3.07767 13.3478 2.73263 13.5591 2.51704L15.5656 0.48779C15.8826 0.17028 16.3144 -0.00567006 16.7629 -0.000116556H17.6054C18.0463 0.000271667 18.469 0.175768 18.7805 0.48779ZM15.355 8.53867C15.5663 8.32308 15.5663 7.97805 15.355 7.76246L12.1845 4.63542C12.0804 4.53045 11.9388 4.47141 11.791 4.47141C11.6432 4.47141 11.5015 4.53045 11.3974 4.63542L2.45121 13.5841C2.23493 13.7985 2.06511 14.0552 1.95236 14.3381L0.0456063 19.1506C-0.0624064 19.4005 0.0268383 19.6918 0.256236 19.8382C0.413349 19.9951 0.648909 20.0431 0.854866 19.9601L5.66608 18.0085C5.94895 17.8957 6.20556 17.7259 6.41991 17.5095L15.355 8.53867Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGIconPen",
});
</script>
