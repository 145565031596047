<template>
  <Card>
    <template #header>
      <div class="flex">
        <div class="flex-grow">
          <div class="flex items-center">
            <img class="hidden h-16 w-16 rounded-full sm:block" :src="avatar" />

            <div>
              <div class="flex items-center">
                <img class="h-16 w-16 rounded-full sm:hidden" :src="avatar" />
                <h1
                  class="ml-6 text-xl font-bold leading-7 text-scale-9 sm:leading-9 sm:truncate"
                >
                  {{ user.fullname }}
                </h1>
              </div>
              <h3 class="ml-6 text-scale-8">
                {{ user.username }}
              </h3>
              <dl
                class="mt-6 flex flex-col text-sm sm:ml-6 sm:mt-1 sm:flex-row sm:flex-wrap"
              >
                <template v-if="user.company">
                  <dt class="sr-only">{{ $ta("account", "company") }}</dt>
                  <dd class="flex items-center text-scale-6 sm:mr-6">
                    <font-awesome-icon icon="building" />
                    <span class="ml-2">{{ user.company }}</span>
                  </dd>
                </template>
                <template v-if="user.location">
                  <dt class="sr-only">{{ $ta("account", "location") }}</dt>
                  <dd class="flex items-center text-scale-6 sm:mr-6">
                    <font-awesome-icon icon="map-marked" />
                    <span class="ml-2">{{ user.location }}</span>
                  </dd>
                </template>
                <dt class="sr-only">{{ $ta("account", "location") }}</dt>
                <dd class="flex items-center text-scale-6 sm:mr-6">
                  <font-awesome-icon icon="newspaper" />
                  <span class="ml-2">
                    <template v-if="user.email_newsletter">
                      {{ $t("newsletter.yes") }}
                    </template>
                    <template v-else>
                      {{ $t("newsletter.no") }}
                    </template>
                  </span>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="flex">
          <SCButton @click="$emit('startAccountEdition')">
            {{ $t("action") }}
          </SCButton>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import { userAvatar } from "@/lib/utils/avatars";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "InformationsCard",
  components: {
    Card,
    SCButton,
  },
  mixins: [ModelsTranslation],
  props: {
    user: Object,
  },
  emits: ["startAccountEdition"],
  data() {
    return {};
  },
  computed: {
    avatar() {
      return userAvatar(this.user);
    },
  },
});
</script>

<i18n>
en:
  action: "Edit profile"
  newsletter:
    yes: "Subscribed to Scalingo's newsletter"
    no: "Not subscribed to Scalingo's newsletter"
fr:
  action: "Modifier le profil"
  newsletter:
    yes: "Abonné(e) à la newsletter Scalingo"
    no: "Non abonné(e) à la newsletter Scalingo"
</i18n>
