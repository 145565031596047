<template>
  <div class="flex items-center space-x-2">
    <font-awesome-icon
      size="2x"
      :icon="hasAccess ? 'eye' : 'eye-slash'"
      class="h-5 w-5 text-color-1"
      :class="{
        'text-color-1': hasAccess,
        'text-scale-6': !hasAccess,
      }"
    />
    <div>
      {{ name }}
    </div>
    <div v-if="hasAccess" class="text-scale-6">
      {{ $t("until", { date: formattedUntil }) }}
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "DataAccessConsentItem",
  props: {
    name: String,
    hasAccess: Boolean,
    until: String,
  },
  computed: {
    formattedUntil() {
      return formatDateTime(this.until);
    },
  },
});
</script>

<i18n>
en:
  until: "until {date}"
fr:
  until: "jusqu'au {date}"
</i18n>
