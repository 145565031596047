import LogsListener from "scalingo/lib/Logs/listener";
import { ArchivesResult } from "scalingo/lib/models/regional";
import { IndexParams } from "scalingo/lib/params/regional/logs";
import { unpackData } from "scalingo/lib/utils";

import ScalingoDBApi from "@/lib/scalingo/dbapi/client";

/**
 * DB Logs API Client
 */
export default class Logs {
  /** Scalingo DB API Client */
  _client: ScalingoDBApi;

  /**
   * Create a new "thematic" client
   * @param client Scalingo DB API Client
   */
  constructor(client: ScalingoDBApi) {
    this._client = client;
  }

  /**
   * Retrieve a signed log url that will allow access to the actual logs
   * @see https://developers.scalingo.com/databases/logs
   * @param dbId ID of the addon matching the database
   */
  signedUrlFor(dbId: string): Promise<string> {
    return unpackData(
      this._client.apiClient().get(`/databases/${dbId}/logs`),
      "url",
    );
  }

  /**
   * Retrieve logs archives for a database
   * @param dbId ID of the database
   * @return Promise that when resolved returns a list of logs archives for this DB
   */
  archivesFor(dbId: string, cursor?: string | number): Promise<ArchivesResult> {
    const params: Record<string, unknown> = {};

    if (cursor !== null && cursor !== undefined) params.cursor = cursor;

    return unpackData(
      this._client
        .apiClient()
        .get(`/databases/${dbId}/logs_archives`, { params }),
    );
  }

  /**
   * Retrieve logs for the database
   * @see https://developers.scalingo.com/databases/logs
   * @param dbId ID of the addon matching the database
   */
  async retrieveFor(dbId: string, opts?: IndexParams): Promise<string> {
    let url = await this.signedUrlFor(dbId);
    url = `${url}&stream=false`;

    if (opts && opts.count) {
      url = `${url}&n=${opts.count}`;
    }

    return unpackData(this._client.unauthenticatedClient().get(url));
  }

  /**
   * Stream logs for the database
   * @see https://developers.scalingo.com/databases/logs
   * @param dbId ID of the addon matching the database
   */
  async listenerFor(dbId: string): Promise<LogsListener> {
    let url = await this.signedUrlFor(dbId);
    url = `${url}&stream=true`;

    // @ts-expect-error the first arg is useless in the code
    return new LogsListener(null, url);
  }
}
