export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Stack"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Stack is the curated and maintained Linux environment in which your applications are deployed and run."])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings for future deployments:"])},
        "heading2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base image used in last successful deployment:"])},
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Scalingo Stack"])},
        "based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Stack image based on"])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
        "privateStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Scalingo Stack in use is only available for the app owner."])},
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A newer version of the base image is available"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to use the most recent image"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger a new deployment"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Stack"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Stack est l'environnement Linux conçu et maintenu, dans lequel vos applications sont déployées et exécutées."])},
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres pour les prochains déploiements:"])},
        "heading2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de base utilisée lors du dernier déploiement réussi:"])},
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la Scalingo Stack"])},
        "based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Stack Docker basée sur"])},
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
        "privateStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Scalingo Stack utilisée n'est disponible que pour le propriétaire de l'app."])},
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une version plus récente de l'image de base est disponible"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour utiliser l'image la plus récente"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencher un nouveau déploiement"])}
        }
      }
    }
  })
}
