export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of users who have access to this database."])},
        "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a user"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs disposant d'un accès à cette base de données."])},
        "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])}
      }
    }
  })
}
