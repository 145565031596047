<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9114 16.0273L10.7365 0.336364C10.6122 0.128373 10.3879 0.000735182 10.1455 0H9.8727C9.63031 0.000735182 9.40603 0.128373 9.28165 0.336364L0.088563 16.0273C-0.029521 16.2424 -0.029521 16.503 0.088563 16.7182L0.215866 16.9273C0.333704 17.143 0.561116 17.2759 0.806915 17.2727H19.1931C19.4389 17.2759 19.6663 17.143 19.7841 16.9273L19.9114 16.7182C20.0295 16.503 20.0295 16.2424 19.9114 16.0273ZM10.9089 13.1815C10.9089 13.4325 10.7054 13.6359 10.4543 13.6359H9.54525C9.29421 13.6359 9.09071 13.4325 9.09071 13.1815V12.2726C9.09071 12.0217 9.29421 11.8182 9.54525 11.8182H10.4543C10.7054 11.8182 10.9089 12.0217 10.9089 12.2726V13.1815ZM10.4816 9.99914C10.5972 9.99997 10.695 9.91392 10.7089 9.79919L11.0725 6.87266C11.0882 6.74301 11.0473 6.61288 10.9603 6.51546C10.8733 6.41805 10.7486 6.36276 10.618 6.3637H9.38162C9.251 6.36276 9.12628 6.41805 9.03928 6.51546C8.95228 6.61288 8.91141 6.74301 8.92708 6.87266L9.29071 9.79919C9.30458 9.91392 9.40239 9.99997 9.51798 9.99914H10.4816Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGIconWarningTriangle",
});
</script>
