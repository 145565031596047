<template>
  <DeleteModal
    :withInfo="true"
    :confirmationValue="app.name"
    :formHandler="formHandler"
    @formSubmitted="$emit('formSubmitted', { app })"
    @modalClosed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>

    <template #info>
      {{ $t("info") }}
      <span class="font-medium">{{ app.name }}</span>
    </template>

    <template #question>
      {{ $t("question") }}
    </template>

    <template #button>
      {{ $t("button") }}
      <span class="ml-1">{{ app.name }}</span>
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "RemoveAppModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
  computed: {
    app() {
      return this.formHandler.app;
    },
  },
});
</script>

<i18n>
en:
  title: "Delete an app"
  question: "Do you really want delete this app?"
  info: "To confirm, type the name of the app below:"
  button: "Delete"
fr:
  title: "Supprimer une app"
  info: "Pour confirmer, saisissez le nom de l'application ci-dessous"
  question: "Souhaitez-vous vraiment supprimer cette app ?"
  button: "Supprimer"
</i18n>
