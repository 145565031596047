<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3333 0C7.204 0 4.66667 2.53733 4.66667 5.66667C4.66667 6.36153 4.79843 7.02414 5.02734 7.63932L0 12.6667V16H3.33333V14H5.33333V12H7.33333L8.36068 10.9727C8.97586 11.2016 9.63847 11.3333 10.3333 11.3333C13.4627 11.3333 16 8.796 16 5.66667C16 2.53733 13.4627 0 10.3333 0ZM11.6667 2.66667C12.5873 2.66667 13.3333 3.41267 13.3333 4.33333C13.3333 5.254 12.5873 6 11.6667 6C10.746 6 10 5.254 10 4.33333C10 3.41267 10.746 2.66667 11.6667 2.66667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "KeyGlyph",
});
</script>
