import { Domain } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { editDomainSSL, fetchDomain } from "@/store/domains";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  tlscert: string;
  tlskey: string;
}

export class EditDomainSSLHandler extends FormHandler<Domain> {
  keyPath = "domains.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly domain: Domain,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      tlscert: null,
      tlskey: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", (domain) => {
      this.notify("success");
      this.resetFormData();

      const callback = () => fetchDomain(this.$store, domain.id);

      // The response from `editDomainSSL` when removing the certificate is a domain in an error state.
      // However, our letsencrypt integration quickly changes the state to a pending one.
      // We're therefore scheduling two refreshes to avoid displaying stale data
      setTimeout(callback, 400);
      setTimeout(callback, 2000);
    });
  }

  async submit(event: FormData): Promise<void> {
    this.follow(
      await editDomainSSL(
        this.$store,
        this.domain,
        event.tlscert,
        event.tlskey,
      ),
    );
  }
}
