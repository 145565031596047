import { scalingoPrivateClient } from "@/lib/scalingo/client";
import {
  Invoice,
  IndexQuery as InvoicesQuery,
  IndexResponse as InvoicesList,
} from "@/lib/scalingo/invoices";
import {
  REFRESH,
  HANDLE_FETCH,
  FETCH_MORE,
  FETCH,
  HANDLE_OPERATION,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_META, SET_ALL, MERGE, ADD } from "@/lib/store/mutation-types";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class InvoicesStore extends CollectionStore<Invoice> {
  actions = CollectionStore.buildActions<Invoice>({
    [REFRESH](context, params) {
      const opts: InvoicesQuery = {
        owner_id: params.ownerId,
      };

      if (params.perPage) {
        opts.per_page = params.perPage;
      }

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).Invoices.all(opts),
        resolveAction: function (resolved: InvoicesList) {
          context.commit(SET_ALL, resolved.invoices);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH_MORE](context, params) {
      if (!context.state.meta.pagination?.next_page) {
        return;
      }

      const opts: InvoicesQuery = {
        owner_id: params.ownerId,
        page: context.state.meta.pagination.next_page,
      };

      if (params.perPage) {
        opts.per_page = params.perPage;
      }

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).Invoices.all(opts),
        resolveAction: function (resolved: InvoicesList) {
          context.commit(MERGE, resolved.invoices);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH](context, id) {
      context.dispatch(HANDLE_OPERATION, {
        promise: scalingoPrivateClient(context).Invoices.find(id),
        resolveAction: ADD,
      });
    },
  });
  mutations = CollectionStore.buildMutations<Invoice>();
  getters = CollectionStore.buildGetters<Invoice>();
}

export const Invoices = buildMapping(new InvoicesStore(), "invoices");

export function listInvoices(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Invoice>>,
): CollectionWithFetch<Invoice> {
  return {
    items: listItems(store.getters[Invoices.getters.ALL], opts),
    latestFetch: store.getters[Invoices.getters.LATEST_FETCH],
    meta: store.getters[Invoices.getters.META],
  };
}

export function ensureInvoices(
  store: ApplicationStore,
  ownerId: string,
  perPage: number,
): void {
  store.dispatch(Invoices.actions.ENSURE, { payload: { ownerId, perPage } });
}

export function ensureInvoice(
  store: ApplicationStore,
  id: string,
): Promise<Invoice> {
  return store.dispatch(Invoices.actions.ENSURE_ONE, id);
}

export function fetchMoreInvoices(
  store: ApplicationStore,
  ownerId: string,
  perPage: number,
): void {
  store.dispatch(Invoices.actions.FETCH_MORE, { ownerId, perPage });
}

export function findInvoice(store: ApplicationStore, id: string): Invoice {
  return store.getters[Invoices.getters.FIND](id);
}
