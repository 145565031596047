export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs / Archives"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs app archives, downloadable in gzip format"])},
        "viewArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View archives"])},
        "backToLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Logs"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No logs archive yet"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs / Archives"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs archivés de votre application"])},
        "viewArchives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir archives"])},
        "backToLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir aux Logs"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun archive de logs"])}
      }
    }
  })
}
