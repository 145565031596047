<template>
  <StepCard
    :step="3"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <div class="py-4">
      <div
        v-for="option in options"
        :key="`option-${option.value}`"
        class="flex flex-col rounded border border-padding cursor-pointer mb-2"
        @click="(e) => selectOption(option, e)"
      >
        <div class="flex p-4">
          <CheckboxInputAtom
            boxType="round"
            :modelValue="isOptionSelected(option.value)"
          ></CheckboxInputAtom>

          <div>{{ option.label }}</div>
        </div>
      </div>

      <section
        v-if="form && form.eventOption === 'selectedEvents'"
        class="mt-4 border-t-2 border-scale-2"
      >
        <div class="my-4 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6">
          <div
            v-for="type in eventTypes.items"
            :key="`type-${type.name}`"
            class="flex flex-col rounded border border-padding cursor-pointer mb-2"
            @click="toggleEventType(type)"
          >
            <div class="flex p-4">
              <CheckboxInputAtom :modelValue="isEventTypeSelected(type)" />

              <div>{{ type.name }}</div>
            </div>
          </div>
        </div>
      </section>

      <div class="flex">
        <SCButton
          size="lg"
          kind="primary"
          class="flex-grow"
          :block="true"
          :loading="isSubmitting"
          @click="submit"
        >
          {{ $t("action") }}
        </SCButton>
      </div>
    </div>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";

export default defineComponent({
  name: "NotifierEventsCard",
  components: {
    StepCard,
    SCButton,
    CheckboxInputAtom,
  },
  props: {
    enabled: Boolean,
    formHandler: Object,
    notifier: Object,
    eventTypes: Object,
  },
  emits: ["submit"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    isSubmitting() {
      return this.formHandler?.isSubmitting || false;
    },
    options() {
      return (this.formHandler?.eventOptions() || []).map((value) => {
        return { value, label: this.$t(`options.${value}`) };
      });
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);
      },
    },
  },
  methods: {
    isOptionSelected(option) {
      return option === this.form.eventOption;
    },
    selectOption(option, val) {
      this.form.eventOption = val ? option.value : null;
    },
    isEventTypeSelected(type) {
      return this.form.selectedEventIds.includes(type.id);
    },
    toggleEventType(type) {
      if (this.isEventTypeSelected(type)) {
        const index = this.form.selectedEventIds.indexOf(type.id);
        if (index > -1) {
          this.form.selectedEventIds.splice(index, 1);
        }
      } else {
        this.form.selectedEventIds.push(type.id);
      }
    },

    submit() {
      this.$emit("submit", this.form);
    },
  },
});
</script>

<style scoped>
.border-padding {
  padding: 1px;
}
</style>

<i18n>
  en:
    title: "Trigger events"
    subtitle: "Set the events on which this notifier reacts"
    action: "Finish"
    options:
      all: "Send me everything"
      deployedEvent: "Just the deployment event"
      selectedEvents: "Let me select individual events:"
  fr:
    title: "Évènements déclencheurs"
    subtitle: "Configurez les évènements pour lesquels ce notifieur réagit"
    action: "Finir"
    options:
      all: "Tout envoyer"
      deployedEvent: "Juste l'évènement de déploiement"
      selectedEvents: "Choisir les évènements parmis ceux disponibles:"
</i18n>
