import { Stack } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { REFRESH, HANDLE_FETCH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  CollectionWithFetch,
  EnsureOptions,
  listItems,
  ListItemsOptions,
} from "@/lib/store/utils";
import { ApplicationStore } from "@/store";

export class StacksStore extends CollectionStore<Stack> {
  actions = CollectionStore.buildActions<Stack>({
    [REFRESH](context, payload = {}) {
      const { regionCode } = payload;
      const promise = scalingoClient(context, regionCode).Stacks.list();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ALL,
      });
    },
  });

  mutations = CollectionStore.buildMutations<Stack>();
  getters = CollectionStore.buildGetters<Stack>();
}

export const Stacks = buildMapping(new StacksStore(), "stacks");

export function listStacks(
  store: ApplicationStore,
  regionCode: string,
  opts?: Partial<ListItemsOptions<Stack>>,
): CollectionWithFetch<Stack> {
  const itemsGetter = `${regionCode}/${Stacks.getters.ALL}`;
  const fetchGetter = `${regionCode}/${Stacks.getters.LATEST_FETCH}`;

  return {
    items: listItems(store.getters[itemsGetter], opts),
    latestFetch: store.getters[fetchGetter],
  };
}

export function ensureStacks(
  store: ApplicationStore,
  regionCode: string,
  opts: EnsureOptions = {},
): void {
  opts.payload = opts.payload || {};
  opts.payload.regionCode = regionCode;

  store.dispatch(`${regionCode}/${Stacks.actions.ENSURE}`, opts);
}
