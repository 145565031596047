export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Advisor"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights and recommendations to optimize this database operations."])},
        "warnings": {
          "swap": {
            "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Swap level is high (", _interpolate(_named("humanised")), "). Please check your memory and swap consumption."])},
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Swap level is very high (", _interpolate(_named("humanised")), "). This could affect your database performance."])}
          },
          "cpu": {
            "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CPU level is high (", _interpolate(_named("value")), "%). This could affect your database performance."])},
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["CPU level is very high (", _interpolate(_named("value")), "%). This could affect your database performance."])}
          },
          "memory": {
            "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Memory level is high (", _interpolate(_named("humanised")), "). This could affect your database performance."])},
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Memory level is very high (", _interpolate(_named("humanised")), "). This could affect your database performance."])}
          },
          "connections": {
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The number of available connections is close to the plan limit (", _interpolate(_named("humanised")), "). If connections are frequently refused, you may want to consider upgrading to a higher plan."])}
          },
          "disk": {
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prolonged usage exceeding plan storage may incur additional fees."])}
          }
        },
        "buttons": {
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change plan"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View metrics"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo Advisor"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils et recommandations pour optimiser le fonctionnement de cette base de donnée."])},
        "warnings": {
          "swap": {
            "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau de swap est élevé (", _interpolate(_named("humanised")), "). Veuillez vérifier votre consommation de mémoire et de swap."])},
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau de swap est très élevé (", _interpolate(_named("humanised")), "). Cela pourrait altérer les performances de votre base de données."])}
          },
          "cpu": {
            "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau de CPU est élevé (", _interpolate(_named("value")), "%). Cela pourrait altérer les performances de votre base de données."])},
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau de CPU est très élevé (", _interpolate(_named("value")), "%). Cela pourrait altérer les performances de votre base de données."])}
          },
          "memory": {
            "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau de mémoire est élevé (", _interpolate(_named("humanised")), "%). Cela pourrait altérer les performances de votre base de données."])},
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le niveau de mémoire est très élevé (", _interpolate(_named("humanised")), "%). Cela pourrait altérer les performances de votre base de données."])}
          },
          "connections": {
            "ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le nombre de connexions disponibles est proche de la limite du plan (", _interpolate(_named("humanised")), "). Si des connexions sont régulièrement refusées, vous pouvez envisager de passer à un plan supérieur."])}
          },
          "disk": {
            "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’usage prolongé d’un stockage supérieur au plan peut entraîner des frais supplémentaires."])}
          }
        },
        "buttons": {
          "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de plan"])},
          "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les métriques"])}
        }
      }
    }
  })
}
