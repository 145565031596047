export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make canonical"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The redirection to the canonical domain will be made with a HTTP 301 status code."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendre canonique"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La redirection au domaine canonique se fera via un code HTTP 301."])}
      }
    }
  })
}
