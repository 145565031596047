export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler created"])},
        "autoscaler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler about"])},
        "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on container"])},
        "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min containers"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max containers"])},
        "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler créé"])},
        "autoscaler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler à propos de"])},
        "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur le container"])},
        "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min containers"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max containers"])},
        "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])}
      }
    }
  })
}
