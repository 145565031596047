<template>
  <div class="flex justify-end">
    <SCTableAction
      :rowIndex="index"
      class="ml-2"
      :title="titleForDomain(item)"
      :highlight="highlightForDomain(item)"
      :to="routeForDomain(item)"
    >
      <SVGIconPen class="h-4 w-4" />
    </SCTableAction>

    <SCTableAction
      v-if="item.canonical === false"
      :rowIndex="index"
      class="ml-2"
      :title="$t('title.star')"
      @click="$emit('startMakeCanonical', { domain: item })"
    >
      <font-awesome-icon icon="star" class="h-4 w-4" />
    </SCTableAction>

    <SCTableAction
      v-if="item.canonical === true"
      :rowIndex="index"
      class="ml-2"
      :title="$t('title.unstar')"
      @click="$emit('startUnmakeCanonical', { domain: item })"
    >
      <font-awesome-icon :icon="['far', 'star']" class="h-4 w-4" />
    </SCTableAction>

    <SCTableAction
      :rowIndex="index"
      class="ml-2"
      type="delete"
      :title="$t('title.delete')"
      @click="$emit('deleteDomainClicked', { domain: item })"
    >
      <BinGlyph class="h-4 w-4" />
    </SCTableAction>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import SVGIconPen from "@/components/graphics/icons/Pen.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DomainActions",
  components: {
    SVGIconPen,
    BinGlyph,
    SCTableAction,
  },
  props: {
    item: Object,
    index: Number,
  },
  emits: ["deleteDomainClicked", "startMakeCanonical", "startUnmakeCanonical"],
  methods: {
    titleForDomain(domain) {
      if (domain._requireManualAction) return this.$t("title.manual");
      if (domain._detailedSSLStatus === "error") return this.$t("title.error");

      return this.$t("title.info");
    },
    highlightForDomain(domain) {
      if (domain._requireManualAction) return "info";
      if (domain._detailedSSLStatus === "error") return "warning";

      return;
    },
    routeForDomain(domain) {
      return {
        name: Routes.App.Domains.Show,
        params: {
          domainId: domain.id,
        },
      };
    },
  },
});
</script>

<i18n>
en:
  title:
    info: "Display the domain, upload a custom certificate"
    manual: "You need to perform some actions to finish the SSL certificate's generation"
    error: "The SSL certificate generation went wrong"
    star: "Make the domain canonical"
    unstar: "Remove the domain canonicity"
    delete: "Delete this domain"
    help: "Open help"
fr:
  title:
    info: "Voir le nom de domaine, uploader un certificat"
    manual: "Il y a des actions a faire pour finir la génération du certificat SSL"
    error: "La génération du certificat SSL est en erreur"
    star: "Rendre ce domaine canonique"
    unstar: "Supprimer la canonicité du domaine"
    delete: "Supprimer le domaine"
    help: "Ouvrir l'aide"
</i18n>
