export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration updated"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The configuration has been successfully updated."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration update failure"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The configuration update failed."])}
        }
      },
      "fr": {
        "success": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration mise à jour"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été mise à jour avec succès."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mise à jour de la configuration a échoué."])}
        }
      }
    }
  })
}
