<template>
  <SCAlert kind="warning">
    <div>
      {{ $t("message") }}
    </div>
  </SCAlert>
</template>

<script>
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";

export default defineComponent({
  name: "SandboxWarning",
  components: { SCAlert },
});
</script>

<i18n>
en:
  message: Sandbox plans have no backup or support, and their technical characteristics will only allow very limited workloads.
fr:
  message: Les plans sandbox ne disposent ni de sauvegarde, ni de support et les caractéristiques techniques ne supporteront que des charges de travail très limitées.
</i18n>
