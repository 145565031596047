export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencing"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences used during the application conception"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences utilisées durant la conception de l'application"])}
      }
    }
  })
}
