<template>
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    @click="(e) => $emit('click', e)"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6666 3.58335V2.75002C13.6666 2.5199 13.4801 2.33335 13.25 2.33335H9.49998V1.50002C9.49998 1.03978 9.12688 0.666687 8.66665 0.666687H5.33331C4.87308 0.666687 4.49998 1.03978 4.49998 1.50002V2.33335H0.74998C0.519861 2.33335 0.333313 2.5199 0.333313 2.75002V3.58335C0.333313 3.81347 0.519861 4.00002 0.74998 4.00002H13.25C13.4801 4.00002 13.6666 3.81347 13.6666 3.58335ZM3.55831 17.3334C2.68154 17.3355 1.95302 16.658 1.89165 15.7834L1.16665 5.66669H12.8333L12.125 15.7834C12.0636 16.658 11.3351 17.3355 10.4583 17.3334H3.55831Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BinGlyph",
  emits: ["click"],
});
</script>
