import countries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import frCountries from "i18n-iso-countries/langs/fr.json";

countries.registerLocale(enCountries);
countries.registerLocale(frCountries);

export default countries;

export function europeanForVat(countryCode: string): boolean {
  const code = countryCode.toUpperCase();

  return EU_MEMBER_STATES.includes(code) || DROM_STATES.includes(code);
}

// https://github.com/Scalingo/project-items/issues/127
export const DROM_STATES = ["RE", "GF", "MQ", "GP", "YT"];

// Obtained from ruby: `Valvat::Utils::EU_MEMBER_STATES`. No need to add a lib just for an array.
export const EU_MEMBER_STATES = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
];
