export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment attempted"])},
        "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method:"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount:"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State:"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentative de paiement"])},
        "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode :"])},
        "montant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant :"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status "])}
      }
    }
  })
}
