export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Median, 95th percentile, 99th percentile."])},
        "noMetric1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No request to the app during the selected period"])},
        "noMetric2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hence no metric to display"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médian, 95ème percentile, 99ème percentile."])},
        "noMetric1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête à l'app sur la période sélectionnée"])},
        "noMetric2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par conséquence aucune métrique à aficher"])}
      }
    }
  })
}
