/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil, omitBy } from "lodash";
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export interface SetupIntent {
  /** ID of the setup intent */
  id: string;
  /** Client Secret to be re-used */
  client_secret: string;
}

export default class SetupIntents {
  client: Scalingo;

  constructor(client: Scalingo) {
    this.client = client;
  }

  /**
   * Create a new setup intent
   * @param {String} profile_id - ID of the billing profile
   * @return {Promise<SetupIntent | APIError>} The newly created setup intent
   */
  create(object_type?: string): Promise<SetupIntent> {
    const payload = omitBy({ object_type }, isNil);

    return unpackData(
      this.client.billingApiClient().post("/setup_intents", payload),
      "setup_intent",
    );
  }
}
