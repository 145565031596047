export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the minimal information required to create an app"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de base"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les information miminal pour la création d'une app"])}
      }
    }
  })
}
