<template>
  <ViewComponent :app="app" />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/addonsCreation/Root.vue";
import { ensureAddonProviders } from "@/store/addon-providers";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "AddonCreationRootContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  created() {
    ensureAddonProviders(this.$store, this.app.region);
    useAppAddonsStore().ensure();
  },
});
</script>
