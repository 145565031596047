import { APIError } from "scalingo/lib/errors";
import { Container } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { App } from "@/lib/scalingo/modifiers/apps";
import { scaleContainers } from "@/store/containers";

import type { ComponentPublicInstance } from "vue";

export class ScaleContainersHandler extends ActionHandler<Container> {
  get keyPath(): string {
    const error = this.operation?.error as APIError;

    if (this.isError && error?.status === 422) {
      const { errors } = error.data;

      if ((errors?.app || []).includes("is restarting")) {
        return "containers.restart.restarting";
      }

      if ((errors?.app || []).includes("is scaling")) {
        return "containers.scale.scaling";
      }
    }

    if (this.isError && error?.status === 402) {
      return "containers.scale.free-trial";
    }

    return "containers.scale.generic";
  }

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly formation: Container[],
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("failure", () => {
      this.notify("error", {
        owner: this.app.owner.username,
      });
    });
  }

  async submit(): Promise<void> {
    this.follow(await scaleContainers(this.$store, this.formation));
  }
}
