export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon removed!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon was removed"])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not remove addon."])}
          }
        },
        "resume": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon resumed!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon was successfuly resumed."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Could not resume addon: ", _interpolate(_named("message"))])}
          }
        }
      },
      "fr": {
        "delete": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon supprimé!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'addon a été supprimé."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'addon n'a pas pu être supprimé."])}
          }
        },
        "resume": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon relancé!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'addon a été relancé avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'addon n'a pas pu être relancé: ", _interpolate(_named("message"))])}
          }
        }
      }
    }
  })
}
