export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer app ownership"])},
        "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the new owner"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer ownership"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to transfer this app? Once transferred, the new owner will be responsible for the app, its data and the payment of its consumption."])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before taking any action, please read the following section of our documentation carefully"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Ownership of an Application"])},
        "hds_warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to transfer an HDS app"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new owner must have accepted the HDH appendix and have an up-to-date point of contact."])}
        },
        "helpers": {
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Confirm by typing “", _interpolate(_named("name")), "”"])}
        },
        "labels": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New owner"])},
          "currentAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the app"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer la propriété de l'application"])},
        "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez à qui transférer l'application"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer la propriété"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir transférer cette application ? Une fois transférée, le ou la nouvelle propriétaire sera responsable de l'application, de ses données et du paiement de sa consommation."])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant toute action, veuillez lire attentivement la section suivante de notre documentation"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert de propriété d'une application"])},
        "hds_warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de transférer une application HDS"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau propriétaire doit avoir accepté l'annexe HDS et disposer d'un point de contact à jour."])}
        },
        "helpers": {
          "name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Confirmez en saisissant “", _interpolate(_named("name")), "”"])}
        },
        "labels": {
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau propriétaire"])},
          "currentAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’application"])}
        }
      }
    }
  })
}
