export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
        },
        "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The default database cannot be deleted. It can only be emptied."])},
        "actions": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
          "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop"])}
        }
      },
      "fr": {
        "headers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
        },
        "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données par défaut ne peut pas être supprimée. Elle peut seulement être vidée."])},
        "actions": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider"])},
          "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
        }
      }
    }
  })
}
