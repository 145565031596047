import { User } from "scalingo/lib/models/auth";

import { ActionHandler } from "@/lib/handlers/action";
import { stopFreeTrial } from "@/store/user";

import type { ComponentPublicInstance } from "vue";

export class DeleteFreeTrialHandler extends ActionHandler<User> {
  keyPath = "user.stopFreeTrial";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly user: User,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(await stopFreeTrial(this.$store));
  }
}
