import { noop } from "lodash";
import { computed, markRaw, Raw, reactive, ref, unref } from "vue";

import type { ComputedRef, Ref, UnwrapNestedRefs } from "vue";

/**
 * Subset of RemoteOperation, focused on letting vue track a promise's advancement.
 * Meant to be used when Vue can't properly follow RemoteOperations.
 */
export type RawPromiseInfo<T> = {
  /** Resolved value of the promise */
  data: Ref<T | null | undefined>;
  /** Rejection value of the promise */
  err: Ref<unknown | null | undefined>;
  /** Wether the operation represented by the promise is still ongoing */
  isLoading: Ref<boolean>;
  /** Wether the operation represented by the promise was a success */
  isSuccess: Ref<boolean>;
  /** Wether the operation represented by the promise is finished */
  isFinished: ComputedRef<boolean>;
  /** Wether the operation represented by the promise ended up in error */
  isError: ComputedRef<boolean>;
  /** The underlying promise. Not meant to be observed by Vue. */
  promise: Raw<Promise<T>>;
};

/**
 * Reactive subset of RemoteOperation, focused on letting vue track a promise's advancement.
 * Meant to be used when Vue can't properly follow RemoteOperations.
 */
export type PromiseInfo<T> = UnwrapNestedRefs<RawPromiseInfo<T>>;

/** Builds and returns a reactive promise information object */
export function promiseInfo<T>(promise: Promise<T>): PromiseInfo<T> {
  const data: RawPromiseInfo<T>["data"] = ref(undefined);
  const err: RawPromiseInfo<T>["err"] = ref(undefined);
  const isLoading: RawPromiseInfo<T>["isLoading"] = ref(true);
  const isSuccess: RawPromiseInfo<T>["isSuccess"] = ref(false);

  const isFinished: RawPromiseInfo<T>["isFinished"] = computed(() => {
    return !unref(isLoading) || false;
  });

  const isError: RawPromiseInfo<T>["isError"] = computed(() => {
    return unref(isFinished) ? !unref(isSuccess) : false;
  });

  const rawInfo: RawPromiseInfo<T> = {
    data,
    err,
    isLoading,
    isFinished,
    isSuccess,
    isError,
    promise: markRaw(promise),
  };

  const info: PromiseInfo<T> = reactive(rawInfo);

  /** Start the operation with the supplied promise */
  promise
    .then(
      (r) => {
        info.data = r;
        info.err = null;
        info.isSuccess = true;
      },
      (e) => {
        info.data = null;
        info.err = e;
        info.isSuccess = false;
      },
    )
    .finally(() => (info.isLoading = false));

  return info;
}

/**
 * Observes a promise and catch rejections.
 */
export function observePromise<T = unknown>(promise: Promise<T>) {
  const info = promiseInfo(promise);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noOp = () => {};

  promise.then(noOp, noOp);

  return info;
}

/**
 * Returns a void promise info: never starting, never settling.
 * Useful for tests, placeholders, and initialisation.
 */
export function voidPromiseInfo<T = unknown>(): PromiseInfo<T> {
  return {
    data: null,
    err: null,
    isError: false,
    isSuccess: false,
    isFinished: false,
    isLoading: false,
    promise: markRaw(new Promise<T>(noop)),
  };
}

/**
 * Returns a pending promise info: always loading, never settling.
 * Useful for tests, placeholders, and initialisation.
 */
export function pendingPromiseInfo<T = unknown>() {
  return promiseInfo(
    new Promise<T>(() => {
      //no-op
    }),
  );
}

/**
 * Returns an always successful promiseInfo.
 * Useful for tests, placeholders, and initialisation.
 */
export function successPromiseInfo(): PromiseInfo<undefined>;
export function successPromiseInfo<T = unknown>(data: T): PromiseInfo<T>;
export function successPromiseInfo<T = unknown>(data?: T) {
  return promiseInfo(Promise.resolve(data));
}

/**
 * Returns an always erroring promiseInfo.
 * Useful for tests, placeholders, and initialisation.
 */
export function errorPromiseInfo(err?: unknown) {
  return promiseInfo(Promise.resolve(err));
}
