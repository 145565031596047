<template>
  <div>
    <h3 class="text-lg">
      <span class="font-bold">{{ title }}</span>
      <span v-if="withCount" class="text-scale-4 pl-1">({{ count }})</span>
    </h3>
    <div
      v-if="subtitle && subtitle.length > 0"
      class="hidden ph:flex text-scale-7"
    >
      {{ preparedSubtitle }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardTitle",
  props: {
    title: String,
    subtitle: String,
    withCount: Boolean,
    count: Number,
  },
  computed: {
    // Temporary, the time we migrate all subtitles to "end with dot" policy
    preparedSubtitle() {
      if (this.subtitle.endsWith(".")) {
        return this.subtitle;
      }
      return this.subtitle + ".";
    },
  },
});
</script>
