import { isArray } from "lodash";
import { DateTime } from "luxon";
import { computed, ref } from "vue";

import {
  promiseInfo as promiseInfoFn,
  successPromiseInfo,
  voidPromiseInfo,
} from "@/lib/promises/info";
import type { PromiseInfo } from "@/lib/promises/info";
import { dashboard } from "@/lib/utils/log";

import type { ComputedRef, Ref } from "vue";

//======== Utilities ========//
/** The arguments to pass to .ensure */
export type EnsureOptions = {
  /** Trigger a fretch even if data is already present. "always", or a number of seconds */
  staleAfter?: "always" | number | null | undefined;
};

/** The options needed for shouldFetch */
type ShouldFetchOpts<DataType = unknown> = {
  data: DataType;
  lastFetchedAt: DateTime | null;
};

/** Wether the data should be (re)fetched */
function shouldFetch<DataType = unknown>(
  { data, lastFetchedAt }: ShouldFetchOpts<DataType>,
  staleAfter: "always" | number | null | undefined,
): boolean {
  let performFetch = false;

  if (staleAfter === "always") {
    dashboard.debug("pinia: always (re)fetching");

    performFetch = true;
  } else if (!data) {
    dashboard.debug("pinia: no values, fetching");

    performFetch = true;
  } else if (!lastFetchedAt) {
    dashboard.debug("pinia: no last fetch at, fetching");

    performFetch = true;
  } else if (staleAfter) {
    const threshold = lastFetchedAt.plus({
      seconds: staleAfter,
    });

    dashboard.debug("pinia: stale data, refetching");

    performFetch = threshold < DateTime.local();
  } else {
    dashboard.debug("pinia: not fetching");
  }

  return performFetch;
}

//======== Members types ========//
type FetchFn<DataType> = () => Promise<DataType | void>;
type EnsureFn<DataType> = (opts?: EnsureOptions) => Promise<DataType | void>;
type ClearFn = () => void;

export type UpdateDataFn<DataType> = (
  promise: Promise<DataType>,
) => PromiseInfo<DataType>;

export type DestroyDataFn = (promise: Promise<void>) => PromiseInfo<void>;

//======== Options types ========//
/** The required options for a data store */
export type DataStoreOptions<DataType> = {
  fetchPromise: () => Promise<DataType>;
};

//======== Store types ========//
/** The public interface of the store, without the main getter. Not used as such, but extended by other types */
export type DataStore<DataType> = {
  promise: Ref<Promise<DataType> | null>;
  /** A promise info representing the state of the current fetch operation */
  promiseInfo: ComputedRef<PromiseInfo<DataType>>;
  /** A promise info representing the state of the first fetch operation */
  initialPromiseInfo: ComputedRef<PromiseInfo<DataType>>;
  /** When this store was last fetched */
  lastFetchedAt: Ref<DateTime | null>;
  /** Fetch the source of data for this store */
  fetch: FetchFn<DataType>;
  /** Fetch (if relevant) the source of data for this store */
  ensure: EnsureFn<DataType>;
  /** Clears any data in the store. */
  clearData: ClearFn;
};

/** The private interface of the store. Extra items are internal helpers, used to define other stores */
export type FullDataStore<DataType> = DataStore<DataType> & {
  /** The data contained in this store */
  data: Ref<DataType | null | undefined>;
  /** Replaces the data in the store */
  updateData: UpdateDataFn<DataType>;
  /** Removes the data from the store */
  destroyData: DestroyDataFn;
};

/** Returns an data store */
export function useDataStore<DataType>(
  options: DataStoreOptions<DataType>,
): FullDataStore<DataType> {
  const data: Ref<DataType | null | undefined> = ref(undefined);
  const promise = ref<Promise<DataType> | null>(null);
  const lastFetchedAt = ref<DateTime | null>(null);

  /**
   * Updates the lastFetchedAt.
   * Internal only.
   */
  const _setFetchedAt = function <T>(promise: Promise<T>) {
    return promise.then((resolved) => {
      lastFetchedAt.value = DateTime.local();
      return resolved;
    });
  };

  /**
   * Promise handler for replacing the store data.
   * Internal only.
   */
  const _updateData = function (updatePromise: Promise<DataType>) {
    promise.value = updatePromise;

    return _setFetchedAt(updatePromise).then(
      (newData: DataType) => (data.value = newData),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
    );
  };

  /** The usual way of handling promises in the context of a store */
  const updateData: UpdateDataFn<DataType> = function (promise) {
    const info = promiseInfoFn(promise);

    // This happens in the background and updates the store
    _updateData(promise);

    // While we return a promise info for observing the operation
    return info;
  };

  /** Destroy the data on the remote API, then updates the store */
  const destroyData: DestroyDataFn = function (promise) {
    const info = promiseInfoFn(promise);

    // This happens in the background and updates the store
    // @ts-expect-error wrong typing for _updateData. Can be fixed later, it does not prevent anything from working.
    _updateData(promise);

    // While we return a promise info for observing the operation
    return info;
  };

  /** The function of the store in charge of fetching the data */
  const fetch = function () {
    const fetchPromise = options.fetchPromise();

    return _updateData(fetchPromise);
  };

  const ensure = function (opts?: EnsureOptions) {
    const staleAfter = opts?.staleAfter;

    if (
      shouldFetch(
        { data: data.value, lastFetchedAt: lastFetchedAt.value },
        staleAfter,
      )
    ) {
      return fetch();
    } else {
      // data.value is expected to be present, we can cast
      return Promise.resolve(data.value as DataType);
    }
  };

  const clearData = function () {
    data.value = null;
    promise.value = null;
    lastFetchedAt.value = null;
  };

  // A promise info representing the current fetch status
  const promiseInfo = computed<PromiseInfo<DataType>>(() => {
    return promise.value
      ? promiseInfoFn(promise.value)
      : voidPromiseInfo<DataType>();
  });

  // In some cases, we want a specific behavior on the first fetch - not on the subsequent refreshes.
  const initialPromiseInfo = computed<PromiseInfo<DataType>>(() => {
    // No data?
    if (data.value === null || data.value === undefined)
      return promiseInfo.value;

    // Data but empty? (@todo: not sure about that one)
    if (isArray(data.value) && data.value.length === 0)
      return promiseInfo.value;

    // Data present? mark as fetched.
    return successPromiseInfo<DataType>(data.value);
  });

  return {
    data,
    promise,
    promiseInfo,
    initialPromiseInfo,
    lastFetchedAt,
    fetch,
    ensure,
    updateData,
    clearData,
    destroyData,
  };
}
