import { App } from "scalingo/lib/models/regional";

import { copyToClipboard } from "@/lib/clipboard";
import { ActionHandler } from "@/lib/handlers/action";
import { RemoteOperation } from "@/lib/store/remote-operation";

import type { ComponentPublicInstance } from "vue";

export class CopyGitRemoteHandler extends ActionHandler<App> {
  keyPath = "gitRemote.copy";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    const promise = copyToClipboard(this.app.git_url).then(() => this.app);

    const operation = new RemoteOperation(promise);

    this.follow(operation);
  }
}
