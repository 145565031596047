<template>
  <svg
    class="h-16 w-16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="120 100 375 375"
    style="enable-background: new 0 0 1266 559"
    xml:space="preserve"
  >
    <g id="logo_art">
      <g>
        <g>
          <path
            id="path50_5_"
            class="st3"
            d="M292.778,434.892L292.778,434.892l62.199-191.322H230.669L292.778,434.892L292.778,434.892z"
          />
          <path
            id="path66_12_"
            class="st4"
            d="M143.549,243.57L143.549,243.57l-18.941,58.126c-1.714,5.278,0.137,11.104,4.661,14.394
				l163.509,118.801L143.549,243.57L143.549,243.57z"
          />
          <path
            id="path74_5_"
            class="st3"
            d="M143.549,243.57h87.12l-37.494-115.224c-1.919-5.895-10.282-5.895-12.27,0L143.549,243.57
				L143.549,243.57z"
          />
          <path
            id="path82_12_"
            class="st4"
            d="M442.097,243.57L442.097,243.57l18.873,58.126c1.714,5.278-0.137,11.104-4.661,14.394
				L292.778,434.892L442.097,243.57L442.097,243.57z"
          />
          <path
            id="path86_5_"
            class="st3"
            d="M442.097,243.57h-87.12l37.425-115.224c1.919-5.895,10.282-5.895,12.27,0L442.097,243.57
				L442.097,243.57z"
          />
          <polygon
            class="st5"
            points="292.778,434.892 354.977,243.57 442.097,243.57 			"
          />
          <polygon
            class="st5"
            points="292.778,434.892 143.549,243.57 230.669,243.57 			"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GitLabGraphic",
});
</script>

<style scoped>
.st3 {
  fill: #e24329;
}
.st4 {
  fill: #fca326;
}
.st5 {
  fill: #fc6d26;
}
</style>
