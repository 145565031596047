export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit will be used before other payment methods"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add credit via"])},
        "currentBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current account balance"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du compte"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le crédit sera utilisé avant les autres moyens de paiement"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter du crédit via"])},
        "currentBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel du compte"])}
      }
    }
  })
}
