export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
        "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
        "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier"])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
        "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])}
      }
    }
  })
}
