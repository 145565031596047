export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "initiate": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiate two-factor authentication"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this barcode with your app"])},
          "qrCodeInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To activate two-factor authentication for you app, scan the QRCode below with a two factor authentication application like Google Authenticator or Authy, then type the authentication code in the field below."])},
          "keyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot scan the QRCode, you can manually enter the following key instead:"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
          "tfaAttemptLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN given by your authenticator app"])},
          "tfaAttemptPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456"])}
        },
        "validated": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication is enabled!"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store your recovery codes safely"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those codes are you recovery codes. Save them in a safe place. If you don't have access to your authentication device, these recovery codes will let you login to your account. Each code can only be used once."])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
        },
        "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])}
      },
      "fr": {
        "initiate": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer l'authentification deux facteurs"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez ce QR code avec votre app"])},
          "qrCodeInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To activate two-factor authentication for you app, scan the QRCode below with a two factor authentication application like Google Authenticator or Authy, then type the authentication code in the field below."])},
          "keyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot scan the QRCode, you can manually enter the following key instead:"])},
          "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
          "tfaAttemptLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN donné par votre application d'authentification"])},
          "tfaAttemptPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456"])}
        },
        "validated": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification deux facteurs est activée!"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conservez vos codes de récupérations"])},
          "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those codes are you recovery codes. Save them in a safe place. If you don't have access to your authentication device, these recovery codes will let you login to your account. Each code can only be used once."])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
        },
        "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier dans le presse papier"])}
      }
    }
  })
}
