export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deployments yet"])}
      },
      "fr": {
        "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun déploiement."])}
      }
    }
  })
}
