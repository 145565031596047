export default {
  "app": {
    "statuses": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
      "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stopped"])},
      "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
      "crashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crashed"])},
      "restarting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restarting"])},
      "scaling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scaling"])},
      "booting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booting"])}
    }
  }
}