export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add credit via paypal"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use paypal to credit your balance"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter du crédit via paypal"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utliser paypal pour ajouter du crédit à votre balance"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])}
      }
    }
  })
}
