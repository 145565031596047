<template>
  <DeleteModal
    :binIcon="false"
    :formHandler="formHandler"
    @formSubmitted="$emit('formSubmitted')"
    @modalClosed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>

    <template #question>
      {{ $t("question") }}
    </template>

    <template #button>
      {{ $t("button") }}
      <span class="ml-1">
        {{ formHandler.token.name }}
      </span>
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "RenewTokenModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed", "successfulTokenRenewal"],
});
</script>

<i18n>
en:
  title: "Renew a token"
  question: "Do you really want renew this token?"
  button: "Renew"
fr:
  title: "Renouveler un token"
  question: "Souhaitez-vous vraiment renouveler ce token?"
  button: "Renouveler"
</i18n>
