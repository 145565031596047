/* eslint-disable @typescript-eslint/no-explicit-any */

import { App } from "scalingo/lib/models/regional";
import { QueryParams } from "scalingo/lib/params/regional/metrics";

// Hopefully there will be real typings eventually.
// Until then, let's simulate one so that we don't have to put "any" everywhere
export type DatasetOptions = any;
export type Dataset = any;
export type GraphConfig = {
  opts: QueryParams;
  metric: string;
  subset: string;
  removeUnder?: number;
};

export type MetricsOpts = {
  app: App;
  since: number;
};

export type ContainerMetricsOpts = MetricsOpts & {
  containerType: string;
  containerIndex?: number;
};

const commonOptions: DatasetOptions = {
  type: "area",
  pointRadius: 0,
  pointHoverRadius: 0,
  fill: false,
  tension: 0,
  borderWidth: 1,
  spanGaps: false,
  steppedLine: "before",
};

const specificOptions: Record<string, Record<string, DatasetOptions>> = {
  rpm: {
    all: {
      nameKey: "rpm.all",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    "5xx": {
      nameKey: "rpm.5xx",
      borderColor: "rgba(220,97,80,1)",
      backgroundColor: "rgba(220,97,80,1)",
    },
  },
  responseTime: {
    median: {
      nameKey: "responseTime.median",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    p95: {
      nameKey: "responseTime.p95",
      borderColor: "rgba(3, 169, 244)",
      backgroundColor: "rgba(3, 169, 244, 0.2)",
      fill: "-1",
    },
    p99: {
      nameKey: "responseTime.p99",
      borderColor: "rgba(126, 206, 244,1)",
      backgroundColor: "rgba(126, 206, 244, 0.2)",
      fill: "-1",
    },
  },
  containers: {
    cpu: {
      nameKey: "containers.cpu",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    memory: {
      nameKey: "containers.memory",
      borderColor: "rgba(24,59,238,1)",
      backgroundColor: "rgba(79,106,242,0.2)",
      fill: "origin",
    },
    swap: {
      nameKey: "containers.swap",
      borderColor: "rgba(220,97,80,1)",
      backgroundColor: "rgba(220,97,80, 0.2)",
      fill: "-1",
    },
  },
};

export function getSeriesOptions(
  metric: string,
  subset: string,
): DatasetOptions {
  return {
    ...commonOptions,
    ...specificOptions[metric][subset],
  };
}

interface EventConfig {
  color: string;
}

export const eventsConfig: Record<string, EventConfig> = {
  deployment: {
    color: "#00C5A4",
  },
  crash: {
    color: "#DC6150",
  },
  repeated_crash: {
    color: "#DC6150",
  },
  restart: {
    color: "#22D1EE",
  },
  scale: {
    color: "#FFB800",
  },
};

export function routerGraphConfig({ since }: MetricsOpts): GraphConfig[] {
  return [
    {
      opts: { since },
      metric: "rpm",
      subset: "all",
    },
    {
      opts: { since, statusCode: "5XX" },
      metric: "rpm",
      subset: "5xx",
    },
  ];
}

export function requestsGraphConfig({ since }: MetricsOpts): GraphConfig[] {
  return [
    {
      opts: { since, statisticsType: "median" },
      metric: "responseTime",
      subset: "median",
    },
    {
      opts: { since, statisticsType: "p95" },
      metric: "responseTime",
      subset: "p95",
    },
    {
      opts: { since, statisticsType: "p99" },
      metric: "responseTime",
      subset: "p99",
    },
  ];
}

export function cpuGraphConfig({
  since,
  containerType,
  containerIndex,
}: ContainerMetricsOpts): GraphConfig[] {
  return [
    {
      opts: { since, containerType, containerIndex },
      metric: "containers",
      subset: "cpu",
    },
  ];
}

export function memoryGraphConfig({
  since,
  containerType,
  containerIndex,
}: ContainerMetricsOpts): GraphConfig[] {
  return [
    {
      opts: { since, containerType, containerIndex },
      metric: "containers",
      subset: "memory",
    },
    {
      opts: {
        since,
        containerType,
        containerIndex,
      },
      metric: "containers",
      subset: "swap",
      removeUnder: 16 * 1024 * 1024,
    },
  ];
}
