export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no keys here yet"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH key will appear here once created."])}
        }
      },
      "fr": {
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucune clé pour le moment"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés SSH apparaitront ici une fois ajoutées."])}
        }
      }
    }
  })
}
