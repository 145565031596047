import { CollaboratorInvitation } from "scalingo/lib/models/regional";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { inviteCollaborator } from "@/store/collaborators";

export interface FormData {
  email: string;
}

export class CreateCollaboratorHandler extends FormHandler<CollaboratorInvitation> {
  keyPath = "collaborator.create";

  data(): Nullable<FormData> {
    return {
      email: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
  }

  async submit(form: FormData): Promise<void> {
    this.follow(await inviteCollaborator(this.$store, form.email));
  }
}
