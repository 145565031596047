export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "commonBuildErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common build errors"])},
        "commonStartErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common start errors"])},
        "commonErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common deployment errors"])}
      },
      "fr": {
        "commonBuildErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs fréquentes au build"])},
        "commonStartErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs fréquentes au démarrage"])},
        "commonErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs fréquentes de déploiment"])}
      }
    }
  })
}
