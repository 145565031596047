import { defineStore } from "pinia";

import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { useSessionStore } from "@/stores/session";
import { currentUser } from "@/stores/user";

export const useOrdersStore = defineStore("orders", () => {
  const { privateClient } = useSessionStore();

  return useModelsStore({
    async fetchPromise() {
      const user = currentUser();
      const response = await privateClient().Orders.all({ owner_id: user.id });

      return response.orders;
    },
    async fetchOnePromise(id) {
      return privateClient().Orders.show(id);
    },
  });
});
