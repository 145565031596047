import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export type OrgType = "company" | "non-profit" | "public-entity";

export interface Contact {
  id: string;
  kind: OrgType;
  entity: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  updated_at: string;
  outdated: boolean;
  nearly_outdated: boolean;
}

export type CreateParams = {
  kind: OrgType;
  entity: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
};

export type UpdateParams = Partial<CreateParams>;

export default class Contacts {
  client: Scalingo;

  /**
   * Create new Client
   * @param {Client} client - Scalingo API Client
   */
  constructor(client: Scalingo) {
    this.client = client;
  }

  /**
   * Return contacts for a given user
   */
  show(): Promise<Contact> {
    return unpackData(
      this.client.authApiClient().get("/hds/point_of_contact", {
        headers: { Accept: "application/json" },
      }),
      "contact",
    );
  }

  create(contact: CreateParams): Promise<Contact> {
    return unpackData(
      this.client
        .authApiClient()
        .post(
          "/hds/point_of_contact",
          { contact },
          { headers: { Accept: "application/json" } },
        ),
      "contact",
    );
  }

  update(contact: UpdateParams): Promise<Contact> {
    const payload = contact ? { contact } : null;

    return unpackData(
      this.client.authApiClient().put(`/hds/point_of_contact/`, payload, {
        headers: { Accept: "application/json" },
      }),
      "contact",
    );
  }

  destroy(): Promise<void> {
    return unpackData(
      this.client.authApiClient().delete("/hds/point_of_contact", {
        headers: { Accept: "application/json" },
      }),
    );
  }
}
