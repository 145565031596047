export default {
  "regions": {
    "osc-st-fr1": {
      "humanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris - 3DS Outscale (staging)"])}
    },
    "osc-fr1": {
      "humanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris - 3DS Outscale"])}
    },
    "osc-secnum-fr1": {
      "humanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paris - 3DS Outscale (SecNumCloud)"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accès à cette région est possible uniquement via demande au support"])}
    }
  }
}