import { BillingProfile } from "scalingo/lib/models/billing";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updateProfile } from "@/store/billing-profile";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  name: string;
  email: string;
  address_line1: string;
  address_line2: string;
  address_zip: string;
  address_city: string;
  address_state: string;
  address_country: string;
  vat_number: string;
  company: string;
}

export class EditBillingProfileHandler extends FormHandler<BillingProfile> {
  keyPath = "billingProfile.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly profile: BillingProfile,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      ...this.profile,
      email: this.profile.email || null,
      address_line2: this.profile.address_line2 || null,
      address_state: this.profile.address_state || null,
      vat_number: this.profile.vat_number || null,
      company: this.profile.company || null,
    };
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await updateProfile(this.$store, this.profile.id, event));
  }

  dispatchEvents(): void {
    this.on("failure", () => this.notify("error"));
    this.on("success", () => this.notify("success"));
  }
}
