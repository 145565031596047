<template>
  <main>
    <LoadingCardState v-if="containers.latestFetch.isLoading" />
    <NoContainerWarning v-else-if="containers.items.length === 0" />
    <template v-else>
      <MetricsChoiceStep
        :enabled="true"
        :containers="containers"
        :formHandler="formHandler"
        @formSubmitted="(e) => $emit('formSubmitted', e)"
      />
      <CardSeparator />
      <NotifierStep :enabled="false" />
    </template>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import MetricsChoiceStep from "@/components/parts/appSettings/alertsCreation/MetricsChoiceStep.vue";
import NoContainerWarning from "@/components/parts/appSettings/alertsCreation/NoContainerWarning.vue";
import NotifierStep from "@/components/parts/appSettings/alertsCreation/NotifierStep.vue";

export default defineComponent({
  name: "AlertsCreation",
  components: {
    NoContainerWarning,
    LoadingCardState,
    CardSeparator,
    NotifierStep,
    MetricsChoiceStep,
  },
  props: {
    app: Object,
    containers: Object,
    formHandler: Object,
  },
  emits: ["formSubmitted"],
});
</script>
