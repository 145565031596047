export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deploy your own ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the minimal information required to create an app"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "errors": {
          "source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The URL supplied (", _interpolate(_named("url")), ") does not seem valid."])},
          "hostname": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The URL hostname does not seem valid (", _interpolate(_named("url")), ")"])},
          "owner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is no repository owner in the url (", _interpolate(_named("url")), ")"])},
          "repo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is no repository in the url (", _interpolate(_named("url")), ")"])},
          "fetch404": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is either no repository at ", _interpolate(_named("url")), " or no branch/ref named ", _interpolate(_named("ref"))])},
          "fetchGeneric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The repository at ", _interpolate(_named("url")), " cannot be read. If you are sure the url is correct and have an adblocker, try disabling it."])},
          "fetchRatelimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Requests to the GitHub API are throttled. Retry after ", _interpolate(_named("date"))])},
          "manifestParsing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The file ", _interpolate(_named("file")), " could not be parsed; is it a valid JSON file?"])},
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get informations for the one-click deploy, retry later or get in touch with the support"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Déployez votre ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez les informations requises pour la création d'une app"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "errors": {
          "source": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'URL fournie (", _interpolate(_named("url")), ") ne semble pas valide."])},
          "hostname": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le nom d'hôte ne semble pas valide (", _interpolate(_named("url")), ")"])},
          "owner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il n'y a pas de propriétaire du repo dans l'URL (", _interpolate(_named("url")), ")"])},
          "repo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il n'y a pas de repo dans l'URL (", _interpolate(_named("url")), ")"])},
          "fetch404": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il n'y a soit pas de repo pour ", _interpolate(_named("url")), ", soit aucune branche/ref nommée ", _interpolate(_named("ref"))])},
          "fetchGeneric": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le repo à l'url ", _interpolate(_named("url")), " ne peut pas être lu. Si vous êtes sûr que l'url est correcte et vous avez un adblocker, essayez de le désactiver."])},
          "fetchRatelimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les requêtes vers l'API GitHub sont limitées. Veuillez retenter après le ", _interpolate(_named("date"))])},
          "manifestParsing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier ", _interpolate(_named("file")), " ne peut pas être parsé; est-ce un fichier JSON valide?"])},
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les informations pour le one-click-deploy. Retentez ultérieurement ou bien contactez le support."])}
        }
      }
    }
  })
}
