import { App } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { Routes } from "@/router/names";
import { destroyApp } from "@/store/apps";

import type { ComponentPublicInstance } from "vue";

export class DeleteAppHandler extends ActionHandler<void> {
  keyPath = "app.delete";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.app.name });
      this.$router.push({ name: Routes.Welcome });
    });

    this.on("failure", () => {
      this.notify("error", { name: this.app.name });
    });
  }

  async submit(): Promise<void> {
    this.follow(await destroyApp(this.$store, this.app));
  }
}
