<template>
  <Card>
    <template #header>
      <div class="border-b-2 pb-6 flex">
        <div class="flex-grow">
          <div class="text-scale-7">
            {{ $t("subtitle") }}
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <SCTable
        layout="fixed"
        :headers="headers"
        :items="invoices.items"
        :paginated="true"
      >
        <template #item.billingPeriod="{ item }">
          <div class="humanize">
            {{ humanizedMonthYearTime(item.billing_month) }}
          </div>
        </template>

        <template #item.totalConsumption="{ item }">
          {{ formatAmount(item.total_consumption) }}
        </template>
      </SCTable>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { formatAmount } from "@/lib/utils/currency";
import { humanizedMonthYearTime } from "@/lib/utils/time";

export default defineComponent({
  name: "OrderConsumption",
  components: { Card, SCTable },
  props: {
    invoices: Object,
  },
  data() {
    return {
      headers: [
        { value: "billingPeriod", text: this.$t("headers.billingPeriod") },
        { value: "totalConsumption", text: this.$t("headers.amount") },
      ],
    };
  },
  methods: {
    formatAmount,
    humanizedMonthYearTime,
  },
});
</script>

<i18n>
en:
  subtitle: "Consumption"
  headers:
    billingPeriod: "Billing period"
    amount: "Amount"
  to: "to"
fr:
  subtitle: "Consommation"
  headers:
    billingPeriod: "Période de facturation"
    amount: "Montant"
  to: "au"
</i18n>
