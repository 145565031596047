export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployments triggered from a GitHub repo."])}
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitLab"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployments triggered from a GitLab repo."])}
        },
        "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect account"])},
        "linkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link repo"])},
        "unlinkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink repo"])},
        "organizationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
        "repositoryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repository"])},
        "branchLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default branch"])},
        "automaticDeployLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic deploy"])}
      },
      "fr": {
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements déclenchés depuis un dépôt GitHub."])}
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitLab"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiements déclenchés depuis un dépôt GitLab."])}
        },
        "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
        "linkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier"])},
        "unlinkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délier"])},
        "organizationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
        "repositoryLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
        "branchLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche par défaut"])},
        "automaticDeployLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement automatique"])}
      }
    }
  })
}
