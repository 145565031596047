<template>
  <div>{{ metric }} {{ is }} {{ limit }} {{ unit }}</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AlertItem",
  props: {
    alert: Object,
  },
  computed: {
    limit() {
      if (this.isResourceConsumption) {
        return this.alert.limit * 100;
      }
      return this.alert.limit;
    },
    isResourceConsumption() {
      const value = this.alert.metric;
      const resourceConsumptions = ["cpu", "memory", "swap"];

      return resourceConsumptions.includes(value);
    },
    metric() {
      const value = this.alert.metric;

      if (value === "cpu") {
        return this.$t("selects.metric.cpu");
      }
      if (value === "memory") {
        return this.$t("selects.metric.memory");
      }
      if (value === "swap") {
        return this.$t("selects.metric.swap");
      }
      if (value === "p95_response_time") {
        return this.$t("selects.metric.p95_response_time");
      }
      if (value === "5XX") {
        return this.$t("selects.metric.5XX");
      }
      if (value === "rpm") {
        return this.$t("selects.metric.rpm");
      }
      if (value === "rpm_per_container") {
        return this.$t("selects.metric.rpm_per_container");
      }
      return "";
    },
    unit() {
      const value = this.alert.metric;

      if (value === "cpu") {
        return this.$t("units.limit.cpu");
      }
      if (value === "memory") {
        return this.$t("units.limit.memory");
      }
      if (value === "swap") {
        return this.$t("units.limit.swap");
      }
      if (value === "p95_response_time") {
        return this.$t("units.limit.p95_response_time");
      }
      if (value === "5XX") {
        return this.$t("units.limit.5XX");
      }
      if (value === "rpm") {
        return this.$t("units.limit.rpm");
      }
      if (value === "rpm_per_container") {
        return this.$t("units.limit.rpm_per_container");
      }
      return "";
    },
    is() {
      if (!this.alert.send_when_below) {
        return this.$t("selects.is.greater");
      }
      return this.$t("selects.is.lower");
    },
  },
});
</script>

<i18n>
en:
  labels:
    define: "Define an alert on"
    metric: "If"
    is: "Is"
    limit: "Limit"
    duration: "During"
  selects:
    is:
      greater: "is greater than"
      lower: "is lower than"
    metric:
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Response time"
      5XX: "5xx errors"
      rpm: "Requests per minute"
      rpm_per_container: "RPM per container"
  units:
    duration: "min"
    limit:
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "per min"
      rpm: "rpm"
      rpm_per_container: "rpm"
  buttons:
    create: "Create metric"
    creating: "Creating ..."
fr:
  labels:
    define: "Definir une alerte sur"
    metric: "Si"
    is: "Est"
    limit: "Limite"
    duration: "Durant"
  selects:
    is:
      greater: "est plus grand que"
      lower: "est plus petit que"
    metric:
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Temps de réponse"
      5XX: "Erreurs 5xx"
      rpm: "Requête par minute"
      rpm_per_container: "RPM par container"
  units:
    duration: "min"
    limit:
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "par min"
      rpm: "rpm"
      rpm_per_container: "rpm"
  buttons:
    create: "Créer la metrique"
    creating: "Création ..."
</i18n>
