<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <SCButton v-if="!isPrivateStack" @click="$emit('startStackChange')">
            {{ $t("manage") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div v-if="currentStack">
        <div class="text-scale-7 pb-2">
          {{ $t("heading") }}
        </div>
        <div class="flex space-x-1 items-center">
          <font-awesome-icon icon="layer-group" class="h-6 w-6 text-scale-6" />
          <span class="font-medium">{{ currentStack.name }}</span>
          <span class="text-scale-5">({{ currentStack.description }})</span>
          <DocLink
            href="https://doc.scalingo.com/platform/internals/stacks/stacks"
            >{{ $t("more") }}
          </DocLink>
        </div>
        <div class="flex text-sm mt-2 items-center text-scale-5 ml-1">
          <div class="flex items-center" :title="$t('based')">
            <font-awesome-icon :icon="['fab', 'docker']" />
            <a
              target="_blank"
              :href="currentStackDockerUrl"
              class="ml-1 underline"
              >{{ currentStack.base_image }}
              <font-awesome-icon class="ml-1" icon="external-link-alt" />
            </a>
          </div>
        </div>
        <div v-if="lastDeployedStackImage">
          <div class="text-scale-7 mt-4">
            {{ $t("heading2") }}
          </div>
          <div class="flex text-sm mt-2 items-center text-scale-5 ml-1">
            <div class="flex items-center" :title="$t('based')">
              <font-awesome-icon :icon="['fab', 'docker']" />
              <a
                target="_blank"
                :href="lastDeployedStackDockerUrl"
                class="ml-1 underline"
                >{{ lastDeployedStackImage }}
                <font-awesome-icon class="ml-1" icon="external-link-alt" />
              </a>
            </div>
          </div>
          <SCAlert
            v-if="newVersionAvailable"
            kind="warning"
            icon="title"
            :title="$t('warning.title')"
            class="mt-4"
          >
            <template #icon>
              <WarningSignGlyph />
            </template>

            <div class="flex flex-col">
              <div>
                <router-link class="underline" :to="triggerRoute">{{
                  $t("warning.button")
                }}</router-link>
                {{ $t("warning.content") }} (<font-awesome-icon
                  :icon="['fab', 'docker']"
                />
                <span class="ml-1 font-medium">{{
                  this.currentStack.base_image
                }}</span
                >).
              </div>
            </div>
          </SCAlert>
        </div>
      </div>
      <FormAlert v-else-if="isPrivateStack" class="mt-4" styling="warning">
        <template #text>
          {{ $t("privateStack") }}
        </template>
      </FormAlert>
      <!-- To remove the clipping effect-->
      <div v-else style="height: 154px"></div>
    </template>
  </Card>
</template>

<script>
import { STATUS_SUCCESS } from "scalingo/lib/models/regional/deployments";
import { defineComponent } from "vue";

import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "StackCard",
  components: {
    SCAlert,
    WarningSignGlyph,
    DocLink,
    CardHeader,
    Card,
    SCButton,
    FormAlert,
  },
  props: {
    stacks: Object,
    deployments: Object,
    app: Object,
    scmRepoLink: Object,
  },
  emits: ["startStackChange"],
  computed: {
    isPrivateStack() {
      return (
        !this.currentStack && this.stacks.items.length > 0 && this.app.stack_id
      );
    },
    currentStack() {
      return this.stacks.items?.find((stack) => {
        return stack.id === this.app.stack_id;
      });
    },
    lastSuccessfulDeployment() {
      return this.deployments.items?.find((el) => {
        return el.status === STATUS_SUCCESS;
      });
    },
    lastDeployedStackImage() {
      return this.lastSuccessfulDeployment?.stack_base_image;
    },
    currentStackDockerUrl() {
      if (this.currentStack === null) {
        return null;
      }
      return this.dockerUrlFromBaseImage(this.currentStack.base_image);
    },
    newVersionAvailable() {
      if (!this.lastDeployedStackImage || !this.currentStack) {
        return false;
      }
      return this.lastDeployedStackImage !== this.currentStack.base_image;
    },
    lastDeployedStackDockerUrl() {
      if (!this.lastDeployedStackImage) {
        return null;
      }
      return this.dockerUrlFromBaseImage(this.currentStack.base_image);
    },
    triggerRoute() {
      if (this.scmRepoLink.value) {
        return {
          name: Routes.App.Deploy.Manual,
          params: { id: this.app.name },
        };
      }
      return {
        name: Routes.App.Deploy.Configuration,
        params: { id: this.app.name },
      };
    },
  },
  methods: {
    dockerUrlFromBaseImage(baseImage) {
      // Remove the tag, unsupported in the url, from the base image
      let base = baseImage.split(":")[0];
      return `https://hub.docker.com/r/${base}`;
    },
  },
});
</script>

<i18n>
en:
  title: "Scalingo Stack"
  subtitle: "Scalingo Stack is the curated and maintained Linux environment in which your applications are deployed and run."
  heading: "Settings for future deployments:"
  heading2: "Base image used in last successful deployment:"
  manage: "Change Scalingo Stack"
  based: "Scalingo Stack image based on"
  more: "Learn more"
  privateStack: "The Scalingo Stack in use is only available for the app owner."
  warning:
    title: "A newer version of the base image is available"
    content: "to use the most recent image"
    button: "Trigger a new deployment"
fr:
  title: "Scalingo Stack"
  subtitle: "Scalingo Stack est l'environnement Linux conçu et maintenu, dans lequel vos applications sont déployées et exécutées."
  heading: "Paramètres pour les prochains déploiements:"
  heading2: "Image de base utilisée lors du dernier déploiement réussi:"
  manage: "Changer la Scalingo Stack"
  based: "Scalingo Stack Docker basée sur"
  more: "En savoir plus"
  privateStack: "La Scalingo Stack utilisée n'est disponible que pour le propriétaire de l'app."
  warning:
    title: "Une version plus récente de l'image de base est disponible"
    content: "pour utiliser l'image la plus récente"
    button: "Déclencher un nouveau déploiement"
</i18n>
