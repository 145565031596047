export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled tasks"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Times are expressed in UTC. Tasks are displayed in the order of the cron.json file"])},
        "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Need help? Refer to ", _interpolate(_named("doclink"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the documentation"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled tasks."])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "lastRun": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last run: ", _interpolate(_named("date"))])},
        "nextRun": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next run: ", _interpolate(_named("date"))])},
        "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all tasks"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches planifiées"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les heures sont exprimées en UTC. Les tâches sont affichées dans l'ordre du fichier cron.json"])},
        "help": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Besoin d'aide? Consultez ", _interpolate(_named("doclink"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la documentation"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune tâche planifiée."])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
        "lastRun": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière exécution : ", _interpolate(_named("date"))])},
        "nextRun": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prochaine exécution : ", _interpolate(_named("date"))])},
        "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir toutes les tâches"])}
      }
    }
  })
}
