// Generic
export const ADD = "add";
export const ADD_MANY = "addMany";
export const DELETE = "delete";
export const MERGE = "merge";
export const SPLICE = "splice";
export const SET_ONE = "setOne";
export const SET_ALL = "setAll";
export const SET_META = "setMeta";

// Related to fetching of remote data
export const START_FETCH = "startFetch";
export const FETCH_SUCCESS = "fetchSuccess";
export const FETCH_ERROR = "fetchError";

// Session
export const CLEAR_APP = "clearApp";
export const CLEAR_TOKEN = "clearToken";
export const SET_APP = "setApp";
export const SET_TOKEN = "setToken";
export const SET_LOADING = "setLoading";
