<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :scmRepoLink="scmRepoLink"
    :scmIntegrations="scmIntegrations"
    :currentCartridge="currentCartridge"
    :currentSCMIntegration="currentSCMIntegration"
    :scmOrgs="scmOrgs"
    :scmRepos="scmRepos"
    :scmBranches="scmBranches"
    :connectIntegrationHandler="connectIntegrationHandler"
    :repoLinkOperation="repoLinkOperation"
    :deleteSCMRepoLinkHandler="deleteSCMRepoLinkHandler"
    :editSCMRepoLinkerHandler="editSCMRepoLinkerHandler"
    :listOrgsInfo="listOrgsInfo"
    :listReposInfo="listReposInfo"
    :listBranchesInfo="listBranchesInfo"
    @deployFormSubmitted="deployFormSubmitted"
    @cartridgeSelected="cartridgeSelected"
    @connectSCMIntegration="connectSCMIntegration"
    @startHostedSCMConnection="startHostedSCMConnection"
    @cancelHostedSCMConnection="cancelHostedSCMConnection"
    @confirmHostedSCMConnection="(e) => connectIntegrationHandler.submit(e)"
    @startSCMLink="startSCMLink"
    @stopSCMLink="stopSCMLink"
    @unlinkRepository="unlinkRepository"
    @scmOrgSelected="scmOrgSelected"
    @scmRepoSearched="scmRepoSearched"
    @scmRepoSelected="scmRepoSelected"
    @scmBranchSelected="scmBranchSelected"
    @finishSCMRepoLink="finishSCMRepoLink"
    @changeLinker="changeLinker"
    @disableAutoDeploy="disableAutoDeploy"
    @enableAutoDeploy="enableAutoDeploy"
    @fetchScmRepoLinkBranches="fetchScmRepoLinkBranches"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/deploy/Configuration.vue";
import {
  CreateSCMIntegrationHandler,
  DeleteSCMRepoLinkHandler,
  EditSCMRepoLinkerHandler,
} from "@/lib/handlers";
import { promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import {
  handlingLinkingFeedback,
  performQuickOAuthLink,
} from "@/lib/scm-integrations";
import {
  listBranchesForAppRepoLink,
  listBranchesForRepo,
  listMatchingRepos,
  listOrganizations,
  orderedBranches,
} from "@/lib/scm-repo-links";
import { Routes } from "@/router/names";
import {
  ensureSCMIntegrations,
  listSCMIntegrations,
} from "@/store/scm-integrations";
import {
  createScmRepoLink,
  disableAutoDeployForSCMRepoLink,
  enableAutoDeployForSCMRepoLink,
} from "@/store/scm-repo-link";

export default defineComponent({
  name: "DeployConfigurationContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    scmRepoLink: Object,
  },
  data() {
    return {
      currentCartridge: "git-push",
      connectIntegrationHandler: null,
      repoLinkOperation: null,
      deleteSCMRepoLinkHandler: null,
      editSCMRepoLinkerHandler: null,
      currentSCMIntegration: null,
      listOrgsInfo: null,
      listReposInfo: null,
      listBranchesInfo: null,
    };
  },
  computed: {
    scmIntegrations() {
      return listSCMIntegrations(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
    scmOrgs() {
      return this.listOrgsInfo?.data || [];
    },
    scmRepos() {
      return this.listReposInfo?.data || [];
    },
    scmBranches() {
      return orderedBranches(this.listBranchesInfo?.data || []);
    },
  },
  watch: {
    scmIntegrations(newVal) {
      if (newVal && newVal.items[0]) {
        this.currentCartridge = newVal.items[0].scm_type;
      }
    },
    scmRepoLink(newVal) {
      if (newVal) {
        this.currentCartridge = newVal.scm_type;
      }
    },
    "repoLinkOperation.isSuccess": function (newVal) {
      if (newVal) {
        this.stopSCMLink();
      }
    },
  },
  created() {
    this.handleSCMCallback(this.$route.query);
  },
  beforeMount() {
    ensureSCMIntegrations(this.$store);

    handlingLinkingFeedback(this.$router, this.$route, this.$store, this.$i18n);
  },
  methods: {
    resetIntegrationFormObject() {
      this.integrationOperation = null;
      this.integrationFormData = {
        url: "https://",
      };
    },
    async handleSCMCallback(query) {
      if (query.scmType) {
        this.currentCartridge = query.scmType;
      }
    },
    deployFormSubmitted({ app }) {
      this.$router.push({
        name: Routes.App.Overview,
        params: { id: app.name },
      });
    },
    changeLinker() {
      this.editSCMRepoLinkerHandler = new EditSCMRepoLinkerHandler(
        this,
        this.currentUser,
      );
      this.editSCMRepoLinkerHandler.submit();
    },
    async disableAutoDeploy() {
      this.repoLinkOperation = await disableAutoDeployForSCMRepoLink(
        this.$store,
      );
    },
    async enableAutoDeploy(eventArgs) {
      this.repoLinkOperation = await enableAutoDeployForSCMRepoLink(
        this.$store,
        eventArgs.form.branch,
      );
    },
    fetchScmRepoLinkBranches() {
      this.listBranchesInfo = promiseInfo(
        listBranchesForAppRepoLink(
          scalingoClient(this.$store, this.app.region),
          this.app,
        ),
      );
    },
    cartridgeSelected(eventArgs) {
      this.currentCartridge = eventArgs.cartridge;
    },
    connectSCMIntegration({ scmType }) {
      const pathname = this.$router.resolve({
        name: Routes.App.Deploy.Configuration,
        params: { id: this.app.name, region: this.app.region },
      }).href;

      performQuickOAuthLink(this.$store, scmType, pathname);
    },
    startHostedSCMConnection({ scmType }) {
      this.connectIntegrationHandler = new CreateSCMIntegrationHandler(
        this,
        scmType,
      );
    },
    cancelHostedSCMConnection() {
      this.connectIntegrationHandler = null;
    },
    startSCMLink({ scmIntegration }) {
      this.currentSCMIntegration = scmIntegration;

      this.listOrgsInfo = promiseInfo(
        listOrganizations(
          scalingoClient(this.$store, this.app.region),
          scmIntegration.id,
        ),
      );
    },
    stopSCMLink() {
      this.currentSCMIntegration = null;
      this.listOrgsInfo = null;
      this.listReposInfo = null;
      this.listBranchesInfo = null;
    },
    unlinkRepository() {
      this.deleteSCMRepoLinkHandler = new DeleteSCMRepoLinkHandler(
        this,
        this.app,
      );
      this.deleteSCMRepoLinkHandler.submit();
    },
    scmOrgSelected({ scmOrg }) {
      this.listReposInfo = promiseInfo(
        listMatchingRepos(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration,
          scmOrg,
        ),
      );
    },
    scmRepoSearched({ term, org }) {
      this.listReposInfo = promiseInfo(
        listMatchingRepos(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration,
          org,
          term,
        ),
      );
    },
    scmRepoSelected({ scmRepo }) {
      const repo = this.scmRepos.find((repo) => {
        return repo.url === scmRepo;
      });

      this.listBranchesInfo = promiseInfo(
        listBranchesForRepo(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration.id,
          repo.fullName,
        ),
      );
    },
    scmBranchSelected() {
      // Nothing to do here
    },
    async finishSCMRepoLink({ form }) {
      const payload = {
        source: form.repository,
        branch: form.branch,
        auth_integration_uuid: this.currentSCMIntegration.id,
        auto_deploy_enabled: form.autoDeploy,
      };

      this.repoLinkOperation = await createScmRepoLink(
        this.$store,
        this.app,
        payload,
      );
    },
  },
});
</script>
