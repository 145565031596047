/**
 * @see https://stackoverflow.com/questions/53795644/downloading-pdf-from-api-via-javascript
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Blob
 */
export function downloadBlob(blob: Blob, fileName: string) {
  const tempEl = document.createElement("a");
  const url = window.URL.createObjectURL(blob);

  document.body.appendChild(tempEl);
  tempEl.style.display = "none";
  tempEl.href = url;
  tempEl.download = fileName;
  tempEl.click();
  window.URL.revokeObjectURL(url);
}
