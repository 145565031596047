<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :to="accessRoute">
          {{ $t("action") }}
        </SCButton>
      </template>
    </CardHeader>
    <div>
      <div class="sm:hidden">
        <select
          id="tabs"
          name="tabs"
          class="block w-full rounded-md text-scale-5 focus:border-text-primary-2 focus:text-primary-2"
        >
          <option
            v-for="(tab, index) in tabs"
            :key="tab.key"
            :selected="currentTab === index"
            @click="currentTab = index"
          >
            {{ tab.name }}
          </option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex" aria-label="Tabs">
            <div
              v-for="(tab, index) in tabs"
              :key="tab.name"
              :class="[
                index === currentTab
                  ? 'border-selected text-primary-2'
                  : 'border-transparent text-scale-6 hover:border-gray-300 hover:text-scale-8',
                'w-1/3 border-b-2 py-3 px-1 text-center text-sm font-medium cursor-pointer capitalize',
              ]"
              @click="currentTab = index"
            >
              {{ tab.name }}
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col sm:flex-row justify-between items-center space-x-2"
    >
      <div
        class="font-mono bg-scale-1 p-2.5 pv:whitespace-nowrap overflow-x-auto mt-4 flex justify-between items-baseline w-full"
      >
        {{ currentItem.value }}
      </div>
      <div class="mt-3.5 flex flex-row items-center space-x-2">
        <span
          v-if="currentItem.key === 'connection-string'"
          class="flex flex-row items-center space-x-2"
          title="Add secret to copied text"
        >
          <small-toggle v-model="secretEnabled"></small-toggle>
          <font-awesome-icon
            class="text-scale-5 h-3.5 w-3.5"
            icon="key"
          ></font-awesome-icon>
        </span>
        <CopyButton @click="copyCommand(valueToCopy)" />
        <a class="underline" :href="currentItem.docLink" target="_blank">
          <SCButton kind="neutral" :title="$t('doclink')">
            <slot />

            <font-awesome-icon class="ml-1" icon="external-link-alt" />
          </SCButton>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SmallToggle from "@/components/atoms/toggles/SmallToggle.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import CopyButton from "@/components/organisms/buttons/CopyButton.vue";
import { copyToClipboard } from "@/lib/clipboard";
import { databaseName } from "@/lib/utils/databaseName";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ConnectCard",
  components: {
    CopyButton,
    SmallToggle,
    SCButton,
    CardHeader,
  },
  props: {
    db: Object,
    user: Object,
    app: Object,
    variables: Object,
  },
  data() {
    return {
      currentTab: 0,
      secretEnabled: false,
      accessRoute: {
        name: Routes.Db.Networking,
        args: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      },
      dbConnexions: {
        redis: {
          tunnel: "SCALINGO_REDIS_URL",
          console: "redis-console",
        },
        postgresql: {
          tunnel: "SCALINGO_POSTGRESQL_URL",
          console: "pgsql-console",
        },
        mysql: {
          tunnel: "SCALINGO_MYSQL_URL",
          console: "mysql-console",
        },
      },
    };
  },
  computed: {
    currentItem() {
      return this.tabs[this.currentTab];
    },
    valueToCopy() {
      return this.secretEnabled
        ? this.currentItem.secretValue
        : this.currentItem.value;
    },
    connectionStringSecret() {
      if (!this.variables.items) {
        return "";
      }
      let variableName = `SCALINGO_${this.db.type_name.toUpperCase()}_URL`;
      let variable = this.variables.items.find(
        (variable) => variable.name === variableName,
      );
      if (!variable) {
        return "";
      }
      return variable.value;
    },
    connectionString() {
      let str = this.connectionStringSecret;
      if (!str) {
        return "";
      }
      // Replace the password value by "<password>"
      const regex = /\/\/(.*):[^@]+@/g;
      return str.replace(regex, "//$1:<password>@");
    },
    tabs() {
      let cliCommand = `scalingo -a ${this.db.app_name} ${
        this.dbConnexions[this.db.type_name]?.console
      }`;
      let connectionString = this.connectionString;
      let connectionStringSecret = this.connectionStringSecret;
      let ssh = `scalingo -a ${this.db.app_name} db-tunnel ${
        this.dbConnexions[this.db.type_name]?.tunnel
      }`;
      let dbTypeName = databaseName(this.db.type_name);
      return [
        {
          key: "console",
          name: `${dbTypeName} ${this.$t("Console")}`,
          value: cliCommand,
          secretValue: cliCommand,
          docLink:
            "https://doc.scalingo.com/platform/databases/access#interactive-remote-console",
        },
        {
          key: "connection-string",
          name: this.$t("connectionString"),
          value: connectionString,
          secretValue: connectionStringSecret,
          docLink: "https://doc.scalingo.com/platform/databases/access",
        },
        {
          key: "ssh-tunnel",
          name: this.$t("sshTunnel"),
          value: ssh,
          secretValue: ssh,
          docLink:
            "https://doc.scalingo.com/platform/databases/access#encrypted-tunnel",
        },
      ];
    },
  },
  methods: {
    databaseName,
    copyCommand(command) {
      copyToClipboard(command);
    },
  },
});
</script>

<i18n>
en:
  title: "Connect"
  subtitle: "Secure connection methods to access your databases"
  action: "Manage"
  console: "console"
  sshTunnel: "SSH Tunnel"
  connectionString: "Connection String"
  doclink: "More information in our documentation"
  copyClipboard: "Copy to clipboard"

fr:
  title: "Connexion"
  subtitle: "Méthodes de connexion sécurisées pour l'accès aux bases de données"
  action: "Gérer"
  console: "console"
  sshTunnel: "Tunnel SSH"
  connectionString: "Chaîne de connexion"
  doclink: "Plus d'information dans notre documentation"
  copyClipboard: "Copier dans le presse-papiers"
</i18n>
