import { MD5 } from "crypto-js";
import { User } from "scalingo/lib/models/auth";

export function gravatarUrl(email: string, size = 128): string {
  return `https://www.gravatar.com/avatar/${MD5(email)}.png?s=${size}`;
}

export function initialsAvatar(fullname: string, size = 128): string {
  // Only transmit the initials, for data privacy reasons
  const cleanedName = fullname
    .split(" ")
    .map((part) => part[0])
    .filter((p) => !!p)
    .join(" ");

  const name = encodeURI(cleanedName);

  return `https://eu.ui-avatars.com/api/${name}/${size}`;
}

export function userAvatar(user: User, size = 128): string {
  const gravatar = gravatarUrl(user.email, size);
  const fallback = initialsAvatar(user.fullname || user.username, size);

  return `${gravatar}&d=${encodeURI(fallback)}`;
}
