<template>
  <div v-if="scmType === 'github-enterprise'">
    <p class="mt-4">
      {{ $t("github.instructions.url") }}
      <code class="text-sm">https://github.example.dev</code>
    </p>

    <p tag="p" keypath="github.instructions.token" class="mt-2">
      {{ $t("github.instructions.token") }}
      <DocLink
        href="https://help.github.com/articles/creating-a-personal-access-token-for-the-command-line"
      >
        {{ $t("github.instructions.doc") }}
      </DocLink>
    </p>

    <p tag="p" keypath="github.instructions.rights" class="mt-2">
      {{ $t("github.instructions.rights") }}
      <code class="text-sm">repo</code>.
    </p>
  </div>

  <div v-else-if="scmType === 'gitlab-self-hosted'">
    <p class="mt-4">
      {{ $t("gitlab.instructions.url") }}
      <code class="text-sm">https://gitlab.example.dev</code>
    </p>

    <p class="mt-2">
      {{ $t("gitlab.instructions.token") }}

      <DocLink
        href="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html"
      >
        {{ $t("gitlab.instructions.doc") }}
      </DocLink>
    </p>

    <p class="mt-2">
      {{ $t("gitlab.instructions.rights") }}

      <code class="text-sm">api,read_user</code>
      {{ $t("gitlab.instructions.roles") }}
      <DocLink
        href="https://docs.gitlab.com/ee/user/permissions.html#project-members-permissions"
      >
        {{ $t("gitlab.instructions.docLink") }}
      </DocLink>
    </p>
  </div>
</template>

<script>
import DocLink from "@/components/molecules/links/DocLink.vue";

export default {
  components: { DocLink },
  props: { scmType: { type: String } },
};
</script>

<i18n>
en:
  github:
    title: "Connect to a GitHub Enterprise"
    instructions:
      url: "Fill with the domain used to access your Github Enteprise, eg. "
      token: "The token you need is a personal access token: "
      doc: "here is the GitHub documentation on how to create one"
      rights: "The only scope you need on Scalingo is "
  gitlab:
    title: "Connect to a self-hosted Gitlab"
    instructions:
      url: "Fill with the domain used to access your GitLab instance, eg. "
      token: "The token you need is a personal access token: "
      doc: "here is the GitLab documentation on how to create one"
      rights: "The scopes you need on Scalingo are "
      roles: ". Your user account must also have "
      docLink: "a sufficient role, maintainer or owner"
fr:
  github:
    title: "Connexion a un GitHub Enterprise"
    instructions:
      url: "Remplissez avec le nom de domaine utilisé par votre GitHub Enterprise, par exemple "
      token: |
        Le token nécessaire est un "personal access token" :
      doc: "voici la documentation GitHub sur comment en créer un"
      rights: "Le seul scope nécessaire pour Scalingo est "
  gitlab:
    title: "Connexion a une instance GitLab"
    instructions:
      url: "Remplissez avec le nom de domaine utilisé par votre instance GitLab, par exemple "
      token: |
        Le token nécessaire est un "personal access token" :
      doc: "voici la documentation GitLab sur comment en créer un"
      rights: "Les scopes nécessaires pour Scalingo sont "
      roles: ". Votre compte doit également avoir "
      docLink: "un rôle tel quel maintainer ou owner"
</i18n>
