<template>
  <ViewComponent :app="app" :dbPlan="dbPlan" :addon="addon" :db="db" />
</template>

<script>
import { computed, defineComponent } from "vue";

import ViewComponent from "@/components/views/db/backups/Root.vue";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbPlanStore } from "@/stores/db/plan";

export default defineComponent({
  name: "BackupRootContainer",
  components: { ViewComponent },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const dbPlanStore = useDbPlanStore();
    const currentDbStore = useCurrentDBStore();

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
      addon: computed(() => currentDbStore.addon),

      dbPlan: computed(() => {
        return {
          item: dbPlanStore.item,
          promiseInfo: dbPlanStore.promiseInfo,
        };
      }),
    };
  },
});
</script>
