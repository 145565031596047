<template>
  <ViewComponent
    :app="app"
    :notifier="notifier"
    :eventTypes="eventTypes"
    :formHandler="formHandler"
    @submit="submit"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/notifiers/edit/Events.vue";
import { EditNotifierHandler } from "@/lib/handlers";
import { ensureEventTypes, listEventTypes } from "@/store/event-types";
import { ensureNotifier, findNotifier } from "@/store/notifiers";

export default defineComponent({
  name: "NotifierEditEventsContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formHandler: null,
    };
  },
  computed: {
    notifier() {
      if (this.$route.params.notifierId) {
        return findNotifier(this.$store, this.$route.params.notifierId);
      }
      return null;
    },
    eventTypes() {
      return listEventTypes(this.$store, this.app.region, { sortBy: "name" });
    },
  },
  watch: {
    notifier: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formHandler = new EditNotifierHandler(
            this,
            this.app,
            newVal,
            this.eventTypes.items,
            "show",
          );
        } else {
          this.formHandler = null;
        }
      },
    },
  },
  beforeMount() {
    ensureNotifier(this.$store, this.$route.params.notifierId);
    ensureEventTypes(this.$store, this.app.region);
  },
  methods: {
    submit(e) {
      this.formHandler.submit(e);
    },
  },
});
</script>
