import { defineStore } from "pinia";
import { ref } from "vue";

import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { modifyCollection } from "@/lib/scalingo/modifiers/logs-archives";
import { currentDB, currentDBClient } from "@/stores/current/db";

export const useDbLogArchivesStore = defineStore("dbLogArchives", () => {
  const nextCursor = ref<string | null>(null);
  const hasMore = ref(false);

  async function _fetchPage(cursor?: string) {
    const { id } = currentDB();
    const client = await currentDBClient();

    const response = await client.Logs.archivesFor(id, cursor);

    hasMore.value = response.has_more;
    nextCursor.value = response.next_cursor;

    return modifyCollection(response.archives);
  }

  const store = useModelsStore({
    fetchPromise: () => _fetchPage(),
  });

  async function fetchNextPage() {
    if (!hasMore.value) return;

    const moreArchives = await _fetchPage(nextCursor.value as string);

    // TODO: merge data
    return store.insertItems(moreArchives);
  }

  return {
    ...store,
    hasMore,
    fetchNextPage,
  };
});
