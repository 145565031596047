/* eslint-disable @typescript-eslint/no-explicit-any */
import Scalingo from "scalingo";
import { unpackData } from "scalingo/lib/utils";

export const STATE_NEW = "new";
export const STATE_PAID = "paid";
export const STATE_FAILED = "failed";
export const STATE_CANCELLED = "cancelled";
export const STATE_REFUNDED = "refunded";
export const STATE_PARTIALLY_REFUNDED = "partially_refunded";

export interface VouchersQuery {
  owner_id: string;
  type: string;
}

export interface Voucher {
  id: string;
  owner_id: string;
  type: string;
  description: string | null;
  content: Record<string, any>;
  expire_at: string | null;
  redeemed_at: string;
  code: string;
  redeemed_by: string | null;
  redeeming_reason: string | null;
}

export default class Vouchers {
  client: Scalingo;

  /**
   * Create new Client for the Balance Operations API
   * @param {Client} client - Scalingo API Client
   */
  constructor(client: Scalingo) {
    this.client = client;
  }

  all(opts: VouchersQuery): Promise<Voucher[]> {
    return unpackData(
      this.client.billingApiClient().get("/vouchers", { params: opts }),
      "vouchers",
    );
  }

  create(voucher: { code: string }): Promise<Voucher> {
    return unpackData(
      this.client.billingApiClient().post("/vouchers", { voucher }),
      "voucher",
    );
  }
}
