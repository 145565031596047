export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "AOFEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF enabled"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF option was successfully enabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF activation failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activation of AOF option failed: ", _interpolate(_named("err"))])}
          }
        },
        "AOFDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF disabled"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF option was successfully disabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF deactivate failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The deactivate of AOF option failed: ", _interpolate(_named("err"))])}
          }
        },
        "cacheEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache enabled"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache option was successfully enabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache activation failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activation of Cache option failed: ", _interpolate(_named("err"))])}
          }
        },
        "cacheDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache disabled"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache option was successfully disabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache deactivate failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The deactivate of Cache option failed: ", _interpolate(_named("err"))])}
          }
        },
        "RDBEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB enabled"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB option was successfully enabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB activation failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activation of RDB option failed: ", _interpolate(_named("err"))])}
          }
        },
        "RDBDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB disabled"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB option was successfully disabled."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB deactivate failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The deactivate of RDB option failed: ", _interpolate(_named("err"))])}
          }
        }
      },
      "fr": {
        "AOFEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF activé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option AOF a été activée avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'activation de l'option AOF"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'activation de l'option AOF a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "AOFDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF désactivé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option AOF a été désactivée avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de a désactivation de AOF"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La désactivation de l'option AOF a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "cacheEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache activé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option Cache a été activée avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'activation de l'option Cache"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'activation de l'option Cache a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "cacheDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache désactivé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option Cache a été désactivée avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de a désactivation de Cache"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La désactivation de l'option Cache a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "RDBEnabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB activé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option RDB a été activée avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'activation de l'option RDB"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'activation de l'option RDB a échoué: ", _interpolate(_named("err"))])}
          }
        },
        "RDBDisabled": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDB désactivé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'option RDB a été désactivée avec succès."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de a désactivation de RDB"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La désactivation de l'option RDB a échoué: ", _interpolate(_named("err"))])}
          }
        }
      }
    }
  })
}
