import { Key } from "scalingo/lib/models/auth";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  HANDLE_FETCH,
  CREATE,
  HANDLE_OPERATION,
  DESTROY,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, ADD, DELETE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class KeysStore extends CollectionStore<Key> {
  actions = CollectionStore.buildActions<Key>({
    [REFRESH](context) {
      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context).Keys.all(),
        resolveAction: SET_ALL,
      });
    },
    [CREATE](context, payload) {
      const promise = scalingoClient(context).Keys.create(
        payload.name,
        payload.content,
      );

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: ADD,
      });
    },
    [DESTROY](context, key) {
      const promise = scalingoClient(context).Keys.destroy(key.id);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: () => context.commit(DELETE, key),
      });
    },
  });
  mutations = CollectionStore.buildMutations<Key>();
  getters = CollectionStore.buildGetters<Key>();
}

export const Keys = buildMapping(new KeysStore(), "keys");

export function allKeys(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Key>>,
): CollectionWithFetch<Key> {
  return {
    items: listItems(store.getters[Keys.getters.ALL], opts),
    latestFetch: store.getters[Keys.getters.LATEST_FETCH],
  };
}

export function ensureKeys(store: ApplicationStore): void {
  store.dispatch(Keys.actions.ENSURE);
}

export function createKey(
  store: ApplicationStore,
  payload: Partial<Key>,
): Promise<RemoteOperation<Key>> {
  return store.dispatch(Keys.actions.CREATE, payload);
}

export function destroyKey(
  store: ApplicationStore,
  key: Key,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Keys.actions.DESTROY, key);
}
