export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the scheduled database maintenance"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maintenance has been successfully completed and the nominal service has been restored."])},
        "degraded-perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance may be degraded for a few moments. However, if you notice any unusual behaviour, please contact our support team."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la maintenance planifiée de base de données terminée"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La maintenance a été effectuée avec succès et le service nominal a été rétabli."])},
        "degraded-perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances peuvent être dégradées pendant quelques instants. Toutefois, si vous constatez un comportement inhabituel, veuillez contacter notre équipe support."])}
      }
    }
  })
}
