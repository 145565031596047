import { APIError } from "scalingo/lib/errors";
import { Container } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import {
  restartAllContainers,
  restartContainersOfType,
} from "@/store/containers";

import type { ComponentPublicInstance } from "vue";

export class RestartContainersHandler extends ActionHandler<Container> {
  get keyPath(): string {
    const error = this.operation?.error as APIError;

    if (this.isError && error?.status === 422) {
      const { errors } = error.data;

      if ((errors?.app || []).includes("is restarting")) {
        return "containers.restart.restarting";
      }

      if ((errors?.app || []).includes("is scaling")) {
        return "containers.scale.scaling";
      }
    }

    return "containers.restart.generic";
  }

  constructor(
    component: ComponentPublicInstance,
    readonly name?: string,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    const op = this.name
      ? await restartContainersOfType(this.$store, this.name)
      : await restartAllContainers(this.$store);

    this.follow(op);
  }
}
