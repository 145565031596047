<template>
  <Card>
    <template v-slot:header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')" />
    </template>
    <template v-slot:body>
      <LoadingCardState v-if="isLoading" class="h-64 justify-center" />
      <EmptyCardState
        v-else-if="noMetricForThePeriod"
        class="h-64 justify-center"
      >
        <template #icon><EmptySetGlyph /></template>
        <span class="flex flex-col text-center">
          <span>{{ $t("noMetric1") }}</span
          ><span>{{ $t("noMetric2") }}</span>
        </span>
      </EmptyCardState>
      <EmptyCardState
        v-else-if="isError && !noMetricForThePeriod"
        class="h-64 justify-center"
      >
        <template #icon><CrossGlyph /></template>
        <span class="capitalize">{{ promiseInfo.err.data.error }}</span>
      </EmptyCardState>
      <RequestsPerMinute v-else-if="isLoaded" :datasets="datasets" />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import EmptySetGlyph from "@/components/atoms/glyphs/EmptySetGlyph.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import RequestsPerMinute from "@/components/organisms/graphs/RequestsPerMinute.vue";
import {
  makeDataSet,
  makeEventsDataSet,
  onlyEventsOfType,
} from "@/lib/metrics";

export default defineComponent({
  name: "RequestsCard",
  components: {
    EmptySetGlyph,
    Card,
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    RequestsPerMinute,
  },
  props: {
    promiseInfo: Object,
    routerRPMRawData: Array,
    router5XXRawData: Array,
    eventsRawData: Array,
  },
  computed: {
    isLoaded() {
      return this.promiseInfo.isSuccess;
    },
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isError() {
      return this.promiseInfo.isError;
    },
    noMetricForThePeriod() {
      return (
        this.isError && this.promiseInfo.err.data.error === "no metrics found"
      );
    },
    restartEventsDataset() {
      const restartEvents = onlyEventsOfType(this.eventsRawData, "restart");

      return makeEventsDataSet(
        this.$i18n,
        "events",
        "restart",
        restartEvents,
        this.allRPMsDataset,
      );
    },
    scaleEventsDataset() {
      const scaleEvents = onlyEventsOfType(this.eventsRawData, "scale");

      return makeEventsDataSet(
        this.$i18n,
        "events",
        "scale",
        scaleEvents,
        this.allRPMsDataset,
      );
    },
    deployEventsDataset() {
      const deployEvents = onlyEventsOfType(this.eventsRawData, "deploy");

      return makeEventsDataSet(
        this.$i18n,
        "events",
        "deploy",
        deployEvents,
        this.allRPMsDataset,
      );
    },
    allRPMsDataset() {
      return makeDataSet(this.$i18n, "rpm", "all", this.routerRPMRawData);
    },
    only5XXsDataset() {
      return makeDataSet(this.$i18n, "rpm", "5xx", this.router5XXRawData);
    },
    datasets() {
      return [
        this.allRPMsDataset,
        this.only5XXsDataset,
        this.restartEventsDataset,
        this.scaleEventsDataset,
        this.deployEventsDataset,
      ];
    },
  },
});
</script>

<i18n>
en:
  title: "Requests per minute"
  subtitle: "Number of requests per minute, with a discount count for requests with 5XX status."
  noMetric1: "No request to the app during the selected period"
  noMetric2: "Hence no metric to display"
fr:
  title: "Requête par minute"
  subtitle: "Requête par minute"
  noMetric1: "Aucune requête à l'app sur la période sélectionnée"
  noMetric2: "Par conséquence aucune métrique à aficher"
</i18n>
