<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :disabled="isEnabling" :loading="isLoading" @click="submit">
          {{ buttonName }}
        </SCButton>
      </template>
    </CardHeader>
    <div>
      <div v-if="isLoading" class="animate-pulse flex space-x-4">
        <div class="h-4 bg-gray-400 rounded w-1/3"></div>
      </div>

      <p v-if="tfa.latestFetch.isSuccess">
        <span class="text-scale-8 mr-1">{{ $t("label") }}</span>
        <span v-if="tfa.value.enabled" class="text-success font-bold">{{
          $t("text_enabled")
        }}</span>
        <span v-if="!tfa.value.enabled" class="text-secondary-3 font-bold">{{
          $t("text_disabled")
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "TwoFactorCard",
  components: {
    CardHeader,
    SCButton,
  },
  props: {
    tfa: Object,
    isEnabling: Boolean,
    initiateOperation: Object,
    disableOperation: Object,
  },
  emits: ["twoFactorDisabled", "twoFactorInitiated"],
  computed: {
    isLoading() {
      const fetch = this.tfa.latestFetch;
      const initiate = this.initiateOperation;
      const disable = this.disableOperation;

      return (
        initiate?.isLoading || disable?.isLoading || fetch?.isLoading || false
      );
    },
    buttonName() {
      if (this.tfa?.value?.enabled === false) {
        return this.$t("buttonNameDisabled");
      }
      return this.$t("buttonNameEnabled");
    },
  },
  methods: {
    submit() {
      if (this.tfa?.value?.enabled) {
        this.$emit("twoFactorDisabled");
      } else {
        this.$emit("twoFactorInitiated");
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Two-factor authentication"
  subtitle: "Add an extra level of security"
  buttonNameDisabled: "Enable"
  buttonNameEnabled: "Disable"
  label: "Two-factor authentication"
  text_disabled: "is disabled"
  text_enabled: "is enabled"
fr:
  title: "Authentification deux facteurs"
  subtitle: "Ajout d'un niveau de sécurité supplémentaire"
  buttonNameDisabled: "Activer"
  buttonNameEnabled: "Désactiver"
  label: "L'authentification deux facteurs"
  text_disabled: "est désactivée"
  text_enabled: "est activée"
</i18n>
