<template>
  <div
    class="z-50 fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
  >
    <!--
      Background overlay, showRoom/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 transition-opacity" @click="modalCloseRequested">
      <div class="absolute inset-0 bg-scale-10 opacity-25"></div>
    </div>

    <!--
      Modal panel, showRoom/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div
      :class="classFn"
      class="bg-scale-0 rounded-lg px-4 pt-5 pb-4 overflow-auto shadow-xl transform transition-all sm:p-6 max-h-screen"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="flex" :class="{ 'border-b-2 border-scale-1': hasBorder }">
        <div class="flex flex-grow">
          <div
            v-if="hasTitleIcon"
            class="bg-scale-1 rounded-full h-12 w-12 mr-4 ml-2 flex items-center justify-center"
          >
            <slot name="titleIcon"></slot>
          </div>
          <div class="flex flex-col">
            <div class="flex-grow">
              <h3 class="font-bold text-lg">
                <slot name="title"></slot>
              </h3>
              <slot name="subtitle"></slot>
            </div>
          </div>
          <div v-if="dismissible" class="flex-grow">
            <div
              class="cursor-pointer float-right"
              @click="modalCloseRequested"
            >
              <LittleCross class="text-scale-3"></LittleCross>
            </div>
          </div>
        </div>
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LittleCross from "@/components/atoms/glyphs/LittleCross.vue";

export default defineComponent({
  name: "SimpleModal",
  components: { LittleCross },
  props: {
    size: {
      type: String,
      default: "",
    },
    hasTitleIcon: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["modalClosed"],
  computed: {
    classFn() {
      if (this.size !== "") {
        return this.size;
      }
      return "";
    },
  },
  created() {
    this.escapeListener = (e) => this.closeOnEscape(e);

    document.addEventListener("keyup", this.escapeListener);
  },
  methods: {
    closeOnEscape(event) {
      let isEscape = false;

      if ("key" in event) {
        isEscape = event.key === "Escape" || event.key === "Esc";
      } else {
        isEscape = event.keyCode === 27;
      }

      if (isEscape) {
        document.removeEventListener("keyup", this.escapeListener);
        this.modalCloseRequested();
      }
    },
    modalCloseRequested() {
      if (this.dismissible) {
        this.$emit("modalClosed");
      }
    },
  },
});
</script>
