import { App } from "scalingo/lib/models/regional";
import { UpdateParams } from "scalingo/lib/params/regional/apps";
import { ComponentPublicInstance } from "vue";

import { ActionHandler } from "@/lib/handlers/action";
import { updateApp } from "@/store/apps";
import { useCurrentAppStore } from "@/stores/current/app";

export class EditAppStackSettingsHandler extends ActionHandler<App> {
  keyPath = "appStackSettings.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      useCurrentAppStore().refresh();
      this.notify("success");
    });
    this.on("failure", () => this.notify("error"));
  }

  async submit(event: UpdateParams): Promise<void> {
    this.follow(await updateApp(this.$store, this.app, event));
  }
}
