<template>
  <ConfirmModal
    :immediate="true"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-lg"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <template #confirmText>{{ $t("buttonName") }}</template>
    {{ $t("text", { repository: event.source_repo_name }) }}
    <DocLink
      href="https://doc.scalingo.com/platform/app/review-apps#addons-collaborators-and-environment-variables"
      >{{ $t("link") }}</DocLink
    >
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import DocLink from "@/components/molecules/links/DocLink.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  name: "ManualReviewAppModal",
  components: {
    ConfirmModal,
    DocLink,
  },
  props: {
    event: Object,
  },
  emits: ["close", "confirm"],
});
</script>

<i18n>
en:
  title: "Review app from a fork"
  text: "Only deploy review apps from trusted sources. As this pull request comes from a fork ({repository}), Scalingo invites you to consult the"
  link: "best practices for using review apps."
  buttonName: "Deploy a review app from a fork"
fr:
  title: "Review app depuis un fork"
  text: "Ne déployez des review apps qu'à partir de sources fiables. Cette pull request provenant d'un fork ({repository}), Scalingo vous invite à consulter les"
  link: "meilleures pratiques pour l'utilisation des review apps."
  buttonName: "Déployer une review app depuis un fork"
</i18n>
