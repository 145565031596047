export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiers"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure notifiers to be informed about events in your app."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items here"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiers will appear here once created."])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a notifier"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifieurs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer des notifieurs pour être informé des évènements de votre app."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun élément"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les notifieurs apparaitront ici une fois ajoutés."])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un notifieur"])}
        }
      }
    }
  })
}
