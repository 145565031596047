import { defineStore } from "pinia";

import {
  useCollectionStore,
  toPublicStore,
} from "@/lib/pinia/use-collection-store";
import { useSessionStore } from "@/stores/session";
import { currentUser } from "@/stores/user";

export const useCreditsStore = defineStore("credits", () => {
  const { privateClient } = useSessionStore();

  const store = useCollectionStore({
    fetchPromise: () => {
      const user = currentUser();

      return privateClient().Vouchers.all({
        owner_id: user.id,
        type: "credit",
      });
    },
  });

  const { insertItem } = store;

  return toPublicStore(store, {
    create(payload: { code: string }) {
      return insertItem(privateClient().Vouchers.create(payload));
    },
  });
});
