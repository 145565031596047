<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <TwoFaDisabledGlyph class="h-6 w-6 text-scale-6"></TwoFaDisabledGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template v-if="event.type_data.provider" #more>
      {{ $t("named") }}
      <span class="font-medium ml-1">{{ event.type_data.provider }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import TwoFaDisabledGlyph from "@/components/atoms/glyphs/TwoFaDisabledGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "TfaDisabledEvent",
  components: { TwoFaDisabledGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "TFA disbled"
  named: "Provider: "
fr:
  title: "TFA désactivé"
  named: "Fournisseur :"
</i18n>
