<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <div class="flex items-center">
        <font-awesome-icon
          icon="code-fork"
          class="h-6 w-6 text-scale-6"
          size="2x"
        ></font-awesome-icon>
        <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
      </div>
    </template>
    <template #more>
      <div class="flex flex-row space-x-1 items-center">
        <span>{{ event.type_data.review_app_name }} from</span>
        <div
          class="flex flex-row space-x-1 items-center"
          :title="
            event.type_data.pr_comes_from_a_fork
              ? $t('titles.forked_repository')
              : ''
          "
        >
          <font-awesome-icon
            v-if="event.type_data.pr_comes_from_a_fork"
            class="text-warning cursor-help"
            icon="code-fork"
          />
          <ExtLink :href="event.type_data.source_repo_url" :hidden="true">{{
            event.type_data.source_repo_name
          }}</ExtLink>
        </div>

        <span>|</span>
        <ExtLink :href="event.type_data.pr_url" :hidden="true"
          >{{ event.type_data.pr_name }} #{{
            event.type_data.pr_number
          }}</ExtLink
        >
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import ExtLink from "@/components/molecules/links/ExtLink.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "DestroyReviewAppEvent",
  components: {
    ExtLink,
    BaseEvent,
  },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Review app destroyed"
  titles:
    forked_repository: "This is a fork from the linked repository, authored by a user outside of your organization."
fr:
  title: "Destruction d'une review app"
  titles:
    forked_repository: "Il s'agit d'un fork du dépôt lié, créé par un utilisateur à l'extérieur de votre organisation."
</i18n>
