<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 11H2C0.89543 11 0 10.1046 0 9V6.16C0.00152008 5.39621 0.147415 4.63959 0.43 3.93L1.5 1.26C1.8019 0.50194 2.53404 0.00329242 3.35 0H9.59C10.4941 0.000654508 11.361 0.360351 12 1C12.639 1.63965 13.5059 1.99935 14.41 2H15V9.95H14.11C13.419 9.94862 12.7762 10.304 12.41 10.89L11.19 12.89C10.7394 13.6087 10.0749 14.1683 9.29 14.49L8.86 14.66C8.31649 14.8786 7.89615 15.3245 7.71 15.88L7.11 17.66C7.04181 17.8619 6.85312 17.9984 6.64 18H6.5C6.09869 18 5.71409 17.8393 5.43221 17.5536C5.15032 17.268 4.99465 16.8813 5 16.48V16.11C4.98039 15.4162 5.13511 14.7285 5.45 14.11L7 11ZM17.5 1C17.2239 1 17 1.22386 17 1.5V10.5C17 10.7761 17.2239 11 17.5 11H19.5C19.7761 11 20 10.7761 20 10.5V1.5C20 1.22386 19.7761 1 19.5 1H17.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThumbDown",
});
</script>
