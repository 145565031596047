import { ActionHandler } from "@/lib/handlers/action";
import { App } from "@/lib/scalingo/modifiers/apps";
import { removeSCMRepoLink } from "@/store/scm-repo-link";

import type { ComponentPublicInstance } from "vue";

export class DeleteSCMRepoLinkHandler extends ActionHandler<void> {
  keyPath = "scmRepoLink.delete";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(await removeSCMRepoLink(this.$store, this.app));
  }
}
