export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retention policy"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the limit is reached, each new backup will replace the oldest"])},
        "overTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When your backup usage is reached, additional scheduled backups will replace the oldest one."])},
        "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your application require greater retention ?"])},
        "text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Learn more about backup ", _interpolate(_named("link"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retention policy according to each plan."])},
        "oldest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Oldest backup: ", _interpolate(_named("backupTime"))])},
        "newest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Newest backup: ", _interpolate(_named("backupTime"))])},
        "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retention"])},
        "frequency": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
          "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily: one every day"])},
          "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly: one every sunday"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly: one every first week of the month"])},
          "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual: on demand"])}
        },
        "retention_policy_labels": {
          "user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Indefinite, last ", _interpolate(_named("capacity")), " rolling backups"])},
          "months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("capacity")), " rolling months"])},
          "weeks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("capacity")), " rolling weeks"])},
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("capacity")), " rolling days"])},
          "notIncluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not included"])}
        },
        "businessCTA": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need greater data retention?"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to a Business plan"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de conservation des données"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque la limite est atteinte, chaque nouveau backup remplacera le plus ancien"])},
        "overTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque le nombre de sauvegardes est atteint, des sauvegardes planifiées supplémentaires remplacent la plus ancienne."])},
        "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre application nécessite-t-elle une plus grande politique de conservation des données ?"])},
        "text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En savoir plus sur la politique de ", _interpolate(_named("link"))])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rétention des backups en fonction de chaque plan."])},
        "oldest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Backup le plus ancien: ", _interpolate(_named("backupTime"))])},
        "newest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Backup le plus récent: ", _interpolate(_named("backupTime"))])},
        "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
        "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rétention"])},
        "frequency": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
          "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotidien : un par jour"])},
          "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire : un par dimanche"])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel : un chaque première semaine du mois"])},
          "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel : sur demande"])}
        },
        "retention_policy_labels": {
          "user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Indéfinie, ", _interpolate(_named("capacity")), " derniers glissants"])},
          "months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("capacity")), " mois glissants"])},
          "weeks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("capacity")), " semaines glissantes"])},
          "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("capacity")), " jours glissants"])},
          "notIncluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non inclus"])}
        },
        "businessCTA": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous besoin d'une plus grande rétention de vos données ?"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez à un plan Business"])}
        }
      }
    }
  })
}
