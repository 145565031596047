export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to a GitHub Enterprise"])},
          "instructions": {
            "url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fill with the domain used to access your Github Enteprise, eg. ", _interpolate(_named("url")), "."])},
            "token": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The token you need is a personal access token: ", _interpolate(_named("doc"))])},
            "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here is the GitHub documentation on how to create one"])},
            "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The only scope you need on Scalingo is ", _interpolate(_named("scope")), "."])}
          }
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to a self-hosted Gitlab"])},
          "instructions": {
            "url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fill with the domain used to access your GitLab instance, eg. ", _interpolate(_named("url")), "."])},
            "token": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The token you need is a personal access token: ", _interpolate(_named("doc"))])},
            "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here is the GitLab documentation on how to create one"])},
            "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The scopes you need on Scalingo are ", _interpolate(_named("scope")), ". Your user account must also have ", _interpolate(_named("roles"))])},
            "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a sufficient role, maintainer or owner"])}
          }
        }
      },
      "fr": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
        "github": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion a un GitHub Enterprise"])},
          "instructions": {
            "url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remplissez avec le nom de domaine utilisé par votre GitHub Enterprise, par exemple ", _interpolate(_named("url")), "."])},
            "token": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le token nécessaire est un \"personal access token\": ", _interpolate(_named("doc")), "\n"])},
            "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voici la documentation GitHub sur comment en créer un"])},
            "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le seul scope nécessaire pour Scalingo est ", _interpolate(_named("scope")), "."])}
          }
        },
        "gitlab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion a une instance GitLab"])},
          "instructions": {
            "url": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remplissez avec le nom de domaine utilisé par votre instance GitLab, par exemple ", _interpolate(_named("url")), "."])},
            "token": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le token nécessaire est un \"personal access token\": ", _interpolate(_named("doc")), "\n"])},
            "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voici la documentation GitLab sur comment en créer un"])},
            "rights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les scopes nécessaires pour Scalingo sont ", _interpolate(_named("scope")), ". Votre compte doit également avoir ", _interpolate(_named("roles"))])},
            "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un rôle tel quel maintainer ou owner"])}
          }
        }
      }
    }
  })
}
