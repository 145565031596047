export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "review": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid content"])},
          "changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review changes"])},
          "noChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No changes"])}
        },
        "readonly": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor is in read-only mode"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The editor is not available if your environment contains one or mone variables with line breaks, and is therefore in readonly."])}
        },
        "invalid": {
          "reserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " is a reserved name"])},
          "nameFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": the name is invalid (must start with _ or a letter and be followed by letters, numbers, or _)"])},
          "nameLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": the name of a variable is limited to 64 characters"])},
          "valueLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": the value of a variable is limited to 8192 characters"])},
          "blank": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": no value is supplied"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "revert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert changes"])}
      },
      "fr": {
        "review": {
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu incorrect"])},
          "changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier les changements"])},
          "noChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun changement"])}
        },
        "readonly": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'éditeur est en lecture seule"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'éditeur n'est pas disponible si votre environnement contient une ou plusieurs variables avec des sauts de lignes, et est donc présenté en lecture seule."])}
        },
        "invalid": {
          "reserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " est un nom réservé"])},
          "nameFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": le nom est invalide (il doit commencer par un _ ou une lettre et être suivi de lettres, chiffres, ou _)"])},
          "nameLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": le nom d'une variable ne peut pas dépasser les 64 caractères"])},
          "valueLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": la valeur d'une variable ne peut pas dépasser les 8192 caractères"])},
          "blank": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": aucune valeur fournie"])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
        "revert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler les modifications"])}
      }
    }
  })
}
