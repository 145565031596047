<template>
  <main>
    <ChoiceCard :enabled="false" />
    <CardSeparator />
    <PlanCard :enabled="false" />
    <CardSeparator />
    <ValidationCard
      :owner="owner"
      :enabled="true"
      :loadingOperation="loadingOperation"
      :selectedAddonProvider="selectedAddonProvider"
      :selectedAddonPlan="selectedAddonPlan"
      :context="createCtx"
      :displayPrices="displayPrices"
      @changeAddonProvider="$emit('changeAddonProvider')"
      @changeAddonPlan="$emit('changeAddonPlan')"
      @provisionningRequested="$emit('provisionningRequested')"
    ></ValidationCard>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import ChoiceCard from "@/components/parts/app/newAddons/ChoiceCard.vue";
import PlanCard from "@/components/parts/app/newAddons/PlanCard.vue";
import ValidationCard from "@/components/parts/app/newAddons/ValidationCard.vue";
import { RemoteOperation } from "@/lib/store/remote-operation";

export default defineComponent({
  name: "AddonCreationStep3",
  components: {
    ValidationCard,
    PlanCard,
    ChoiceCard,
    CardSeparator,
  },
  props: {
    owner: Object,
    loadingOperation: [RemoteOperation, Object],
    selectedAddonProvider: Object,
    selectedAddonPlan: Object,
    createCtx: Object,
    displayPrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["changeAddonPlan", "changeAddonProvider", "provisionningRequested"],
});
</script>
