export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs from the last archive up to now are viewable using our CLI tool with"])}
      },
      "fr": {
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les logs entre la dernière archive et maintenant sont visibles sur la CLI via"])}
      }
    }
  })
}
