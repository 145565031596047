export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending invitation"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted invitation"])}
      },
      "fr": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invitation en cours"])},
        "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invitation acceptée"])}
      }
    }
  })
}
