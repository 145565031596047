<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :addon="addon"
    :db="db"
    :dbPlan="dbPlan"
    :since="since"
    :instancesData="instancesData"
    @rangeSelected="rangeSelected"
  />
</template>

<script>
import { DateTime } from "luxon";
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import ViewComponent from "@/components/views/db/DbMetrics.vue";
import { upsertDataPoints } from "@/lib/metrics";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { useCurrentAppStore } from "@/stores/current/app";
import {
  currentDB,
  currentDBClient,
  useCurrentDBStore,
} from "@/stores/current/db";
import { useDbPlanStore } from "@/stores/db/plan";

export default defineComponent({
  name: "DbMetricsCtrl",
  components: { ViewComponent },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const dbPlanStore = useDbPlanStore();

    const baseDb = currentDB();

    // How far in the past do we check for data (in hours)
    const since = ref(3);

    /**
     * Data holder object. Looks like
     * {
     *   <instance id> => {
     *     <graph name> => {
     *       names: [metricName1, ...],
     *       info: <PromiseInfo>,
     *       sets: {
     *         <label1>: Point[],
     *         ...,
     *       },
     *     },
     *     ...
     *   },
     *   ...
     * }
     */
    const instancesData = ref({});

    // (1) When mounted this component, ensure that we have an up to date db object/instances list
    onMounted(() => currentDbStore.partialDBRefresh("instances"));
    // (2) Every time the db changes, clear the data holder object
    watch(baseDb, (db) => cleanupInstanceMetrics(db));
    // (3) Every time the "since" changes, fetch the metrics from the start again
    watch(since, () => cleanupInstanceMetrics(currentDB()));
    // (4) Every time the data holder object changes, fetch the metrics
    watch(instancesData, (newObj) => refreshInstancesMetrics(newObj));

    // This function resets the data holder object
    function cleanupInstanceMetrics(db) {
      instancesData.value = {};

      db.instances.forEach((instance) => {
        const metrics = {
          cpu: {
            names: ["cpu"],
            info: voidPromiseInfo(),
            sets: { cpu: [] },
          },
          memory: {
            names: ["memory", "swap"],
            info: voidPromiseInfo(),
            sets: { memory: [], swap: [] },
          },
        };

        if (instance.type === "db-node") {
          metrics.disk = {
            names: ["disk"],
            info: voidPromiseInfo(),
            sets: { used: [], total: [] },
          };

          metrics.diskio = {
            names: ["diskio"],
            info: voidPromiseInfo(),
            sets: { reads: [], writes: [] },
          };
        }

        instancesData.value[instance.id] = metrics;
      });
    }

    // This function is invoked either when the data holder changes (with last = false)
    // or periodically, when new metrics data should be added (with last = true)
    function refreshInstancesMetrics(targetInstancesData, last = false) {
      Object.keys(targetInstancesData).forEach((instanceId) =>
        Object.entries(targetInstancesData[instanceId]).forEach(
          ([graphName, graphMeta]) => {
            refreshInstanceMetrics(
              currentDB(),
              instanceId,
              graphName,
              graphMeta,
              last,
            );
          },
        ),
      );
    }

    // This function refreshes metrics data for one instance.
    // It fetches (in the background) all the data needed.
    async function refreshInstanceMetrics(
      db,
      instanceId,
      graphName,
      graphMeta,
      last,
    ) {
      const client = await currentDBClient();
      const opts = last ? { last: true } : { since: since.value };

      const promises = graphMeta.names.map((metricName) => {
        return client.Instances.metrics(
          db.id,
          instanceId,
          metricName,
          opts,
        ).then((response) => {
          if (graphName === "cpu" || graphName === "memory") {
            const dataPoints = graphMeta.sets[metricName];

            upsertDataPoints(dataPoints, response);

            checkForInterval(response);
          } else if (graphName === "disk") {
            const { used, total } = response;

            upsertDataPoints(graphMeta.sets.used, used);
            upsertDataPoints(graphMeta.sets.total, total);
          } else if (graphName === "diskio") {
            const { reads, writes } = response;

            upsertDataPoints(graphMeta.sets.reads, reads);
            upsertDataPoints(graphMeta.sets.writes, writes);
          }
        });
      });

      graphMeta.info = promiseInfo(Promise.all(promises));
    }

    // Metrics needs to be updated periodically,
    // and the period has to be infered from the datapoints.
    const refreshInterval = ref(null);
    const refreshIntervalID = ref(null);

    function checkForInterval(datapoints) {
      if (!datapoints) return;

      const first = datapoints[0];
      const second = datapoints[1];

      if (first && second) {
        const diff = DateTime.fromISO(second.time).diff(
          DateTime.fromISO(first.time),
        );

        refreshInterval.value = diff.as("milliseconds");
      }
    }

    // When we detect a new interval between items, we clear the previous
    // refresh mechanism (if any) and we set up the new one.
    watch(refreshInterval, (newVal) => {
      if (refreshIntervalID.value) {
        clearInterval(refreshIntervalID.value);
        refreshIntervalID.value = null;
      }

      if (newVal) {
        refreshIntervalID.value = setInterval(() => {
          refreshInstancesMetrics(instancesData.value, true);
        }, newVal);
      }
    });

    return {
      since,
      instancesData,
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => baseDb),
      addon: computed(() => currentDbStore.addon),
      dbPlan: computed(() => dbPlanStore.item),
      rangeSelected: (e) => (since.value = e),
    };
  },
});
</script>
