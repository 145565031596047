export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cannotDownload": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot download invoice"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while downloading the invoice. Please try again later."])}
        }
      },
      "fr": {
        "cannotDownload": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de télécharger la facture"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du téléchargement de la facture. Veuillez réessayer plus tard."])}
        }
      }
    }
  })
}
