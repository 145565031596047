<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 9.08V8.92C21.0004 8.75501 20.9177 8.60091 20.78 8.51L13.11 3.26C12.8625 3.0908 12.5698 3.00019 12.27 3H11.73C11.4302 3.00019 11.1375 3.0908 10.89 3.26L3.22 8.51C3.08232 8.60091 2.99963 8.75501 3 8.92V9.08C2.99963 9.24499 3.08232 9.39909 3.22 9.49L10.89 14.74C11.1375 14.9092 11.4302 14.9998 11.73 15H12.27C12.5698 14.9998 12.8625 14.9092 13.11 14.74L20.78 9.49C20.9177 9.39909 21.0004 9.24499 21 9.08ZM16.9 14.27L18.67 13.06L20.78 14.51C20.9177 14.6009 21.0004 14.755 21 14.92V15.08C21.0004 15.245 20.9177 15.3991 20.78 15.49L13.11 20.74C12.8625 20.9092 12.5698 20.9998 12.27 21H11.73C11.4302 20.9998 11.1375 20.9092 10.89 20.74L3.22 15.49C3.08232 15.3991 2.99963 15.245 3 15.08V14.92C2.99963 14.755 3.08232 14.6009 3.22 14.51L5.33 13.06L7.06 14.27L6 15L11.93 19H12.15L18 15L16.9 14.27Z"
      fill="currentColor"
    />
    <path
      d="M23 17C23 19.7614 20.7614 22 18 22C15.2386 22 13 19.7614 13 17C13 14.2386 15.2386 12 18 12C20.7614 12 23 14.2386 23 17Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 17C24 20.3137 21.3137 23 18 23C14.6863 23 12 20.3137 12 17C12 13.6863 14.6863 11 18 11C21.3137 11 24 13.6863 24 17ZM18 22C20.7614 22 23 19.7614 23 17C23 14.2386 20.7614 12 18 12C15.2386 12 13 14.2386 13 17C13 19.7614 15.2386 22 18 22Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1951 14.6219L20.3781 14.8049C20.456 14.8828 20.4999 14.9885 20.5 15.0987V15.3094C20.5014 15.4216 20.4574 15.5295 20.3781 15.6088L19.8653 16.1133C19.8393 16.1396 19.8039 16.1543 19.767 16.1543C19.73 16.1543 19.6946 16.1396 19.6686 16.1133L18.8898 15.3233C18.837 15.2694 18.837 15.1831 18.8898 15.1292L19.3914 14.6219C19.4707 14.5425 19.5786 14.4985 19.6907 14.4999H19.9014C20.0116 14.5 20.1173 14.5439 20.1951 14.6219ZM19.3388 16.6346C19.3916 16.5807 19.3916 16.4944 19.3388 16.4405L18.5461 15.6588C18.5201 15.6325 18.4847 15.6178 18.4477 15.6178C18.4108 15.6178 18.3754 15.6325 18.3494 15.6588L16.1128 17.896C16.0587 17.9496 16.0163 18.0137 15.9881 18.0845L15.5114 19.2876C15.4844 19.35 15.5067 19.4229 15.5641 19.4595C15.6033 19.4987 15.6622 19.5107 15.7137 19.49L16.9165 19.0021C16.9872 18.9739 17.0514 18.9314 17.105 18.8773L19.3388 16.6346Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppEditGlyph",
});
</script>
