<template>
  <Card>
    <template v-slot:header>
      <div class="border-b-2 pb-6 flex">
        <div class="flex-grow">
          <h3 class="font-bold text-xl flex">
            {{ invoice.invoice_number }}
            <PaymentStatus class="pl-2 h-8" :invoiceState="invoice.state" />
          </h3>
          <div class="text-scale-7">
            {{ $t("subtitle") }}
          </div>
        </div>
        <div class="flex">
          <slot name="buttons"></slot>
          <SCButton
            v-if="invoice.pdf_url"
            class="self-center"
            @click="$emit('downloadPdf', { invoice })"
          >
            {{ $t("download") }}
          </SCButton>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <table class="table-fixed w-full text-scale-10 mt-8">
        <thead class="border-b-2 font-medium">
          <tr>
            <td class="w-3/4">{{ $t("itemLabel") }}</td>
            <td class="w-1/4 text-right">{{ $t("priceLabel") }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in invoice.items" :key="key">
            <td class="py-2">{{ item.label }}</td>
            <td class="text-right">{{ formatAmount(item.price) }}</td>
          </tr>
          <tr>
            <td class="text-scale-5 py-2">VAT (20%)</td>
            <td class="text-right">
              {{
                formatAmount(invoice.total_price_with_vat - invoice.total_price)
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="border-t-2 border-scale-1 font-medium">
            <td class="text-scale-10 py-2 pt-4">Total</td>
            <td class="text-right">
              {{ formatAmount(invoice.total_price_with_vat) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import PaymentStatus from "@/components/parts/billing/showBilling/PaymentStatus.vue";
import { formatAmount } from "@/lib/utils/currency";

export default defineComponent({
  name: "InvoiceSummary",
  components: { PaymentStatus, SCButton, Card },
  props: {
    invoice: Object,
  },
  emits: ["downloadPdf"],
  methods: {
    formatAmount,
  },
});
</script>

<i18n>
en:
  subtitle: "Invoice summary"
  itemLabel: "Item"
  priceLabel: "Price"
  download: "Download (PDF)"
fr:
  subtitle: "Résumé de la facture"
  itemLabel: "Item"
  priceLabel: "Prix"
  download: "Télécharger (PDF)"
</i18n>
