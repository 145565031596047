<template>
  <StepCard
    :step="2"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-3xl"
  >
    <template v-if="findAppInfo?.isLoading">
      <LoadingCardState />
    </template>
    <DeployForm
      v-else-if="findAppInfo?.isSuccess"
      class="mt-6"
      :app="app"
      :scmRepoLink="scmRepoLink"
      :scmIntegrations="scmIntegrations"
      :currentCartridge="currentCartridge"
      :currentSCMIntegration="currentSCMIntegration"
      :scmOrgs="scmOrgs"
      :scmRepos="scmRepos"
      :scmBranches="scmBranches"
      :connectIntegrationHandler="connectIntegrationHandler"
      :repoLinkOperation="repoLinkOperation"
      :listOrgsInfo="listOrgsInfo"
      :listReposInfo="listReposInfo"
      :listBranchesInfo="listBranchesInfo"
      @cartridgeSelected="cartridgeSelected"
      @formSubmitted="formSubmitted"
      @connectSCMIntegration="connectSCMIntegration"
      @startHostedSCMConnection="(e) => $emit('startHostedSCMConnection', e)"
      @cancelHostedSCMConnection="$emit('cancelHostedSCMConnection')"
      @confirmHostedSCMConnection="
        (e) => $emit('confirmHostedSCMConnection', e)
      "
      @startSCMLink="startSCMLink"
      @stopSCMLink="$emit('stopSCMLink')"
      @unlinkRepo="$emit('unlinkRepo')"
      @scmOrgSelected="(eventArgs) => $emit('scmOrgSelected', eventArgs)"
      @scmRepoSearched="(e) => $emit('scmRepoSearched', e)"
      @scmRepoSelected="(eventArgs) => $emit('scmRepoSelected', eventArgs)"
      @scmBranchSelected="(eventArgs) => $emit('scmBranchSelected', eventArgs)"
      @finishSCMRepoLink="(eventArgs) => $emit('finishSCMRepoLink', eventArgs)"
    ></DeployForm>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import DeployForm from "@/components/parts/configure/app/DeployForm.vue";

export default defineComponent({
  name: "DeployStepCard",
  components: { DeployForm, StepCard, LoadingCardState },
  props: {
    app: Object,
    scmRepoLink: Object,
    findAppInfo: Object,
    scmIntegrations: Object,
    enabled: Boolean,
    currentCartridge: String,
    currentSCMIntegration: Object,
    scmOrgs: Array,
    scmRepos: Array,
    scmBranches: Array,
    connectIntegrationHandler: Object,
    repoLinkOperation: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "cancelHostedSCMConnection",
    "cartridgeSelected",
    "confirmHostedSCMConnection",
    "connectSCMIntegration",
    "finishSCMRepoLink",
    "formSubmitted",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "startHostedSCMConnection",
    "startSCMLink",
    "stopSCMLink",
    "unlinkRepo",
  ],
  methods: {
    formSubmitted() {
      this.$emit("formSubmitted", { app: this.app });
    },
    cartridgeSelected(eventArgs) {
      this.$emit("cartridgeSelected", eventArgs);
    },
    connectSCMIntegration(eventArgs) {
      this.$emit("connectSCMIntegration", eventArgs);
    },
    startSCMLink(eventArgs) {
      this.$emit("startSCMLink", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Deployment method"
  subtitle: "Choose a way to deploy your code on Scalingo."
fr:
  title: "Méthode de déploiement"
  subtitle: "Choisissez une manière de déployer votre code sur Scalingo."
</i18n>
