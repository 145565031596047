<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :reviewApps="reviewApps"
    :scmRepoLink="scmRepoLink.value"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/reviewApps/List.vue";
import { Routes } from "@/router/names";
import { ensureReviewApps, listReviewApps } from "@/store/review-apps";

export default defineComponent({
  name: "ReviewAppsListContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    scmRepoLink: Object,
  },
  computed: {
    reviewApps() {
      return listReviewApps(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
  },
  beforeMount() {
    if (!this.scmRepoLink.value) {
      this.$router.push({ name: Routes.App.ReviewApps.Root });
    }

    ensureReviewApps(this.$store, { staleAfter: "always" });
  },
});
</script>
