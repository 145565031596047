<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
    <LoadingCardState
      v-if="cpuOperation.isLoading"
      class="h-64 justify-center"
    />
    <EmptyCardState
      v-else-if="cpuOperation.isError"
      class="h-64 justify-center"
    >
      <template #icon><CrossGlyph /></template>
      <span class="capitalize">{{ cpuOperation.error }}</span>
    </EmptyCardState>

    <CPUGraph
      v-else-if="cpuOperation.isSuccess"
      :id="`${container.name}-cpu-graph`"
      :chartData="cpuOperation.value.datasets"
      :eventsAnnotations="eventsAnnotations"
      :container="container"
      :index="index"
      :displayInFullScreen="displayInFullScreen"
    />

    <LoadingCardState
      v-if="memoryOperation.isLoading"
      class="h-64 justify-center"
    />
    <EmptyCardState
      v-else-if="memoryOperation.isError"
      class="h-64 justify-center"
    >
      <template #icon><CrossGlyph /></template>
      <span class="capitalize">{{ memoryOperation.error }}</span>
    </EmptyCardState>

    <MemoryGraph
      v-else-if="memoryOperation.isSuccess"
      :id="`${container.name}-memory-graph`"
      :chartData="memoryOperation.value.datasets"
      :eventsAnnotations="eventsAnnotations"
      :container="container"
      :index="index"
      :maxMemory="maxMemory"
      :displayInFullScreen="displayInFullScreen"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import CPUGraph from "@/components/organisms/apex/CPUGraph.vue";
import MemoryGraph from "@/components/organisms/apex/MemoryGraph.vue";

export default defineComponent({
  name: "ContainerGraphsCard",
  components: {
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    CPUGraph,
    MemoryGraph,
  },
  props: {
    displayInFullScreen: Boolean,
    container: Object,
    containerSizes: Object,
    cpuOperation: Object,
    memoryOperation: Object,
    eventsAnnotations: Array,
    index: Number,
  },
  computed: {
    maxMemory() {
      const size = this.containerSizes.items.find(
        (size) => size.name === this.container.size,
      );

      return size.memory;
    },
  },
});
</script>
