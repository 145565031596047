<template>
  <VerticalPageMenu :items="menuItems"></VerticalPageMenu>
</template>

<script>
import { defineComponent } from "vue";

import VerticalPageMenu from "@/components/molecules/menus/VerticalPageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ReviewAppsMenu",
  components: { VerticalPageMenu },
  props: {
    selection: String,
    app: Object,
    scmRepoLink: Object,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems() {
      const routerParams = { id: this.app.name };

      const menuItems = [];

      if (this.scmRepoLink) {
        menuItems.push({
          name: this.$t("listing"),
          key: Routes.App.ReviewApps.List,
          routerArgs: {
            name: Routes.App.ReviewApps.List,
            params: routerParams,
          },
        });
      }

      menuItems.push({
        name: this.$t("config"),
        key: Routes.App.ReviewApps.Configuration,
        routerArgs: {
          name: Routes.App.ReviewApps.Configuration,
          params: routerParams,
        },
      });

      if (this.scmRepoLink) {
        menuItems.push({
          name: this.$t("manual"),
          key: Routes.App.ReviewApps.Manual,
          routerArgs: {
            name: Routes.App.ReviewApps.Manual,
            params: routerParams,
          },
        });
      }

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.key)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    config: "Configuration"
    listing: "Listing"
    manual: "Manual deployment"
  fr:
    config: "Configuration"
    listing: "Listing"
    manual: "Déploiement manuel"
</i18n>
