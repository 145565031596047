export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Confirm your ", _interpolate(_named("name")), " configuration"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review the configuration and estimated pricing of your app"])},
        "containers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no container"]), _normalize(["1 container"]), _normalize([_interpolate(_named("count")), " containers"])])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (for 30 days)"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and deploy"])},
        "errors": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong one-click-deploy configuration"])},
          "provider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The addon provider \"", _interpolate(_named("name")), "\" is invalid."])},
          "plan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The addon plan \"", _interpolate(_named("name")), "\" is invalid."])}
        },
        "status": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("name")), " is in progress"])},
          "errorTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("name")), " could not be created"])},
          "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["creating app \"", _interpolate(_named("name")), "\""])},
          "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adding environment variables"])},
          "addons1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creating addons"])},
          "addons2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting for addons to be provisionned"])},
          "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting app deployment"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Confirmez la configuration de votre ", _interpolate(_named("name"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez la configuration et le coût estimé de votre app"])},
        "containers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["aucun container"]), _normalize(["1 container"]), _normalize([_interpolate(_named("count")), " containers"])])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (pour 30 jours)"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer et déployer"])},
        "errors": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise configuration one-click-deploy"])},
          "provider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le type d'addon \"", _interpolate(_named("name")), "\" n'est pas valide."])},
          "plan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le plan d'addon \"", _interpolate(_named("name")), "\" n'est pas valide."])}
        },
        "status": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre ", _interpolate(_named("name")), " est en cours de création"])},
          "errorTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre ", _interpolate(_named("name")), " n'a pas pu être créé"])},
          "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["création de l'app \"", _interpolate(_named("name")), "\""])},
          "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajout des variables d'environnement"])},
          "addons1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["création des addons"])},
          "addons2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provisionnement des addons en cours"])},
          "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lancement du déploiement de l'app"])}
        }
      }
    }
  })
}
