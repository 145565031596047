export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type and amount of containers in use."])},
        "noContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No containers yet"])},
        "actions": {
          "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
          "restartAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart all"])},
          "restartOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Restart ", _interpolate(_named("container"))])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteneurs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type et nombre de conteneurs utilisés."])},
        "noContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de conteneurs"])},
        "actions": {
          "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
          "restartAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout redémarrer"])},
          "restartOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Redémarrer ", _interpolate(_named("container"))])}
        }
      }
    }
  })
}
