export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
        "triggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["triggered"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resolved"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
        "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usage on container"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["value at"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte"])},
        "triggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déclenchée"])},
        "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résolue"])},
        "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
        "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut"])},
        "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usage sur le container"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valeur à"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limite"])}
      }
    }
  })
}
