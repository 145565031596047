import { isEqual } from "lodash";

import { ActionHandler } from "@/lib/handlers/action";

import type { ComponentPublicInstance } from "vue";

export type { ErrorBag } from "@/lib/handlers/action";

/** The api of ValidationOberserver's instance we'll be using */
type ValidationObserver = {
  setErrors(errors: Record<string, string[] | string>): void;
};

/** Set the errors on the form if available */
export function setFormErrors(
  component: ComponentPublicInstance,
  formRef: string,
  errors: Record<string, string | string[]>,
): void {
  if (!component) return;
  if (!formRef) return;

  const form = component.$refs[formRef] as unknown as ValidationObserver | null;

  if (!form) return;

  form.setErrors(errors);
}

/** Set the backing component to its initial form data */
export function resetFormData(
  component: ComponentPublicInstance,
  formDataKey: string,
  data: unknown,
): void {
  if (!component) return;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentFormHolder = (component as any)[formDataKey];

  if (componentFormHolder == null || componentFormHolder == undefined) return;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (component as any)[formDataKey] = data;
}

/**
 * The generic skeleton of a form handler, to be subclassed.
 * Subclasses should implement `data` and `submit`. */
export abstract class FormHandler<T> extends ActionHandler<T> {
  /** The ref name used to locate the form */
  formRef = "form";

  /** The key under which to find the form data */
  formDataKey = "form";

  /** Holds a version of `data()`, used for dirtiness check */
  _dataForDirtiness: unknown;

  /** The object used to back the form. Should be invoked by components to get the initial state of the form. */
  abstract data(): unknown;

  /** Must be called by the backing component */
  initComponent(
    component: ComponentPublicInstance,
    formRef?: string,
    formDataKey?: string,
  ): void {
    if (formRef) this.formRef = formRef;
    if (formDataKey) this.formDataKey = formDataKey;

    this.on("failure", () => {
      setFormErrors(component, this.formRef, this.errors);
    });

    this.on("reset", () => {
      resetFormData(component, this.formDataKey, this.data());
    });

    this.emit("reset");
  }

  /**
   * Emits the 'reset' event.
   * This fn exists because its intent is clearer than calling `emit("reset")`.
   */
  resetFormData() {
    this.emit("reset");
  }

  /** Compares a version of the data to the initial data */
  isDirty(data: unknown): boolean {
    // Cache data() to reduce cost of dirtiness checking.
    // It should be stable for a given FormHandler.
    if (
      this._dataForDirtiness === null ||
      this._dataForDirtiness === undefined
    ) {
      this._dataForDirtiness = this.data();
    }

    return isEqual(this._dataForDirtiness, data);
  }
}
