export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running Queries"])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh queries"])},
        "idleQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idle Connections: "])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes en cours d’exécution"])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les requêtes"])},
        "idleQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexions en attente : "])}
      }
    }
  })
}
