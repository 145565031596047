export default {
  "banners": {
    "backupRestoration": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been successfully restored"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access your data safely."])}
      },
      "warning": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database restoration in progress"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on the size of your database, this process may take anywhere from a few minutes to several hours. We recommend that you avoid any operations until the restoration is complete."])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the database restoration."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encountered an issue while restoring the database. To ensure its integrity and current status, please reach out to our support team."])}
      },
      "info": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database restoration scheduled."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The restoration process will start soon. We recommend that you avoid any operations until the restoration is complete."])}
      }
    },
    "maintenanceInProgress": {
      "warning": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance in progress."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your database will remain available, but please avoid making any changes until the operation has been completed."])}
      }
    },
    "databaseUpgrade": {
      "warning": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database upgrade in progress."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database is currently being upgraded. We recommend that you avoid any operations until the upgrade is complete."])}
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been successfully upgraded."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access your data safely."])}
      },
      "user-error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the database upgrade."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue prevented the upgrade, leaving the database in its original state. If the issue persists, please reach out to our support team."])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the database upgrade."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encountered an issue while upgrading this database. Its current state requires intervention from our team. To ensure its integrity and current status, please reach out to our support team."])}
      }
    }
  }
}