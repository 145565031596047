import { defineComponent } from "vue";

import { navErrorHandler } from "@/router/utils";

export default defineComponent({
  data() {
    const displayInFullScreen: boolean =
      this.$route.query?.fullscreen === "true";

    return {
      displayInFullScreen,
    };
  },
  watch: {
    displayInFullScreen(newVal) {
      const query = { ...this.$route.query };

      if (newVal) {
        query.fullscreen = "true";
      } else {
        delete query.fullscreen;
      }

      this.$router.push({ query }).catch(navErrorHandler);
    },
  },
  methods: {
    fullScreenToggled() {
      this.displayInFullScreen = !this.displayInFullScreen;
    },
  },
});
