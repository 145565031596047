export default {
  "helpers": {
    "humanReadableSize": {
      "bytes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["byte"]), _normalize(["byte"]), _normalize(["bytes"])])},
      "kilo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KB"])},
      "mega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MB"])},
      "giga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB"])},
      "tera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TB"])}
    }
  }
}