export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache Mode"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache mode configuration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This persistence mode configures Redis® to drop the least recently used keys automatically. This mode is optimal for caching as the most queried keys will be kept in cache forever. Please be cautious, any data can be deleted don't use your Redis® instance to store important information in this mode."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache mode enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache mode disabled"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Cache"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration du mode Cache"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mode de persistance configure Redis® pour qu'il abandonne automatiquement les clés les moins récemment utilisées. Ce mode est optimal pour la mise en cache car les clés les plus demandées seront conservées dans le cache pour toujours. Soyez prudent, toutes les données peuvent être supprimées. N'utilisez pas votre instance Redis® dans ce mode pour stocker des informations importantes."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Cache activé"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Cache désactivé"])}
      }
    }
  })
}
