import Scalingo from "scalingo";

import { AppInfos } from "./app-infos";
import BalanceOperations from "./balance-operations";
import Contacts from "./contacts";
import Invoices from "./invoices";
import Orders from "./orders";
import PaymentMethods from "./payment-methods";
import Paypal from "./paypal";
import SetupIntents from "./setup-intents";
import Usage from "./usage";
import Vouchers from "./vouchers";

/**
 * This class handles a few private endpoints to the Scalingo API.
 * Those are not publicly documented and are not meant to be publicly consumed.
 * They all relate to billing and payment methods.
 */
export default class ScalingoPrivate {
  client: Scalingo;
  BalanceOperations: BalanceOperations;
  Invoices: Invoices;
  Orders: Orders;
  Vouchers: Vouchers;
  Contacts: Contacts;
  Paypal: Paypal;
  PaymentMethods: PaymentMethods;
  SetupIntents: SetupIntents;
  Usage: Usage;
  AppInfos: AppInfos;

  constructor(scalingoClient: Scalingo) {
    this.client = scalingoClient;
    this.BalanceOperations = new BalanceOperations(this.client);
    this.Invoices = new Invoices(this.client);
    this.Vouchers = new Vouchers(this.client);
    this.Contacts = new Contacts(this.client);
    this.Orders = new Orders(this.client);
    this.Paypal = new Paypal(this.client);
    this.PaymentMethods = new PaymentMethods(this);
    this.SetupIntents = new SetupIntents(this.client);
    this.Usage = new Usage(this.client);
    this.AppInfos = new AppInfos(this.client);
  }
}
