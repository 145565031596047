import { User as UserModel, UserPreferences } from "scalingo/lib/models/auth";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  UPDATE,
  HANDLE_OPERATION,
  HANDLE_FETCH,
  STOP_FREE_TRIAL,
} from "@/lib/store/action-types";
import { SET_ONE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { ResourceStore } from "@/lib/store/resource-store";
import { buildMapping, ResourceWithFetch } from "@/lib/store/utils";
import { ApplicationStore } from "@/store";

export class UserStore extends ResourceStore<UserModel> {
  actions = ResourceStore.buildActions<UserModel>({
    [REFRESH](context) {
      const promise = scalingoClient(context).Users.self();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [UPDATE](context, payload) {
      const promise = scalingoClient(context).Users.updateAccount(payload);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [STOP_FREE_TRIAL](context) {
      const promise = scalingoClient(context)
        .Users.stopFreeTrial()
        .then(() => {
          // User accounts needs to be refreshed once the free trial is stopped
          context.dispatch(REFRESH);
        });

      return context.dispatch(HANDLE_OPERATION, { promise });
    },
  });

  mutations = ResourceStore.buildMutations<UserModel>();
  getters = ResourceStore.buildGetters<UserModel>();
}

export const User = buildMapping(new UserStore(), "user");

// User will always be available, never null or undefined
type UserWithWithFetch = ResourceWithFetch<UserModel> & { value: UserModel };

export function userAndFetch(store: ApplicationStore): UserWithWithFetch {
  return {
    value: store.getters[User.getters.CURRENT],
    latestFetch: store.getters[User.getters.LATEST_FETCH],
  };
}

export function refreshUser(
  store: ApplicationStore,
): Promise<RemoteOperation<UserModel>> {
  return store.dispatch(User.actions.REFRESH);
}

export function updateAccount(
  store: ApplicationStore,
  payload: Partial<UserModel>,
): Promise<RemoteOperation<UserModel>> {
  return store.dispatch(User.actions.UPDATE, payload);
}

export function updatePreferences(
  store: ApplicationStore,
  preferences: Partial<UserPreferences>,
): Promise<RemoteOperation<UserModel>> {
  return store.dispatch(User.actions.UPDATE, { preferences });
}

export function stopFreeTrial(
  store: ApplicationStore,
): Promise<RemoteOperation<UserModel>> {
  return store.dispatch(User.actions.STOP_FREE_TRIAL);
}

export function userHasFlag(user: UserModel, flag: string): boolean {
  const flags = user.flags || {};

  return flags[flag] || false;
}

export function userIsAdmin(user: UserModel): boolean {
  return userHasFlag(user, "admin");
}

export function userIsAdminOrHasFlag(user: UserModel, flag: string): boolean {
  return userIsAdmin(user) || userHasFlag(user, flag);
}
