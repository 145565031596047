/**
 * Decorates a promise representing an operation with accessors
 * allowing to query the current state of the promise, the resolved/rejected values, etc
 */
export class RemoteOperation<T> {
  /** Always resolves (with `true`) once the underlying promise is settled */
  settled: Promise<true> | null = null;

  /** Resolved value of the promise */
  value: T | null = null;

  /** Rejection value of the promise */
  error: unknown | null = null;

  /** Wether the operation has started */
  hasStarted = false;

  /** Wether the operation represented by the promise is still ongoing */
  isLoading = false;

  /** Wether the operation represented by the promise was a success */
  isSuccess = false;

  /** Optional contextual data. Useful to pass data around. */
  context: unknown = null;

  /** The underlying promise */
  promise: Promise<T> | null = null;

  constructor(promise?: Promise<T>) {
    this.reset();

    if (promise) {
      this.start(promise);
    }
  }

  /** Alias of `value` for prettier use when dealing with collections. */
  get values(): T | null {
    return this.value;
  }

  /** Alias of `error` for prettier use when dealing with collections. */
  get errors(): unknown {
    return this.error;
  }

  /** Wether the operation represented by the promise is finished */
  get isFinished(): boolean {
    return this.hasStarted ? !this.isLoading : false;
  }

  /** Wether the operation represented by the promise ended up in error */
  get isError(): boolean {
    return this.isFinished ? !this.isSuccess : false;
  }

  /** Start the operation with the supplied promise */
  start(promise: Promise<T>): RemoteOperation<T> {
    this.promise = promise;
    this.hasStarted = true;
    this.isLoading = true;

    this.settled = new Promise((resolve) => {
      const trackSettlement = () => resolve(true);

      promise.then(trackSettlement, trackSettlement);
    });

    return this;
  }

  /** Make the operation follow the promise */
  follow(promise: Promise<T>): RemoteOperation<T> {
    const wrapped: Promise<T> = promise.then(
      (r) => {
        this.resolve(r);
        return r;
      },
      (e) => {
        this.reject(e);
        throw e;
      },
    );

    return this.start(wrapped);
  }

  /** Resets the operation to its initial "unstarted" state */
  reset(): void {
    this.promise = null;
    this.settled = null;
    this.value = null;
    this.error = null;
    this.hasStarted = false;
    this.isLoading = false;
    this.isSuccess = false;
  }

  /** Reset, then start the operation with the supplied promise */
  restart(promise: Promise<T>): void {
    this.reset();
    this.start(promise);
  }

  /** The operation was successful */
  resolve(value: T): void {
    this.value = value;
    this.isLoading = false;
    this.isSuccess = true;
  }

  /** The operation failed */
  reject(error: unknown): void {
    this.error = error;
    this.isLoading = false;
    this.isSuccess = false;
  }
}

function andReducer(acc: boolean, val: boolean): boolean {
  return acc && val;
}

function orReducer(acc: boolean, val: boolean): boolean {
  return acc || val;
}

export class RemoteOperationAggregate {
  operations: RemoteOperation<unknown>[] = [];

  constructor(operations: RemoteOperation<unknown>[]) {
    this.follow(operations);
  }

  reset(): void {
    this.operations = [];
  }

  follow(operations: RemoteOperation<unknown>[]): void {
    if (operations?.length > 0) {
      this.operations = operations;
    }
  }

  get hasStarted(): boolean {
    if (!this.operations || this.operations.length === 0) {
      return false;
    }

    return this.operations.map((o) => o.hasStarted).reduce(orReducer);
  }

  get isLoading(): boolean {
    if (!this.operations || this.operations.length === 0) {
      return false;
    }

    return this.operations.map((o) => o.isLoading).reduce(orReducer);
  }

  get isSuccess(): boolean {
    if (!this.operations || this.operations.length === 0) {
      return false;
    }

    return this.operations.map((o) => o.isSuccess).reduce(andReducer);
  }

  get isFinished(): boolean {
    if (!this.operations || this.operations.length === 0) {
      return false;
    }

    return this.operations.map((o) => o.isFinished).reduce(andReducer);
  }

  get isError(): boolean {
    if (!this.operations || this.operations.length === 0) {
      return false;
    }

    return this.operations.map((o) => o.isError).reduce(orReducer);
  }

  get errors(): unknown[] | null {
    if (!this.isError) {
      return null;
    }

    return this.operations.map((o) => o.errors);
  }

  get error(): unknown | null {
    return this.errors?.[0];
  }
}

/**
 * Below are utility operations, mostly here as dev placeholders or for testing purposes.
 */

/** Returns an successful operation */
export function operationSuccess<T>(data: T): RemoteOperation<T> {
  return new RemoteOperation<T>().follow(Promise.resolve(data));
}

/** Returns a loading operation */
export function operationLoading<T>(): RemoteOperation<T> {
  return new RemoteOperation<T>().follow(
    new Promise(() => {
      /** no op */
    }),
  );
}

/** Returns a failing operation */
export function operationError<T>(error: unknown): RemoteOperation<T> {
  return new RemoteOperation<T>().follow(Promise.reject(error));
}

/** Dummy, always-resolved operation */
export const RemoteOperationSuccess = new RemoteOperation<true>().follow(
  Promise.resolve(true),
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

/** Returns an successful operation lookalike */
export function fakeOperationSuccess<T>(data: T) {
  return {
    settled: Promise.resolve(true),
    value: data,
    error: null,
    hasStarted: true,
    isLoading: false,
    isSuccess: true,
    context: null,
    promise: Promise.resolve(data),
    values: data,
    errors: null,
    isFinished: true,
    isError: false,
    start: noOp,
    follow: noOp,
    reset: noOp,
    restart: noOp,
    resolve: noOp,
    reject: noOp,
  };
}

/** Returns a loading operation lookalike */
export function fakeOperationLoading() {
  return {
    settled: new Promise(noOp),
    value: null,
    error: null,
    hasStarted: true,
    isLoading: true,
    isSuccess: false,
    context: null,
    promise: new Promise(noOp),
    values: null,
    errors: null,
    isFinished: false,
    isError: false,
    start: noOp,
    follow: noOp,
    reset: noOp,
    restart: noOp,
    resolve: noOp,
    reject: noOp,
  };
}

/** Returns a failed operation lookalike */
export function fakeOperationError<T>(error: T) {
  return {
    settled: Promise.resolve(true),
    value: null,
    error: error,
    hasStarted: true,
    isLoading: false,
    isSuccess: false,
    context: null,
    promise: Promise.reject(error),
    values: null,
    errors: error,
    isFinished: true,
    isError: true,
    start: noOp,
    follow: noOp,
    reset: noOp,
    restart: noOp,
    resolve: noOp,
    reject: noOp,
  };
}
