// This is a helper to quickly setup a store which manages a single resource of a data type

import { DateTime } from "luxon";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";

import { promiseInfo } from "@/lib/promises/info";
import {
  CLEAR,
  ENSURE,
  REFRESH,
  HANDLE_OPERATION,
  HANDLE_FETCH,
  HANDLE_PROMISE,
} from "@/lib/store/action-types";
import { CURRENT, LATEST_FETCH } from "@/lib/store/getter-types";
import {
  SET_ONE,
  START_FETCH,
  FETCH_SUCCESS,
  FETCH_ERROR,
} from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  GenericResource,
  RootState,
  handleFetch,
  CommonState,
  startFetch,
  successfulFetch,
  failedFetch,
  handleOperation,
  shouldRefresh,
  EnsureOptions,
  broadcastGlobalError,
} from "@/lib/store/utils";

/** A "state" containing a singular resource */
export interface ResourceState<T = GenericResource> extends CommonState<T> {
  value: T | null;
  latestFetch: RemoteOperation<T>;
  lastFetchAt: DateTime | null;
}

/**
 * Returns a boilerplate store for a singular resource.
 * To be subclassed.
 */
export abstract class ResourceStore<T = GenericResource>
  implements Module<ResourceState<T>, RootState>
{
  state: ResourceState<T>;
  namespaced = true;

  abstract actions: ActionTree<ResourceState<T>, RootState>;
  abstract mutations: MutationTree<ResourceState<T>>;
  abstract getters: GetterTree<ResourceState<T>, RootState>;

  constructor(initialValue?: T) {
    this.state = {
      value: initialValue || null,
      latestFetch: new RemoteOperation<T>(),
      lastFetchAt: null,
    };
  }

  static buildActions<T = GenericResource>(
    actions: ActionTree<ResourceState<T>, RootState> = {},
  ): ActionTree<ResourceState<T>, RootState> {
    return {
      [ENSURE]: function (context, opts?: EnsureOptions) {
        const staleAfter = opts?.staleAfter;
        const refresh = shouldRefresh(context.state, staleAfter);

        if (refresh) {
          opts?.payload
            ? context.dispatch(REFRESH, opts.payload)
            : context.dispatch(REFRESH);
        }
      },
      [CLEAR]: function (context) {
        context.commit(SET_ONE, null);
      },
      // No need for a return value: `latestFetch` is in the store
      [HANDLE_FETCH](context, { promise, resolveAction }) {
        handleFetch(context, promise, resolveAction);
      },
      // Other operations are not in the store, so we return the operation
      [HANDLE_OPERATION]: function (
        context,
        { operation, promise, resolveAction },
      ) {
        return handleOperation(operation, context, promise, resolveAction);
      },
      [HANDLE_PROMISE]: function (context, { promise, resolveAction }) {
        const info = promiseInfo(promise);

        // This happens "in the background"
        promise.then(
          (resolved: T) => {
            if (resolveAction) {
              if (typeof resolveAction === "string") {
                context.commit(resolveAction, resolved);
              }

              if (typeof resolveAction === "function") {
                resolveAction(resolved);
              }
            }
          },
          (error: unknown) => {
            broadcastGlobalError(error);
          },
        );

        return info;
      },
      ...actions,
    };
  }

  static buildMutations<T = GenericResource>(
    mutations: MutationTree<ResourceState<T>> = {},
  ): MutationTree<ResourceState<T>> {
    return {
      [START_FETCH]: (state, promise) => startFetch(state, promise),
      [FETCH_SUCCESS]: (state, resolved) => successfulFetch(state, resolved),
      [FETCH_ERROR]: (state, error) => failedFetch(state, error),
      [SET_ONE]: function (state, value) {
        state.value = value;
      },
      ...mutations,
    };
  }

  static buildGetters<T = GenericResource>(
    getters: GetterTree<ResourceState<T>, RootState> = {},
  ): GetterTree<ResourceState<T>, RootState> {
    return {
      [CURRENT]: function (state) {
        return state.value;
      },
      [LATEST_FETCH]: function (state) {
        return state.latestFetch;
      },
      ...getters,
    };
  }
}

/**
 * Returns a generic resource store.
 * Meant to be used as a placeholder, or for testing purposes.
 */
export class GenericStore extends ResourceStore {
  actions = ResourceStore.buildActions();
  mutations = ResourceStore.buildMutations();
  getters = ResourceStore.buildGetters();
}
