export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "noPaidPlanTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to a Starter or Business plan to get backups"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backups allows you to create a manual or scheduled copy of your data."])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All backups are stored on our platform and can be either restored or downloaded."])},
        "upgradeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade plan"])},
        "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
        "noBackupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger your first backup"])},
        "manualBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger manual backup"])}
      },
      "fr": {
        "noPaidPlanTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez à un plan Starter ou Business pour disposer de backups"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les backups vous permettent de créer une copie manuelle ou planifiée de vos données."])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les backups sont stockés sur notre plateforme et peuvent être restaurés ou téléchargés."])},
        "upgradeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de plan"])},
        "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
        "noBackupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclenchez votre premier backup"])},
        "manualBackup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclencher un backup manuel"])}
      }
    }
  })
}
