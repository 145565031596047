export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
        }
      },
      "fr": {
        "headers": {
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
        }
      }
    }
  })
}
