<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AddonResumedGlyph class="h-6 w-6 text-scale-6"></AddonResumedGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      <span class="ml-1 mr-1 font-medium">
        {{ event.type_data.addon_provider_name }}</span
      >
      {{ $t("plan") }}
      <span class="ml-1 font-medium"> {{ event.type_data.plan_name }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AddonResumedGlyph from "@/components/atoms/glyphs/AddonResumedGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent, AddonResumedGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Addon resumed"
  plan: "with the plan"
fr:
  title: "Addon remis en service"
  plan: "avec le plan"
</i18n>
