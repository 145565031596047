<template>
  <TableAlert styling="warning">
    <template v-slot:title>{{ $t("title") }}</template>
    <template v-slot:text>{{ $t("text") }}</template>
  </TableAlert>
</template>

<script>
import { defineComponent } from "vue";

import TableAlert from "@/components/molecules/alerts/TableAlert.vue";

export default defineComponent({
  name: "NoContainerWarning",
  components: { TableAlert },
});
</script>

<i18n>
en:
  title: "No container found"
  text: "To create an alert you need to have at least one existing container type."
fr:
  title: "Aucun container trouvé"
  text: "Pour créer une alerte vous devez avoir un type de container existant."
</i18n>
