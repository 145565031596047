<template>
  <router-view :app="app"></router-view>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DomainsRoot",
  props: {
    app: Object,
  },
});
</script>
