export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your credits are now visible on a dedicated page"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no vouchers redeemed"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem voucher"])}
      },
      "fr": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vouchers seront déduits de votre facture"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun vouchers utilisé"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un voucher"])}
      }
    }
  })
}
