export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler deleted"])},
        "autoscaler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler was monitoring metric"])},
        "container_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on the container type"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler supprimé"])},
        "autoscaler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoscaler surveillant la métrique"])},
        "container_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur le type de container"])}
      }
    }
  })
}
