import i18n from "@/i18n";

/** Type describing a function that checks wether an item meets the filter */
export type FilterFn<T> = (app: T) => boolean;

/** Type describing a function that filters a collection */
export type CollectionFn<T> = (apps: T[]) => T[];

/** Type describing a list of filter functions */
export type FilterList<T> = FilterFn<T>[];

/** The signature of the function passed to `reduce` */
export type FilterReducer<T> = (acc: boolean, fn: FilterFn<T>) => boolean;

/** An option object for a given filter */
export interface FilterOption<T> {
  value: string;
  check: FilterFn<T> | null;
  i18nKey?: string;
  default?: boolean;
  text?: string;
  humanize?: boolean;
}

/** An filter object */
export interface Filter<T> {
  key: string;
  disabled?: boolean;
  options: FilterOption<T>[];
}

/** An filter definition object */
export interface FilterDefinition<T> {
  filter: Filter<T>;
  default: FilterOption<T> | undefined;
}

/**
 * This functions takes a list of filters as parameters,
 * and returns a function that returns a list of item that meets the N filters supplied.
 * The return value is intended to be used for the `transform` value of `ListItemsOptions`.
 */
export function applyFilters<T>(
  filters: FilterList<T>,
): CollectionFn<T> | null {
  if (filters.length > 0) {
    return (coll: T[]) => {
      return coll.filter((item) => {
        const reducer: FilterReducer<T> = (
          acc: boolean,
          fn: FilterFn<T>,
        ): boolean => {
          return acc && fn(item);
        };

        return filters.reduce(reducer, true);
      });
    };
  } else {
    return null;
  }
}

export function addTextToOption<T>(
  option: FilterOption<T>,
  prefix: string | null = null,
): void {
  let key: string;

  if (option.i18nKey) {
    key = option.i18nKey;
  } else {
    key = option.value;

    if (prefix) {
      key = `${prefix}.${key}`;
    }
  }

  Object.defineProperty(option, "text", {
    get() {
      return i18n.global.t(key).toString();
    },
  });
}
