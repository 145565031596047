export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query Statistics"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize your application performance by analyzing and improving the efficiency of your PostgreSQL queries."])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques des requêtes"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisez les performances de vos applications en analysant et améliorant l'efficacité de vos requêtes PostgreSQL."])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
      }
    }
  })
}
