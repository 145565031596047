export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Scalingo Stack"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the Linux environment used by this app."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App deployment required"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Scalingo Stack change is only effective once the app has been redeployed."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la Stack Scalingo"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'environnement Linux utilisé sur cette app."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer"])},
        "warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiment de l'app requis"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le changement de Stack Scalingo ne sera effectif uniquement apres le rédéploiment de l'app."])}
        }
      }
    }
  })
}
