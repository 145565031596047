<template>
  <ViewComponent
    :user="currentUser"
    :contractsToAccept="contractsToAccept"
    :contactNearlyOutdated="contactNearlyOutdated"
    :contactOutdated="contactOutdated"
    :contactLastUpdate="contact?.updated_at"
    :acceptContractHandler="acceptContractHandler"
    :bannerIsDisplayed="bannerIsDisplayed"
    @dismissBanner="dismissBanner"
    @startContractAcceptance="startContractAcceptance"
    @cancelContractAcceptance="cancelContractAcceptance"
    @submitContractAcceptance="(e) => acceptContractHandler.submit(e)"
  />
</template>

<script>
import { DateTime } from "luxon";
import { ref, defineComponent, onBeforeMount, onBeforeUnmount } from "vue";

import ViewComponent from "@/components/views/Welcome.vue";
import { AcceptContractHandler } from "@/lib/handlers/accept/contract";
import { isBefore } from "@/lib/utils/time";
import {
  listPlatformContractsToAccept,
  listPreviousPlatformContractsAccepted,
} from "@/store/contracts";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";
import { useSessionStore } from "@/stores/session";

export default defineComponent({
  name: "WelcomeContainer",
  components: { ViewComponent },
  setup() {
    const sessionStore = useSessionStore();
    const pocStore = usePointOfContactStore();
    const bannerIsDisplayed = ref(
      isBefore(localStorage.getItem("dismissBanner"), { weeks: 1 }),
    );

    onBeforeMount(() => {
      sessionStore.showBreadcrumbMenu();
      sessionStore.showMenu("welcome");
    });

    onBeforeUnmount(() => {
      sessionStore.hideBreadcrumbMenu();
      sessionStore.hideMenu();
    });

    async function dismissBanner() {
      localStorage.setItem("dismissBanner", DateTime.now());
      bannerIsDisplayed.value = isBefore(
        localStorage.getItem("dismissBanner"),
        { weeks: 1 },
      );
    }

    return {
      pocStore,
      sessionStore,
      dismissBanner,
      bannerIsDisplayed,
    };
  },
  data() {
    return {
      acceptContractHandler: null,
    };
  },
  computed: {
    contractsToAccept() {
      return listPlatformContractsToAccept(this.$store);
    },
    contact() {
      return this.pocStore.item;
    },
    contactNearlyOutdated() {
      if (!this.contact) {
        return false;
      }
      return this.contact.nearly_outdated;
    },
    contactOutdated() {
      if (!this.contact) {
        return false;
      }
      return this.contact.outdated;
    },
    previousContractsAcceptedPresent() {
      return (
        listPreviousPlatformContractsAccepted(this.$store).items.length > 0
      );
    },
  },
  created() {
    this.pocStore.ensure();
  },
  methods: {
    startContractAcceptance({ contract }) {
      this.acceptContractHandler = new AcceptContractHandler(
        this,
        contract,
        this.previousContractsAcceptedPresent,
      );
    },
    cancelContractAcceptance() {
      this.acceptContractHandler = null;
    },
  },
});
</script>
