<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.375 13.9633V39.375H0.625V13.9633C11.5328 6.03437 7.16641 9.225 19.3023 0.230469C19.5086 0.0773437 19.7531 0 20 0C20.1227 0 20.2453 0.01875 20.3633 0.0578125C20.393 0.0671875 20.4219 0.078125 20.4508 0.0898438C20.4797 0.102344 20.5078 0.114844 20.5359 0.129688C20.5641 0.14375 20.5914 0.159375 20.618 0.176563C20.6453 0.192969 20.6719 0.210938 20.6977 0.230469C32.5906 9.04297 28.243 5.87344 39.375 13.9633Z"
      fill="#FF7D47"
    />
    <path
      d="M39.375 13.9633V39.375H20V0C20.1227 0 20.2453 0.01875 20.3633 0.0578125C20.393 0.0671875 20.4219 0.078125 20.4508 0.0898438C20.4797 0.102344 20.5078 0.114844 20.5359 0.129688C20.5641 0.14375 20.5914 0.159375 20.618 0.176563C20.6453 0.192969 20.6719 0.210938 20.6977 0.230469C32.5906 9.04297 28.243 5.87344 39.375 13.9633Z"
      fill="#FF7D47"
    />
    <path
      d="M31.1719 1.17188V28.75C31.1719 29.3969 30.6469 29.9219 30 29.9219H10C9.35313 29.9219 8.82812 29.3969 8.82812 28.75V1.17188C8.82812 0.525 9.35313 0 10 0H30C30.6469 0 31.1719 0.525 31.1719 1.17188Z"
      fill="#EEF4FF"
    />
    <path
      d="M31.1719 1.17188V28.75C31.1719 29.3969 30.6469 29.9219 30 29.9219H20V0H30C30.6469 0 31.1719 0.525 31.1719 1.17188Z"
      fill="#D9E6FC"
    />
    <path
      d="M18.1362 25.9298L1.86273 14.0534C1.50641 13.7933 1.0343 13.7555 0.640938 13.9553C0.247734 14.1552 0 14.559 0 15.0001V38.8282C0 39.4787 0.528672 40.0001 1.17172 40.0001C1.41688 40.0001 1.66055 39.9233 1.86555 39.7727L18.139 27.8209C18.7754 27.3534 18.778 26.3982 18.1362 25.9298Z"
      fill="#FFB454"
    />
    <path
      d="M39.3591 13.9553C38.9659 13.7554 38.4937 13.7934 38.1373 14.0534L21.8638 25.9297C21.226 26.3951 21.2205 27.3504 21.861 27.8208L38.1345 39.7726C38.3395 39.9231 38.5831 39.9999 38.8283 39.9999C39.4715 39.9999 40 39.4783 40 38.828V15C40 14.5589 39.7523 14.1552 39.3591 13.9553Z"
      fill="#FFB454"
    />
    <path
      d="M0.058606 39.193C0.216419 39.6742 0.665637 40 1.17189 40H38.8281C39.3344 40 39.7836 39.6742 39.9414 39.193C40.0992 38.7117 39.9305 38.1836 39.5219 37.8836L20.6938 24.0555C20.2813 23.7523 19.7188 23.7523 19.3063 24.0555L0.478137 37.8836C0.0695435 38.1836 -0.0992066 38.7117 0.058606 39.193Z"
      fill="#FF7D47"
    />
    <path
      d="M0.058606 39.193C0.216418 39.6742 0.665637 40 1.17189 40H20V23.8281C19.7563 23.8281 19.5125 23.9039 19.3063 24.0555L0.478137 37.8836C0.0695435 38.1836 -0.0992065 38.7117 0.058606 39.193Z"
      fill="#FF7D47"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGPlatformEmail",
});
</script>
