<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')" />
    <div class="text-scale-8">{{ $t("text") }}</div>
    <div class="mt-4 text-scale-8">
      {{ $t("text2") }}
      <DocLink
        href="https://redis.io/topics/persistence
"
      >
        {{ $t("doclink") }}
      </DocLink>
    </div>
    <ProTipAlert icon="title" class="mt-4">
      {{ $t("protip") }}
    </ProTipAlert>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";

export default defineComponent({
  name: "PersistenceCard",
  components: {
    CardHeader,
    DocLink,
    ProTipAlert,
  },
});
</script>

<i18n>
en:
  title: "Scalingo for Redis® Configuration"
  subtitle: "Redis® persistence strategy"
  text: "Redis® has different persistency models according to the prefered performance and reliability tradeoff. By default on Scalingo, the RDB persistency mode is enabled. These settings are global and impact all the databases of the Redis® instance."
  doclink: "official Redis® persistence documentation."
  protip: "Changing the persistency configuration of your Redis® database is instant and does not require any restart of the instance. Moreover, all the options can be turned off as quickly as they have been enabled. If you wish you can disable all the persistency options. Your Redis® instance will keep living in memory, data will be preserved until a restart operation is done (maintenance/upgrade)."
  text2: "All the modes can be combined if you need to use the advantages of different options. You can read more information in the {link}"
fr:
  title: "Configuration de Scalingo pour Redis®"
  subtitle: "Stratégie de persistance de Redis®"
  text: "Redis® dispose de différents modèles de persistance en fonction du compromis préféré entre performance et fiabilité. Par défaut sur Scalingo, le mode de persistance RDB est activé. Ces paramètres sont globaux et ont un impact sur toutes les bases de données de l'instance Redis®."
  doclink: "documentation officielle sur la persistance de Redis®."
  protip: "La modification de la configuration de persistance de votre base de données Redis® est instantanée et ne nécessite aucun redémarrage de l'instance. De plus, toutes les options peuvent être désactivées aussi rapidement qu'elles ont été activées. Si vous le souhaitez, vous pouvez désactiver toutes les options de persistance. Votre instance Redis® continuera à vivre en mémoire, les données seront préservées jusqu'à ce qu'une opération de redémarrage soit effectuée (maintenance/mise à jour)."
  text2: "Tous les modes peuvent être combinés si vous avez besoin d'utiliser les avantages de différentes options. Vous trouverez plus d'informations dans la {link}"
</i18n>
