<template>
  <Card>
    <template #header>
      <div class="border-b-2 pb-2 flex">
        <div class="flex-grow">
          <h3 class="font-bold text-xl flex">
            {{ order.quote }}
            <OrderStatus class="pl-2 h-8" :orderState="order.status" />
          </h3>
          <div class="text-scale-7">
            {{ $t("subtitle") }}
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <span class="mt-4 grid grid-cols-2 gap-4 md:gap-x-56">
        <span class="flex justify-between">
          <div class="text-scale-5 humanize">
            {{ $t("attributes.order.start_date") }}
          </div>
          <div class="font-medium pl-1">
            {{ humanizedMidDateTime(order.start_date) }}
          </div>
        </span>

        <span class="flex justify-between">
          <div class="text-scale-5 humanize">
            {{ $t("attributes.order.quote_amount") }}
          </div>
          <div class="font-medium pl-1">
            {{ formatAmount(order.quote_amount) }}
          </div>
        </span>

        <span class="flex justify-between">
          <div class="text-scale-5 humanize">
            {{ $t("attributes.order.email") }}
          </div>
          <div class="font-medium pl-1">
            {{ order.email }}
          </div>
        </span>

        <span class="flex justify-between">
          <div class="text-scale-5 humanize">
            {{ $t("attributes.order.consumed") }}
          </div>
          <div
            v-if="order.consumed"
            class="font-medium pl-1 flex justify-between"
          >
            {{ formatAmount(order.consumed) }}

            <div class="ml-2">
              ({{ computePercentOf(order.quote_amount, order.consumed) }})
            </div>
          </div>
        </span>
      </span>
    </template>
    <template v-if="order.message_to_user" #footer>
      <SCAlert class="mt-4">
        <p>{{ order.message_to_user }}</p>
      </SCAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import Card from "@/components/molecules/card/Card.vue";
import OrderStatus from "@/components/parts/billing/showBilling/OrderStatus.vue";
import { computePercentOf } from "@/lib/utils/computations";
import { formatAmount } from "@/lib/utils/currency";
import { humanizedMidDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "OrderSummary",
  components: { OrderStatus, Card, SCAlert },
  props: {
    order: Object,
  },
  methods: {
    formatAmount,
    humanizedMidDateTime,
    computePercentOf,
  },
});
</script>

<i18n>
en:
  subtitle: "Order details"
fr:
  subtitle: "Détails de la commande"
</i18n>
