import {
  App,
  Container,
  Alert,
  AlertWithNotifiers,
} from "scalingo/lib/models/regional";
import { UpdateParams } from "scalingo/lib/params/regional/alerts";

import { CreateAlertHandler } from "@/lib/handlers/create/alert";
import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { updateAlert } from "@/store/alerts";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  metric: string;
  duration: number;
  containerType: string;
  is: "greater" | "lower";
  limit: number;
}

export class EditAlertHandler extends FormHandler<Alert> {
  errorFieldsMapping = { duration_before_trigger: "duration" };
  keyPath = "alerts.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly alert: AlertWithNotifiers,
    readonly containers: Container[],
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    const metric = this.alert.metric;
    const duration = this.alert.duration_before_trigger / (60 * 1000000000);
    const is = this.alert.send_when_below ? "lower" : "greater";
    const limit = CreateAlertHandler.isResourceConsumption(metric)
      ? this.alert.limit * 100
      : this.alert.limit;

    return {
      metric,
      limit,
      duration,
      is,
      containerType: this.alert.container_type,
    };
  }

  dispatchEvents(): void {
    this.on("success", (alert) => {
      this.notify("success");

      this.$router.push({
        name: Routes.App.Alerts.Edit.Notifiers,
        params: {
          id: this.app.name,
          region: this.app.region,
          alertId: alert.id,
        },
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    const payload: UpdateParams = {
      container_type: event.containerType,
      limit: CreateAlertHandler.transformLimit(event.limit, event.metric),
      metric: event.metric,
      send_when_below: event.is === "lower",
      duration_before_trigger:
        parseFloat(String(event.duration)) * 60 * 1000000000, // minutes to nanoseconds
      notifiers: this.alert.notifiers,
      disabled: this.alert.disabled,
    } as UpdateParams;

    this.follow(await updateAlert(this.$store, this.alert, payload));
  }
}
