import Client from "scalingo";
import { Addon } from "scalingo/lib/models/regional";

import { App } from "@/lib/scalingo/modifiers/apps";

/**
 * The fourth step of the one click deploy consists in waiting for all the addons to be provisionned.
 */
export async function ocdStep4(
  client: Client,
  app: App,
  addons: Addon[],
): Promise<App> {
  const promises = addons.map((addon) => watchOneAddon(client, app, addon));

  await Promise.all(promises);

  return app;
}

function watchOneAddon(client: Client, app: App, addon: Addon): Promise<Addon> {
  let intervalId: NodeJS.Timeout | null = null;

  return new Promise<Addon>((resolve) => {
    intervalId = setInterval(async () => {
      const fetchedAddon = await client.Addons.getAddon(app.id, addon.id);

      if (fetchedAddon.status === "running") {
        if (intervalId) clearInterval(intervalId);
        resolve(fetchedAddon);
      }
    }, 2000);
  });
}
