import {
  App,
  Domain as DomainModel,
  SslStatus,
} from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/domains";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  CREATE,
  DESTROY,
  FETCH,
  HANDLE_FETCH,
  HANDLE_OPERATION,
  REFRESH,
  UPDATE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { ADD, DELETE, SET_ALL, SET_ONE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { isInThePast } from "@/lib/utils/time";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

type DomainManualAction = false | "dns01" | "dnsConfig";
type AcmeError = false | "challenge" | "dnsProvider" | "generic";
type DetailedSSLStatus =
  | SslStatus
  | "manualActionRequired"
  | "willSoonExpire"
  | "expired"
  | "successLetsEncrypt";

export type Domain = DomainModel & {
  _requireManualAction: DomainManualAction;
  _acmeError: AcmeError;
  _willSoonExpire: boolean;
  _isExpired: boolean;
  _detailedSSLStatus: DetailedSSLStatus;
};

export function enhanceDomain(domain: DomainModel): Domain {
  let requireManualAction: DomainManualAction = false;
  let acmeError: AcmeError = false;
  let willExpireSoon = false;
  let isExpired = false;
  let detailedSSLStatus: DetailedSSLStatus;

  if (domain.validity) {
    isExpired = isInThePast(domain.validity);

    willExpireSoon =
      !domain.letsencrypt && isInThePast(domain.validity, { months: 3 });
  }

  if (domain.ssl_status !== "success") {
    if (
      (domain.letsencrypt_status === "pending_dns" ||
        domain.letsencrypt_status === "dns_required") &&
      domain.acme_dns_value
    ) {
      requireManualAction = "dns01";
    } else if (
      domain.letsencrypt_status === "pending_dns" &&
      domain.ssl_status === "pending"
    ) {
      requireManualAction = "dnsConfig";
    } else if (domain.ssl_status === "error") {
      if (domain.acme_challenge_error) {
        acmeError = "challenge";
      } else if (domain?.acme_dns_error?.variables?.length) {
        acmeError = "dnsProvider";
      } else {
        acmeError = "generic";
      }
    }
  }

  if (isExpired) {
    detailedSSLStatus = "expired";
  } else if (willExpireSoon) {
    detailedSSLStatus = "willSoonExpire";
  } else if (requireManualAction) {
    detailedSSLStatus = "manualActionRequired";
  } else if (domain.ssl_status === "success" && domain.letsencrypt) {
    detailedSSLStatus = "successLetsEncrypt";
  } else {
    detailedSSLStatus = domain.ssl_status;
  }

  return {
    ...domain,
    _requireManualAction: requireManualAction,
    _willSoonExpire: willExpireSoon,
    _isExpired: isExpired,
    _acmeError: acmeError,
    _detailedSSLStatus: detailedSSLStatus,
  };
}

export function enhanceDomains(domains: DomainModel[]): Domain[] {
  return domains.map(enhanceDomain);
}

export class DomainsStore extends CollectionStore<Domain> {
  actions = CollectionStore.buildActions<Domain>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context, currentApp.region).Domains.for(
          currentApp.id,
        ),
        resolveAction: (coll: DomainModel[]) => {
          context.commit(SET_ALL, enhanceDomains(coll));
        },
      });
    },
    [FETCH](context, id) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Domains.show(
          currentApp.id,
          id,
        ),
        resolveAction: (domain: DomainModel) => {
          context.commit(ADD, enhanceDomain(domain));
        },
      });
    },
    [CREATE](context, payload = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Domains.create(
          currentApp.id,
          payload,
        ),
        resolveAction: (domain: DomainModel) => {
          context.commit(ADD, enhanceDomain(domain));
        },
      });
    },
    [UPDATE](context, payload) {
      const currentApp = useCurrentAppStore().regional as App;

      const promise = scalingoClient(context, currentApp.region).Domains.update(
        currentApp.id,
        payload.id,
        payload,
      );
      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: (domain: DomainModel) => {
          context.commit(SET_ONE, enhanceDomain(domain));
        },
      });
    },
    [DESTROY](context, { domain }) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Domains.destroy(
          currentApp.id,
          domain.id,
        ),
        resolveAction: () => context.commit(DELETE, domain),
      });
    },
  });
  mutations = CollectionStore.buildMutations<Domain>();
  getters = CollectionStore.buildGetters<Domain>();
}

export const Domains = buildMapping(new DomainsStore(), "domains");

export function listDomains(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Domain>>,
): CollectionWithFetch<Domain> {
  return {
    items: listItems(store.getters[Domains.getters.ALL], opts),
    latestFetch: store.getters[Domains.getters.LATEST_FETCH],
  };
}

export function createDomain(
  store: ApplicationStore,
  payload: CreateParams,
): Promise<RemoteOperation<Domain>> {
  return store.dispatch(Domains.actions.CREATE, payload);
}

export function deleteDomain(
  store: ApplicationStore,
  domain: DomainModel,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Domains.actions.DESTROY, { domain });
}

export function makeCanonical(
  store: ApplicationStore,
  domain: DomainModel,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Domains.actions.UPDATE, {
    id: domain.id,
    canonical: true,
  });
}

export function unmakeCanonical(
  store: ApplicationStore,
  domain: DomainModel,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Domains.actions.UPDATE, {
    id: domain.id,
    canonical: false,
  });
}

export function editDomainSSL(
  store: ApplicationStore,
  domain: DomainModel,
  tlscert: string,
  tlskey: string,
): Promise<RemoteOperation<DomainModel>> {
  return store.dispatch(Domains.actions.UPDATE, {
    id: domain.id,
    tlscert,
    tlskey,
  });
}

export function refreshDomains(
  store: ApplicationStore,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Domains.actions.REFRESH);
}

export function ensureDomains(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(Domains.actions.ENSURE, opts);
}

export function fetchDomain(
  store: ApplicationStore,
  id: string,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Domains.actions.FETCH, id);
}

export function ensureDomain(
  store: ApplicationStore,
  id: string,
): Promise<Domain> {
  return store.dispatch(Domains.actions.ENSURE_ONE, id);
}

export function findDomain(store: ApplicationStore, id: string): Domain {
  return store.getters[Domains.getters.FIND](id);
}
