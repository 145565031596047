<template>
  <RangeSelectors
    class="place-items-center"
    size="md"
    :items="items"
    :selectedValue="selectedValue"
    @selected="(e) => $emit('rangeSelected', e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import RangeSelectors from "@/components/atoms/selectors/RangeSelectors.vue";

export default defineComponent({
  name: "GroupBySelector",
  components: { RangeSelectors },
  props: {
    selectedValue: String,
  },
  emits: ["rangeSelected"],
  computed: {
    items() {
      return [
        {
          value: "app",
          text: this.$t("app"),
          title: this.$t("app"),
        },
        {
          value: "resource",
          text: this.$t("resource"),
          title: this.$t("resource"),
        },
      ];
    },
  },
});
</script>

<i18n>
  en:
    app: "App"
    region: "Region"
    resource: "Resource"
  fr:
    app: "App"
    region: "Région"
    resource: "Ressource"
</i18n>
