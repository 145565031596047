<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 9.08V8.92C21.0004 8.75501 20.9177 8.60091 20.78 8.51L13.11 3.26C12.8625 3.0908 12.5698 3.00019 12.27 3H11.73C11.4302 3.00019 11.1375 3.0908 10.89 3.26L3.22 8.51C3.08232 8.60091 2.99963 8.75501 3 8.92V9.08C2.99963 9.24499 3.08232 9.39909 3.22 9.49L10.89 14.74C11.1375 14.9092 11.4302 14.9998 11.73 15H12.27C12.5698 14.9998 12.8625 14.9092 13.11 14.74L20.78 9.49C20.9177 9.39909 21.0004 9.24499 21 9.08ZM16.9 14.27L18.67 13.06L20.78 14.51C20.9177 14.6009 21.0004 14.755 21 14.92V15.08C21.0004 15.245 20.9177 15.3991 20.78 15.49L13.11 20.74C12.8625 20.9092 12.5698 20.9998 12.27 21H11.73C11.4302 20.9998 11.1375 20.9092 10.89 20.74L3.22 15.49C3.08232 15.3991 2.99963 15.245 3 15.08V14.92C2.99963 14.755 3.08232 14.6009 3.22 14.51L5.33 13.06L7.06 14.27L6 15L11.93 19H12.15L18 15L16.9 14.27Z"
      fill="currentColor"
    />
    <path
      d="M24 17C24 20.3137 21.3137 23 18 23C14.6863 23 12 20.3137 12 17C12 13.6863 14.6863 11 18 11C21.3137 11 24 13.6863 24 17Z"
      fill="white"
    />
    <path
      d="M18 12C15.2386 12 13 14.2386 13 17C13 19.7614 15.2386 22 18 22C20.7614 22 23 19.7614 23 17C23 14.2386 20.7614 12 18 12ZM18 14.1429C18.3943 14.1429 18.7143 14.4621 18.7143 14.8571V16.2857H20.1429C20.5371 16.2857 20.8571 16.605 20.8571 17C20.8571 17.395 20.5371 17.7143 20.1429 17.7143H18.7143V19.1429C18.7143 19.5379 18.3943 19.8571 18 19.8571C17.6057 19.8571 17.2857 19.5379 17.2857 19.1429V17.7143H15.8571C15.4629 17.7143 15.1429 17.395 15.1429 17C15.1429 16.605 15.4629 16.2857 15.8571 16.2857H17.2857V14.8571C17.2857 14.4621 17.6057 14.1429 18 14.1429Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppAddGlyph",
});
</script>
