export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "contractName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " – version ", _interpolate(_named("version"))])},
        "acceptedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["accepted on ", _interpolate(_named("date"))])},
        "refusedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["refused on ", _interpolate(_named("date")), ": ", _interpolate(_named("reason"))])}
      },
      "fr": {
        "contractName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " – version ", _interpolate(_named("version"))])},
        "acceptedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["accepté(e)(s) le ", _interpolate(_named("date"))])},
        "refusedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["refusé(e)(s) le ", _interpolate(_named("date")), ": ", _interpolate(_named("reason"))])}
      }
    }
  })
}
