<template>
  <div>
    <div class="flex-col hidden ph:flex">
      <VerticalMenuItem
        v-for="(item, index) in items"
        :key="index"
        :class="classFn(index)"
        :item="item"
      ></VerticalMenuItem>
    </div>
    <div class="flex ph:hidden">
      <SelectInput
        class="w-full"
        :options="items"
        :modelValue="selection"
        :withIcon="true"
        @update:modelValue="redirect"
      >
        <template v-slot:icon>
          <MenuGlyph class="text-scale-4"></MenuGlyph>
        </template>
      </SelectInput>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import MenuGlyph from "@/components/atoms/glyphs/MenuGlyph.vue";
import SelectInput from "@/components/atoms/inputs/SelectInput.vue";
import VerticalMenuItem from "@/components/atoms/menuItems/VerticalMenuItem.vue";

export default defineComponent({
  name: "VerticalPageMenu",
  components: { MenuGlyph, SelectInput, VerticalMenuItem },
  props: {
    items: Array,
  },
  computed: {
    selection() {
      const item = this.items.find((item) => item.selected);
      if (item) {
        return item.value;
      }
      return "";
    },
  },
  methods: {
    redirect(value) {
      const item = this.items.find((item) => item.value === value);
      if (item) {
        this.$router.push(item.routerArgs);
      }
    },
    classFn: function (index) {
      return {
        "pt-3": index > 0,
      };
    },
  },
});
</script>
