<template>
  <svg
    :class="{ animated }"
    width="24"
    height="24"
    viewBox="0 0 22 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.71985 8.15015L7.91985 8.35036C8.01451 8.44434 8.06775 8.57227 8.06775 8.70572C8.06775 8.83918 8.01451 8.96711 7.91985 9.06109L4.38985 12.6047C4.19204 12.7967 3.87767 12.7967 3.67985 12.6047L0.149852 9.06109C0.055196 8.96711 0.00195312 8.83918 0.00195312 8.70572C0.00195312 8.57227 0.055196 8.44434 0.149852 8.35036L0.349852 8.15015C0.443928 8.0524 0.574266 7.99803 0.709852 8H2.99985C3.00278 5.14041 4.52873 2.49937 7.0034 1.07084C9.47808 -0.357682 12.5259 -0.356898 14.9999 1.0729C15.1384 1.1502 15.2342 1.28658 15.2599 1.44328C15.2811 1.59759 15.2293 1.75301 15.1199 1.86371L14.3799 2.60447C14.2231 2.75858 13.9854 2.79488 13.7899 2.69456C12.9329 2.23139 11.9737 1.99048 10.9999 1.99384C7.68614 1.99384 4.99985 4.68289 4.99985 8H7.35985C7.49544 7.99803 7.62578 8.0524 7.71985 8.15015ZM21.6499 7.84985L21.8499 7.64964C21.9445 7.55566 21.9978 7.42773 21.9978 7.29428C21.9978 7.16082 21.9445 7.03289 21.8499 6.93891L18.3199 3.39528C18.122 3.2033 17.8077 3.2033 17.6099 3.39528L14.0799 6.93891C13.9852 7.03289 13.932 7.16082 13.932 7.29428C13.932 7.42773 13.9852 7.55566 14.0799 7.64964L14.2799 7.84985C14.3739 7.9476 14.5043 8.00197 14.6399 8H16.9999C16.9999 9.59293 16.3677 11.1206 15.2425 12.247C14.1173 13.3734 12.5912 14.0062 10.9999 14.0062C10.0308 14.0064 9.07636 13.7692 8.21985 13.3154C8.02488 13.2118 7.78509 13.2484 7.62985 13.4055L6.89985 14.1363C6.79037 14.247 6.73862 14.4024 6.75985 14.5567C6.781 14.7097 6.86892 14.8454 6.99985 14.9271C9.47379 16.3569 12.5216 16.3577 14.9963 14.9292C17.471 13.5006 18.9969 10.8596 18.9999 8H21.2899C21.4254 8.00197 21.5558 7.9476 21.6499 7.84985Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReloadGlyph",
  props: {
    animated: Boolean,
  },
});
</script>

<style scoped>
.animated {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
