import { ActionHandler } from "@/lib/handlers/action";
import { resetDeploymentCache } from "@/store/deployments";

export class ResetDeploymentCacheHandler extends ActionHandler<void> {
  keyPath = "deploymentCache.delete";

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(await resetDeploymentCache(this.$store));
  }
}
