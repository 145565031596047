export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App edited"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
        "forceHttp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force HTTPS is now"])},
        "stickySession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sticky session is now"])},
        "routerLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router logs are now"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App editée"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activée"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivée"])},
        "forceHttp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction forcer HTTPS est maintenant"])},
        "stickySession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction sessions persistantes est maintenant"])},
        "routerLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction logs routeur est maintenant"])}
      }
    }
  })
}
