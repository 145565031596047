<template>
  <SCCard :title="domain.name" :subtitle="subtitle">
    <template #beforeTitle>
      <DomainIndicator class="inline-block mr-2" :domain="domain" />
    </template>

    <SCAlert
      v-if="domain._requireManualAction"
      :title="$t('instructions.title')"
    >
      <template v-if="domain._requireManualAction === 'dns01'">
        <p>{{ $t("instructions.dns01.line1") }}</p>
        <p>{{ $t("instructions.dns01.line2") }}</p>
        <br />
        <p>
          {{ $t("instructions.dns01.line3") }}

          <DocLink
            href="https://doc.scalingo.com/platform/app/domain#app-configuration"
          >
            {{ $t("instructions.dns01.line4") }}
          </DocLink>
        </p>
        <br />
        <table class="font-mono">
          <tbody>
            <tr class="text-left font-medium pr-4">
              <th class="pr-4">{{ $t("labels.name") }}</th>
              <th class="pr-4">{{ $t("labels.ttl") }}</th>
              <th class="pr-4">{{ $t("labels.type") }}</th>
              <th class="pr-4">{{ $t("labels.value") }}</th>
            </tr>
            <tr>
              <td class="pr-4">{{ domain.acme_dns_fqdn }}</td>
              <td class="pr-4">120</td>
              <td class="pr-4">TXT</td>
              <td class="pr-4">{{ domain.acme_dns_value }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else-if="domain._requireManualAction === 'dnsConfig'">
        <p>{{ $t("instructions.dns.line1") }}</p>
        <p>{{ $t("instructions.dns.line2") }}</p>
        <br />
        <p>
          <DocLink
            class="mt-2"
            href="https://doc.scalingo.com/platform/app/domain#configure-your-domain-name"
          >
            {{ $t("instructions.dns.line3") }}
          </DocLink>
        </p>
      </template>
    </SCAlert>

    <SCAlert
      v-else-if="domain._acmeError === 'challenge'"
      kind="error"
      icon="title"
      :title="$t('errors.challengeError.title')"
    >
      <template #icon>
        <WarningSignGlyph />
      </template>

      <p>{{ $t("errors.challengeError.line1") }}</p>
      <br />
      <code class="text-sm">
        {{ domain.acme_challenge_error }}
      </code>
    </SCAlert>

    <SCAlert
      v-else-if="domain._acmeError === 'dnsProvider'"
      kind="error"
      icon="title"
      :title="$t('errors.dns01error.title')"
    >
      <template #icon>
        <WarningSignGlyph />
      </template>

      <p>{{ $t("errors.dns01error.line1") }}</p>
      <p>
        {{
          $t("errors.dns01error.line2", {
            provider: domain.acme_dns_error.dns_provider,
          })
        }}
      </p>

      <code class="block my-2 pl-4 text-sm"> {{ acmeDNSErrorVariables }}</code>

      <p>{{ $t("errors.dns01error.line3") }}</p>
    </SCAlert>

    <SCAlert
      v-else-if="domain._acmeError === 'generic'"
      kind="error"
      icon="title"
      :title="$t('errors.generic.title')"
    >
      <template #icon>
        <WarningSignGlyph />
      </template>

      <p>{{ $t("errors.generic.line1") }}</p>
    </SCAlert>

    <SCKVList>
      <SCKVItem :desc="$t('labels.sslStatus')">
        <template v-if="domain._detailedSSLStatus === 'error'">
          <WarningSignGlyph class="text-error mr-2 self-center" />

          {{ $ta("domain", "sslStatuses.error") }}
        </template>

        <template v-if="domain._detailedSSLStatus === 'expired'">
          <WarningSignGlyph class="text-error mr-2 self-center" />

          {{ $ta("domain", "sslStatuses.expired") }}
        </template>

        <template v-else-if="domain._detailedSSLStatus === 'pending'">
          {{ $ta("domain", "sslStatuses.pending") }}
        </template>

        <template v-else-if="domain._detailedSSLStatus === 'willSoonExpire'">
          <WarningSignGlyph class="text-warning mr-2 self-center" />

          {{ $ta("domain", "sslStatuses.willSoonExpire") }}
        </template>

        <template
          v-else-if="domain._detailedSSLStatus === 'manualActionRequired'"
        >
          <WarningSignGlyph class="text-warning mr-2 self-center" />

          {{ $ta("domain", "sslStatuses.manualActionRequired") }}
        </template>

        <template v-else-if="domain._detailedSSLStatus === 'success'">
          {{ $ta("domain", "sslStatuses.success") }}
        </template>

        <template
          v-else-if="domain._detailedSSLStatus === 'successLetsEncrypt'"
        >
          {{ $ta("domain", "sslStatuses.successLetsEncrypt") }}
        </template>
      </SCKVItem>

      <SCKVItem v-if="domain.validity" :desc="$t('labels.validUntil')">
        {{ validity }}
      </SCKVItem>

      <SCKVItem v-if="domain.tlscert" :desc="$t('labels.tlscert')">
        <code class="font-mono">{{ domain.tlscert }}</code>
      </SCKVItem>

      <SCKVItem v-if="domain.tlskey" :desc="$t('labels.tlskey')">
        {{ domain.tlskey }}
      </SCKVItem>
    </SCKVList>
  </SCCard>
</template>

<script>
import { defineComponent } from "vue";

import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCCard from "@/components/molecules/card/SCCard.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SCKVItem from "@/components/molecules/lists/SCKVItem.vue";
import SCKVList from "@/components/molecules/lists/SCKVList.vue";
import DomainIndicator from "@/components/parts/appSettings/domainsSettings/DomainIndicator.vue";
import { formatDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "DomainCard",
  components: {
    SCAlert,
    SCCard,
    SCKVList,
    SCKVItem,
    DocLink,
    DomainIndicator,
    WarningSignGlyph,
  },
  mixins: [ModelsTranslation],
  props: {
    app: Object,
    domain: Object,
  },
  computed: {
    subtitle() {
      return this.$t("subtitle", {
        date: formatDateTime(this.domain.created_at),
      });
    },
    validity() {
      return this.domain?.validity ? formatDateTime(this.domain.validity) : "";
    },
    acmeDNSErrorVariables() {
      return (this.domain?.acme_dns_error?.variables || []).join(", ");
    },
  },
});
</script>

<i18n>
en:
  title: "Domains"
  subtitle: "Added on {date}"
  labels:
    sslStatus: "SSL Status"
    validUntil: "Valid until"
    tlscert: "Certificate info"
    tlskey: "Key info"
    name: "Name"
    ttl: "TTL"
    type: "Type"
    value: "Value"
  instructions:
    title: "Manual action required"
    dns:
      line1: "You need to configure your domain name to make it point towards Scalingo."
      line2: "This can take some time, so do not worry if the changes are not immediately picked up."
      line3: "Read our documentation page on the topic"
    dns01:
      line1: "In order to generate a Let’s Encrypt certificate for this wildcard domain, you need to create a TXT record in your zone."
      line2: "This allows Let’s Encrypt to know that you are the owner of the wildcard domain."
      line3: "Below, you will find the configuration that you should apply."
      line4: "You can also read our documentation page on the topic"
  errors:
    dns01error:
      title: "Configuration error"
      line1: "We were not able to update your wildcard domain configuration."
      line2: "It seems that the configuration for the DNS provider {provider} is wrong. Please make sure the following environment variables are correctly set:"
      line3: "If everything looks alright to you, reach us through the support to resolve the situation."
    challengeError:
      title: "Generation error"
      line1: "Let’s Encrypt failed to generate the SSL certificate. The error was:"
    generic:
      title: "Generation error"
      line1: "Let’s Encrypt failed to generate the SSL certificate; please contact us through the support to resolve the situation."
fr:
  title: "Domaines"
  subtitle: "Ajouté le {date}"
  labels:
    sslStatus: "Statut SSL"
    validUntil: "Valide jusqu'au"
    tlscert: "Infos du certificat"
    tlskey: "Infos de la clé"
    name: "Nom"
    ttl: "TTL"
    type: "Type"
    value: "Valeur"
  instructions:
    title: "Action manuelle requise"
    dns:
      line1: "Vous devez configurer votre nom de domaine afin qu'il pointe vers les serveurs Scalingo."
      line2: "Cette opération n'est pas toujours immédiate, il se peut que vous voyez encore ce message alors que votre configuration est correcte."
      line3: "Se référer a notre page de documentation sur le sujet"
    dns01:
      line1: "Afin de générer un certificat Let’s Encrypt certificate pour ce domaine wildcard, vous devez ajouter un champ TXT à votre zone DNS."
      line2: "Ceci permet à Let’s Encrypt de vérifier que vous êtes bien propriétaire du nom de domaine."
      line3: "Ci dessous, vous trouverez la configuration à appliquer."
      line4: "Vous pouvez également consulter notre documentation sur le sujet"
  errors:
    dns01error:
      title: "Erreur de configuration"
      line1: "Nous n'avons pas pu mettre à jour la configuration de votre domaine wildcard."
      line2: "Il semble que la configuration pour votre fournisseur DNS {provider} soit erronée. Si ce n'est pas le cas, contactez-nous au support."
      line3: "Veuillez vérifier que les variables d'environnement suivantes soit présentes et exactes:"
    challengeError:
      title: "Erreur de génération"
      line1: "Let’s Encrypt n'a pas pu générer le certificat. L'erreur est la suivante:"
    generic:
      title: "Erreur de génération"
      line1: "Let’s Encrypt n'a pas pu générer le certificat. Veuillez nous contacter au support pour régler la situation."
</i18n>
