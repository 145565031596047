export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Type"])},
        "addon_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon ID"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])}
      },
      "fr": {
        "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Base de Données"])},
        "addon_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'Addon"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])}
      }
    }
  })
}
