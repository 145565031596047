<template>
  <EventSwitch
    :event="event"
    :long="true"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :eventComplement="eventComplement"
    @bodyOpened="(e) => $emit('bodyOpened', e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import EventSwitch from "@/components/organisms/events/EventSwitch.vue";

export default defineComponent({
  name: "EventsSwitch",
  components: { EventSwitch },
  props: {
    event: Object,
    long: Boolean,
    eventsComplements: Array,
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["bodyOpened"],
  computed: {
    eventComplement() {
      return this.eventsComplements.find(
        (complement) => complement.eventId === this.event.id,
      );
    },
  },
});
</script>
