<template>
  <span
    :class="{ 'shadow-sm': !noShadow }"
    class="inline-flex rounded-md"
    :title="title"
  >
    <router-link
      v-if="internalLink"
      :class="styling"
      class="rounded-md px-4 py-1 self-center"
      :disabled="isDisabled"
      :to="routeArgs"
    >
      <slot></slot>
    </router-link>

    <a
      v-else-if="externalLink"
      :class="styling"
      class="rounded-md px-4 py-1 self-center"
      v-bind="routeArgs.tagAttrs"
    >
      <slot></slot>
    </a>

    <button
      v-else
      :type="buttonType"
      :class="classFn"
      :disabled="isDisabled"
      class="rounded-md px-4 py-1 self-center"
      @click="clicked"
    >
      <div v-if="isLoading" class="w-24 h-6 flex justify-center items-center">
        <Spinner></Spinner>
      </div>
      <span v-if="!isLoading">
        <slot></slot>
      </span>
    </button>
  </span>
</template>

<script>
/**
 * Three behaviors possible, by order of precedence:
 * - routeArgs null or undefined: <button> without a link
 * - routeArgs contains a property `noRouter` set to true: <a> tag, with routeArgs.tagAttrs forwarded to the tag
 * - Otherwise, assuming routeArgs is a vuerouter compatible object.
 */

import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";

export default defineComponent({
  name: "TextButton",
  components: { Spinner },
  props: {
    routeArgs: Object,
    styling: String,
    buttonStyle: String,
    noShadow: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,
    grow: Boolean,
    title: String,
    buttonType: {
      type: String,
      default: "button",
    },
    size: {
      type: String,
      default: "",
    },
  },
  emits: ["clicked"],
  computed: {
    noLink() {
      return this.routeArgs === null || this.routeArgs === undefined;
    },
    internalLink() {
      return !this.noLink && this.routeArgs && !this.routeArgs.noRouter;
    },
    externalLink() {
      return !this.noLink && !this.internalLink;
    },
    classFn: function () {
      let classValue = this.styling;
      if (this.size !== "") {
        classValue += " " + this.size;
      }

      if (this.isDisabled === true || this.isLoading) {
        classValue += " opacity-50 cursor-not-allowed";
      }
      return classValue;
    },
  },
  methods: {
    clicked() {
      if (this.isDisabled === true || this.isLoading) {
        return;
      }
      this.$emit("clicked");
    },
  },
});
</script>

<style scoped>
/*This classes exist because they have special color not present in the default palette */

.bg-primary-special {
  background: linear-gradient(
    180deg,
    var(--primary-2-125) 0%,
    var(--primary-2-90) 100%
  );
}
.bg-primary-special:not([disabled]):hover {
  background: var(--primary-2-135);
}
.bg-tertiary-special {
  background: #f17a3d;
}
.bg-tertiary-special:not([disabled]):hover {
  background: #e95a11;
}

.text-tertiary-special {
  color: #f17a3d;
}
.text-tertiary-special:not([disabled]):hover {
  color: #e95a11;
}
</style>
