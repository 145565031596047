<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import { Routes } from "@/router/names";
import { navErrorHandler } from "@/router/utils";

export default defineComponent({
  props: {
    id: String,
    group: {
      type: String,
      default: "chart-group",
    },
    chartData: Array,
    eventsAnnotations: Array,
    container: Object,
    index: Number,
    displayInFullScreen: Boolean,
  },
  data() {
    return {
      unwatchAnnotations: null,
    };
  },
  computed: {
    hasIndex() {
      return this.index !== undefined && this.index !== null;
    },
  },
  unmounted() {
    this.unwatchAnnotations?.();
  },
  methods: {
    chartDefaults() {
      return {
        chart: {
          type: "area",
          id: this.id,
          group: this.group,
          animations: {
            enabled: false,
          },
          events: {
            mounted: () => {
              this.unwatchAnnotations = this.$watch(
                "eventsAnnotations",
                (annotations) => {
                  const chart = this.$refs.apex?.chart;

                  if (chart) {
                    chart.clearAnnotations();

                    annotations.forEach((annotation) =>
                      chart.addXaxisAnnotation(annotation),
                    );
                  }
                },
                { immediate: true },
              );
            },
          },
          toolbar: this.toolbarConfig(),
        },
        title: {
          text: this.title,
        },
        legend: {
          horizontalAlign: "right",
          showForSingleSeries: true,
        },
        tooltip: {
          x: {
            show: false,
            formatter: (val) => DateTime.fromMillis(val).toFormat("D, t"),
          },
          style: {
            fontSize: "11px",
          },
          fixed: {
            enabled: true,
            position: "topLeft",
            offsetX: 70,
            offsetY: 30,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "stepline",
          width: 1,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
        },
      };
    },
    toolbarConfig() {
      const toolbar = {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
      };

      if (!this.hasIndex && this.container && this.container.amount > 1) {
        toolbar.tools.customIcons.push({
          icon: `<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-eye fa-w-18"><path fill="currentColor" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z" class=""></path></svg>`,
          index: -1,
          title: "See container details",
          click: () => {
            const query = { ...this.$route.query };

            if (this.displayInFullScreen) {
              query.fullscreen = "true";
            } else {
              delete query.fullscreen;
            }

            const targetRoute = {
              name: Routes.App.Metrics.ApexPerContainer,
              params: { containerType: this.container.name },
              query,
            };

            this.$router.push(targetRoute).catch(navErrorHandler);
          },
        });
      }

      return toolbar;
    },
  },
});
</script>
