<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')"> </CardHeader>
    </template>
    <template #body>
      <h2 class="text-lg font-medium text-scale-9 mb-4">
        {{ $t("earn.title") }}
      </h2>
      <p class="mb-4">
        {{ $t("earn.description") }}
      </p>
      <h2 class="text-lg font-medium text-scale-9 mb-4">
        {{ $t("share.title") }}
      </h2>
      <div class="flex flex-row items-center space-x-2">
        <p>
          {{ $t("share.description") }}
          <span class="font-medium">
            {{ user.referral_url }}
          </span>
        </p>
        <CopyButton @click="$emit('copyReferralLink')"></CopyButton>
      </div>
      <div class="flex flex-row items-center mt-2">
        <SCButton :a="twitterLink" kind="neutral">
          <div class="flex flex-row items-center">
            <font-awesome-icon
              :icon="['fab', 'twitter']"
              class="mr-2 h-4 w-4"
            />
            <span>{{ $t("actions.twitter") }}</span>
          </div>
        </SCButton>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import CopyButton from "@/components/organisms/buttons/CopyButton.vue";

export default defineComponent({
  name: "ProgramCard",
  components: {
    CopyButton,
    Card,
    CardHeader,
    SCButton,
  },
  props: {
    user: Object,
  },
  emits: ["copyReferralLink"],
  computed: {
    twitterLink() {
      const linkWithUtms =
        this.user.referral_url +
        "?utm_source=twitter&utm_medium=social-network&utm_campaign=referral";
      const content = this.$t("share.tweet", { link: linkWithUtms });
      const encoded = encodeURIComponent(content);

      // url=/ to avoid referer to be present in the tweet
      return {
        target: "_blank",
        href: `http://twitter.com/share?text=${encoded}&url=/`,
      };
    },
  },
});
</script>

<i18n>
en:
  title: "Referral program"
  earn:
    title: "Earn 20% of what they use"
    description: "If a person signs up with your referral link and spends something on the platform, each month you'll get 20% of what they spent in credit, during 12 months after their account creation."
  share:
    title: "Share you referral link"
    description: "Copy your personal referral link and share it with your friends and followers: "
    tweet: "Easily deploy your app on {'@'}ScalingoHQ in less than 2 minutes. Sign up with my link {link} #PaaS"
  actions:
    copy: "Copy link"
    twitter: "Share on Twitter"
fr:
  title: "Programme de parrainage"
  earn:
    title: "Gagnez 20% de leur consommation"
    description: "Si une personne créée un compte avec votre lien de parrainage et utilise la plateforme, chaque mois vous gagnerez 20% de leurs dépenses en crédit (pendant les 12 premiers mois)."
  share:
    title: "Partagez votre lien de parrainage"
    description: "Copiez votre lien de parrainage et partagez le avec vos amis et followers: "
    tweet: "Déployez facilement votre app sur {'@'}ScalingoHQ en moins de 2 minutes. Inscrivez-vous avec mon lien {link} #PaaS"
  actions:
    copy: "Copier le lien"
    twitter: "Partager sur Twitter"
</i18n>
