<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C7.26333 4 6.66667 4.61023 6.66667 5.36364V6.04545H10V5.36364C10 4.61023 9.40333 4 8.66667 4H8ZM15.3333 4C14.5967 4 14 4.61023 14 5.36364V6.04545H17.3333V5.36364C17.3333 4.61023 16.7367 4 16 4H15.3333ZM5.33333 7.40909C4.59667 7.40909 4 8.01932 4 8.77273V17.6364C4 18.3898 4.59667 19 5.33333 19H18.6667C19.4033 19 20 18.3898 20 17.6364V8.77273C20 8.01932 19.4033 7.40909 18.6667 7.40909H5.33333Z"
      fill="currentColor"
    />
    <path
      d="M24 16C24 19.3137 21.3137 22 18 22C14.6863 22 12 19.3137 12 16C12 12.6863 14.6863 10 18 10C21.3137 10 24 12.6863 24 16Z"
      fill="white"
    />
    <path
      d="M18 11C15.2386 11 13 13.2386 13 16C13 18.7614 15.2386 21 18 21C20.7614 21 23 18.7614 23 16C23 13.2386 20.7614 11 18 11ZM18 13.1429C18.3943 13.1429 18.7143 13.4621 18.7143 13.8571V15.2857H20.1429C20.5371 15.2857 20.8571 15.605 20.8571 16C20.8571 16.395 20.5371 16.7143 20.1429 16.7143H18.7143V18.1429C18.7143 18.5379 18.3943 18.8571 18 18.8571C17.6057 18.8571 17.2857 18.5379 17.2857 18.1429V16.7143H15.8571C15.4629 16.7143 15.1429 16.395 15.1429 16C15.1429 15.605 15.4629 15.2857 15.8571 15.2857H17.2857V13.8571C17.2857 13.4621 17.6057 13.1429 18 13.1429Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonAddGlyph",
});
</script>
