export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current email address used by this account: ", _interpolate(_named("email"))])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update email"])},
        "newEmailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email address"])},
        "newEmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example", "@", "mail.com"])},
        "previouslySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your email address was previously changed to ", _interpolate(_named("email")), ", but it is not yet confirmed."])},
        "previouslySentResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive confirmation email? Click to resend."])},
        "successfulSubmissionResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive confirmation email? Click to resend."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'email"])},
        "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'adresse email actuellement utilisée pour ce compte: ", _interpolate(_named("email"))])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'email"])},
        "newEmailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle addresse email"])},
        "newEmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exemple", "@", "email.com"])},
        "previouslySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre adresse email a précédemment été changée pour ", _interpolate(_named("email")), ", mais elle n'est pas encore confirmée."])},
        "previouslySentResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu l'email de confirmation? Cliquez pour renvoyer."])},
        "successfulSubmissionResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu l'email de confirmation? Cliquez pour renvoyer."])}
      }
    }
  })
}
