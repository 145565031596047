<template>
  <ViewComponent
    :collaborators="uniqAndSortedCollaborators"
    :isAppOwner="isAppOwner"
  ></ViewComponent>
</template>

<script>
import { defineComponent, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/Collaborators.vue";
import { sortCollaborators } from "@/lib/utils/collaborators.ts";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";
import { useAppInfosStore } from "@/stores/app-infos";
import { useCollaboratorsStore } from "@/stores/collaborators";
import { currentUser } from "@/stores/user";

export default defineComponent({
  name: "Collaborators",
  components: { ViewComponent },
  setup() {
    const router = useRouter();
    const collaboratorsStore = useCollaboratorsStore();
    const appInfosStore = useAppInfosStore();

    onBeforeMount(() => {
      if (!userIsAdminOrHasFlag(currentUser(), "employee")) {
        router.push({ name: Routes.Welcome });
      }

      collaboratorsStore.ensure({ staleAfter: "always" });
      appInfosStore.ensure();
    });

    const isAppOwner = computed(() => {
      const { items, promiseInfo } = appInfosStore;

      if (promiseInfo.isFinished) {
        return items.length > 0;
      }

      return true;
    });

    const byCollaborator = (collaborators, currentCollaborator) => {
      const existingCollaborator = collaborators.find(
        (object) => object.email === currentCollaborator.email,
      );

      if (existingCollaborator) {
        existingCollaborator.apps.push(currentCollaborator.app_name);
      } else {
        currentCollaborator.username ??= "n/a";
        currentCollaborator.apps = [currentCollaborator.app_name];
        collaborators.push(currentCollaborator);
      }

      return collaborators;
    };

    const uniqAndSortedCollaborators = computed(() => {
      if (!collaboratorsStore.items) {
        return [];
      }
      const sortedCollaborators = sortCollaborators(collaboratorsStore.items);

      return sortedCollaborators.reduce(byCollaborator, []);
    });

    return {
      collaboratorsStore,
      isAppOwner,
      uniqAndSortedCollaborators,
    };
  },
});
</script>
