export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A secure password will be generated automatically."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un mot de passe sécurisé sera généré automatiquement."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
      }
    }
  })
}
