import { defineStore } from "pinia";

import { useCollectionStore } from "@/lib/pinia/use-collection-store-next";
import { scalingoDBClient } from "@/lib/scalingo/dbapi/client";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";

export const useDbInstancesStore = defineStore("dbInstances", () => {
  const store = useCollectionStore({
    fetchPromise: async () => {
      const appStore = useCurrentAppStore();
      const dbStore = useCurrentDBStore();

      const app = appStore.regional;
      if (!app) throw new Error("no current app");

      const addon = dbStore.addon;
      if (!addon) throw new Error("no current addon");

      const client = await scalingoDBClient(app, addon.id);

      return client.Instances.status(addon.id);
    },
  });

  return store;
});
