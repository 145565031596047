<template>
  <SCTable
    layout="fixed"
    :headers="headers"
    :items="documents"
    :showFooter="false"
  >
    <template #item.name="{ item }">
      <DocLink
        v-if="localizedAttr(item, 'web')"
        :href="localizedAttr(item, 'web')"
      >
        {{ localizedAttr(item, "name") }} ({{ localizedAttr(item, "short") }})
      </DocLink>
      <template v-else>
        {{ localizedAttr(item, "name") }} ({{ localizedAttr(item, "short") }})
      </template>
    </template>

    <template #item.actions="{ item }">
      <div class="flex justify-end space-x-3">
        <a
          v-for="(pdf, key) in actionsFor(item)"
          :key="key"
          class="flex underline hover:text-scale-10 text-scale-8 cursor-pointer"
          target="_blank"
          :href="pdf.link"
          :title="pdf.text"
        >
          <DownloadGlyph class="h-4 w-4 mr-1" />
          ({{ pdf.title }})
        </a>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import DownloadGlyph from "@/components/atoms/glyphs/DownloadGlyph";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "ContractDocuments",
  components: {
    DocLink,
    DownloadGlyph,
    SCTable,
  },
  mixins: [ModelsTranslation],
  props: {
    documents: Array,
  },
  data() {
    return {
      headers: [
        { value: "name", text: this.$t("headers.document") },
        { value: "actions", text: "", align: "right", class: "w-2/12" },
      ],
    };
  },
  methods: {
    localizedAttr(object, attr) {
      return object[attr]?.[this.$i18n.locale];
    },
    actionsFor(document) {
      const actions = [];

      for (const [loc, url] of Object.entries(document.pdf)) {
        actions.push({
          text: this.$t("actions.download"),
          link: url,
          title: loc,
        });
      }

      return actions;
    },
  },
});
</script>

<i18n>
en:
  headers:
    document: "Document name"
  actions:
    download: "Download this document"
fr:
  headers:
    document: "Nom du document"
  actions:
    download: "Télécharger le document"
</i18n>
