<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="23"
    viewBox="0 0 36 23"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <rect
        y="-0.03125px"
        width="38"
        height="26"
        fill="white"
        x="0.96875px"
      ></rect>
      <path d="M36,0 H24 V23 H36 V0 " :fill="red"></path>
      <path
        d="M0,23 H12 V0 H0 V23 "
        fill-rule="evenodd"
        :fill="blue"
        clip-rule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="36" height="23" rx="4" fill="white"></rect>
      </clipPath>
    </defs>
    <svg
      viewBox="0 0 20 20"
      style="zoom: 32"
      height="20"
      width="20"
      fill="none"
      visibility="visible"
    ></svg>
    <path
      d="M18.4324,2.65172 L18.4317,2.65141 C18.298,2.59594 18.154701,2.56738 18.01,2.56738 C17.86528,2.56738 17.721989,2.59594 17.588329,2.65141 L17.588131,2.65149 L11.66813,5.11149 L11.6681,5.11142 L11.66006,5.11493 C11.460979,5.20178 11.29207,5.34562 11.17461,5.52833 L11.5952,5.79871 L11.17461,5.52833 C11.05803,5.709681 10.99733,5.92127 10.99999,6.13678 V12.61325 C10.99999,14.47505 11.7396,16.26055 13.0561,17.57715 C14.37261,18.89365 16.158169,19.633249 18.02,19.633249 C19.8818,19.633249 21.6674,18.89365 22.9839,17.57715 C24.3004,16.26055 25.040001,14.47505 25.040001,12.61325 V6.133221 H25.040001 L25.040001,6.128469 C25.0378,5.902781 24.966299,5.68321 24.8351,5.49955 C24.707001,5.3202 24.528099,5.18357 24.3216,5.10721 L18.4324,2.65172 "
      visibility="visible"
      stroke="white"
      :fill="yellow"
    ></path>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGRegionFranceSecNum",
  props: {
    monochrome: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    red() {
      return this.monochrome ? "#616E7C" : "#F44653";
    },
    blue() {
      return this.monochrome ? "#323F4B" : "#1035BB";
    },
    yellow() {
      return this.monochrome ? "#52606D" : "#FFB800";
    },
  },
});
</script>
