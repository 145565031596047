import { App } from "scalingo/lib/models/regional";
import { UpdateParams } from "scalingo/lib/params/regional/apps";

import { ActionHandler } from "@/lib/handlers/action";
import { updateApp } from "@/store/apps";

import type { ComponentPublicInstance } from "vue";

export class EditAppSettingsHandler extends ActionHandler<App> {
  keyPath = "appSettings.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(event: UpdateParams): Promise<void> {
    this.follow(await updateApp(this.$store, this.app, event));
  }
}
