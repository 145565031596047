export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of credits that help pay for your monthly consumption."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no credits redeemed"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des crédits qui participent au paiement de votre consommation mensuelle."])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun crédit utilisé"])}
      }
    }
  })
}
