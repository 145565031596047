<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template #buttons>
          <router-link :to="changePlanRouterArgs">
            <SCButton> {{ $t("changePlan") }}</SCButton>
          </router-link>
        </template>
      </CardHeader>
    </template>

    <template #body>
      <span v-if="dbPlan.promiseInfo.isSuccess">
        <div class="mt-4 text-scale-10 font-medium text-base flex flex-row">
          {{ $t("scalingoFor") }}
          <div class="ml-1 capitalize">{{ planNameHumanized }}</div>
        </div>

        <div class="text-scale-10 font-normal text-base">
          {{ readablePlan(dbPlan) }}
        </div>
      </span>
      <div v-if="!isBusinessPlan" class="space-y-4 mt-4">
        <InformationAlert :finerBanner="true" :marginSize="4">
          <span>
            {{ $t("higherPlanInfo") }}
            <span
              class="text-primary-2 hover:text-primary-2-125 font-medium underline rounded-md py-1 self-center cursor-pointer"
            >
              <DocLink
                href="https://doc.scalingo.com/platform/databases/intro#database-plans"
                >{{ $t("doclink") }}
              </DocLink>
            </span>
          </span>
        </InformationAlert>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";
import { databaseName } from "@/lib/utils/databaseName";
import { humanReadableSize } from "@/lib/utils/size";
import { Routes } from "@/router/names";
export default defineComponent({
  name: "DbPlanCard",
  components: { Card, CardHeader, SCButton, InformationAlert, DocLink },

  props: {
    dbPlan: Object,
    app: Object,
    addon: Object,
  },

  computed: {
    planNameHumanized() {
      let planArray = this.dbPlan.item.id.split("-");
      return `${this.databaseName(planArray[0])} ${
        planArray[1]
      } ${humanReadableSize(this.dbPlan.item.memory)}`;
    },

    redundancyValue() {
      return this.dbPlan.item.id.includes("business")
        ? this.$t("multi")
        : this.$t("single");
    },

    backupRetentionValue() {
      if (this.dbPlan.item.id.includes("business")) {
        return this.$t("retention.oneYear");
      } else if (this.dbPlan.item.id.includes("starter")) {
        return this.$t("retention.oneMonth");
      }
      return this.$t("retention.none");
    },
    changePlanRouterArgs() {
      return {
        name: Routes.App.Addon.Edition.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          addonId: this.addon?.id,
        },
      };
    },
    isBusinessPlan() {
      if (!this.dbPlan.item) {
        return false;
      }
      return this.dbPlan.item.monthly_scheduled_backups !== 0;
    },
  },

  methods: {
    humanReadableSize,
    databaseName,
    readablePlan(dbPlan) {
      return `${this.$t("redundancy")}: ${this.redundancyValue}
      - ${this.$t("memory")}: ${humanReadableSize(dbPlan.item.memory)}
      - ${this.$t("storage")}: ${humanReadableSize(
        dbPlan.item.disk * 1024 * 1024,
      )}
      - ${this.$t("backup")}: ${this.backupRetentionValue}`;
    },
  },
});
</script>

<i18n>
en:
  title: "Database Plan"
  subtitle: "Scale the plan and its size to match your data and your requirements for performance and availability."
  changePlan: "Change plan"
  scalingoFor: "Scalingo for "
  redundancy: "Redundancy"
  single: "single-node (98% SLA)"
  multi: "multi-node (99,96% SLA)"
  memory: "Memory"
  storage: "Storage"
  backup: "Backup"
  higherPlanInfo: "Change to a Business plan and enjoy increased redundancy and a 99.96% SLA."
  doclink: "Learn more."
  retention:
    oneYear: "1 year retention"
    oneYearWithPITR: "1 year retention and 7 days PITR"
    oneMonth: "1 month retention"
    oneMonthWithPITR: "1 month retention and 7 days PITR"
    none: "none"
fr:
  title: "Plan de la base de données"
  subtitle: "Adapter le plan et sa taille à vos données et à vos besoins en termes de performance et de disponibilité."
  changePlan: "Changer de plan"
  scalingoFor: "Scalingo avec "
  redundancy: "Redondance"
  single: "mono-noeud (SLA 98%)"
  multi: "multi-noeuds (SLA 99,96%)"
  memory: "Mémoire"
  storage: "Stockage"
  backup: "Backup"
  higherPlanInfo: "Changez pour un plan Business et profitez d’une redondance accrue ainsi que d’un SLA de 99,96 %."
  doclink: "En savoir plus."
  retention:
    oneYear: "1 an de rétention"
    oneYearWithPITR: "1 an de rétention et 7 jours de PITR"
    oneMonth: "1 mois de rétention"
    oneMonthWithPITR: "1 mois de rétention et 7 jours de PITR"
    none: "aucun"
</i18n>
