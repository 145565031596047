<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.09069 2C6.33728 2 5.72705 2.63929 5.72705 3.42857V7H7.09069V3.42857H9.13614L9.41846 3.72433C9.67414 3.99219 10.0212 4.14286 10.3826 4.14286H13.3442C13.7056 4.14286 14.0527 3.99219 14.3084 3.72433L14.5907 3.42857H16.6361V20.5714H7.09069V17.7143H5.72705V20.5714C5.72705 21.3607 6.33728 22 7.09069 22H16.6361C17.3896 22 17.9998 21.3607 17.9998 20.5714V3.42857C17.9998 2.63929 17.3896 2 16.6361 2H7.09069ZM10.8407 2.71429H12.2043C12.3925 2.71429 12.5452 2.87429 12.5452 3.07143C12.5452 3.26857 12.3925 3.42857 12.2043 3.42857H10.8407C10.6525 3.42857 10.4998 3.26857 10.4998 3.07143C10.4998 2.87429 10.6525 2.71429 10.8407 2.71429ZM13.568 2.71429C13.7561 2.71429 13.9089 2.87429 13.9089 3.07143C13.9089 3.26857 13.7561 3.42857 13.568 3.42857C13.3798 3.42857 13.2271 3.26857 13.2271 3.07143C13.2271 2.87429 13.3798 2.71429 13.568 2.71429Z"
      fill="currentColor"
    />
    <path
      d="M3.68182 10.8571C3.68182 9.28143 4.905 8 6.40909 8C6.77273 8 7.6 8.1 8 8.5V9.75858C8 9.84767 7.88673 9.89793 7.81253 9.84862C7.38071 9.56165 6.72259 9.4491 6.40909 9.42857C5.65705 9.42857 5.04545 10.0693 5.04545 10.8571V11.5714H9.13636C9.51273 11.5714 9.81818 11.8914 9.81818 12.2857V15.1429C9.81818 15.5371 9.51273 15.8571 9.13636 15.8571H3.68182C3.30545 15.8571 3 15.5371 3 15.1429V12.2857C3 11.8914 3.30545 11.5714 3.68182 11.5714V10.8571Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TwoFaDisabledGlyph",
});
</script>
