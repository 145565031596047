export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment variables edited"])},
        "openTerminalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show edited variables"])},
        "added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 added"]), _normalize(["1 added"]), _normalize([_interpolate(_named("count")), " added"])])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 updated"]), _normalize(["1 updated"]), _normalize([_interpolate(_named("count")), " updated"])])},
        "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 deleted"]), _normalize(["1 deleted"]), _normalize([_interpolate(_named("count")), " deleted"])])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables d'environnement éditées"])},
        "openTerminalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les variables éditées"])},
        "added": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune ajout"]), _normalize(["Un ajout"]), _normalize([_interpolate(_named("count")), " ajouts"])])},
        "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune édition"]), _normalize(["Une édition"]), _normalize([_interpolate(_named("count")), " éditions"])])},
        "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucune suppression"]), _normalize(["Une suppression"]), _normalize([_interpolate(_named("count")), " suppressions"])])}
      }
    }
  })
}
