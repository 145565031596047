export default {
  "helpers": {
    "humanReadableSize": {
      "bytes": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["octet"]), _normalize(["octet"]), _normalize(["octets"])])},
      "kilo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ko"])},
      "mega": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
      "giga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
      "tera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])}
    }
  }
}