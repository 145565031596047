import {
  ChangelogEntry,
  fetchLastEntries,
} from "@/lib/scalingo/feeds/changelog";
import { HANDLE_FETCH, REFRESH } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL } from "@/lib/store/mutation-types";
import {
  buildMapping,
  listItems,
  ListItemsOptions,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class ChangelogEntriesStore extends CollectionStore<ChangelogEntry> {
  actions = CollectionStore.buildActions<ChangelogEntry>({
    [REFRESH](context) {
      context.dispatch(HANDLE_FETCH, {
        promise: fetchLastEntries(),
        resolveAction: SET_ALL,
      });
    },
  });
  mutations = CollectionStore.buildMutations<ChangelogEntry>();
  getters = CollectionStore.buildGetters<ChangelogEntry>();
}

export const ChangelogEntries = buildMapping(
  new ChangelogEntriesStore(),
  "changelogEntries",
);

export function listChangelogEntries(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<ChangelogEntry>>,
): CollectionWithFetch<ChangelogEntry> {
  return {
    items: listItems(store.getters[ChangelogEntries.getters.ALL], opts),
    latestFetch: store.getters[ChangelogEntries.getters.LATEST_FETCH],
  };
}

export function clearChangelogEntries(store: ApplicationStore): void {
  store.dispatch(ChangelogEntries.actions.CLEAR);
}

export function ensureChangelogEntries(store: ApplicationStore): void {
  store.dispatch(ChangelogEntries.actions.ENSURE);
}
