<template>
  <Card>
    <template v-slot:header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template v-slot:buttons>
          <SCButton :loading="operationIsLoading" @click="toggleAutoDeploy">
            <span v-if="scmRepoLink.auto_deploy_enabled">
              {{ $t("button_disable") }}
            </span>
            <span v-if="!scmRepoLink.auto_deploy_enabled">
              {{ $t("button_enable") }}
            </span>
          </SCButton>
        </template>
      </CardHeader></template
    >
    <template v-slot:body>
      <div class="py-3 flex items-center">
        <template v-if="operationIsLoading">
          <Spinner />
          <span class="ml-2">
            {{ $t("updating") }}
          </span>
        </template>
        <template v-else>
          <TwoStatesIndicator
            class="mr-2"
            :isOn="scmRepoLink.auto_deploy_enabled"
            :alternativeColors="true"
          ></TwoStatesIndicator>
          <span v-if="scmRepoLink.auto_deploy_enabled">
            {{ $t("enabled") }}
            <span class="font-bold">
              {{ scmRepoLink.branch }}
            </span>
            {{ $t("branch") }}
          </span>
          <span v-if="!scmRepoLink.auto_deploy_enabled">
            {{ $t("disabled") }}
          </span>
        </template>
      </div>
      <div
        v-if="
          scmRepoLink.auto_deploy_enabled && scmRepoLink.last_auto_deploy_at
        "
        class="py-3"
      >
        <span class="text-scale-10">{{ $t("last") }}</span>
        <span class="text-scale-10 font-medium">{{ lastAutoDeployDate }}</span>
      </div>

      <InfoCallout>{{ $t("info") }}</InfoCallout>
      <EnableAutoDeployModal
        v-if="showModal"
        :scmBranches="scmBranches"
        @modalClosed="showModal = false"
        @formSubmitted="(e) => $emit('enableAutoDeploy', e)"
      ></EnableAutoDeployModal>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import InfoCallout from "@/components/molecules/callouts/InfoCallout.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EnableAutoDeployModal from "@/components/parts/app/showDeployConfiguration/EnableAutoDeployModal.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "AutomaticDeploymentCard",
  components: {
    EnableAutoDeployModal,
    InfoCallout,
    TwoStatesIndicator,
    SCButton,
    Card,
    CardHeader,
    Spinner,
  },
  props: {
    scmRepoLink: Object,
    scmBranches: Array,
    repoLinkOperation: Object,
  },
  emits: ["disableAutoDeploy", "enableAutoDeploy", "fetchScmRepoLinkBranches"],
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    operationIsLoading() {
      return this.repoLinkOperation?.isLoading || false;
    },
    lastAutoDeployDate() {
      return formatDateTime(this.scmRepoLink.last_auto_deploy_at);
    },
  },
  methods: {
    toggleAutoDeploy() {
      if (this.scmRepoLink.auto_deploy_enabled) {
        this.$emit("disableAutoDeploy");
      } else {
        this.$emit("fetchScmRepoLinkBranches");
        this.showModal = true;
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Automatic deployments"
  subtitle: "Trigger the deployment of the application after each push on the linked branch."
  enabled: "Enabled on"
  disabled: "Disabled"
  updating: "Configuration is being updated..."
  button_enable: "Enable"
  button_disable: "Disable"
  branch: "branch"
  info: "If multiple integrations (CI, etc.) are present, the deployment will be triggered only after their success"
  last: "Last auto-deployment "
fr:
  title: "Déploiements automatiques"
  subtitle: "Déclencher le déploiement automatique de l'application après chaque push sur la branche liée."
  enabled: "Activé sur"
  disabled: "Désactivé"
  updating: "Configuration is being updated..."
  button_enable: "Activer"
  button_disable: "Désactiver"
  branch: "branche"
  info: "Si de multiples intégrations (CI, etc.) sont présentes, le déploiement sera lancé uniquement après leurs succès"
  last: "Dernier deploiement "
</i18n>
