<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.467784 5.56917C0.355149 5.48716 0.306994 5.34269 0.3479 5.2095L0.401182 5.04298C0.440137 4.91138 0.557252 4.81821 0.694232 4.80985L4.95011 4.47016L6.58187 0.547012C6.6366 0.409576 6.77399 0.32337 6.92154 0.33387H7.09471C7.23381 0.330919 7.35949 0.416495 7.40774 0.547012L9.04615 4.47016L13.302 4.80985C13.439 4.81821 13.5561 4.91138 13.5951 5.04298L13.6484 5.2095C13.6937 5.33848 13.6538 5.48204 13.5485 5.56917L10.3382 8.3267L11.3239 12.463C11.3564 12.5954 11.3066 12.7344 11.1974 12.816L11.0043 12.9226C10.8916 12.9987 10.7439 12.9987 10.6313 12.9226L7.00812 10.7245L3.36498 12.9426C3.25232 13.0187 3.10467 13.0187 2.99201 12.9426L2.84548 12.8426C2.73629 12.761 2.68647 12.622 2.71894 12.4896L3.67801 8.3267L0.467784 5.56917Z"
      fill="#FFB800"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StarGlyph",
});
</script>
