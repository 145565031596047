<template>
  <div class="flex flex-row space-x-2 items-center">
    <ThreeStatesIndicator
      v-if="!isOngoing"
      :isOk="inSuccess"
      :isKo="inFailure"
      :isNo="isScheduled || isCancelled"
    ></ThreeStatesIndicator>
    <div v-if="isOngoing" class="relative flex h-3 w-3">
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary-2 opacity-75"
      ></span>
      <span
        class="relative inline-flex rounded-full h-3 w-3 bg-secondary-2"
      ></span>
    </div>
    <span class="humanize">{{ $ta("backup", `statuses.${item.status}`) }}</span>
  </div>
</template>
<script>
import ThreeStatesIndicator from "@/components/atoms/indicators/ThreeStatesIndicator.vue";
import ModelsTranslation from "@/mixins/models_translation";

export default {
  name: "BackupStatus",
  components: { ThreeStatesIndicator },
  mixins: [ModelsTranslation],
  props: {
    item: {},
  },
  computed: {
    inSuccess() {
      return this.item.status === "done";
    },
    inFailure() {
      return this.item.status === "error";
    },
    isOngoing() {
      return this.item.status === "running";
    },
    isScheduled() {
      return this.item.status === "scheduled";
    },
    isCancelled() {
      return this.item.status === "cancelled";
    },
  },
};
</script>
