<template>
  <svg
    viewBox="0 0 111 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.192 16.651H31C31.0846 18.444 31.6553 19.7887 32.7545 20.6852C33.8537 21.5816 35.418 22.0085 37.4896 22.0085C38.462 22.0085 39.3287 21.9018 40.0686 21.667C40.7873 21.4322 41.4003 21.1121 41.8865 20.6852C42.3727 20.2583 42.732 19.7673 42.9857 19.2124C43.2394 18.6574 43.3662 18.0384 43.3662 17.3554C43.3662 15.9893 42.9857 14.9221 42.2247 14.1964C41.4637 13.4707 40.2588 12.937 38.6311 12.5955L36.5595 12.19C35.7985 12.0406 35.2489 11.8271 34.9318 11.5283C34.6147 11.2508 34.4668 10.8239 34.4668 10.2476C34.4668 10.0342 34.509 9.84205 34.5936 9.6286C34.6782 9.4365 34.8261 9.26574 35.0375 9.11633C35.2489 8.96692 35.5237 8.83885 35.8619 8.75347C36.2001 8.66809 36.6441 8.60406 37.1937 8.60406C38.0815 8.60406 38.7579 8.79616 39.2019 9.15902C39.6458 9.54322 39.9417 10.1409 40.1108 10.9733L43.0914 10.5891C43.0068 9.99146 42.8589 9.41516 42.6475 8.86019C42.4361 8.30523 42.0979 7.83565 41.654 7.40875C41.1889 6.98186 40.597 6.64034 39.8783 6.38421C39.1384 6.12807 38.2295 6 37.1303 6C36.2636 6 35.4814 6.10672 34.7627 6.29883C34.044 6.49093 33.4098 6.76841 32.9025 7.13127C32.3952 7.51548 31.9935 7.96371 31.6976 8.51868C31.4016 9.07364 31.2748 9.71398 31.2748 10.4397C31.2748 11.1654 31.3805 11.8058 31.5919 12.318C31.8033 12.8517 32.0992 13.2999 32.5009 13.6628C32.9025 14.0256 33.4098 14.3244 34.0017 14.5379C34.5936 14.7727 35.27 14.9648 36.0522 15.0928L38.0181 15.4557C38.8002 15.6265 39.3498 15.8826 39.6458 16.2241C39.9417 16.5656 40.0897 16.9498 40.0897 17.334C40.0897 17.6329 40.0263 17.889 39.9417 18.1238C39.8572 18.3799 39.7092 18.5934 39.4978 18.7641C39.2864 18.9349 39.0116 19.0843 38.6734 19.191C38.3352 19.2978 37.9124 19.3404 37.4262 19.3404C36.3904 19.3404 35.6083 19.127 35.0798 18.7001C34.5513 18.2732 34.2554 17.5902 34.192 16.651Z"
    />
    <path
      d="M54.4119 17.5902H51.4102C51.3468 18.2305 51.1565 18.7215 50.8394 19.063C50.5012 19.4045 50.0573 19.5752 49.4654 19.5752C49.0004 19.5752 48.641 19.4899 48.3451 19.2978C48.0491 19.1057 47.8166 18.8495 47.6475 18.508C47.4784 18.1878 47.3515 17.8036 47.2881 17.3767C47.2036 16.9498 47.1824 16.5016 47.1824 16.032C47.1824 15.5838 47.2036 15.1569 47.2881 14.73C47.3515 14.3031 47.4784 13.9402 47.6475 13.5987C47.8166 13.2785 48.0703 13.0224 48.3662 12.8303C48.6621 12.6382 49.0215 12.5315 49.4866 12.5315C50.0996 12.5315 50.5435 12.7236 50.7972 13.0651C51.0508 13.4066 51.1988 13.8762 51.2833 14.4312L54.2639 14.0256C54.1794 13.4707 54.0525 12.937 53.8411 12.4674C53.6298 11.9979 53.3338 11.571 52.9533 11.2295C52.5728 10.8879 52.0866 10.6318 51.537 10.4397C50.9874 10.2476 50.3321 10.1409 49.5922 10.1409C48.7044 10.1409 47.9434 10.2903 47.267 10.5678C46.5905 10.8666 46.0198 11.2721 45.5547 11.7844C45.0897 12.2967 44.7303 12.937 44.4767 13.6628C44.223 14.3885 44.1173 15.1996 44.1173 16.0747C44.1173 16.9712 44.223 17.7609 44.4344 18.4867C44.6458 19.2124 44.9628 19.8314 45.3856 20.3436C45.8084 20.8559 46.3791 21.2615 47.0556 21.539C47.732 21.8378 48.5353 21.9658 49.4654 21.9658C51.0508 21.9658 52.2346 21.5816 53.0379 20.7919C53.8411 20.0021 54.2851 18.9349 54.4119 17.5902Z"
    />
    <path
      d="M62.2404 20.2156V21.6243H65.0307V14.5806C65.0307 13.8975 64.9461 13.2999 64.777 12.7449C64.6079 12.19 64.3542 11.7204 63.9949 11.3362C63.6355 10.952 63.1705 10.6745 62.5786 10.461C61.9867 10.2476 61.268 10.1409 60.4224 10.1409C59.0273 10.1409 57.928 10.4184 57.1248 10.9306C56.3004 11.4429 55.793 12.2967 55.6028 13.4493L58.3085 13.7054C58.4776 12.809 59.133 12.3607 60.3167 12.3607C60.8241 12.3607 61.2468 12.4888 61.6273 12.7449C62.0078 13.0011 62.1981 13.4707 62.1981 14.1537V14.7086L60.7818 14.7727C60.0631 14.8154 59.3655 14.8794 58.689 14.9861C58.0126 15.1142 57.3996 15.3063 56.85 15.5624C56.3004 15.8399 55.8564 16.2241 55.5182 16.6937C55.18 17.1846 55.0109 17.8036 55.0109 18.572C55.0109 19.1697 55.0954 19.6606 55.3068 20.0875C55.4971 20.5144 55.7507 20.8559 56.089 21.1121C56.4272 21.3895 56.8077 21.5816 57.2516 21.7097C57.6955 21.8378 58.1606 21.9018 58.6679 21.9018C59.5135 21.9018 60.211 21.7524 60.7606 21.4322C61.3102 21.1334 61.7964 20.7279 62.2404 20.2156ZM62.1769 16.651V17.2273C62.1769 17.6969 62.0924 18.0811 61.9233 18.3799C61.7542 18.6788 61.5216 18.9349 61.268 19.127C60.7818 19.4899 60.1688 19.6606 59.4078 19.6606C58.9427 19.6606 58.6045 19.5539 58.3508 19.3404C58.0972 19.127 57.9703 18.8495 57.9703 18.4653C57.9703 18.2305 57.9915 18.0171 58.076 17.8036C58.1394 17.6115 58.2663 17.4408 58.4776 17.2914C58.689 17.1419 58.985 17.0139 59.3655 16.9072C59.746 16.8218 60.2322 16.7577 60.8663 16.715L62.1769 16.651Z"
    />
    <path d="M66.7122 6.38421V21.6243H69.6716V6.38421H66.7122Z" />
    <path
      d="M71.4817 10.4824V21.6243H74.4411V10.4824H71.4817ZM71.4394 6.38421V9.07364H74.4834V6.38421H71.4394Z"
    />
    <path
      d="M76.23 10.4824V21.6243H79.1894V14.9221C79.1894 14.6019 79.2528 14.3031 79.3796 14.0256C79.5065 13.7481 79.6756 13.5133 79.887 13.2999C80.1195 13.0864 80.3732 12.937 80.648 12.809C80.9228 12.7022 81.261 12.6382 81.6415 12.6382C82.1277 12.6382 82.5293 12.7876 82.8253 13.0651C83.1212 13.3639 83.2692 13.8975 83.2692 14.666V21.6243H86.2286V14.2604C86.2286 12.8517 85.9115 11.8058 85.3196 11.1441C84.7066 10.4824 83.7765 10.1409 82.5293 10.1409C81.874 10.1409 81.2399 10.2903 80.648 10.5891C80.0561 10.8879 79.5276 11.3789 79.1048 12.0192V10.4824H76.23Z"
    />
    <path
      d="M90.9806 22.3287H87.9789C88.0423 22.9477 88.1902 23.4813 88.4016 23.9509C88.613 24.4205 88.9301 24.8047 89.3529 25.1035C89.7545 25.4023 90.2618 25.6371 90.896 25.7866C91.5302 25.936 92.2912 26 93.179 26C94.1302 26 94.9335 25.8719 95.61 25.6158C96.2864 25.3597 96.8149 24.9541 97.2376 24.4418C97.6604 23.9296 97.9564 23.3106 98.1466 22.5848C98.3369 21.8591 98.4426 21.0053 98.4426 20.0662V10.4824H95.5677V11.7417C95.1026 11.1441 94.5742 10.7172 94.0246 10.4824C93.4749 10.2689 92.9042 10.1409 92.3123 10.1409C91.5513 10.1409 90.8749 10.2903 90.283 10.5678C89.6911 10.8453 89.1838 11.2295 88.7821 11.7204C88.3593 12.2113 88.0423 12.7876 87.8309 13.4707C87.6195 14.1537 87.5138 14.9007 87.5138 15.7118C87.5138 17.4194 87.8943 18.7855 88.6553 19.7887C89.4163 20.8132 90.5789 21.3042 92.1432 21.3042C92.8619 21.3042 93.4961 21.1761 94.0668 20.8986C94.6376 20.6211 95.1026 20.2156 95.4831 19.6606V20.0021C95.4831 20.4717 95.462 20.9413 95.4197 21.3682C95.3774 21.8164 95.2929 22.2006 95.1449 22.5422C94.9969 22.8837 94.7644 23.1612 94.4685 23.3746C94.1514 23.588 93.7286 23.6734 93.179 23.6734C92.8196 23.6734 92.5237 23.6734 92.27 23.6307C92.0164 23.588 91.805 23.524 91.6359 23.4173C91.4668 23.3319 91.3188 23.1825 91.2131 23.0117C91.1074 22.841 91.0228 22.6275 90.9806 22.3287ZM95.5043 15.6692V15.968C95.5043 16.5229 95.4408 16.9712 95.314 17.3554C95.1872 17.7396 94.9969 18.0384 94.7855 18.2732C94.553 18.508 94.2782 18.6788 93.9611 18.7855C93.6441 18.8922 93.3058 18.9349 92.9465 18.9349C92.27 18.9349 91.7204 18.7001 91.2765 18.2092C90.8326 17.7182 90.6212 16.8858 90.6212 15.6905C90.6212 15.1355 90.6846 14.666 90.8114 14.2604C90.9383 13.8762 91.1074 13.5347 91.3188 13.2785C91.5302 13.0224 91.7838 12.8517 92.0798 12.7236C92.3757 12.6169 92.6717 12.5528 93.0099 12.5528C93.3058 12.5528 93.6018 12.5955 93.8977 12.6596C94.1937 12.7449 94.4473 12.8943 94.701 13.1078C94.9335 13.3212 95.1238 13.6414 95.2717 14.047C95.4197 14.4739 95.5043 15.0075 95.5043 15.6692Z"
    />
    <path
      d="M105.419 21.9658C106.265 21.9658 107.026 21.8378 107.723 21.5603C108.4 21.3042 108.992 20.8986 109.478 20.3863C109.964 19.8741 110.345 19.2551 110.598 18.5293C110.852 17.8036 111 16.9712 111 16.032C111 15.1355 110.873 14.3031 110.619 13.5774C110.366 12.8517 109.985 12.2327 109.499 11.7204C109.013 11.2081 108.421 10.8239 107.745 10.5464C107.047 10.2903 106.265 10.1409 105.419 10.1409C104.574 10.1409 103.792 10.2903 103.115 10.5464C102.418 10.8239 101.826 11.2081 101.34 11.7204C100.853 12.2327 100.473 12.8517 100.219 13.5774C99.9655 14.3031 99.8387 15.1355 99.8387 16.032C99.8387 17.953 100.325 19.4045 101.297 20.429C102.27 21.4536 103.644 21.9658 105.419 21.9658ZM105.419 19.5752C104.553 19.5752 103.919 19.2764 103.517 18.6788C103.094 18.0811 102.904 17.1846 102.904 16.032C102.904 14.9007 103.094 14.0256 103.517 13.428C103.94 12.8303 104.574 12.5315 105.419 12.5315C106.265 12.5315 106.878 12.8303 107.301 13.428C107.723 14.0256 107.935 14.9007 107.935 16.032C107.935 17.1846 107.723 18.0598 107.301 18.6574C106.878 19.2764 106.265 19.5752 105.419 19.5752Z"
    />
    <path
      d="M6.8268 1.85198C7.73893 0.276848 9.7832 -0.31116 11.3928 0.538627C13.0024 1.38841 13.5679 3.3542 12.6557 4.92933L6.48052 15.5932C5.5684 17.1683 3.52412 17.7563 1.9145 16.9065C0.304883 16.0568 -0.260547 14.091 0.651578 12.5158L6.8268 1.85198Z"
      fill="#22D1EE"
    />
    <path
      d="M11.5194 12.4067C12.4316 10.8315 14.4758 10.2435 16.0854 11.0933C17.6951 11.9431 18.2605 13.9089 17.3484 15.484L11.1731 26.1479C10.261 27.723 8.21674 28.311 6.60713 27.4612C4.99751 26.6114 4.43208 24.6457 5.3442 23.0705L11.5194 12.4067Z"
      fill="#183BEE"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGScalingoLogoFull",
});
</script>
