<template>
  <div>
    <div>
      <template v-if="deployments.items">
        <div
          v-for="(deployment, index) in deployments.items"
          :key="index"
          class="py-2"
          :class="{ 'border-t border-scale-2': index > 0 }"
        >
          <Deployment
            :app="app"
            :dockerAddon="dockerAddon"
            :deployment="deployment"
            :withLink="true"
          />
        </div>
      </template>
      <EmptyCardState
        v-if="
          deployments.items.length === 0 && deployments.latestFetch.isFinished
        "
      >
        <template #icon>
          <TerminalGlyph class="h-4 text-scale-5"></TerminalGlyph>
        </template>

        {{ $t("none") }}
      </EmptyCardState>
    </div>
    <template v-if="deployments.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-else>
      <div v-if="hasMore" class="flex justify-center">
        <LinkButton
          :noUnderLine="true"
          @clicked="$emit('viewMoreButtonClicked')"
        >
          {{ $t("viewMore") }}
        </LinkButton>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import TerminalGlyph from "@/components/atoms/glyphs/TerminalGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import Deployment from "@/components/parts/app/showDeploy/ShowDeployDeployment.vue";

export default defineComponent({
  name: "ShowDeployDeployments",
  components: {
    LinkButton,
    LoadingCardState,
    Deployment,
    TerminalGlyph,
    EmptyCardState,
  },
  props: {
    app: Object,
    addons: Object,
    deployments: Object,
  },
  emits: ["viewMoreButtonClicked"],
  computed: {
    hasMore() {
      return !!this.deployments.meta?.pagination?.next_page;
    },
    dockerAddon() {
      return (this.addons.items || []).find((addon) => {
        return addon.addon_provider?.id === "scalingo-docker-image";
      });
    },
  },
});
</script>

<i18n>
  en:
    viewMore: "View more"
    none: "No deployments yet"
  fr:
    viewMore: "Voir plus"
    none: "Aucun déploiement."
</i18n>
