<template>
  <section>
    <Card>
      <template v-slot:header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template #buttons>
            <SCButton :loading="false" @click="$emit('startDatabaseCreation')">
              {{ $t("addDatabase") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template v-slot:body>
        <DatabasesList
          v-if="mySQLDatabasesList?.length"
          class="mt-2"
          :databases="mySQLDatabasesList"
          @startDatabaseEmptying="(e) => $emit('startDatabaseEmptying', e)"
          @startDatabaseDeletion="(e) => $emit('startDatabaseDeletion', e)"
        />
        <NoDatabase v-else />
      </template>
    </Card>
    <CreateDatabaseModal
      v-if="createCtx"
      :context="createCtx"
      @submit="(e) => $emit('confirmDatabaseCreation', e)"
      @close="$emit('cancelDatabaseCreation')"
    />
    <EmptyDatabaseModal
      v-if="emptyCtx"
      :context="emptyCtx"
      @close="$emit('cancelDatabaseEmptying')"
      @confirm="(e) => $emit('confirmDatabaseEmptying', e)"
    />
    <DeleteDatabaseModal
      v-if="deleteCtx"
      :context="deleteCtx"
      @close="$emit('cancelDatabaseDeletion')"
      @confirm="(e) => $emit('confirmDatabaseDeletion', e)"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import CreateDatabaseModal from "@/components/parts/dbSettings/databases/CreateDatabaseModal.vue";
import DatabasesList from "@/components/parts/dbSettings/databases/DatabasesList.vue";
import DeleteDatabaseModal from "@/components/parts/dbSettings/databases/DeleteDatabaseModal.vue";
import EmptyDatabaseModal from "@/components/parts/dbSettings/databases/EmptyDatabaseModal.vue";
import NoDatabase from "@/components/parts/dbSettings/databases/NoDatabase.vue";

export default defineComponent({
  name: "DatabasesManagement",
  components: {
    CardHeader,
    Card,
    SCButton,
    DatabasesList,
    CreateDatabaseModal,
    EmptyDatabaseModal,
    DeleteDatabaseModal,
    NoDatabase,
  },
  props: {
    mySQLDatabasesList: Array,
    createCtx: Object,
    emptyCtx: Object,
    deleteCtx: Object,
  },
  emits: [
    "startDatabaseCreation",
    "confirmDatabaseCreation",
    "cancelDatabaseCreation",
    "startDatabaseEmptying",
    "confirmDatabaseEmptying",
    "cancelDatabaseEmptying",
    "startDatabaseDeletion",
    "confirmDatabaseDeletion",
    "cancelDatabaseDeletion",
  ],
});
</script>

<i18n>
en:
  title: "Database Management"
  subtitle: "Databases of this MySQL instance."
  addDatabase: "Add a database"
fr:
  title: "Gestion des bases de données"
  subtitle: "Bases de données de cette instance MySQL."
  addDatabase: "Ajouter une base de données"
</i18n>
