import { APIError } from "scalingo/lib/errors";

import { FormHandler } from "@/lib/handlers/form";
import { Voucher } from "@/lib/scalingo/vouchers";
import { Nullable } from "@/lib/utils/types";
import { ensureBillingProfile } from "@/store/billing-profile";
import { createVoucher } from "@/store/vouchers";

interface FormData {
  code: string;
}

export class CreateVoucherHandler extends FormHandler<Voucher> {
  keyPath = "billingProfile.create";

  data(): Nullable<FormData> {
    return {
      code: null,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => {
      ensureBillingProfile(this.$store, { staleAfter: "always" });
    });
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await createVoucher(this.$store, event.code));
  }

  formatErrors(): void {
    const error = this.operation?.error;

    if (error && error instanceof APIError) {
      // One field to alias
      if (error.data.error) {
        this.errors.code = [error.data.error];
      }
    }
  }
}
