import { User } from "scalingo/lib/models/auth";
import { BillingProfile } from "scalingo/lib/models/billing";

import { FormHandler } from "@/lib/handlers/form";
import { PaymentMethod } from "@/lib/scalingo/payment-methods";
import { Nullable } from "@/lib/utils/types";
import {
  createPaymentMethod,
  refreshPaymentMethod,
} from "@/store/payment-methods";

import type { StripeCardElement, StripeError } from "@stripe/stripe-js";
import type { ComponentPublicInstance } from "vue";

interface FormData {
  holder: string;
  defaultPaymentMethod: boolean;
}

interface EventData extends FormData {
  el: StripeCardElement;
}

export class CreateCardPaymentMethodHandler extends FormHandler<PaymentMethod> {
  keyPath = "paymentMethod.card.create";

  constructor(
    component: ComponentPublicInstance,
    readonly user: User,
    readonly profile: BillingProfile,
    readonly allMethods: PaymentMethod[],
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      holder: null,
      defaultPaymentMethod: true,
    };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("success", () => {
      (this.allMethods || [])
        .filter((pm) => pm.default)
        .forEach((method) => refreshPaymentMethod(this.$store, method));
    });
  }

  async submit(event: EventData): Promise<void> {
    const payload = {
      profile: this.profile,
      card: event,
    };

    this.follow(await createPaymentMethod(this.$store, payload));
  }

  formatErrors(): void {
    const error = this.operation?.error as Record<string, unknown>;

    if (error?.stripe) {
      const err = error.stripe as StripeError;
      const code = err.decline_code || err.code;

      if (code) this.errors.$codes.push(code);
    }
  }
}
