export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])},
        "noEnv": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All good!"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " does not expect any environment variable to be configured."])}
        }
      },
      "fr": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])},
        "noEnv": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout est bon!"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " n'attends aucune variable d'environnement pour être configuré."])}
        }
      }
    }
  })
}
