<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton @click="redeemVoucher">
          {{ $t("buttonName") }}
        </SCButton>
      </template>
    </CardHeader>
    <VouchersListBody
      v-if="displayVouchers"
      :vouchers="vouchers"
    ></VouchersListBody>
    <EmptyCardState v-else>
      <template #icon>
        <BillGlyph class="h-8 text-scale-6"></BillGlyph>
      </template>
      <span class="text-scale-6 text-lg font-medium">
        {{ $t("none") }}
      </span>
    </EmptyCardState>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import VouchersListBody from "@/components/parts/billing/showBilling/VouchersListBody.vue";

export default defineComponent({
  name: "VouchersList",
  components: {
    SCButton,
    CardHeader,
    VouchersListBody,
    BillGlyph,
    EmptyCardState,
  },
  props: {
    vouchers: Object,
  },
  emits: ["redeemVoucher"],
  computed: {
    displayVouchers() {
      const op = this.vouchers.latestFetch;

      return op.isLoading || this.vouchers.items.length > 0;
    },
  },
  methods: {
    redeemVoucher(eventArgs) {
      this.$emit("redeemVoucher", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Vouchers"
  subtitle: "List of vouchers that help pay for your monthly consumption."
  none: "no vouchers redeemed"
  buttonName: "Redeem voucher"
fr:
  title: "Voucher"
  subtitle: "Liste des vouchers qui participent au paiement de votre consommation mensuelle."
  none: "Aucun vouchers utilisé"
  buttonName: "Utiliser un voucher"
</i18n>
