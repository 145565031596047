<template>
  <SCAlert :kind="kind" :finerBanner="finerBanner">
    <div class="flex flex-row space-x-1.5">
      <span>
        <InformationGlyph
          :style="'margin-top: ' + marginSize + 'px'"
          class="h-4 w-4"
        />
      </span>
      <span>
        <slot></slot>
      </span>
    </div>
  </SCAlert>
</template>

<script>
import { defineComponent } from "vue";

import InformationGlyph from "@/components/atoms/glyphs/InformationGlyph.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";

export default defineComponent({
  name: "InformationSCAlert",
  components: { SCAlert, InformationGlyph },
  props: {
    finerBanner: {
      type: Boolean,
      default: false,
    },
    marginSize: {
      type: Number,
      default: 3,
    },
    kind: {
      type: String,
      default: "info",
    },
  },
});
</script>
