<template>
  <SimpleModal @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:subtitle>
      <div class="max-w-3xl">
        {{ $t("subtitle") }}
      </div>
    </template>

    <template v-slot:body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <div class="flex justify-between space-x-4">
                <AdvancedSelectInput
                  v-model="form.kind"
                  :options="orgTypes"
                  class="sm:w-full md:w-1/2"
                  required
                >
                  <template #label>
                    {{ $ta("hdsContactProfile", "orgType") }}
                  </template>

                  <template #text="{ option }">
                    {{ option.text }}
                  </template>
                </AdvancedSelectInput>

                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.entity"
                  as="span"
                  class="w-1/2"
                  name="company"
                  rules="required"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('hdsContactProfile', 'company')"
                    :errors="errors"
                    required
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
              </div>

              <div class="flex justify-between space-x-4">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.firstname"
                  as="span"
                  class="w-1/2"
                  name="firstname"
                  rules="required"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('hdsContactProfile', 'firstname')"
                    :errors="errors"
                    required
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.lastname"
                  as="span"
                  class="w-1/2"
                  name="lastname"
                  rules="required"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('hdsContactProfile', 'lastname')"
                    :errors="errors"
                    required
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
              </div>

              <div class="flex justify-between space-x-4">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.email"
                  as="span"
                  name="email"
                  rules="email|required"
                  mode="eager"
                  class="w-1/2"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('hdsContactProfile', 'email')"
                    required
                    :errors="errors"
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="form.phone"
                  as="span"
                  class="w-1/2"
                  name="phone"
                  rules="phone|required"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$ta('hdsContactProfile', 'phoneNumber')"
                    :errors="errors"
                    required
                    @update:modelValue="handleChange"
                  ></TextInput>
                </Field>
              </div>
            </div>
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "HDSContactFormModal",
  components: {
    AdvancedSelectInput,
    SimpleModal,
    SCButton,
    TextInput,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["modalClosed"],
  data() {
    return {
      orgTypes: [
        {
          value: "company",
          text: this.$t("attributes.hdsContactProfile.orgTypes.company"),
        },
        {
          value: "non-profit",
          text: this.$t("attributes.hdsContactProfile.orgTypes.non-profit"),
        },
        {
          value: "public-entity",
          text: this.$t("attributes.hdsContactProfile.orgTypes.public-entity"),
        },
      ],
    };
  },
});
</script>

<i18n>
  en:
    title: "Point of contact Health professional"
    subtitle: "The HDH Reference System requires Scalingo and the owner of an account hosting Health Data (you) to define a point of contact who is able to designate a health professional when necessary."
    buttonName: "Update the point of contact"
  fr:
    title: "Point de contact professionnel de santé"
    subtitle: "Le référentiel HDS impose, à Scalingo et au propriétaire d'un compte hébergeant des données de Santé (vous), de définir un point de contact étant en mesure de désigner un professionnel de santé lorsque cela est nécessaire."
    buttonName: "Mettre à jour le point de contact"
</i18n>
