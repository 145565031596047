import mitt from "mitt";
import { APIError } from "scalingo/lib/errors";

/**
 * This allows working around the usual parent=>direct-children flow of events.
 *
 * This MUST be use scarcingly, and only when doing so is obviously the better option.
 *
 * Examples:
 * - centralizing global error handling (as those errors can happen
 *   in the store, in the controllers, in a promise, pretty much anywhere)
 *
 * If event listeners are set from a component that is not persisten throughout the app,
 * the components MUST remove their handlers in `beforeUnmount`.
 */

type Events = {
  scalingoAPIUnauthorized: { error: APIError };
};

export const eventBus = mitt<Events>();
