import axios, { AxiosInstance } from "axios";
import { App } from "scalingo/lib/models/regional";

import { availableRegions, Region } from "@/lib/scalingo/client";
import BackupRestorations from "@/lib/scalingo/dbapi/backup_restorations";
import Backups from "@/lib/scalingo/dbapi/backups";
import Database from "@/lib/scalingo/dbapi/database";
import Instances from "@/lib/scalingo/dbapi/instances";
import Logs from "@/lib/scalingo/dbapi/logs";
import Users from "@/lib/scalingo/dbapi/users";
import { dashboard } from "@/lib/utils/log";

import { getDBToken } from "./tokens";

/**
 * This class handles a few private endpoints to the Scalingo API.
 * Those are not publicly documented and are not meant to be publicly consumed.
 * They all relate to billing and payment methods.
 */
export default class ScalingoDBApi {
  /** Bearer Token for the current user. */
  _token: string;

  /** URL to the DB Scalingo API. */
  _apiUrl: string;

  constructor(token: string, apiUrl: string) {
    this._token = token;
    this._apiUrl = apiUrl;
  }

  apiClient(): AxiosInstance {
    return axios.create({
      baseURL: `${this._apiUrl}/api`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this._token}`,
      },
    });
  }

  /**
   * Create a vanilla axios instance for the Scalingo API
   * @return Axios instance for the DB API, unauthenticated
   */
  unauthenticatedClient(): AxiosInstance {
    return axios.create({
      baseURL: `${this._apiUrl}/api`,
      headers: {
        Accept: "application/json",
      },
    });
  }

  Backups = new Backups(this);
  BackupRestorations = new BackupRestorations(this);
  Database = new Database(this);
  Instances = new Instances(this);
  Logs = new Logs(this);
  Users = new Users(this);
}

const cache: Record<string, ScalingoDBApi> = {};

// Create or get the cached client. Updates the token in case it changed.
export async function scalingoDBClient(
  app: App,
  addonId: string,
): Promise<ScalingoDBApi> {
  const region = availableRegions.find(
    (region) => region.code === app.region,
  ) as Region;

  const token = await getDBToken(app, addonId);

  if (!cache[region.code]) {
    dashboard.debug(`Scalingo: creating DB ${region.code} client`);

    cache[region.code] = new ScalingoDBApi(token, region.dbApiUrl);
  } else {
    dashboard.debug(`Scalingo: updating DB ${region.code} client`);

    cache[region.code]._token = token;
  }

  return cache[region.code];
}
