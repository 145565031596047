export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "upgradeAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade available"])},
        "actions": {
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change plan"])},
          "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
          "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
        },
        "dashboard": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version (preview)"])},
          "legacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old dashboard"])}
        }
      },
      "fr": {
        "upgradeAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour disponible"])},
        "actions": {
          "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "changePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de plan"])},
          "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relancer"])},
          "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
        },
        "dashboard": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle version (preview)"])},
          "legacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien dashboard"])}
        }
      }
    }
  })
}
