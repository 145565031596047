export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem voucher"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers will be deducted from your bill"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un code de crédit"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vouchers seront déduits de votre facture"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
      }
    }
  })
}
