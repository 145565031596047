export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "titles": {
          "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggered on events"])},
          "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators to notify"])},
          "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra mails to notify"])}
        },
        "labels": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send notifications"])}
        },
        "actions": {
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to notifiers"])}
        },
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      },
      "fr": {
        "titles": {
          "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclenché lors des évènements"])},
          "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs à notifier"])}
        },
        "labels": {
          "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les notifications"])},
          "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses emails à notifier"])}
        },
        "actions": {
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
          "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des notifieurs"])}
        },
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])}
      }
    }
  })
}
