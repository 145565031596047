<template>
  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
    <dt class="text-sm font-medium text-scale-7">
      {{ desc }}
    </dt>

    <dd class="mt-1 flex text-sm text-scale-9 sm:mt-0 sm:col-span-2">
      <slot />
    </dd>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SCKVItem",
  props: {
    desc: String,
  },
});
</script>
