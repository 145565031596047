export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
        "platformStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform status"])}
      },
      "fr": {
        "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
        "platformStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la plateforme"])}
      }
    }
  })
}
