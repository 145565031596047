export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "error": {
          "generic": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot fetch the consumption"])}
          },
          "invalid_date": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date is invalid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the date format in the url"])}
          },
          "too_long_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period is invalid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period cannot exceed three months"])}
          },
          "negative_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period is invalid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'from' date is after 'to' date"])}
          },
          "monthly_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period is invalid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only firsts and last days of months are allowed for periods before 2023"])}
          },
          "no_future_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period is invalid"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period's end cannot exceed yesterday"])}
          }
        }
      },
      "fr": {
        "error": {
          "generic": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'obtenir la consommation"])}
          },
          "invalid_date": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date est invalide"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier le format de la date dans l'url"])}
          },
          "too_long_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période est invalide"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période ne peut être plus grande que trois mois"])}
          },
          "negative_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période est invalide"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date 'from' se situe après la date `to`"])}
          },
          "monthly_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période est invalide"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les seules dates autorisées sont débuts et fins des mois pour les périodes pré 2023"])}
          },
          "no_future_range": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La période est invalide"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fin de période ne peut être plus que hier"])}
          }
        }
      }
    }
  })
}
