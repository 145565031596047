export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing profile"])},
        "created": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The info below will be used for invoicing"])},
          "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit these billing informations"])}
        },
        "newRecord": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet added any billing informations. Click the button to add one."])},
          "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add billing informations"])}
        },
        "needConfirmation": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to confirm your account before creating a billing profile."])}
        },
        "address": {
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This address will appear on your monthly invoices and should be the legal address of your home or company."])}
        },
        "email": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing email"])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the possibility to defined a billing email. Invoices will be sent to this email. If none is defined, your account email will be used."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address defined, using the account's one"])}
        },
        "tax": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax information"])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo is a French company, thus:"])},
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non European companies are exempted of tax"])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European companies won't be charged VAT if they enter their European Tax ID"])},
          "point3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European individuals will be charged the VAT of their home country"])},
          "point4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French companies and individuals will be charged the French VAT"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID :"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tax ID has been supplied."])}
        },
        "invoiceAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This text will be added in a dedicated field on each future invoice"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil de facturation"])},
        "created": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations ci-dessous seront utilisées pour la facturation"])},
          "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les informations de facturation"])}
        },
        "newRecord": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore ajouté d'informations de facturation. Cliquez sur le bouton pour les remplir."])},
          "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les informations de facturation"])}
        },
        "needConfirmation": {
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour créer un profil de facturation, vous devez d'abord confirmer votre compte."])}
        },
        "address": {
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse apparaîtra sur vos factures mensuelles et doit être l'adresse légale de votre domicile ou de votre société."])}
        },
        "email": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email de facturation"])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez la possibilité de définir un email de facturation. Les factures seront envoyées à cette adresse. Si aucune adresse n'est définie, l'email de votre compte sera utilisé."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aucune adresse n'est définie, celle du compte sera utilisée."])}
        },
        "tax": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les taxes"])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo est une société française, donc :"])},
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entreprises non européennes sont exemptées de taxe"])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entreprises européennes ne seront pas facturées de la TVA si elles indiquent leur numéro d'identification fiscale européen"])},
          "point3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les particuliers européens se verront appliquer la TVA de leur pays d'origine."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID :"])},
          "point4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entreprises et les particuliers français se verront appliquer la TVA française."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun identifiant fiscal n'a été fourni."])}
        },
        "invoiceAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce texte sera rajouté dans un champ dédié sur chaque facture future"])}
      }
    }
  })
}
