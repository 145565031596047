export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command run"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
        "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An operator started a one-off container for maintenance/support purposes."])},
        "errors": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The logs could not be fetched, please retry in a little while. If the issue keeps happening, please get in touch with support!"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande exécutée"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
        "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un opérateur a lancé un container one-off pour des raisons de maintenance/support"])},
        "errors": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les logs n'ont pas pu être récupérés, veuillez retenter d'ici peu. Si le problème persiste, contactez nous au support!"])}
        }
      }
    }
  })
}
