<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0029 2C9.24067 2 7.00142 4.23858 7.00142 7C7.00142 9.76142 9.24067 12 12.0029 12C14.7652 12 17.0044 9.76142 17.0044 7C17.0044 4.23858 14.7652 2 12.0029 2ZM20.0054 16.76L21.8959 20.55C22.0519 20.8595 22.0365 21.2277 21.8551 21.5231C21.6738 21.8185 21.3524 21.999 21.0057 22H3.00021C2.65351 21.999 2.33208 21.8185 2.15071 21.5231C1.96935 21.2277 1.95392 20.8595 2.10994 20.55L4.00051 16.76C4.84848 15.0683 6.57907 14.0001 8.47186 14H15.534C17.4268 14.0001 19.1574 15.0683 20.0054 16.76Z"
      fill="currentColor"
    />
    <path
      d="M24 17C24 20.3137 21.3137 23 18 23C14.6863 23 12 20.3137 12 17C12 13.6863 14.6863 11 18 11C21.3137 11 24 13.6863 24 17Z"
      fill="white"
    />
    <path
      d="M18 12C15.2386 12 13 14.2386 13 17C13 19.7614 15.2386 22 18 22C20.7614 22 23 19.7614 23 17C23 14.2386 20.7614 12 18 12ZM18 14.1429C18.3943 14.1429 18.7143 14.4621 18.7143 14.8571V16.2857H20.1429C20.5371 16.2857 20.8571 16.605 20.8571 17C20.8571 17.395 20.5371 17.7143 20.1429 17.7143H18.7143V19.1429C18.7143 19.5379 18.3943 19.8571 18 19.8571C17.6057 19.8571 17.2857 19.5379 17.2857 19.1429V17.7143H15.8571C15.4629 17.7143 15.1429 17.395 15.1429 17C15.1429 16.605 15.4629 16.2857 15.8571 16.2857H17.2857V14.8571C17.2857 14.4621 17.6057 14.1429 18 14.1429Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserAddGlyph",
});
</script>
