export default {
  "banners": {
    "backupRestoration": {
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été restaurée avec succès."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez accéder à vos données en toute sécurité."])}
      },
      "warning": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration de la base de données en cours."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon la taille de votre base de données, cette opération peut prendre de quelques minutes à plusieurs heures. Nous vous recommandons d’éviter toute opération jusqu’à ce que la restauration soit terminée."])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la restauration de la base de données."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré un problème lors de la restauration de la base de données. Pour vérifier son intégrité et son état actuel, veuillez contacter notre équipe de support."])}
      },
      "info": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration planifiée."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le processus de restauration va bientôt commencer. Nous vous recommandons d’éviter toute opération jusqu’à ce que la restauration soit terminée."])}
      }
    },
    "maintenanceInProgress": {
      "warning": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance en cours."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre base de données reste disponible, mais il est conseillé d’éviter toute modification tant que l’opération n’est pas terminée."])}
      }
    },
    "databaseUpgrade": {
      "warning": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de la base de donnée en cours."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données est en cours de mise à jour. Nous vous recommandons d’éviter toute opération jusqu’à ce que la mise à jour soit terminée."])}
      },
      "success": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été mise à jour avec succès."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez accéder à vos données en toute sécurité."])}
      },
      "user-error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la mise à jour de la base de données."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème a empêché la mise à jour, laissant la base de données dans son état d’origine. Si l’erreur persiste, veuillez contacter notre équipe de support."])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la mise à jour de la base de données."])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré un problème lors de la mise à jour de cette base de données. Son état actuel nécessite une action de nos équipes. Pour vérifier son intégrité et son état actuel, veuillez contacter notre équipe de support."])}
      }
    }
  }
}