import Client from "scalingo";
import { SCMType } from "scalingo/lib/models/regional";
import { unpackData } from "scalingo/lib/utils";

interface SCMRepoLink {
  owner: string;
  repo: string;
  branch: string;
  scm_type: SCMType;
  url: string;
}

export interface AppInfoOwner {
  id: string;
  username: string;
  email: string;
}

export interface AppInfo {
  id: string;
  parent_id: string | null;
  region: string;
  name: string;
  status: string;
  created_at: string;
  last_deployed_at: string | null;
  scm_repo_link: SCMRepoLink;
  owner: AppInfoOwner;
}

export class AppInfos {
  client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  /**
   * Return invoices for a given user
   * @param {InvoicesOpts} opts Object that contains the index of the page and the number of elements per page
   * @return {Promise<Invoice[] | APIError>}
   */
  all(): Promise<AppInfo[]> {
    return unpackData(this.client.apiClient().get(`/dashboard/apps`), "apps");
  }
}
