import Rollbar from "rollbar";

import {
  Fallback,
  Middleware,
  MiddlewareError,
  MiddlewareErrors,
  PostProcessor,
  Response,
} from "@/lib/auth/utils";

import { debug } from "../utils/log";

interface PipelineCtorOpts {
  middlewares: Middleware[];
  fallback: Fallback;
  postProcessors: PostProcessor[];
  rollbar: Rollbar;
}

export class AuthPipeline {
  middlewares: Middleware[];
  fallback: Fallback;
  postProcessors: PostProcessor[];
  rollbar: Rollbar;

  constructor(opts: PipelineCtorOpts) {
    this.middlewares = opts.middlewares;
    this.fallback = opts.fallback;
    this.postProcessors = opts.postProcessors;
    this.rollbar = opts.rollbar;
  }

  async runMiddlewareChain(): Promise<Response> {
    const failures: MiddlewareErrors = {};

    for (let i = 0; i < this.middlewares.length; i++) {
      const middleware = this.middlewares[i];
      debug(`Running Auth/${middleware.name}`);

      const promise = middleware.call(this.rollbar);

      try {
        return await promise;
      } catch (e) {
        failures[middleware.name] = e as MiddlewareError;
        debug(`-> Auth/${middleware.name} failure`, e);
      }
    }

    return Promise.reject(failures);
  }

  runPostProcessors(response: Response): Response {
    this.postProcessors.forEach((postProcessor) => {
      debug(`Running Auth/${postProcessor.name}`);
      response = postProcessor(response);
    });

    return response;
  }

  async call(): Promise<Response | void> {
    try {
      const response = await this.runMiddlewareChain();
      debug(`-> Auth success`, response);

      return this.runPostProcessors(response);
    } catch (e) {
      this.rollbar.warn("auth/middleware chain failure", e as MiddlewareErrors);

      debug(`Running fallback Auth/${this.fallback.name}`);
      this.fallback.call(e as MiddlewareErrors);
    }
  }
}
