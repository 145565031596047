<template>
  <SimpleModal @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template v-slot:body>
      <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <StripeElement
                id="card-number-element"
                labelClass="capitalize"
                :label="$ta('paymentMethod', 'cardNumber')"
                :errors="elementsErrors.cardNumber"
              />
            </div>

            <div class="mt-6">
              <StripeElement
                id="card-exp-element"
                labelClass="capitalize"
                :label="$ta('paymentMethod', 'exp')"
                :errors="elementsErrors.cardExpiry"
              />
            </div>

            <div class="mt-6">
              <StripeElement
                id="card-cvc-element"
                labelClass="uppercase"
                :label="$ta('paymentMethod', 'cvc')"
                :errors="elementsErrors.cardCvc"
              />
            </div>

            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.holder"
                name="holder"
                rules="required|min:3"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('paymentMethod', 'holder')"
                  labelClass="capitalize"
                  :errors="errors"
                  @update:modelValue="handleChange"
                ></TextInput>
              </Field>
            </div>
            <div v-if="validPaymentMethods">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.defaultPaymentMethod"
                name="defaultPayment"
              >
                <CheckboxInput
                  :modelValue="field.value"
                  :label="$t('setAsDefault')"
                  :errors="errors"
                  @update:modelValue="handleChange"
                >
                </CheckboxInput>
              </Field>
            </div>

            <SCAlert v-if="globalErrors" kind="error">
              {{ globalErrors }}
            </SCAlert>

            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-8"
              :disabled="!meta.valid || stripeElementsInvalid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CheckboxInput from "@/components/molecules/inputs/CheckboxInput.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import StripeElement from "@/components/molecules/stripe/Element.vue";
import { stripeClient } from "@/lib/stripe";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "CardFormModal",
  components: {
    SimpleModal,
    SCButton,
    CheckboxInput,
    TextInput,
    StripeElement,
    Field,
    Form,
    SCAlert,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    validPaymentMethods: Boolean,
  },
  emits: ["modalClosed"],
  data() {
    return {
      elements: [],
      elementsErrors: {
        cardNumber: [],
        cardExpiry: [],
        cardCvc: [],
      },
      elementsValidity: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
      },
    };
  },
  computed: {
    stripeElementsInvalid() {
      return !(
        this.elementsValidity.cardNumber &&
        this.elementsValidity.cardExpiry &&
        this.elementsValidity.cardCvc
      );
    },
    globalErrors() {
      if (this.formHandler.errors.$codes.length > 0) {
        return this.formHandler.errors.$codes
          .map((code) => this.$t(`errors.${code}`))
          .join(", ");
      }

      return null;
    },
  },
  async mounted() {
    const stripe = await stripeClient();

    if (!stripe) return;

    const elements = stripe.elements();

    if (!elements) return;

    const style = this.generateStyle();

    this.elements = [
      {
        el: elements.create("cardNumber", { showIcon: true, style: style }),
        id: "#card-number-element",
      },
      {
        el: elements.create("cardExpiry", { style: style }),
        id: "#card-exp-element",
      },
      {
        el: elements.create("cardCvc", { style: style }),
        id: "#card-cvc-element",
      },
    ];

    this.elements.forEach((e) => {
      e.el.mount(e.id);

      e.el.on("change", (event) => {
        if (event) {
          this.elementsValidity[event.elementType] = event.complete;
          if (event.error) {
            this.elementsErrors[event.elementType] = [event.error.message];
          } else {
            this.elementsErrors[event.elementType] = [];
          }
        }
      });
    });
  },
  methods: {
    generateStyle() {
      const appRoot = document.getElementById("app-root");

      const backgroundColor =
        getComputedStyle(appRoot).getPropertyValue("--scale-0");
      const textColor =
        getComputedStyle(appRoot).getPropertyValue("--scale-10");
      const placeholderColor =
        getComputedStyle(appRoot).getPropertyValue("--scale-3");
      const errorColor = getComputedStyle(appRoot).getPropertyValue("--error");

      return {
        base: {
          backgroundColor: backgroundColor,
          color: textColor,
          "::placeholder": {
            color: placeholderColor,
          },
          fontSize: "16px",
          lineHeight: "24px",
          paddingTop: "0.5rem",
          paddingRight: "0.75rem",
          paddingBottom: "0.5rem",
          paddingLeft: "0.75rem",
          height: "24px",
        },
        invalid: {
          iconColor: errorColor,
          color: errorColor,
        },
      };
    },
    submitForm() {
      this.$emit("formSubmitted", {
        ...this.form,
        el: this.elements[0].el,
      });
    },
  },
});
</script>

<i18n>
  en:
    title: "Add payment card"
    subtitle: "Complete your billing profile with a payment card"
    buttonName: "Add"
    setAsDefault: "Set as default payment method"
    errors:
      generic_error: "Something went wrong. Try again in a little bit or contact our support."
      generic_decline: "Your card was declined."
      insufficient_funds: "Your card has insufficient funds."
      lost_card: "Your card was declined."
      stolen_card: "Your card was declined."
      expired_card: "Your card has expired."
      incorrect_cvc: "Your card's security code is incorrect."
      processing_error: "An error occurred while processing your card. Try again in a little bit."
      incorrect_number: "Your card number is invalid."
      setup_intent_authentication_failure: "The authentication failed."
  fr:
    title: "Ajouter une carte de paiement"
    subtitle: "Compléter votre profil de facturation avec une carte de paiement"
    buttonName: "Ajouter"
    setAsDefault: "Ajouter comme methode de paiement par défaut"
    errors:
      generic_error: "Quelques chose s'est mal passé. Veuillez retenter dans quelques minutes ou contacter notre support."
      generic_decline: "Votre carte a été refusée."
      insufficient_funds: "Votre carte ne dispose pas de fonds suffisants."
      lost_card: "Votre carte a été refusée."
      stolen_card: "Votre carte a été refusée."
      expired_card: "Votre carte a expirée."
      incorrect_cvc: "Le code de sécurité de votre carte est incorrect."
      processing_error: "Une error s'est produite durant le traitement de votre carte. Veuillez retenter ultérieurement."
      incorrect_number: "Le numéro de votre est invalide."
      setup_intent_authentication_failure: "L'authentification a échouée."
</i18n>
