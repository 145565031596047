<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :containers="containersPerName"
    :containerSizes="containerSizes"
    :since="since"
    :routerInfo="routerInfo"
    :responseTimeInfo="responseTimeInfo"
    :containersInfos="containersInfos"
    :routerRPMRawData="routerRPMRawData"
    :router5XXRawData="router5XXRawData"
    :responseTimeMedianRawData="responseTimeMedianRawData"
    :responseTimep95RawData="responseTimep95RawData"
    :responseTimep99RawData="responseTimep99RawData"
    :containersRawData="containersRawData"
    :eventsRawData="eventsRawData"
    @rangeSelected="rangeSelected"
  />
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/metrics/Overview.vue";
import { upsertDataPoints } from "@/lib/metrics";
import { pendingPromiseInfo, promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import {
  ensureContainerSizes,
  listContainerSizes,
} from "@/store/container-sizes";
import { listContainers, ensureContainers } from "@/store/containers";

// The common behavior for rejections. No-op at the moment.
function metricsRejectionFn() {
  // no-op
}

export default defineComponent({
  name: "MetricsOverviewContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Config
      since: 3,
      last: false,
      intervalID: null,
      refreshInterval: null,
      // Meta operations
      routerInfo: pendingPromiseInfo(),
      responseTimeInfo: pendingPromiseInfo(),
      // Operations
      eventsInfo: null,
      routerRPMInfo: null,
      router5XXInfo: null,
      responseTimeMedianInfo: null,
      responseTimep95Info: null,
      responseTimep99Info: null,
      containersInfos: {},
      // Raw datasets
      eventsRawData: [],
      routerRPMRawData: [],
      router5XXRawData: [],
      responseTimeMedianRawData: [],
      responseTimep95RawData: [],
      responseTimep99RawData: [],
      containersRawData: {},
    };
  },
  computed: {
    scalingo() {
      return scalingoClient(this.$store, this.app.region);
    },
    containers() {
      return listContainers(this.$store);
    },
    containerSizes() {
      return listContainerSizes(this.$store, this.app.region);
    },
    containersPerName() {
      const res = {};

      this.containers.items.forEach(
        (container) => (res[container.name] = container),
      );

      return res;
    },
    commonGraphOptions() {
      if (this.last) {
        return { last: true };
      } else {
        return { since: this.since };
      }
    },
  },
  watch: {
    containers() {
      this.prepareContainersMetrics();
      this.refreshContainersMetrics();
    },
    "responseTimeMedianInfo.isSuccess": function (newVal) {
      if (newVal) {
        const datapoints = this.responseTimeMedianInfo.data;
        const first = datapoints[0];
        const second = datapoints[1];

        if (first && second) {
          const diff = DateTime.fromISO(second.time).diff(
            DateTime.fromISO(first.time),
          );

          this.refreshInterval = diff.as("milliseconds");

          return;
        }
      }

      this.refreshInterval = null;
    },
    refreshInterval(newVal) {
      if (this.intervalID) {
        clearInterval(this.intervalID);
      }

      if (newVal) {
        this.intervalID = setInterval(() => {
          this.refreshMetrics();
          this.refreshContainersMetrics();
        }, newVal);
      }
    },
  },
  mounted() {
    this.refreshContainers();
    this.refreshMetrics();
  },
  unmounted() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  methods: {
    prepareContainersMetrics() {
      this.containersInfos = {};
      this.containersRawData = {};

      this.containers.items.forEach((container) => {
        this.containersInfos[container.name] = {
          cpu: pendingPromiseInfo(),
          memory: pendingPromiseInfo(),
          swap: pendingPromiseInfo(),
          allCpu: pendingPromiseInfo(),
          allMemory: pendingPromiseInfo(),
        };

        this.containersRawData[container.name] = {
          cpu: [],
          memory: [],
          swap: [],
        };
      });
    },
    refreshContainers() {
      ensureContainers(this.$store, { staleAfter: "always" });
      ensureContainerSizes(this.$store, this.app.region);
    },
    refreshMetrics() {
      // Events needs to be refreshed before others
      this.refreshEvents();
      this.refreshRoutersRequests();
      this.refreshResponseTime();
    },
    async refreshEvents() {
      const eventsPromise = this.scalingo.Events.for(this.app.id, {
        from: this.since,
      });

      this.eventsInfo = promiseInfo(eventsPromise);

      eventsPromise.then((eventsData) => {
        this.eventsRawData.push(...eventsData.events);
      }, metricsRejectionFn);
    },
    async refreshRoutersRequests() {
      const routerRPMPromise = this.scalingo.Metrics.get(
        this.app.id,
        "router",
        this.commonGraphOptions,
      );

      const router5XXPromise = this.scalingo.Metrics.get(
        this.app.id,
        "router",
        {
          ...this.commonGraphOptions,
          statusCode: "5XX",
        },
      );

      this.routerRPMInfo = promiseInfo(routerRPMPromise);
      this.router5XXInfo = promiseInfo(router5XXPromise);

      if (!this.last) {
        this.routerInfo = promiseInfo(
          Promise.all([routerRPMPromise, router5XXPromise]),
        );
      }

      routerRPMPromise.then((routerRPMData) => {
        upsertDataPoints(this.routerRPMRawData, routerRPMData);
      }, metricsRejectionFn);

      router5XXPromise.then((router5XXData) => {
        upsertDataPoints(this.router5XXRawData, router5XXData);
      }, metricsRejectionFn);
    },
    async refreshResponseTime() {
      const responseTimeMedianPromise = this.scalingo.Metrics.get(
        this.app.id,
        "requests",
        {
          ...this.commonGraphOptions,
          statisticsType: "median",
        },
      );

      const responseTimep95Promise = this.scalingo.Metrics.get(
        this.app.id,
        "requests",
        {
          ...this.commonGraphOptions,
          statisticsType: "p95",
        },
      );

      const responseTimep99Promise = this.scalingo.Metrics.get(
        this.app.id,
        "requests",
        {
          ...this.commonGraphOptions,
          statisticsType: "p99",
        },
      );

      this.responseTimeMedianInfo = promiseInfo(responseTimeMedianPromise);
      this.responseTimep95Info = promiseInfo(responseTimep95Promise);
      this.responseTimep99Info = promiseInfo(responseTimep99Promise);

      if (!this.last) {
        this.responseTimeInfo = promiseInfo(
          Promise.all([
            responseTimeMedianPromise,
            responseTimep95Promise,
            responseTimep99Promise,
          ]),
        );
      }

      responseTimeMedianPromise.then((medianData) => {
        upsertDataPoints(this.responseTimeMedianRawData, medianData);
      }, metricsRejectionFn);

      responseTimep95Promise.then((p95Data) => {
        upsertDataPoints(this.responseTimep95RawData, p95Data);
      }, metricsRejectionFn);

      responseTimep99Promise.then((p99Data) => {
        upsertDataPoints(this.responseTimep99RawData, p99Data);
      }, metricsRejectionFn);
    },
    refreshContainersMetrics() {
      Object.keys(this.containersInfos).forEach((containerName) => {
        const containerPromises = {};

        Object.keys(this.containersRawData[containerName]).forEach(
          (statisticsType) => {
            containerPromises[statisticsType] = this.refreshContainerMetrics(
              containerName,
              statisticsType,
            );
          },
        );

        if (!this.last) {
          // There is only one dataset for cpu
          this.containersInfos[containerName].allCpu = promiseInfo(
            containerPromises.cpu,
          );

          // But there is two for the memory
          this.containersInfos[containerName].allMemory = promiseInfo(
            Promise.all([containerPromises.memory, containerPromises.swap]),
          );
        }
      });

      this.last = true;
    },
    async refreshContainerMetrics(containerName, statisticsType) {
      const promise = this.scalingo.Metrics.get(this.app.id, statisticsType, {
        ...this.commonGraphOptions,
        containerType: containerName,
      });

      this.containersInfos[containerName][statisticsType] =
        promiseInfo(promise);

      promise.then((data) => {
        upsertDataPoints(
          this.containersRawData[containerName][statisticsType],
          data,
        );
      }, metricsRejectionFn);

      return promise;
    },
    rangeSelected(since) {
      this.last = false;
      this.since = since;
      this.eventsRawData = [];
      this.routerRPMRawData = [];
      this.router5XXRawData = [];
      this.responseTimeMedianRawData = [];
      this.responseTimep95RawData = [];
      this.responseTimep99RawData = [];
      this.containersRawData = {};
      this.refreshInterval = null;
      this.intervalID = null;

      this.refreshContainers();
      this.refreshMetrics();
    },
  },
});
</script>
