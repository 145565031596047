<template>
  <div class="flex flex-col items-center pt-6">
    <div class="h-20 w-20 rounded-full flex items-center justify-center">
      <Spinner class="h-10 w-10 text-scale-5"></Spinner>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";

export default defineComponent({
  name: "LoadingCardState",
  components: { Spinner },
});
</script>
