export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "larger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Larger font"])},
        "smaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smaller font"])}
      },
      "fr": {
        "larger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus grande police"])},
        "smaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus petite police"])}
      }
    }
  })
}
