export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub Enterprise"])},
        "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Hosted GitLab instance"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also connect a"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or a"])},
        "optionGitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to see Gitlab self hosted way"])},
        "optionGithub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to see Github entreprise way"])}
      },
      "fr": {
        "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub Enterprise"])},
        "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Hosted GitLab"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez aussi connecter un"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou une instance"])},
        "optionGitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour voir l'option Gitlab self hosted"])},
        "optionGithub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour voir l'option Github entreprise"])}
      }
    }
  })
}
