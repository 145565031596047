import { User, UserPreferences } from "scalingo/lib/models/auth";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { updatePreferences } from "@/store/user";

import type { ComponentPublicInstance } from "vue";

type FormData = UserPreferences;
export class EditUserPreferencesHandler extends FormHandler<User> {
  keyPath = "userPreferences.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly user: User,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return { ...this.user.preferences };
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await updatePreferences(this.$store, event));
  }
}
