<template>
  <SCBanner :kind="kind" align="center">
    <div>
      <div class="text-center">
        {{ $t("text") }}
      </div>
      <div>
        <div class="text-center">
          Database consent:
          <span v-if="databasesUntil">
            Until {{ formatDateTime(databasesUntil) }}
          </span>
          <span v-else class="animate-pulse">None</span>
        </div>
        <div class="text-center">
          Container consent:
          <span v-if="containersUntil">
            Until {{ formatDateTime(containersUntil) }}
          </span>
          <span v-else class="animate-pulse">None </span>
        </div>
      </div>
    </div>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "AdminAccessBanner",
  components: { SCBanner },
  props: {
    dataAccessConsent: Object,
  },
  computed: {
    kind() {
      if (!this.databasesUntil || !this.containersUntil) {
        return "error";
      }
      return "admin";
    },
    databasesUntil() {
      return this.dataAccessConsent?.databases_until;
    },
    containersUntil() {
      return this.dataAccessConsent?.containers_until;
    },
  },
  methods: {
    formatDateTime,
  },
});
</script>

<i18n>
en:
  text: "You are allowed to see this app because you have administrator rights. Be careful of your actions."
fr:
  text: "You are allowed to see this app because you have administrator rights. Be careful of your actions."
</i18n>
