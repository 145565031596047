<template>
  <div>
    <Pill v-if="item._requireManualAction" type="neutral" class="ml-2 py-1">
      {{ $t("manualActionRequired") }}
    </Pill>

    <Pill v-else-if="item._acmeError" type="danger" class="ml-2 py-1">
      <template v-if="item._acmeError === 'dnsProvider'">
        {{ $t("dnsProviderError") }}
      </template>
      <template v-else-if="item._acmeError === 'challenge'">
        {{ $t("challengeError") }}
      </template>
      <template v-else>
        {{ $t("genericError") }}
      </template>
    </Pill>

    <Pill v-else-if="item._isExpired" type="danger" class="ml-2 py-1">
      {{ $t("expired") }}
    </Pill>

    <Pill v-else-if="item._willSoonExpire" type="neutral" class="ml-2 py-1">
      {{ $t("willExpire") }}
    </Pill>

    <Pill
      v-if="item._detailedSSLStatus === 'success'"
      type="tag"
      class="ml-2 py-1"
      :title="$t('certificateTitle', { date: formatDateTime(item.validity) })"
    >
      <SVGIconKey class="inline" />
    </Pill>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Pill from "@/components/atoms/pills/Pill.vue";
import SVGIconKey from "@/components/graphics/icons/Key.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "DomainMessage",
  components: { SVGIconKey, Pill },
  props: {
    item: Object,
  },
  methods: {
    formatDateTime,
  },
});
</script>

<i18n>
en:
  expired: "Certificate outdated"
  willExpire: "Certificate soon outdated"
  manualActionRequired: "manual action required"
  dnsProviderError: "DNS config. error"
  challengeError: "Let’s Encrypt failure"
  genericError: "Let’s Encrypt failure"
  certificateTitle: "Custom certificate - valid until {date}"
fr:
  expired: "Certificat expiré"
  willExpire: "Certificat bientot expiré"
  manualActionRequired: "action manuelle requise"
  dnsProviderError: "Erreur de config. DNS"
  challengeError: "Let’s Encrypt failure"
  genericError: "Let’s Encrypt failure"
  certificateTitle: "Certificat personnalisé - valide jusqu'au {date}"
</i18n>
