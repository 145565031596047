export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains attached to your app."])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items here"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains will appear here once created."])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a domain"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines rattaché à votre app"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "none": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun élément"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les noms de domaines apparaitront ici une fois ajoutés."])},
          "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nom de domaine"])}
        }
      }
    }
  })
}
