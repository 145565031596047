import { Fallback, MiddlewareErrors, TOKEN_PATH } from "@/lib/auth/utils";

export class Logout implements Fallback {
  name = "Logout";

  constructor(readonly authBaseUrl: string) {}

  get logoutUrl(): string {
    return `${this.authBaseUrl}/logout`;
  }

  call(_errors: MiddlewareErrors): void {
    window.localStorage.removeItem(TOKEN_PATH);
    window.location.replace(this.logoutUrl);
  }
}
