export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
        "enterFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter fullscreen"])},
        "exitFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit fullscreen"])},
        "routerLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router logs"])},
        "logsStreaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs streaming"])},
        "autoScroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-scroll"])},
        "smartColoration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloration"])}
      },
      "fr": {
        "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoyer"])},
        "enterFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer en plein écran"])},
        "exitFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortir du plein écran"])},
        "routerLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs router"])},
        "logsStreaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs streaming"])},
        "autoScroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-scroll"])},
        "smartColoration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coloration"])}
      }
    }
  })
}
