export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add SEPA account"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a SEPA mechanism in your facturation profile"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un compte SEPA"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un mécanisme SEPA dans votre profil de facturation"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
      }
    }
  })
}
