export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "creationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token created"])},
        "renewalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token renewed"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "newTokenValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new token is:"])},
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store it in a safe place, since this token won't be shown again and will have full access to your Scalingo account."])}
      },
      "fr": {
        "creationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token créé"])},
        "renewalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token renouvellé"])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
        "newTokenValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nouveau token est:"])},
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegardez le dans un endroit sûr, ce token ne sera plus visible ensuite et permet un accès complet a votre compte Scalingo."])}
      }
    }
  })
}
