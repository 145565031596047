export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All containers restarted"])},
          "some": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Containers of type ", _interpolate(_named("type")), " restarted"])}
        }
      },
      "fr": {
        "title": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les containers redémarrés"])},
          "some": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Containers de type ", _interpolate(_named("type")), " redémarrés"])}
        }
      }
    }
  })
}
