<template>
  <main>
    <ListSCMIntegrations
      :scmIntegrations="scmIntegrations"
      @startIntegrationDeletion="(e) => $emit('startIntegrationDeletion', e)"
      @connectSCM="(e) => $emit('connectSCM', e)"
    />

    <CreateHostedSCMIntegration
      v-if="createCtx"
      :context="createCtx"
      @close="$emit('cancelHostedSCMConnection')"
      @submit="(e) => $emit('confirmHostedSCMConnection', e)"
    />

    <ConfirmSCMIntegrationDeletion
      v-if="deleteCtx"
      :context="deleteCtx"
      @close="$emit('cancelIntegrationDeletion')"
      @confirm="$emit('confirmIntegrationDeletion')"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ConfirmSCMIntegrationDeletion from "@/components/organisms/modals/ConfirmSCMIntegrationDeletion.vue";
import CreateHostedSCMIntegration from "@/components/organisms/modals/CreateHostedSCMIntegration.vue";
import ListSCMIntegrations from "@/components/parts/account/integrations/ListSCMIntegrations.vue";

export default defineComponent({
  name: "Integrations",
  components: {
    ConfirmSCMIntegrationDeletion,
    ListSCMIntegrations,
    CreateHostedSCMIntegration,
  },
  props: {
    scmIntegrations: Object,
    createCtx: Object,
    deleteCtx: Object,
  },
  emits: [
    "cancelHostedSCMConnection",
    "cancelIntegrationDeletion",
    "confirmHostedSCMConnection",
    "confirmIntegrationDeletion",
    "connectSCM",
    "startIntegrationDeletion",
  ],
});
</script>
