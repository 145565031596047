export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename an app"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want delete this app?"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm, type the name of the app below:"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
        "labels": {
          "newAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New name of the app"])},
          "currentAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current name of the app"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer une app"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour confirmer, entrez le nom de l'app ci dessous:"])},
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous vraiment supprimer cette app ?"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
        "labels": {
          "newAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New name of the app"])},
          "currentAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current name of the app"])}
        }
      }
    }
  })
}
