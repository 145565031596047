export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Metrics per container (", _interpolate(_named("name")), ")"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key indicators of performance and consumption for each individual container."])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application metrics"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Métriques par conteneur (", _interpolate(_named("name")), ")"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicateurs clés de performance et de consommation pour chaque conteneur."])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métriques de l'application"])}
      }
    }
  })
}
