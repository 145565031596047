import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { Domain, createDomain } from "@/store/domains";

export interface FormData {
  name: string;
}

export class CreateDomainHandler extends FormHandler<Domain> {
  keyPath = "domains.create";

  data(): Nullable<FormData> {
    return {
      name: null,
    };
  }

  async submit(form: FormData): Promise<void> {
    this.follow(await createDomain(this.$store, form));
  }
}
