<template>
  <svg
    class="h-6 w-6"
    width="22"
    height="6"
    viewBox="0 0 22 6"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.0001 0.600098C1.6745 0.600098 0.600098 1.6745 0.600098 3.0001C0.600098 4.3257 1.6745 5.4001 3.0001 5.4001C4.3257 5.4001 5.4001 4.3257 5.4001 3.0001C5.4001 1.6745 4.3257 0.600098 3.0001 0.600098ZM11.0001 0.600098C9.6745 0.600098 8.6001 1.6745 8.6001 3.0001C8.6001 4.3257 9.6745 5.4001 11.0001 5.4001C12.3257 5.4001 13.4001 4.3257 13.4001 3.0001C13.4001 1.6745 12.3257 0.600098 11.0001 0.600098ZM19.0001 0.600098C17.6745 0.600098 16.6001 1.6745 16.6001 3.0001C16.6001 4.3257 17.6745 5.4001 19.0001 5.4001C20.3257 5.4001 21.4001 4.3257 21.4001 3.0001C21.4001 1.6745 20.3257 0.600098 19.0001 0.600098Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HorizontalDotsGlyph",
});
</script>
