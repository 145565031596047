import Vuex, { createLogger, Plugin } from "vuex";

import { availableRegions } from "@/lib/scalingo/client";

import { AlertsStore } from "./alerts";
import { AppsStore } from "./apps";
import { AuditLogsStore } from "./audit-logs";
import { AutoscalersStore } from "./autoscalers";
import { BalanceOperationsStore } from "./balance-operations";
import { BillingProfileStore } from "./billing-profile";
import { BlogArticlesStore } from "./blog-articles";
import { ChangelogEntriesStore } from "./changelog-entries";
import { CollaboratorsStore } from "./collaborators";
import { ContainersStore } from "./containers";
import { ContractsStore } from "./contracts";
import { CronTasksStore } from "./cron-tasks";
import { DeploymentLogsStore } from "./deployment-logs";
import { DeploymentsStore } from "./deployments";
import { DomainsStore } from "./domains";
import { EventsStore } from "./events";
import { InvoicesStore } from "./invoices";
import { KeysStore } from "./keys";
import { LogArchivesStore } from "./log-archives";
import { LogsStore } from "./logs";
import { NotifiersStore } from "./notifiers";
import { OperationsStore } from "./operations";
import { PaymentMethodsStore } from "./payment-methods";
import { ReferralStatsStore } from "./referral-stats";
import { RegionStore } from "./region-store";
import { ReviewAppsStore } from "./review-apps";
import { SCMIntegrationsStore } from "./scm-integrations";
import { ScmRepoLinkStore } from "./scm-repo-link";
import { SessionStore } from "./session";
import { TokensStore } from "./tokens";
import { TwoFactorAuthStore } from "./two_factor_auth";
import { UserStore } from "./user";
import { UserEventsStore } from "./user-events";
import { VariablesStore } from "./variables";
import { VouchersStore } from "./vouchers";

const plugins: Plugin<unknown>[] = [];

// Set a value to this environment var to enable the logging in the dev tools console
if (process.env.VUE_APP_ENABLE_LOGGER) {
  plugins.push(createLogger());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const modules: Record<string, any> = {
  session: new SessionStore(),
  apps: new AppsStore(),
  alerts: new AlertsStore(),
  autoscalers: new AutoscalersStore(),
  auditLogs: new AuditLogsStore(),
  balanceOperations: new BalanceOperationsStore(),
  billingProfile: new BillingProfileStore(),
  blogArticles: new BlogArticlesStore(),
  changelogEntries: new ChangelogEntriesStore(),
  collaborators: new CollaboratorsStore(),
  containers: new ContainersStore(),
  contracts: new ContractsStore(),
  cronTasks: new CronTasksStore(),
  deployments: new DeploymentsStore(),
  deploymentLogs: new DeploymentLogsStore(),
  domains: new DomainsStore(),
  events: new EventsStore(),
  invoices: new InvoicesStore(),
  keys: new KeysStore(),
  logs: new LogsStore(),
  logArchives: new LogArchivesStore(),
  notifiers: new NotifiersStore(),
  operations: new OperationsStore(),
  paymentMethods: new PaymentMethodsStore(),
  scmIntegrations: new SCMIntegrationsStore(),
  scmRepoLink: new ScmRepoLinkStore(),
  referralStats: new ReferralStatsStore(),
  reviewApps: new ReviewAppsStore(),
  tfa: new TwoFactorAuthStore(),
  tokens: new TokensStore(),
  user: new UserStore(),
  userEvents: new UserEventsStore(),
  variables: new VariablesStore(),
  vouchers: new VouchersStore(),
};

availableRegions.forEach((region) => {
  modules[region.code] = new RegionStore();
});

export const applicationStore = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production",
  plugins,
});

export type ApplicationStore = typeof applicationStore;

export default applicationStore;
