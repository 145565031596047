import cronstrue from "cronstrue/i18n";

export function humanCronSchedule(schedule: string, locale: string): string {
  return cronstrue.toString(schedule, {
    locale,
    use24HourTimeFormat: true,
    monthStartIndexZero: false,
    dayOfWeekStartIndexZero: true,
    verbose: true,
    throwExceptionOnParseError: true,
  });
}
