export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox plans have no backup or support, and their technical characteristics will only allow very limited workloads."])}
      },
      "fr": {
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plans sandbox ne disposent ni de sauvegarde, ni de support et les caractéristiques techniques ne supporteront que des charges de travail très limitées."])}
      }
    }
  })
}
