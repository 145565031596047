import { isObject } from "lodash";
import { DateTime } from "luxon";

import { promiseInfo, PromiseInfo } from "@/lib/promises/info";

/**
 * This file can be named either scalingo.json or app.json from a github repository url
 */
export class ManifestParser {
  hostname: string | null = null;
  owner: string | null = null;
  repo: string | null = null;
  ref = "master";

  constructor(
    readonly source: string,
    hash: string,
  ) {
    // Note: the parsing of the source url is done in `parseSource` below
    if (hash.length > 1) {
      const hashParts = hash.split("#");

      this.ref = hashParts[0] || hashParts[1] || this.ref;
    }
  }

  get manifest(): PromiseInfo<string> {
    const promise = this.parseSource().then(() => {
      return this.fetchAndReadFile("scalingo.json").catch(() => {
        return this.fetchAndReadFile("app.json");
      });
    });

    return promiseInfo<string>(promise);
  }

  private parseSource(): Promise<void> {
    const makeError = (error: string): Promise<void> => {
      return Promise.reject({ error, source: this.source });
    };

    // Making sure we are given a url. Otherwise, strings will be assumed to be a path relative to the dashboard
    if (
      !this.source.startsWith("http://") &&
      !this.source.startsWith("https://")
    ) {
      return makeError("parsing/source");
    }

    // Leveraging DOM apis to parse the uri into fragments
    const parser = document.createElement("a");
    parser.href = this.source;

    const hostname = parser.hostname;
    const parts = parser.pathname.split("/");
    const owner = parts[1];
    const repo = parts[2];

    // Missing hostname is unlikely but not impossible
    if (!hostname) return makeError("parsing/hostname");
    if (!owner) return makeError("parsing/owner");
    if (!repo) return makeError("parsing/repo");

    this.hostname = hostname;
    this.owner = owner;
    this.repo = repo;

    return Promise.resolve();
  }

  private async fetchAndReadFile(file: string): Promise<string> {
    const url = `https://api.github.com/repos/${this.owner}/${this.repo}/contents/${file}?ref=${this.ref}`;
    const headers = { Accept: "application/vnd.github.v3.raw" };

    let response, manifest;

    try {
      response = await fetch(url, { headers });

      if (!response.ok) {
        if (response.headers.get("x-ratelimit-remaining") === "0") {
          const reset = parseInt(
            response.headers.get("x-ratelimit-reset") as string,
            10,
          );

          return Promise.reject({
            error: "fetch-ratelimit",
            source: this.source,
            reset: DateTime.fromSeconds(reset),
          });
        } else {
          return Promise.reject({
            error: "fetch-response",
            status: response.status,
            source: this.source,
            ref: this.ref,
            response,
          });
        }
      }
    } catch (e) {
      return Promise.reject({
        error: "fetch-request",
        source: this.source,
        ref: this.ref,
        e,
      });
    }

    try {
      manifest = await response.json();
    } catch (e) {
      return Promise.reject({ error: "manifest-parsing", file, e });
    }

    manifest.source = this.source;
    manifest.ref = this.ref;
    manifest.treeUrl = `${this.source}/tree/${this.ref}`;
    manifest.archiveUrl = `${this.source}/archive/${this.ref}.tar.gz`;

    manifest.deploymentPayload = {
      git_ref: manifest.ref,
      source_url: manifest.archiveUrl,
    };

    if (isObject(manifest.scripts)) {
      manifest.deploymentPayload.postdeploy_hook =
        manifest.scripts["first-deploy"] || manifest.scripts.postdeploy;
    }

    return manifest;
  }
}
