export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual backup limit"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the limit of backups allocated by your plan (", _interpolate(_named("max")), "). Running a new backup will automatically delete the oldest backup. This does not affect scheduled backups."])},
        "upsellText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to go from 10 to 50 manual backup?"])},
        "upsellLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to a Business plan."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a new manual backup"])}
      },
      "fr": {
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite des backups manuels"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez atteint la limite de backup allouée par votre plan (", _interpolate(_named("max")), "). L'exécution d'un nouveau backup supprimera automatiquement le plus ancien. Cela n'affecte pas les backups planifiés."])},
        "upsellText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous passer de 10 à 50 backups manuels ?"])},
        "upsellLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez à un plan Business."])},
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer un nouveau backup manuel"])}
      }
    }
  })
}
