<template>
  <main class="flex flex-col space-y-4 mt-8">
    <Card>
      <template v-slot:header>
        <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
          <template v-if="queryStatisticsActive" #buttons>
            <SCButton
              :loading="fakeLoadRefresh"
              @click="
                getQueriesStatistics();
                setLoadingButton('fakeLoadRefresh');
              "
            >
              {{ $t("refresh") }}
            </SCButton>
            <SCButton
              class="ml-2"
              :loading="fakeLoadReset"
              :disabled="queryStatisticsList.length === 0"
              @click="
                pgResetQueriesStatistics();
                setLoadingButton('fakeLoadReset');
              "
            >
              {{ $t("reset") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template v-slot:body>
        <LoadingCardState v-if="isLoading" />
        <div v-else id="table-container" class="overflow-x-auto">
          <QueryStatisticsList
            v-if="queryStatisticsActive"
            :queryStatisticsList="queryStatisticsList"
          >
          </QueryStatisticsList>
          <ActivateQueryStatistics
            v-else
            :pgActivateQueryStatistics="pgActivateQueryStatistics"
          ></ActivateQueryStatistics>
        </div>
      </template>
    </Card>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ActivateQueryStatistics from "@/components/parts/db/queryStatistics/ActivateQueryStatistics.vue";
import QueryStatisticsList from "@/components/parts/db/queryStatistics/QueryStatisticsList.vue";

export default defineComponent({
  name: "DbQueryStatistics",
  components: {
    ActivateQueryStatistics,
    QueryStatisticsList,
    CardHeader,
    Card,
    SCButton,
    LoadingCardState,
  },
  props: {
    db: {
      type: Object,
      required: true,
    },
    extensionList: {
      type: Array,
      required: false,
    },
    pgActivateQueryStatistics: {
      type: Function,
      required: true,
    },
    queryStatisticsActive: {
      type: Boolean,
      required: true,
    },
    queryStatisticsList: {
      type: Array,
      required: true,
    },
    getQueriesStatistics: {
      type: Function,
      required: true,
    },
    pgResetQueriesStatistics: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      fakeLoadReset: false,
      fakeLoadRefresh: false,
    };
  },
  methods: {
    setLoadingButton(varName) {
      this[varName] = true;
      setTimeout(() => {
        this[varName] = false;
      }, 500);
    },
  },
});
</script>

<i18n>
  en:
    title: "Query Statistics"
    subtitle: "Optimize your application performance by analyzing and improving the efficiency of your PostgreSQL queries."
    refresh: "Refresh"
    reset: "Reset"
  fr:
    title: "Statistiques des requêtes"
    subtitle: "Optimisez les performances de vos applications en analysant et améliorant l'efficacité de vos requêtes PostgreSQL."
    refresh: "Actualiser"
    reset: "Réinitialiser"
</i18n>
