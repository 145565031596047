export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator data access"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify which data operators are allowed to inspect."])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases metadata and monitoring data"])},
        "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application runtime environment"])},
        "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure"])},
        "more": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support operators & data access"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learn more"])},
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the context of customer support, our operators may have to inspect your containeurs or databases to help their diagnostics."])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Except in emergencies, these access are only performed after explicit consent and in the full respect of your data's integrity and privacy."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès aux données par l'opérateur"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez quelles données nos opérateurs sont autorisés à inspecter."])},
        "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métadonnées et données de surveillance des bases de données"])},
        "containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement d'exécution de l'application"])},
        "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jusqu'au"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer"])},
        "more": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateurs de support & accès aux données"])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en savoir plus"])},
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cadre du support, nos opérateurs peuvent être amenés à inspecter vos containers ou bases de données à des fins de diagnostic."])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauf urgence, ces accès se font uniquement après consentement explicite et dans le respect de l'intégrité et la confidentialité de vos données."])}
        }
      }
    }
  })
}
