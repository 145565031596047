import { App } from "scalingo/lib/models/regional";
import { AlertWithNotifiers } from "scalingo/lib/models/regional/alerts";
import {
  CreateParams,
  UpdateParams,
} from "scalingo/lib/params/regional/alerts";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  HANDLE_FETCH,
  REFRESH,
  CREATE,
  HANDLE_OPERATION,
  DESTROY,
  UPDATE,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { DELETE, SET_ALL } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";
import { useCurrentAppStore } from "@/stores/current/app";

import { ApplicationStore } from ".";

export class AlertsStore extends CollectionStore<AlertWithNotifiers> {
  actions = CollectionStore.buildActions<AlertWithNotifiers>({
    [REFRESH](context) {
      const currentApp = useCurrentAppStore().regional as App;
      const promise = scalingoClient(context, currentApp.region).Alerts.for(
        currentApp.id,
      );

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ALL,
      });
    },
    [CREATE](context, payload = {}) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Alerts.create(
          currentApp.id,
          payload,
        ),
        // GET /alerts/:id don't return the notifier ids, so we do a full refresh from the index
        resolveAction() {
          context.dispatch(REFRESH);
        },
      });
    },
    [UPDATE](context, { id, payload }) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Alerts.update(
          currentApp.id,
          id,
          payload,
        ),
        // GET /alerts/:id don't return the notifier ids, so we do a full refresh from the index
        resolveAction() {
          context.dispatch(REFRESH);
        },
      });
    },
    [DESTROY](context, { alert }) {
      const currentApp = useCurrentAppStore().regional as App;

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Alerts.destroy(
          currentApp.id,
          alert.id,
        ),
        resolveAction: () => context.commit(DELETE, alert),
      });
    },
  });
  mutations = CollectionStore.buildMutations<AlertWithNotifiers>();
  getters = CollectionStore.buildGetters<AlertWithNotifiers>();
}

export const Alerts = buildMapping(new AlertsStore(), "alerts");

export function listAlerts(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<AlertWithNotifiers>>,
): CollectionWithFetch<AlertWithNotifiers> {
  return {
    items: listItems(store.getters[Alerts.getters.ALL], opts),
    latestFetch: store.getters[Alerts.getters.LATEST_FETCH],
  };
}

export function findAlert(
  store: ApplicationStore,
  id: string,
): AlertWithNotifiers {
  return store.getters[Alerts.getters.FIND](id);
}

export function createAlert(
  store: ApplicationStore,
  payload: CreateParams,
): Promise<RemoteOperation<AlertWithNotifiers>> {
  return store.dispatch(Alerts.actions.CREATE, payload);
}

export function updateAlert(
  store: ApplicationStore,
  alert: AlertWithNotifiers,
  payload: UpdateParams,
): Promise<RemoteOperation<AlertWithNotifiers>> {
  return store.dispatch(Alerts.actions.UPDATE, { id: alert.id, payload });
}

export function ensureAlerts(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(Alerts.actions.ENSURE, opts);
}

export function deleteAlert(
  store: ApplicationStore,
  alert: AlertWithNotifiers,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Alerts.actions.DESTROY, { alert });
}
