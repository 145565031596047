import { Nullable } from "@/lib/utils/types";

export type ItemsTransformOption<T> = (coll: T[]) => T[];
export type FilterItemsOptions<T> = Nullable<{
  sortBy: keyof T;
  sortDirection: "asc" | "desc";
  transform?: ItemsTransformOption<T>;
  limit: number;
}>;

export const defaultFilterOptions = {
  sortBy: null,
  sortDirection: null,
  transform: null,
  limit: null,
};

// When supplied with an array, returns an array
export function filterItems<T>(
  sourceItems: T[],
  options?: Partial<FilterItemsOptions<T>>,
): T[];

// When supplied with null, returns null
export function filterItems<T>(
  sourceItems: null,
  options?: Partial<FilterItemsOptions<T>>,
): null;

// Actual impl
export function filterItems<T>(
  sourceItems: T[] | null,
  options: Partial<FilterItemsOptions<T>> = {},
): typeof sourceItems {
  if (!sourceItems) return null;

  // sort is in-place, and we have to avoid mutating the original object
  let items = sourceItems.slice();

  options = {
    ...defaultFilterOptions,
    ...options,
  };

  const { sortBy, sortDirection, limit, transform } = options;

  if (sortBy) {
    const whenGreater = sortDirection === "desc" ? -1 : 1;
    const whenLower = whenGreater * -1;

    items.sort((a, b) => {
      if (a[sortBy] && !b[sortBy]) { return whenGreater; } // eslint-disable-line prettier/prettier
      if (!a[sortBy] && b[sortBy]) { return whenLower; } // eslint-disable-line prettier/prettier
      if (a[sortBy] > b[sortBy]) { return whenGreater; } // eslint-disable-line prettier/prettier
      if (a[sortBy] < b[sortBy]) { return whenLower; } // eslint-disable-line prettier/prettier
      return 0;
    });
  }

  if (!sortBy && sortDirection === "desc") {
    // Reverse mutates in place
    items = items.reverse();
  }

  if (transform) {
    items = transform(items);
  }

  if (limit) {
    items = items.slice(0, limit);
  }

  return items;
}
