<template>
  <span :title="relative">
    {{ absolute }}
  </span>
</template>

<script>
import { defineComponent } from "vue";

import { formatDateTime, relativeDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "AbsoluteDate",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    absolute() {
      return formatDateTime(this.value, "ff");
    },
    relative() {
      return relativeDateTime(this.value);
    },
  },
});
</script>
