<template>
  <SCBanner :kind="bannerStyle">
    <template #icon>
      <ReloadGlyph v-if="ongoing" :animated="true" />
      <ThumbUp v-if="success" />
      <ThumbDown v-if="failure" class="place-self-center" />
    </template>

    <template v-if="ongoing">
      <router-link class="hover:underline" :to="showRouteFor(deployment)">
        {{
          $t("ongoing", {
            status: $ta("deployment", `statuses.${deployment.status}`),
          })
        }}
      </router-link>
    </template>

    <template v-if="success">
      {{ $t("success") }}
    </template>

    <template v-if="failure">
      {{
        $t("failure", {
          status: $ta("deployment", `statuses.${deployment.status}`),
        })
      }}
    </template>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import ThumbDown from "@/components/atoms/glyphs/ThumbDown.vue";
import ThumbUp from "@/components/atoms/glyphs/ThumbUp.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { shortGitRef } from "@/lib/utils/git";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";
import {
  STATUSES_ONGOING,
  STATUSES_SUCCESSES,
  STATUSES_FAILURES,
} from "@/store/deployments";

export default defineComponent({
  name: "DeploymentBanner",
  components: { ReloadGlyph, SCBanner, ThumbDown, ThumbUp },
  mixins: [ModelsTranslation],
  props: {
    deployment: Object,
  },
  computed: {
    bannerStyle() {
      if (this.success) return "success";
      if (this.failure) return "error";

      return "warning";
    },
    ongoing() {
      return STATUSES_ONGOING.includes(this.deployment?.status);
    },
    success() {
      return STATUSES_SUCCESSES.includes(this.deployment?.status);
    },
    failure() {
      return STATUSES_FAILURES.includes(this.deployment?.status);
    },
  },
  methods: {
    shortGitRef,
    showRouteFor(deployment) {
      return {
        name: Routes.App.Deploy.List.Show,
        params: {
          deploymentId: deployment.id,
        },
      };
    },
  },
});
</script>

<i18n>
  en:
    ongoing: "Deployment in progress ({status})…"
    success: "Successful deployment!"
    failure: "Deployment failed ({status})."
  fr:
    ongoing: "Deploiement en cours ({status}) …"
    success: "Déploiement réussi!"
    failure: "Deploiement échoué ({status})."
</i18n>
