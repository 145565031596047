<template>
  <ViewComponent
    :keys="keys"
    :deleteKeyHandler="deleteKeyHandler"
    :createKeyHandler="createKeyHandler"
    @startKeyCreation="startKeyCreation"
    @cancelKeyCreation="cancelKeyCreation"
    @submitKeyCreation="(e) => createKeyHandler.submit(e)"
    @startKeyDeletion="startKeyDeletion"
    @cancelKeyDeletion="cancelKeyDeletion"
    @confirmKeyDeletion="(e) => deleteKeyHandler.submit(e)"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/account/Keys.vue";
import { CreateSshKeyHandler } from "@/lib/handlers/create/ssh-key";
import { DeleteSshKeyHandler } from "@/lib/handlers/delete/ssh-key";
import { allKeys, ensureKeys } from "@/store/keys";

export default defineComponent({
  name: "KeysContainer",
  components: { ViewComponent },
  data() {
    return {
      createKeyHandler: null,
      deleteKeyHandler: null,
    };
  },
  computed: {
    keys() {
      return allKeys(this.$store);
    },
  },
  beforeMount() {
    ensureKeys(this.$store);
  },
  methods: {
    startKeyDeletion({ key }) {
      this.deleteKeyHandler = new DeleteSshKeyHandler(this, key);
    },
    cancelKeyDeletion() {
      this.deleteKeyHandler = null;
    },
    startKeyCreation() {
      this.createKeyHandler = new CreateSshKeyHandler(this);
    },
    cancelKeyCreation() {
      this.createKeyHandler = null;
    },
  },
});
</script>
