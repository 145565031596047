<template>
  <SCTable
    class="mt-6"
    layout="fixed"
    :headers="headers"
    :items="tokens.items"
    :paginated="true"
    :searchable="true"
    :searchFn="searchFn"
  >
    <template #item.name="{ item }"> {{ item.name }}</template>
    <template #item.issuedAt="{ item }">
      <AbsoluteDate :value="item.issued_at" />
    </template>
    <template #item.lastUsedAt="{ item }">
      <AbsoluteDate v-if="item.last_used_at" :value="item.last_used_at" />
    </template>

    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <SCTableAction
          :title="$t('renew')"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('startTokenRenewal', { token: item })"
        >
          <font-awesome-icon icon="redo" class="h-4 w-4" />
        </SCTableAction>
        <SCTableAction
          :title="$t('delete')"
          type="delete"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('startTokenDeletion', { token: item })"
        >
          <BinGlyph class="h-4 w-4" />
        </SCTableAction>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import AbsoluteDate from "@/components/atoms/presenters/AbsoluteDate.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import ModelsTranslation from "@/mixins/models_translation";

const searchFn = (row, search) => {
  return (
    row.name
      .toString()
      .toLocaleLowerCase()
      .indexOf(search.toLocaleLowerCase()) !== -1
  );
};

export default defineComponent({
  name: "TokensList",
  components: {
    AbsoluteDate,
    BinGlyph,
    SCTableAction,
    SCTable,
  },
  mixins: [ModelsTranslation],
  props: {
    tokens: Object,
  },
  emits: ["startTokenDeletion", "startTokenRenewal"],
  data() {
    return {
      headers: [
        { value: "name", text: this.$ta("token", "name"), class: "w-3/6" },
        {
          value: "issuedAt",
          text: this.$ta("token", "issuedAt"),
          class: "w-2/6",
        },
        {
          value: "lastUsedAt",
          text: this.$ta("token", "lastUsedAt"),
          class: "w-2/6",
        },
        { value: "actions", text: "", align: "right", class: "w-1/6" },
      ],
      searchFn: searchFn,
    };
  },
});
</script>

<i18n>
en:
  title: "My tokens"
  renew: "Renew"
  delete: "Delete"
  noTokens: "You do not have tokens yet."
fr:
  title: "Mes tokens"
  renew: "Renouveller"
  delete: "Supprimer"
  noTokens: "Vous n'avez pas encore crée de tokens."
</i18n>
