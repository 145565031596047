export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "labels": {
          "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon"])}
        },
        "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as CSV"])},
        "exportJson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as JSON"])},
        "mergeReviewApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge review apps consumption with their parent app"])},
        "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])}
      },
      "fr": {
        "labels": {
          "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon"])}
        },
        "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en CSV"])},
        "exportJson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en JSON"])},
        "mergeReviewApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionner la consommation des Review App avec leur application parente"])},
        "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouper par"])}
      }
    }
  })
}
