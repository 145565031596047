<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :addon="addon"
    :db="db"
    :dbVersion="dbVersion"
    :dbPlan="dbPlan"
    :lastBackup="lastBackup"
    :oldestBackup="oldestBackup"
    :dbMetrics="dbMetrics"
    :variables="variables"
    @showAvailableUpgrade="redirectToSettingsGeneral"
  ></ViewComponent>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import ViewComponent from "@/components/views/db/DbOverview.vue";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import { Routes } from "@/router/names";
import { ensureVariables, listVariables } from "@/store/variables";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbBackupsStore } from "@/stores/db/backups";
import { useDbMetricsStore } from "@/stores/db/metrics";
import { useDbPlanStore } from "@/stores/db/plan";
import { useDbVersionStore } from "@/stores/db/version";

export default defineComponent({
  name: "DbOverviewContainer",
  components: { ViewComponent },
  setup() {
    const store = useStore();
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();
    const dbVersionStore = useDbVersionStore();
    const dbBackupsStore = useDbBackupsStore();
    const dbMetricsStore = useDbMetricsStore();
    const dbPlanStore = useDbPlanStore();
    const router = useRouter();

    onBeforeMount(() => {
      dbBackupsStore.ensure();
      dbMetricsStore.ensure({ staleAfter: "always" });
      onBeforeMount(() => dbPlanStore.ensure());
      ensureVariables(store, { staleAfter: "always" });
    });

    function redirectToSettingsGeneral() {
      const app = currentAppStore.appInfoOrFull;

      router.push({
        name: Routes.Db.General,
        params: { region: app.region, id: app.name },
      });
    }

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
      addon: computed(() => currentDbStore.addon),
      redirectToSettingsGeneral,
      dbVersion: computed(() => {
        return {
          item: dbVersionStore.item,
          promiseInfo: dbVersionStore.promiseInfo,
        };
      }),
      dbMetrics: computed(() => {
        return {
          item: dbMetricsStore.item,
          promiseInfo: dbMetricsStore.promiseInfo,
        };
      }),
      dbPlan: computed(() => {
        return {
          item: dbPlanStore.item,
          promiseInfo: dbPlanStore.promiseInfo,
        };
      }),
      variables: computed(() => {
        return listVariables(store, {
          sortBy: "name",
          sortDirection: "asc",
        });
      }),
      lastBackup: computed(() => {
        const filteredItems = filterItems(dbBackupsStore.items, {
          limit: 1,
          sortBy: "created_at",
          sortDirection: "desc",
        });
        return {
          item: filteredItems?.[0],
          promiseInfo: dbBackupsStore.promiseInfo,
        };
      }),
      oldestBackup: computed(() => {
        const filteredItems = filterItems(dbBackupsStore.items, {
          limit: 1,
          sortBy: "created_at",
          sortDirection: "asc",
          transform: (backups) => backups.filter((b) => b.status === "done"),
        });

        return {
          item: filteredItems?.[0],
          promiseInfo: dbBackupsStore.promiseInfo,
        };
      }),
    };
  },
});
</script>
