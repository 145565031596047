<template>
  <WarningModal
    :withCancelButton="false"
    @modalClosed="$emit('modalClosed')"
    @formSubmitted="$emit('warningAccepted')"
  >
    <template v-slot:title>
      {{ $t("title") }}
    </template>
    <template v-slot:question>
      <p>
        {{ isHds ? $t("labels.hds") : $t("labels.regular") }}
        {{ $t("line1", { count: minContainers }) }}
      </p>
      <p class="mt-2">
        {{ $t("line2") }}
      </p>
    </template>
    <template v-slot:button>{{ $t("button") }}</template>
  </WarningModal>
</template>

<script>
import { defineComponent } from "vue";

import WarningModal from "@/components/molecules/modalsCollection/WarningModal.vue";

export default defineComponent({
  name: "ScaleBelowLimitWarning",
  components: { WarningModal },
  props: {
    isHds: Boolean,
    minContainers: Number,
  },
  emits: ["modalClosed", "warningAccepted"],
});
</script>

<i18n>
en:
  title: "Impossible to scale"
  button: "Understood"
  labels:
    regular: "This app's"
    hds: "\"HDS\" apps'"
  line1: "configuration prevents its web container from being scaled below {count}."
  line2: "It is still possible to scale the app to 0 (and therefore to shut it down)."
fr:
  title: "Impossible de scaler"
  button: "J'ai compris"
  labels:
    regular: "Cette app est configurée"
    hds: "Les apps \"HDS\" sont configurées"
  line1: "afin que les containers web ne puissent pas être scalés en dessous de {count}."
  line2: "Il est toujours possible de scaler l'app a 0 (et en conséquence de la stopper)."
</i18n>
