export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your app will always be available under this subdomain "])}
      },
      "fr": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre app sera toujours disponible sous le domaine "])}
      }
    }
  })
}
