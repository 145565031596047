import i18n from "@/i18n";
import { setupPolling } from "@/lib/pinia/utils/polling";
import Client from "@/lib/scalingo/dbapi/client";
import { useCurrentDBStore } from "@/stores/current/db";
import { useDbBannersStore } from "@/stores/db/banners";

type response = {
  operation: operation;
};

type operation = {
  created_at: string;
  finished_at: string;
  status: string;
  type: string;
  id: string;
  error: string;
};

export function importOperationMethods() {
  const setupOperationPolling = (client: Client, operation: operation) => {
    const stopFn = () => {
      return ["finished", "error"].includes(operation.status);
    };

    const refreshFn = async () => {
      fetchDbOperationById(client, operation);
    };

    const teardownFn = () => {
      if (operation.type === "upgrade") {
        const currentDbStore = useCurrentDBStore();

        currentDbStore.fetchDatabase();
      }
      clearOperationBanner(operation);
      setOperationBanner(operation);
    };

    setupPolling({
      id: operation.id,
      kind: "dbOperation",
      stopFn,
      refreshFn,
      teardownFn,
    });
  };

  const fetchDbOperationById = async (client: Client, operation: operation) => {
    const clientResponse: response = await client.Database.getDatabaseOperation(
      operation.id,
    );

    if (["pitr_restore", "upgrade"].includes(clientResponse.operation.type)) {
      setOperationBanner(clientResponse.operation);
      setupOperationPolling(client, clientResponse.operation);
    }
  };

  const clearOperationBanner = (operation: operation) => {
    const bannersStore = useDbBannersStore();
    const bannerName = getOperationBannerName(operation);

    bannersStore.removeGenericBannerType(bannerName);
  };

  const setOperationBanner = (operation: operation) => {
    const bannersStore = useDbBannersStore();
    const bannerName = getOperationBannerName(operation);

    switch (operation.status) {
      case "finished":
        bannersStore.setGenericBanner({
          title: i18n.global.t(`banners.${bannerName}.success.title`),
          subtitle: i18n.global.t(`banners.${bannerName}.success.subtitle`),
          name: bannerName,
          kind: "success",
          dismissible: true,
        });
        break;
      case "error":
        bannersStore.setGenericBanner({
          title: i18n.global.t(`banners.${bannerName}.error.title`),
          subtitle: i18n.global.t(`banners.${bannerName}.error.subtitle`),
          name: bannerName,
          kind: "error",
        });
        break;
      case "user-error":
        bannersStore.setGenericBanner({
          title: i18n.global.t(`banners.${bannerName}.user-error.title`),
          subtitle: i18n.global.t(`banners.${bannerName}.user-error.subtitle`),
          name: bannerName,
          kind: "user-error",
        });
        break;
      case "running":
        bannersStore.setGenericBanner({
          title: i18n.global.t(`banners.${bannerName}.warning.title`),
          subtitle: i18n.global.t(`banners.${bannerName}.warning.subtitle`),
          kind: "warning",
          name: bannerName,
          running: true,
        });
        break;
      case "info":
        bannersStore.setGenericBanner({
          title: i18n.global.t(`banners.${bannerName}.warning.title`),
          subtitle: i18n.global.t(`banners.${bannerName}.warning.subtitle`),
          name: bannerName,
          kind: "info",
        });
        break;
    }
  };

  const getOperationBannerName = (operation: operation) => {
    let bannerName = "default";

    switch (operation.type) {
      case "pitr_restore":
        bannerName = "backupRestoration";
        break;
      case "upgrade":
        bannerName = "databaseUpgrade";
        break;
    }

    return bannerName;
  };

  return {
    fetchDbOperationById,
  };
}
