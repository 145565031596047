export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse key indicators of performance and consumption."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métriques"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des indicateurs clés de performance et de consommation."])}
      }
    }
  })
}
