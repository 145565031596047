<template>
  <div
    :title="feedback ? $t('copied') : copyClipboardTitle"
    class="items-center flex"
    @click="clicked"
  >
    <font-awesome-icon
      v-if="!feedback"
      class="text-scale-6 h-4 w-4"
      :icon="['far', 'clipboard']"
    ></font-awesome-icon>
    <font-awesome-icon
      v-else
      class="text-success h-4 w-4"
      icon="check"
    ></font-awesome-icon>
  </div>
</template>
<script>
export default {
  name: "CopyTableButton",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      feedback: false,
    };
  },
  computed: {
    copyClipboardTitle() {
      if (this.title) {
        return this.title;
      }
      return this.$t("copyClipboard");
    },
  },
  methods: {
    clicked() {
      this.$emit("click");
      this.feedback = true;
      setTimeout(
        function () {
          this.feedback = false;
        }.bind(this),
        1500,
      );
    },
  },
};
</script>

<i18n>
en:
  copyClipboard: "Copy to clipboard"
  copied: "Copied"
fr:
  copyClipboard: "Copier dans le presse-papiers"
  copied: "Copié"
</i18n>
