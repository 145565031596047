export default {
  "app": {
    "statuses": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle"])},
      "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrêtée"])},
      "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en fonctionnement"])},
      "crashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en erreur"])},
      "restarting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en cours de redémarrage"])},
      "scaling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en train de scaler"])},
      "booting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en cours de démarage"])}
    }
  }
}