import { Autoscaler } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { updateAutoscaler } from "@/store/autoscalers";

import type { ComponentPublicInstance } from "vue";

export class EnableAutoscalerHandler extends ActionHandler<Autoscaler> {
  keyPath = "autoscaler.enable";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly autoscaler: Autoscaler,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", {
        container: this.autoscaler.container_type,
      });
    });

    this.on("failure", () => {
      this.notify("error", {
        container: this.autoscaler.container_type,
      });
    });
  }

  async submit(): Promise<void> {
    this.follow(
      await updateAutoscaler(this.$store, this.autoscaler, { disabled: false }),
    );
  }
}
