import { DateTime } from "luxon";
import { User } from "scalingo/lib/models/auth";
import { DataAccessConsent } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/data_access_consent";

import { FormHandler } from "@/lib/handlers/form";
import { scalingoClient } from "@/lib/scalingo/client";
import { App, AppInfo } from "@/lib/scalingo/modifiers/apps";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { Nullable } from "@/lib/utils/types";
import { useCurrentAppStore } from "@/stores/current/app";

import type { ComponentPublicInstance } from "vue";

export type FormData = {
  containers: boolean;
  databases: boolean;
  duration: string;
};

export class CreateDataAccessConsentHandler extends FormHandler<DataAccessConsent> {
  keyPath = "data-access-consent.create";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App | AppInfo,
    readonly user: User,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return { containers: false, databases: false, duration: "24" };
  }

  dispatchEvents(): void {
    this.on("success", () => {
      useCurrentAppStore().refresh();
      this.notify("success");
    });
  }

  async submit(form: FormData): Promise<void> {
    const payload: CreateParams = {
      containers_until: null,
      databases_until: null,
    };

    const now = DateTime.local();
    const hours = parseInt(form.duration, 10);

    if (form.containers) {
      payload.containers_until = now.plus({ hours });
    }

    if (form.databases) {
      payload.databases_until = now.plus({ hours });
    }

    const promise = scalingoClient(
      this.$store,
      this.app.region,
    ).DataAccessConsent.create(this.app.id, payload);

    const op = new RemoteOperation<DataAccessConsent>().follow(promise);

    this.follow(op);
  }
}
