export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review app deployment"])},
        "titles": {
          "forked_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a fork from the linked repository, authored by a user outside of your organization."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement d'une review app"])},
        "titles": {
          "forked_repository": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un fork du dépôt lié, créé par un utilisateur à l'extérieur de votre organisation."])}
        }
      }
    }
  })
}
