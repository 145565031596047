<template>
  <SimpleModal @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      <div class="flex">
        <div class="self-center">
          <CheckGlyph class="text-color-1" :size="4"></CheckGlyph>
        </div>
        <h3 class="font-bold text-lg ml-2">
          <template v-if="context === 'creation'">
            {{ $t("creationTitle") }}
          </template>
          <template v-if="context === 'renewal'">
            {{ $t("renewalTitle") }}
          </template>
        </h3>
      </div>
    </template>
    <template v-slot:body>
      <div class="flex border-t-2 border-scale-1 mt-4 flex-col max-w-xl">
        <p class="mt-4 text-scale-9">{{ $t("newTokenValue") }}</p>
        <div class="flex flex-row items-center space-x-2 mt-2 mb-4">
          <div
            class="bg-scale-1 px-5 py-2 text-center rounded font-medium flex items-center"
          >
            <span class="flex-grow">{{ token }}</span>
          </div>
          <CopyButton @click="copyToClipboard(this.token)"></CopyButton>
        </div>
        <p class="text-scale-7">{{ $t("instructions") }}</p>
        <SCButton
          block
          kind="primary"
          size="lg"
          class="flex-grow mt-8"
          @click="$emit('modalClosed')"
        >
          {{ $t("buttonName") }}
        </SCButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import CopyButton from "@/components/organisms/buttons/CopyButton.vue";
import { copyToClipboard } from "@/lib/clipboard";

export default defineComponent({
  name: "TokenShowModal",
  components: { CopyButton, CheckGlyph, SCButton, SimpleModal },
  props: {
    token: String,
    context: {
      type: String,
      required: true,
      validator: function (value) {
        return ["creation", "renewal"].indexOf(value) !== -1;
      },
    },
  },
  emits: ["modalClosed"],
  methods: {
    copyToClipboard,
  },
});
</script>

<i18n>
en:
  creationTitle: "Token created"
  renewalTitle: "Token renewed"
  buttonName: "Continue"
  newTokenValue: "Your new token is:"
  instructions: "Store it in a safe place, since this token won't be shown again and will have full access to your Scalingo account."
fr:
  creationTitle: "Token créé"
  renewalTitle: "Token renouvellé"
  buttonName: "Continuer"
  newTokenValue: "Votre nouveau token est:"
  instructions: "Sauvegardez le dans un endroit sûr, ce token ne sera plus visible ensuite et permet un accès complet a votre compte Scalingo."
</i18n>
