export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "labels": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["owner"])},
          "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])}
        }
      },
      "fr": {
        "labels": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut"])},
          "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propriétaire"])},
          "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["région"])}
        }
      }
    }
  })
}
