import { App, Autoscaler } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/autoscalers";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { METRICS_IN_PERCENTS, updateAutoscaler } from "@/store/autoscalers";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  container: string;
  metric: string;
  target: number | string;
  max_containers: number | string;
  min_containers: number | string;
  // The autoscaler can be edited even when disabled, and can be re-enable right away or later.
  // The next two fields are here to handle this
  disabled: boolean;
}

interface UpdateParams extends CreateParams {
  disabled?: boolean;
}

export class EditAutoscalerHandler extends FormHandler<Autoscaler> {
  errorFieldsMapping = { limit: "target" };
  keyPath = "autoscaler.edit";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly autoscaler: Autoscaler,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    const target = METRICS_IN_PERCENTS.includes(this.autoscaler.metric)
      ? Math.floor(this.autoscaler.target * 100)
      : this.autoscaler.target;

    return {
      container: this.autoscaler.container_type,
      min_containers: this.autoscaler.min_containers,
      max_containers: this.autoscaler.max_containers,
      metric: this.autoscaler.metric,
      target: target,
      disabled: this.autoscaler.disabled,
    };
  }

  dispatchEvents(): void {
    this.on("success", (autoscaler) => {
      this.notify("success", {
        container: autoscaler.container_type,
        min: autoscaler.min_containers,
        max: autoscaler.max_containers,
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    const min_containers =
      typeof event.min_containers === "string"
        ? parseInt(event.min_containers, 10)
        : event.min_containers;

    const max_containers =
      typeof event.max_containers === "string"
        ? parseInt(event.max_containers, 10)
        : event.max_containers;

    let target =
      typeof event.target === "string"
        ? parseInt(event.target, 10)
        : event.target;

    target = METRICS_IN_PERCENTS.includes(event.metric) ? target / 100 : target;

    const payload: UpdateParams = {
      container_type: event.container,
      metric: event.metric,
      min_containers,
      max_containers,
      target,
    };

    if (event.disabled !== undefined && event.disabled !== null) {
      payload.disabled = event.disabled;
    }

    this.follow(await updateAutoscaler(this.$store, this.autoscaler, payload));
  }
}
