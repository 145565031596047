<template>
  <SCTable layout="fixed" :headers="headers" :items="orderedPaymentMethods">
    <template #item.default="{ item }">
      <StarGlyph v-if="item.default" class="h-8" />
      <span v-else></span>
    </template>
    <template #item.type="{ item }">
      <div class="text-scale-8 flex place-items-center">
        <template v-if="item.object_type === 'card'">
          <font-awesome-icon
            v-if="item.card.brand === 'visa'"
            :icon="['fab', 'cc-visa']"
          />

          <font-awesome-icon
            v-else-if="item.card.brand === 'mastercard'"
            :icon="['fab', 'cc-mastercard']"
          />

          <font-awesome-icon v-else icon="credit-card" />

          <span class="text-sm ml-2">{{ $t("type.card") }}</span>
        </template>

        <template v-else>
          <font-awesome-icon icon="money-check-alt" />

          <span class="text-sm ml-2">{{ $t("type.sepa") }}</span>
        </template>
      </div>
    </template>

    <template #item.added="{ item }">
      <SimpleDate :value="item.created_at" />
    </template>

    <template #item.details="{ item }">
      <i18n-t
        v-if="item.object_type === 'sepa'"
        keypath="attributes.paymentMethod.infos.sepa"
        scope="global"
        tag="span"
      >
        <template #start>
          <span class="font-bold">{{ item.sepa.country }}</span>
        </template>

        <template #end>
          <span class="font-bold">{{ item.sepa.last4 }}</span>
        </template>
      </i18n-t>

      <i18n-t
        v-if="item.object_type === 'card'"
        keypath="attributes.paymentMethod.infos.card"
        scope="global"
        tag="span"
      >
        <template #last4>
          <span class="font-bold">{{ item.card.last4 }}</span>
        </template>

        <template #exp>
          <span class="font-bold">{{ item.card.exp }}</span>
        </template>

        <template #holder>
          <span class="font-bold">{{ item.card.name }}</span>
        </template>
      </i18n-t>
    </template>

    <template #item.actions="{ item }">
      <template v-if="actionInProgress(item)">
        <Spinner class="float-right h-4 w-4 text-scale-5"></Spinner>
      </template>
      <div v-else class="flex justify-end">
        <HorizontalDottedDropdown
          :actions="actionsFor(item)"
          @action="actionClicked"
        />
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import StarGlyph from "@/components/atoms/glyphs/StarGlyph.vue";
import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import HorizontalDottedDropdown from "@/components/molecules/dropdowns/HorizontalDottedDropdown.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "PaymentMethodsListBody",
  components: {
    HorizontalDottedDropdown,
    Spinner,
    SCTable,
    SimpleDate,
    StarGlyph,
  },
  mixins: [ModelsTranslation],
  props: {
    paymentMethods: Object,
    deleteHandler: Object,
    defaultHandler: Object,
  },
  emits: ["deletePaymentMethod"],
  data() {
    return {
      headers: [
        {
          value: "default",
          text: "",
          class: "w-8",
        },
        {
          value: "type",
          text: this.$ta("paymentMethod", "type"),
          class: "humanize w-28",
        },
        {
          value: "added",
          text: this.$t("added"),
          class: "humanize w-36",
        },
        { value: "details", text: this.$t("details"), class: "humanize " },
        { value: "actions", text: "", class: "humanize w-12" },
      ],
    };
  },
  computed: {
    orderedPaymentMethods() {
      const items = this.paymentMethods.items?.slice() || [];
      const defaultSepa = items.filter(
        (item) => item.object_type === "sepa" && item.default,
      );
      const otherSepa = items.filter(
        (item) => item.object_type === "sepa" && !item.default,
      );
      const defaultCard = items.filter(
        (item) => item.object_type === "card" && item.default,
      );
      const otherCard = items.filter(
        (item) => item.object_type === "card" && !item.default,
      );

      return [...defaultSepa, ...otherSepa, ...defaultCard, ...otherCard];
    },
  },
  methods: {
    actionsFor(method) {
      const actions = [];
      const wrapperClass = "leading-5";
      if (!method) {
        return actions;
      }

      if (method?.object_type === "sepa" && method?.sepa.mandate_url) {
        actions.push({
          wrapperClass,
          text: this.$i18n.t("actions.mandate"),
          a: { href: method.sepa.mandate_url, target: "_blank" },
        });
      }

      if (!method.default) {
        actions.push({
          wrapperClass,
          text: this.$i18n.t("actions.makeDefault", {
            type: method.object_type,
          }),
          key: "makeDefault",
          target: method,
        });
      }

      actions.push({
        wrapperClass,
        text: this.$i18n.t("actions.delete"),
        key: "delete",
        kind: "warning",
        target: method,
      });

      return actions;
    },
    actionClicked({ action }) {
      if (action.key === "makeDefault") {
        this.$emit("makePaymentMethodDefault", {
          paymentMethod: action.target,
        });
        return;
      }

      if (action.key === "delete") {
        this.$emit("deletePaymentMethod", { paymentMethod: action.target });
        return;
      }
    },
    actionInProgress(paymentMethod) {
      if (this.deleteHandler?.isSubmitting) {
        return this.deleteHandler.paymentMethod.id === paymentMethod.id;
      }

      if (this.defaultHandler?.isSubmitting) {
        return this.defaultHandler.paymentMethod.id === paymentMethod.id;
      }

      return false;
    },
  },
});
</script>

<i18n>
en:
  details: "details"
  added: "created at"
  type:
    sepa: "SEPA"
    card: "Card"
  actions:
    mandate: "See mandate"
    makeDefault: "Make default {type}"
    delete: "Delete"
fr:
  details: "détails"
  added: "date d'ajout"
  type:
    sepa: "SEPA"
    card: "Carte"
  actions:
    mandate: "Voir le mandat"
    makeDefault: "Mettre par défaut {type}"
    delete: "Supprimer"
</i18n>
