import { Settings } from "luxon";
import Scalingo from "scalingo/lib/index";
import { ActionContext, Store } from "vuex";

import allRegions from "@/lib/scalingo/regions.json";
import localRegions from "@/lib/scalingo/regions.local.json";
import storybookRegions from "@/lib/scalingo/regions.storybook.json";
import { RootState } from "@/lib/store/utils";
import { ApplicationStore } from "@/store";
import { Session } from "@/store/session";

import ScalingoPrivate from "./private";
import { dashboard } from "../utils/log";

type Platform = "staging" | "production";

export interface Region {
  code: string;
  apiUrl: string;
  dbApiUrl: string;
  default?: boolean;
  visible?: boolean;
  requiresFlag?: string | false;
  glyph?: string;
}

// Loading the regions data from static files, cf README
let availableRegions: Region[];
if (process.env.VUE_APP_PLATFORM_ENV) {
  const platform = process.env.VUE_APP_PLATFORM_ENV as Platform;

  availableRegions = allRegions[platform] as Region[];
} else if (process.env.STORYBOOK) {
  availableRegions = storybookRegions as Region[];
} else {
  availableRegions = localRegions as Region[];
}

const defaultRegion = availableRegions.find((r) => r.default) as Region;

export { availableRegions, defaultRegion };

// Caches for clients
const clients = {} as Record<string, Scalingo>;
const privateClients = {} as Record<string, ScalingoPrivate>;

// Helper to retrieve the user token
function userToken<T>(
  contextOrStore: ApplicationStore | ActionContext<T, RootState>,
): string | null {
  const getters =
    contextOrStore instanceof Store
      ? contextOrStore.getters
      : contextOrStore.rootGetters;

  return getters[Session.getters.USER_TOKEN];
}

// Create or get the cached client. Updates the token in case it changed.
export function scalingoClient<T>(
  contextOrStore: ApplicationStore | ActionContext<T, RootState> | string,
  regionCode = defaultRegion.code,
): Scalingo {
  const token =
    typeof contextOrStore === "string"
      ? contextOrStore
      : userToken(contextOrStore);

  let region: Region | null = null;

  region = availableRegions.find(
    (region) => region.code === regionCode,
  ) as Region;

  if (token) {
    if (!clients[region.code]) {
      dashboard.debug(`Scalingo: creating ${region.code} client`);

      clients[region.code] = new Scalingo(token, {
        apiUrl: region.apiUrl,
        authApiUrl: process.env.VUE_APP_AUTH_BASE_URI,
        billingApiUrl: process.env.VUE_APP_BILLING_BASE_URI,
        noUserAgent: true,
      });
    } else {
      dashboard.debug(`Scalingo: updating ${region.code} client`);

      clients[region.code]._token = token;
    }

    // Add `Accept-Language` header according to the latest known locale
    // We're accessing the locale through luxon, it's reliable and easier that interacting with the store/user prefs/etc
    if (Settings.defaultLocale === "fr") {
      clients[region.code]._headers["Accept-Language"] =
        "fr-FR,fr;q=0.9,en-US;q=0.8,en;q=0.7";
    } else {
      clients[region.code]._headers["Accept-Language"] =
        "en-US,en;q=0.9,fr-FR;q=0.8,fr;q=0.7";
    }

    dashboard.debug(
      `Scalingo client Accept-Language: ${
        clients[region.code]._headers["Accept-Language"]
      }`,
    );

    return clients[region.code];
  } else {
    throw new Error("no user token");
  }
}

// Create or get the cached private client. Updates the token (via the public client) in case it changed.
export function scalingoPrivateClient<T>(
  contextOrStore: ApplicationStore | ActionContext<T, RootState> | string,
  regionCode = defaultRegion.code,
): ScalingoPrivate {
  const client = scalingoClient(contextOrStore, regionCode);

  if (!privateClients[regionCode]) {
    privateClients[regionCode] = new ScalingoPrivate(client);
  } else {
    privateClients[regionCode].client = client;
  }

  return privateClients[regionCode];
}
