<template>
  <div :class="classFn" class="h-3 w-3 rounded-full"></div>
</template>

<script>
import { defineComponent } from "vue";

export const STATUS_OK = "ok";
export const STATUS_WARNING = "warning";
export const STATUS_KO = "ko";

export default defineComponent({
  name: "AlertingStatesIndicator",
  props: {
    isOk: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isKo: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    classFn() {
      return (
        " h-" + this.size + " " + "w-" + this.size + " " + this.classCircle
      );
    },
    classCircle() {
      if (this.isOk || this.status === STATUS_OK) {
        return "bg-success";
      }
      if (this.isWarning || this.status === STATUS_WARNING) {
        return "bg-warning";
      }
      if (this.isKo || this.status === STATUS_KO) {
        return "bg-error";
      }
      return "bg-scale-5";
    },
  },
});
</script>
