<template>
  <ViewComponent></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/misc/About.vue";

export default defineComponent({
  name: "Licencing",
  components: { ViewComponent },
});
</script>
