import {
  TwoFactorAuthInitiateResponse,
  TwoFactorAuthValidateResponse,
  TwoFactorAuthObject,
} from "scalingo/lib/models/auth";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  CREATE,
  HANDLE_FETCH,
  UPDATE,
  HANDLE_OPERATION,
  DESTROY,
} from "@/lib/store/action-types";
import { SET_ONE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { ResourceStore } from "@/lib/store/resource-store";
import { buildMapping, ResourceWithFetch } from "@/lib/store/utils";
import { ApplicationStore } from "@/store";

export class TwoFactorAuthStore extends ResourceStore<TwoFactorAuthObject> {
  actions = ResourceStore.buildActions<TwoFactorAuthObject>({
    [REFRESH](context) {
      const promise = scalingoClient(context).TwoFactorAuth.status();

      context.dispatch(HANDLE_FETCH, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    // Initiate TFA
    [CREATE](context) {
      const promise = scalingoClient(context).TwoFactorAuth.initiate();

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    // Disable TFA
    [DESTROY](context) {
      const promise = scalingoClient(context).TwoFactorAuth.disable();

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction(resolved: { tfa: TwoFactorAuthObject }) {
          context.commit(SET_ONE, resolved.tfa);
        },
      });
    },
    // Validate TFA
    [UPDATE](context, { attempt, reload }) {
      const promise = scalingoClient(context).TwoFactorAuth.validate(attempt);

      if (reload) {
        promise.then(() => context.dispatch(REFRESH));
      }

      return context.dispatch(HANDLE_OPERATION, { promise });
    },
  });

  mutations = ResourceStore.buildMutations<TwoFactorAuthObject>();
  getters = ResourceStore.buildGetters<TwoFactorAuthObject>();
}

export const TFA = buildMapping(new TwoFactorAuthStore(), "tfa");

export function tfaStatus(
  store: ApplicationStore,
): ResourceWithFetch<TwoFactorAuthObject> {
  return {
    value: store.getters[TFA.getters.CURRENT],
    latestFetch: store.getters[TFA.getters.LATEST_FETCH],
  };
}

export function refreshTfa(store: ApplicationStore): void {
  store.dispatch(TFA.actions.REFRESH);
}

export function ensureTfa(store: ApplicationStore): void {
  store.dispatch(TFA.actions.ENSURE);
}

export function initiateTfa(
  store: ApplicationStore,
): Promise<RemoteOperation<TwoFactorAuthInitiateResponse>> {
  return store.dispatch(TFA.actions.CREATE);
}

export function disableTfa(
  store: ApplicationStore,
): Promise<RemoteOperation<TwoFactorAuthObject>> {
  return store.dispatch(TFA.actions.DESTROY);
}

export function validateTfa(
  store: ApplicationStore,
  attempt: string | number,
  reload = false,
): Promise<RemoteOperation<TwoFactorAuthValidateResponse>> {
  return store.dispatch(TFA.actions.UPDATE, { attempt, reload });
}
