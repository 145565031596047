<template>
  <apexchart
    ref="apex"
    type="line"
    :options="chartOptions"
    :series="chartData"
    height="300px"
  />
</template>

<script>
import { defineComponent } from "vue";

import Common from "@/components/organisms/apex/Common.vue";

export default defineComponent({
  name: "RPMGraph",
  mixins: [Common],
  computed: {
    title() {
      return "Requests per minute";
    },
    chartOptions() {
      return {
        ...this.chartDefaults(),
        colors: ["rgba(24,59,238,1)", "rgba(220,97,80,1)"],
        fill: {
          type: "solid",
          colors: ["rgba(79,106,242,0.2)", "rgba(220,97,80,1)"],
        },
        yaxis: {
          labels: {
            minWidth: 60,
            maxWidth: 60,
          },
        },
      };
    },
  },
});
</script>
