import { App, Container, Autoscaler } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/autoscalers";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { createAutoscaler, METRICS_IN_PERCENTS } from "@/store/autoscalers";

import type { ComponentPublicInstance } from "vue";

interface FormData {
  container: string;
  metric: string;
  target: number | string;
  max_containers: number | string;
  min_containers: number | string;
}

export class CreateAutoscalerHandler extends FormHandler<Autoscaler> {
  keyPath = "autoscaler.create";

  constructor(
    component: ComponentPublicInstance,
    readonly app: App,
    readonly container: Container,
  ) {
    super(component);
  }

  data(): Nullable<FormData> {
    return {
      container: this.container.name,
      max_containers: 3,
      min_containers: 2,
      metric: "cpu",
      target: 0,
    };
  }

  dispatchEvents(): void {
    this.on("success", (autoscaler) => {
      this.notify("success", {
        container: autoscaler.container_type,
        min: autoscaler.min_containers,
        max: autoscaler.max_containers,
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    const min_containers =
      typeof event.min_containers === "string"
        ? parseInt(event.min_containers, 10)
        : event.min_containers;

    const max_containers =
      typeof event.max_containers === "string"
        ? parseInt(event.max_containers, 10)
        : event.max_containers;

    let target =
      typeof event.target === "string"
        ? parseInt(event.target, 10)
        : event.target;

    target = METRICS_IN_PERCENTS.includes(event.metric) ? target / 100 : target;

    const payload: CreateParams = {
      container_type: event.container,
      metric: event.metric,
      min_containers,
      max_containers,
      target,
    };

    this.follow(await createAutoscaler(this.$store, payload));
  }
}
