export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced options"])},
        "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide advanced options"])}
      },
      "fr": {
        "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les options avancées"])},
        "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les options avancées"])}
      }
    }
  })
}
