<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AddonEditGlyph class="h-6 w-6 text-scale-6"></AddonEditGlyph>
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ event.type_data.addon_provider_name }}
      <div class="px-1">-</div>
      {{ event.type_data.old_plan_name }}
      <div class="px-1">→</div>
      {{ event.type_data.new_plan_name }}
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AddonEditGlyph from "@/components/atoms/glyphs/AddonEditGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "UpgradeAddonEvent",
  components: { AddonEditGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
  en:
    title: "Addon upgraded"
  fr:
    title: "Addon mis à niveau"
</i18n>
