<template>
  <ConfirmModal
    :immediate="true"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-lg"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    {{ $t("text") }}
    <template #confirmText>{{ $t("buttonName") }}</template>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  name: "StopAllContainersModal",
  components: {
    ConfirmModal,
  },
  emits: ["close", "confirm"],
});
</script>

<i18n>
en:
  title: "Stop All Containers"
  text: "This will stop all containers running for your application."
  buttonName: "Stop All Containers"
fr:
  title: "Arrêter tous les conteneurs"
  text: "Cette action arrêtera tous les conteneurs de votre application."
  buttonName: "Arrêter tous les conteneurs"
</i18n>
