<template>
  <svg width="40" height="40" viewBox="0 0 40 40">
    <g transform="matrix(0.14869888,0,0,0.14869888,-14.264769,-12.125407)">
      <path
        d="m 352.748,175.446 c -7.295,-11.33 -17.52,-21.361 -30.374,-29.821 -24.835,-16.32 -57.466,-25.311 -91.884,-25.311 -11.499,0 -22.828,1 -33.826,2.98 -6.823,-6.57 -14.8,-12.48 -23.249,-17.15 -31.347,-15.6307 -58.972,-9.8105 -72.93,-4.79 -4.5853,1.65 -6.0002,7.45 -2.6086,10.95 9.8436,10.16 26.1296,30.241 22.1256,48.502 C 104.439,176.696 96,195.857 96,215.807 c 0,20.331 8.439,39.492 24.002,55.382 4.004,18.261 -12.282,38.351 -22.1256,48.512 -3.3815,3.49 -1.9767,9.29 2.6086,10.94 13.958,5.02 41.583,10.85 72.94,-4.78 8.449,-4.67 16.426,-10.58 23.249,-17.151 10.998,1.98 22.327,2.98 33.826,2.98 34.428,0 67.059,-8.98 91.884,-25.3 12.854,-8.461 23.079,-18.481 30.374,-29.821 8.128,-12.621 12.242,-26.211 12.242,-40.372 -0.01,-14.54 -4.124,-28.121 -12.252,-40.751 z M 229.085,288.14 c -14.881,0 -29.069,-1.92 -42.004,-5.39 l -9.452,9.09 c -5.137,4.94 -11.158,9.41 -17.439,12.93 -8.319,4.07 -16.537,6.3 -24.665,6.971 0.462,-0.831 0.883,-1.671 1.335,-2.511 9.472,-17.4 12.031,-33.041 7.666,-46.911 -15.493,-12.171 -24.785,-27.751 -24.785,-44.722 0,-38.951 48.958,-70.532 109.344,-70.532 60.387,0 109.354,31.581 109.354,70.532 0,38.962 -48.957,70.543 -109.354,70.543 z"
        fill="#f5455c"
      />
      <path
        d="m 176.776,201.377 c -8.9,0 -16.115,7.16 -16.115,15.99 0,8.831 7.215,15.991 16.115,15.991 8.901,0 16.115,-7.16 16.115,-15.991 0,-8.83 -7.214,-15.99 -16.115,-15.99 z"
        fill="#f5455c"
      />
      <path
        d="m 228.644,201.377 c -8.901,0 -16.115,7.16 -16.115,15.99 0,8.831 7.214,15.991 16.115,15.991 8.9,0 16.115,-7.16 16.115,-15.991 0,-8.83 -7.215,-15.99 -16.115,-15.99 z"
        fill="#f5455c"
      />
      <path
        d="m 280.521,201.377 c -8.901,0 -16.115,7.16 -16.115,15.99 0,8.831 7.214,15.991 16.115,15.991 8.9,0 16.115,-7.16 16.115,-15.991 0,-8.83 -7.215,-15.99 -16.115,-15.99 z"
        fill="#f5455c"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGPlatformRocketChat",
});
</script>
