export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "headers": {
          "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started at"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
        },
        "periodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
        "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size not available"])},
        "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
      },
      "fr": {
        "headers": {
          "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencée à"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
        },
        "periodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifié"])},
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])},
        "notAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille non disponible"])},
        "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurer"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])}
      }
    }
  })
}
