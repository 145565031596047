<template>
  <SCCard :title="$t('title')" :subtitle="$t('subtitle')">
    <template #actions>
      <SCButton
        v-if="contracts.meta.hasMore"
        :loading="contracts.latestFetch.isLoading"
        @click="$emit('fetchAllContracts')"
      >
        {{ $t("showAllContracts") }}
      </SCButton>
    </template>

    <div v-for="contract in myContracts" :key="contract.id" class="mb-4">
      <AnsweredContractInfos :contract="contract" />

      <ContractDocuments :documents="contract.documents" />
    </div>
  </SCCard>
</template>

<script>
import { requiredForPlatform } from "scalingo/lib/Contracts";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SCCard from "@/components/molecules/card/SCCard.vue";
import AnsweredContractInfos from "@/components/parts/account/contracts/AnsweredContractInfos.vue";
import ContractDocuments from "@/components/parts/account/contracts/ContractDocuments.vue";

export default defineComponent({
  name: "PlatformContractsList",
  components: {
    SCButton,
    SCCard,
    ContractDocuments,
    AnsweredContractInfos,
  },
  props: {
    contracts: Object,
  },
  emits: ["fetchAllContracts"],
  data() {
    return {};
  },
  computed: {
    /**
     * `contracts` is expected to be comprised of:
     * - 1 or 2 contracts `requiredForPlatform`. At least one of the two will be `accepted`.
     *   - if there is only one, it's the latest version of the terms.
     *   - if there is two, it's the latest version + the previous version that will soon be phased out.
     * - 1 contract requiredForHDS, which may or may not be `accepted`.
     */
    mainPlatformContract() {
      const acceptedPlatformContracts = this.contracts.items.filter((c) => {
        return requiredForPlatform(c) && c.accepted;
      });

      if (acceptedPlatformContracts.length > 1) {
        return acceptedPlatformContracts.find((c) => c.latest);
      } else {
        return acceptedPlatformContracts[0];
      }
    },
    myContracts() {
      // This is true if we're not showing the full history
      if (this.contracts.meta.hasMore) {
        return [this.mainPlatformContract];
      }

      // This is true if we're showing the full history
      return this.contracts.items.filter((c) => {
        return c.accepted || c.refused;
      });
    },
  },
});
</script>

<i18n>
en:
  title: "Contracts & documents"
  subtitle: "Contractual documents relative to the use of the platform"
  showAllContracts: "Display older contracts"
fr:
  title: "Contrats & documents"
  subtitle: "Documents contractuels relatifs à l'utilisation de la plateforme"
  showAllContracts: "Montrer mes anciens contrats"
</i18n>
