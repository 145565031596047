<template>
  <a target="_blank" :href="document.web[this.$i18n.locale]">
    <span class="hidden 2xl:flex">{{ document.name[this.$i18n.locale] }}</span>
    <span class="2xl:hidden">{{ document.short[this.$i18n.locale] }}</span>
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterDocumentLink",
  props: {
    document: Object,
  },
});
</script>
