import { defineStore } from "pinia";
import { SCMIntegration } from "scalingo/lib/models/auth";
import { CreateParams } from "scalingo/lib/params/auth/scm_integrations";

import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { ignore404 } from "@/lib/pinia/utils/error-handlers";
import { useSessionStore } from "@/stores/session";

export const useScmIntegrationsStore = defineStore("scmIntegrations", () => {
  const { client } = useSessionStore();

  const store = useModelsStore({
    fetchPromise: () => client().SCMIntegrations.all(),
  });

  return {
    ...store,
    create(payload: CreateParams) {
      return client().SCMIntegrations.create(payload).then(store.insertItem);
    },
    destroy(integration: SCMIntegration) {
      return client()
        .SCMIntegrations.destroy(integration.id)
        .catch(ignore404)
        .then(() => store.removeItem(integration));
    },
  };
});
