<template>
  <SCTable
    layout="auto"
    :headers="headers"
    :items="credits.items"
    :paginated="true"
    :searchable="true"
    :searchFn="searchFn"
  >
    <template #item.validity="{ item }">
      <div
        class="flex items-center"
        :class="{ 'text-scale-5': item.expire_at <= currentDate }"
      >
        <TwoStatesIndicator
          class="mr-2"
          :isOn="!item.expire_at || item.expire_at >= currentDate"
          :alternativeColors="true"
        ></TwoStatesIndicator>
        <div v-if="item.expire_at">
          {{ $t("from") }} {{ formatDateTime(item.redeemed_at, "D") }}
          {{ $t("to") }}
          {{ formatDateTime(item.expire_at, "D") }}
        </div>

        <template v-else>
          {{ $t("never") }}
        </template>
      </div>
    </template>

    <template #item.code="{ item }">
      <div :class="{ 'text-scale-5': item.expire_at <= currentDate }">
        {{ item.code }}
      </div></template
    >

    <template #item.amounts="{ item }">
      <template v-if="item.type === 'money_voucher'">
        <div :class="{ 'text-scale-5': item.expire_at <= currentDate }">
          <span class="capitalize">
            {{ $ta("voucher", "initialAmount") }}
          </span>
          :
          {{ formatAmount(item.content.amount) }}
          <br />
          <div v-if="item.content.amount_per_month">
            <span class="capitalize">
              {{ $ta("voucher", "maxMonthlyAmount") }}
            </span>
            :
            {{ formatAmount(item.content.amount_per_month) }}
            <br />
          </div>
          <span class="capitalize">
            {{ $ta("voucher", "remainingAmount") }}
          </span>
          :
          {{ formatAmount(item.content.remaining_amount) }}
        </div>
      </template>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { formatAmount } from "@/lib/utils/currency";
import { formatDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "CreditsListBody",
  components: {
    SCTable,
    TwoStatesIndicator,
  },
  mixins: [ModelsTranslation],
  props: {
    credits: Object,
  },
  data() {
    return {
      currentDate: new Date().toISOString(),
      headers: [
        {
          value: "validity",
          text: this.$ta("voucher", "validity"),
          class: "humanize w-3/12",
        },
        {
          value: "code",
          text: this.$ta("voucher", "creditCode"),
          class: "humanize w-3/12",
        },
        {
          value: "amounts",
          text: this.$i18n.t("amounts"),
          class: "humanize w-4/12",
        },
      ],
      searchFn(row, search) {
        return !![row.code].find((col) => {
          return (
            col
              .toString()
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1
          );
        });
      },
    };
  },
  methods: {
    formatAmount,
    formatDateTime,
  },
});
</script>

<i18n>
en:
  amounts: "amounts"
  never: "Doesn't expire"
  from: "From"
  to: "to"
fr:
  amounts: "montants"
  never: "N'expire jamais"
  from: "Du"
  to: "au"
</i18n>
