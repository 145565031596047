<template>
  <main>
    <BackLinkButton class="my-2" :routeArgs="backLink">
      {{ $t("backToInvoices") }}
    </BackLinkButton>

    <template v-if="invoice">
      <InvoiceSummary
        :invoice="invoice"
        @downloadPdf="(e) => $emit('downloadInvoicePdf', e)"
      ></InvoiceSummary>
      <InvoiceDetails class="mt-4" :invoice="invoice"></InvoiceDetails>
    </template>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import InvoiceDetails from "@/components/parts/billing/showInvoice/InvoiceDetails.vue";
import InvoiceSummary from "@/components/parts/billing/showInvoice/InvoiceSummary.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Invoice",
  components: {
    BackLinkButton,
    InvoiceDetails,
    InvoiceSummary,
  },
  props: {
    user: Object,
    invoice: Object,
  },
  emits: ["downloadInvoicePdf"],
  computed: {
    backLink() {
      return {
        name: Routes.Billing.Invoices.Root,
      };
    },
  },
});
</script>

<i18n>
en:
  backToInvoices: "Back to invoices"
fr:
  backToInvoices: "Retour aux factures"
</i18n>
