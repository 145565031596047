<template>
  <SCDropdownButton
    :actions="choices"
    @action="(e) => $emit('sortByChoiceSelected', e)"
  >
    {{ $t("label") }}
    <span class="font-medium pl-1">
      {{ selectedChoice.text }}
    </span>
  </SCDropdownButton>
</template>

<script>
import { defineComponent } from "vue";

import SCDropdownButton from "@/components/molecules/buttons/SCDropdownButton.vue";
import { POSSIBLE_SORTS } from "@/lib/utils/app-sorts";

export default defineComponent({
  name: "SortBy",
  components: { SCDropdownButton },
  props: {
    currentSort: String,
  },
  emits: ["sortByChoiceSelected"],
  computed: {
    choices() {
      return POSSIBLE_SORTS.map((item) => {
        const selected = item.key === this.currentSort;

        let classes = "inline-block humanize";
        if (selected) {
          classes += " font-bold";
        }

        return {
          ...item,
          text: this.$i18n.t(item.i18nKey),
          class: classes,
          selected,
        };
      });
    },
    selectedChoice() {
      return this.choices.find((t) => t.selected);
    },
  },
});
</script>

<i18n>
en:
  label: "Sort by:"
  nameAz: "name from A to Z"
  nameZa: "name from Z to A"
  recentlyUpdated: "recently updated"
  leastRecentlyUpdated: "least recently updated"
fr:
  label: "Trier par:"
  nameAz: "nom de A à Z"
  nameZa: "nom de Z à A"
  recentlyUpdated: "récemment mise à jour"
  leastRecentlyUpdated: "moins récemment mise à jour"
</i18n>
