export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "upgradeAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade available"])},
        "dashboard": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version (preview)"])},
          "legacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old dashboard"])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
        }
      },
      "fr": {
        "upgradeAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour disponible"])},
        "dashboard": {
          "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle version (preview)"])},
          "legacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien dashboard"])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
        }
      }
    }
  })
}
