import { defineStore } from "pinia";
import { ref } from "vue";

// in milliseconds
const DEFAULT_TOAST_DURATION = 5000;

export type ToastType = "success" | "warning" | "error";

export interface Toast {
  id?: string | number;
  type: ToastType;
  title: string;
  message?: string;
  htmlMessage?: string;
  deleteAfter?: number;
  clearId?: NodeJS.Timeout;
}

export interface NotifyPayload {
  title: string;
  message?: string;
  htmlMessage?: string;
  type?: ToastType;
  keep?: boolean;
  deleteAfter?: number;
}

export const useToastsStore = defineStore("toasts", () => {
  let nextID = 1;

  const items = ref<Toast[]>([]);

  function addOne(payload: NotifyPayload) {
    const id = nextID++;
    const type = payload.type || "success";
    const { title, message, htmlMessage } = payload;

    const toast: Toast = { id, type, title, message, htmlMessage };

    if (!payload.keep) {
      toast.deleteAfter = payload.deleteAfter || DEFAULT_TOAST_DURATION;
    }

    if (toast.deleteAfter && toast.deleteAfter > 0) {
      toast.clearId = setTimeout(() => closeOne(toast), toast.deleteAfter);
    }

    items.value.push(toast);
  }

  function closeOne(toast: Toast) {
    items.value = items.value.filter((toastInColl) => {
      return !(toastInColl.id === toast.id);
    });
  }

  return {
    items,
    addOne,
    closeOne,
  };
});
