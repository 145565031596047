export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest review apps."])},
        "noReviewApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No review apps yet"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review apps"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières review apps."])},
        "noReviewApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune review app"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer"])}
      }
    }
  })
}
