<template>
  <main>
    <Title
      :appCount="appCount"
      :currentSort="currentSort"
      :filterQuery="filterQuery"
      @sortByChoiceSelected="(e) => $emit('sortByChoiceSelected', e)"
      @toggleFiltersDisplay="(e) => $emit('toggleFiltersDisplay', e)"
    ></Title>

    <div v-if="displayFilters" class="mt-2">
      <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
        <FilterByEnum
          :label="$t('labels.type')"
          :filterObject="filterDefs.type.filter"
          :selectedOption="currentTypeFilter"
          @filterSelected="(e) => $emit('filterBy', e)"
        >
          <template #optionText="{ option }">
            <div class="humanize">
              {{ option.text }}
            </div>
          </template>
        </FilterByEnum>

        <FilterByEnum
          :label="$t('labels.status')"
          :filterObject="filterDefs.status.filter"
          :selectedOption="currentStatusFilter"
          @filterSelected="(e) => $emit('filterBy', e)"
        >
          <template #optionText="{ option }">
            <div class="humanize">
              {{ option.text }}
            </div>
          </template>
        </FilterByEnum>

        <FilterByEnum
          :label="$t('labels.owner')"
          :filterObject="filterDefs.owner.filter"
          :selectedOption="currentOwnerFilter"
          :optionsAreLoading="appInfos.promiseInfo.isLoading"
          @filterSelected="(e) => $emit('filterBy', e)"
        ></FilterByEnum>

        <FilterByEnum
          :label="$t('labels.region')"
          :filterObject="filterDefs.region.filter"
          :selectedOption="currentRegionFilter"
          :optionsAreLoading="appInfos.promiseInfo.isLoading"
          @filterSelected="(e) => $emit('filterBy', e)"
        ></FilterByEnum>
      </div>
    </div>

    <ListAppsCards :appInfos="appInfos" />

    <GettingStartedCard
      v-if="appInfos.initialPromiseInfo.isFinished && appInfos.none"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import FilterByEnum from "@/components/parts/app/listApps/FilterByEnum.vue";
import ListAppsCards from "@/components/parts/app/listApps/ListAppsCards.vue";
import Title from "@/components/parts/app/listApps/ListAppsTitle.vue";
import GettingStartedCard from "@/components/parts/home/overview/GettingStartedCard.vue";

export default defineComponent({
  name: "Projects",
  components: {
    ListAppsCards,
    Title,
    FilterByEnum,
    GettingStartedCard,
  },
  props: {
    appInfos: Object,
    allAppInfosCount: Number,
    user: Object,
    currentSort: String,
    currentTypeFilter: String,
    currentStatusFilter: String,
    currentOwnerFilter: String,
    currentNameFilter: String,
    currentRegionFilter: String,
    filterDefs: Object,
    filterQuery: Object,
    displayFilters: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["filterBy", "sortByChoiceSelected", "toggleFiltersDisplay"],
  computed: {
    appCount() {
      if (!this.appInfos.items) return 0;
      if (this.appInfos.items.length === this.allAppInfosCount) {
        return this.allAppInfosCount;
      } else {
        return `${this.appInfos.items.length}/${this.allAppInfosCount}`;
      }
    },
    firstLoading() {
      return (
        this.appInfos.promiseInfo.isLoading && !this.appInfos.lastFetchedAt
      );
    },
  },
});
</script>

<i18n>
en:
  labels:
    type: "type"
    status: "status"
    owner: "owner"
    region: "region"
fr:
  labels:
    type: "type"
    status: "statut"
    owner: "propriétaire"
    region: "région"
</i18n>
