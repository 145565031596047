<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :notifier="notifier"
    :collaborators="collaborators"
    :eventTypes="eventTypes"
    @updateNotifier="updateNotifier"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/notifiers/Show.vue";
import { EditNotifierActivityHandler } from "@/lib/handlers";
import { ensureCollaborators, listCollaborators } from "@/store/collaborators";
import { ensureEventTypes, listEventTypes } from "@/store/event-types";
import { ensureNotifier, findNotifier } from "@/store/notifiers";

export default defineComponent({
  name: "NotifiersShowContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  computed: {
    eventTypes() {
      return listEventTypes(this.$store, this.app.region);
    },
    notifier() {
      if (this.$route.params.notifierId) {
        return findNotifier(this.$store, this.$route.params.notifierId);
      }
      return null;
    },
    collaborators() {
      return listCollaborators(this.$store);
    },
  },
  beforeMount() {
    ensureEventTypes(this.$store, this.app.region);
    ensureNotifier(this.$store, this.$route.params.notifierId);
    ensureCollaborators(this.$store);
  },
  methods: {
    // Only use to toggle `notifier.active` in this context
    updateNotifier({ notifier, active }) {
      const handler = new EditNotifierActivityHandler(this, notifier);
      handler.submit(active);
    },
  },
});
</script>
