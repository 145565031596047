export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled database maintenance is about to start"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scheduled maintenance will impact the database"])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["between"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La maintenance planifiée de base de données est sur le point de commencer"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La maintenance planifiée aura un impact sur la base de donnée"])},
        "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entre"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])}
      }
    }
  })
}
