export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger events"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the events on which this notifier reacts"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
        "options": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send me everything"])},
          "deployedEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just the deployment event"])},
          "selectedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let me select individual events:"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évènements déclencheurs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez les évènements pour lesquels ce notifieur réagit"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir"])},
        "options": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout envoyer"])},
          "deployedEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juste l'évènement de déploiement"])},
          "selectedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir les évènements parmis ceux disponibles:"])}
        }
      }
    }
  })
}
