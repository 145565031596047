import { BillingProfile } from "scalingo/lib/models/billing";

import { FormHandler } from "@/lib/handlers/form";
import { Nullable } from "@/lib/utils/types";
import { createProfile } from "@/store/billing-profile";

interface FormData {
  name: string;
  email: string;
  address_line1: string;
  address_line2: string;
  address_zip: string;
  address_city: string;
  address_state: string;
  address_country: string;
  vat_number: string;
  company: string;
}

export class CreateBillingProfileHandler extends FormHandler<BillingProfile> {
  keyPath = "billingProfile.create";

  data(): Nullable<FormData> {
    return {
      name: null,
      email: null,
      address_line1: null,
      address_line2: null,
      address_zip: null,
      address_city: null,
      address_state: null,
      address_country: null,
      vat_number: null,
      company: null,
    };
  }

  async submit(event: FormData): Promise<void> {
    this.follow(await createProfile(this.$store, event));
  }

  dispatchEvents(): void {
    this.on("failure", () => this.notify("error"));
    this.on("success", () => this.notify("success"));
  }
}
