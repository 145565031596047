import { User } from "scalingo/lib/models/auth";
import { Event } from "scalingo/lib/models/regional";
import { ComponentPublicInstance } from "vue";

interface VueLike {
  event: Event;
  currentUser: User;
  $t: ComponentPublicInstance["$t"];
}

export function withAuthor(this: VueLike): boolean {
  return !!this.event.user || !!this.event.type_data?.addon_name;
}

export function authorName(this: VueLike): string {
  if (!this.currentUser) {
    return "";
  }
  if (this.event.user) {
    if (this.event.user?.id == this.currentUser.id) {
      return this.$t("generic.you").toString();
    } else {
      return this.event.user.username;
    }
  } else if (this.event.type_data?.addon_name) {
    return this.event.type_data.addon_name;
  } else {
    return "";
  }
}
