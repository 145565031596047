<template>
  <ViewComponent
    :billingProfile="billingProfile"
    :hasCredits="hasCredits"
    :hasOrders="hasOrders"
  />
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/billing/Root.vue";
import { billingProfile, ensureBillingProfile } from "@/store/billing-profile";
import { useCreditsStore } from "@/stores/credits";
import { useOrdersStore } from "@/stores/orders";

export default defineComponent({
  name: "RootContainer",
  components: { ViewComponent },
  setup() {
    const creditsStore = useCreditsStore();
    const ordersStore = useOrdersStore();

    onBeforeMount(() => creditsStore.ensure());
    onBeforeMount(() => ordersStore.ensure());

    return { creditsStore, ordersStore };
  },
  computed: {
    billingProfile() {
      return billingProfile(this.$store);
    },
    hasCredits() {
      const { any } = this.creditsStore;

      return any;
    },
    hasOrders() {
      const { any } = this.ordersStore;

      return any;
    },
  },

  beforeMount() {
    ensureBillingProfile(this.$store, { staleAfter: "always" });
  },
});
</script>
