<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <AddonDatabaseGlyph class="h-6 w-6 text-scale-6"></AddonDatabaseGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more>
      {{ $t("maintenance") }}
    </template>
    <template #body>
      <p class="mb-2">
        {{ $t("degraded-perf") }}
      </p>
      <p>Maintenance ID: {{ event.type_data.maintenance_id }}</p>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AddonDatabaseGlyph from "@/components/atoms/glyphs/AddonDatabaseGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "CompleteDatabaseMaintenanceEvent",
  components: {
    AddonDatabaseGlyph,
    BaseEvent,
  },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
  en:
    title: "End of the scheduled database maintenance"
    maintenance: "The maintenance has been successfully completed and the nominal service has been restored."
    degraded-perf: "Performance may be degraded for a few moments. However, if you notice any unusual behaviour, please contact our support team."
  fr:
    title: "Fin de la maintenance planifiée de base de données terminée"
    maintenance: "La maintenance a été effectuée avec succès et le service nominal a été rétabli."
    degraded-perf: "Les performances peuvent être dégradées pendant quelques instants. Toutefois, si vous constatez un comportement inhabituel, veuillez contacter notre équipe support."
</i18n>
