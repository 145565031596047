<template>
  <div
    :class="{
      'border-2 border-color-3 ': isSelectable && isSelected,
      'border border-padding cursor-pointer': isSelectable && !isSelected,
      'border border-padding cursor-not-allowed': !isSelectable,
    }"
    class="flex flex-col rounded"
    @click="cartridgeSelected"
  >
    <div :id="id" class="flex px-2">
      <div class="flex flex-grow py-4">
        <div class="flex items-center pl-4">
          <slot name="icon"></slot>
        </div>
        <div class="flex items-center px-4">
          <div class="flex flex-col">
            <div class="text-scale-9 text-lg font-bold">
              {{ title }}
            </div>
            <div
              v-if="titleData !== ''"
              class="text-scale-6 mt-2 text-lg font-medium mb-3"
            >
              {{ titleData }}
            </div>
            <div class="text-scale-7">
              {{ subtitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex px-4">
        <div class="flex items-center flex-grow">
          <div v-if="isSelected">
            <slot name="action"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="bg-scale-1 py-4">
      <div class="flex px-6">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OpenableCartridge",
  props: {
    title: String,
    id: String,
    subtitle: String,
    titleData: {
      type: String,
      default: "",
    },
    isOpen: Boolean,
    isSelected: Boolean,
    isSelectable: Boolean,
  },
  emits: ["cartridgeSelected"],
  methods: {
    cartridgeSelected() {
      if (this.isSelectable) {
        this.$emit("cartridgeSelected");
      }
    },
  },
});
</script>

<style scoped>
.border-padding {
  padding: 1px;
}
</style>
