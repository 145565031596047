export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Scalingo!"])},
        "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're happy to have you among our users!"])},
        "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't forget to confirm your account in the 24 hours following its creation, otherwise you will no longer be able to use the platform."])},
        "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not receive the confirmation instructions? Follow "])},
        "link1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this link "])},
        "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to send it again."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur Scalingo!"])},
        "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes heureux de vous compter parmi nos utilisateurs!"])},
        "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas de confirmer votre compte dans les 24 heures suivant sa création, sans quoi vous ne pourrez plus utiliser la plateforme."])},
        "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu les instructions de confirmation? Suivez "])},
        "link1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce lien "])},
        "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour l'envoyer à nouveau."])}
      }
    }
  })
}
