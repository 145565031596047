<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :disabled="!canUseSEPA" @click="addSepaClicked">
          {{ $t("buttons.addSepa") }}
        </SCButton>
        <SCButton class="ml-2" @click="addCardClicked">
          {{ $t("buttons.addCard") }}
        </SCButton>
      </template>
    </CardHeader>

    <p class="mt-2 text-scale-7">
      {{ $t("disclaimer") }}
    </p>

    <SCAlert
      v-if="!canUseSEPA"
      kind="info"
      class="mt-2"
      :title="$t('sepa.title')"
    >
      <p>{{ $t("sepa.body1") }}</p>
      <p>
        {{ $t("sepa.body2") }}

        <span class="underline fond-bold cursor-pointer" @click="openSupport">
          {{ $t("sepa.support") }}
        </span>
      </p>
    </SCAlert>

    <PaymentMethodsListBody
      v-if="displayPaymentMethods"
      class="mt-4"
      :paymentMethods="paymentMethods"
      :deleteHandler="deleteHandler"
      :defaultHandler="defaultHandler"
      @deletePaymentMethod="deletePaymentMethod"
      @makePaymentMethodDefault="makePaymentMethodDefault"
    >
    </PaymentMethodsListBody>

    <EmptyCardState v-else class="mt-2">
      <template #icon>
        <BillGlyph class="h-8 text-scale-6"></BillGlyph>
      </template>
      <span class="text-scale-6 text-lg font-medium">
        {{ $t("none") }}
      </span>
    </EmptyCardState>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import PaymentMethodsListBody from "@/components/parts/billing/showBilling/PaymentMethodsListBody.vue";

export default defineComponent({
  name: "PaymentMethodsList",
  components: {
    SCButton,
    CardHeader,
    BillGlyph,
    PaymentMethodsListBody,
    EmptyCardState,
    SCAlert,
  },
  props: {
    billingProfile: Object,
    paymentMethods: Object,
    deleteHandler: Object,
    defaultHandler: Object,
  },
  emits: [
    "addCard",
    "addSepa",
    "deletePaymentMethod",
    "makePaymentMethodDefault",
  ],
  computed: {
    displayPaymentMethods() {
      const op = this.paymentMethods.latestFetch;

      return op.isLoading || this.paymentMethods.items.length > 0;
    },
    canUseSEPA() {
      if (!this.billingProfile) return false;

      return this.billingProfile.flags.includes("sepa-allowed");
    },
  },
  methods: {
    addSepaClicked(eventArgs) {
      this.$emit("addSepa", eventArgs);
    },
    addCardClicked(eventArgs) {
      this.$emit("addCard", eventArgs);
    },
    makePaymentMethodDefault(paymentMethod) {
      this.$emit("makePaymentMethodDefault", paymentMethod);
    },
    deletePaymentMethod(paymentMethod) {
      this.$emit("deletePaymentMethod", paymentMethod);
    },
    openSupport() {
      const message = this.$t("sepa.supportMsg");

      window.Intercom?.("showNewMessage", message);
    },
  },
});
</script>

<i18n>
en:
  title: "Payment methods"
  subtitle: "Displayed in the order they will be used"
  none: "No payment method added"
  disclaimer: "You will be charged at the beginning of each month for the resources used."
  sepa:
    title: "SEPA direct debit as a payment method"
    body1: "SEPA Direct Debit is only available to known customers who have paid at least three consecutive invoices with an amount greater than €100."
    body2: "If you meet the above requirements, or if your case requires special attention, please "
    support: "contact our support team."
    supportMsg: "Hello! I would like to request the ability to pay my invoices via SEPA Direct Debit."
  buttons:
    addSepa: "Add SEPA account"
    addCard: "Add card"
fr:
  title: "Moyens de paiement"
  subtitle: "Affichés par ordre d'utilisation"
  none: "Aucune méthode de paiement"
  disclaimer: "Vous serez facturé au début de chaque mois pour les ressources utilisées."
  sepa:
    title: "Le prélèvement SEPA comme mode de paiement"
    body1: "Le prélèvement SEPA (SEPA Direct Debit) n'est disponible que pour les clients connus qui ont payé au moins trois factures consécutives d'un montant supérieur à 100 €."
    body2: "Si vous répondez aux critères ci-dessus, ou si votre cas nécessite une attention particulière, veuillez "
    support: "contacter notre équipe support."
    supportMsg: "Bonjour! Je souhaiterai pouvoir régler mes factures via prélèvement SEPA."
  buttons:
    addSepa: "Ajouter un compte SEPA"
    addCard: "Ajouter une carte bancaire"
</i18n>
