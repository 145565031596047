export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "manual": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual backup"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manual backup will be carried out shortly."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup failure"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The backup has not been carried out: ", _interpolate(_named("err"))])}
          }
        },
        "restore": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database restoration"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The restoration will be executed shortly."])}
          },
          "error": {
            "generic": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restoration of this backup is not possible at the moment. Refresh the page and try again. If the issue persists, please reach out to our support team."])}
            },
            "notFound": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The backup you want to restore no longer exists."])}
            }
          }
        },
        "scheduling": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup schedule"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration has been updated."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup schedule failed!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration has not been updated."])}
          }
        }
      },
      "fr": {
        "manual": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup manuel"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le backup manuel va être effectué sous peu."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du backup"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le backup n'a pas été effectué : ", _interpolate(_named("err"))])}
          }
        },
        "restore": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration de la base de données"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La restauration va être exécutée sous peu."])}
          },
          "error": {
            "generic": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La restauration de ce backup n'est pas possible pour le moment. Rafraîchissez la page et réessayez. Si le problème persiste, contactez notre équipe support."])}
            },
            "notFound": {
              "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le backup que vous souhaitez restorer n’existe plus."])}
            }
          }
        },
        "scheduling": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup programmé!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration a été mise à jour."])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la programmation du backup!"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration n'a pas été mise à jour."])}
          }
        }
      }
    }
  })
}
