<template>
  <SCBanner
    :dismissible="banner.dismissible || false"
    :kind="banner.kind"
    @dismiss="banner.show = false"
  >
    <div>
      <div class="flex row">
        <ReloadGlyph v-if="banner.running" class="mr-2" :animated="true" />
        <WarningTriangle v-else-if="banner.kind === 'warning'" class="mr-2" />
        <DangerSignGlyph v-else-if="banner.kind === 'error'" class="mr-2" />
        <CheckGlyph v-else-if="banner.kind === 'success'" class="mr-2" />
        <InformationGlyph v-else-if="banner.kind === 'info'" class="mr-2" />
        {{ banner.title }}
      </div>
      <p class="ml-1 mt-2 font-normal">
        {{ banner.subtitle }}
      </p>
    </div>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import InformationGlyph from "@/components/atoms/glyphs/InformationGlyph.vue";
import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import WarningTriangle from "@/components/graphics/icons/WarningTriangle.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "SuccessBanner",
  components: {
    SCBanner,
    DangerSignGlyph,
    WarningTriangle,
    CheckGlyph,
    InformationGlyph,
    ReloadGlyph,
  },
  props: {
    banner: Object,
    title: String,
    subtitle: String,
  },
});
</script>
