import { Event } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { REFRESH, FETCH_MORE } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, SET_META, MERGE } from "@/lib/store/mutation-types";
import {
  buildMapping,
  listItems,
  ListItemsOptions,
  CollectionWithFetch,
  EnsureOptions,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class UserEventsStore extends CollectionStore<Event> {
  actions = CollectionStore.buildActions<Event>({
    async [REFRESH](context, opts = {}) {
      const response = await scalingoClient(context).Events.all(opts);

      context.commit(SET_ALL, response.events);
      context.commit(SET_META, response.meta);
    },
    async [FETCH_MORE](context) {
      if (!context.state.meta.pagination?.next_page) {
        return;
      }

      const opts = { page: context.state.meta.pagination.next_page };

      const response = await scalingoClient(context).Events.all(opts);

      context.commit(MERGE, response.events);
      context.commit(SET_META, response.meta);
    },
  });

  mutations = CollectionStore.buildMutations<Event>();
  getters = CollectionStore.buildGetters<Event>();
}

export const UserEvents = buildMapping(new UserEventsStore(), "userEvents");

export function listEvents(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Event>>,
): CollectionWithFetch<Event> {
  return {
    items: listItems(store.getters[UserEvents.getters.ALL], opts),
    latestFetch: store.getters[UserEvents.getters.LATEST_FETCH],
    meta: store.getters[UserEvents.getters.META],
  };
}

export function ensureEvents(
  store: ApplicationStore,
  opts?: EnsureOptions,
): void {
  store.dispatch(UserEvents.actions.ENSURE, opts);
}

export function fetchMoreEvents(store: ApplicationStore): void {
  store.dispatch(UserEvents.actions.FETCH_MORE);
}
