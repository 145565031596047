export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF Persistence"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF persistence configuration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mode provides the highest level of persistency for Redis®, any command impacting the dataset will be saved synchronously in file, ensuring that in case of incident, all the data will be recoverable from this log file."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF persistence enabled"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOF persistence disabled"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistance de l'AOF"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de la persistance de l'AOF"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mode fournit le plus haut niveau de persistance pour Redis®, toute commande ayant un impact sur le jeu de données sera sauvegardée de manière synchrone dans un fichier, garantissant qu'en cas d'incident, toutes les données pourront être récupérées à partir de ce fichier de log."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistance de l'AOF activée"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persistance de l'AOF désactivée"])}
      }
    }
  })
}
