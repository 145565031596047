<template>
  <apexchart
    ref="apex"
    type="line"
    :options="chartOptions"
    :series="chartData"
    height="300px"
  />
</template>

<script>
import { defineComponent } from "vue";

import Common from "@/components/organisms/apex/Common.vue";
import { humanReadableSize } from "@/lib/utils/size";

export default defineComponent({
  name: "MemoryGraph",
  mixins: [Common],
  props: {
    maxMemory: Number,
  },
  computed: {
    title() {
      return this.hasIndex
        ? `RAM – ${this.container.name}-${this.index}`
        : `RAM – ${this.container.name} ${this.container.amount}:${this.container.size}`;
    },
    chartOptions() {
      const opts = {
        ...this.chartDefaults(),
        colors: ["rgba(24,59,238,1)", "rgba(220,97,80,1)"],
        fill: {
          type: "solid",
          opacity: 1,
          colors: ["#d7dcf8", "#f8dfdc"],
        },
        yaxis: {
          min: 0,
          max: this.maxMemory * 2,
          tickAmount: 8,
          labels: {
            minWidth: 60,
            maxWidth: 60,
            formatter: (value) => humanReadableSize(value, 2),
          },
        },
      };

      opts.chart.stacked = true;
      opts.legend.onItemHover = {
        highlightDataSeries: false,
      };

      return opts;
    },
  },
});
</script>
