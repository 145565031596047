export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer app ownership"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer ownership"])},
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage collaborators"])},
        "noCollaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collaborators for this application."])},
        "allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As the owner of this application, you are the only one who has the right to transfer its ownership to an existing collaborator."])},
        "notAllowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Only ", _interpolate(_named("name")), ", owner of the app is allowed to transfer its ownership."])},
        "c3_warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact our support team"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is not possible to transfer an app outside the UGAP billing account."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer la propriété de l'application"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer la propriété"])},
        "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les collaborateurs"])},
        "noCollaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun collaborateur pour cette application."])},
        "allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que propriétaire de cette application, vous seul·e êtes autorisé·e à transférer sa propriété à un collaborateur ou une collaboratrice existante."])},
        "notAllowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seul·e ", _interpolate(_named("name")), ", propriétaire de l'application, peut en transférer la propriété."])},
        "c3_warning": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de contacter notre équipe support"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n’est pas possible de transférer une application en dehors du compte de facturation lié à l’UGAP."])}
        }
      }
    }
  })
}
