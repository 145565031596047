export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "appNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App name"])},
        "regionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
        "appNamePlaceholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("min")), " to ", _interpolate(_named("max")), " characters"])},
        "billingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing profile"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a payment method"])},
        "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a voucher"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create app"])},
        "hds": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health data hosting (conform to French norm HDH)"])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your application consists of hosting personal health data collected during prevention, diagnosis, care or social and medico-social monitoring activities, you must tick this box."])},
          "prerequisite": {
            "unsigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to "])},
            "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["accept the ", _interpolate(_named("name"))])},
            "inOrderTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" in order to create a HDH application."])}
          },
          "appendix": {
            "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the HDH contract appendix..."])},
            "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already accepted the terms of the HDH contract appendix."])},
            "unsigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to "])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept the terms of the HDH contract appendix "])},
            "inOrderTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in order to create a HDH application."])}
          },
          "contact": {
            "filled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have filled an HDH contact. ", _interpolate(_named("accountHds"))])},
            "accountHds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check or update this contact in your account settings."])},
            "youNeedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to"])},
            "unfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in order to create a HDH application."])},
            "outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["before you can create a new HDH app"])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fill a HDH contact"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update the HDH contact"])}
          }
        },
        "errors": {
          "freeTrialExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your free trial period has expired."])},
          "freeTrialExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot create more apps while in free trial."])},
          "maxAppsExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limits of apps you can create."])},
          "fillBilling": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and add "])},
            "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or "])}
          },
          "appRejected": {
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deployment of this application is not allowed."])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you think this is an error, get in touch with the support."])}
          }
        }
      },
      "fr": {
        "appNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'app"])},
        "regionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
        "appNamePlaceholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("min")), " à ", _interpolate(_named("max")), " caractères minimum"])},
        "billingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil de facturation"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un moyen de paiement"])},
        "voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un voucher"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'app"])},
        "hds": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement de données de santé (conforme HDS)"])},
          "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si votre application consiste à héberger les données de santé à caractère personnel recueillies à l'occasion d'activités de prévention, de diagnostic, de soins ou de suivi social et médico-social, vous devez cocher cette case."])},
          "prerequisite": {
            "unsigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez "])},
            "link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["accepter les ", _interpolate(_named("name"))])},
            "inOrderTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant de pouvoir créer une application HDS."])}
          },
          "appendix": {
            "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vérifions l'état de l'annexe HDS..."])},
            "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà accepté l'annexe HDS."])},
            "unsigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez "])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepter l'annexe HDS "])},
            "inOrderTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant de pouvoir créer une application HDS."])}
          },
          "contact": {
            "filled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez renseigné un point de contact professionnel de santé. ", _interpolate(_named("accountHds"))])},
            "accountHds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez ou modifiez ce contact dans les paramètres de votre compte."])},
            "youNeedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez"])},
            "unfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant de pouvoir créer une application HDS"])},
            "outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant de pouvoir créer une nouvelle application HDS."])},
            "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["renseigner un point de contact professionnel de santé"])},
            "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mettre à jour le contact HDS"])}
          }
        },
        "errors": {
          "freeTrialExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre période d'essai est terminée."])},
          "freeTrialExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas créer d'autres applications pendant la période d'essai."])},
          "maxAppsExceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum d'applications que vous pouvez créer."])},
          "fillBilling": {
            "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir votre "])},
            "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et ajoutez "])},
            "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ou "])}
          },
          "appRejected": {
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le déploiement de cette application n'est pas autorisé."])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous pensez que c'est une erreur, veuillez contacter le support."])}
          }
        }
      }
    }
  })
}
