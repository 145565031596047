<template>
  <ViewComponent
    :db="db"
    :extensionList="extensionList"
    :pgActivateQueryStatistics="pgActivateQueryStatistics"
    :queryStatisticsList="queryStatisticsList"
    :queryStatisticsActive="queryStatisticsActive"
    :getQueriesStatistics="getQueriesStatistics"
    :pgResetQueriesStatistics="pgResetQueriesStatistics"
    :isLoading="isLoading"
  ></ViewComponent>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

import ViewComponent from "@/components/views/db/DbQueryStatistics.vue";
import { i18nT } from "@/lib/utils/i18n";
import {
  currentDB,
  currentDBClient,
  useCurrentDBStore,
} from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "QueryStatistics",
  components: { ViewComponent },
  setup() {
    const currentDbStore = useCurrentDBStore();
    const extensionList = ref([]);
    const queryStatisticsList = ref([]);
    const isLoading = ref(true);
    const toastsStore = useToastsStore();
    const t = i18nT();

    const queryStatisticsActive = computed(() => {
      return extensionList.value.some(
        (obj) => obj.name === "pg_stat_statements",
      );
    });

    async function getExtensionsList() {
      const client = await currentDBClient();
      const db = currentDB();
      const response = await client.Database.pgListExtensions(db.id);

      extensionList.value = response.result;
      if (queryStatisticsActive.value) {
        getQueriesStatistics();
      } else {
        isLoading.value = false;
      }
    }

    async function pgResetQueriesStatistics() {
      const client = await currentDBClient();
      const db = currentDB();

      client.Database.pgResetQueriesStatistics(db.id);
      queryStatisticsList.value = [];
      toastsStore.addOne({
        type: "success",
        title: t("resetTitle").toString(),
        message: t("resetMessage").toString(),
      });
    }

    async function pgActivateQueryStatistics() {
      const client = await currentDBClient();
      const db = currentDB();

      await client.Database.pgActivateQueryStatistics(db.id);
      getExtensionsList();
      toastsStore.addOne({
        type: "success",
        title: t("activatedTitle").toString(),
        message: t("activatedMessage").toString(),
      });
    }

    async function getQueriesStatistics() {
      const client = await currentDBClient();
      const db = currentDB();
      const response = await client.Database.pgListQueriesStatistics(db.id);

      queryStatisticsList.value = response.result || [];
      isLoading.value = false;
    }

    getExtensionsList();

    return {
      db: computed(() => currentDbStore.database),
      extensionList,
      pgActivateQueryStatistics,
      queryStatisticsList,
      queryStatisticsActive,
      getQueriesStatistics,
      pgResetQueriesStatistics,
      isLoading,
    };
  },
});
</script>

<i18n>
en:
  activatedTitle: "Query statistics enabled"
  activatedMessage: "Performance tracking will be available shortly."
  resetTitle: "Query statistics reset"
  resetMessage: "Performance tracking will be available shortly."
fr:
  activatedTitle: "Query statistics activées"
  activatedMessage: "Le suivi de la performance de vos requêtes sera disponible sous peu."
  resetTitle: "Query statistics réinitialisées"
  resetMessage: "Le suivi de la performance de vos requêtes sera disponible sous peu."
</i18n>
