/* eslint-disable @typescript-eslint/no-explicit-any */

import { scalingoPrivateClient } from "@/lib/scalingo/client";
import {
  PaymentMethod,
  IndexQuery as PaymentMethodsQuery,
  IndexResponse as PaymentMethodsList,
} from "@/lib/scalingo/payment-methods";
import {
  REFRESH,
  HANDLE_FETCH,
  FETCH_MORE,
  CREATE,
  HANDLE_OPERATION,
  DESTROY,
  UPDATE,
  FETCH,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import {
  SET_ALL,
  SET_META,
  MERGE,
  ADD,
  DELETE,
  SET_ONE,
} from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  GenericResource,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class PaymentMethodsStore extends CollectionStore<PaymentMethod> {
  actions = CollectionStore.buildActions<PaymentMethod>({
    [REFRESH](context, params) {
      const opts: PaymentMethodsQuery = {
        profile_id: params.profileId,
      };

      if (params.perPage) {
        opts.per_page = params.perPage;
      }

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).PaymentMethods.all(opts),
        resolveAction: function (resolved: PaymentMethodsList) {
          context.commit(SET_ALL, resolved.payment_methods);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH_MORE](context, params) {
      if (!context.state.meta.pagination?.next_page) {
        return;
      }

      const opts: PaymentMethodsQuery = {
        profile_id: params.profileId,
        page: context.state.meta.pagination.next_page,
      };

      if (params.perPage) {
        opts.per_page = params.perPage;
      }

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).PaymentMethods.all(opts),
        resolveAction: function (resolved: PaymentMethodsList) {
          context.commit(MERGE, resolved.payment_methods);
          context.commit(SET_META, resolved.meta);
        },
      });
    },
    [FETCH](context, id) {
      const promise = scalingoPrivateClient(context).PaymentMethods.find(id);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [CREATE](context, payload) {
      let promise;

      if (payload.sepa) {
        promise = scalingoPrivateClient(
          context,
        ).PaymentMethods.createSepaFullFlow(
          payload.profile.id,
          payload.sepa.el,
          { holder: payload.sepa.holder, email: payload.sepa.email },
        );
      } else if (payload.card) {
        promise = scalingoPrivateClient(
          context,
        ).PaymentMethods.createCardFullFlow(
          payload.profile.id,
          payload.card.el,
          payload.card.holder,
          payload.card.defaultPaymentMethod,
        );
      }

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: ADD,
      });
    },
    [UPDATE](context, paymentMethod) {
      const promise = scalingoPrivateClient(context).PaymentMethods.setDefault(
        paymentMethod.id,
      );

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [DESTROY](context, paymentMethod) {
      const promise = scalingoPrivateClient(context).PaymentMethods.destroy(
        paymentMethod.id,
      );

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: () => context.commit(DELETE, paymentMethod),
      });
    },
  });
  mutations = CollectionStore.buildMutations<PaymentMethod>();
  getters = CollectionStore.buildGetters<PaymentMethod>();
}

export const PaymentMethods = buildMapping(
  new PaymentMethodsStore(),
  "paymentMethods",
);

export function listPaymentMethods(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<PaymentMethod>>,
): CollectionWithFetch<PaymentMethod> {
  return {
    items: listItems(store.getters[PaymentMethods.getters.ALL], opts),
    latestFetch: store.getters[PaymentMethods.getters.LATEST_FETCH],
  };
}

export function paymentMethodsMeta(store: ApplicationStore): GenericResource {
  return store.getters[PaymentMethods.getters.META];
}

export function ensurePaymentMethods(
  store: ApplicationStore,
  profileId: string,
): void {
  store.dispatch(PaymentMethods.actions.ENSURE, { payload: { profileId } });
}

export function fetchMorePaymentMethods(
  store: ApplicationStore,
  profileId: string,
  perPage: number,
): void {
  store.dispatch(PaymentMethods.actions.FETCH_MORE, { profileId, perPage });
}

export function createPaymentMethod(
  store: ApplicationStore,
  payload: Record<string, any>,
): Promise<RemoteOperation<PaymentMethod>> {
  return store.dispatch(PaymentMethods.actions.CREATE, payload);
}

export function refreshPaymentMethod(
  store: ApplicationStore,
  paymentMethod: PaymentMethod,
): Promise<RemoteOperation<PaymentMethod>> {
  return store.dispatch(PaymentMethods.actions.FETCH, paymentMethod.id);
}

export function makePaymentMethodDefault(
  store: ApplicationStore,
  paymentMethod: PaymentMethod,
): Promise<RemoteOperation<PaymentMethod>> {
  return store.dispatch(PaymentMethods.actions.UPDATE, paymentMethod);
}

export function deletePaymentMethod(
  store: ApplicationStore,
  paymentMethod: PaymentMethod,
): Promise<RemoteOperation<void>> {
  return store.dispatch(PaymentMethods.actions.DESTROY, paymentMethod);
}
