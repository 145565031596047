export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo for Redis® Configuration"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redis® persistence strategy"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redis® has different persistency models according to the prefered performance and reliability tradeoff. By default on Scalingo, the RDB persistency mode is enabled. These settings are global and impact all the databases of the Redis® instance."])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["official Redis® persistence documentation."])},
        "protip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing the persistency configuration of your Redis® database is instant and does not require any restart of the instance. Moreover, all the options can be turned off as quickly as they have been enabled. If you wish you can disable all the persistency options. Your Redis® instance will keep living in memory, data will be preserved until a restart operation is done (maintenance/upgrade)."])},
        "text2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All the modes can be combined if you need to use the advantages of different options. You can read more information in the ", _interpolate(_named("link"))])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de Scalingo pour Redis®"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratégie de persistance de Redis®"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redis® dispose de différents modèles de persistance en fonction du compromis préféré entre performance et fiabilité. Par défaut sur Scalingo, le mode de persistance RDB est activé. Ces paramètres sont globaux et ont un impact sur toutes les bases de données de l'instance Redis®."])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentation officielle sur la persistance de Redis®."])},
        "protip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La modification de la configuration de persistance de votre base de données Redis® est instantanée et ne nécessite aucun redémarrage de l'instance. De plus, toutes les options peuvent être désactivées aussi rapidement qu'elles ont été activées. Si vous le souhaitez, vous pouvez désactiver toutes les options de persistance. Votre instance Redis® continuera à vivre en mémoire, les données seront préservées jusqu'à ce qu'une opération de redémarrage soit effectuée (maintenance/mise à jour)."])},
        "text2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tous les modes peuvent être combinés si vous avez besoin d'utiliser les avantages de différentes options. Vous trouverez plus d'informations dans la ", _interpolate(_named("link"))])}
      }
    }
  })
}
