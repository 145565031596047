<template>
  <SCBanner v-if="displayBanner" :kind="bannerStyle" align="center">
    <template v-if="inFreeTrial">
      <p tag="span">
        {{ $t("inFreeTrial") }}
        {{ $tc("days", freeTrialRemainingDays) }}
        {{ $t("inFreeTrialEndSentence") }}
      </p>
    </template>

    <template v-if="notBillable">
      {{ $t("freeTrialOver.text") }}

      <router-link class="ml-1 font-bold underline" :to="billingRoute">
        {{ $t("freeTrialOver.link") }}
      </router-link>
    </template>
  </SCBanner>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";
import { userIsAdmin } from "@/store/user";

export default defineComponent({
  name: "BillingStatusBanner",
  components: { SCBanner },
  mixins: [ModelsTranslation],
  props: {
    user: Object,
    billingProfile: Object,
    isCollaboratorOnly: Boolean,
  },
  data() {
    return {
      billingRoute: {
        name: Routes.Billing.Root,
      },
    };
  },
  computed: {
    displayBanner() {
      if (userIsAdmin(this.user)) return false;

      return this.inFreeTrial || this.notBillable;
    },
    bannerStyle() {
      if (this.inFreeTrial) return "info";
      if (this.notBillable) return "error";

      return "warning";
    },
    inFreeTrial() {
      const freeTrialEndsAt = DateTime.fromISO(this.user.free_trial_end_time);

      return freeTrialEndsAt.diffNow().as("seconds") > 0;
    },
    freeTrialRemainingDays() {
      const freeTrialEndsAt = DateTime.fromISO(this.user.free_trial_end_time);

      return Math.floor(freeTrialEndsAt.diffNow().as("days"));
    },
    hasPaymentMethod() {
      return !!this.billingProfile?.payment_method;
    },
    notBillable() {
      return (
        !this.inFreeTrial && !this.hasPaymentMethod && !this.isCollaboratorOnly
      );
    },
  },
});
</script>

<i18n>
  en:
    inFreeTrial: "Yay! You are now trialing Scalingo. You have "
    days: "0 days | 1 day | {count} days"
    inFreeTrialEndSentence: " to try out our features!"
    freeTrialOver:
      text: "Free trial ended. To continue to use Scalingo,"
      link: "you need to add a payment method or a voucher."
  fr:
    inFreeTrial: "Yay ! Vous êtes maintenant en cours d'essai. Vous avez "
    days: "0 jours | 1 jour | {count} jours"
    inFreeTrialEndSentence: " restant pour tester notre plateforme !"
    freeTrialOver:
      text: "Période d'essai terminée. Pour continuer à utiliser Scalingo,"
      link: "vous devez ajouter un moyen de paiement ou un voucher."
</i18n>
