export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point-In-Time Recovery"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This database is continuously backed up, allowing it to be restored to a specific point in time."])},
        "startPitr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a PITR"])},
        "retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retention period: "])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
        "nopitr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous backup is being activated. This process may take up to 24 hours."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point-In-Time Recovery"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette base de données est sauvegardée en continu, permettant sa restauration à un moment précis."])},
        "startPitr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration PITR"])},
        "retention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rétention : "])},
        "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
        "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heure"])},
        "nopitr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sauvegarde en continu est en cours d'activation. Cette étape peut prendre jusqu'à 24 heures."])}
      }
    }
  })
}
