<template>
  <transition
    enterActiveClass="transition ease-out duration-100"
    enterClass="transform opacity-0 scale-95"
    enterToClass="transform opacity-100 scale-100"
    leaveActiveClass="transition ease-in duration-75"
    leaveClass="transform opacity-100 scale-100"
    leaveToClass="transform opacity-0 scale-95"
  >
    <div v-if="show" :class="wrapperClass">
      <ActionsList :items="items" @click="(e) => $emit('click', e)">
        <template #prepend>
          <slot name="prepend" />
        </template>
        <template #append>
          <slot name="append" />
        </template>
      </ActionsList>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from "vue";

import ActionsList from "@/components/molecules/lists/ActionsList.vue";

export default defineComponent({
  name: "DropdownMenu",
  components: { ActionsList },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      validator(value) {
        return ["none", "left", "right", "alongside-right"].includes(value);
      },
      default: "none",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["click"],
  computed: {
    wrapperClass() {
      let classes = "origin-top-right flex absolute z-50";

      if (this.align === "left") {
        classes += " left-0 mt-2";
      }

      if (this.align === "right") {
        classes += " right-0 flex-row-reverse mt-2";
      }
      if (this.align === "alongside-right") {
        classes += " right-0 flex-row-reverse mr-8 -mt-9";
      }

      return classes;
    },
  },
});
</script>
