import { ActionHandler } from "@/lib/handlers/action";
import { PaymentMethod } from "@/lib/scalingo/payment-methods";
import {
  makePaymentMethodDefault,
  refreshPaymentMethod,
} from "@/store/payment-methods";

import type { ComponentPublicInstance } from "vue";

export class SetDefaultPaymentMethodHandler extends ActionHandler<PaymentMethod> {
  get keyPath(): string {
    if (this.paymentMethod.object_type === "card")
      return "paymentMethod.card.setDefault";
    else return "paymentMethod.sepa.setDefault";
  }

  constructor(
    readonly component: ComponentPublicInstance,
    readonly paymentMethod: PaymentMethod,
    readonly allMethods: PaymentMethod[],
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      (this.allMethods || [])
        .filter((pm) => pm.default)
        .forEach((method) => refreshPaymentMethod(this.$store, method));
    });

    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(
      await makePaymentMethodDefault(this.$store, this.paymentMethod),
    );
  }
}
