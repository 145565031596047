<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 482.022 512.36"
    enable-background="new 0 0 482.022 512.36"
    xml:space="preserve"
  >
    <g>
      <path
        fill="#FFA400"
        d="M344.592,276.957h-58.997v-30.291c0-24.191-19.685-43.876-43.876-43.876s-43.876,19.685-43.876,43.876   v30.291h-58.997v-30.291c0-56.724,46.149-102.873,102.873-102.873s102.873,46.149,102.873,102.873V276.957z"
      />
      <path
        fill="#003A70"
        d="M368.389,276.957h-253.34c-10.816,0-19.665,8.85-19.665,19.665v187.406   c0,10.816,8.849,19.666,19.665,19.666h253.34c10.816,0,19.665-8.85,19.665-19.666V296.622   C388.054,285.806,379.205,276.957,368.389,276.957z M254.559,400.001v25.393c0,7.087-5.749,12.837-12.837,12.837   s-12.837-5.749-12.837-12.837v-25.389c-7.897-4.481-13.233-12.947-13.233-22.674c0-14.396,11.671-26.067,26.067-26.067   c14.396,0,26.067,11.671,26.067,26.067C267.786,387.058,262.451,395.523,254.559,400.001z"
      />
      <path
        fill="#FFA400"
        d="M89.367,249.016H28.235c-9.911,0-17.948-8.036-17.948-17.948c0-9.912,8.036-17.948,17.948-17.948h61.132   c9.911,0,17.948,8.036,17.948,17.948C107.315,240.98,99.278,249.016,89.367,249.016z"
      />
      <path
        fill="#FFA400"
        d="M132.43,152.854c-4.01,0-8.048-1.338-11.389-4.085l-48.356-39.761c-7.656-6.296-8.758-17.606-2.462-25.262   c6.294-7.654,17.601-8.762,25.262-2.464l48.356,39.761c7.656,6.296,8.758,17.606,2.462,25.262   C142.756,150.62,137.613,152.854,132.43,152.854z"
      />
      <path
        fill="#FFA400"
        d="M241.718,110.588c-9.911,0-17.948-8.036-17.948-17.948V31.027c0-9.912,8.036-17.948,17.948-17.948   c9.911,0,17.948,8.036,17.948,17.948V92.64C259.665,102.552,251.629,110.588,241.718,110.588z"
      />
      <g>
        <g>
          <path
            fill="#FFA400"
            d="M351.008,152.854c-5.186,0-10.326-2.233-13.873-6.55c-6.296-7.656-5.194-18.966,2.462-25.262     l48.356-39.761c7.664-6.299,18.971-5.19,25.262,2.464c6.296,7.656,5.194,18.966-2.462,25.262l-48.356,39.761     C359.055,151.515,355.018,152.854,351.008,152.854z"
          />
        </g>
      </g>
      <path
        fill="#FFA400"
        d="M455.203,249.016H393.49c-9.911,0-17.948-8.036-17.948-17.948c0-9.912,8.036-17.948,17.948-17.948h61.713   c9.911,0,17.948,8.036,17.948,17.948C473.15,240.98,465.114,249.016,455.203,249.016z"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogoLetsEncrypt",
});
</script>
