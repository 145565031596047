import { Token } from "scalingo/lib/models/auth";

import { scalingoClient } from "@/lib/scalingo/client";
import {
  REFRESH,
  HANDLE_FETCH,
  CREATE,
  HANDLE_OPERATION,
  UPDATE,
  DESTROY,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, ADD, SET_ONE, DELETE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class TokensStore extends CollectionStore<Token> {
  actions = CollectionStore.buildActions<Token>({
    async [REFRESH](context) {
      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context).Tokens.all(),
        resolveAction: SET_ALL,
      });
    },
    async [CREATE](context, payload) {
      const promise = scalingoClient(context).Tokens.create(payload.name);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: ADD,
      });
    },
    async [UPDATE](context, token) {
      const promise = scalingoClient(context).Tokens.renew(token.id);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: SET_ONE,
      });
    },
    [DESTROY](context, token) {
      const promise = scalingoClient(context).Tokens.destroy(token.id);

      return context.dispatch(HANDLE_OPERATION, {
        promise,
        resolveAction: () => context.commit(DELETE, token),
      });
    },
  });
  mutations = CollectionStore.buildMutations<Token>();
  getters = CollectionStore.buildGetters<Token>();
}

export const Tokens = buildMapping(new TokensStore(), "tokens");

export function allTokens(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Token>>,
): CollectionWithFetch<Token> {
  return {
    items: listItems(store.getters[Tokens.getters.ALL], opts),
    latestFetch: store.getters[Tokens.getters.LATEST_FETCH],
  };
}

export function ensureTokens(store: ApplicationStore): void {
  store.dispatch(Tokens.actions.ENSURE);
}

export function createToken(
  store: ApplicationStore,
  payload: Partial<Token>,
): Promise<RemoteOperation<Token>> {
  return store.dispatch(Tokens.actions.CREATE, payload);
}

export function renewToken(
  store: ApplicationStore,
  token: Token,
): Promise<RemoteOperation<Token>> {
  return store.dispatch(Tokens.actions.UPDATE, token);
}

export function destroyToken(
  store: ApplicationStore,
  token: Token,
): Promise<RemoteOperation<void>> {
  return store.dispatch(Tokens.actions.DESTROY, token);
}
