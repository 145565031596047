export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "errors": {
          "mysqlBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To upgrade your MySQL instance to a Business Plan, you must first upgrade it to MySQL 8.0 LTS."])}
        }
      },
      "fr": {
        "errors": {
          "mysqlBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour mettre à niveau votre instance MySQL vers un plan Business, vous devez préalablement la mettre à jour vers MySQL 8.0 LTS."])}
        }
      }
    }
  })
}
