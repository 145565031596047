export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "tableHeaders": {
          "sqlQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query"])},
          "pid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PID"])},
          "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started on"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])}
        },
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
          "kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminate"])}
        },
        "noRunningQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No queries are currently running."])}
      },
      "fr": {
        "tableHeaders": {
          "sqlQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête"])},
          "pid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PID"])},
          "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrée le"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
        },
        "buttons": {
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "kill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompre"])}
        },
        "noRunningQueries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête n'est actuellement en cours d'exécution."])}
      }
    }
  })
}
