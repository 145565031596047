export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "creation": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User created"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been successfully created."])}
          }
        },
        "deletion": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deleted"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has been successfully deleted."])}
          }
        }
      },
      "fr": {
        "creation": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur créé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été créé avec succès."])}
          }
        },
        "deletion": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur supprimé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur a été supprimé avec succès."])}
          }
        }
      }
    }
  })
}
