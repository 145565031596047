export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Tokens"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These tokens allow you to interact with our APIs without using your account credentials"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No token yet"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens API"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces tokens vous permettent d'interagir avec nos APIs sans avoir à fournir les identifiants de votre compte utilisateur"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun token"])}
      }
    }
  })
}
