import Client from "scalingo";
import { Container } from "scalingo/lib/models/regional";
import { CreateParams } from "scalingo/lib/params/regional/apps";

import { App, enhanceApp } from "@/lib/scalingo/modifiers/apps";

/** The first step of the one click deploy consists in creating the app and scaling it to the right formation */
export async function ocdStep1(
  client: Client,
  params: CreateParams,
  formation: Container[],
): Promise<App> {
  // App creation
  const app = await client.Apps.create({
    name: params.name,
    git_source: params.git_source,
  });

  await client.Containers.scale(app.id, formation);

  return enhanceApp(app);
}
