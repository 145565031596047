<template>
  <main>
    <LoadingCardState />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";

export default defineComponent({
  name: "CollaborationAccept",
  components: { LoadingCardState },
  data() {
    return {};
  },
});
</script>
