<template>
  <div v-if="show" :class="bgColor" class="flex">
    <div
      v-if="text"
      :class="textColor"
      class="container px-4 flex py-3 font-bold"
    >
      <div v-if="withIcon" class="self-center pr-3">
        <slot name="icon"></slot>
      </div>
      <div :class="{ 'justify-center': !withIcon }" class="flex-grow flex">
        <div class="self-center">
          {{ text }}
          <span class="font-normal">
            <slot name="textComplement"></slot>
          </span>
        </div>
      </div>
      <div
        v-if="dismissible"
        class="flex align-middle h-3 w-3 cursor-pointer"
        @click="dismiss"
      >
        <CrossGlyph></CrossGlyph>
      </div>
    </div>
    <div
      v-if="title"
      :class="textColor"
      class="container px-6 pt-3 flex font-bold"
    >
      <div v-if="title" class="flex-grow flex">
        <div class="w-1/2 flex flex-col">
          <div :class="{ 'text-2xl': styling === 'scalingoWelcome' }">
            {{ title }}
          </div>
          <div class="text-sm font-normal mt-2">{{ subtitle }}</div>
        </div>
        <div class="w-1/2 overflow-hidden h-24 flex justify-end px-8">
          <ColumnsIllustration></ColumnsIllustration>
        </div>
      </div>
      <div
        v-if="dismissible"
        class="flex align-middle h-3 w-3 cursor-pointer"
        @click="dismiss"
      >
        <CrossGlyph></CrossGlyph>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import ColumnsIllustration from "@/components/atoms/illustrations/ColumnsIllustration.vue";

export default defineComponent({
  name: "Alert",
  components: { ColumnsIllustration, CrossGlyph },
  props: {
    styling: String,
    text: String,
    title: String,
    subtitle: String,
    dismissible: Boolean,
    withIcon: Boolean,
  },
  emits: ["dismissed"],
  data: function () {
    return {
      show: true,
    };
  },
  computed: {
    bgColor: function () {
      if (this.styling === "error") {
        return "bg-color-2-10";
      }
      if (this.styling === "information") {
        return "bg-primary-2-10";
      }
      if (this.styling === "warning") {
        return "bg-secondary-3-10";
      }
      if (this.styling === "success") {
        return "bg-color-1-10";
      }
      if (this.styling === "proTip") {
        return "bg-scale-0 rounded";
      }
      if (
        this.styling === "scalingoWelcome" ||
        this.styling === "profileWelcome"
      ) {
        return "bg-primary-2 rounded";
      }
      throw "Invalid style: " + this.styling;
    },
    textColor: function () {
      if (this.styling === "error") {
        return "text-color-2";
      }
      if (this.styling === "information") {
        return "text-primary-2";
      }
      if (this.styling === "warning") {
        return "text-secondary-3";
      }
      if (this.styling === "success") {
        return "text-color-1";
      }
      if (this.styling === "proTip") {
        return "text-color-9";
      }
      if (
        this.styling === "scalingoWelcome" ||
        this.styling === "profileWelcome"
      ) {
        return "text-primary-4";
      }
      throw "Invalid style: " + this.styling;
    },
  },
  methods: {
    dismiss() {
      this.show = false;
      this.$emit("dismissed");
    },
  },
});
</script>
