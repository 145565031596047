export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption"])},
        "headers": {
          "billingPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
        },
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
      },
      "fr": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation"])},
        "headers": {
          "billingPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de facturation"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
        },
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
      }
    }
  })
}
