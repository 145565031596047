export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "accept": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration accepted!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You now have access to ", _interpolate(_named("name"))])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collaboration is invalid."])}
          }
        },
        "region": {
          "invalid": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid region"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The region '", _interpolate(_named("region")), "' does not exist."])}
          },
          "unauthorized": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You do not have access to the region '", _interpolate(_named("region")), "'. Get in touch with the support to request the access."])}
          }
        }
      },
      "fr": {
        "accept": {
          "success": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration acceptée!"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez a présent accès à ", _interpolate(_named("name"))])}
          },
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collaboration est invalide."])}
          }
        },
        "region": {
          "invalid": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région invalide"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La région '", _interpolate(_named("region")), "' n'existe pas."])}
          },
          "unauthorized": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non autorisé"])},
            "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous n'avez pas accès a la région '", _interpolate(_named("region")), "'. Contactez le support afin d'y avoir accès."])}
          }
        }
      }
    }
  })
}
