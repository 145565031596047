<template>
  <div class="grid grid-cols-1 xl:grid-cols-3 gap-4 mt-4">
    <Card>
      <template #header>
        <CardHeader
          :title="$t('clicks.title')"
          :subtitle="$t('clicks.subtitle')"
        >
        </CardHeader>
      </template>
      <template #body>
        <div
          v-if="stats.latestFetch.isLoading"
          class="pt-2 mb-2 flex items-center justify-center"
        >
          <Spinner class="h-8 w-8 text-scale-5" />
        </div>
        <div v-else class="text-center text-4xl font-bold">
          {{ stats.value.clicks }}
        </div>
      </template>
    </Card>

    <Card>
      <template #header>
        <CardHeader
          :title="$t('signups.title')"
          :subtitle="$t('signups.subtitle')"
        >
        </CardHeader>
      </template>
      <template #body>
        <div
          v-if="stats.latestFetch.isLoading"
          class="pt-2 mb-2 flex items-center justify-center"
        >
          <Spinner class="h-8 w-8 text-scale-5" />
        </div>
        <div v-else class="text-center text-4xl font-bold">
          {{ stats.value.signups }}
        </div>
      </template>
    </Card>

    <Card>
      <template #header>
        <CardHeader
          :title="$t('earnings.title')"
          :subtitle="$t('earnings.subtitle')"
        >
        </CardHeader>
      </template>
      <template #body>
        <div
          v-if="stats.latestFetch.isLoading"
          class="pt-2 mb-2 flex items-center justify-center"
        >
          <Spinner class="h-8 w-8 text-scale-5" />
        </div>
        <div v-else class="text-center text-4xl font-bold">
          {{ formatAmount(stats.value.amount_earned) }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { formatAmount } from "@/lib/utils/currency";

export default defineComponent({
  name: "StatsCard",
  components: {
    Card,
    CardHeader,
    Spinner,
  },
  props: {
    stats: Object,
  },
  computed: {},
  methods: {
    formatAmount,
  },
});
</script>

<i18n>
en:
  clicks:
    title: "Clicks"
    subtitle: "Number of clicks on your link"
  signups:
    title: "Sign ups"
    subtitle: "Number of sign ups from your link"
  earnings:
    title: "Earnings"
    subtitle: "Credit added to your account"
fr:
  clicks:
    title: "Clicks"
    subtitle: "Nombre de clic sur votre lien"
  signups:
    title: "Inscriptions"
    subtitle: "Nombre d'inscriptions depuis votre lien"
  earnings:
    title: "Earnings"
    subtitle: "Credit ajouté à votre compte"
</i18n>
