<template>
  <svg
    :class="classFn"
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.905 17.63L11.8126 0.37C11.6758 0.141211 11.4291 0.0008087 11.1625 0H10.8624C10.5958 0.0008087 10.3491 0.141211 10.2123 0.37L0.0998607 17.63C-0.0300317 17.8667 -0.0300317 18.1533 0.0998607 18.39L0.239894 18.62C0.369516 18.8573 0.619669 19.0035 0.890048 19H21.1148C21.3852 19.0035 21.6354 18.8573 21.765 18.62L21.905 18.39C22.0349 18.1533 22.0349 17.8667 21.905 17.63ZM12.0027 14.5C12.0027 14.7761 11.7788 15 11.5026 15H10.5023C10.2261 15 10.0022 14.7761 10.0022 14.5V13.5C10.0022 13.2239 10.2261 13 10.5023 13H11.5026C11.7788 13 12.0027 13.2239 12.0027 13.5V14.5ZM11.5326 11C11.6598 11.0009 11.7674 10.9062 11.7826 10.78L12.1827 7.56C12.2 7.41735 12.155 7.27417 12.0593 7.16698C11.9635 7.05979 11.8263 6.99896 11.6826 7H10.3223C10.1786 6.99896 10.0413 7.05979 9.94561 7.16698C9.84988 7.27417 9.80491 7.41735 9.82216 7.56L10.2223 10.78C10.2375 10.9062 10.3451 11.0009 10.4723 11H11.5326Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DangerSignGlyph",
  props: {
    size: {
      type: [Number, String],
      default: 6,
    },
  },
  computed: {
    classFn() {
      if (!this.size) return "";
      return "h-" + this.size + " w-" + this.size;
    },
  },
});
</script>
