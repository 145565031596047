export default {
  "attributes": {
    "defaults": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created at"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
    },
    "account": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
      "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "emailNewsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription to the newsletter"])}
    },
    "maintenanceObjectives": {
      "no-op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The goal of this maintenance is to test the whole process of maintenance without any operation on the database."])},
      "failing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The goal of this maintenance is to not finish correctly and fail. You should not see this event, our team has been informed."])},
      "db-node-migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purpose of this maintenance is to migrate a component of the database from a server to another. This action aims at allowing us to install security upgrades in our infrastructure. It has been designed to be achieved with the shortest downtime possible."])},
      "pg-md5-to-sha256-auth-migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of our ongoing commitment to maintain the highest security standards, this maintenance will update the password hashing algorithm and the authentication method used by the internal users managed by Scalingo to SCRAM-SHA-256. For more information, please consult the associated security bulletin: https://doc.scalingo.com/security/bulletins/ssb-2024-002."])},
      "db-upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The purpose of this maintenance is to upgrade the database to a more recent version."])}
    },
    "app": {
      "types": {
        "onlyParentsApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["only parent apps"])},
        "onlyReviewApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["only review apps"])}
      },
      "statuses": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
        "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stopped"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
        "crashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crashed"])},
        "restarting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restarting"])},
        "scaling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scaling"])},
        "booting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booting"])}
      }
    },
    "billingProfile": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing email"])},
      "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address (line 1)"])},
      "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address (line 2)"])},
      "addressZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zip code"])},
      "addressCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
      "addressState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["state"])},
      "addressCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
      "vatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
      "invoiceAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice free text"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company"])}
    },
    "hdsContactProfile": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address (line 1)"])},
      "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address (line 2)"])},
      "addressZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
      "addressCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "addressCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
      "orgType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization type"])},
      "orgTypes": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "non-profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-profit organization"])},
        "public-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public entity"])}
      }
    },
    "deployment": {
      "statuses": {
        "queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["queued"])},
        "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["building"])},
        "pushing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pushing"])},
        "starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successful deployment"])},
        "crashed-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crashed when booting"])},
        "timeout-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeout at boot"])},
        "push-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["push error"])},
        "build-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["build error"])},
        "hook-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hook error"])},
        "aborted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aborted"])}
      }
    },
    "backup": {
      "statuses": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successful"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failed"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduled"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])}
      },
      "full_statuses": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successful backup"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failed backup"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running backup"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduled backup"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled backup"])}
      },
      "retention_policy_labels": {
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
        "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks"])},
        "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])}
      },
      "retention_policy": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All backups, whatever their retention policy"])},
        "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep ", _interpolate(_named("daily_scheduled_backups")), " backups: one for each day of the previous week"])},
        "weeks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep ", _interpolate(_named("weekly_scheduled_backups")), " backups: one for each week of the previous ", _interpolate(_named("weekly_scheduled_backups")), " weeks"])},
        "months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep ", _interpolate(_named("monthly_scheduled_backups")), " backups: one for each month of the previous ", _interpolate(_named("monthly_scheduled_backups")), " months"])},
        "user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep last ", _interpolate(_named("manual_backups_count")), " backups manually made by user"])}
      },
      "method": {
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual"])},
        "periodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daily"])}
      }
    },
    "notifier": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "webhookUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL of the webhook"])},
      "types": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "rocket_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocket.Chat"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack."])},
        "webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])}
      }
    },
    "invoice": {
      "statuses": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment failed"])},
        "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refunded"])},
        "partially_refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partially refunded"])}
      }
    },
    "token": {
      "issuedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["issued at"])},
      "lastUsedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last used at"])}
    },
    "key": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
      "fingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fingerprint"])}
    },
    "contract": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["version"])}
    },
    "paymentMethod": {
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iban"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["card number"])},
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiration date"])},
      "cvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cvc"])},
      "holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["holder"])},
      "infos": {
        "sepa": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Account ", _interpolate(_named("start")), "...", _interpolate(_named("end"))])},
        "card": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Card ending in ", _interpolate(_named("last4")), ". Exp: ", _interpolate(_named("exp")), ". Holder: ", _interpolate(_named("holder"))])}
      }
    },
    "scmIntegration": {
      "accessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["access token"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])}
    },
    "voucher": {
      "redeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redeemed"])},
      "redeemedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redeemed at"])},
      "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiration"])},
      "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validity"])},
      "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires"])},
      "expireAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expire at"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
      "voucherCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voucher code"])},
      "creditCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit code"])},
      "initialAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initial amount"])},
      "maxMonthlyAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max amount per month"])},
      "remainingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining amount"])}
    },
    "order": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
      "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quote"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["service opening"])},
      "quote_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order amount"])},
      "consumed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total consumption"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact email"])},
      "statuses": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archived"])}
      }
    },
    "collaborator": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
    },
    "domain": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "sslStatuses": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain is not yet protected by SSL"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain is not protected by SSL, the certificate generation is in error"])},
        "manualActionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain must be configured before being protected by SSL"])},
        "willSoonExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain is protected by SSL, but the certificate is about to expire"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate is expired"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain is protected by SSL"])},
        "successLetsEncrypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain is protected by SSL via Let's Encrypt"])}
      }
    },
    "variable": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["value"])}
    },
    "icons": {
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commit"])},
      "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image size"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])}
    }
  }
}