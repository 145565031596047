export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon suspended"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the plan"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason:"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon suspendu"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec le plan"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison :"])}
      }
    }
  })
}
