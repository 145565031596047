<template>
  <ViewComponent
    :user="currentUser"
    :billingProfile="billingProfile.value"
    :paymentMethods="paymentMethods"
    :cardPaymentMethodHandler="cardPaymentMethodHandler"
    :sepaPaymentMethodHandler="sepaPaymentMethodHandler"
    :deletePaymentMethodHandler="deletePaymentMethodHandler"
    :defaultPaymentMethodHandler="defaultPaymentMethodHandler"
    @sepaFormSubmitted="(e) => sepaPaymentMethodHandler.submit(e)"
    @sepaModalOpened="openSepaModal"
    @sepaModalClosed="closeSepaModal"
    @cardFormSubmitted="(e) => cardPaymentMethodHandler.submit(e)"
    @cardModalOpened="openCardModal"
    @cardModalClosed="closeCardModal"
    @deletePaymentMethod="deletePaymentMethod"
    @makePaymentMethodDefault="makePaymentMethodDefault"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/billing/PaymentMethods.vue";
import {
  CreateCardPaymentMethodHandler,
  CreateSepaPaymentMethodHandler,
  DeletePaymentMethodHandler,
  SetDefaultPaymentMethodHandler,
} from "@/lib/handlers";
import { Routes } from "@/router/names";
import { billingProfileAndFetch } from "@/store/billing-profile";
import {
  listPaymentMethods,
  ensurePaymentMethods,
} from "@/store/payment-methods";

export default defineComponent({
  name: "PaymentMethodsContainer",
  components: { ViewComponent },
  data() {
    return {
      cardPaymentMethodHandler: null,
      sepaPaymentMethodHandler: null,
      deletePaymentMethodHandler: null,
      defaultPaymentMethodHandler: null,
    };
  },
  computed: {
    billingProfile() {
      return billingProfileAndFetch(this.$store);
    },
    paymentMethods() {
      return listPaymentMethods(this.$store);
    },
  },
  async beforeMount() {
    await this.billingProfile.latestFetch.settled;

    if (!this.billingProfile.value) {
      this.$router.push({ name: Routes.Billing.Root });
      return;
    }

    this.deletePaymentMethodHandler = null;
    this.defaultPaymentMethodHandler = null;

    if (this.billingProfile.value) {
      ensurePaymentMethods(this.$store, this.billingProfile.value.id);
    }
  },
  methods: {
    openSepaModal() {
      this.sepaPaymentMethodHandler = new CreateSepaPaymentMethodHandler(
        this,
        this.currentUser,
        this.billingProfile.value,
      );
    },
    closeSepaModal() {
      this.sepaPaymentMethodHandler = null;
    },
    openCardModal() {
      this.cardPaymentMethodHandler = new CreateCardPaymentMethodHandler(
        this,
        this.currentUser,
        this.billingProfile.value,
        this.paymentMethods.items,
      );
    },
    closeCardModal() {
      this.cardPaymentMethodHandler = null;
    },
    deletePaymentMethod({ paymentMethod }) {
      this.deletePaymentMethodHandler = new DeletePaymentMethodHandler(
        this,
        paymentMethod,
      );
      this.deletePaymentMethodHandler.submit();
    },
    makePaymentMethodDefault({ paymentMethod }) {
      this.defaultPaymentMethodHandler = new SetDefaultPaymentMethodHandler(
        this,
        paymentMethod,
        this.paymentMethods.items,
      );
      this.defaultPaymentMethodHandler.submit();
    },
  },
});
</script>
