<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <font-awesome-icon
        icon="globe-europe"
        class="h-6 w-6 text-scale-6"
        size="2x"
      ></font-awesome-icon>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #more
      ><span
        >{{ $t("from") }}
        <span class="font-medium">{{ event.type_data.source }}</span>
        {{ $t("to") }}
        <span class="font-medium">{{ event.type_data.destination }}</span>
        {{ $t("name") }}
        <span class="font-medium">{{
          event.type_data.dst_app_name
        }}</span></span
      ></template
    >
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Region migration starting"
  from: "From"
  to: "to"
  name: "region with name"
fr:
  title: "Migration de région lancée"
  from: "Depuis"
  to: "vers la région"
  name: "avec le nom"
</i18n>
