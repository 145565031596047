/* eslint-disable no-console */

function shouldLog(): boolean {
  return (
    process.env.NODE_ENV === "development" ||
    !!localStorage.getItem("Dashboard.Debug")
  );
}

export function log(message?: unknown, ...optionalParams: unknown[]): void {
  if (shouldLog()) {
    console.log(message, ...optionalParams);
  }
}

export function debug(message?: unknown, ...optionalParams: unknown[]): void {
  if (shouldLog()) {
    console.debug(message, ...optionalParams);
  }
}

export function info(message?: unknown, ...optionalParams: unknown[]): void {
  if (shouldLog()) {
    console.info(message, ...optionalParams);
  }
}

export function warn(message?: unknown, ...optionalParams: unknown[]): void {
  if (shouldLog()) {
    console.warn(message, ...optionalParams);
  }
}

export const dashboard = { log, debug, info, warn };
