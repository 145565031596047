<template>
  <svg
    class="h-6 w-6"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.37856 1.15005C2.28788 1.05338 2.16128 0.998535 2.02877 0.998535C1.89627 0.998535 1.76967 1.05338 1.67899 1.15005L1.14931 1.68005C1.05269 1.77079 0.997883 1.89747 0.997883 2.03005C0.997883 2.16264 1.05269 2.28932 1.14931 2.38005L3.74773 5.00005C2.05625 6.13216 0.765553 7.77014 0.0599735 9.68005C0.0226499 9.78273 0.00239364 9.89083 1.0041e-05 10.0001C-0.000518915 10.1063 0.019856 10.2117 0.0599735 10.3101C1.68479 14.3691 5.62406 17.022 9.99392 17.0001C11.6115 16.9906 13.2064 16.6181 14.6611 15.9101L17.6093 18.8501C17.7 18.9467 17.8266 19.0016 17.9591 19.0016C18.0916 19.0016 18.2182 18.9467 18.3089 18.8501L18.8385 18.3201C18.9352 18.2293 18.99 18.1026 18.99 17.9701C18.99 17.8375 18.9352 17.7108 18.8385 17.6201L2.37856 1.15005ZM9.99392 15.0001C6.6124 14.9924 3.5333 13.0498 2.06875 10.0001C2.73385 8.52815 3.82309 7.28852 5.19684 6.44005L7.35553 8.60005C6.80796 9.74728 7.04253 11.1153 7.94096 12.0143C8.83939 12.9132 10.2066 13.148 11.3531 12.6001L13.112 14.3601C12.1189 14.7572 11.063 14.9739 9.99392 15.0001ZM7.11567 3.40005C12.3856 1.8925 17.9421 4.61201 19.9878 9.70005C20.0041 9.79939 20.0041 9.90071 19.9878 10.0001C19.9911 10.113 19.9706 10.2254 19.9279 10.3301C19.4434 11.684 18.6454 12.9039 17.5993 13.8901L16.1802 12.4701C16.9265 11.7779 17.519 10.9363 17.9191 10.0001C16.4545 6.95028 13.3754 5.00767 9.99392 5.00005C9.59581 5.00038 9.19828 5.03047 8.80465 5.09005L7.11567 3.40005Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StrikeEyeGlyph",
});
</script>
