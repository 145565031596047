import Rollbar from "rollbar";

import { LoginOrAuthorize } from "./fallbacks/login-or-authorize";
import { Logout } from "./fallbacks/logout";
import { LoginFromCache } from "./middlewares/login-from-cache";
import { LoginFromOAuthAssisted } from "./middlewares/login-from-oauth-assisted";
import { LoginFromOAuthCallback } from "./middlewares/login-from-oauth-callback";
import { AuthPipeline } from "./pipeline";
import { saveTokenInCache } from "./postprocessors/save-token-in-cache";
import { PostProcessor } from "./utils";

const endpoint = process.env.VUE_APP_OAUTH_ENDPOINT as string;
const clientID = process.env.VUE_APP_OAUTH_CLIENT_ID as string;

export function initialLoginPipeline(
  rollbar: Rollbar,
  postProcess?: PostProcessor,
): AuthPipeline {
  const postProcessors = [saveTokenInCache];
  if (postProcess) postProcessors.push(postProcess);

  return new AuthPipeline({
    middlewares: [
      new LoginFromOAuthCallback(),
      new LoginFromCache(),
      new LoginFromOAuthAssisted(endpoint, clientID),
    ],
    fallback: new LoginOrAuthorize(endpoint, clientID),
    postProcessors,
    rollbar,
  });
}

export function refreshTokenPipeline(
  rollbar: Rollbar,
  postProcess?: PostProcessor,
): AuthPipeline {
  const postProcessors = [saveTokenInCache];
  if (postProcess) postProcessors.push(postProcess);

  return new AuthPipeline({
    middlewares: [new LoginFromOAuthAssisted(endpoint, clientID)],
    fallback: new LoginOrAuthorize(endpoint, clientID),
    postProcessors,
    rollbar,
  });
}

export function logoutPipeline(rollbar: Rollbar): AuthPipeline {
  return new AuthPipeline({
    middlewares: [],
    fallback: new Logout(endpoint),
    postProcessors: [],
    rollbar,
  });
}
