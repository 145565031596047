<template>
  <SimpleModal :hasTitleIcon="true" @modalClosed="$emit('modalClosed')">
    <template v-slot:title>
      {{ $t("title", { type: form.container }) }}
    </template>
    <template v-slot:subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template v-slot:titleIcon>
      <ScaleGlyph></ScaleGlyph>
    </template>
    <template v-slot:body>
      <div class="popinWidth">
        <Form ref="form" v-slot="{ meta, handleSubmit }" as="">
          <form @submit.prevent="handleSubmit(submitForm)">
            <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
              <div class="mt-6 flex">
                <div class="w-1/2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.min_containers"
                    name="min_containers"
                    rules="required|integer"
                  >
                    <NumberInput
                      :name="field.name"
                      :modelValue="field.value"
                      :label="$t('form.containersRangeMinimum')"
                      labelClass="humanize"
                      min="2"
                      :max="maxContainers"
                      :errors="errors"
                      @update:modelValue="handleChange"
                    ></NumberInput>
                  </Field>
                </div>
                <div class="w-1/2 ml-2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.max_containers"
                    name="max_containers"
                    rules="required|integer"
                  >
                    <NumberInput
                      :name="field.name"
                      :modelValue="field.value"
                      :label="$t('form.containersRangeMaximum')"
                      labelClass="humanize"
                      :min="form.min_containers"
                      :max="maxContainers"
                      :errors="errors"
                      @update:modelValue="handleChange"
                    ></NumberInput>
                  </Field>
                </div>
              </div>
              <div class="flex">
                <div class="w-1/2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.metric"
                    name="metric"
                    rules="required"
                  >
                    <AdvancedSelectInput
                      :name="field.name"
                      :modelValue="field.value"
                      :options="whenOptions"
                      :errors="errors"
                      @update:modelValue="handleChange"
                    >
                      <template #label>
                        {{ $t("form.scaleWhen") }}
                      </template>

                      <template #text="{ option }">
                        {{ option.text }}
                      </template>
                    </AdvancedSelectInput>
                  </Field>
                </div>
                <div class="w-1/2 ml-2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.target"
                    name="target"
                    :rules="targetRules"
                  >
                    <NumberInput
                      :name="field.name"
                      :modelValue="field.value"
                      :subText="recommendationLabel"
                      :unit="$t('form.variesFromUnit.' + form.metric)"
                      :label="$t('form.variesFrom')"
                      min="0"
                      :max="maxTarget"
                      labelClass="humanize"
                      :errors="errors"
                      @update:modelValue="handleChange"
                    ></NumberInput>
                  </Field>
                </div>
              </div>

              <CheckboxInputAtom
                v-if="initiallyDisabled"
                :modelValue="!form.disabled"
                :label="$t('form.reenable')"
                class="mt-6"
                @update:modelValue="(e) => (form.disabled = !e)"
              />

              <FormAlert
                v-if="formHandler.errors.base"
                class="mt-4"
                styling="error"
              >
                <template #text>
                  {{ $t("errors.generic") }}
                </template>
              </FormAlert>

              <SCButton
                block
                kind="primary"
                type="submit"
                size="lg"
                class="flex-grow mt-6"
                :disabled="!meta.valid"
                :loading="formHandler?.isSubmitting"
              >
                {{ $t("buttonName") }}
              </SCButton>

              <SCButton
                block
                kind="neutral"
                size="lg"
                class="mt-4"
                @click="$emit('modalClosed')"
              >
                {{ $t("cancelButton") }}
              </SCButton>
            </div>
          </form>
        </Form>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import ScaleGlyph from "@/components/atoms/glyphs/ScaleGlyph.vue";
import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import NumberInput from "@/components/molecules/inputs/NumberInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import { METRICS_IN_PERCENTS } from "@/store/autoscalers";

export default defineComponent({
  name: "AutoScaleModal",
  components: {
    AdvancedSelectInput,
    NumberInput,
    ScaleGlyph,
    SCButton,
    SimpleModal,
    Field,
    Form,
    FormAlert,
    CheckboxInputAtom,
  },
  mixins: [FormModalHandlerMixin],
  props: {
    maxContainers: [Number, String],
    autoscalerRecommendedValue: Number,
  },
  emits: ["autoscalerMetricChanged", "modalClosed"],
  data() {
    return {
      form: {
        initiallyDisabled: false,
      },
    };
  },
  computed: {
    targetRules() {
      return METRICS_IN_PERCENTS.includes(this.form.metric)
        ? "required|max_value:200"
        : "required";
    },
    maxTarget() {
      return METRICS_IN_PERCENTS.includes(this.form.metric) ? 200 : null;
    },
    recommendationLabel() {
      if (this.autoscalerRecommendedValue) {
        return this.$i18n.t(`form.variesFromSubText.${this.form.metric}`, {
          value: this.autoscalerRecommendedValue,
        });
      } else {
        return this.$i18n.t("form.noRecommendation");
      }
    },
    whenOptions() {
      return [
        { text: this.$t("form.variesFromValues.cpu"), value: "cpu" },
        { text: this.$t("form.variesFromValues.memory"), value: "memory" },
        { text: this.$t("form.variesFromValues.swap"), value: "swap" },
        {
          text: this.$t("form.variesFromValues.p95_response_time"),
          value: "p95_response_time",
        },
        {
          text: this.$t("form.variesFromValues.5XX"),
          value: "5XX",
        },
        {
          text: this.$t("form.variesFromValues.rpm"),
          value: "rpm",
        },
        {
          text: this.$t("form.variesFromValues.rpm_per_container"),
          value: "rpm_per_container",
        },
      ];
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);

        // Without this temp field,
        // the checkbox would disappear as soon as we tick it
        this.initiallyDisabled = this.form.disabled;
      },
    },
    "form.metric": {
      immediate: true,
      handler(metric) {
        this.$emit("autoscalerMetricChanged", {
          container: this.form.container,
          metric,
        });
      },
    },
  },
});
</script>

<style scoped>
.popinWidth {
  width: 750px;
}
</style>

<i18n>
en:
  title: "Autoscaler for container {type}"
  subtitle: "Configure how this resource will automatically scale"
  errors:
    generic: "Could not save autoscaler: make sure the values selected are correct."
  form:
    containersRange: "Containers range"
    containersRangeMinimum: "Minimum containers"
    containersRangeMaximum: "Maximum containers"
    scaleWhen: "Scale when"
    variesFrom: "Varies from"
    noRecommendation: "No recommended value"
    reenable: "Re-enable autoscaler"
    variesFromValues:
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Response time"
      5XX: "5xx errors"
      rpm: "Requests per minute"
      rpm_per_container: "RPM per container"
    variesFromSubText:
      cpu: "{value} % recommended"
      memory: "{value} % recommended"
      swap: "{value} % recommended"
      p95_response_time: "{value} ms recommended"
      5XX: "{value} recommended"
      rpm: "{value} rpm recommended"
      rpm_per_container: "{value} rpm recommended"
    variesFromUnit:
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "per min"
      rpm: "rpm"
      rpm_per_container: "rpm"
  buttonName: "Confirm"
  cancelButton: "Cancel"
fr:
  title: "Autoscaler pour le container {type}"
  subtitle: "Configurer commment cette ressource va automatiquement changer d'échelle"
  errors:
    generic: "Impossible de sauvegarder l'autoscaler : vérifiez que les valeurs sélectionnées sont correctes."
  form:
    containersRange: "Intervalle des containers"
    containersRangeMinimum: "Minimum de containers"
    containersRangeMaximum: "Maximum de containers"
    scaleWhen: "Changer quand"
    variesFrom: "Valeur varie de"
    noRecommendation: "Aucune valeur recommandée"
    reenable: "Réactiver l'autoscaler"
    variesFromValues:
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Temps de réponse"
      5XX: "Erreurs 5xx"
      rpm: "Requêtes par minute"
      rpm_per_container: "RPM par container"
    variesFromSubText:
      cpu: "{value} % recommandé"
      memory: "{value} % recommandé"
      swap: "{value} % recommandé"
      p95_response_time: "{value} ms recommandé"
      5XX: "{value} recommandé"
      rpm: "{value} rpm recommandé"
      rpm_per_container: "{value} rpm recommandé"
    variesFromUnit:
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "par min"
      rpm: "rpm"
      rpm_per_container: "rpm"
  buttonName: "Confirmer"
  cancelButton: "Annuler"
</i18n>
