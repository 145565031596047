<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <RestartGlyph class="text-color-1" />
      <div class="text-color-1 pl-2 font-bold">
        <template v-if="event.type_data.scope">
          {{ $t("title.some", { type: type }) }}
        </template>
        <template v-else>
          {{ $t("title.all") }}
        </template>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import RestartGlyph from "@/components/atoms/glyphs/RestartGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "RestartEvent",
  components: { BaseEvent, RestartGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.event.type_data.scope.join(", ");
    },
  },
});
</script>

<i18n>
en:
  title:
    all: "All containers restarted"
    some: "Containers of type {type} restarted"
fr:
  title:
    all: "Tous les containers redémarrés"
    some: "Containers de type {type} redémarrés"
</i18n>
