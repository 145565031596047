import { requiredForHDS, requiredForPlatform } from "scalingo/lib/Contracts";
import { Contract } from "scalingo/lib/models/auth";

import { scalingoClient } from "@/lib/scalingo/client";
import { REFRESH, HANDLE_FETCH, FETCH_MORE } from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { MERGE, SET_ALL, SET_META } from "@/lib/store/mutation-types";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
  EnsureOptions,
  mergeListOptions,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class ContractsStore extends CollectionStore<Contract> {
  actions = CollectionStore.buildActions<Contract>({
    [REFRESH](context) {
      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context).Contracts.list(),
        resolveAction: (contracts: Contract[]) => {
          context.commit(SET_META, { hasMore: true });
          context.commit(SET_ALL, contracts);
        },
      });
    },
    [FETCH_MORE](context) {
      context.dispatch(HANDLE_FETCH, {
        promise: scalingoClient(context).Contracts.history(),
        resolveAction: (contracts: Contract[]) => {
          context.commit(SET_META, { hasMore: false });
          context.commit(MERGE, contracts);
        },
      });
    },
  });
  mutations = CollectionStore.buildMutations<Contract>();
  getters = CollectionStore.buildGetters<Contract>();
}

export const Contracts = buildMapping(new ContractsStore(), "contracts");

export function listContracts(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Contract>>,
): CollectionWithFetch<Contract> {
  return {
    items: listItems(store.getters[Contracts.getters.ALL], opts),
    latestFetch: store.getters[Contracts.getters.LATEST_FETCH],
    meta: store.getters[Contracts.getters.META],
  };
}

/** Returns the contracts required for the platform, no matter their status */
export function listPlatformContracts(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Contract>> = {},
): CollectionWithFetch<Contract> {
  const newOptions: Partial<ListItemsOptions<Contract>> = {
    transform(coll) {
      return coll.filter((c) => requiredForPlatform(c));
    },
  };

  return listContracts(store, mergeListOptions(newOptions, opts));
}

/** Returns the contracts required for HDS, no matter their status */
export function listHDSContracts(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Contract>> = {},
): CollectionWithFetch<Contract> {
  const newOptions: Partial<ListItemsOptions<Contract>> = {
    transform(coll) {
      return coll.filter((c) => requiredForHDS(c));
    },
  };

  return listContracts(store, mergeListOptions(newOptions, opts));
}

/** Returns the contracts required for HDS that have been accepted, with new version available */
export function listPreviousHDSContractsAccepted(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Contract>> = {},
): CollectionWithFetch<Contract> {
  const newOptions: Partial<ListItemsOptions<Contract>> = {
    transform(coll) {
      return coll.filter((c) => requiredForHDS(c) && c.accepted && !c.latest);
    },
  };

  return listContracts(store, mergeListOptions(newOptions, opts));
}

/** Returns the contracts required for the platform that have not been answered yet */
export function listPlatformContractsToAccept(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Contract>> = {},
): CollectionWithFetch<Contract> {
  const newOptions: Partial<ListItemsOptions<Contract>> = {
    transform(coll) {
      return coll.filter((c) => !c.accepted && !c.refused && c.latest);
    },
  };

  return listPlatformContracts(store, mergeListOptions(newOptions, opts));
}

/** Returns the contracts required for the platform that have been accepted, with new version available */
export function listPreviousPlatformContractsAccepted(
  store: ApplicationStore,
  opts: Partial<ListItemsOptions<Contract>> = {},
): CollectionWithFetch<Contract> {
  const newOptions: Partial<ListItemsOptions<Contract>> = {
    transform(coll) {
      return coll.filter((c) => c.accepted && !c.latest);
    },
  };

  return listPlatformContracts(store, mergeListOptions(newOptions, opts));
}

export function ensureContracts(
  store: ApplicationStore,
  opts: EnsureOptions = {},
): void {
  store.dispatch(Contracts.actions.ENSURE, opts);
}

export function ensureAllContracts(store: ApplicationStore): void {
  store.dispatch(Contracts.actions.FETCH_MORE);
}
