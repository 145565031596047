<template>
  <div :class="classFn">
    <router-link :to="item.routerArgs">{{ item.name }}</router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "VerticalMenuItem",
  props: {
    item: Object,
  },
  computed: {
    classFn: function () {
      return {
        "text-scale-10 font-bold": this.item.selected,
        "text-scale-7 hover:text-scale-9": !this.item.selected,
      };
    },
  },
});
</script>
