<template>
  <div class="flex flex-row justify-between mb-6 rounded-sm">
    <div class="flex flex-row space-x-6">
      <div class="flex flex-row items-center space-x-2">
        <span class="text-sm text-scale-7">{{ $t("groupBy") }}</span>
        <GroupBySelector
          :selectedValue="groupBy"
          @rangeSelected="(e) => $emit('groupBySelected', e)"
        ></GroupBySelector>
      </div>
      <CheckboxInput v-if="false" :label="$t('mergeReviewApp')"></CheckboxInput>
    </div>
    <SCButton
      kind="neutral"
      :actions="actions"
      @action="downloadJson"
      @click="downloadCsv"
    >
      <div class="flex flex-row items-center">
        <font-awesome-icon
          class="mr-1 py-0.5 text-scale-7"
          icon="file-export"
        ></font-awesome-icon>
        <span>{{ $t("exportCsv") }}</span>
      </div>
    </SCButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInput from "@/components/atoms/inputs/CheckboxInput.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import GroupBySelector from "@/components/parts/consumption/GroupBySelector.vue";

export default defineComponent({
  name: "Toolbar",
  components: {
    CheckboxInput,
    GroupBySelector,
    SCButton,
  },
  props: {
    usage: Object,
    fromDate: Object,
    toDate: Object,
    groupBy: String,
    accountName: String,
  },
  computed: {
    appUsages() {
      return this.usage.item.app_usages;
    },
    actions() {
      return [
        {
          text: this.$t("exportJson"),
          key: "export-json",
        },
      ];
    },
  },
  methods: {
    csvContent() {
      let content = [
        ["app", "sku", "resource", "consumption", "amount (cents)", "region"],
      ];
      for (const k in this.appUsages) {
        const usage = this.appUsages[k];
        const appName = usage.app;
        for (const k1 in usage.runtimes) {
          const runtime = usage.runtimes[k1];
          content.push([
            appName,
            runtime.sku,
            this.runtimeItemString(runtime),
            runtime.duration,
            runtime.price,
            usage.region,
          ]);
        }
        for (const k2 in usage.addon_usages) {
          const addon = usage.addon_usages[k2];
          content.push([
            appName,
            addon.sku,
            this.addonItemString(addon),
            addon.duration,
            addon.price,
            usage.region,
          ]);
        }
      }
      return content;
    },
    fileName() {
      return (
        this.accountName +
        "_consumption_" +
        this.fromDate.toISODate() +
        "_" +
        this.toDate.toISODate()
      );
    },
    runtimeItemString(item) {
      return `${this.$t("labels.container")} - ${this.$t("labels.type")} ${
        item.type
      } - ${this.$t("labels.size")} ${item.size_name}`;
    },
    addonItemString(item) {
      return `${this.$t("labels.addon")} ${item.provider_name} - ${
        item.plan_name
      }`;
    },
    downloadCsv() {
      this.downloadBlob(
        this.arrayToCsv(this.csvContent()),
        this.fileName() + ".csv",
        "text/csv;charset=utf-8;",
      );
    },
    downloadJson() {
      this.downloadBlob(
        JSON.stringify(this.usage.item.app_usages),
        this.fileName() + ".json",
        "application/json;charset=utf-8;",
      );
    },
    downloadBlob(content, filename, charset) {
      const blob = new Blob([content], { type: charset });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      a.click();
    },
    arrayToCsv(data) {
      return data
        .map(
          (row) =>
            row
              .map(String) // convert every value to String
              .map((v) => v.replaceAll('"', '""')) // escape double quotes
              .map((v) => `"${v}"`) // quote it
              .join(","), // comma-separated
        )
        .join("\r\n"); // rows starting on new lines
    },
  },
});
</script>

<i18n>
en:
  labels:
    container: "Container"
    size: "Size"
    type: "Type"
    addon: "Addon"
  exportCsv: "Export as CSV"
  exportJson: "Export as JSON"
  mergeReviewApp: "Merge review apps consumption with their parent app"
  groupBy: "Group by"
fr:
  labels:
    container: "Container"
    size: "Taille"
    type: "Type"
    addon: "Addon"
  exportCsv: "Exporter en CSV"
  exportJson: "Exporter en JSON"
  mergeReviewApp: "Fusionner la consommation des Review App avec leur application parente"
  groupBy: "Grouper par"
</i18n>
