import Client from "scalingo";
import { Variable } from "scalingo/lib/models/regional";

import { App } from "@/lib/scalingo/modifiers/apps";

type VariableWithoutId = Pick<Variable, "name" | "value">;

/**
 * The second step of the one click deploy consists in creating the environment variables.
 * If there's none, we bypass the request altogether
 */
export async function ocdStep2(
  client: Client,
  app: App,
  variables: VariableWithoutId[],
): Promise<App> {
  if (variables.length > 0) {
    await client.Environment.bulkUpdate(app.id, variables);
  }

  return app;
}
