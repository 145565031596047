<template>
  <ViewComponent
    :app="app"
    :loadingOperation="loadingOperation"
    :addon="addon"
    :selectedAddonProvider="selectedAddonProvider"
    @addonPlanSelected="addonPlanSelected"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/addonsEdition/Step2.vue";
import { Routes } from "@/router/names";
import { listAddonProviders } from "@/store/addon-providers";
import { billingProfile } from "@/store/billing-profile";

export default defineComponent({
  name: "AddonCreationStep2Container",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    addon: Object,
  },
  computed: {
    loadingOperation() {
      return this.addonProviders.latestFetch;
    },
    billingProfile() {
      return billingProfile(this.$store);
    },
    addonProviders() {
      return listAddonProviders(this.$store, this.app.region);
    },
    selectedAddonProvider() {
      return this.addonProviders.items.find((provider) => {
        return provider.id === this.addon.addon_provider.id;
      });
    },
  },
  methods: {
    addonPlanSelected(addonPlan) {
      this.$router.push({
        name: Routes.App.Addon.Edition.Step3,
        params: {
          ...this.$route.params,
          planId: addonPlan.name,
        },
      });
    },
  },
});
</script>
