<template>
  <table
    class="w-full bg-scale-0"
    :class="layout === 'auto' ? 'table-auto' : 'table-fixed'"
  >
    <thead v-if="showHeaders">
      <tr class="text-scale-6 text-sm h-8">
        <th
          v-for="(header, index) in headers"
          :key="`${randomPrefix}-header-${index}`"
          :class="columnClass(header)"
        >
          <slot name="header" v-bind="{ header, value: header.text }">
            {{ header.text }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="`${randomPrefix}-row-${index}`"
        class="hover:shadow-lg rounded"
      >
        <td
          v-for="(header, hindex) in headers"
          :key="`${randomPrefix}-cell-${hindex}`"
          :class="{
            'text-left': !header.align || header.align == 'left',
            'text-center': header.align == 'center',
            'text-right': header.align == 'right',
            'bg-scale-0': striped && index % 2 == 0,
            'bg-scale-1': striped && index % 2 == 1,
            'border border-scale-1': !striped,
            'px-2': smallPadding,
            'px-4': !smallPadding,
          }"
          class="py-2"
        >
          <slot
            :name="`item.${header.value}`"
            v-bind="{ header, item, index, value: item[header.value] }"
          >
            <slot
              name="item"
              v-bind="{ header, item, index, value: item[header.value] }"
            >
              {{ item[header.value] }}
            </slot>
          </slot>
        </td>
      </tr>
    </tbody>
    <tfoot></tfoot>
  </table>
</template>

<script>
import { defineComponent } from "vue";

export const TABLE_LAYOUT_AUTO = "auto";
export const TABLE_LAYOUT_FIXED = "fixed";
export const TABLE_LAYOUTS = [TABLE_LAYOUT_AUTO, TABLE_LAYOUT_FIXED];

export default defineComponent({
  name: "BaseTable",
  components: {},
  props: {
    /** The layout class of the table.
     * @values auto, fixed
     * @default auto
     */
    layout: {
      type: String,
      default: TABLE_LAYOUT_AUTO,
      validator(value) {
        return TABLE_LAYOUTS.includes(value);
      },
    },
    headers: {
      type: Array,
      default: () => [],
    },
    showHeaders: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    striped: {
      type: Boolean,
      default: true,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      randomPrefix: Math.random().toString(36).substring(7),
    };
  },
  methods: {
    columnClass(header) {
      let base = "bg-scale-1 font-normal";

      base += this.smallPadding ? " px-2" : " px-4";

      if (!header.align || header.align == "left") {
        base += " text-left";
      }
      if (header.align == "center") {
        base += " text-center";
      }
      if (header.align == "right") {
        base += " text-right";
      }

      if (header.class) {
        base += ` ${header.class}`;
      }

      return base;
    },
  },
});
</script>

<style scoped>
th:first-of-type,
td:first-of-type {
  @apply rounded-l;
}

th:last-of-type,
td:last-of-type {
  @apply rounded-r;
}

tbody > tr:hover {
  /* hack to make tr shadowable */
  transform: scale(1);
}
</style>
