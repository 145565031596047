import { APIError } from "scalingo/lib/errors";
import { App } from "scalingo/lib/models/regional";
import { RouteLocationNormalized } from "vue-router";

import { FormHandler } from "@/lib/handlers/form";
import { defaultRegion } from "@/lib/scalingo/client";
import { Nullable } from "@/lib/utils/types";
import { Routes } from "@/router/names";
import { createApp } from "@/store/apps";
import { useAppInfosStore } from "@/stores/app-infos";

interface FormData {
  name: string;
  region: string;
  hds_resource?: boolean;
}

export class CreateAppHandler extends FormHandler<App> {
  keyPath = "app.create";

  data(): Nullable<FormData> {
    return {
      name: null,
      region: defaultRegion.code,
      hds_resource: false,
    };
  }

  dispatchEvents(): void {
    this.on("success", async (app) => {
      // Vue router typings does not accept "irregular" objects, so we have to do a bit of typecasting here.
      const params = {
        id: app.name,
        region: app.region,
        app: app,
      } as unknown as RouteLocationNormalized["params"];

      // Refreshing the app listing
      const appInfosStore = useAppInfosStore();
      appInfosStore.ensure({ staleAfter: "always" });
      await appInfosStore.promise;

      this.$router.push({
        name: Routes.Configure.App,
        params,
      });
    });
  }

  async submit(event: FormData): Promise<void> {
    const { region, ...payload } = event;

    this.follow(await createApp(this.$store, region, payload));
  }

  formatErrors(): void {
    const error = this.operation?.error;

    if (error && error instanceof APIError) {
      this.errors.$codes.push(...formatAppCreationErrors(error));
    }
  }
}

export function formatAppCreationErrors(error: APIError): string[] {
  const codes = [];

  // Handled error codes
  if (error.data?.name === "free-trial-expired") {
    codes.push("free-trial-expired");
  }

  if (error.data?.name === "free-trial-exceeded") {
    codes.push("free-trial-exceeded");
  }

  if (error.data?.name === "max-apps-exceeded") {
    codes.push("max-apps-exceeded");
  }

  if (error.data?.errors?.base?.[0] === "rejected") {
    codes.push("app-rejected");
  }

  return codes;
}
