<template>
  <span class="text-5xl font-bold">∅</span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmptySetGlyph",
});
</script>
