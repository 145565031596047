export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": {
          "newAddon": {
            "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an addon"])},
            "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a plan"])},
            "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your selection"])}
          },
          "editAddon": {
            "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a plan"])},
            "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your selection"])}
          }
        }
      },
      "fr": {
        "title": {
          "newAddon": {
            "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix d'un add-on"])},
            "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix d'un plan"])},
            "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la selection"])}
          },
          "editAddon": {
            "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix d'un plan"])},
            "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la selection"])}
          }
        }
      }
    }
  })
}
