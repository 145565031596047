export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage containers in use."])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
        "estimatedCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated cost"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No containers yet, they will appear after the first successful deployment."])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help? Refer to"])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" the documentation "])},
        "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or contact us through the support."])},
        "actions": {
          "scale": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale all"])}
          },
          "restart": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart all"])},
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart"])}
          },
          "autoscale": {
            "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable autoscale"])}
          },
          "stop": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop all"])}
          }
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteneurs"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des conteneurs utilisés."])},
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
        "estimatedCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût estimé"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qté"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de conteneurs, ils apparaîtront après le premier déploiement réussi."])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide ? Consultez"])},
        "doclink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" la documentation "])},
        "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou contactez nous au support."])},
        "actions": {
          "scale": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout scaler"])}
          },
          "restart": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout redémarrer"])},
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrer"])}
          },
          "autoscale": {
            "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer l'autoscaling"])}
          },
          "stop": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout stopper"])}
          }
        }
      }
    }
  })
}
