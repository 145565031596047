<template>
  <div>
    <label
      :class="labelClass"
      class="py-4 block text-sm font-medium leading-5 text-scale-10"
    >
      {{ label }}
    </label>
    <div class="verflow-x-auto">
      <table class="min-w-full">
        <tbody>
          <template v-for="(option, index) in options" :key="index">
            <tr
              class="border-scale-3 border cursor-pointer"
              :class="{
                'bg-color-1-15': option.selected,
                'text-scale-5': option.on_demand,
              }"
              @click="onPlanClicked(option)"
            >
              <td class="pl-2 sm:px-4 py-2">
                <div class="flex items-center h-auto">
                  <RadioCheckButton
                    v-model="inputValue"
                    inputId="plan"
                    :enabled="modelValue !== option.id"
                  ></RadioCheckButton>
                  <span class="pl-2 md:pl-4 capitalize font-medium">{{
                    option.display_name
                  }}</span>
                </div>
              </td>
              <td class="pl-1 sm:px-4 py-2 sm:w-1/3">
                <div class="sm:flex items-center h-auto">
                  <div
                    class="sm:w-1/2 sm:flex sm:justify-end items-center pr-2 md:pr-8"
                  >
                    <template v-if="option.ramStr">
                      <span class="font-medium pr-2">{{ option.ramStr }}</span>
                      <span class="text-sm text-scale-8">RAM</span>
                    </template>
                  </div>
                  <div class="sm:w-1/2 sm:flex sm:justify-end items-center">
                    <template v-if="option.diskStr">
                      <span class="font-medium pr-2">{{ option.diskStr }}</span>
                      <span class="whitespace-nowrap">{{ $t("disk") }}</span>
                    </template>
                  </div>
                </div>
              </td>
              <td
                class="pl-2 md:pl-4 py-2 pr-2 sm:pr-0 flex items-center justify-end"
              >
                <div
                  v-if="displayPrices"
                  class="sm:flex sm:flex-col sm:items-center"
                >
                  <div class="font-medium">{{ option.price }}€</div>
                  <div class="text-sm text-scale-8">
                    {{ $t("per_thirty_days") }}
                  </div>
                </div>
                <div
                  class="hidden sm:flex sm:mx-3"
                  @click.stop="detailsToggled(option.id)"
                >
                  <IconActionButton
                    :isDisabled="option.id === inputValue"
                    :title="
                      detailsToggle !== option.id
                        ? $t('expandPlanInformation')
                        : $t('collapsePlanInformation')
                    "
                    class="text-scale-8"
                    icon="info"
                    :active="
                      detailsToggle === option.id || option.id === inputValue
                    "
                  ></IconActionButton>
                </div>
              </td>
            </tr>
            <tr
              v-if="detailsToggle === option.id || option.id === inputValue"
              :key="index + '-details'"
              class="bg-scale-3-25 border-l border-r border-scale-3"
            >
              <td colspan="3" class="px-4 md:px-8 py-4 text-sm">
                <div class="grid sm:grid-cols-2">
                  <div
                    v-for="(item, idx) in option.descriptionItems"
                    :key="idx"
                  >
                    {{ item }}
                  </div>
                  <p
                    v-if="option.on_demand"
                    tag="div"
                    keypath="planOnDemand.label"
                    class="mt-2 text-warning col-span-2"
                  >
                    {{ $t("planOnDemand.label") }}
                    <span
                      class="underline font-bold cursor-pointer"
                      @click="openSupport(option)"
                    >
                      {{ $t("planOnDemand.supportRequest") }}
                    </span>
                  </p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import IconActionButton from "@/components/atoms/buttons/IconActionButton.vue";
import RadioCheckButton from "@/components/molecules/specialisedInputs/RadioCheckButton.vue";

export default defineComponent({
  name: "PlanOptionRow",
  components: { IconActionButton, RadioCheckButton },
  props: {
    provider: Object,
    options: Array,
    label: String,
    labelClass: String,
    modelValue: String,
    displayPrices: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  data: function () {
    return {
      detailsToggle: "",
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    onPlanClicked(plan) {
      this.detailsToggled(plan.id);

      if (plan.on_demand) {
        return;
      }

      this.inputValue = plan.id;
    },
    detailsToggled: function (id) {
      if (this.detailsToggle === id) {
        this.detailsToggle = "";
      } else {
        this.detailsToggle = id;
      }
    },
    openSupport(plan) {
      const message = this.$t("planOnDemand.supportMsg", {
        plan: plan.display_name,
        addon: this.provider.name,
      });

      window.Intercom?.("showNewMessage", message);
    },
  },
});
</script>

<i18n>
en:
  expandPlanInformation: "Expand information about this plan"
  collapsePlanInformation: "Collapse information about this plan"
  per_thirty_days: "per 30 days"
  disk: "Disk"
  planOnDemand:
    label: "This plan is available on demand only. If you're interested, "
    supportRequest: "reach us through the support"
    supportMsg: "Hi Support Team,\n\n
      I would like to use the following on-demand plan: {addon} '{plan}'.\n\n
      My need is the following: [complete]\n\n
      Can you please provision it for me?\n\n
      Thanks a lot."
fr:
  expandPlanInformation: "Montrer plus information sur ce plan"
  collapsePlanInformation: "Cacher le surplus d'information sur ce plan"
  per_thirty_days: "pour 30 jours"
  disk: "Disque"
  planOnDemand:
    label: "Ce plan est disponible sur demande uniquement. Si vous êtes intéressés, "
    supportRequest: "contactez-nous au support"
    supportMsg: "Bonjour l'équipe support,\n\n
      Je souhaite utiliser le plan sur demande suivant: {addon} '{plan}'.\n\n
      Mes besoins sont les suivants: [complete]\n\n
      Pouvez-vous me provisionner l'addon?\n\n
      Merci beaucoup."
</i18n>
