import { Domain } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { deleteDomain } from "@/store/domains";

import type { ComponentPublicInstance } from "vue";

export class DeleteDomainHandler extends ActionHandler<void> {
  keyPath = "domains.delete";

  constructor(
    readonly component: ComponentPublicInstance,
    readonly domain: Domain,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => this.notify("success"));
    this.on("failure", () => this.notify("error"));
  }

  async submit(): Promise<void> {
    this.follow(await deleteDomain(this.$store, this.domain));
  }
}
