<template>
  <div
    v-if="addons.promiseInfo"
    class="bg-scale-0 rounded px-8 py-6 flex flex-col"
  >
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
      <template #buttons>
        <SCButton :to="routeArgs">
          {{ $t("action") }}
        </SCButton>
      </template>
    </CardHeader>

    <template v-if="addons.promiseInfo.isLoading">
      <LoadingCardState />
    </template>

    <template v-if="addons.promiseInfo.isFinished">
      <div v-if="addons.items.length" class="flex-grow">
        <AddonItem
          v-for="(item, index) in addons.items"
          :key="index"
          :app="app"
          :addon="item"
          :user="user"
          :rowIndex="index"
          class="py-3 flex"
          :class="{ 'border-t border-scale-2': index > 0 }"
        />
      </div>
      <EmptyCardState v-else>
        <template #icon>
          <PuzzlePieceGlyph class="h-8 text-scale-5"></PuzzlePieceGlyph>
        </template>

        {{ $t("none") }}
      </EmptyCardState>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PuzzlePieceGlyph from "@/components/atoms/glyphs/PuzzlePieceGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AddonItem from "@/components/parts/app/showApp/AddonItem.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AddonsCard",
  components: {
    EmptyCardState,
    SCButton,
    LoadingCardState,
    PuzzlePieceGlyph,
    CardHeader,
    AddonItem,
  },
  props: {
    app: Object,
    user: Object,
    addons: Object,
  },
  data() {
    return {
      routeArgs: {
        name: Routes.App.Resources,
        args: { id: this.app.name },
      },
    };
  },
});
</script>

<i18n>
en:
  title: "Addons"
  subtitle: "Linked extensions."
  action: "Manage"
  none: "No addon yet"
fr:
  title: "Addons"
  subtitle: "Extensions liées."
  action: "Gérer"
  none: "Aucun addon"
</i18n>
