import { scalingoPrivateClient } from "@/lib/scalingo/client";
import { Voucher, VouchersQuery } from "@/lib/scalingo/vouchers";
import {
  REFRESH,
  HANDLE_FETCH,
  CREATE,
  HANDLE_OPERATION,
} from "@/lib/store/action-types";
import { CollectionStore } from "@/lib/store/collection-store";
import { SET_ALL, ADD } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import {
  buildMapping,
  ListItemsOptions,
  listItems,
  CollectionWithFetch,
} from "@/lib/store/utils";

import { ApplicationStore } from ".";

export class VouchersStore extends CollectionStore<Voucher> {
  actions = CollectionStore.buildActions<Voucher>({
    async [REFRESH](context, params) {
      const opts: VouchersQuery = {
        owner_id: params.ownerId,
        type: "-credit",
      };

      context.dispatch(HANDLE_FETCH, {
        promise: scalingoPrivateClient(context).Vouchers.all(opts),
        resolveAction: SET_ALL,
      });
    },
    async [CREATE](context, payload) {
      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoPrivateClient(context).Vouchers.create(payload),
        resolveAction: ADD,
      });
    },
  });
  mutations = CollectionStore.buildMutations<Voucher>();
  getters = CollectionStore.buildGetters<Voucher>();
}

export const Vouchers = buildMapping(new VouchersStore(), "vouchers");

export function listVouchers(
  store: ApplicationStore,
  opts?: Partial<ListItemsOptions<Voucher>>,
): CollectionWithFetch<Voucher> {
  return {
    items: listItems(store.getters[Vouchers.getters.ALL], opts),
    latestFetch: store.getters[Vouchers.getters.LATEST_FETCH],
  };
}

export function ensureVouchers(store: ApplicationStore, ownerId: string): void {
  store.dispatch(Vouchers.actions.ENSURE, { payload: { ownerId } });
}

export function createVoucher(
  store: ApplicationStore,
  code: string,
): Promise<RemoteOperation<Voucher>> {
  return store.dispatch(Vouchers.actions.CREATE, { code });
}
