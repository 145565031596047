<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :logs="logs"
    :streamLogs="streamLogs"
    :logsFontSize="logsFontSize"
    :displayInFullScreen="displayInFullScreen"
    @streamingToggled="streamingToggled"
    @clearClicked="clearClicked"
    @fontSizeChanged="fontSizeChanged"
    @fullScreenToggled="fullScreenToggled"
  ></ViewComponent>
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/LogsLive.vue";
import FullscreenableController from "@/mixins/fullscreenable_controller";
import {
  listLogs,
  ensureLogs,
  clearLogs,
  toggleLogStreaming,
} from "@/store/logs";
import { updatePreferences } from "@/store/user";

export default defineComponent({
  name: "LogsLiveContainer",
  components: { ViewComponent },
  mixins: [FullscreenableController],
  props: {
    app: {
      type: Object,
      required: true,
    },
  },

  computed: {
    logs() {
      return listLogs(this.$store);
    },
    streamLogs() {
      return this.currentUser.preferences?.stream_logs;
    },
    logsFontSize() {
      return this.currentUser.preferences.logs_font_size || "text-sm";
    },
  },

  mounted() {
    ensureLogs(this.$store);

    toggleLogStreaming(this.$store, this.streamLogs);
  },
  methods: {
    clearClicked() {
      clearLogs(this.$store);
    },
    fontSizeChanged({ fontSize }) {
      updatePreferences(this.$store, { logs_font_size: fontSize });
    },
    streamingToggled(eventArgs) {
      toggleLogStreaming(this.$store, eventArgs);
      updatePreferences(this.$store, { stream_logs: eventArgs });
    },
  },
});
</script>
