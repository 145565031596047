export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backToOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to orders"])},
        "protip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly statements are available at the beginning of the following month. However, you can "])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow and estimate your consumption now."])}
      },
      "fr": {
        "backToOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux commandes"])},
        "protip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les décomptes mensuels sont disponibles au début du mois suivant. Cependant, vous pouvez "])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suivre et estimer votre consommation dès maintenant."])}
      }
    }
  })
}
