export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "create": {
          "github-enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitHub"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful connection to your GitHub Enterprise account!"])}
          },
          "gitlab": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitLab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful connection to your GitLab account!"])}
          },
          "gitlab-self-hosted": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected to GitLab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful connection to your GitLab self-hosted account!"])}
          }
        },
        "delete": {
          "github": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from GitHub"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitHub account"])}
          },
          "gitlab": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from Gitlab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitLab account"])}
          },
          "github-enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from GitHub"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitHub Enterprise account"])}
          },
          "gitlab-self-hosted": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from Gitlab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful disconnection from your GitLab self-hosted account"])}
          }
        }
      },
      "fr": {
        "create": {
          "github-enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitHub"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte GitHub Enterprise réussie !"])}
          },
          "gitlab": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitLab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte GitLab réussie !"])}
          },
          "gitlab-self-hosted": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à GitLab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à votre compte GitLab self-hosted réussie !"])}
          }
        },
        "delete": {
          "github": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de GitHub"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitHub"])}
          },
          "gitlab": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de Gitlab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitLab"])}
          },
          "github-enterprise": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de GitHub"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitHub Enterprise"])}
          },
          "gitlab-self-hosted": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de Gitlab"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bien déconnecté de votre compte GitLab self-hosted"])}
          }
        }
      }
    }
  })
}
