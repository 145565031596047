<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <CardHeader :title="$t('title')" :subtitle="$t('subtitle')"> </CardHeader>
    <div>
      <div class="flex flex-col pb-2 space-y-2">
        <SCTable
          :headers="headers"
          :items="backupsPolicies"
          :showFooter="false"
          :striped="false"
          :smallPadding="true"
        >
          <template #item.retention="{ item }">
            <span v-if="notIncludedInPlan(item)" class="text-scale-5">{{
              $t("retention_policy_labels.notIncluded")
            }}</span>
            <span v-else>
              {{ item.retention }}
            </span>
          </template>
          <template #item.usage="{ item }">
            <span
              v-if="notIncludedInPlan(item)"
              class="text-scale-5 float-right"
              >0 / 0</span
            >
            <span v-else class="float-right">
              {{ item.usage }}
            </span>
          </template>
        </SCTable>
      </div>
    </div>
    <div class="space-y-4">
      <InformationAlert
        v-if="hasBackupInPlan && !isBusinessPlan"
        :finerBanner="true"
        :marginSize="4"
      >
        <span>
          {{ $t("businessCTA.title") }}
          <router-link :to="changePlanRouterArgs">
            <span
              class="text-primary-2 hover:text-primary-2-125 font-medium underline rounded-md py-1 self-center cursor-pointer"
            >
              {{ $t("businessCTA.link") }}
            </span>
          </router-link>
        </span>
      </InformationAlert>
      <div class="text-scale-7">
        <p>
          {{ $t("text-2") }}
          <DocLink
            href="https://doc.scalingo.com/platform/databases/intro#backups-retention-policy"
            >{{ $t("doclink") }}
          </DocLink>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { formatDateTime, isBefore, relativeDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "PlanInformation",
  components: {
    InformationAlert,
    SCTable,
    CardHeader,
    DocLink,
  },
  mixins: [ModelsTranslation],
  props: {
    dbPlan: Object,
    backups: Object,
    hasBackupInPlan: Boolean,
    app: Object,
    addon: Object,
  },
  data() {
    return {
      headers: [
        {
          value: "frequency",
          text: this.$t("frequency.title"),
        },
        {
          value: "retention",
          text: this.$t("retention"),
        },
        { value: "usage", text: this.$t("usage"), class: "text-right" },
      ],
      retentionPolicies: ["days", "weeks", "months", "user"],
    };
  },
  computed: {
    backupsCapacity() {
      return {
        user: this.dbPlan.item.manual_backups_count,
        months: this.dbPlan.item.monthly_scheduled_backups,
        weeks: this.dbPlan.item.weekly_scheduled_backups,
        days: this.dbPlan.item.daily_scheduled_backups,
      };
    },
    backupFrequencies() {
      return [
        this.$t("frequency.daily"),
        this.$t("frequency.weekly"),
        this.$t("frequency.monthly"),
        this.$t("frequency.manual"),
      ];
    },
    isBusinessPlan() {
      if (!this.dbPlan.item) {
        return false;
      }
      return this.dbPlan.item.monthly_scheduled_backups !== 0;
    },
    backupsPolicies() {
      if (!this.dbPlan.item) {
        return [];
      }
      let policies = [];
      const backupsCount = this.backupsCount;
      const backupsCapacity = this.backupsCapacity;
      const backupFrequencies = this.backupFrequencies;

      for (const i in this.retentionPolicies) {
        const retentionPolicy = this.retentionPolicies[i];
        let policy = {
          retentionPolicy: retentionPolicy,
          frequency: backupFrequencies[i],
          retention: this.$t("retention_policy_labels." + retentionPolicy, {
            capacity: backupsCapacity[retentionPolicy],
          }),
          usage:
            backupsCount[retentionPolicy] +
            " / " +
            backupsCapacity[retentionPolicy],
        };
        policies.push(policy);
      }
      return policies;
    },
    backupsCount() {
      let count = {
        user: 0,
        months: 0,
        weeks: 0,
        days: 0,
      };

      for (const key in this.backups.items) {
        const backup = this.backups.items[key];
        if (backup.method === "manual") {
          count["user"]++;
          continue;
        }
        if (
          isBefore(backup.started_at, {
            weeks: this.dbPlan.item.weekly_scheduled_backups + 1,
          })
        ) {
          count["months"]++;
          continue;
        }
        if (
          isBefore(backup.started_at, {
            days: 7,
          })
        ) {
          count["weeks"]++;
          continue;
        }
        count["days"]++;
      }
      return count;
    },
    changePlanRouterArgs() {
      return {
        name: Routes.App.Addon.Edition.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
          addonId: this.addon.id,
        },
      };
    },
  },
  methods: {
    formatDateTime,
    relativeDateTime,
    notIncludedInPlan(item) {
      if (!this.hasBackupInPlan) {
        return true;
      }
      return !this.isBusinessPlan && item.retentionPolicy === "months";
    },
  },
});
</script>

<i18n>
en:
  title: "Retention policy"
  subtitle: "When the limit is reached, each new backup will replace the oldest"
  overTable: "When your backup usage is reached, additional scheduled backups will replace the oldest one."
  text-1: "Does your application require greater retention ?"
  text-2:  "Learn more about backup {link}"
  doclink: "retention policy according to each plan."
  oldest: "Oldest backup: {backupTime}"
  newest: "Newest backup: {backupTime}"
  usage: "Usage"
  policy: "Name"
  retention: "Retention"
  frequency:
    title: "Frequency"
    daily: "Daily: one every day"
    weekly: "Weekly: one every sunday"
    monthly: "Monthly: one every first week of the month"
    manual: "Manual: on demand"
  retention_policy_labels:
    user: "Indefinite, last {capacity} rolling backups"
    months: "{capacity} rolling months"
    weeks: "{capacity} rolling weeks"
    days: "{capacity} rolling days"
    notIncluded: "Not included"
  businessCTA:
    title: "Do you need greater data retention?"
    link: "Upgrade to a Business plan"

fr:
  title: "Politique de conservation des données"
  subtitle: "Lorsque la limite est atteinte, chaque nouveau backup remplacera le plus ancien"
  overTable: "Lorsque le nombre de sauvegardes est atteint, des sauvegardes planifiées supplémentaires remplacent la plus ancienne."
  text-1: "Votre application nécessite-t-elle une plus grande politique de conservation des données ?"
  text-2:  "En savoir plus sur la politique de {link}"
  doclink: "rétention des backups en fonction de chaque plan."
  oldest: "Backup le plus ancien: {backupTime}"
  newest: "Backup le plus récent: {backupTime}"
  usage: "Usage"
  policy: "Nom"
  retention: "Rétention"
  frequency:
    title: "Fréquence"
    daily: "Quotidien : un par jour"
    weekly: "Hebdomadaire : un par dimanche"
    monthly: "Mensuel : un chaque première semaine du mois"
    manual: "Manuel : sur demande"
  retention_policy_labels:
    user: "Indéfinie, {capacity} derniers glissants"
    months: "{capacity} mois glissants"
    weeks: "{capacity} semaines glissantes"
    days: "{capacity} jours glissants"
    notIncluded: "Non inclus"
  businessCTA:
    title: "Avez-vous besoin d'une plus grande rétention de vos données ?"
    link: "Passez à un plan Business"
</i18n>
