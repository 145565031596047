<template>
  <div class="flex flex-col">
    <div>
      {{ $t("provider") }}:
      <span class="font-medium ml-1">{{
        event.type_data.addon_provider_name
      }}</span>
    </div>
    <div>
      {{ $t("addon_id") }}:
      <span class="font-medium ml-1">{{ event.type_data.addon_uuid }}</span>
    </div>
    <div>
      {{ $t("ip") }}:
      <span class="font-medium ml-1">{{ event.type_data.remote_ip }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonEventBody",
  props: {
    event: Object,
  },
});
</script>

<i18n>
en:
  provider: "Database Type"
  addon_id: "Addon ID"
  ip: "IP"
fr:
  provider: "Type de Base de Données"
  addon_id: "ID de l'Addon"
  ip: "IP"
</i18n>
