<template>
  <SCBanner kind="warning" align="center">
    <i18n keypath="warning">
      {{ $t("warning") }}

      <router-link :to="hdsRoute" class="underline text-primary-2">
        {{ $t("link") }}
      </router-link>
    </i18n>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ContactOutdatedBanner",
  components: { SCBanner },
  data() {
    return {
      hdsRoute: {
        name: Routes.Account.HDS,
      },
    };
  },
});
</script>

<i18n>
  en:
    warning: "In order to continue using your HDS apps, you must"
    link: "review and, if necessary, update the point of contact Health professional information."
  fr:
    warning: "Afin de pouvoir continuer à utiliser vos applications HDS, vous devez"
    link: "vérifier, et le cas échéant mettre à jour, les informations du point de contact professionnel de santé."
</i18n>
