<template>
  <section>
    <p class="mt-2">
      <span class="font-bold">{{ hdsContact.entity }}</span>
      <span class="text-scale-7">
        ({{ $t(`attributes.hdsContactProfile.orgTypes.${hdsContact.kind}`) }})
      </span>
    </p>
    <p>{{ hdsContact.firstname }} {{ hdsContact.lastname }}</p>
    <p>{{ hdsContact.email }} / {{ hdsContact.phone }}</p>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "HDSContactInfo",
  mixins: [ModelsTranslation],
  props: {
    hdsContact: Object,
  },
});
</script>
