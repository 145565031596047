export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Empty ", _interpolate(_named("name"))])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying a database is a permanent action that cannot be undone."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vider ", _interpolate(_named("name"))])},
        "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider une base de données est une action permanente qui ne peut pas être annulée."])}
      }
    }
  })
}
