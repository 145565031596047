<template>
  <!-- The args are ordere by logical groups: email, password, tfa, then deletion -->
  <ViewComponent
    :currentEmail="currentEmail"
    :unconfirmedEmail="unconfirmedEmail"
    :accountName="currentUser.username"
    :changeEmailHandler="changeEmailHandler"
    :changePasswordHandler="changePasswordHandler"
    :requestDeletionInfo="requestDeletionInfo"
    :tfa="tfa"
    :twoFactorIsEnabling="twoFactorIsEnabling"
    :twoFactorCodes="twoFactorCodes"
    :twoFactorInitiateOperation="twoFactorInitiateOperation"
    :twoFactorValidateOperation="twoFactorValidateOperation"
    :twoFactorDisableOperation="twoFactorDisableOperation"
    :twoFactorValidateErrors="twoFactorValidateErrors"
    @confirmEmailChange="(e) => changeEmailHandler.submit(e)"
    @confirmPasswordChange="(e) => changePasswordHandler.submit(e)"
    @confirmDeletionRequest="confirmDeletionRequest"
    @twoFactorInitiated="twoFactorInitiated"
    @twoFactorDisabled="twoFactorDisabled"
    @twoFactorPinSubmitted="twoFactorPinSubmitted"
    @twoFactorModalClosed="resetTwoFactorStatus"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/account/Authentication.vue";
import { EditAccountEmailHandler, ResetPasswordHandler } from "@/lib/handlers";
import { voidPromiseInfo } from "@/lib/promises/info";
import {
  ensureTfa,
  refreshTfa,
  tfaStatus,
  initiateTfa,
  validateTfa,
  disableTfa,
} from "@/store/two_factor_auth";
import { useUserStore } from "@/stores/user";

export default defineComponent({
  name: "AuthenticationContainer",
  components: { ViewComponent },
  setup() {
    const userStore = useUserStore();

    return { userStore };
  },
  data() {
    return {
      changeEmailHandler: null,
      changePasswordHandler: null,
      requestDeletionInfo: voidPromiseInfo(),
      // TFA
      twoFactorInitiateOperation: null,
      twoFactorDisableOperation: null,
      twoFactorValidateOperation: null,
      twoFactorCodes: null,
    };
  },
  computed: {
    currentEmail() {
      return this.currentUser?.email;
    },
    unconfirmedEmail() {
      return this.currentUser?.unconfirmed_email;
    },
    // TFA
    tfa() {
      return tfaStatus(this.$store);
    },
    twoFactorIsEnabling() {
      const uri = this.tfa?.value?.uri;

      return uri !== null && uri !== undefined;
    },
    twoFactorValidateErrors() {
      if (this.twoFactorValidateOperation?.isError) {
        const e = this.twoFactorValidateOperation.error;
        return { attempt: [e.data.error] };
      } else {
        return {};
      }
    },
  },
  watch: {
    async twoFactorValidateOperation(newStatus) {
      if (!newStatus) return;

      try {
        await newStatus.promise;

        if (newStatus.isSuccess) {
          this.twoFactorCodes = newStatus.value.codes;
        }
      } catch (error) {
        // not supposed to happen, will just not show the 2FA recovery codes, founded no way to log it
      }
    },
  },
  beforeMount() {
    ensureTfa(this.$store);

    if (this.currentUser) {
      this.resetChangeEmail();
      this.resetChangePassword();
    }
  },
  methods: {
    resetChangeEmail() {
      this.changeEmailHandler = new EditAccountEmailHandler(
        this,
        this.currentUser,
      );

      // When the email is successfully changed, renew the form handler
      this.changeEmailHandler.on("success", () => this.resetChangeEmail());
    },
    resetChangePassword() {
      this.changePasswordHandler = new ResetPasswordHandler(this);

      // When the password is successfully changed, renew the form handler
      this.changePasswordHandler.on("success", () =>
        this.resetChangePassword(),
      );
    },
    confirmDeletionRequest() {
      this.requestDeletionInfo = this.userStore.requestionDeletion();
    },
    resetTwoFactorStatus() {
      refreshTfa(this.$store);
      this.twoFactorInitiateOperation = null;
      this.twoFactorDisableOperation = null;
      this.twoFactorValidateOperation = null;
    },
    async twoFactorInitiated() {
      this.twoFactorInitiateOperation = await initiateTfa(this.$store);
    },
    async twoFactorDisabled() {
      this.twoFactorDisableOperation = await disableTfa(this.$store);
    },
    async twoFactorPinSubmitted(eventArgs) {
      this.twoFactorValidateOperation = await validateTfa(
        this.$store,
        eventArgs.attempt,
        true,
      );
    },
  },
});
</script>
