<template>
  <main>
    <ChangeEmailForm
      :currentEmail="currentEmail"
      :unconfirmedEmail="unconfirmedEmail"
      :formHandler="changeEmailHandler"
      @formSubmitted="(e) => $emit('confirmEmailChange', e)"
    />
    <ChangePasswordForm
      class="mt-6"
      :formHandler="changePasswordHandler"
      @formSubmitted="(e) => $emit('confirmPasswordChange', e)"
    />
    <TwoFactorCard
      class="mt-6"
      :tfa="tfa"
      :isEnabling="twoFactorIsEnabling"
      :initiateOperation="twoFactorInitiateOperation"
      :disableOperation="twoFactorDisableOperation"
      @twoFactorInitiated="twoFactorInitiated"
      @twoFactorDisabled="twoFactorDisabled"
    ></TwoFactorCard>

    <DeleteAccountForm
      class="mt-6"
      :accountName="accountName"
      :promiseInfo="requestDeletionInfo"
      @formSubmitted="(e) => $emit('confirmDeletionRequest', e)"
    />

    <TwoFactorActivate
      v-if="displayModal"
      :tfa="tfa"
      :codes="twoFactorCodes"
      :isSubmitting="twoFactorIsSubmitting"
      :validateErrors="twoFactorValidateErrors"
      @twoFactorPinSubmitted="twoFactorPinSubmitted"
      @twoFactorModalClosed="twoFactorModalClosed"
    ></TwoFactorActivate>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ChangeEmailForm from "@/components/parts/account/showAccountAuthentication/ChangeEmailForm.vue";
import ChangePasswordForm from "@/components/parts/account/showAccountAuthentication/ChangePasswordForm.vue";
import DeleteAccountForm from "@/components/parts/account/showAccountAuthentication/DeleteAccountForm.vue";
import TwoFactorActivate from "@/components/parts/account/showAccountAuthentication/TwoFactorActivate.vue";
import TwoFactorCard from "@/components/parts/account/showAccountAuthentication/TwoFactorCard.vue";

export default defineComponent({
  name: "Authentication",
  components: {
    DeleteAccountForm,
    TwoFactorCard,
    TwoFactorActivate,
    ChangePasswordForm,
    ChangeEmailForm,
  },
  props: {
    accountName: String,
    currentEmail: String,
    unconfirmedEmail: String,
    changeEmailHandler: Object,
    changePasswordHandler: Object,
    requestDeletionInfo: Object,
    // TFA
    tfa: Object,
    twoFactorIsEnabling: Boolean,
    twoFactorCodes: Array,
    twoFactorInitiateOperation: Object,
    twoFactorValidateOperation: Object,
    twoFactorDisableOperation: Object,
    twoFactorValidateErrors: Object,
  },
  emits: [
    "confirmEmailChange",
    "confirmPasswordChange",
    "confirmDeletionRequest",
    "twoFactorDisabled",
    "twoFactorInitiated",
    "twoFactorModalClosed",
    "twoFactorPinSubmitted",
  ],
  data() {
    return {};
  },
  computed: {
    displayModal() {
      return this.twoFactorIsEnabling || this.twoFactorCodes;
    },
    twoFactorIsSubmitting() {
      return this.twoFactorValidateOperation?.isLoading || false;
    },
  },
  methods: {
    // TFA
    twoFactorInitiated(eventArgs) {
      this.$emit("twoFactorInitiated", eventArgs);
    },
    twoFactorDisabled(eventArgs) {
      this.$emit("twoFactorDisabled", eventArgs);
    },
    twoFactorPinSubmitted(eventArgs) {
      this.$emit("twoFactorPinSubmitted", eventArgs);
    },
    twoFactorModalClosed(eventArgs) {
      this.$emit("twoFactorModalClosed", eventArgs);
    },
  },
});
</script>
