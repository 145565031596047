export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HDH Contracts & documents"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractual documents relative to health data hosting"])},
        "showAllContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List older contracts"])},
        "hdsContractWarning1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that only applications that are identified as HDH"])},
        "hdsContractWarning2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and their add-ons are covered by the HDH contract."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract et documents HDS"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents contractuels relatifs à l'hébergement de données de santé"])},
        "showAllContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer mes anciens contrats"])},
        "hdsContractWarning1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les applications identifiées comme HDS"])},
        "hdsContractWarning2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et leurs add-ons sont couvertes par le contrat HDS."])}
      }
    }
  })
}
