export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon log drain created"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url: "])},
        "addon_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon name: "])},
        "addon_uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon uuid: "])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log drain d'addon créé"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url :"])},
        "addon_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'addon: "])},
        "addon_uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuid de l'addon: "])}
      }
    }
  })
}
