<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <EnvVarEditGlyph class="h-6 w-6 text-scale-6"></EnvVarEditGlyph>
      <div class="text-scale-7 pl-2 font-bold">{{ $t("title") }}</div>
    </template>
    <template #body>
      <span class="text-scale-7 text-sm">
        {{ $tc("added", newVarsLength, { newVarsLength }) }} -
        {{ $tc("updated", updatedVarsLength, { updatedVarsLength }) }} -
        {{ $tc("deleted", deletedVarsLength, { deletedVarsLength }) }}</span
      >
      <div class="text-scale-0 mt-4 flex flex-col text-scale-10">
        <div
          v-for="(new_var, key) in newVars"
          :key="'new' + key"
          class="mb-1 flex flex-col"
        >
          <span class="bg-color-1-10 p-1 text-color-1"
            >+ {{ new_var.name }}={{ new_var.value }}</span
          >
        </div>
        <div
          v-for="(updated_var, key) in updatedVars"
          :key="'updated' + key"
          class="mb-1 flex flex-col"
        >
          <span class="bg-color-2-10 text-color-2 p-1 line-through"
            >- {{ updated_var.name }}={{ updated_var.old_value }}</span
          >
          <span class="bg-color-1-10 p-1 text-color-1"
            >+ {{ updated_var.name }}={{ updated_var.value }}</span
          >
        </div>
        <div
          v-for="(deleted_var, key) in deletedVars"
          :key="'deleted' + key"
          class="mb-1"
        >
          <span class="bg-color-2-10 text-color-2 p-1"
            >-
            <span class=""
              >{{ deleted_var.name }}={{ deleted_var.value }}</span
            ></span
          >
        </div>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import EnvVarEditGlyph from "@/components/atoms/glyphs/EnvVarEditGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "EditVariablesEvent",
  components: { EnvVarEditGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    newVars() {
      return this.event.type_data.new_vars;
    },
    updatedVars() {
      return this.event.type_data.updated_vars;
    },
    deletedVars() {
      return this.event.type_data.deleted_vars;
    },
    newVarsLength() {
      return this.newVars ? this.newVars.length : 0;
    },
    updatedVarsLength() {
      return this.updatedVars ? this.updatedVars.length : 0;
    },
    deletedVarsLength() {
      return this.deletedVars ? this.deletedVars.length : 0;
    },
  },
});
</script>

<i18n>
  en:
    title: "Environment variables edited"
    openTerminalTitle: "Show edited variables"
    added:  "0 added | 1 added | {count} added"
    updated: "0 updated | 1 updated | {count} updated"
    deleted: "0 deleted | 1 deleted | {count} deleted"
  fr:
    title: "Variables d'environnement éditées"
    openTerminalTitle: "Afficher les variables éditées"
    added:  "Aucune ajout | Un ajout | {count} ajouts"
    updated: "Aucune édition | Une édition | {count} éditions"
    deleted: "Aucune suppression | Une suppression | {count} suppressions"
</i18n>
