import { Variable } from "scalingo/lib/models/regional";

import { ActionHandler } from "@/lib/handlers/action";
import { deleteVariable } from "@/store/variables";

import type { ComponentPublicInstance } from "vue";

export class DeleteVariableHandler extends ActionHandler<void> {
  keyPath = "variable.delete";

  constructor(
    component: ComponentPublicInstance,
    readonly variable: Variable,
  ) {
    super(component);
  }

  dispatchEvents(): void {
    this.on("success", () => {
      this.notify("success", { name: this.variable.name });
    });
  }

  async submit(): Promise<void> {
    this.follow(await deleteVariable(this.$store, this.variable));
  }
}
