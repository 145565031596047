import { App } from "scalingo/lib/models/regional";

import { scalingoClient } from "@/lib/scalingo/client";
import { FETCH, HANDLE_OPERATION, UPDATE } from "@/lib/store/action-types";
import { CollectionStore, find, insert } from "@/lib/store/collection-store";
import { FIND } from "@/lib/store/getter-types";
import { ADD, MERGE } from "@/lib/store/mutation-types";
import { RemoteOperation } from "@/lib/store/remote-operation";
import { buildMapping } from "@/lib/store/utils";
import { ApplicationStore } from "@/store";
import { useCurrentAppStore } from "@/stores/current/app";

interface DeploymentLogsModel {
  id: string;
  logs: string;
}

export class DeploymentLogsStore extends CollectionStore<DeploymentLogsModel> {
  actions = CollectionStore.buildActions<DeploymentLogsModel>({
    [FETCH](context, { deploymentId, force }) {
      const currentApp = useCurrentAppStore().regional as App;

      if (!force) {
        const logs = context.getters[FIND](deploymentId);

        if (logs) {
          return;
        }
      }

      return context.dispatch(HANDLE_OPERATION, {
        promise: scalingoClient(context, currentApp.region).Deployments.logs(
          currentApp.id,
          deploymentId,
        ),
        resolveAction(logs: string) {
          context.commit(ADD, { id: deploymentId, logs });
        },
      });
    },
    [UPDATE](context, payload = {}) {
      context.commit(MERGE, payload);
    },
  });

  mutations = CollectionStore.buildMutations<DeploymentLogsModel>({
    [MERGE](state, payload) {
      const model = find(state, payload.id);

      const incoming = payload.content || payload.logs;

      if (model) {
        if (incoming) {
          model.logs ||= "";
          model.logs += incoming;
        }
      } else {
        insert(state, {
          id: payload.id,
          logs: incoming,
        });
      }
    },
  });
  getters = CollectionStore.buildGetters<DeploymentLogsModel>();
}

export const DeploymentLogs = buildMapping(
  new DeploymentLogsStore(),
  "deploymentLogs",
);

export function logsForDeployment(
  store: ApplicationStore,
  deploymentId: string,
): Promise<RemoteOperation<DeploymentLogsModel>> {
  return store.getters[DeploymentLogs.getters.FIND](deploymentId);
}

export function fetchLogsForDeployment(
  store: ApplicationStore,
  deploymentId: string,
  force = false,
): Promise<RemoteOperation<DeploymentLogsModel>> {
  return store.dispatch(DeploymentLogs.actions.FETCH, { deploymentId, force });
}
